import { Component, Inject } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { LxmMessage } from "src/app/_helpers";
import { ProductService } from "src/app/_services";

@Component({
    selector: 'sync-products-dialog',
    host: { 'class': 'dialog' },
    templateUrl: './sync-products.dialog.html'
  })
  export class SyncProductsDialog {
  
    public formData;
    public formGroup: FormGroup;
    public suppliersOptions = [];
    public isLoading = false;
  
    constructor(
      public dialogRef: MatDialogRef<SyncProductsDialog>,
      private _productService: ProductService,
      private _msg: LxmMessage,
      @Inject(MAT_DIALOG_DATA) public data) {
      const { formData: { productSuppliers } } = this.data || {};
  
      this.suppliersOptions = productSuppliers || [];
  
      this.formGroup = new FormGroup({
        suppliers: new FormControl()
      });
    }
  
    public dialogImage = 'sync-products-modal-image';
  
    public syncProducts() {
      const form = this.formGroup.value;
  
      const req = {
        supplierIds: form.suppliers
      }
  
      this.isLoading = true;
  
      this._productService.syncProducts(req).result(this.formGroup, res => {
        this.isLoading = false;
        this.dialogRef.close(true);
      }, e => {
        this._msg.error({
          message: 'global.generic_error'
        })
        console.error(e);
        this.isLoading = false;
      })
    }
  
    public onNoClick(): void {
      this.dialogRef.close();
    }
  
    public closeDialog() {
      this.dialogRef.close();
    }
  
  }