<div class="row overflow-x" [formGroup]="fgInternal" *ngIf="hasData">

  <mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="format_column">
      <mat-header-cell *matHeaderCellDef>
        <span>
          {{'cards.products.product_movie_info.format_section.format' | translate}}
        </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
        <div class="flex f-1" style="position: relative;">
          <lxm-select #formatSelect for="formatId" value="id" labelField="translatedValue" [isTranslated]="true"
            [searchable]="true" [options]="movieRatingFormatOptions" [sortByLabel]="true" [readonly]="readonly"
            (change)="onFormatChanged(i, $event?.id)">
            <validation-errors [for]="'formats['+(i)+'].formatId'" aliasTo="formatId" [errorsFormGroup]="formGroup">
            </validation-errors>
          </lxm-select>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="rating_column">
      <mat-header-cell *matHeaderCellDef>
        <span>
          {{'cards.products.product_movie_info.format_section.rating' | translate}}
        </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
        <div class="flex f-1" style="position: relative;">
          <lxm-select #ratingSelect for="ratingId" value="id" labelField="translatedValue" [isTranslated]="true"
            [searchable]="true" [sortByLabel]="true" [readonly]="readonly">
            <validation-errors [for]="'formats['+(i)+'].ratingId'" aliasTo="ratingId" [errorsFormGroup]="formGroup">
            </validation-errors>
          </lxm-select>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="remove_column">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
        <lxm-button *ngIf="!readonly" type="transparent" icon="trash" padding="8px 0" (click)="removeItem(i)">
        </lxm-button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="table.columns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: table.columns"
      [ngClass]="{'row-to-delete readonly' : row.get('isDeleted').value}"></mat-row>
  </mat-table>

</div>

<div class="row f-1" *ngIf="!hasData" style="padding: 8px;">
  <no-content image="no-film-ratings" text="cards.products.product_movie_info.format_section.no_formats" [inline]="true" style="margin: 0;"></no-content>
</div>

<div class="row mt10" *ngIf="!readonly">
  <lxm-button type="transparent" icon="plus-dark" label="cards.products.product_movie_info.format_section.add_new" (click)="addItem()"></lxm-button>
</div>