<ng-container [ngSwitch]="appState.hasModule(LxmAppModule.BuyerPimExtensions)">
  <ng-container *ngSwitchCase="true">
    <product-search-retailer-card class="mb12" [isOpen]="isSearchPanelOpen" [localStorageKey]="searchPanelLocalStorageKey" (search)="searchSubject.next($event)"></product-search-retailer-card>
    <products-retailer-card [search]="search"></products-retailer-card>
  </ng-container>
  <ng-container *ngSwitchCase="false">
    <ng-container *nonVisitor>
      <product-search-supplier-card class="mb12" [isOpen]="isSearchPanelOpen" [localStorageKey]="searchPanelLocalStorageKey" (search)="searchSubject.next($event)"></product-search-supplier-card>
    </ng-container>
    <products-supplier-card [search]="search"></products-supplier-card>
  </ng-container>
</ng-container>
