import { Component, Input, ViewContainerRef, ViewChild, AfterContentInit, TemplateRef } from "@angular/core";

@Component({
    selector: "lxm-tree-header",
    templateUrl: "./lxm-tree-header.component.html"
})
export class LxmTreeHeaderComponent implements AfterContentInit {
    @Input()
    public colName: string;

    @Input()
    public templateRef: TemplateRef<any>;

    @ViewChild("content", { static: true, read: ViewContainerRef })
    private _contentVc: ViewContainerRef;

    public ngAfterContentInit(): void {
        this._contentVc.createEmbeddedView(this.templateRef);
    }
}
