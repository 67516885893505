export enum OfferField {
  Name = 1,
  Description = 2,
  ValidFrom = 3,
  ValidTo = 4,
  Status = 5,
  OfferType = 6,
  Products = 7,
  Recipients = 8,
  Users = 9,
  Attachments = 10,
  Retailer = 11,
  RecipientEmail = 12,
  RecipientUser = 13,
  RetailerCampaign = 14,
  Owner = 15,
  Currency = 16,
  Number = 17,
  HasProductsPdf = 18,
  CanOrder = 19,
  Procurement = 20,
  OfferCalendarEntry = 21,
  CampaignType = 22
}

(OfferField as any).__typeName = 'OfferField';

const _ = 'cards.offer.base_info.label.';

(OfferField as any).__altTranslationKeys = {
  [OfferField.Retailer] : `${_}retailer`,
  [OfferField.Name] : _ + 'offer_name',
  [OfferField.Description] : _ + 'offer_description',
  [OfferField.ValidFrom] : _ + 'offer_date_from',
  [OfferField.ValidTo] : _ + 'offer_date_to',
  [OfferField.Status] : _ + 'offer_status',
  [OfferField.OfferType] : _ + 'offer_type',
  [OfferField.Recipients] : _ + 'purchase_manager',
  [OfferField.Users] : _ + 'sales_manager',
  [OfferField.Products] : 'cards.offer.products.title',
  [OfferField.Attachments] : 'cards.offer.files.title',
  [OfferField.RetailerCampaign] : 'retailer_campaign',
  [OfferField.Owner] : _ + 'owner',
  [OfferField.HasProductsPdf] : _ + 'include_products_pdf',
  [OfferField.Procurement] : _ + 'procurement_name',
  [OfferField.OfferCalendarEntry] : _ + 'campaign_calendar_entry',
  [OfferField.CampaignType] : _ + 'campaign_type',
};
