<card-expanding [formGroup]="form" [valid]="cardValidType" [expanded]="isExpanded" (onExpand)="saveLocalExpandedValue($event)">

  <span header-title>{{ title | translate}}</span>

  <div header-actions *requiredRights="[UserAction.ManageProducts]">
    <ng-container *ngIf="isOwnProduct">
      <ng-container *ngIf="!locked">
        <lxm-button *ngIf="!edit" class="ml15" type="beige" icon="edit" title="action.edit" (click)="toggleEdit()"></lxm-button>
        <lxm-button *ngIf="edit" class="ml15" type="grey" icon="back" label="action.cancel" (click)="toggleEdit()">
        </lxm-button>
        <lxm-button *ngIf="edit" class="ml15" type="beige" icon="save" label="action.save" [loading]="saveLoading"
          (click)="save()" buttonType="submit"></lxm-button>
      </ng-container>
      <ng-container *ngIf="locked">
        {{ 'global.locked_by' | translate }}: {{ lockedBy.name }} {{ lockedAt | amTimeAgo }}
      </ng-container>
    </ng-container>
  </div>

  <div body>

    <div class="row">
      <div class="col-6 no-padding">

        <form-field [readonly]="!edit"
          label="cards.products.product_movie_info.label.media_format" description="cards.products.product_movie_info.label_description.media_format"
          for="mediaFormatId"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.MovieMediaFormat, form.get('mediaFormatId').value) : null">
          <lxm-select select
            [readonly]="!edit"
            [options]="movieMediaFormatOptions"
            for="mediaFormatId"
            value="id"
            labelField="translatedValue"
            [isTranslated]="true"
            [sortByLabel]="true">
            </lxm-select>
          <validation-errors for="mediaFormatId"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit"
          label="cards.products.product_movie_info.label.dvd_region" description="cards.products.product_movie_info.label_description.dvd_region"
          for="dvdRegionId"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.MovieDvdRegion, form.get('dvdRegionId').value) : null">
          <lxm-select select
            [readonly]="!edit"
            [options]="movieDvdRegionOptions"
            for="dvdRegionId"
            value="id"
            labelField="translatedValue"
            [isTranslated]="true"
            [sortByLabel]="true">
            </lxm-select>
          <validation-errors for="dvdRegionId"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit"
          label="cards.products.product_movie_info.label.aspect_ratio" description="cards.products.product_movie_info.label_description.aspect_ratio"
          for="aspectRatioId"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.MovieAspectRatio, form.get('aspectRatioId').value?.length > 0) : null">
          <lxm-select select
            [readonly]="!edit"
            [options]="movieAspectRatioOptions"
            for="aspectRatioId"
            value="id"
            labelField="translatedValue"
            [isTranslated]="true"
            [sortByLabel]="true">
            </lxm-select>
          <validation-errors for="aspectRatioId"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit" for="duration"
            label="cards.products.product_movie_info.label.duration"
            description="cards.products.product_movie_info.label_description.duration"
            type="input-text"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.MovieDuration, form.get('duration').value) : null">
            <validation-errors for="duration"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit"
          label="cards.products.product_movie_info.label.genres"
          description="cards.products.product_movie_info.label_description.genres"
          for="genres"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.MovieGenres, form.get('genres').value?.length > 0) : null">
          <lxm-select select
            [readonly]="!edit"
            [options]="movieGenreOptions"
            for="genres"
            value="id"
            labelField="translatedValue"
            maxSelectedItems="4"
            [multiple]="true"
            [searchable]="true"
            [isTranslated]="true"
            [sortByLabel]="true">
          </lxm-select>
          <validation-errors for="genres"></validation-errors>
        </form-field>

      </div>

      <div class="col-6 no-padding">
        <form-field [readonly]="!edit" for="year"
            label="cards.products.product_movie_info.label.year"
            description="cards.products.product_movie_info.label_description.year"
            type="input-text"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.MovieYear, form.get('year').value) : null">
            <validation-errors for="year"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit" for="releaseYear"
            label="cards.products.product_movie_info.label.release_year"
            description="cards.products.product_movie_info.label_description.release_year"
            type="input-text"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.MovieReleaseYear, form.get('releaseYear').value) : null">
            <validation-errors for="releaseYear"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit"
          label="cards.products.product_movie_info.label.production_countries"
          description="cards.products.product_movie_info.label_description.production_countries"
          for="productionCountries"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.MovieProductionCountries, form.get('productionCountries').value?.length > 0) : null">
          <lxm-select select
            value="id"
            [readonly]="!edit"
            [options]="countryOptions"
            [isTranslated]="true"
            labelField="translatedName"
            [sortByLabel]="true"
            [searchable]="true"
            [multiple]="true"
            maxSelectedItems="4"
            for="productionCountries">
          </lxm-select>
          <validation-errors for="productionCountries"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit" for="productionCompany"
            label="cards.products.product_movie_info.label.production_company"
            description="cards.products.product_movie_info.label_description.production_company"
            type="input-text"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.MovieProductionCompany, form.get('productionCompany').value) : null">
            <validation-errors for="productionCompany"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit"
          label="cards.products.product_movie_info.label.content_ratings"
          description="cards.products.product_movie_info.label_description.content_ratings"
          for="contentRatings"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.MovieContentRatings, form.get('contentRatings').value?.length > 0) : null">
          <lxm-select select
            [readonly]="!edit"
            [options]="movieContentRatingOptions"
            [isTranslated]="true"
            value="id"
            labelField="translatedValue"
            [sortByLabel]="true"
            [searchable]="true"
            [multiple]="true"
            for="contentRatings">
          </lxm-select>
          <validation-errors for="contentRatings"></validation-errors>
        </form-field>

      </div>
    </div>

    <section-expanding [expanded]="shouldExpandSections" class="mt0" [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.MovieFormats, form.get('formats').value?.length > 0) : null">
      <span header-title>
        {{'cards.products.product_movie_info.format_section.title' | translate}}
      </span>
      <div body>
        <div class="row">
          <div class="col-12 no-padding">
            <movie-format-list for="formats" [readonly]="!edit" [movieRatingFormatOptions]="movieRatingFormatOptions">
            </movie-format-list>
          </div>
        </div>
      </div>
    </section-expanding>

    <section-expanding [expanded]="shouldExpandSections" class="mt0" [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.MovieAudio, form.get('audio').value?.length > 0) : null">
      <span header-title>
        {{'cards.products.product_movie_info.audio_section.title' | translate}}
      </span>
      <div body>
        <div class="row">
          <div class="col-12 no-padding">
            <movie-audio-list for="audio" [readonly]="!edit" [movieSubtitleTypeOptions]="movieSubtitleTypeOptions">
            </movie-audio-list>
          </div>
        </div>
      </div>
    </section-expanding>

    <section-expanding [expanded]="shouldExpandSections" class="mt0" [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.MovieActors, form.get('actors').value?.length > 0) : null">
      <span header-title>
        {{'cards.products.product_movie_info.actors_section.title' | translate}}
      </span>
      <div body>
        <div class="row">
          <div class="col-12 no-padding">
            <movie-actor-list for="actors" [readonly]="!edit" [movieActorsRoleOptions]="movieActorRoleOptions">
            </movie-actor-list>
          </div>
        </div>
      </div>
    </section-expanding>

  </div>

  <div footer class="flex flex-row" *ngIf="edit">
    <lxm-button class="ml15" type="grey" icon="back" label="action.cancel" (click)="toggleEdit()"></lxm-button>
    <lxm-button class="ml15" type="beige" icon="save" label="action.save" [loading]="saveLoading" (click)="save()">
    </lxm-button>
  </div>

</card-expanding>
