import { Pipe, PipeTransform} from "@angular/core";
import { formatPercent } from "@angular/common";
import { locale } from 'src/app/_services'

@Pipe({
  name: "percentValue",
  // detects locale change but could be performance heavy
  pure: false
})
export class PercentValuePipe implements PipeTransform {

  transform(value: any, digits: number = 1) {
    if (isNaN(value)) {
      return '-';
    }
    const digitsInfo = `1.${digits}-${digits}`;
    return formatPercent(value, locale, digitsInfo) || "-";
  }

}
