import { Component, OnInit } from "@angular/core";
import { TitleService } from 'src/app/_services/title.service';

@Component({
  selector: "roles-layout",
  templateUrl: "./roles.layout.html",
  host: { 'class': 'tab-content' },
  styleUrls: ['./roles.layout.scss']
})
export class RolesLayout implements OnInit {
  title = "cards.lexi_market_settings.lxm_settings_user_role.title";

  constructor(private _titleService: TitleService) { }

  public ngOnInit() {
    this._titleService.setTitle(this.title);
  }

}
