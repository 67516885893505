<lxm-dialog [dialogRef]="dialogRef" [formGroup]="form">
    <span header-title>
        {{(edit ? 
            (ruleId ? 'cards.clients.contract_conditions.dialog.title_edit' : 'cards.clients.contract_conditions.dialog.title_add') 
            : 'cards.clients.contract_conditions.dialog.title_passive')  
            | translate}}
    </span>

    <div body style="padding-bottom: 40px;">

            <form-field dialog [readonly]="!edit" label="cards.clients.contract_conditions.dialog.label.order_nr" for="orderNr" type="input-number">
                <validation-errors for="orderNr"></validation-errors>
            </form-field>

            <form-field dialog label="cards.clients.contract_conditions.dialog.label.discount_type">
                <lxm-select select for="discountRuleType"
                    [readonly]="!edit"
                    [clearable]="false"
                    [options]="typeOptions"
                    value="id">
                </lxm-select>
                <validation-errors for="type" aliasTo="discountRuleType"></validation-errors>
            </form-field>

            <form-field dialog label="{{conditionLabelKey}}" *ngIf="showConditionField" [ngSwitch]="discountRuleType">
                <lxm-select select 
                    *ngSwitchCase="ClientDiscountRuleType.Gs1Segment"
                    [readonly]="!edit"
                    [searchable]="true"
                    labelField="title"
                    for="gs1Segment"
                    url="api/clvac/product-level-gs1-segments/">
                    <validation-errors for="conditionId" aliasTo="gs1Segment"></validation-errors>
                </lxm-select>
                <lxm-select select
                    *ngSwitchCase="ClientDiscountRuleType.Brand"
                    [readonly]="!edit"
                    [searchable]="true"
                    labelField="value"
                    for="brand"
                    url="api/clvac/brands/">
                    <validation-errors for="conditionId" aliasTo="brand"></validation-errors>
                </lxm-select>
                <lxm-select select
                    *ngSwitchCase="ClientDiscountRuleType.StorageConditions"
                    [readonly]="!edit"
                    [searchable]="true"
                    for="storageCondition"
                    value="id"
                    labelField="translatedValue"
                    [isTranslated]="true"
                    [options]="formData.storageConditions">
                    <validation-errors for="conditionId" aliasTo="storageCondition"></validation-errors>
                </lxm-select>
            </form-field>
            
            <form-field dialog label="cards.clients.contract_conditions.dialog.label.description" [readonly]="!edit" for="description" type="textarea">
                <validation-errors for="description"></validation-errors>
            </form-field>

            <form-field dialog label="cards.clients.contract_conditions.dialog.label.condition" 
                for="discountPercent">
                <combined-input any for="discountPercent" [readonly]="!edit" inputType="text" class="dialog-input-fixed-width-1">
                    <span suffix>%</span>
                    <validation-errors for="discountPercent"></validation-errors>
                </combined-input>
            </form-field>

            <form-field dialog label="cards.clients.contract_conditions.dialog.label.status">
                <lxm-select select class="dialog-input-fixed-width-1"
                    [readonly]="!edit"
                    [options]="statusOptions"
                    [clearable]="false"
                    for="status" 
                    value="id">
                </lxm-select>
                <validation-errors for="status"></validation-errors>
            </form-field>

    </div>

    <div footer class="justify-between">

      <div class="flex flex-row">
        <lxm-button 
            *ngIf="edit"
            type="primary"
            label="action.save"
            (click)="save()"
            [loading]="saveLoading"
            buttonType="submit">
        </lxm-button>
        <lxm-button type="secondary"
                    class="ml10"
                    label="action.close"
                    (click)="closeDialog()">
        </lxm-button>
      </div>

      <div>
        <lxm-button *ngIf="ruleId && edit"
          type="transparent" 
          label="action.delete" 
          [loading]="deleteLoading"
          icon="trash" 
          class="ml30" 
          (click)="deleteRule()">
        </lxm-button>
      </div>

    </div>

</lxm-dialog>
