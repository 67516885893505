import { Component, ViewChild, OnInit, AfterViewInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserDialog } from "src/app/components/_partials/lxm-settings/users-table/dialogs/user.dialog";
import { UsersTableComponent } from "src/app/components/_partials/lxm-settings/users-table/users-table.component";
import { LxmDialog, LxmMessage } from "src/app/_helpers";
import { appSettings } from "src/app/app.settings";
import { UserService } from "src/app/_services";
import { forkJoin } from "rxjs";
import { InviteUserDialog } from "src/app/components/_partials/lxm-settings/users-table/dialogs/invite.dialog";
import { UserAction, UserStatus } from "src/app/enum";
import { AppState } from "src/app/state/app.state";
import { Location } from "@angular/common";

@Component({
  selector: "users-card",
  templateUrl: "./users.card.html",
  styleUrls: ["./users.scss"]
})
export class UsersCard {
  @ViewChild(UsersTableComponent, { static: false })
  private _usersTable: UsersTableComponent;

  public UserAction = UserAction;
  public isUsersSyncInProgress = false;

  constructor(
    public appState: AppState,
    private _userService: UserService,
    private _dialog: LxmDialog,
    private _route: ActivatedRoute,
    private _router: Router,
    private _message: LxmMessage
  ) {
    const { action } = this._route.snapshot.queryParams || {};

    if (action?.includes("addUser")) {
      this.openInviteDialog();
      this._router.navigate([]);
    }
  }

  ngAfterViewInit(): void {
    this._usersTable.onRowClick$.subscribe((row: any) => {
      if (row?.status == UserStatus.Pending) {
        this.openInviteDialog(row);
        return;
      }
      this.openUserDialog(row);
    });
  }

  public get hasAzureAdClientSettings() {
    return this._route.snapshot.data.formData.hasAzureAdClientSettings;
  }

  public reload() {
    this._usersTable.load();
  }

  public openInviteDialog(user?: any) {
    this._dialog.open(InviteUserDialog, {
      ...appSettings.DIALOG_SIZES.M,
      data: {
        user: user,
        usersCard: this
      },
      resolve: () => {
        const resolvers: any = {
          formData: this._userService.getUserDetailFormData()
        };

        return forkJoin(resolvers);
      }
    });
  }

  public openUserDialog(user?: any): void {
    this._dialog.open(UserDialog, {
      ...appSettings.DIALOG_SIZES.M,
      data: {
        userId: user?.id,
        usersCard: this
      },
      resolve: () => {
        const resolvers: any = {
          formData: this._userService.getUserDetailFormData(user?.id)
        };

        if (user) {
          resolvers.user = this._userService.getUserDetail(user.id);
        }

        return forkJoin(resolvers);
      }
    });
  }

  public syncAzureAdUsers() {
    this.isUsersSyncInProgress = true;

    this._userService.syncAzureUsers().result(
      null,
      res => {
        this._message.ok({
          message: "common.message.sync_success"
        });

        this.reload();
        this.isUsersSyncInProgress = false;
      },
      error => {
        this._message.error({
          messages: ["common.error.sync_failed", error.validationSummary]
        });

        this.isUsersSyncInProgress = false;
      }
    );
  }
}
