import {ElementRef, AfterViewInit, HostBinding, Component, Input, OnChanges, ChangeDetectorRef, SimpleChanges} from '@angular/core';
import { trigger, state, style, transition, sequence, animate } from '@angular/animations';

@Component({
  selector: 'expander',
  template: `
    <ng-content></ng-content>
  `,
  styles: [`
    :host {
      display: block;
    }
  `],
  animations: [
    trigger('expand', [

      state('true', style(
        {
          maxHeight: '*'
        }
      )),

      state('false', style(
        {
          maxHeight: 0,
          marginTop: 0,
          opacity: 0,
          paddingTop: 0,
          paddingBottom: 0,
          overflow: 'hidden'
        }
      )),

      transition('void <=> *', []),

      transition('false <=> true', [
        style({ maxHeight: '{{startHeight}}px', opacity: 1 }),
        sequence([
          style({overflow: 'hidden'}),
          animate('{{speed}}ms ease')
        ])
      ], { params: { startHeight: 0, speed: 250 } })

    ])
  ]
})
export class ExpanderComponent implements AfterViewInit, OnChanges {

  private viewInit = false;

  @HostBinding('class.expanded') @Input()
  trigger: boolean = true;

  startHeight: number;

  @Input()
  speed: number = 250;

  constructor(private element: ElementRef, private _cd: ChangeDetectorRef) {}  
  
  @HostBinding('@expand') get expand() {
    return {value: this.trigger, params: {startHeight: this.startHeight, speed: this.speed}};
  }

  setStartHeight() {
    this.startHeight = this.element.nativeElement.clientHeight;
  }

  ngAfterViewInit() {
    this.viewInit = true;
    this.setStartHeight();
  }

  ngOnChanges(changes: SimpleChanges) {
    const { trigger } = changes;

    if (trigger) {
      if (trigger.currentValue === false) {
        this._cd.detach();
      } else if (!trigger.firstChange) {
        this._cd.reattach();
        this._cd.detectChanges();
      }
    }

    if (this.viewInit) {
      this.setStartHeight();
    }
  }

}

