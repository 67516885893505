import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IPackageData } from '../models/settings/tenant-quota';

@Injectable()
export class PackageDataResolve implements Resolve<any> {

  constructor(private _http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot): Observable<IPackageData> {
    return this._http.get(`api/settings/packageData`) as Observable<IPackageData>;
  }
}
