export enum OfferProductDecisionStatus {
    Saved = 1,
    InProduction = 2,
    Expired = 3,
    Confirmed = 4,
    Canceled = 5,
    Exported = 6
}

(OfferProductDecisionStatus as any).__typeName = 'OfferProductDecisionStatus';
