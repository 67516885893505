export enum ProductChangeType {
  StatusChange = 1,
  CreatedProduct = 101,
  SavedExtraParameters = 102,
  SavedBaseInfo = 103,
  SavedAlcoholInfo = 104,
  SavedProductAttributes = 105,
  SavedExpiryInfo = 106,
  SavedSeafoodInfo = 107,
  SavedMarkingAndNutritionInfo = 108,
  SavedElectronicsInfo = 109,
  SavedMeatInfo = 110,
  SavedAdditionalInfo = 111,
  CreatedProductUnit = 112,
  UpdatedProductUnit = 113,
  DeletedProductUnit = 114,
  SavedMovieInfo = 115,
  SavedMusicInfo = 116,
  SavedBookInfo = 117,
  SavedGameInfo = 118,
  SavedMedicationInfo = 119,
  ProductsDataImported = 200
}

(ProductChangeType as any).__typeName = 'ProductChangeType';
