<div class="tab-content">
    <card-section>
        <span header-title>
            {{'cards.settings.product_management_settings.segments_tree.title' | translate}}
        </span>
        <div header-actions *requiredRights="[UserAction.ManageClvs]">
            <div class="flex">
              <lxm-button type="beige"
                          class="ml15"
                          icon="plus-dark"
                          label="cards.settings.product_management_settings.segments_tree.action.add_segment"
                          (click)="openSegmentDialog()">
              </lxm-button>
            </div>
        </div>
    </card-section>
</div>

<lxm-tree class="product-segments-tree" dataUrl="segments/list" [displayColumns]="displayColumns">
    <div lxmTreeColumnDef="code">
        <div *lxmTreeHeaderDef>
            {{'cards.settings.product_management_settings.segments_tree.table.code' | translate}}
        </div>
        <div *lxmTreeCellDef="let segment">{{segment?.code | formValue}}</div>
    </div>

    <div lxmTreeColumnDef="name">
        <div *lxmTreeHeaderDef>
            {{'cards.settings.product_management_settings.segments_tree.table.name' | translate}}
        </div>
        <div *lxmTreeCellDef="let segment">{{segment?.name | formValue}}</div>
    </div>

    <div lxmTreeColumnDef="description">
        <div *lxmTreeHeaderDef>
            {{'cards.settings.product_management_settings.segments_tree.table.description' | translate}}</div>
        <div *lxmTreeCellDef="let segment">{{segment.description | formValue}}</div>
    </div>

    <div lxmTreeColumnDef="canAssignToProduct">
        <div *lxmTreeHeaderDef>
            {{'cards.settings.product_management_settings.segments_tree.table.canAssignToProduct' | translate}}</div>
        <div *lxmTreeCellDef="let segment">{{segment.canAssignToProduct | translateEnum : Bool}}</div>
    </div>

    <div lxmTreeColumnDef="gpc">
      <div *lxmTreeHeaderDef class="nowrap">
          {{'cards.settings.product_management_settings.segments_tree.table.gpc' | translate}}</div>
      <div *lxmTreeCellDef="let segment" class="nowrap">
        <ng-container *ngIf="segment.canAssignToProduct">
          ({{ segment.mappedGpcSegments.length }})
        </ng-container>
      </div>
  </div>

    <div lxmTreeColumnDef="status">
        <div *lxmTreeHeaderDef>
            {{'cards.settings.product_management_settings.segments_tree.table.status' | translate}}
        </div>
        <div *lxmTreeCellDef="let segment">{{segment.status | translateEnum : Status}}</div>
    </div>

    <div lxmTreeColumnDef="actions">
        <div *lxmTreeHeaderDef></div>
        <div *lxmTreeCellDef="let segment">
            <div class="flex" *requiredRights="[UserAction.ManageClvs]">
              <div style="width: 41px;">
                <lxm-button type="beige" *ngIf="!segment.canAssignToProduct"
                            icon="plus-dark"
                            title="cards.settings.product_management_settings.segments_tree.action.add_child_segment"
                            (click)="$event.stopPropagation(); openSegmentDialog(null, segment)">
                </lxm-button>
              </div>
              <div class="ml15" style="width: 41px;">
                <lxm-button type="beige"
                            icon="edit"
                            title="cards.settings.product_management_settings.segments_tree.action.edit_segment"
                            (click)="$event.stopPropagation(); openSegmentDialog(segment)">
                </lxm-button>
              </div>
            </div>
        </div>
    </div>
</lxm-tree>
