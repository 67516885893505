import { Directive, ElementRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserAction } from '../enum';
import { AuthenticationService } from '../_services';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[showPrices]'
})
export class ShowPricesDirective {

  constructor(
    private elementRef: ElementRef,
    private _authService: AuthenticationService,
    private _translateService: TranslateService) {
  }

  private _showPrices = false;

  private _replaceTemplate = `<span>${this._translateService.instant('global.hidden')}</span>`

  @Input()
  set showPrices(actions: UserAction[]) {
    const hasRight = this._authService.hasRight(actions);
    this._showPrices = hasRight;
  }

  ngOnInit() {
    if (!this._showPrices) {
      this.elementRef.nativeElement.innerHTML = this._replaceTemplate;
    }
  }

}
