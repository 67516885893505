import { Component } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { SettingsService } from 'src/app/_services';
import { LxmMessage } from 'src/app/_helpers';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { debug } from 'console';

@Component({
  selector: "mail-settings-card",
  templateUrl: './mail-settings.card.html',
  styleUrls: ['./mail-settings.scss']
})
export class MailSettingsCardComponent {

  public saveLoading = false;
  public testLoading = false;

  public form: FormGroup;

  public canEditSendFromUserEmail = false;
  public passwordChanged = false;
  private pwdMask: string;

  constructor(
    route: ActivatedRoute,
    private _message: LxmMessage,
    private _settingsService: SettingsService) {

      const card = route.snapshot.data.mailSettingsCard;

      if (!card.withCredentials) {
        this.canEditSendFromUserEmail = false;
        card.sendFromUserEmail = true;
      }
      
      this.pwdMask = card.isPasswordSet ? "*" : null;
      this.form = new FormGroup({
        useCustomSmtp: new FormControl(card.useCustomSmtp),
        host: new FormControl(card.host),
        port: new FormControl(card.port),
        useStartTls: new FormControl(card.useStartTls),
        enableSsl: new FormControl(card.enableSsl),
        sendFromUserEmail: new FormControl(card.sendFromUserEmail),
        withCredentials: new FormControl(card.withCredentials),
        user: new FormControl(card.user),
        password: new FormControl(this.pwdMask),
      });

      this.form.get('withCredentials').valueChanges.subscribe(x => {
        if (x) {
          this.canEditSendFromUserEmail = true;
        }
        else {
          this.form.get('sendFromUserEmail').setValue(true);
          this.canEditSendFromUserEmail = false;
        }
      })
      
      this.form.get('password').valueChanges.subscribe(x => {
        if (x && (this.pwdMask == null || x != this.pwdMask)) {
          this.passwordChanged = true;
        } else {
          this.passwordChanged = false;
        }
      })
  }

  public get useCustomSmtp() {
    return this.form.value.useCustomSmtp;
  }

  public test() {

    this.testLoading = true

    if (!this.passwordChanged) {
      this.form.value.password = null;
    }
    
    this._settingsService.testSmtpSettings(this.form.value)
      .pipe(
        finalize(() => {
          this.testLoading = false;
        }
      ))
      .result(this.form, () => {
        this._message.ok({
          message: 'cards.lexi_market_settings.mail_settings.test_mail_sent_successful'
        });
      }, () => {
        this._message.error({
          message: 'cards.lexi_market_settings.mail_settings.sending_test_mail_failed'
        });
      });
  }

  public save() {

    this.saveLoading = true;

    if (!this.passwordChanged) {
      this.form.value.password = null;
    }
    
    this._settingsService.saveSmtpSettings(this.form.value)
      .pipe(
        finalize(() => {
          this.saveLoading = false;
        }
      ))
      .result(this.form, () => {
        this._message.ok({
          message: 'cards.lexi_market_settings.mail_settings.save_successful'
        });
      }, () => {
        this._message.error({
          message: 'cards.lexi_market_settings.mail_settings.save_failed'
        });
      });
  }
}
