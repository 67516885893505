import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  ISearchResponse, IOffersSupplierListItem, IAttachment, IOffersRetailerListItem,
  IOfferCardProcurementDetail, ICampaignTypeDto, IOfferCardCalendarCampaignDetail
} from '../models';
import { SupplierOfferStatus, ReceivingOfferStatus, label, OfferRequestStatus, ProductPriceSchemeType } from '../enum';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup } from '@angular/forms';
import { AppState } from '../state/app.state';

@Injectable()
export class CreateOfferDataService {
  public productIds: string[];
}

@Injectable()
export class OffersSearchDataService {

  constructor(private _state: AppState) { }

  public sellerSearchData = new FormGroup({
    searchTerm: new FormControl(),
    dateFrom: new FormControl(),
    dateTo: new FormControl(),
    offerType: new FormControl(),
    retailers: new FormControl(),
    recipients: new FormControl(),
    offerStatus: new FormControl(),
    offerOwner: new FormControl([this._state.user.id]),
    showInactiveUsers: new FormControl()
  });

  public buyerSearchData = new FormGroup({
    searchTerm: new FormControl(),
    dateFrom: new FormControl(),
    dateTo: new FormControl(),
    offerType: new FormControl(),
    suppliers: new FormControl(),
    recipients: new FormControl(),
    showInactiveRecipients: new FormControl(),
    offerStatus: new FormControl(),
  })

  public serializeBuyerSearchData() {
    const searchData = this.buyerSearchData.value;
    return {
      searchTerm: searchData.searchTerm,
      createdAtFrom: searchData?.dateFrom?.format(),
      createdAtTo: searchData?.dateTo?.format(),
      supplierIds: searchData?.suppliers?.map((x: any) => x.id),
      recipientIds: searchData?.recipients?.map((x: any) => x.id),
      offerStatusIds: searchData?.offerStatus?.map((x: any) => x.id),
      offerTypeId: searchData?.offerType?.id,
    };
  }

  public serializeSellerSearchData() {
    const searchData = this.sellerSearchData.value;
    return {
      searchTerm: searchData.searchTerm,
      createdAtFrom: searchData?.dateFrom,
      createdAtTo: searchData?.dateTo,
      retailerIds: searchData?.retailers,
      offerStatusIds: searchData?.offerStatus,
      offerOwnerIds: searchData?.offerOwner,
      offerTypeId: searchData?.offerType,
      recipientIds: searchData?.recipients
    };
  }


}

@Injectable({ providedIn: 'root' })
export class OfferService {

  constructor(private _http: HttpClient) { }

  public setOwner(offerId: string, ownerId: string) {
    return this._http.post<any>(`api/offers/${offerId}/owner`, { ownerId });
  }

  public getSendingOfferStatusProgress(status: SupplierOfferStatus) {
    return label(SupplierOfferStatus, status);
  }

  public getReceivingOfferStatusProgress(status: ReceivingOfferStatus) {
    return label(ReceivingOfferStatus, status);
  }

  public getOfferRequestStatusProgress(status: OfferRequestStatus) {
    return label(OfferRequestStatus, status);
  }

  public listForSupplier(data: any = {}) {
    return this._http.post<ISearchResponse<IOffersSupplierListItem>>(`api/offers/forSupplier`, data);
  }

  public listForRetailer(data: any = {}) {
    return this._http.post<ISearchResponse<IOffersRetailerListItem>>(`api/offers/forRetailer`, data);
  }

  public createOffer(data: any) {
    return this._http.post<any>(`api/offers`, data);
  }

  public saveOffer(offerId: string, data: any) {
    return this._http.post<any>(`api/offers/${offerId}`, data);
  }

  public saveReceivingOffer(offerId: string, data: any) {
    return this._http.post<any>(`api/offers/receivingOffer/${offerId}`, data);
  }

  public createOfferRequest(data: any) {
    return this._http.post<any>(`api/offers/request`, data);
  }

  public saveOfferRequest(offerId: string, data: any) {
    return this._http.post<any>(`api/offers/request/${offerId}`, data);
  }

  public sendOfferRequest(offerId: string) {
    return this._http.post<any>(`api/offers/request/${offerId}/send`, {});
  }

  public validateOfferProductCampaignInfo(data: any) {
    return this._http.post<any>(`api/offers/validateOfferProductCampaignInfo/`, data);
  }

  public sendOffer(offerId: string) {
    return this._http.post<any>(`api/offers/${offerId}/send`, {});
  }

  public sendOfferProposals(offerId: string) {
    return this._http.post<any>(`api/offers/receivingOffer/${offerId}/send`, {});
  }

  public addAttachment(offerId: string, data: any) {
    return this._http.post<IAttachment>(`api/offers/${offerId}/attachments`, data);
  }

  public saveAttachment(offerId: string, attachmentId: string, data: any) {
    return this._http.post<IAttachment>(`api/offers/${offerId}/attachments/${attachmentId}`, data);
  }

  public removeAttachment(offerId: string, attachmentId: string) {
    return this._http.delete<void>(`api/offers/${offerId}/attachments/${attachmentId}`);
  }

  public downloadAttachment(offerId: string, attachmentId: string) {
    document.location.href = environment.apiUrl + `api/offers/${offerId}/attachments/${attachmentId}/download`;
  }

  public deleteOffer(offerId: string) {
    return this._http.delete<any>(`api/offers/${offerId}`, {});
  }

  public acceptReceivingOffer(offerId: string) {
    return this._http.post<any>(`api/offers/${offerId}/acceptReceivingOffer`, {});
  }

  public acceptSupplierOffer(offerId: string) {
    return this._http.post<any>(`api/offers/${offerId}/acceptSupplierOffer`, {});
  }

  public rejectOffer(offerId: string) {
    return this._http.post<any>(`api/offers/${offerId}/reject`, {});
  }

  public comment(offerId: string, comment: string) {
    return this._http.post<any>(`api/offers/${offerId}/comment`, {
      comment: comment
    });
  }

  public updatePrices(offerId: string) {
    return this._http.post<any>(`api/offers/${offerId}/updatePrices`, {});
  }

  public productsWithDisplay(productIds: string[], retailerId: string) {
    return this._http.post<{ [index: string]: boolean }>(`api/offers/productsWithDisplay`, {
      productIds: productIds,
      retailerId: retailerId
    });
  }

  public logisticsData(productIds: string[], retailerId: string) {
    return this._http.post<{ [index: string]: any }>(`api/offers/logisticsData`, {
      productIds: productIds,
      retailerId: retailerId
    });
  }

  public getOfferProductRelations(productIds: string[], date: Date, offerId?: string, retailerId?: string) {
    return this._http.post<any>(`api/offers/offerProductRelations`, {
      productIds: productIds,
      retailerId: retailerId,
      date: date,
      offerId: offerId
    });
  }

  public getActiveProcurements(retailerId: string) {
    return this._http.get<IOfferCardProcurementDetail[]>(`api/offers/procurements/${retailerId}`);
  }

  public getActiveCampaigns(retailerId: string) {
    return this._http.get<IRetailerActiveCalendarCampaignsDto>(`api/offers/campaigns/${retailerId}`);
  }

  // RETAILER

  public exportXls(offerId: string) {
    return this._http.get<IOfferExportResult>(`api/offers/${offerId}/exportxls`);
  }

  public exportEdiPricelist(offerId: string) {
    return this._http.get<IOfferExportResult>(`api/offers/${offerId}/exportPriceList`);
  }

  public exportMultipleOffersXls(offerIds: string[]) {
    const req = {
      offerIds: offerIds
    };
    return this._http.post<IOfferExportResult>(`api/offers/exportxls`, req);
  }

  public downloadXls(fileId: string) {
    // very bad for bigger files, as the whole blob 
    // is cached to browser memory instead of directly streaming the download to disk
    return this._http.get(`api/offers/downloadxls/${fileId}`, {
      responseType: 'blob'
    });
  }

  public downloadPdf(fileId: string) {
    // very bad for bigger files, as the whole blob 
    // is cached to browser memory instead of directly streaming the download to disk
    return this._http.get(`api/offers/downloadPdf/${fileId}`, {
      responseType: 'blob'
    });
  }

  public downloadXml(fileId: string) {
    // very bad for bigger files, as the whole blob 
    // is cached to browser memory instead of directly streaming the download to disk
    return this._http.get(`api/offers/downloadXml/${fileId}`, {
      responseType: 'blob'
    });
  }

  public exportNewItemProposal(offerId: string) {
    return this._http.get<IOfferExportResult>(`api/offers/${offerId}/exportNewItemProposal`);
  }

  public exportProductImages(offerId: string) {
    return this._http.get<IProductImagesExportResult>(`api/offers/${offerId}/exportProductImages`);
  }

  public downloadProductImages(fileId: string) {
    return this._http.get(`api/offers/downloadProductImages/${fileId}`, {
      responseType: 'blob'
    });
  }

  public getOfferProductRetailerAssortmentDialogFormData(offerId: string, offerProductId: string, data: any = {}) {
    // return this._http.post(`api/offers/${offerId}/offerProduct/${offerProductId}/retailerAssortment/formData`, data);
    return this._http.post(`api/offers/${offerId}/offerProduct/${offerProductId}/retailerSupplierPriceAndAssortment/formData/`, data);
  }

  public getOfferProductRetailerAssortmentScheme(offerId: string, offerProductId: string, assortmentId?: string) {
    return this._http.get(`api/offers/${offerId}/offerProduct/${offerProductId}/retailerAssortment/${assortmentId}`);
  }

  public getOfferProductRetailerAssortmentAndPriceScheme(offerId: string, offerProductId: string, req: any = {}) {
    return this._http.post<any>(`api/offers/${offerId}/offerProduct/${offerProductId}/retailerPriceAndAssortment`, req);
  }

  public createOfferProductRetailerAssortmentScheme(offerId: string, offerProductId: string, req: ISaveRetailerAssortmentReq) {
    return this._http.post(`api/offers/${offerId}/offerProduct/${offerProductId}/retailerAssortment`, req);
  }

  public updateOfferProductRetailerAssortmentScheme(offerId: string, offerProductId: string, assortmentId: string, req: ISaveRetailerAssortmentReq) {
    return this._http.post(`api/offers/${offerId}/offerProduct/${offerProductId}/retailerAssortment/${assortmentId}`, req);
  }

  public getOfferProductRetailerAssortmentAndPriceSchemeFormData(offerId: string, offerProductId: string, data: any = {}) {
    return this._http.post(`api/offers/${offerId}/offerProduct/${offerProductId}/retailerSupplierPriceAndAssortment/formData/`, data);
  }

  public updateOfferProductRetailerAssortmentAndPriceScheme(offerId: string, offerProductId: string, schemeId: string, assortmentId: string, req: IUpdateOfferProductRetailerAssortmentAndPriceScheme) {
    if (assortmentId == null) {
      return this._http.post<any>(`api/offers/${offerId}/offerProduct/${offerProductId}/priceScheme/${schemeId}`, req);
    }
    return this._http.post<any>(`api/offers/${offerId}/offerProduct/${offerProductId}/assortment/${assortmentId}/priceScheme/${schemeId}`, req);
  }

  public createOfferProductRetailerAssortmentAndPriceScheme(offerId: string, offerProductId: string, req: ICreateOfferProductRetailerAssortmentAndPriceScheme) {
    return this._http.post<any>(`api/offers/${offerId}/offerProduct/${offerProductId}/newRetailerPriceAndAssortment`, req);
  }

  // public updateOfferProductRetailerAssortmentScheme(offerId: string, offerProductId: string, assortmentId: string, req: ISaveRetailerAssortmentReq) {
  //   return this._http.post(`api/offers/${offerId}/offerProduct/${offerProductId}/retailerAssortment/${assortmentId}`, req);
  // }

  // public deleteOfferProductRetailerAssortmentScheme(offerId: string, offerProductId: string, assortmentId: string) {
  //   return this._http.delete(`api/offers/${offerId}/offerProduct/${offerProductId}/retailerAssortment/${assortmentId}`);
  // }

  public deleteOfferProductRetailerAssortmentScheme(offerId: string, offerProductId: string, assortmentId: string, schemeId: string) {
    return this._http.delete(`api/offers/${offerId}/offerProduct/${offerProductId}/assortment/${assortmentId}/priceScheme/${schemeId}`);
  }

  public validateOfferProductRetailerDetails(data: any) {
    return this._http.post<any>(`api/offers/validateOfferProductRetailerDetails/`, data);
  }

  // public getOfferProductRetailerSupplierPriceSchemeDialogFormData(offerId: string, offerProductId: string, schemeId?: string) {
  //   return this._http.get(`api/offers/${offerId}/offerProduct/${offerProductId}/retailerSupplierPrices/formData/${schemeId || ""}`);
  // }


  public getOfferProductRetailerSupplierPriceScheme(offerId: string, offerProductId: string, schemeId?: string) {
    return this._http.get(`api/offers/${offerId}/offerProduct/${offerProductId}/retailerSupplierPrices/${schemeId}`);
  }

  public createOfferProductRetailerSupplierPriceScheme(offerId: string, offerProductId: string, data: any) {
    return this._http.post<any>(`api/offers/${offerId}/offerProduct/${offerProductId}/retailerSupplierPrices`, data);
  }

  public updateOfferProductRetailerSupplierPriceScheme(offerId: string, offerProductId: string, schemeId: string, data: any) {
    return this._http.post<any>(`api/offers/${offerId}/offerProduct/${offerProductId}/retailerSupplierPrices/${schemeId}`, data);
  }

  // public deleteOfferProductRetailerSupplierPriceScheme(offerId: string, offerProductId: string, schemeId: string) {
  //   return this._http.delete<string>(`api/offers/${offerId}/offerProduct/${offerProductId}/retailerSupplierPrices/${schemeId}`);
  // }

  public deleteOfferProductRetailerSupplierPriceScheme(offerId: string, offerProductId: string, assortmentId: string, schemeId: string) {
    return this._http.delete<string>(`api/offers/${offerId}/offerProduct/${offerProductId}/assortment/${assortmentId}/priceScheme/${schemeId}`);
  }

  // PDF

  public exportPdf(offerId: string) {
    return this._http.get<any>(`api/offers/${offerId}/exportPdf`);
  }

  // IMPORT

  public getOfferImportFormData() {
    return this._http.get(`api/offers/import/formData`);
  }

  public offerImport(data: any) {
    return this._http.post<any>(`api/import/customOffer`, data);
  }

  public exportOfferImportXls(offerId: string) {
    return this._http.get<IOfferExportResult>(`api/offers/${offerId}/exportOfferImportXls`);
  }

  public exportFruitsVegetablesOfferImportXls(offerId: string) {
    return this._http.get<IOfferExportResult>(`api/offers/${offerId}/exportFruitsVegetablesOfferImportXls`);
  }

}

export interface IOfferExportResult {
  id: string;
  fileName: string;
  contentType: string;
}

export interface IProductImagesExportResult {
  id: string;
  fileName: string;
  contentType: string;
}

interface ISaveRetailerAssortmentReq {
  validFrom?: any;
  validTo?: any;
  assortmentGroupTagId?: string;
  productPayment?: any;
  productPaymentSupplierId?: any;
  priceSchemeValidFrom?: Date;
  status: string;
  assortmentGroups: string[];
  offerProductDecisionId?: string;
}

export interface ICreateOfferProductRetailerAssortmentAndPriceScheme {
  offerProductId: string;
  offerId: string;
  priceScheme: {
    offerId: string;
    offerProductId: string;
    retailerSupplierId: string;
    logisticsPartnerId: string;
    priceSchemeValidFrom: Date;
    validTo?: Date;
    vatId: string;
    listPrice: number;
    retailPrices: [
      {
        price: number;
        priceWithoutVat: number;
        currencyId: string;
      }
    ],
    priceGroups: [
      {
        priceGroupId: string;
        retailPrices: [
          {
            price: number;
            priceWithoutVat: number;
            currencyId: string;
          }
        ]
      }
    ],
    buyInPrice: number;
    buyInPriceCurrencyId: string;
    buyInPriceChangedByRetailer: boolean;
    schemeType: ProductPriceSchemeType
  },
  assortment: {
    offerId: string;
    offerProductId: string;
    assortmentSchemeValidFrom: Date,
    status: string;
    assortmentGroups: string[];
    assortmentGroupTagId: string;
    offerProductDecisionId: string;
    productPayment: number;
    productPaymentSupplierId: string;
    productPaymentCurrencyId: string;
  }
}

export interface IUpdateOfferProductRetailerAssortmentAndPriceScheme {
  offerId: string;
  offerProductId: string;
  priceSchemeId: string;
  assortmentId: string;
  priceScheme: {
    schemeId: string;
    offerId: string;
    offerProductId: string;
    retailerSupplierId: string;
    logisticsPartnerId: string;
    priceSchemeValidFrom: Date;
    validTo?: Date;
    vatId: string;
    listPrice: number;
    retailPrices: [
      {
        price: number;
        priceWithoutVat: number;
        currencyId: string;
      }
    ],
    priceGroups: [
      {
        priceGroupId: string;
        retailPrices: [
          {
            price: number;
            priceWithoutVat: number;
            currencyId: string;
          }
        ]
      }
    ],
    buyInPrice: number;
    buyInPriceCurrencyId: string;
    buyInPriceChangedByRetailer: boolean;
    schemeType: ProductPriceSchemeType
  },
  assortment: {
    retailerProductAssortmentId: string;
    offerId: string;
    offerProductId: string;
    assortmentSchemeValidFrom: Date,
    status: string;
    assortmentGroups: string[];
    assortmentGroupTagId: string;
    offerProductDecisionId: string;
    productPayment: number;
    productPaymentSupplierId: string;
    productPaymentCurrencyId: string;
  }
}

export interface IRetailerActiveCalendarCampaignsDto {
  retailerCampaignTypes: ICampaignTypeDto[];
  activeCalendarCampaigns: IOfferCardCalendarCampaignDetail[];
}