import { Directive, HostListener } from "@angular/core";
import { DialogComponent } from "../components";
import { LxmDialog } from "../_helpers";
import { SubscriptionRestrictedDialogComponent } from "../dialogs/subscription-restricted/subscription-restricted.dialog";
import { AppState } from "../state/app.state";
import { SubscriptionPlan } from "../enum/subscription-plan";

@Directive({
  selector: "[subscription-restricted]"
})
export class SubscriptionRestrictedDirective {
  constructor(
    private _dialog: LxmDialog,
    private _appState: AppState
  ) {}

  @HostListener("click") public onClick() {
    if (!this._appState.hasSubscription) {
      this._dialog.open(SubscriptionRestrictedDialogComponent, {
        width: "auto"
      });
    }
  }
}
