<data-hover [icon]="icon" background="grey" trigger="click">
  <div class="popover-container procurement-details-popover">
    <div class="popover-section">
      <div class="popover-section-header">
        <span class="product-procurement-icon"></span>
        <span class="popopver-section-header-text ml10"
          >{{procurement?.supplyFrom | formDate}} - {{procurement?.supplyTo | formDate}}</span
        >
      </div>
      <div class="popover-section-content">
        <div class="popover-section-column">
          <span class="popover-section-column-header">
            {{ "cards.products.product_pricelist_retailer_supplier.table.info.procurement_name" | translate }}
          </span>
          <span>
            {{ procurement?.name || '-' }}
          </span>
        </div>
        <div class="popover-section-column">
          <span class="popover-section-column-header">
            {{ "cards.products.product_pricelist_retailer_supplier.table.info.fulfillment_time" | translate }}
          </span>
          <span>
            {{ procurement?.fullfillmentTime || '-' }}
          </span>
        </div>
        <div class="popover-section-column">
          <span class="popover-section-column-header">
            {{ "cards.products.product_pricelist_retailer_supplier.table.info.supply_conditions" | translate }}
          </span>
          <span>
            {{ procurement?.supplyConditions || '-' }}
          </span>
        </div>
        <div class="popover-section-column">
          <span class="popover-section-column-header">
            {{ "cards.products.product_pricelist_retailer_supplier.table.info.note" | translate }}
          </span>
          <span style="max-height: 200px; overflow: auto;">
            {{ procurement?.notes || '-' }}
          </span>
        </div>
      </div>
    </div>
  </div>
</data-hover>