export class HttpBadRequestResponse {
  public message: string;
  public exception: string;
  public validationSummary: string;
  public args: any[];
  public validationResults:  { [id: string]: IErrorValue[] };
}

export class HttpNotFoundResponse {

}

export class HttpUnauthorizedResponse {
  public validationSummary: string;
  public args: any[];
  public validationResults:  { [id: string]: IErrorValue[] };
}

export interface IErrorValue {
  value: string;
  args: any;
}
