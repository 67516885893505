import { Component, Inject, Optional } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: "offer-product-order-detail",
  host: { 'class': 'dialog' },
  templateUrl: './offer-product-order-detail.dialog.html',
  styleUrls: ['./offer-product-order-detail.dialog.scss']
})
export class OfferProductOrderDetailDialogComponent {

  public form: FormGroup;
  public readonly = false;

  public product: any;

  public orderUnitOptions = [];

  constructor(
    public dialogRef: MatDialogRef<OfferProductOrderDetailDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.readonly = data.readonly;
    this.product = data.product;
    this.orderUnitOptions = data.product?.orderDetails?.orderUnits || [];

    this.form = new FormGroup({
      canOrder: new FormControl(this.product.orderDetails?.canOrder ?? true),
      minOrderUnits: new FormControl(this.product.orderDetails?.minOrderUnits),
      minPrice: new FormControl(this.product?.orderDetails?.minPrice),
      orderUnitId: new FormControl(this.readonly ? data.product.orderDetails?.orderUnitId : (this.product.orderDetails?.orderUnitId ?? this.orderUnitOptions[0]?.id))
    });
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public save(): void {
    this.closeDialog(this.form.value);
  }

  public closeDialog(data?: any): void {
    this.dialogRef.close(data);
  }
}
