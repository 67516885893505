import { Component, OnInit, OnChanges, Input, Optional } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { IClvDto } from 'src/app/cards/product/product-info/product-info.model';
import { EditableListBase } from '../editable-list-base';

@Component({
  selector: 'movie-audio-list',
  templateUrl: './movie-audio-list.component.html',
  styleUrls: ['./movie-audio-list.component.scss']
})

export class MovieAudioListComponent extends EditableListBase implements OnInit, OnChanges {

  @Input() public languageOptions: IClvDto[];
  @Input() public movieSubtitleTypeOptions: IClvDto[];

  protected get _defaultItem(): any {
    return {
      id: null,
      language: null,
      typeId: null,
      isDeaf:false,
      isDeleted: false,
      isNew: true
    };
  }

  public table = {
    columns: ['language_column', 'type_column', 'deaf_column', 'remove_column']
  }

  public get columns(): string[] {
    return this.table.columns;
  }

  constructor(@Optional() controlContainer: ControlContainer) {
    super(controlContainer);
  }

  protected _getNormalizedItem(item: any) {
    return {
      id: item.id,
      language: item.language,
      typeId: item.type?.id || item.typeId,
      isDeaf:item.isDeaf || false,
      isDeleted: item.isDeleted || false,
      isNew: item.isNew || false
    };
  }

}
