<div class="package-info-wrapper" [ngSwitch]="data.type">

  <span class="package-info-header">
    <span class="package-title">Lexi.Market {{data.type}}</span>
    <div class="package-price-wrapper ml20" [class.annual]="period == 'annual'">
      <div class="package-price">
        <span class="package-price-value">
          {{data.price[period] | priceValue:0 }}
        </span>
        <span class="package-price-period">
          / {{ 'cards.lexi_market_settings.lxm_settings_subscription.label.month' | translate }}
        </span>
      </div>
      <span class="package-price-detail">
        {{ 'cards.lexi_market_settings.lxm_settings_subscription.subscription_cards.label.billed_annually' | translate }}
      </span>
    </div>



  </span>

  <div class="package-info-content">
    <ng-container *ngSwitchCase="'XL'">
      <span class="subscription-text">
        <span class="check-small-icon mr8"></span>
        {{'cards.lexi_market_settings.lxm_settings_subscription.subscription_cards.subscription_xl.text.detail1' | translate}}
      </span>
      <span class="subscription-text">
        <span class="check-small-icon mr8"></span>
        {{'cards.lexi_market_settings.lxm_settings_subscription.subscription_cards.subscription_xl.text.detail2' | translate}}
      </span>
      <span class="subscription-text">
        <span class="check-small-icon mr8"></span>
        {{'cards.lexi_market_settings.lxm_settings_subscription.subscription_cards.subscription_xl.text.detail3' | translate}}
      </span>
    </ng-container>
    <ng-container *ngSwitchCase="'L'">
      <span class="subscription-text">
        <span class="check-small-icon mr8"></span>
        {{'cards.lexi_market_settings.lxm_settings_subscription.subscription_cards.subscription_l.text.detail1' | translate}}
      </span>
      <span class="subscription-text">
        <span class="check-small-icon mr8"></span>
        {{'cards.lexi_market_settings.lxm_settings_subscription.subscription_cards.subscription_l.text.detail2' | translate}}
      </span>
      <span class="subscription-text">
        <span class="check-small-icon mr8"></span>
        {{'cards.lexi_market_settings.lxm_settings_subscription.subscription_cards.subscription_l.text.detail3' | translate}}
      </span>
    </ng-container>
    <ng-container *ngSwitchCase="'M'">
      <span class="subscription-text">
        <span class="check-small-icon mr8"></span>
        {{'cards.lexi_market_settings.lxm_settings_subscription.subscription_cards.subscription_m.text.detail1' | translate}}
      </span>
      <span class="subscription-text">
        <span class="check-small-icon mr8"></span>
        {{'cards.lexi_market_settings.lxm_settings_subscription.subscription_cards.subscription_m.text.detail2' | translate}}
      </span>
      <span class="subscription-text">
        <span class="check-small-icon mr8"></span>
        {{'cards.lexi_market_settings.lxm_settings_subscription.subscription_cards.subscription_m.text.detail3' | translate}}
      </span>
    </ng-container>
    <ng-container *ngSwitchCase="'S'">
      <span class="subscription-text">
        <span class="check-small-icon mr8"></span>
        {{'cards.lexi_market_settings.lxm_settings_subscription.subscription_cards.subscription_s.text.detail1' | translate}}
      </span>
      <span class="subscription-text">
        <span class="check-small-icon mr8"></span>
        {{'cards.lexi_market_settings.lxm_settings_subscription.subscription_cards.subscription_s.text.detail2' | translate}}
      </span>
      <span class="subscription-text">
        <span class="check-small-icon mr8"></span>
        {{'cards.lexi_market_settings.lxm_settings_subscription.subscription_cards.subscription_s.text.detail3' | translate}}
      </span>
    </ng-container>
    <ng-container *ngSwitchCase="'XS'">
      <span class="subscription-text">
        <span class="check-small-icon mr8"></span>
        {{'cards.lexi_market_settings.lxm_settings_subscription.subscription_cards.subscription_xs.text.detail1' | translate}}
      </span>
      <span class="subscription-text">
        <span class="check-small-icon mr8"></span>
        {{'cards.lexi_market_settings.lxm_settings_subscription.subscription_cards.subscription_xs.text.detail2' | translate}}
      </span>
    </ng-container>
    <ng-container *ngSwitchCase="'XXS'">
      <span class="subscription-text">
        <span class="check-small-icon mr8"></span>
        {{'cards.lexi_market_settings.lxm_settings_subscription.subscription_cards.subscription_xxs.text.detail1' | translate}}
      </span>
      <span class="subscription-text">
        <span class="check-small-icon mr8"></span>
        {{'cards.lexi_market_settings.lxm_settings_subscription.subscription_cards.subscription_xxs.text.detail2' | translate}}
      </span>
    </ng-container>
  </div>

  <div class="package-info-footer mt15">
    <ng-container *ngIf="isActive && data.validTo">
      <span class="subscription-valid">
        <!-- {{'cards.lexi_market_settings.lxm_settings_subscription.subscription_cards.subscription_l.action.subscribe.text' | translate}}
        {{data.validTo | formDate}} -->
        
      </span>
    </ng-container>
    <div class="row align-center">
      <div class="f-1">
        <ng-container *ngIf="!isActive">
          <lxm-button type="orange"
                      label="cards.lexi_market_settings.lxm_settings_subscription.subscription_cards.label.subscribe.title"
                      (click)="choosePackage()">
          </lxm-button>
        </ng-container>
        <ng-container *ngIf="isActive">
          <span class="package-active">
            <span class="check-green-small-icon mr8"></span>
              {{'cards.lexi_market_settings.lxm_settings_subscription.subscription_cards.label.subscribe.title' | translate}}
          </span>
        </ng-container>
      </div>
      <div *ngIf="isRequested" class="in-progress">
          {{ 'cards.lexi_market_settings.lxm_settings_subscription.subscription_cards.label.in_progress' | translate }}
      </div>
    </div>
  </div>

</div>
