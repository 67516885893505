import { Injectable } from '@angular/core';
import { TranslateService} from '@ngx-translate/core';
import { PICTOGRAMS } from '../config/constants';
import { ProductField } from '../enum/product-field';


@Injectable()
export class PictogramHelper {

  public getPictogramByProductField(productField: ProductField) {
    switch(productField) {
      case ProductField.SuitableForVegans:
        return PICTOGRAMS.suitableForVegans;
      case ProductField.GlutenFree:
        return PICTOGRAMS.glutenFree;
      case ProductField.LactoseFree:
        return PICTOGRAMS.lactoseFree;
      case ProductField.SugarFree:
        return PICTOGRAMS.sugarFree;
    }
    return null;
  }
}

