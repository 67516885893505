import { Component, OnInit } from '@angular/core';
import { TitleService } from 'src/app/_services/title.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { LocalStorage } from 'src/app/_helpers';
import { LOCAL_STORAGE_KEY } from 'src/app/config';

@Component({
  selector: 'market-layout',
  templateUrl: './market.layout.html',
  host: { 'class': 'content' },
  styleUrls: ['./market.layout.scss']
})
export class MarketLayout implements OnInit {

  public loading: boolean;
  public search: Observable<any>;
  public searchSubject = new BehaviorSubject<any>(null);

  public searchPanelLocalStorageKey = LOCAL_STORAGE_KEY.MARKET_SEARCH_PANEL_OPEN;
  public isSearchPanelOpen = false;

  constructor(
    private _titleService: TitleService
  ) {
    this.isSearchPanelOpen = LocalStorage.getValue(this.searchPanelLocalStorageKey);
    this.search = this.searchSubject.asObservable();
  }

  public ngOnInit() {
    this._titleService.setTitle('cards.market.title');
  }

}
