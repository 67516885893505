import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { DialogComponent } from 'src/app/components';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: "subscription-restricted-dialog",
  host: { class: "dialog lexi-dialog" },
  templateUrl: "./subscription-restricted.dialog.html",
  styleUrls: ["./subscription-restricted.dialog.scss"],
})
export class SubscriptionRestrictedDialogComponent implements AfterViewInit {
  @ViewChild("dialog", { static: true, read: DialogComponent })
  private _dialog: DialogComponent;

  constructor(
    public dialogRef: MatDialogRef<SubscriptionRestrictedDialogComponent>
  ) {}

  public ngAfterViewInit(): void {}

  // requiredRight: UserAction.ManageSettings, requiredModule: LxmAppModule.SellerPimExtensions

  public closeDialog() {
    this.dialogRef.close();
  }
}
