<div class="tab-content">
  <card-section>
    <span header-title>
      {{title | translate}}
    </span>
    <div header-actions *requiredRights="[UserAction.ManageBuyerAssortment]">
      <div class="flex">
        <lxm-button 
          *ngIf="canEdit"
          type="beige"
          class="ml15"
          label="cards.settings.clv.common.action.add_classifier"
          icon="plus-dark"
          (click)="openClassificatorValueDialog({classificatorId: classificatorId})">
        </lxm-button>
        <lxm-button type="beige"
                    class="ml15"
                    label="cards.settings.clv.common.action.export_data"
                    icon="export">
        </lxm-button>
      </div>
    </div>
  </card-section>
</div>

<div class="clv-table">

  <div class="loading-container" *ngIf="dataSource.loading$ | async">
    <div class="loading">
      {{'global.table.loading' | translate}}
    </div>
  </div>

  <mat-table [dataSource]="dataSource" matSort [@.disabled]="true">

    <!-- General fields -->

    <ng-container matColumnDef="code">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header data-table-filter-text>
          {{ 'cards.settings.clv.common.table.code' | translate }}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let clv">{{clv.code}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="value">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header data-table-filter-text>
          {{ 'cards.settings.clv.common.table.value' | translate }}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let clv">
        <ng-container *ngIf="clv.translatedValue">
          {{clv.translatedValue | translatedValue}}
        </ng-container>
        <ng-container *ngIf="!clv.translatedValue">
          {{clv.value}}
        </ng-container>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header data-table-filter-text>
          {{ 'cards.settings.clv.common.table.description' | translate }}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let clv">{{clv.description}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header data-table-filter-dropdown [options]="statuses">
          {{ 'cards.settings.clv.common.table.status' | translate }}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let clv">{{ clv.status | translateEnum : 'Status'}}</mat-cell>
   
    </ng-container>

    <ng-container matColumnDef="color">
      <mat-header-cell *matHeaderCellDef>
      </mat-header-cell>
      <mat-cell *matCellDef="let clv">
        <color-box [color]="clv.color" class="size-s"></color-box>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns" class="clv-table-head"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns" class="clv-table-row" (click)="openClassificatorValueDialog(row)"></mat-row>
  </mat-table>

  <data-table-footer
    [localStorageKey]="LOCAL_STORAGE_KEY"
    (paginator)="hookPaginator($event)"
  >
  </data-table-footer>

</div>

