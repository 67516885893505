import { Component } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ITenantQuota } from "src/app/models/settings/tenant-quota";
import { SettingsService } from "src/app/_services";
import { LxmMessage, LxmDialog } from "src/app/_helpers";
import { TermsDialogComponent } from "src/app/components/_partials/terms-and-conditions/terms.dialog";
import { appSettings } from "src/app/app.settings";
import { LINKS } from "src/app/config";
import { TranslateService } from "@ngx-translate/core";
import { SubscriptionChangeRequestedComponent } from "../change-requested/change-requested.dialog";

@Component({
  selector: "subscription-card",
  templateUrl: "./subscription.card.html",
  styleUrls: ["./subscription.card.scss"]
})
export class SubscriptionCard {
  public requestedPlanId: number;
  public requestedPlanAnnual: boolean;

  public currentPlan: any;

  public actionLoading = {} as any;

  public editPaymentDetails = false;

  public SubscriptionPackageItem = SubscriptionPackageItem;
  public SubscriptionPackageView = SubscriptionPackageView;

  public packageFeatures = {
    [SubscriptionPackageItem.ActiveProductCount]: {
      label: "Aktiivsete toodete arv"
    },
    [SubscriptionPackageItem.UnlimitedUsers]: {
      label: "Piiramatu arv kasutajaid"
    },
    [SubscriptionPackageItem.OffersToXRetailers]: {
      label: "Pakkumine üle 60 jaeketile"
    },
    [SubscriptionPackageItem.CRM]: {
      label: "Kliendihaldus (CRM)"
    },
    [SubscriptionPackageItem.PdfOffers]: {
      label: "Toodete pakkumine PDF vormingus"
    },
    [SubscriptionPackageItem.APIUsage]: {
      label: "API kasutamine"
    },
    [SubscriptionPackageItem.AITranslations]: {
      label: "AI põhine toodete tõlkimine"
    },
    [SubscriptionPackageItem.Declarations]: {
      label: "Vastavusdeklaratsioonide koostamine"
    }
  };

  public packageInfo = {
    // activePackageId: 0,
    packages: [
      // {
      //   id: SubscriptionPackage.XXS,
      //   type: "XXS",
      //   price: { annual: 15 },
      //   productCount: 5
      // },
      {
        id: SubscriptionPackage.XS,
        type: "XS",
        price: { annual: 45, monthly: 49 },
        productCount: 20,
        features: [
          SubscriptionPackageItem.ActiveProductCount,
          SubscriptionPackageItem.UnlimitedUsers,
          SubscriptionPackageItem.OffersToXRetailers,
          SubscriptionPackageItem.CRM
        ]
      },
      {
        id: SubscriptionPackage.S,
        type: "S",
        price: { annual: 137, monthly: 149 },
        productCount: 100,
        features: [
          SubscriptionPackageItem.ActiveProductCount,
          SubscriptionPackageItem.UnlimitedUsers,
          SubscriptionPackageItem.OffersToXRetailers,
          SubscriptionPackageItem.CRM,
          SubscriptionPackageItem.PdfOffers
        ]
      },
      {
        id: SubscriptionPackage.M,
        type: "M",
        price: { annual: 229, monthly: 249 },
        productCount: 300,
        features: [
          SubscriptionPackageItem.ActiveProductCount,
          SubscriptionPackageItem.UnlimitedUsers,
          SubscriptionPackageItem.OffersToXRetailers,
          SubscriptionPackageItem.CRM,
          SubscriptionPackageItem.PdfOffers
        ]
      },
      {
        id: SubscriptionPackage.L,
        type: "L",
        price: { annual: 320, monthly: 349 },
        productCount: 1000,
        features: [
          SubscriptionPackageItem.ActiveProductCount,
          SubscriptionPackageItem.UnlimitedUsers,
          SubscriptionPackageItem.OffersToXRetailers,
          SubscriptionPackageItem.CRM,
          SubscriptionPackageItem.PdfOffers,
          SubscriptionPackageItem.APIUsage,
          SubscriptionPackageItem.AITranslations,
          SubscriptionPackageItem.Declarations
        ]
      },
      {
        id: SubscriptionPackage.XL,
        type: "XL",
        price: { annual: 687, monthly: 749 },
        productCount: "Piiramatu",
        features: [
          SubscriptionPackageItem.ActiveProductCount,
          SubscriptionPackageItem.UnlimitedUsers,
          SubscriptionPackageItem.OffersToXRetailers,
          SubscriptionPackageItem.CRM,
          SubscriptionPackageItem.PdfOffers,
          SubscriptionPackageItem.APIUsage,
          SubscriptionPackageItem.AITranslations,
          SubscriptionPackageItem.Declarations
        ]
      }
    ]
  };

  public get packages() {
    return this.packageInfo.packages.filter(
      (x) => x.price[this.form.value.annual ? "annual" : "monthly"]
    );
  }

  public get productsActivePercent() {
    return this.productCapacity
      ? (this.productsActive / this.productCapacity) * 100
      : 0;
  }

  public form: FormGroup;
  public tenantQuota: ITenantQuota;
  public packageView = SubscriptionPackageView.Matrix;

  public paymentDetailsFg = new FormGroup({
    subscriberEmail: new FormControl(),
    billingEmail: new FormControl(),
    terms: new FormControl(false)
  });

  constructor(
    route: ActivatedRoute,
    private _message: LxmMessage,
    private _dialog: LxmDialog,
    private _translateService: TranslateService,
    private _settingsService: SettingsService
  ) {
    const tenantQuota = route.snapshot.data.packageData.quota as ITenantQuota;
    this.tenantQuota = tenantQuota;
    this.setPackage(tenantQuota.planId);

    this.requestedPlanId = route.snapshot.data.packageData.requestedPlan % 100;
    this.requestedPlanAnnual =
      route.snapshot.data.packageData.requestedPlan > 100;

    if (this.requestedPlanId) {
      this.form = new FormGroup({
        activePackageId: new FormControl(this.requestedPlanId),
        annual: new FormControl(this.requestedPlanAnnual)
      });
    } else {
      this.form = new FormGroup({
        activePackageId: new FormControl(tenantQuota.planId),
        annual: new FormControl(tenantQuota.annual)
      });
    }
  }

  public get activePackageId() {
    return this.currentPlan?.id;
  }

  public get activeTo() {
    return this.tenantQuota?.activeTo;
  }

  public get productsActive() {
    return this.tenantQuota.activeProductsCount;
  }

  public get productCapacity() {
    return this.tenantQuota?.maxActiveProducts;
  }

  public get currentAnnual() {
    return this.tenantQuota?.annual;
  }

  public pendingPackage: any;

  public setPackage(id) {
    this.currentPlan = this.packageInfo.packages.find((x) => x.id === id);
  }

  public choosePackage(id) {
    this.currentPlan = this.packageInfo.packages.find((x) => x.id === id);
  }

  public choosePackageAndSetPaymentDetails(packageId) {
    this.pendingPackage = this.getPackageById(packageId);
    this.packageView = SubscriptionPackageView.Details;
    // this.form.patchValue({ activePackageId: packageId });
  }

  public finalizePaymentDetails() {
    const f = this.paymentDetailsFg.value;

    if (!f.subscriberEmail) {
      this.paymentDetailsFg
        .get("subscriberEmail")
        .setErrors({ required: true });
        this.paymentDetailsFg.setErrors({
          subscriberEmail: "global.errors.required"
        });
    }

    if (!f.billingEmail) {
      this.paymentDetailsFg.get("billingEmail").setErrors({ required: true });
      this.paymentDetailsFg.setErrors({ billingEmail: "global.errors.required" });
    }

    if (!f.terms) {
      this.paymentDetailsFg.get("terms").setErrors({ required: true });
      this.paymentDetailsFg.setErrors({ terms: 'global.errors.required'});
    }

    console.log(this.paymentDetailsFg);

    if (!f.subscriberEmail || !f.billingEmail || !f.terms) {
      return;
    }

    this._dialog.open(
      SubscriptionChangeRequestedComponent,
      {
        width: "auto"
      },
      (res) => {
    
      }
    );

        this.setPackage(this.pendingPackage.id);
        this.pendingPackage = null;
        this.packageView = SubscriptionPackageView.Matrix;

 
  }

  public getPackageById(id) {
    return this.packageInfo.packages.find((x) => x.id === id);
  }

  public isPackageActive(id) {
    return this.currentPlan?.id === id;
  }

  public onTermsLinkClick(e?) {
    if (e) {
      e.stopPropagation();
      e.stopImmediatePropagation();
    }
    window.open(this.termsLink, "_blank");
  }

  private _paymentDetailsSnapshot: any;

  public toggleEditPaymentDetails() {
    this.editPaymentDetails = !this.editPaymentDetails;

    if (!this.editPaymentDetails) {
      this.paymentDetailsFg.patchValue(this._paymentDetailsSnapshot);
    } else {
      this._paymentDetailsSnapshot = this.paymentDetailsFg.value;
    }
  }

  public savePaymentDetails() {
    // ...
    this._paymentDetailsSnapshot = this.paymentDetailsFg.value;
    this.editPaymentDetails = false;
  }

  public savePackage() {
    const f = this.form.value;
    const req = {
      plan: f.activePackageId,
      annual: f.annual
    };

    this._settingsService.updatePackage(req).result(
      this.form,
      (res: {
        newQuota: ITenantQuota;
        viaStripe: boolean;
        viaNotification: boolean;
        requestedPlan: number;
      }) => {
        if (res.viaStripe) {
          this._message.ok({
            message:
              "cards.lexi_market_settings.lxm_settings_subscription.label.plan_changed_successfully"
          });

          this.currentPlan = res.requestedPlan % 100;
          this.tenantQuota.annual = res.requestedPlan > 100;

          this.tenantQuota.maxActiveProducts = res.newQuota.maxActiveProducts;
          this.tenantQuota.activeTo = res.newQuota.activeTo;
        } else if (res.viaNotification) {
          this.requestedPlanId = res.requestedPlan % 100;
          this.requestedPlanAnnual = res.requestedPlan > 100;

          this._dialog.confirm(
            {
              title:
                "cards.lexi_market_settings.lxm_settings_subscription.label.plan_change_request_sent_successfully_title",
              message:
                "cards.lexi_market_settings.lxm_settings_subscription.label.plan_change_request_sent_successfully_message",
              yes: "global.ok",
              showNo: false
            },
            () => {}
          );
        }
      },
      (error) => {
        this._message.error({
          messages: error.validationResults?.["plan"]?.map((x) => x.value)
        });
      },
      "save",
      this.actionLoading
    );
  }

  public cancelSubscription() {
    this._dialog.confirm(
      {
        message:
          "cards.lexi_market_settings.lxm_settings_subscription.label.cancel_subscription_text",
        title:
          "cards.lexi_market_settings.lxm_settings_subscription.label.cancel_subscription_confirm_title"
      },
      () => {
        this._settingsService
          .cancelSubscription()
          .result(this.form, (res: ITenantQuota) => {
            this.tenantQuota.activeTo = res.activeTo;

            this._message.ok({
              message:
                "cards.lexi_market_settings.lxm_settings_subscription.label.subscription_cancelled_successfully"
            });
          });
      }
    );
  }

  public get termsLink() {
    const lang = this._translateService.currentLang;

    switch (lang) {
      case "et":
        return LINKS.LXM_TERMS_ET;
      default:
        return LINKS.LXM_TERMS_EN;
    }
  }

  // public openTerms() {
  //   this._dialog.open(TermsDialogComponent, {
  //     ...appSettings.DIALOG_SIZES.M
  //   });
  // }
}

export interface IPackageInfo {
  id: any;
  type: string;
  isActive: boolean;
  validTo: any;
}

export enum SubscriptionPackage {
  XXS = 7,
  XS = 2,
  S = 3,
  M = 6,
  L = 4,
  XL = 5
}

enum SubscriptionPackageView {
  Matrix = 1,
  Details = 2
}

export enum SubscriptionPackageItem {
  ActiveProductCount = "1",
  UnlimitedUsers = "2",
  OffersToXRetailers = "3",
  CRM = "4",
  PdfOffers = "5",
  APIUsage = "6",
  AITranslations = "7",
  Declarations = "8"
}
