import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'image-drop-box',
  templateUrl: './image-drop-box.component.html',
  styleUrls: ['./image-drop-box.component.scss']
})
export class ImageDropBoxComponent implements OnChanges {

  @Input() public files: any[] = [];
  @Input() public uploadInProgress = false;
  @Input() public uploadingFile = false;
  @Input() public multiple = false;
  @Input() public indeterminate = false;
  @Input() public accept: string;
  @Input() public addFileText: string;
  @Input() public showAddFileCondition: boolean;

  @Input() public readonly = false;

  @Input() public src: string;
  @Input() public contentType: string;
  @Input() public base64: string;

  @Output() public onChange = new EventEmitter<FileList>();
  @Output() public onDelete = new EventEmitter<number>();

  public imageSrc: string;

  public get progressMode() {
    return this.indeterminate ? 'indeterminate' : 'determinate';
  }

  public emitFiles(fileList: FileList) {
    this.onChange.emit(fileList);
  }

  public deleteFile(index: number) {
    this.onDelete.emit(index);
  }

  public get hasImage() {
    return this.imageSrc ? true : false;
  }

  private readonly _fallbackImage = '/assets/img/icons/no-content/no-image.svg';

  public checkImage(imageSrc: string, good: any, bad: any) {
    const img = new Image();
    img.onload = good;
    img.onerror = bad;
    img.src = imageSrc;
  }

  public getImage() {

    if (!this.src) {
      if (this.readonly) {
        this.imageSrc = this._fallbackImage;
      } else {
        this.imageSrc = null;
      }
      return;
    }

    if (this.base64 && this.contentType) {
      this.imageSrc = `data:${this.contentType};base64,${this.src}`;
      return;
    }

    this.checkImage(this.src, success => {
      this.imageSrc = this.src;
    }, error => {
      this.imageSrc = this._fallbackImage;
    });

  }
  
  ngOnChanges() {
    this.getImage();
  }

}
