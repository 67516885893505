import { Pipe, PipeTransform, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { TranslateService, TranslationChangeEvent, LangChangeEvent } from "@ngx-translate/core";
import { Subscription } from 'rxjs';
import { LocaleService } from '../_services';

@Pipe({
    name: "translateOptions",
    pure: false // required to update the value when the promise is resolved
})
export class TranslateOptionsPipe implements PipeTransform, OnDestroy {

    private _value: any[];
    private _lastItems: any[];
    private _lastLabelField: string;
    private _lastIsTranslated: boolean;

    private _onTranslationChange: Subscription;
    private _onLangChange: Subscription;
    private _onDefaultLangChange: Subscription;

    constructor(private _localeService:LocaleService, private _translateService: TranslateService, private _ref: ChangeDetectorRef) { }

    public updateValue(items: any[], labelField: string, isTranslated: boolean): void {
        if (!isTranslated) {
            this._value = items?.map(x => {
                let clone = {...x};
                clone[labelField] = this._translateService.instant(x[labelField]);
                return clone;
            });
        } else {
            const lang = this._localeService.language.value;

            this._value = items?.map(x => {
                let clone = {...x};
                clone[labelField] = x[labelField][lang];
                return clone;
            });
        }

        this._ref.markForCheck();
    }

    public transform(items: any[], labelField: string, isTranslated: boolean) {
        // if we ask another time for the same key, return the last value
        if (items === this._lastItems && labelField === this._lastLabelField && this._lastIsTranslated === this._lastIsTranslated) {
            return this._value;
        }

        this._lastItems = items;
        this._lastLabelField = labelField;
        this._lastIsTranslated = isTranslated;

        // set the value
        this.updateValue(items, labelField, isTranslated);

        // if there is a subscription to onLangChange, clean it
        this._dispose();

        // subscribe to onTranslationChange event, in case the translations change
        if (!this._onTranslationChange) {
            this._onTranslationChange = this._translateService.onTranslationChange.subscribe((event: TranslationChangeEvent) => {
                if (event.lang === this._translateService.currentLang) {
                    this.updateValue(items, labelField, isTranslated);
                }
            });
        }

        // subscribe to onLangChange event, in case the language changes
        if (!this._onLangChange) {
            this._onLangChange = this._translateService.onLangChange.subscribe((event: LangChangeEvent) => {
                this.updateValue(items, labelField, isTranslated);
            });
        }

        // subscribe to onDefaultLangChange event, in case the default language changes
        if (!this._onDefaultLangChange) {
            this._onDefaultLangChange = this._translateService.onDefaultLangChange.subscribe(() => {
                this.updateValue(items, labelField, isTranslated);
            });
        }

        return this._value;
    }

    public ngOnDestroy(): void {
        this._dispose();
    }

    /**
     * Clean any existing subscription to change events
     */
    private _dispose(): void {
        if (typeof this._onTranslationChange !== 'undefined') {
            this._onTranslationChange.unsubscribe();
            this._onTranslationChange = undefined;
        }
        if (typeof this._onLangChange !== 'undefined') {
            this._onLangChange.unsubscribe();
            this._onLangChange = undefined;
        }
        if (typeof this._onDefaultLangChange !== 'undefined') {
            this._onDefaultLangChange.unsubscribe();
            this._onDefaultLangChange = undefined;
        }
    }
}