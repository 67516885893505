import { Component, OnInit, Input, ChangeDetectorRef, ElementRef, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Location } from '@angular/common';
import { ProductsListBase } from '../products/products-list-base';
import { Observable } from 'rxjs';
import { HttpMethod, LxmAppModule, UserAction } from 'src/app/enum';

import { mergeMap, map } from 'rxjs/operators';
import { LxmDialog } from 'src/app/_helpers';
import { CreateOfferDataService, DataTableService, ProductService } from 'src/app/_services';
import { ProductsRetailerExportDialogComponent } from '../products/retailer/export-products-retailer.dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsListState } from '../products/products-list.state';
import { AppState } from 'src/app/state/app.state';
import { LOCAL_STORAGE_KEY } from 'src/app/config/local-storage-keys';
import { MarketProductsListState } from './products-list.state';

@Component({
  selector: 'market-products-card',
  templateUrl: './market-products.card.html',
  host: { 'class': 'data-table-card' },
  styleUrls: ['./market-products.card.scss', '../products/products-list-base.scss']
})
export class MarketProductsCard extends ProductsListBase<IProductData, any> implements OnInit {

  public LOCAL_STORAGE_KEY = LOCAL_STORAGE_KEY.PAGE_SIZE_MARKET_PRODUCTS_LIST;

  @ViewChild('download') private _download: ElementRef;

  public table = {
    columns: [
      'select',
      'image',
      'fullName',
      'ean',
      'brand',
      'segment',
      'supplier',
      'product_listed_price',
      'product_price_for_retailer'
    ],

    widths: {
      select: '50px',
      image: '84px',
      name: '100%',
      ean: '12%',
      brand: '12%',
      tree: '20%',
      supplier: '15%',
      listedPrice: '8%',
      priceForRetailer: '8%'
    }
  };

  dataUrl = 'products/market';
  method = HttpMethod.Post;
  initialData;

  public LxmAppModule = LxmAppModule;
  public UserAction = UserAction;


  constructor(
    public state: MarketProductsListState,
    public appState: AppState,
    private _productService: ProductService,
    private _router: Router,
    private _createOfferDataService: CreateOfferDataService,
    location: Location,
    public dialog: LxmDialog,
    _dataService: DataTableService<IProductData, any>,
    _cd: ChangeDetectorRef,
    route: ActivatedRoute
  ) {
    super(state, location, dialog, _dataService, _cd);
    this.initialData = route?.snapshot?.data?.initialData;
    this._searchUrl = '/market/search/';

    this.formData = route?.snapshot?.data?.formData;
    
    let pricePrecision = this.formData?.pricePrecision;
    if (pricePrecision) {
      this.pricePrecision = pricePrecision;
    }

    this.procurementHasPriority = this.formData?.procurementHasPriority;
  }

  public send() {
    // todo
  }

  public requestOffer() {
    this._createOfferDataService.productIds = this.selection.map(x => x.id);
    this._router.navigate(['/offers/request']);
  }

  public exportXls() {
    var ids = this.selection.map(x => x.id);
    this.dialog.open(ProductsRetailerExportDialogComponent, {
      resolve: () =>
        this._productService.exportXls({ productIds: ids })
          .pipe(
            mergeMap(async res => {
              const blob = await this._productService.downloadXls(res.id).toPromise();
              return {
                fileName: res.fileName,
                blob: blob
              }
            })
          )
          .pipe(
            map(res => {
              const url = window.URL.createObjectURL(res.blob);

              const link = this._download.nativeElement;
              link.href = url;
              link.download = res.fileName;
              link.click();

              window.URL.revokeObjectURL(url);
            })
          )
    });
  }
}

export interface IProductData {
  id: string;
  fullName: string;
  status: number;
  image: string;
  weight: string;
  ean: number;
  brand: string;
  segment: string;
  supplier: string;
  company: string;
  publicSalesPrice: string;
  retailSalesPrice: string;
  hasCampaign: boolean;
  campaignInfo: any;
}

export interface ISupplierInfo {
  company: string;
  as: string;
}

export interface IProductInfo {
  name: string;
  weight: string;
}
