import { Injectable } from '@angular/core';
import { AppState } from "src/app/state/app.state";
import { appSettings } from "src/app/app.settings";

@Injectable()
export class ContentLanguage {

  constructor(private _state: AppState) {}

  public get contentLanguages() {
      return this._state.contentLanguages;
  }

  public get(item: { [lang: string]: string }): string {

      var res = item[this._state.displayContentLanguage]
      if (res) {
        return res;
      }

      let language = appSettings.defaultLang.value;
      this.contentLanguages.some(x => {
          if (item[x]) {
              language = x;
              return true;
          }
      });
      return item[language]
  }
}
