import { Component, Input, OnInit } from "@angular/core";
import { ViewEncapsulation } from '@angular/core';

@Component({
  selector: "card-section",
  templateUrl: "./section.component.html",
  styleUrls: ['./section.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class SectionComponent {

  constructor() {}

}
