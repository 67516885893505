import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { HttpClient } from "@angular/common/http"
import { Observable, of } from "rxjs";
import { IClvDto } from '../cards/product/product-info/product-info.model';
import { LocalStorage } from "../_helpers";
import { LOCAL_STORAGE_KEY, PAGE_SIZE } from 'src/app/config';
import { TabType as ProductManagementTabType } from 'src/app/cards/settings/product-management-settings/product-management-settings.card';
import { TabType as FieldTabType } from 'src/app/cards/settings/lxm-field-settings/lxm-field-settings.card';
import { TabType as AssortmentManagementTabType } from 'src/app/cards/settings/assortment-management/assortment-management.card';
import { AssortmentSchemesService, AuthenticationService } from '../_services';
import { AppState } from "../state/app.state";
import { LxmAppModule } from "../enum";
import { catchError } from "rxjs/operators";
import { IPhoneCodeItem } from "./lxm-settings/users.resolve";

@Injectable()
export class SettingsFormDataResolve implements Resolve<any> {

  constructor(private _http: HttpClient) { }

  resolve(): Observable<ISettingsFormData> {
    return (this._http.get<ISettingsFormData>("api/settings/formdata") as Observable<ISettingsFormData>)
    .pipe(catchError(error => {
        

      return of(null);
    }));
  }
}

@Injectable()
export class AssortmentManagementDataResolve implements Resolve<any> {

  constructor(
    private _http: HttpClient, 
    private _assortmentSchemesService: AssortmentSchemesService, 
    private _appState: AppState) {}

  resolve(): Observable<any> {

    if (!this._appState.hasModule(LxmAppModule.BuyerAssortment)){
      return null;
    }

    const _tabOption = LocalStorage.getValue(LOCAL_STORAGE_KEY.TAB_ASSORTMENT_MANAGEMENT_SETTINGS);

    if (_tabOption) {
      const { type } = _tabOption.data;
      switch (type) {
        case AssortmentManagementTabType.Clv:
          const { data } = _tabOption.data;
          const tabData = data;
          if (tabData) {
            const { controller, classificatorId } = tabData;
            if (controller) {
              return this._http.get(`api/${controller}/griddata`, { params: { classificatorId: classificatorId || '', $count: 'true', $top: PAGE_SIZE[LOCAL_STORAGE_KEY.PAGE_SIZE_ASSORTMENT_STATUS_LIST]} }) as Observable<any>
            };
          }
          return null;
        case AssortmentManagementTabType.AssortmentSchemes:
          return this._assortmentSchemesService.getMap() as Observable<any>
        case AssortmentManagementTabType.AssortmentGroups:
          return this._http.get(`api/assortmentGroups`, { params: { $count: 'true', $top: PAGE_SIZE[LOCAL_STORAGE_KEY.PAGE_SIZE_ASSORTMENT_GROUPS_LIST]} }) as Observable<any>
        default:
          return null;
      }
    }
    return null;
  }
}

@Injectable()
export class SettingsProductManagementDataResolve implements Resolve<any> {

  constructor(private _http: HttpClient) {}


  resolve(): Observable<any> {
    const _tabOption = LocalStorage.getValue(LOCAL_STORAGE_KEY.TAB_PRODUCT_MANAGEMENT_SETTINGS);

    if (_tabOption) {
      const { type } = _tabOption.data;
      switch (type) {
        case ProductManagementTabType.Clv:
          const { data } = _tabOption.data;
          const tabData = data;
          if (tabData) {
            const { controller, classificatorId } = tabData;
            if (controller) {
              return this._http.get(`api/${controller}/griddata`, { params: { classificatorId: classificatorId || '', $count: 'true', $top: PAGE_SIZE.DEFAULT} }) as Observable<any>
            };
          }
          return null;
        case ProductManagementTabType.ContentLanguages:
          return this._http.get(`api/contentLanguages/griddata`, { params: { $count: 'true', $top: PAGE_SIZE.DEFAULT} }) as Observable<any>
        case ProductManagementTabType.SegmentTree:
          return this._http.get(`api/segments/list`, { params: { parentId: '', $count: 'true', $top: PAGE_SIZE.DEFAULT} }) as Observable<any>
        default:
          return null;
      }
    }
    return null;
  }
}

@Injectable()
export class SettingsFieldDataResolve implements Resolve<any> {

  constructor(private _http: HttpClient) {}

  resolve(): Observable<any> {
    const _tabOption = LocalStorage.getValue(LOCAL_STORAGE_KEY.TAB_FIELD_SETTINGS);

    if (_tabOption) {
      const { type } = _tabOption.data;
      switch (type) {
        case FieldTabType.Clv:
          const { data } = _tabOption.data;
          const tabData = data;
          if (tabData) {
            const { controller, classificatorId } = tabData;
            if (controller) {
              return this._http.get(`api/${controller}/griddata`, { params: { classificatorId: classificatorId || '', $count: 'true', $top: PAGE_SIZE.DEFAULT} }) as Observable<any>;
            }
          }
          return null;
        case FieldTabType.GpcTree:
          return this._http.get(`api/gpcTree`, { params: { parentId: ''} }) as Observable<any>;
        case FieldTabType.AtcTree:
          return this._http.get(`api/atcTree`, { params: { parentId: ''} }) as Observable<any>;
      }
    }
    return null;
  }
}

export interface ISettingsFormData {
  unitCodeTypes: IClvDto[];
  phoneCodes: IPhoneCodeItem[];
}
