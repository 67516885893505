import { Component, HostBinding, Input } from '@angular/core';
import { OfferType } from 'src/app/enum';
import { IClvDto } from 'src/app/cards/product/product-info/product-info.model';
import { registry } from 'src/app/enum';

@Component({
  selector: "type-indicator",
  templateUrl: './type-indicator.component.html',
  styleUrls: ['./type-indicator.scss'],
  host: {
    'class': 'type-indicator',
    '[class.suppressed]': 'suppressed'
  }
})
export class TypeIndicatorComponent {

  @Input() public type: IClvDto;
  @Input() public suppressed: boolean = false;
  @Input() public typeId: string;
  @Input() public enum: string = 'OfferType';

  @HostBinding('class') public get typeClass() {
    const typeId = this.typeId ?? this.type?.id;
    if (!typeId || !this.typeEnum) {
      return null;
    }

    return this.typeEnum?.[typeId];
  }

  public get typeEnum() {
    return registry[this.enum];
  }



}