import { NgModule } from '@angular/core';
import { QuillModule } from 'ngx-quill';
import Quill from 'quill';
import { VideoHandler, ImageHandler, Options, ImageBlot } from 'ngx-quill-upload';

var ATTRIBUTES = ['alt', 'height', 'width', 'resourceid'];


const Link = Quill.import('formats/link');

// Override the existing property on the Quill global object and add custom protocols
Link.PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel', 'radar', 'rdar', 'smb', 'sms'];

class LxmLinkSanitizer extends Link {
    static sanitize(url: string) {
    const sanitizedUrl = super.sanitize(url);

    if (!sanitizedUrl || sanitizedUrl === 'about:blank') return sanitizedUrl;

    const hasWhitelistedProtocol = Link.PROTOCOL_WHITELIST.some(function(protocol: string) {
        return sanitizedUrl.startsWith(protocol);
    });

    if (hasWhitelistedProtocol) return sanitizedUrl;

    return `https://${sanitizedUrl}`;
    }
}


const ImageBlotBASE = Quill.import('formats/image');

ImageBlotBASE.value = function (node) {
  return { 
    url: node.getAttribute('src'),
    resourceid: node.getAttribute('resourceid')
  };
};

ImageBlotBASE.formats = function formats(domNode) {
  return ATTRIBUTES.reduce(function (formats, attribute) {
    if (domNode.hasAttribute(attribute)) {
      formats[attribute] = domNode.getAttribute(attribute);
    }
    return formats;
  }, {});
};

class LxmImageBlot extends ImageBlot
{
  static formats(node) {
    // We still need to report unregistered embed formats
    let format = {} as any;
    if (node.hasAttribute('resourceid')) {
      format.resourceid = node.getAttribute('resourceid');
    }
    
    return format;
  }

  format(name, value) {
    // Handle unregistered embed formats
    if (name === 'resourceid') {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

LxmImageBlot.tagName = 'img';
LxmImageBlot.blotName = 'image';
LxmImageBlot.className = 'quill-upload-image';

class LxmImageHandler extends ImageHandler {
  constructor(quill, options: Options) {
    super(quill, options);

    this.handler = 'image';
    this.applyForToolbar();
    Quill.register(LxmImageBlot, true);
  }

  insertFileToEditor(res, handlerId) {
    const el = document.getElementById(handlerId);
    if (el) {
        el.setAttribute('src', res.url + '/' + res.body);
        el.setAttribute('resourceid', res.body);
        el.removeAttribute('id');
        el.removeAttribute('class');
    }
  }
}

Quill.register(LxmLinkSanitizer, true);
Quill.register('modules/imageHandler', LxmImageHandler);
Quill.register('modules/videoHandler', VideoHandler);

@NgModule({
  imports: [
    QuillModule.forRoot({
      suppressGlobalRegisterWarning: true,
      modules: {
        clipboard: {
          matchVisual: false
        },
      },
    })
  ],
  declarations: [
    
  ],
  exports: [
    QuillModule
  ]
})

export class LxmQuillModule {}
