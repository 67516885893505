import { Injectable, EventEmitter } from '@angular/core';
import { IReactiveLinkOptions } from 'src/app/components/_layout/navigator/reactive-links/reactive-links.component';
import { LocalStorage } from '../_helpers';
import { LOCAL_STORAGE_KEY } from 'src/app/config';
import { ProductCardSection } from '../enum';

@Injectable({ providedIn: 'root' })
export class NavigatorState {

  public collapsed = LocalStorage.getValue(LOCAL_STORAGE_KEY.NAVIGATOR_OPEN) || false;
  public previousCollapsedValue: boolean;
  public currentView = 0; // 0 is the default navigation view

  public isAnyItemFullscreen = false;

  public refreshReactiveLinks = new EventEmitter();

  public showProductReactiveLinks = false;
  public isOwnProduct = false;
  public headerText: string;

  public hasBuyerPimExtensions = false;

  public get productReactiveLinkOptions(): IReactiveLinkOptions {
    return this.hasBuyerPimExtensions ? {
      view: 'product-layout',
      targets: [
        { translateKey: 'cards.products.product_base_info.title', selector: 'product-info-card', section: ProductCardSection.BaseInfo },
        { translateKey: 'cards.products.product_description_fields.title', selector: 'product-custom-card', section: ProductCardSection.ExtraParameters },
        { translateKey: 'cards.products.product_assortment_management.title', selector: 'retailer-product-assortment-card', section: ProductCardSection.AssortmentSchemes },
        { translateKey: 'cards.products.product_catalog.title', selector: 'supplier-product-assortment-card', section: ProductCardSection.AssortmentSchemes },
        { translateKey: this.isOwnProduct ? 'cards.products.product_buyin_prices.title' : 'cards.products.product_clientprices.title', selector: 'product-supplier-prices-card', section: ProductCardSection.PriceSchemes },
        { translateKey: 'cards.products.product_pricelist.title', selector: 'product-pricelist-card', section: ProductCardSection.PriceSchemes },
        { translateKey: 'cards.products.product_pricelist.title_clientprices', selector: 'product-client-prices-card', section: ProductCardSection.ClientPricelists },
        { translateKey: 'cards.products.product_alcohol.title', selector: 'product-alcohol-card', section: ProductCardSection.AlcoholInfo },
        { translateKey: 'cards.products.product_fish.title', selector: 'product-seafood-card', section: ProductCardSection.SeafoodInfo },
        { translateKey: 'cards.products.product_meat.title', selector: 'product-meat-card', section: ProductCardSection.MeatInfo },
        { translateKey: 'cards.products.product_movie_info.title', selector: 'product-movie-card', section: ProductCardSection.MovieInfo },
        { translateKey: 'cards.products.product_music_info.title', selector: 'product-music-card', section: ProductCardSection.MusicInfo },
        { translateKey: 'cards.products.product_game_info.title', selector: 'product-game-card', section: ProductCardSection.GameInfo },
        { translateKey: 'cards.products.product_medication_info.title', selector: 'product-medication-card', section: ProductCardSection.MedicationInfo },
        { translateKey: 'cards.products.product_electronics.title', selector: 'product-electronics-card', section: ProductCardSection.ElectronicsInfo },
        { translateKey: 'cards.products.product_book_info.title', selector: 'product-book-card', section: ProductCardSection.BookInfo },
        { translateKey: 'cards.products.product_certificate_info_and_nutrition_info.title', selector: 'product-nutritional-card', section: ProductCardSection.MarkingAndNutritionInfo },
        { translateKey: 'cards.products.product_additional_info_and_instructions.title', selector: 'product-additional-card', section: ProductCardSection.AdditionalInfoAndAttachments },
        { translateKey: 'cards.products.product_attributes.title', selector: 'product-attributes-card', section: ProductCardSection.Attributes },
        { translateKey: 'cards.products.product_storage_info.title', selector: 'product-expiry-card', section: ProductCardSection.ExpiryInfo },
        { translateKey: 'cards.products.product_packaging.title', selector: 'product-packaging-card', section: ProductCardSection.ProductUnits },
        { translateKey: 'cards.products.product_images.title', selector: 'product-images-card', section: ProductCardSection.ProductImages },
        { translateKey: 'cards.history.title', selector: 'product-history-card', section: ProductCardSection.History },
      ]
    } : {
      view: 'product-layout',
      targets: [
        { translateKey: 'cards.products.product_base_info.title', selector: 'product-info-card', section: ProductCardSection.BaseInfo },
        { translateKey: 'cards.products.product_description_fields.title', selector: 'product-custom-card', section: ProductCardSection.ExtraParameters },
        { translateKey: 'cards.products.product_alcohol.title', selector: 'product-alcohol-card', section: ProductCardSection.AlcoholInfo },
        { translateKey: 'cards.products.product_fish.title', selector: 'product-seafood-card', section: ProductCardSection.SeafoodInfo },
        { translateKey: 'cards.products.product_meat.title', selector: 'product-meat-card', section: ProductCardSection.MeatInfo },
        { translateKey: 'cards.products.product_movie_info.title', selector: 'product-movie-card', section: ProductCardSection.MovieInfo },
        { translateKey: 'cards.products.product_music_info.title', selector: 'product-music-card', section: ProductCardSection.MusicInfo },
        { translateKey: 'cards.products.product_game_info.title', selector: 'product-game-card', section: ProductCardSection.GameInfo },
        { translateKey: 'cards.products.product_medication_info.title', selector: 'product-medication-card', section: ProductCardSection.MedicationInfo },
        { translateKey: 'cards.products.product_electronics.title', selector: 'product-electronics-card', section: ProductCardSection.ElectronicsInfo },
        { translateKey: 'cards.products.product_book_info.title', selector: 'product-book-card', section: ProductCardSection.BookInfo },
        { translateKey: 'cards.products.product_certificate_info_and_nutrition_info.title', selector: 'product-nutritional-card', section: ProductCardSection.MarkingAndNutritionInfo },
        { translateKey: 'cards.products.product_additional_info_and_instructions.title', selector: 'product-additional-card', section: ProductCardSection.AdditionalInfoAndAttachments },
        { translateKey: 'cards.products.product_attributes.title', selector: 'product-attributes-card', section: ProductCardSection.Attributes },
        { translateKey: 'cards.products.product_storage_info.title', selector: 'product-expiry-card', section: ProductCardSection.ExpiryInfo },
        { translateKey: 'cards.products.product_packaging.title', selector: 'product-packaging-card', section: ProductCardSection.ProductUnits },
        { translateKey: 'cards.products.product_images.title', selector: 'product-images-card', section: ProductCardSection.ProductImages },
        { translateKey: this.isOwnProduct ? 'cards.products.product_buyin_prices.title' : 'cards.products.product_clientprices.title', selector: 'product-supplier-prices-card', section: ProductCardSection.PriceSchemes },
        { translateKey: 'cards.products.product_pricelist.title', selector: 'product-pricelist-card', section: ProductCardSection.PriceSchemes },
        { translateKey: 'cards.products.product_pricelist.title_clientprices', selector: 'product-client-prices-card', section: ProductCardSection.ClientPricelists },
        { translateKey: 'cards.products.product_assortment_management.title', selector: 'retailer-product-assortment-card', section: ProductCardSection.AssortmentSchemes },
        { translateKey: 'cards.products.product_catalog.title', selector: 'supplier-product-assortment-card', section: ProductCardSection.AssortmentSchemes },
        { translateKey: 'cards.history.title', selector: 'product-history-card', section: ProductCardSection.History },
      ]
    }
    
    
    ;
  }

  public initProductReactiveLinks(isOwnProduct: boolean, headerText: string) {
    this.headerText = headerText;
    this.isOwnProduct = isOwnProduct;
    this.showProductReactiveLinks = true;
  }

  public destroyProductReactiveLinks() {
    this.headerText = null;
    this.isOwnProduct = false;
    this.showProductReactiveLinks = false;
  }
}
