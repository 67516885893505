import { Injectable } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";

@Injectable({ providedIn: "root" })
export class ClientsState {

  public isSearchLoading = false;

  public searchData = new FormGroup({
    searchTerm: new FormControl(),
    clientTags: new FormControl()
  });

  public serializeSearchData() {
    const searchData = this.searchData.value;
    return {
      searchTerm: searchData.searchTerm,
      clientTagIds: searchData.clientTags?.map((x: any) => x.id)
    };
  }

  
}
