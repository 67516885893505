import {
  Component,
  Input,
  OnInit,
  Output,
  Optional,
  Inject
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Observable, BehaviorSubject, Subscription } from "rxjs";

@Component({
  selector: "lxm-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.scss"],
})
export class DialogComponent implements OnInit {
  private _dataLoadedSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  @Input()
  public title: string;

  @Input()
  public dialogRef: MatDialogRef<any>;

  @Input()
  public disableContentMargin = false;

  @Output()
  public dataLoaded$: Observable<boolean> =
    this._dataLoadedSubject.asObservable();

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) private _data: any) {}

  private _onDataLoadedSubscription: Subscription;

  ngOnInit() {
    if (!this._data || !this._data.__resolve) {
      this._dataLoadedSubject.next(true);
      return;
    }

    let resolve = this._data.__resolve as () => Observable<any>;
    if (!resolve) {
      this._dataLoadedSubject.next(true);
      return;
    }

    let observable = resolve();
    if (!observable) {
      this._dataLoadedSubject.next(true);
      return;
    }

    this._onDataLoadedSubscription = observable?.subscribe((res) => {
      if (res instanceof Object) {
        Object.keys(res).forEach((x) => {
          this._data[x] = res[x];
        });
      }
    }, err => {
      console.error(err);
    }, () => {
      this._dataLoadedSubject.next(true);
    });

  }

  public closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (this._onDataLoadedSubscription) {
      this._onDataLoadedSubscription.unsubscribe();
    }
  }
}
