import { HttpEventType } from "@angular/common/http";
import { resolve } from "@angular/compiler-cli/src/ngtsc/file_system";
import { Component, Input, OnInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { HubConnection, HubConnectionState } from "@microsoft/signalr";
import { TranslateService } from "@ngx-translate/core";
import { from, Subscription } from "rxjs";
import { appSettings } from "src/app/app.settings";
import {
  ICountry,
  IProductCard,
  IProductCardFormData
} from "src/app/cards/product/product-info/product-info.model";
import { ALLERGEN, FOOD_SUGGESTION } from "src/app/config";
import { PICTOGRAMS } from "src/app/config/constants";
import { ProductValidationDialogComponent } from "src/app/dialogs";
import { ProductCloneDialogComponent } from "src/app/dialogs/product-clone/product-clone.dialog";
import { RequiredModulesDirective } from "src/app/directives";
import {
  asArray,
  ClientStatus,
  LxmAppModule,
  ProductUnitType,
  SupplierProductAssortmentStatus,
  UserAction
} from "src/app/enum";
import { ProductUnitCodeType } from "src/app/enum/product-unit-code-type";
import { IUser } from "src/app/models";
import { AppState } from "src/app/state/app.state";
import { ContentLanguage, LxmMessage, RouteNavigator } from "src/app/_helpers";
import { ActionHelper } from "src/app/_helpers/action";
import {
  FileService,
  ProductService,
  ProductValidity,
  SignalRService,
  TitleService
} from "src/app/_services";
import {
  ClientsService,
  IClientCard,
  IClientFormData,
  IClientInfo
} from "src/app/_services/clients.service";
import { environment } from "src/environments/environment";
import { ProductHeaderState } from "./product-header.state";
import svgIcons from "src/assets/svg/svg-icons";
import { BreadcrumbService } from "xng-breadcrumb";
import { TranslatedValuePipe } from "src/app/pipes";
import { ExportProductsPdfDialog } from "src/app/dialogs/export-products-pdf/export-products-pdf.dialog";

@Component({
  selector: "product-header-card",
  templateUrl: "./product-header.card.html",
  styleUrls: ["./product-header.card.scss"]
})
export class ProductHeaderCard {
  public edit = false;

  public imagesListCount = 4;

  public hasBuyerPimExtensions = false;

  public isOwnProduct = true;
  public isClone = false;

  public isProductActive: boolean;

  private _formData: IProductCardFormData;

  public UserAction = UserAction;

  @Input() public isMarketProduct: boolean = false;

  @Input() public productId: string;

  public showDeleteButton = false;
  public showAddButton = false;
  public showCloneButton = false;
  public showPdfButton = false;
  public sendToDirectoButtonDisabled = false;

  public show = {
    dotMenu: false,
    deleteButton: false,
    addButton: false,
    cloneButton: false,
    pdfButton: false,
    sendToDirectoButton: false,
    getImageFromApollo: false //Temporary to show that pictures can be downloaded from Apollo media server. todo - remove.
  };

  public pictograms: string[] = [];

  public showRetailerAssortmentStatus = false;

  private _updateSubscription: Subscription;

  constructor(
    public state: ProductHeaderState,
    private _appState: AppState,
    private route: ActivatedRoute,
    private _router: Router,
    private _action: ActionHelper,
    private _productService: ProductService,
    private _productValidity: ProductValidity,
    private _lxmMessage: LxmMessage,
    private _breadcrumbService: BreadcrumbService,
    private _translatedValuePipe: TranslatedValuePipe
  ) {
    const productCard = route.snapshot.data.productCard as IProductCard;
    this._formData = productCard.formData;
    this.isClone = productCard.isClone;

    const { productId } = this.route.snapshot.params;

    const {
      baseInfo,
      additionalInfo,
      expirationInfo,
      images,
      markingAndNutritionInfo,
      alcoholInfo,
      retailerAssortments,
      assortments,
      validationResults,
      productInfoFullness
    } = productCard || {};

    this.sendToDirectoButtonDisabled = productInfoFullness < 75;
    this.isProductActive = productCard.isActive;
    this.isOwnProduct = productCard.isOwnProduct;

    this.showRetailerAssortmentStatus =
      this.isOwnProduct &&
      this._appState.hasModule(LxmAppModule.BuyerAssortment);

    if (this._productValidity.hasBuyerPimExtensions) {
      this._productValidity.shouldValidate = true;
      this._productValidity.set(validationResults);
    }

    this._breadcrumbService.set(
      "@ProductName",
      this._translatedValuePipe.transform(baseInfo?.fullName)
    );

    this.state.setData({
      id: productId,
      fullName: baseInfo?.fullName,
      images: images,
      ean13: baseInfo.codes.find(
        x => x.type?.id === ProductUnitCodeType.EAN13 && x.isPrimary
      ),
      supplierCode: baseInfo.codes.find(
        x => x.type?.id === ProductUnitCodeType.SupplierCode && x.isPrimary
      ),
      internalCode: baseInfo.codes.find(
        x => x.type?.id === ProductUnitCodeType.InternalCode && x.isPrimary
      ),
      weighingGoodsCode: baseInfo.codes.find(
        x => x.type?.id === ProductUnitCodeType.WeighingGoods && x.isPrimary
      ),
      brand: baseInfo?.brand,
      countryOfOrigin: baseInfo?.countryOfOrigin,
      markingAndNutritionInfo: markingAndNutritionInfo,
      foodSuggestions: alcoholInfo?.wineFoodSuggestions,
      retailerAssortmentStatus: retailerAssortments?.length
        ? retailerAssortments?.find(x => x.isCurrent)?.status
        : null,
      supplierAssortmentStatus: assortments?.length
        ? assortments?.find(x => x.isCurrent)?.status
        : null,
      awards: additionalInfo?.awards?.map(x => x.id),
      storageConditionId: expirationInfo?.storageConditions?.id,
      alcoholContent: alcoholInfo?.alcoholContent,
      alcoholFree: markingAndNutritionInfo?.alcoholFree,
      ethicalMarkingIds: markingAndNutritionInfo?.ethicalMarkings?.map(
        x => x.id
      )
    });
  }

  public get productActiveStatus(): SupplierProductAssortmentStatus {
    return this.data?.status;
  }

  public get retailerAssortmentStatus() {
    return this.data?.retailerAssortmentStatus;
  }

  public get supplierAssortmentStatus() {
    return (
      this.data?.supplierAssortmentStatus ??
      SupplierProductAssortmentStatus.NoScheme
    );
  }

  public getCountryIcon(item: any) {
    if (!item["iso3"]) {
      return;
    }
    const key = item["iso3"].toLowerCase();
    if (key) {
      if (svgIcons[key]) {
        return key;
      }
    }
    if (svgIcons["global"]) {
      return "global";
    }
    return;
  }

  ngOnInit() {
    this._setRights();
    this._updatePictograms();
    this.hasBuyerPimExtensions = this._appState.hasModule(
      LxmAppModule.BuyerPimExtensions
    );
  }

  public isChilled(storageConditionId) {
    const chilledId = "862d0c3f-ce13-4fa8-baac-88ea50e2ff8b";
    return storageConditionId === chilledId;
  }

  public isFrozen(storageConditionId) {
    const frozenId = "10402d42-aacc-44a9-bbe2-ee0a10ea9816";
    return storageConditionId === frozenId;
  }

  private _updatePictograms() {
    const allergens = this.allergens.map(
      (x: { allergen: { code: any } }) => x.allergen?.code
    );
    let pictograms = [];

    const {
      suitableForVegans,
      lactoseFree,
      sugarFree,
      glutenFree,
      alcoholFree
    } = this.nutritionInfo || {};

    // Check if has any awards
    if (this.awards?.length) {
      pictograms.push(PICTOGRAMS.awarded);
    }

    // Check for ethical markings
    const ethicalMarkingIds = this.data?.ethicalMarkingIds;
    if (ethicalMarkingIds?.length) {
      // Fairtrade
      const fairtradeMarkingIds = [
        "151fdf82-fd74-4d0c-816d-e2a136e3ef09",
        "1fc596c3-3a08-4b5c-8a9b-b52d3d01b703",
        "1706dc2d-4927-4fba-bfda-71995dab96e3"
      ];

      const fairtrade = ethicalMarkingIds.some(x =>
        fairtradeMarkingIds.includes(x)
      );

      if (fairtrade) {
        pictograms.push(PICTOGRAMS.fairtrade);
      }

      // EU Organic farming
      const organicFarmingMarkingIds = ["3c34c762-56eb-4de1-a9dd-f9deafb0a631"];

      const organicFarming = ethicalMarkingIds.some(x =>
        organicFarmingMarkingIds.includes(x)
      );

      if (organicFarming) {
        pictograms.push(PICTOGRAMS.organicFarming);
      }

      // Rainforest alliance
      const rainforestAllianceMarkingIds = [
        "23b46aeb-d590-47fa-be18-6dedfef101d0",
        "abc3e861-0285-4b82-a9ba-4cffff1b747a"
      ];

      const rainforestAlliance = ethicalMarkingIds.some(x =>
        rainforestAllianceMarkingIds.includes(x)
      );

      if (rainforestAlliance) {
        pictograms.push(PICTOGRAMS.rainforestAlliance);
      }

      // Tunnustatud eesti maitse - Pääsukesemärk
      const eestiMaitseMarkingIds = ["cd4d8a2e-0073-4036-8ce7-9ac726b22959"];

      const eestiMaitse = ethicalMarkingIds.some(x =>
        eestiMaitseMarkingIds.includes(x)
      );

      if (eestiMaitse) {
        pictograms.push(PICTOGRAMS.tunnustatudEestiMaitse);
      }
    }

    // Check for storage conditions
    const storageConditionId = this.data?.storageConditionId;
    if (storageConditionId) {
      if (this.isChilled(storageConditionId)) {
        pictograms.push(PICTOGRAMS.chilled);
      }

      if (this.isFrozen(storageConditionId)) {
        pictograms.push(PICTOGRAMS.frozen);
      }
    }

    // Check for vegan
    if (suitableForVegans) {
      pictograms.push(PICTOGRAMS.suitableForVegans);
    }

    // Check for gluten free
    if (glutenFree) {
      pictograms.push(PICTOGRAMS.glutenFree);
    }

    // Check for lactose free
    if (lactoseFree) {
      pictograms.push(PICTOGRAMS.lactoseFree);
    }

    // Check for sugar free
    if (sugarFree) {
      pictograms.push(PICTOGRAMS.sugarFree);
    }

    // Check for alcohol
    if (this.data?.alcoholContent) {
      pictograms.push(PICTOGRAMS.alcohol);
    }

    // Check for alcohol free
    if (alcoholFree) {
      pictograms.push(PICTOGRAMS.alcoholFree);
    }

    // Check for food suggestions
    const foodSuggestions = this.foodSuggestions;
    if (foodSuggestions?.length) {
      foodSuggestions.forEach(x => {
        const suggestion = FOOD_SUGGESTION[x.id];
        if (suggestion) {
          pictograms.push(suggestion);
        }
      });
    }

    if (allergens.length) {
      // Check for fish allergens
      if (allergens.some((x: string) => ALLERGEN.FISH.includes(x))) {
        pictograms.push(PICTOGRAMS.allergenFish);
      }

      // Check for crustaceans allergens
      if (allergens.some((x: string) => ALLERGEN.CRUSTACEANS.includes(x))) {
        pictograms.push(PICTOGRAMS.allergenCrustaceans);
      }

      // Check for mollusc allergens
      if (allergens.some((x: string) => ALLERGEN.MOLLUSCS.includes(x))) {
        pictograms.push(PICTOGRAMS.allergenMolluscs);
      }

      // Check for gluten allergens
      if (allergens.some((x: string) => ALLERGEN.GLUTEN.includes(x))) {
        pictograms.push(PICTOGRAMS.allergenGluten);
      }

      // Check for nut allergens
      if (allergens.some((x: string) => ALLERGEN.NUTS.includes(x))) {
        pictograms.push(PICTOGRAMS.allergenNuts);
      }

      // Check for peanut allergens
      if (allergens.some((x: string) => ALLERGEN.PEANUTS.includes(x))) {
        pictograms.push(PICTOGRAMS.allergenPeanuts);
      }

      // Check for celery allergens
      if (allergens.some((x: string) => ALLERGEN.CELERY.includes(x))) {
        pictograms.push(PICTOGRAMS.allergenCelery);
      }

      // Check for mustard allergens
      if (allergens.some((x: string) => ALLERGEN.MUSTARD.includes(x))) {
        pictograms.push(PICTOGRAMS.allergenMustard);
      }

      // Check for egg allergens
      if (allergens.some((x: string) => ALLERGEN.EGGS.includes(x))) {
        pictograms.push(PICTOGRAMS.allergenEggs);
      }

      // Check for lactose allergens
      if (allergens.some((x: string) => ALLERGEN.LACTOSE.includes(x))) {
        pictograms.push(PICTOGRAMS.allergenLactose);
      }

      // Check for sesame allergens
      if (allergens.some((x: string) => ALLERGEN.SESAME.includes(x))) {
        pictograms.push(PICTOGRAMS.allergenSesame);
      }

      // Check for soy allergens
      if (allergens.some((x: string) => ALLERGEN.SOY.includes(x))) {
        pictograms.push(PICTOGRAMS.allergenSoy);
      }

      // Check for sulphite allergens
      if (allergens.some((x: string) => ALLERGEN.SULPHITES.includes(x))) {
        pictograms.push(PICTOGRAMS.allergenSulphites);
      }
    }

    this.pictograms = pictograms;
  }

  private _setRights() {
    if (this.isOwnProduct) {
      const canManageProducts = this._appState.hasRight([
        UserAction.ManageProducts
      ]);
      this.show.deleteButton = canManageProducts;
      this.show.addButton = canManageProducts;
      this.show.cloneButton = canManageProducts;
      this.show.sendToDirectoButton =
        canManageProducts && this._formData.canExport;
      this.show.getImageFromApollo =
        canManageProducts && this._formData.canExport;
    }
    this.show.pdfButton =
      !this.isMarketProduct && this._appState.hasModule(LxmAppModule.Pdf);
    this.show.dotMenu =
      this.show.deleteButton ||
      this.show.addButton ||
      this.show.cloneButton ||
      this.show.pdfButton ||
      this.show.sendToDirectoButton;
  }

  public get data() {
    return this.state.dataSubject.value;
  }

  public get imagesList() {
    return this.data?.images;
  }

  public get mainImage() {
    return this.imagesList[0];
  }

  public get additionalImages() {
    return this.imagesList.filter(x => x.id !== this.mainImage.id);
  }

  public get nutritionInfo() {
    return this.data?.markingAndNutritionInfo;
  }

  public get foodSuggestions() {
    return this.data?.foodSuggestions;
  }

  public get allergens() {
    return this.nutritionInfo?.allergens || [];
  }

  public get awards() {
    return this.data?.awards;
  }

  public openValidationDialog() {
    this._action.openDialog(
      ProductValidationDialogComponent,
      {
        ...appSettings.DIALOG_SIZES.M,
        data: {
          routeData: this.route.snapshot.data,
          productId: this.productId
        }
      },
      () => {}
    );
  }

  public openExportProductsDialog(): void {
    if (!this.productId) {
      return;
    }

    const { countries, currencies, defaultCurrency } = this._formData || {};

    const data = {
      formData: {
        countries: countries,
        currencies: currencies,
        defaultCurrency: defaultCurrency
      },
      productIds: [this.productId]
    };

    this._action.openDialog(
      ExportProductsPdfDialog,
      {
        ...appSettings.DIALOG_SIZES.AUTO,
        data: data
      },
      res => {
        //
      }
    );
  }

  public clone() {
    const image = this.route.snapshot.data.productCard.images?.find(
      (x: { isMaster: boolean }) => x.isMaster == true
    );
    const prod = {
      productId: this.productId,
      name: this.route.snapshot.data.productCard.baseInfo.fullName,
      ean: this.route.snapshot.data.productCard.units.find(
        (x: { type: { id: ProductUnitType } }) =>
          x.type.id == ProductUnitType.BaseUnit
      ).defaultEan,
      imageUrl: image?.url,
      thumbnailUrl: image?.thumbnailSmallUrl,
      assortmentStatus: this.route.snapshot.data.productCard.assortments?.find(
        (x: { isCurrent: boolean }) => x.isCurrent == true
      )?.status
    };
    this._action.openDialog(
      ProductCloneDialogComponent,
      {
        data: {
          product: prod
        },
        ...appSettings.DIALOG_SIZES.M
      },
      productId => {
        if (!productId) {
          return;
        }
        this._router.navigate(["/products/clone", productId], {
          replaceUrl: true
        });
      }
    );
  }

  public delete() {
    const confirmOptions = this._action.createConfirm(
      "delete-modal-image",
      "cards.products.product_base_info.remove_product_title",
      "cards.products.product_base_info.remove_product_message"
    );

    this._action.confirm(confirmOptions, () => {
      this._productService.deleteProduct(this.productId).result(null, () => {
        this._router.navigate(["/products"]);
      });
    });
  }

  public sendToDirecto() {
    if (this.sendToDirectoButtonDisabled) {
      this._lxmMessage.error({
        message:
          "cards.products.product_base_info.product_info_is_not_filled_enough"
      });

      return;
    }

    const confirmOptions = this._action.createConfirm(
      "warning-modal-image",
      "cards.products.product_base_info.send_product_to_directo_title",
      "cards.products.product_base_info.send_product_to_directo_message"
    );

    this._action.confirm(confirmOptions, () => {
      this._productService.sendToDirecto(this.productId).result(
        null,
        res => {
          this._lxmMessage.ok({
            message:
              "cards.products.product_base_info.sent_product_to_directo_successful"
          });
        },
        err => {
          // this._lxmMessage.error({
          //   message: err.validationSummary || "cards.products.product_base_info.sent_product_to_directo_failed"
          // });
        }
      );
    });
  }

  public getImageFromApollo() {
    const confirmOptions = this._action.createConfirm(
      "warning-modal-image",
      "GetImageFromApollo",
      "You want to download image from Apollo"
    );

    this._action.confirm(confirmOptions, () => {
      this._productService.getImageFromApollo(this.productId).result(
        null,
        () => {
          this._lxmMessage.ok({
            message: "Image downloaded"
          });
          this._router.routeReuseStrategy.shouldReuseRoute = () => false;
          this._router.onSameUrlNavigation = "reload";
          this._router.navigate(["/products", this.productId]);
        },
        err => {
          // this._lxmMessage.error({
          //   message: err.validationSummary || "Image download failed."
          // });
        }
      );
    });
  }

  public getProductInfoFromApollo() {
    this._productService.getProductInfoFromApollo(this.productId).result(
      null,
      res => {
        this._lxmMessage.ok({ message: res.xml });
      },
      err => {
        // this._lxmMessage.error({
        //   message: err.validationSummary || "Error"
        // });
      }
    );
  }

  ngAfterViewInit() {
    this._updateSubscription = this.state.dataSubject.subscribe(() =>
      this._updatePictograms()
    );
  }

  ngOnDestroy() {
    this.state.resetData();
  }
}
