<div class="file-container" [class.no-files]="files.length < 1">
    <ng-content select="[validation-errors]"></ng-content>
    <ng-container *ngIf="showAddFileCondition && multiple ? true : files.length < 1">
      <input hidden type="file" #fileInput (change)="emitFiles($event.target.files)">
      <div class="add-file-container" fileDrop [multiple]="multiple" [accept]="accept" (onFileDropped)="emitFiles($event)">
        <div class="add-file-center">
          <span class="add-file-image"></span>
          <span class="add-file-text">
            {{ addFileText ? (addFileText | translate) : 'components.file_drop_box.drag_file_here' | translate}}
          </span>
        </div>
      </div>
    </ng-container>
    <div class="files-list mt10" *ngFor="let file of files; let i= index" (click)="$event.stopImmediatePropagation()">
      <div class="flex f-1 flex-col">
        <div class="flex f-1 flex-row align-center justify-between">
          <span class="files-list-text">
            {{ file.name }}
          </span>
          <lxm-button type="transparent"
                      class="delete-file"
                      icon="trash"
                      title="action.delete"
                      padding="8px 0"
                      (click)="deleteFile(i)">
          </lxm-button>
        </div>
        <div class="file-progress" *ngIf="file.progress !== undefined">
          <mat-progress-bar [mode]="progressMode" [value]="file.progress" [class.invalid]="file.progress < 0"></mat-progress-bar>
          <span class="file-progress-text">
            <ng-container *ngIf="!file.error && file.progress < 100">
              {{('components.file_drop_box.uploading' | translate) + " " + file.progress + "%"}}
            </ng-container>
            <ng-container *ngIf="file.progress === 100">
              {{((file.customText ? file.customText : 'components.file_drop_box.upload_success') | translate)}}
            </ng-container>
            <ng-container *ngIf="file.progress < 0 && file.error">
              {{ file.error | translate }}
            </ng-container>
          </span>
        </div>
      </div>
    </div>
  </div>
