import { Component, Inject, Optional } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  AuthenticationService,
  OfferService,
  ProductService,
  SegmentService
} from "src/app/_services";
import { Unit, UserAction } from "src/app/enum";
import { Guid } from "src/app/util/Guid";
import { ProductRetailerDetailsDialogBase } from "./product-retailer-details-dialog-base";
import { RetailerDetailsCopyDialog } from "./copy-dialog/retailer-details-copy.dialog";
import { LxmDialog } from "src/app/_helpers";
import { forkJoin } from "rxjs";
import { stringIsNullOrEmpty } from "src/app/util/fn";
import { ProductUnitCodeTypeGroup } from "src/app/enum/product-unit-code-type-group";

@Component({
  selector: "receiving-offer-product-retailer-details-dialog",
  host: { class: "dialog" },
  templateUrl: "./product-retailer-details.dialog.html",
  styleUrls: ["./product-retailer-details.dialog.scss"]
})
export class ProductRetailerDetailsDialogComponent extends ProductRetailerDetailsDialogBase {
  constructor(
    public dialogRef: MatDialogRef<ProductRetailerDetailsDialogBase>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    authService: AuthenticationService,
    private _offerService: OfferService,
    public _segmentService: SegmentService,
    private _productService: ProductService,
    private _lxmDialog: LxmDialog
  ) {
    super(dialogRef, data, _segmentService);
    this.readonly =
      data.readonly || !authService.hasRight([UserAction.ManageReceivedOffers]);
  }

  ngOnInit() {
    const data = this.data;
    this.form = new FormGroup({
      fullName: new FormControl(data.product.retailerDetails?.fullName),
      shortName: new FormControl(data.product.retailerDetails?.shortName),
      segment: new FormControl(data.product?.retailerDetails?.segment),
      segmentOther: new FormControl(),
      expiryInDays: new FormControl(data.product.retailerDetails?.expiryInDays),
      minExpiryOnDelivery: new FormControl(
        data.product.retailerDetails?.minExpiryOnDelivery
      ),
      expiryForTargetInDays: new FormControl(
        data.product.retailerDetails?.expiryForTargetInDays
      ),
      tags: new FormControl(data.product.retailerDetails?.tags),
      attributes: new FormControl(data.product.retailerDetails?.attributes),
      codes: new FormControl(
        this.getNormalizedCodes(data.product.retailerDetails?.codes) || []
      ),
      parameter1: new FormControl(data.product.retailerDetails?.parameter1),
      parameter2: new FormControl(data.product.retailerDetails?.parameter2),
      parameter3: new FormControl(data.product.retailerDetails?.parameter3),
      parameter4: new FormControl(data.product.retailerDetails?.parameter4),
      parameter5: new FormControl(data.product.retailerDetails?.parameter5),
      parameter6: new FormControl(data.product.retailerDetails?.parameter6),
      parameter7: new FormControl(data.product.retailerDetails?.parameter7),
      parameter8: new FormControl(data.product.retailerDetails?.parameter8),
      parameter9: new FormControl(data.product.retailerDetails?.parameter9),
      parameter10: new FormControl(data.product.retailerDetails?.parameter10),
      brand: new FormControl(data.product.retailerDetails?.brand),
      subBrand: new FormControl(data.product.retailerDetails?.subBrand),
      model: new FormControl(data.product.retailerDetails?.model),
      countryOfOrigin: new FormControl(
        data.product.retailerDetails?.countryOfOrigin
      ),
      markingPriceRequired: new FormControl(
        data.product.retailerDetails?.markingPriceRequired
      ),
      description: new FormControl(data.product.retailerDetails?.description),
      orderUnit: new FormControl(data.product.retailerDetails?.orderUnit),
      salesUnit: new FormControl(data.product.retailerDetails?.salesUnit),
      contentUnit: new FormControl(data.product.retailerDetails?.contentUnit),
      netContent: new FormControl(data.product.retailerDetails?.netContent),
      averageWeightInGrams: new FormControl(
        data.product.retailerDetails?.averageWeightInGrams
      ),
      drainedWeightInGrams: new FormControl(
        data.product.retailerDetails?.drainedWeightInGrams
      )
    });

    this.product = data.product;
    this.attributeOptions = data.attributes;

    console.log(this.product);

    this.tagsOptions = data.productTags.filter(
      x =>
        x.gs1Segments.length == 0 ||
        x.gs1Segments.some(q => this.productGs1SegmentId().includes(q))
    );

    this.codeTypeOptions = data.productUnitCodeTypes;
    this.visibleParameters = this.getVisibleParameters();
    this.segmentOtherOptions = data.segmentTreeOptions;
    this.countries = data.countries;
    this.markingPriceRequiredVisible = data.product.salesUnitId == Unit.Kg;
    this.productBrands = data.productBrands;
    this.productSubBrands = data.productSubBrands;
    this.units = data.units;

    if (data.product.gs1BrickCodeId) {
      this._segmentService
        .getSegmentsByGpcBrickCode(data.product.gs1BrickCodeId)
        .subscribe(res => {
          this.segmentOptions = res;
          this.segmentOptions.push(this._segmentOther);
          this.setSegmentValue();
        });
    } else {
      this.segmentOptions = [this._segmentOther];
      this.setSegmentValue();
    }

    this.form.get("segment").valueChanges.subscribe(segment => {
      this.showOtherSegments = segment?.id == Guid.empty;
      this.form.get("segmentOther").setValue(null);
    });
  }

  public get productEan() {
    return this.product.ean;
  }

  public get productSupplierCode() {
    return this.product.supplierCode;
  }

  public get productImage() {
    const { imageUrl } = this.product || {};
    return {
      url: imageUrl
    };
  }

  public get isAverageWeightVisible() {
    return this.form.get("salesUnit").value?.id == Unit.Kg;
  }

  public get isDrainedWeightVisible() {
    const contentUnitId = this.form.get("contentUnit").value?.id;
    return contentUnitId == Unit.Kg || contentUnitId == Unit.Liter;
  }

  public save(): void {
    this.isSaveLoading = true;

    const f = this.form.value;
    f.codes = this.getNormalizedCodes(f.codes);

    if (f.segment?.id == Guid.empty) {
      f.segment = f.segmentOther;
    }

    this._offerService
      .validateOfferProductRetailerDetails({
        retailerDetails: f
      })
      .result(
        this.form,
        res => {
          this.isSaveLoading = false;
          this.closeDialog(res);
        },
        e => {
          this.isSaveLoading = false;
        }
      );
  }

  public openRetailerDetailsCopyDialog() {
    this._lxmDialog.open(
      RetailerDetailsCopyDialog,
      {
        height: "auto",
        width: "800px",
        data: {
          product: {
            image: this.productImage,
            ean: this.productEan,
            id: this.product.productId,
            name: this.product.name,
            brand: this.product.brand,
            thumbnail: this.product.thumbnail,
            thumbnailContentType: this.product.thumbnailContentType,
            retailerAssortment: this.product.retailerAssortment
          },
          readonly: this.readonly
        },
        resolve: () => {
          const resolvers: any = {
            formData: this._productService.getRetailerDetailsFormData(
              this.productEan,
              null,
              [this.product.productId]
            )
          };

          return forkJoin(resolvers);
        }
      },
      res => {
        if (!res) {
          return;
        }

        const patch = {};

        if (res.copyTags) {
          patch["tags"] = res.tags || [];
        }

        if (res.copyCodes) {
          const codes = this.getNormalizedCodes(this.form.get("codes").value)
            .filter(x => x.group == ProductUnitCodeTypeGroup.SupplierCode)
            .map(x => ({ isSystemCode: false, ...x }))
            .concat(res.codes);

          this.form.get("codes").setValue(codes);
          patch["codes"] = codes || [];
        }

        if (res.copySegment) {
          const option = this.segmentOptions.find(x => x.id == res.segment.id);
          const otherOption = this.segmentOtherOptions.find(
            x => x.id == res.segment.id
          );

          if (option) {
            patch["segment"] = option;
          } else {
            patch["segment"] = this._segmentOther;
            patch["segmentOther"] = otherOption;
            this.showOtherSegments = true;
          }
        }

        if (res.copyExtraParameters) {
          Object.entries(res.extraParameters).filter(([key, value]) => {
            if (!stringIsNullOrEmpty(value)) {
              patch[key] = value;
              if (!this.visibleParameters.includes(key)) {
                this.visibleParameters.push(key);
              }
              return true;
            }
            return false;
          });
        }

        if (Object.keys(patch).length) {
          this.form.patchValue(patch);
        }
      }
    );
  }
}
