import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "loading-spinner",
  templateUrl: "./loading-spinner.component.html",
  styleUrls: ['./loading-spinner.scss']
})
export class LoadingSpinnerComponent {

  @Input() public type: string = 'default';

}
