import { Component, Inject, Optional, ViewChild } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UserService } from "src/app/_services/user.service";
import { IUsersFormData } from "src/app/resolve/lxm-settings/users.resolve";
import { LxmDialog } from 'src/app/_helpers';
import { IUserAssistantDto } from 'src/app/models';
import { DialogComponent } from 'src/app/components';
import { AppState } from "src/app/state/app.state";
import { UserAction, LxmAppModule } from "src/app/enum";

@Component({
    selector: "user-dialog",
    host: { 'class': 'dialog' },
    templateUrl: "./user.dialog.html",
    styleUrls: ["./user.dialog.scss"]
})
export class UserDialog {

    public assistantsOptions: IUserAssistantDto[] = [];
    public form: FormGroup;

    @ViewChild('dialog', { static: true, read: DialogComponent })
    private _dialog: DialogComponent;

    public canEdit = false;
    public saveLoading = false;
    public deleteLoading = false;
    public showLocations: boolean = false;

    constructor(
      public appState: AppState,
      private _userService: UserService,
      private _lxmDialog: LxmDialog,
      public dialogRef: MatDialogRef<UserDialog>,
      @Inject(MAT_DIALOG_DATA) public data: IUserDialogData
    ) {
        
      this.form = new FormGroup({
        id: new FormControl(),
        firstName: new FormControl(),
        lastName: new FormControl(),
        phoneCode: new FormControl(),
        phoneNumberShort: new FormControl(),
        idCode: new FormControl(),
        email: new FormControl(),
        jobTitle: new FormControl(),
        roles: new FormControl(),
        assistants: new FormControl(),
        portfolioDescription: new FormControl(),
        locations: new FormControl(),
      });

      this.showLocations = this.appState.hasModule(LxmAppModule.CreateSalesOrders)
        || this.appState.hasModule(LxmAppModule.CreatePurchaseOrders);
      
      this.canEdit = appState.hasRight([UserAction.ManageUsers]);
    }

    public ngAfterViewInit(): void {

      this._dialog.dataLoaded$
        .subscribe(state => {
          if (!state) {
            return;
          }
  
          this.assistantsOptions = this._formatAssistantsOptions(this.data.formData.assistants);
          
          if (this.data.user) {
            this.form.patchValue({
              id: this.data.user.id,
              firstName: this.data.user.firstName,
              lastName: this.data.user.lastName,
              phoneCode: this.data.user.phoneCode?.id,
              phoneNumberShort: this.data.user.phoneNumberShort,
              idCode: this.data.user.idCode,
              email: this.data.user.email,
              jobTitle: this.data.user.jobTitle,
              roles: this.data.user.roles?.map(x => x.roleId),
              assistants: this.data.user.assistants?.map(x => x.id),
              portfolioDescription: this.data.user.portfolioDescription,
              locations: this.data.user.locations?.map(x => x.locationId)
            });
          }
        });
    }

    public onNoClick(): void {
        this.dialogRef.close();
    }

    public save(): void {
        let f = this.form.value;

        let req = {
            id: f.id,
            firstName: f.firstName,
            lastName: f.lastName,
            phoneCodeId: f.phoneCode,
            phoneNumberShort: f.phoneNumberShort,
            idCode: f.idCode,
            email: f.email,
            jobTitle: f.jobTitle,
            roleIds: f.roles,
            assistantIds: f.assistants,
            portfolioDescription: f.portfolioDescription 
              ? Object.assign({}, ...f.portfolioDescription.map(o => ({ [o.language]: o.value }))) 
              : {},
            locationIds: f.locations
        };

        this.saveLoading = true;
        this._userService
            .save(req.id, req)
            .result(this.form, () => {
              this.data.usersCard.reload();
              this.saveLoading = false;
              this.dialogRef.close(true);
            }, error => {
              this.saveLoading = false;
            });
    }

    public delete(): void {
      this._lxmDialog.confirm({
            title: "cards.lexi_market_settings.lxm_settings_user_edit.delete.title",
            message: "cards.lexi_market_settings.lxm_settings_user_edit.delete.message",
            height: "200px"
        }, () => {
            let f = this.form.value;
            this.deleteLoading = true;
            this._userService.delete(f.id)
                .result(null, _ => {
                  this.data.usersCard.reload();
                  this.deleteLoading = false;
                  this.dialogRef.close(true);
                }, err => {
                    this.deleteLoading = false;
                });
        })
    }

    public closeDialog(): void {
        this.dialogRef.close();
    }

    private _formatAssistantsOptions(options: any[]) {
        if (!options) {
            return [];
        }
        return options.map(x => Object.assign({}, x, { label: x.fullName ? `${x.fullName} (${x.email})` : x.email }));
    }
}

interface IUserDialogData {
    formData: IUsersFormData,
    user: any,
    usersCard: any
}