<ng-container [formGroup]="formGroup" [readonly]="!canEdit">

    <form-field dialog label="cards.settings.product_management_settings.codes_edit.label.type" [required]="canEdit">
      <lxm-select select class="w-full"
          [searchable]="true"
          [options]="unitCodeTypes"
          labelField="translatedValue"
          [isTranslated]="true"
          value="id"
          for="codeType">
      </lxm-select>
      <validation-errors for="codeType"></validation-errors>
      <validation-errors for="codeTypeId"></validation-errors>
    </form-field>

    <form-field dialog label="cards.settings.product_management_settings.codes_edit.label.code_beginning" for="codeBeginning" type="input-text">
      <validation-errors for="codeBeginning"></validation-errors>
    </form-field>

    <form-field dialog label="cards.settings.product_management_settings.codes_edit.label.next_number" for="nextNumber" type="input-text" [required]="canEdit">
      <validation-errors for="nextNumber"></validation-errors>
    </form-field>

    <form-field dialog label="cards.settings.product_management_settings.codes_edit.label.last_number" for="lastNumber" type="input-text" [required]="canEdit">
      <validation-errors for="lastNumber"></validation-errors>
    </form-field>

    <form-field dialog label="cards.settings.product_management_settings.codes_edit.label.number_length" for="numberLength" type="input-text">
      <validation-errors for="numberLength"></validation-errors>
    </form-field>

    <form-field dialog label="cards.settings.product_management_settings.codes_edit.label.has_checksum">
      <slide-toggle toggle for="hasChecksum"></slide-toggle>
      <validation-errors for="hasChecksum"></validation-errors>
    </form-field>

</ng-container>