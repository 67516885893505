import { Component, OnInit, Input, Inject, ViewChild, AfterViewInit, Injector } from "@angular/core";
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService, ProductValidity, RetailerProductAssortmentService } from 'src/app/_services';
import { DialogComponent } from 'src/app/components/_layout/dialog/dialog.component';
import { LxmDialog } from 'src/app/_helpers/dialogs';
import { ActivatedRoute } from '@angular/router';
import { Subscription, forkJoin, Observable } from 'rxjs';
import { asArray, Bool, UserAction, ProductCardSection, StatusRule } from 'src/app/enum';
import { IProductCardFormData, IRetailChain, IProductCard, IProductBaseInfo, IProductCardRetailChain, IClvDto } from '../product-info/product-info.model';
import { nextSchemeDay } from 'src/app/_helpers/calculate';
import { AppState } from 'src/app/state/app.state';
import { appSettings } from 'src/app/app.settings';
import { LxmMessage } from 'src/app/_helpers';
import { IRetailerProductAssortment, IRetailerProductAssortmentFormData } from './retailer-product-assortment.model';
import { parentPort } from "worker_threads";
import { ProductCardBase } from "../product-card-base";
import { LOCAL_STORAGE_KEY } from "src/app/config";
import { joinAssortmentGroupNames } from "src/app/util/fn";

@Component({
  selector: "retailer-product-assortment-card",
  templateUrl: "./retailer-product-assortment.card.html",
  styleUrls: ['./retailer-product-assortment.card.scss']
})
export class RetailerProductAssortmentCard extends ProductCardBase<IRetailerProductAssortment[]> {

  public title = 'cards.products.product_assortment_management.title';

  public UserAction = UserAction;

  public cardSection = ProductCardSection.AssortmentSchemes;
  
  public table = [
    'user',
    'valid',
    'status',
    'assortment_groups',
  ];

  public productCard: IProductCard;
  public assortments: IRetailerProductAssortment[];
  public expandedElements = [];

  public formData: IProductCardFormData;

  @Input()
  public productId: string;

  public cardValid = true;
  public canEdit: boolean;

  private _loadBaseAssortmentsSubscription: Subscription;

  public isExpandedKey = LOCAL_STORAGE_KEY.PRODUCT_RETAILER_PRODUCT_ASSORTMENT_CARD_OPEN;

  constructor(
    injector: Injector,
    private _appState: AppState,
    private _dialog: LxmDialog,
    private _retailerProductAssortmentService: RetailerProductAssortmentService,
    route: ActivatedRoute,
    private _authService: AuthenticationService,
    public productValidity: ProductValidity) {
    super(injector, 'productRetailerAssortment', 'productRetailerAssortmentChanged');

    this.productCard = route.snapshot.data.productCard as IProductCard;
    this.assortments = this.productCard.retailerAssortments;
    this.formData = this.productCard.formData;
  }

  ngOnInit() {
    super.ngOnInit();
    this.canEdit = this._authService.hasRight([UserAction.ManageProducts]);
  }

  public openSchemaDialog(row?: IRetailerProductAssortment) {
    if ((row && !row.isEditable) || !this.canEdit) {
      return;
    }

    this._dialog.open(RetailerProductAssortmentBaseSchemaDialog, {
      ...appSettings.DIALOG_SIZES.L,
      data: {
        assortmentCard: this,
        schema: row
      },
      resolve: () => {
        const resolvers: any = {
          formData: this._retailerProductAssortmentService.getFormData(this.productId)
        };
        return forkJoin(resolvers);
      }
    });
  }

  public loadBaseAssortments(): void {
    if (this._loadBaseAssortmentsSubscription) {
      this._loadBaseAssortmentsSubscription.unsubscribe();
    }

    this._loadBaseAssortmentsSubscription = this._retailerProductAssortmentService
      .getGridData(this.productId)
      .subscribe(result => {
        this.assortments = result;
      }, err => {
        // todo
      });
  }

  public joinAssortmentGroupNames(row) {
    return joinAssortmentGroupNames(row);
  }

}

@Component({
  selector: "retailer-product-assortment-base-schema-dialog",
  host: { 'class': 'dialog' },
  templateUrl: "./template/retailer-product-assortment-schema.dialog.html",
  styleUrls: ['./template/retailer-product-assortment-schema.dialog.scss']
})
export class RetailerProductAssortmentBaseSchemaDialog {

  @ViewChild('dialog', { static: true, read: DialogComponent })
  private _dialog: DialogComponent;

  public saveLoading = false;
  public deleteLoading = false;
  public showAssortmentStatusReminder = true;

  public form: FormGroup;

  public assortmentGroupsList: FormArray;
  public selectedAssortmentGroupIds: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IProductAssortmentDialogData,
    public dialogRef: MatDialogRef<RetailerProductAssortmentBaseSchemaDialog>,
    private _retailerProductAssortmentService: RetailerProductAssortmentService,
    private _message: LxmMessage,
    public dialog: LxmDialog) {

    this.form = new FormGroup({
      id: new FormControl(),
      validFrom: new FormControl(),
      status: new FormControl(),
      assortmentGroups: new FormControl([]),
      parentTagId: new FormControl()
    });

    const normalizedSchema = this.normalizeData(this.data.schema);
    this.form.patchValue(normalizedSchema);
  }

  public assortmentGroups = {};
  public assortmentGroupTags = {};
  public assortmentGroupsByTags = {};
  public assortmentStatuses = [];
  public parentTags = [];
  public tags = {};

  public tagsByParentTag = [];

  public getTagsBySelectedParentTag() {
    const { parentTagId } = this.form.value;
    this.tagsByParentTag = this.tags?.[parentTagId] ?? [];
  }

  public ngAfterViewInit(): void {

    this._dialog.dataLoaded$
      .subscribe(state => {
        if (!state) {
          return;
        }

        const { formData: { assortmentGroups, assortmentGroupTags, assortmentStatuses, assortmentGroupsByTags, parentTags, tags }, schema } = this.data || {};

        this.assortmentGroups = assortmentGroups ?? {};
        this.assortmentStatuses = assortmentStatuses ?? [];
        this.assortmentGroupsByTags = assortmentGroupsByTags ?? {};
        this.assortmentGroupTags = assortmentGroupTags ?? {};
        this.tags = tags ?? {};
        this.parentTags = parentTags ?? [];

        this.form.patchValue({
          parentTagId: schema?.assortmentGroupTagId || this.parentTags[0]?.id || null,
          status: this.form.value?.status ?? assortmentStatuses?.find(x => x.statusRule == StatusRule.ActiveByDefault)
        }, { emitEvent: false });

        this.getTagsBySelectedParentTag();
      });

    this.form.get('status').valueChanges
      .subscribe(val => {
        if (this.assortmentStatuses?.find(x => x.id == val && x.statusRule == StatusRule.InactiveByDefault)) {
          this.assortmentGroups = []
          this.form.patchValue({
            assortmentGroups: this.assortmentGroups
          });
        }
      });
  }

  private normalizeData(data: IRetailerProductAssortment): any {
    const existingAssortment = this.data.assortmentCard.assortments?.[0];

    let result: any;

    if (data) {
      result = { ...data };
    } else if (existingAssortment) {
      result = { ...existingAssortment };
      result.id = null;
      result.validFrom = null;
    } else {
      result = <any>{};
    }

    result.assortmentGroups = result.assortmentGroups?.map(x => x.id) ?? [];

    result.validFrom = result.validFrom
      ? result.validFrom.editableDate
      : nextSchemeDay(existingAssortment?.validFrom.editableDate);

    return result;
  }

  public save(): void {
    this.saveLoading = true;
    const f = this.form.value;
    var statusId = f.status?.id ?? f.status;
    
    if (this.showAssortmentStatusReminder){
      if (this.assortmentStatuses?.find(x => x.id == statusId && x.statusRule == StatusRule.Remind)){
        this.showAssortmentStatusReminder = false;
        this._message.warning({
          message: 'enums.StatusRule.ReminderMessage'
        });
        this.saveLoading = false;
        return;
      }
    }

    const req = {
      productId: this.data.assortmentCard.productId,
      validFrom: f.validFrom,
      status: statusId,
      assortmentGroups: f.assortmentGroups,
      assortmentGroupTagId: f.parentTagId
    };

    this._retailerProductAssortmentService
      .save(this.data.assortmentCard.productId, f.id, req)
      .result(this.form, () => {
        this.data.assortmentCard.loadBaseAssortments();
        this.closeDialog();
      }, (err) => {

        this._message.error({
          message: err.validationSummary || 'common.error.save_failed',
        });

        this.saveLoading = false;
      });
  }

  public deleteScheme() {
    this.dialog.confirm({
      title: 'cards.products.product_assortment_management.delete.title',
      message: 'cards.products.product_assortment_management.delete.message'
    }, () => {
      this.deleteLoading = true;
      const id = this.data.schema.id;
      this._retailerProductAssortmentService.delete(this.data.assortmentCard.productId, id).result(this.form, () => {
        this.data.assortmentCard.loadBaseAssortments();
        this.closeDialog();
      }, () => {
        this.deleteLoading = false;
      });
    });
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public closeDialog() {
    this.dialogRef.close();
  }
}

interface IProductAssortmentDialogData {
  formData: IRetailerProductAssortmentFormData;
  assortmentCard: RetailerProductAssortmentCard;
  retailChains: IRetailChain[];
  schema: IRetailerProductAssortment;
}
