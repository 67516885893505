import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.layout.html',
  styleUrls: ['./home.layout.scss']
})
export class HomeLayout implements AfterViewInit {

  public isLoading = false;

  constructor() {
    this.isLoading = true;
  }

  ngAfterViewInit() {
    this.isLoading = false;
  }

}
