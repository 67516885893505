export enum SalesOrderStatus {
    Created = 1,
    Pending = 2,
    Negotiating = 4,
    Confirmed = 5,
    Rejected = 6,
    Cancelled = 7,
    Expired = 8,
    WaitingClientConfirm = 9,
    Completed = 10,
}

(SalesOrderStatus as any).__typeName = 'SalesOrderStatus';