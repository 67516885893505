import { Component, OnInit, OnChanges, Input, Optional,Injector, ViewChildren,QueryList } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { IClvDto } from 'src/app/cards/product/product-info/product-info.model';
import { EditableListBase } from '../editable-list-base';
import { SelectComponent } from 'src/app/components/form/select/select.component';
import { ClvService } from '../../../_services/clv.service';

@Component({
  selector: 'movie-format-list',
  templateUrl: './movie-format-list.component.html',
  styleUrls: ['./movie-format-list.component.scss']
})
export class MovieFormatListComponent extends EditableListBase implements OnInit, OnChanges {

  protected _clvacService: ClvService;

  @ViewChildren('formatSelect') formatSelect: QueryList<SelectComponent>;
  @ViewChildren('ratingSelect') ratingSelect: QueryList<SelectComponent>;

  @Input() public movieRatingFormatOptions: IClvDto[];

  protected get _defaultItem(): any {
    return {
      id: null,
      formatId: null,
      ratingId: null,
      isDeleted: false,
      isNew: true
    };
  }

  public table = {
    columns: ['format_column', 'rating_column', 'remove_column']
  }

  public get columns(): string[] {
    return this.table.columns;
  }

  constructor(injector: Injector, @Optional() controlContainer: ControlContainer) {
    super(controlContainer);
    this._clvacService = injector.get(ClvService);
  }

  protected _getNormalizedItem(item: any) {
    return {
      id: item.id,
      formatId: item.format?.id || item.formatId,
      ratingId: item.rating?.id || item.ratingId,
      isDeleted: item.isDeleted || false,
      isNew: item.isNew || false
    };
  }

  public ngAfterViewInit() {
    var ratingSelects = this.ratingSelect.toArray();
    this.formatSelect.forEach((select,i)=>{
      var formatId = select.formGroup.get(select.for).value;
      this._clvacService.getMovieRatings(formatId)
        .subscribe(data=>{
          ratingSelects[i].options = data;
          ratingSelects[i]._finalizeOptions();
        });
    });
  }

  public onFormatChanged(index:number, formatId:string)
  {
    this.ratingSelect.forEach((select,i)=>{
      if(i == index)
      {
         select.formGroup.get(select.for).patchValue(null);
         select.finalizedOptions = [];

         if(formatId)
         {
          this._clvacService.getMovieRatings(formatId)
            .subscribe(data=>{
              select.options = data;
              select._finalizeOptions();
            });
         }
      }
    })
  }
}
