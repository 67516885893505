import { Component, OnInit, OnChanges, Input, Optional } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { IClvDto } from 'src/app/cards/product/product-info/product-info.model';
import { AllergenContentType, asArray, asObject } from 'src/app/enum';
import { EditableListBase } from '../editable-list-base';

@Component({
  selector: 'product-allergens-list',
  templateUrl: './product-allergens-list.component.html',
  styleUrls: ['./product-allergens-list.scss']
})
export class ProductAllergensListComponent extends EditableListBase implements OnInit, OnChanges {

  protected get _defaultItem(): any {
    return {
      id: null,
      allergenId: null,
      contentType: null,
      isDeleted: false,
      isNew: true
    };
  }

  public table = {
    columns: ['allergen_name', 'allergen_contains', 'allergen_remove']
  }

  public get columns(): string[] {
    return this.table.columns;
  }

  @Input() public allergenOptions: IClvDto[];

  public containmentOptions = asArray(AllergenContentType);

  constructor(@Optional() controlContainer: ControlContainer) {
    super(controlContainer);
  }

  protected _getNormalizedItem(item: any) {
    return {
      id: item.id,
      allergen: item.allergen,
      allergenId: item.allergen?.id || item.allergenId,
      contentType: item.contentType,
      isDeleted: item.isDeleted || false,
      isNew: item.isNew || false
    };
  }

  public isItemDeleted(item) {
    return item.value?.isDeleted || false;
  }
}
