<svg-icon
  *ngIf="icon"
  class="size-15x"
  [src]="icon"
  [svgStyle]="{ width: 'inherit', height: 'inherit' }"
  viewBox="auto"
></svg-icon>

<span *ngIf="label" class="font-display text-sm text-grey-100">{{
  label
}}</span>
<ng-content></ng-content>
