import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from "@ngx-translate/core";
import { IRetailerOption } from "src/app/models";
import { ProductField, ProductUnitField, ProductUnitType, translate as translateEnum } from 'src/app/enum';
import { ProductService, ProductValidity } from 'src/app/_services/product.service';
import { SystemClvNamePipe, TranslateEnumPipe } from "src/app/pipes";

@Component({
    selector: 'product-validation-dialog',
    host: { 'class': 'dialog' },
    templateUrl: './product-validation.dialog.html',
    styleUrls: ['./product-validation.dialog.scss']
  })
  export class ProductValidationDialogComponent {

    public formGroup = new FormGroup({
      retailers: new FormControl(this.validatedRetailers),
    });

    public validationInstructions = {
      prelude: 'cards.products.product_validation.info_array.prelude',
      text: [
        'cards.products.product_validation.info_array.text1',
        'cards.products.product_validation.info_array.text2',
        'cards.products.product_validation.info_array.text3'
      ]
    };

    public retailerOptions: IRetailerOption[] = [];
    public productUnits = [];
    public productId: string;
  
    constructor(
      @Inject(MAT_DIALOG_DATA) private _data: any,
      public dialogRef: MatDialogRef<ProductValidationDialogComponent>,
      private _productService: ProductService,
      private _productValidity: ProductValidity,
      private _translateService: TranslateService,
      private _translateEnumPipe: TranslateEnumPipe
    ) {
      const { routeData, productId } = this._data;

      this.retailerOptions = routeData?.productCard?.formData?.retailChains || [];
      this.productUnits = routeData?.productCard?.units || [];
      this.productId = productId;
    }

    public closeDialog() {
      this.dialogRef.close();
    }

    public get showSummary() {
      return this._productValidity.shouldValidate;
    }

    public get hasSummary() {
      return this._productValidity.requiredByRetailChainNames ? true : false;
    }

    public get validatedRetailers() {
      return this._productValidity.validatedRetailers;
    }

    public get invalidFields() {
      return this._productValidity.invalidFieldKeys?.map(key => translateEnum(ProductField, key, this._translateService)).join(', ');
    }

    public get invalidUnitFields() {
      return this._productValidity.invalidUnitIds.map(x => {
        const unitType = this.productUnits.find(u => u.id === x)?.type;
        return this._translateEnumPipe.transform(unitType?.id, 'ProductUnitType') + ' (' + this._productValidity.invalidUnitFieldsById(x)?.map(key => translateEnum(ProductUnitField, key, this._translateService)).join(', ') + ') ';
      })
    }

    public get isProductValid() {
      return this._productValidity.isProductValid;
    }
    
    public isValidateLoading = false;
    public validateRetailers() {
      this.isValidateLoading = true;
      const retailers = this.formGroup.get('retailers').value;
      const retailerIds = retailers?.map(x => x.id);
      this._productValidity.setValidatedRetailers(retailers);
      this._productService.validateRetailChainFieldRules(this.productId, retailerIds).result(null, res => {
        this._productValidity.shouldValidate = true;
        this._productValidity.set(res);
        this.isValidateLoading = false;
      }, error => {
        this.isValidateLoading = false;
      });
    }

    public resetValidation() {
      this._productService.emptyRetailChainFieldRules();
      this._productValidity.emptyValidatedRetailers();
    }
  
  }
  