import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { LocalStorage } from "src/app/_helpers";
import { ClientsState } from "../../clients.state";
import { IClvDto } from "src/app/cards/product/product-info/product-info.model";
import { IClientListFormData } from "src/app/_services/clients.service";

@Component({
  selector: "clients-search-card",
  templateUrl: './clients-search.card.html',
  styleUrls: ['./clients-search.card.scss']
})
export class ClientsSearchCard {

  public formData;
  public clientTagOptions: IClvDto[];

  @Output()
  public search = new EventEmitter();

  @Input()
  public isOpen = false;

  @Input()
  private localStorageKey: string;

  constructor(
    private _clientsState: ClientsState,
    route: ActivatedRoute
  ) {
    this.formData = route?.snapshot?.data?.formData as IClientListFormData;
    this.clientTagOptions = this.formData.clientTags;
  }

  public get searchData() {
    return this._clientsState.searchData;
  }

  public get isLoading() {
    return this._clientsState.isSearchLoading;
  }

  public toggleOpen(isOpen: boolean) {
    this.isOpen = isOpen;
    LocalStorage.set(this.localStorageKey, isOpen);
  }

  public getItemClass(item: any) {
    return `color-tag color-${item.color}`;
  }

  public ngOnInit(): void {
    this.submit();
  }

  public submit() {
    const val = this._clientsState.serializeSearchData();
    this.search.emit(val);
  }
}