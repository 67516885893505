import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IProductCard, IProductCardFormData, IProductsSearchFormData, IProductListFilterDataDto, IRetailerProductsSearchFormData } from '../cards/product/product-info/product-info.model';
import { ResolveBase } from './resolve-base';
import { AppState } from '../state/app.state';
import { PAGE_SIZE } from 'src/app/config/pagination';
import { LxmAppModule } from '../enum';
import { LOCAL_STORAGE_KEY } from '../config';
import { MarketSearchDataService, ProductsSupplierSearchDataService, ProductsRetailerSearchDataService } from '../_services';
import { LocalStorage } from "src/app/_helpers/local-storage";


@Injectable()
export class ProductCardResolve extends ResolveBase {
  req(route: ActivatedRouteSnapshot): Observable<any> {
    return this.http.get(`api/products/${route.paramMap.get('productId')}`) as Observable<IProductCard>;
  }
}

@Injectable()
export class CloneProductCardResolve extends ResolveBase {
  req(route: ActivatedRouteSnapshot): Observable<any> {
    return this.http.get(`api/products/clone/${route.paramMap.get('productId')}`) as Observable<IProductCard>;
  }
}

@Injectable()
export class ProductCardFormDataResolve implements Resolve<any> {

  constructor(private _http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot): Observable<IProductCardFormData> {
    return this._http.get(`api/products/cardFormData`) as Observable<IProductCardFormData>;
  }
}

@Injectable()
export class ProductListFormDataResolve implements Resolve<any> {

  constructor(private _http: HttpClient, private _state: AppState) { }

  resolve(route: ActivatedRouteSnapshot) {
    const forType = this._state.hasModule(LxmAppModule.BuyerPimExtensions) ? 'forRetailer' : 'forSupplier';

    return this._http.get<IProductsSearchFormData | IRetailerProductsSearchFormData>(`api/products/listFormData/${forType}`);
  }
}

@Injectable()
export class ProductListDataResolve implements Resolve<any> {

  constructor(
    private _http: HttpClient, 
    private _state: AppState, 
    private _supplierSearchDataService: ProductsSupplierSearchDataService,
    private _retailerSearchDataService: ProductsRetailerSearchDataService
    ) { }

  resolve(route: ActivatedRouteSnapshot) {

    const hasBuyerPimExtensions = this._state.hasModule(LxmAppModule.BuyerPimExtensions);
    const forType = hasBuyerPimExtensions ? 'forRetailer' : 'forSupplier';

    let searchData: any = hasBuyerPimExtensions ? this._retailerSearchDataService.serializeSearchData() : this._supplierSearchDataService.serializeSearchData();

    return this._http.post(`api/products/${forType}`, searchData, { params: { $top: `${PAGE_SIZE[LOCAL_STORAGE_KEY.PAGE_SIZE_PRODUCTS_LIST]}`, $count: 'true' } });
  }
}

@Injectable()
export class MarketProductListDataResolve implements Resolve<any> {

  constructor(private _http: HttpClient, private _state: AppState, private _marketSearchDataService: MarketSearchDataService) { }

  resolve(route: ActivatedRouteSnapshot) {
    let searchData: any = this._marketSearchDataService.serializeSearchData();
    return this._http.post(`api/products/market`, searchData, { params: { $top: `${PAGE_SIZE[LOCAL_STORAGE_KEY.PAGE_SIZE_MARKET_PRODUCTS_LIST]}`, $count: 'true' } });
  }
}

@Injectable()
export class ProductListCachedFilterDataResolve implements Resolve<any> {

  constructor(private _http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot): Observable<IProductListFilterDataDto> {
    return this._http.get(`api/products/cachedfilterData/${route.paramMap.get("searchKey")}`) as Observable<any>;
  }
}
