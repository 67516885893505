import { Component, Input, HostBinding } from "@angular/core";

@Component({
  selector: "no-content",
  templateUrl: "./no-content.component.html",
  styleUrls: ['./no-content.component.scss']
})
export class NoContentComponent {

  @Input() public image: string = 'no-image';
  @Input() public heading: string;
  @Input() public text: string;
  @Input() public iconStyle: string;

  @HostBinding('class.inline')
  @Input() public inline = false;

  public style: string;

  ngOnInit() {
    const imageUrl = `background-image: url('/assets/img/icons/no-content/${this.image}.svg');`;
    this.style = imageUrl + this.iconStyle;
  }

}

