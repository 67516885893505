import { registry } from "src/app/enum"

export enum ClientExpiryRuleOperator {
  Between = 1,
  NotBetween = 2,
  EqualTo = 3,
  NotEqualTo = 4,
  GreaterThan = 5,
  LessThan = 6,
  GreaterThanOrEqualTo = 7,
  LessThanOrEqualTo = 8,
}

(ClientExpiryRuleOperator as any).__typeName = 'ClientExpiryRuleOperator';

registry[(<any>ClientExpiryRuleOperator).__typeName] = ClientExpiryRuleOperator;
