import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ISupplierProductAssortment, ISupplierProductAssortmentFormData } from '../cards/product/product-assortment-supplier/supplier-product-assortment.model';
import { IRetailerProductAssortmentFormData } from '../cards/product/product-assortment-retailer/retailer-product-assortment.model';

@Injectable({ providedIn: 'root' })
export class SupplierProductAssortmentService {

  constructor(private _http: HttpClient) { }

  public save(productId: string, id: string, data: any): Observable<string> {
    return this._http.post<string>(`api/products/${productId}/assortment/supplier/${id || ''}`, data);
  }

  public delete(productId: string, id: string): Observable<string> {
    return this._http.delete<string>(`api/products/${productId}/assortment/${id}`);
  }

  public getGridData(productId: string) {
    return this._http.get<ISupplierProductAssortment[]>(`api/products/${productId}/assortment/supplier/griddata`);
  }

  public getFormData(productId: string) {
    return this._http.get<ISupplierProductAssortmentFormData>(`api/products/${productId}/assortment/supplier/formData`);
  }
}

@Injectable({ providedIn: 'root' })
export class RetailerProductAssortmentService {

  constructor(private _http: HttpClient) { }

  public save(productId: string, id: string, data: any): Observable<string> {
    return this._http.post<string>(`api/products/${productId}/assortment/retailer/${id || ''}`, data);
  }

  public delete(productId: string, id: string): Observable<string> {
    return this._http.delete<string>(`api/products/${productId}/assortment/${id}`);
  }

  public getGridData(productId: string) {
    return this._http.get<ISupplierProductAssortment[]>(`api/products/${productId}/assortment/retailer/griddata`);
  }

  public getFormData(productId: string) {
    return this._http.get<IRetailerProductAssortmentFormData>(`api/products/${productId}/assortment/retailer/formData`);
  }
}
