import { Component, Inject } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { LxmMessage, LocalStorage } from "src/app/_helpers";
import { ProductService } from "src/app/_services";
import { appSettings } from "src/app/app.settings";
import { LOCAL_STORAGE_KEY } from "src/app/config";

@Component({
    selector: 'export-products-pdf-dialog',
    host: { 'class': 'dialog' },
    templateUrl: './export-products-pdf.dialog.html'
  })
  export class ExportProductsPdfDialog {
  
    public formData;
  
    public formGroup: FormGroup;
  
    public countryOptions = [];
    public languageOptions = appSettings.languages;
    public currencyOptions = [];
    public selectedProductIds = [];
  
    public exportOrDownloadLoading = false;
  
    constructor(
      public dialogRef: MatDialogRef<ExportProductsPdfDialog>, 
      private _productService: ProductService,
      private _msg: LxmMessage,
      @Inject(MAT_DIALOG_DATA) public data) { 
        const { formData: { countries, defaultCurrency, currencies }, productIds } = this.data || {};
  
        this.countryOptions = countries || [];
        this.currencyOptions = currencies || [];
        this.selectedProductIds = productIds;
  
        const savedCurrencyId = LocalStorage.getValue(LOCAL_STORAGE_KEY.PRODUCT_PDF_EXPORT_CURRENCY);
        const savedLanguageId = LocalStorage.getValue(LOCAL_STORAGE_KEY.PRODUCT_PDF_EXPORT_LANGUAGE);
        const savedCountryId = LocalStorage.getValue(LOCAL_STORAGE_KEY.PRODUCT_PDF_EXPORT_COUNTRY);
        const savedIncludePrices = LocalStorage.getValue(LOCAL_STORAGE_KEY.PRODUCT_PDF_EXPORT_INCLUDE_PRICES);
  
        const includePrices = savedIncludePrices ? true : false;
  
        this.formGroup = new FormGroup({
          language: new FormControl(this.languageOptions.find(x => x.id === savedLanguageId) || this.languageOptions[0]),
          includePrices: new FormControl(includePrices),
          priceListCountryId: new FormControl(this.countryOptions.find(x => x.id === savedCountryId)?.id),
          currencyId: new FormControl(this.currencyOptions.find(x => x.id === savedCurrencyId)?.id || defaultCurrency?.id || this.currencyOptions[0]?.id)
        });
      }
  
    public saveLocalValue(value: any, formKey: string) {
  
      switch(formKey) {
        case 'language':
          LocalStorage.set(LOCAL_STORAGE_KEY.PRODUCT_PDF_EXPORT_LANGUAGE, value.id);
          break;
        case 'includePrices':
          LocalStorage.set(LOCAL_STORAGE_KEY.PRODUCT_PDF_EXPORT_INCLUDE_PRICES, value.checked);
          break;
        case 'priceListCountryId':
          LocalStorage.set(LOCAL_STORAGE_KEY.PRODUCT_PDF_EXPORT_COUNTRY, value.id);
          break;
        case 'currencyId':
          LocalStorage.set(LOCAL_STORAGE_KEY.PRODUCT_PDF_EXPORT_CURRENCY, value.id);
          break;
      }
  
    }
  
    public get includePrices() {
      return this.formGroup.get('includePrices').value;
    }
  
    public exportProducts() {
      const form = this.formGroup.value;
  
      const req = {
        date: null,
        language: form.language?.value,
        includePrice: form.includePrices,
        currencyId: form.includePrices ? form.currencyId : null,
        priceListCountryId: form.includePrices ? form.priceListCountryId : null,
        productIds: this.selectedProductIds
      }
  
      this.exportOrDownloadLoading = true;
  
      this._productService.exportProductsPdf(req).result(this.formGroup, res => {
        this._productService.downloadProductPdf(res.id);
        this.exportOrDownloadLoading = false;
      }, e => {
        this._msg.error({
          message: 'global.generic_error'
        })
        console.error(e);
        this.exportOrDownloadLoading = false;
      })
    }
  
    public onNoClick(): void {
      this.dialogRef.close();
    }
  
    public closeDialog() {
      this.dialogRef.close();
    }
  
  }
  