import { Component, ChangeDetectorRef, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
import { DataTableService } from "src/app/_services/data-table.service";
import { ClvTableComponent, IClvItem, IClvDataModel } from "src/app/components/_partials/settings/clv-table/clv-table.component";
import { ClassificatorValueDialog } from "src/app/components/_partials/settings/clv-table/dialogs/classificator-value.dialog";
import { ISettingsFormData } from "src/app/resolve/settings-formdata.resolve";
import { asArray, CampaignTypeValueType, CampaignTypeVatType, UserAction, TextFilterValueType } from 'src/app/enum';
import { BehaviorSubject } from 'rxjs';
import { IClvDto } from 'src/app/cards/product/product-info/product-info.model';
import { appSettings } from 'src/app/app.settings';

@Component({
  selector: "product-clv-table",
  templateUrl: "./product-clv-table.component.html",
  styleUrls: ["../clv-table.component.scss"]
})
export class ProductClvTableComponent extends ClvTableComponent {

  public CampaignTypeValueType = CampaignTypeValueType;
  public CampaignTypeVatType = CampaignTypeVatType;
  public TextFilterValueType = TextFilterValueType;
  public UserAction = UserAction;

  public formData: ISettingsFormData;

  public campaignTypeValueTypes = asArray(CampaignTypeValueType);
  public campaignTypeVatTypes = asArray(CampaignTypeVatType);

  @Input() public title: string;

  protected colPropertyMap = {
    'manufacturerAddress': 'address',
    'manufacturerGln': 'gln',
    'logisticsPartnerAddress': 'address',
    'logisticsPartnerIsDefault': 'isDefault'
  };

  constructor(
    private _dialog: MatDialog,
    private _route: ActivatedRoute,
    dataService: DataTableService<IClvItem, IClvDataModel>,
    public translate: TranslateService,
    cd: ChangeDetectorRef) {
    super(dataService, cd);

    this.formData = this._route.snapshot.data.formData;
    this.onRowClick$.subscribe(row => this.openClassificatorValueDialog(row));
  }

  public getCodeTypeName(clv: any): IClvDto {
    if (!clv.typeId) {
      return;
    }
    return this.formData.unitCodeTypes.find(x => x.id === clv.typeId);
  }

  public getNextNumber(clv: any): string {
    if (!clv.nextNumber) {
      return;
    }

    return String(clv.nextNumber).padStart(clv.numberLength, "0");
  }

  public getCodeFormat(clv: any): string {
    return `${clv.codeBeginning || ""}{${String(clv.lastNumber).padStart(clv.numberLength, "0")}}${clv.hasChecksum ? "{9}" : ""}`
  }

  public openClassificatorValueDialog(clv: any): void {
    if (this._dialog.openDialogs.length !== 0) {
      return;
    }

    const dialogRef = this._dialog.open(ClassificatorValueDialog, {
      ...appSettings.DIALOG_SIZES.L,
      restoreFocus: false,
      data: {
        parent: this,
        row: clv
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }

      this.load();
    });
  }
}
