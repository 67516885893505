import {
  Component,
  Input,
  OnInit,
  ContentChildren,
  QueryList,
  AfterContentInit,
  TemplateRef,
  forwardRef,
  Inject,
  ViewEncapsulation,
  HostBinding,
  HostListener
} from "@angular/core";
import {
  LxmTreeService,
  ILxmTreeNode
} from "src/app/_services/lxm-tree.service";
import { LxmTreeColumnDefDirective } from "./lxm-tree-def.directive";
import { BehaviorSubject, Observable } from "rxjs";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "lxm-tree",
  templateUrl: "./lxm-tree.component.html",
  styleUrls: ["lxm-tree.scss"],
  encapsulation: ViewEncapsulation.None
})
export class LxmTreeComponent implements OnInit, AfterContentInit {
  @ContentChildren(LxmTreeColumnDefDirective)
  private _columnDefs: QueryList<LxmTreeColumnDefDirective>;

  private _nodeComponents: { [index: string]: LxmTreeNodeComponent } = {};

  private _nodesByParentId: { [index: string]: ILxmTreeNode[] } = {};

  private _iterator: LxmTreeIterator = new LxmTreeIterator(this);

  private _childrenSubject: BehaviorSubject<
    LxmTreeIterator
  > = new BehaviorSubject<LxmTreeIterator>(this._iterator);

  @Input() public dataUrl: string;

  @Input()
  public initialData: any = null;

  @Input()
  public displayColumns: string[];

  public headerRefs: { [index: string]: TemplateRef<any> };

  public cellRefs: { [index: string]: TemplateRef<any> };

  public children$: Observable<
    LxmTreeIterator
  > = this._childrenSubject.asObservable();

  private ROOT: string = "__root";

  constructor(private _segmentTreeService: LxmTreeService, public route: ActivatedRoute) {
  }

  public ngOnInit(): void {
    this.load(null, this.initialData);
  }

  public ngAfterContentInit(): void {
    this.headerRefs = {};
    this.cellRefs = {};
    this._columnDefs.forEach(x => {
      this.headerRefs[x.colName] = x.headerDef.templateRef;
      this.cellRefs[x.colName] = x.cellDef.templateRef;
    });
  }

  public addNode(nodeComponent: LxmTreeNodeComponent): void {
    let id = nodeComponent.node ? nodeComponent.node.id : "";
    let expanded = this._nodeComponents[id]?.expanded || false;
    this._nodeComponents[id] = nodeComponent;
    nodeComponent.expanded = expanded;

    // TODO: refactor and optimize, if query amount leads to performance problems
    if (nodeComponent.expanded) {
      this.load(nodeComponent.node.id);
    }
  }

  public getNode(id: string): LxmTreeNodeComponent {
    return this._nodeComponents[id];
  }

  public getChildNodes(parentId: string): ILxmTreeNode[] {
    return this._nodesByParentId[parentId || this.ROOT];
  }

  public isVisible(node: ILxmTreeNode): boolean {
    if (!node.parentId) {
      return true;
    }

    let current = node.parentId;
    while (current) {
      let nodeComp = this.getNode(current);
      if (!nodeComp.expanded) {
        return false;
      }
      current = nodeComp.node.parentId;
    }

    return true;
  }

  public load(id: string, initialData: any = this.initialData) {
    if (initialData) {
        this._nodesByParentId[this.ROOT] = initialData;
        this._childrenSubject.next(this._iterator);
        this.initialData = null;
    } else {
      this._segmentTreeService
        .getSegments(this.dataUrl, id)
        .subscribe(response => {
          this._nodesByParentId[id || this.ROOT] = response;
          this._childrenSubject.next(this._iterator);
          if (!id) {
            return;
          }
          this.getNode(id).node.isParent = response && response.length > 0;
        });
    }
  }

  public trackById(index: number, element: ILxmTreeNode) {
    return element ? element.id : null;
  }
}

class LxmTreeIterator implements Iterable<ILxmTreeNode> {
  constructor(private _tree: LxmTreeComponent) {}

  *[Symbol.iterator](): Iterator<ILxmTreeNode> {
    let current = {
      prev: null,
      item: null,
      index: 0,
      children: this._tree.getChildNodes(null)
    };

    while (current != null) {
      if (current.children && current.children.length > current.index) {
        let child = current.children[current.index];
        yield child;

        current = {
          prev: current,
          index: 0,
          item: child,
          children: this._tree.getChildNodes(child.id)
        };
      } else if (current.prev) {
        current = current.prev;
        current.index++;
      } else {
        break;
      }
    }
  }
}

@Component({
  selector: "tr[lxm-tree-node]",
  host: { 'class': 'lxm-tree-node' },
  templateUrl: "./lxm-tree-node.component.html"
})
export class LxmTreeNodeComponent implements OnInit {
  private _isLoaded: boolean;

  @Input()
  public node: ILxmTreeNode;

  @Input()
  public displayColumns: string[];

  @Input()
  public cellRefs: { [index: string]: TemplateRef<any> };

  public level: number;

  @HostBinding('class.parent-node') public get isParent() {
    return this.node?.isParent;
  }
  @HostBinding('class.expanded') @Input() public expanded: boolean;

  constructor(
    @Inject(forwardRef(() => LxmTreeComponent))
    protected _tree: LxmTreeComponent
  ) {}

  public ngOnInit() {
    this._tree.addNode(this);
  }

  @HostListener('click') public open() {
    if (!this.node.isParent) {
      return;
    }

    this.expanded = !this.expanded;

    if (this._isLoaded) {
      return;
    }

    this._isLoaded = true;
    this._tree.load(this.node.id);
  }
}
