import { Component, HostBinding, Input, ViewEncapsulation } from "@angular/core";
import { label, registry } from 'src/app/enum';

@Component({
  selector: "progress-bar",
  templateUrl: "./progress-bar.component.html",
  styleUrls: ['./progress-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProgressBarComponent {

  @Input() public value: number = 0;

  @Input() public color: string;

  public get fillColor() {

    if (this.color) {
      return this.color;
    }

    if (this.value <= 25) {
      return 'watermelon';
    }
    if (this.value <= 50) {
      return 'tangerine';
    }
    if (this.value <= 100) {
      return 'dark-sea-green';
    }
  }

}

