<lxm-dialog [dialogRef]="dialogRef" [formGroup]="formGroup">
    <span header-title>{{'cards.products.export_pdf_dialog.title' | translate}}</span>
  
    <div body>
      <div class="row">
        <div class="col-12 no-padding">
            <form-field dialog label="cards.products.export_pdf_dialog.label.language">
                <lxm-select 
                    select 
                    for="language" 
                    [options]="languageOptions" 
                    (change)="saveLocalValue($event, 'language')"
                    [clearable]="false">
                </lxm-select>
            </form-field>

            <form-field dialog label="cards.products.export_pdf_dialog.label.include_prices">
                <slide-toggle 
                    toggle 
                    for="includePrices"
                    (change)="saveLocalValue($event, 'includePrices')">
                </slide-toggle>
            </form-field>

            <ng-container *ngIf="includePrices">
                <form-field dialog label="cards.products.export_pdf_dialog.label.pricelist_country">
                    <lxm-select 
                        select 
                        for="priceListCountryId" 
                        value="id" 
                        labelField="translatedName"
                        [isTranslated]="true"
                        [options]="countryOptions" 
                        [clearable]="false" 
                        [searchable]="true"
                        (change)="saveLocalValue($event, 'priceListCountryId')">
                    </lxm-select>
                </form-field>
    
                <form-field dialog label="cards.products.export_pdf_dialog.label.currency">
                    <lxm-select 
                        select 
                        for="currencyId"
                        value="id"
                        labelField="value"
                        [options]="currencyOptions"
                        (change)="saveLocalValue($event, 'currencyId')"
                        [clearable]="false">
                    </lxm-select>
                </form-field>
            </ng-container>

        </div>

      </div>
  
    </div>
  
    <section footer>
      <lxm-button type="primary" label="action.export" (click)="exportProducts()" [loading]="exportOrDownloadLoading"></lxm-button>
      <lxm-button type="secondary" class="ml10" label="action.cancel" (click)="closeDialog()"></lxm-button>
    </section>
  
  </lxm-dialog>
  