import { Component, Inject, Optional, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AssortmentSchemesService, AuthenticationService } from 'src/app/_services';
import { finalize } from 'rxjs/operators';
import { DialogComponent } from 'src/app/components/_layout/dialog/dialog.component';
import { UserAction } from 'src/app/enum';
import { AssortmentGroupTagsService } from 'src/app/_services/assortment-group-tags.service';
import { ActionHelper } from 'src/app/_helpers/action';

@Component({
  selector: "assortment-group-tags-dialog",
  host: { 'class': 'dialog' },
  templateUrl: './assortment-group-tags.dialog.html',
  styleUrls: ['./assortment-group-tags.dialog.scss']
})
export class AssortmentGroupTagsDialogComponent {

  public UserAction = UserAction;

  public form: FormGroup;

  public isNew: boolean;
  public canEdit: boolean = true;

  public tags = [];

  public actionLoading = {};

  @ViewChild('dialog', { static: true, read: DialogComponent })
  private _dialog: DialogComponent;

  constructor(
    private _assortmentSchemesService: AssortmentSchemesService,
    public dialogRef: MatDialogRef<AssortmentGroupTagsDialogComponent>,
    private _action: ActionHelper,
    authService: AuthenticationService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  public get selection() {
    return this.data.selected;
  }

  public get assortmentSchemeId() {
    return this.data.assortmentSchemeId;
  }

  public get tagsMap() {
    return this.data.tagsMap;
  }

  public ngAfterViewInit(): void {
    this._dialog.dataLoaded$
      .subscribe(state => {
        if (!state) {
          return;
        }

        for(let item of this.selection) {
          const tags = this.tagsMap?.[item.segmentId]?.[item.assortmentGroupId];
          if (tags?.length) {
            for (let tag of tags) {
              this.selectedTagIdsSet.add(tag.assortmentGroupTagId);
            }
          } 
        }

        this.tags = this.data.tags.map(x => {
          Object.assign(x, {
            checked: this.selectedTagIdsSet.has(x.id)
          })
          return x;
        })

      });
  }


  public onNoClick(): void {
    this.dialogRef.close();
  }

  public selectedTagIdsSet = new Set();
  public selectTag(tag) {
    if (tag.checked) {
      this.selectedTagIdsSet.delete(tag.id);
    } else {
      this.selectedTagIdsSet.add(tag.id);
    }
    tag.checked = !tag.checked;
  }

  public get selectedTagIds() {
    return Array.from(this.selectedTagIdsSet);
  }

  public save(): void {

    const req = {
      assortmentSchemeId: this.assortmentSchemeId,
      map: this.selection,
      assortmentGroupTagIds: this.selectedTagIds
    }

    const messageOptions = this._action.createMessage(
      "Tagid lisatud!"
    );
 
    this._assortmentSchemesService.saveTagsMap(this.assortmentSchemeId, req).result(null, res => {
      this.closeDialog(this.assortmentSchemeId);
    }, e => {

    },  
    "save", 
    this.actionLoading,
    messageOptions);
  }

  public closeDialog(data?: any): void {
    this.dialogRef.close(data);
  }
}
