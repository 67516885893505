export enum UserAction {
  
  ViewUsers = 1,
  ManageUsers = 2,
  ViewClvs = 3,
  ManageClvs = 4,
  ManageSettings = 5,

  Reports = 100,

  ViewProducts = 200,
  ManageProducts = 201,
  ViewPrices = 202,
  ManagePrices = 203,
  ImportProducts = 204,
  ViewOthersClientsPrices = 205,

  ManageBuyerAssortment = 300,

  ViewProductUpdates = 400,
  SendProductUpdates = 401,

  ViewReceivedProductUpdates = 500,
  ImportReceivedProductUpdates = 501,

  ViewClients = 600,
  ManageClients = 601,
  ViewOthersClients = 602,
  ManageOthersClients = 603,
  AcceptTenantInvite = 604,

  ViewSupplierOffers = 700,
  ManageSupplierOffers = 701,
  ConfirmSupplierOffers = 702,
  ViewOthersSupplierOffers = 703,
  EditOthersSupplierOffers = 704,

  ReceiveOffers = 800,
  ViewReceivedOffers = 801,
  ManageReceivedOffers = 802,
  ConfirmReceivedOffers = 803,
  ViewOthersReceivedOffers = 804,
  EditOthersReceivedOffers = 805,

  ViewLocations = 900,
  ManageLocations = 901,

  ViewMarket = 1000,
  ViewMarketPrices = 1001,

  ViewSuppliers = 1100,
  ManageSuppliers = 1101,

  ManageOfferCalendar = 1200,

  ViewPublishedOffersCalendar = 1300,

  ReceiveProductUpdates = 1400,

  ManageProcurements = 1500,
  
  ReceiveProcurements = 1600,

  ManagePurchaseOrders = 1700,
  EditOthersPurchaseOrders = 1701,
  ViewPurchaseOrders = 1702,
  ViewOthersPurchaseOrders = 1703,
  SendPurchaseOrders = 1704,
  ViewIncomingSalesOrders = 1705,
  ViewPurchaseOrdersPrices = 1706,

  ViewSalesOrders = 1800,
  ManageSalesOrders = 1801,
  SendSalesOrders = 1804,
  ViewIncomingPurchaseOrders = 1805,
  ViewSalesOrdersPrices = 1806

}

(UserAction as any).__typeName = 'UserAction';
