import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { appSettings } from 'src/app/app.settings';
import { LOCAL_STORAGE_KEY, PAGE_SIZE, PAGE_SIZE_OPTIONS } from 'src/app/config';
import { UserAction } from 'src/app/enum';
import { DataTableService } from 'src/app/_services';
import { ClvTableComponent } from '../clv-table.component';
import { ClassificatorValueDialog } from '../dialogs/classificator-value.dialog';

@Component({
  selector: "assortment-clv-table",
  templateUrl: './assortment-clv-table.component.html',
  styleUrls: ['../clv-table.component.scss']
})
export class AssortmentClvTableComponent extends ClvTableComponent {

  @Input()
  public title: string;

  @Input() 
  public canEdit: boolean;

  public UserAction = UserAction;

  public LOCAL_STORAGE_KEY = LOCAL_STORAGE_KEY.PAGE_SIZE_ASSORTMENT_STATUS_LIST;

  constructor(
    private _dialog: MatDialog,
    dataService: DataTableService<any, any>,
    cd: ChangeDetectorRef,
  ) {
    super(dataService, cd);
  }

  public openClassificatorValueDialog(clv: any): void {
    if (!this.canEdit || this._dialog.openDialogs.length !== 0) {
      return;
    }

    const dialogRef = this._dialog.open(ClassificatorValueDialog, {
      ...appSettings.DIALOG_SIZES.M,
      restoreFocus: false,
      data: {
        parent: this,
        row: clv,
        requiredRightsForEdit: [UserAction.ManageBuyerAssortment]
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }

      this.load();
    });
  }
}
