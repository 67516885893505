<div class="indicator-container status--{{progress}}"
     [popper]="tooltipcontent"
     popperTrigger="hover"
     [popperPlacement]="position"
     [popperPositionFixed]="true"
     popperAppendTo="app-root"
     [popperHideOnMouseLeave]="true"
     [popperDisabled]="!description && !template">
  <span class="status-circle" *ngFor="let status of [].constructor(range); let i = index"></span>
</div>

<popper-content #tooltipcontent stop-propagation>
  <div *ngIf="description">
    {{ description | translate}}
  </div>
  <div *ngIf="template" [innerHTML]="template"></div>
</popper-content>

