import { Component, Output, EventEmitter, OnInit, Input, AfterViewInit, ChangeDetectorRef, HostListener, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { DataTableComponent } from 'src/app/components';
import { DataTableService, ProductService } from 'src/app/_services';
import { Observable } from 'rxjs';
import { KeyCode, SupplierProductAssortmentStatus } from 'src/app/enum';
import { PAGE_SIZE, PAGE_SIZE_OPTIONS, LOCAL_STORAGE_KEY } from 'src/app/config';
import { IConfirmOptions, LocalStorage, LxmDialog, LxmMessage } from 'src/app/_helpers';
import { ProductsListState } from './products-list.state';
import { appSettings } from 'src/app/app.settings';
import { SyncProductsDialog } from 'src/app/dialogs/sync-products/sync-products.dialog';
import { ExportProductsPdfDialog } from 'src/app/dialogs/export-products-pdf/export-products-pdf.dialog';

@Component({
  template: ''
}) // Necessary for proper injection
export class ProductsListBase<T extends { id: string, status: number }, TData>
  extends DataTableComponent<T, TData> implements OnInit, AfterViewInit {

  @Input() public LOCAL_STORAGE_KEY = LOCAL_STORAGE_KEY.PAGE_SIZE_PRODUCTS_LIST;

  public ProductStatus = SupplierProductAssortmentStatus;

  @Output()
  public loaded: EventEmitter<boolean> = new EventEmitter();

  public selection: any[] = [];
  public currentPageSelected: string[] = [];

  public pricePrecision: number = 3;

  private _activeRowsCount = 0;

  protected formData: any;

  public procurementHasPriority = false;

  @Input()
  public search: Observable<any>;
  private searchData: any;
  protected _searchUrl = '/products';

  constructor(
    private _state: ProductsListState,
    private _location: Location,
    public dialog: LxmDialog,
    _dataService: DataTableService<T, TData>,
    _cd: ChangeDetectorRef,
  ) {
    super(_dataService, _cd);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  public isAllSelected() {
    return this.currentPageSelected.length === this._activeRowsCount;
  }

  public masterToggle() {
    if (this.isAllSelected()) {
      this.dataSource.data.forEach(row => {
        const index = this._getSelectionProductIndex(row);
        if (index > -1) {
          this.selection.splice(index, 1);
        }
      })
    } else {
      this.dataSource.data.forEach(row => {
        if (!this.isProductSelected(row)) {
          this.selection.push(row);
        }
      });
    }
    this._updateCurrentPageSelection();
  }

  protected _updateCurrentPageSelection(data = this.dataSource.data) {
    this.currentPageSelected = [];
    data?.forEach(row => {
      this.isProductSelected(row) ? this.currentPageSelected.push(row.id) : null;
    });
    this._activeRowsCount = data ? data.length : 0;
  }

  public ngOnInit() {
    super.ngOnInit();

    this.dataSource.data$.subscribe(x => {
      this._updateCurrentPageSelection(x);
    });

    this.loadAfterViewInit = this.search === null;

    this.dataSource.loading$.subscribe((val: boolean) => {
      this._state.isSearchLoading = val;
    })

  }

  public get isLoading() {
    return this._state.isSearchLoading;
  }

  public onSearch(searchData) {
    this.searchData = searchData;
  }

  public onLoad() {
    this.load();
  }

  public get hasProducts() {
    return this.dataSource?.data?.length > 0 ? true : false;
  }

  protected getData() {
    return this.searchData;
  }

  public isProductSelected(row): boolean {
    return this.selection.find(x => x.id === row.id) ? true : false;
  }

  public toggleSelection(row): void {
    const index = this._getSelectionProductIndex(row);
    if (index < 0) {
      this.selection.push(row);
      this.currentPageSelected.push(row.id);
    } else {
      this.selection.splice(index, 1);
      const i = this._getCurrentPageProductIndex(row);
      if (i > -1) {
        this.currentPageSelected.splice(i, 1);
      }
    }
  }

  public emptySelectedProducts() {
    this.selection = [];
    this.currentPageSelected = [];
  }

  protected _getSelectionProductIndex(row): number {
    return this.selection.findIndex(x => x.id === row.id);
  }

  protected _getCurrentPageProductIndex(row): number {
    return this.currentPageSelected.findIndex(x => x === row.id);
  }

  @HostListener('window:keydown', ['$event']) onKeyDown(e) {
    if (this.isLoading) return;
    if (e.ctrlKey || e.metaKey) {
      if (e.keyCode === KeyCode.LEFT) {
        e.preventDefault();
        this.paginator.previousPage();
      }
      if (e.keyCode === KeyCode.RIGHT) {
        e.preventDefault();
        this.paginator.nextPage();
      }
    }
  }

  public openSyncProductsDialog(): void {
    this.dialog.open(SyncProductsDialog, {
      ...appSettings.DIALOG_SIZES.M,
      data: {
        formData: this.formData,
      }
    }, res => {
      if (res) {
        this.load();
      }
    });
  }

  public openExportProductsDialog(selection = this.selection): void {
    this.dialog.open(ExportProductsPdfDialog, {
      ...appSettings.DIALOG_SIZES.AUTO,
      data: {
        formData: this.formData,
        productIds: selection.map(x => x.id)
      }

    }, res => {
      //
    }
    );
  }

}
