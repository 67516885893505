import { Component, Input, OnInit } from "@angular/core";
import { ViewEncapsulation } from '@angular/core';
import { IClvDto } from "src/app/cards/product/product-info/product-info.model";

@Component({
  selector: "procurement-details-popover",
  templateUrl: "./procurement-details-popover.component.html",
  styleUrls: ['./procurement-details-popover.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProcurementDetailsPopoverComponent {

  @Input() public procurement: IProcurementDetails;
  @Input() public icon: string = 'info';

}


export interface IProcurementDetails {
  supplyFrom: Date;
  supplyTo: Date;
  name: string;
  fullfillmentTime: string;
  supplyConditions: string;
  notes: string;
}
