import { Directive, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[zoomValue]',
})
export class ZoomDirective {

  private _zoom: number = 1;

  @Input() public set zoomValue(zoom: number) {
    this._zoom = zoom;
  }

  @HostBinding('style.transform-origin') public transformOrigin = '0 0';

  @HostBinding('style.transform') public get scale() {
    return `scale(${this._zoom})`;
  }

  @HostBinding('style.width') public get width() {
    return `${100 / this._zoom}%`;
  }

}

export function addZoom(zoom: number, inc = 0.05, max = 1) {
  const newZoom = parseFloat((zoom + inc).toFixed(2));
  if (newZoom > max) {
    return zoom;
  }
  return newZoom;
}

export function decZoom(zoom: number, dec = 0.05, min = 0.5) {
  const newZoom = parseFloat((zoom - dec).toFixed(2));
  if (newZoom < min) {
    return zoom;
  }
  return newZoom;
}
