import { Injectable, Injector, InjectionToken, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialog } from '../dialogs/confirm/confirm.dialog';
import { TranslateService } from '@ngx-translate/core';
import { appSettings } from 'src/app/app.settings';
import { ClvType } from '../enum';
import { CLV_TYPE_DATA } from '../config';
import { ClassificatorValueDialog } from '../components/_partials/settings/clv-table/dialogs/classificator-value.dialog';
import { ActionHelper } from './action';
import { FormControl } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class LxmDialog {

  public get openDialogs() {
    return this.dialog.openDialogs;
  }

  public constructor(
    private dialog: MatDialog,
    private readonly _translateService: TranslateService) { }

  public translate(key: string) {
    return this._translateService.instant(key);
  }

  public open(ref, options, afterClosed = _ => {}) {

    let data = options.data || {};
    let width = options.width || appSettings.DIALOG_WIDTHS.M;
    let maxWidth = options.maxWidth || '90vw';
    let minWidth = options.minWidth || width;
    let height = options.height || appSettings.DIALOG_HEIGHTS.AUTO;
    let restoreFocus = options.restoreFocus || false;
    data.__resolve = options.resolve;


    
    this.dialog
      .open(ref, {
        width: width,
        minWidth: minWidth,
        maxWidth: maxWidth,
        height: height,
        data: data,
        restoreFocus: restoreFocus
      })
      .afterClosed()
      .subscribe(result => {
        afterClosed(result);
      });
  }

  public confirm(options: IConfirmOptions, onConfirm: (cd?: MatDialogRef<ConfirmationDialog, any>) => any) {

    const title = options.title,
      message = options.message || '',
      template = options.template || null,
      templateRef = options.templateRef,
      image = options.image || null,
      width = options.width || '600px',
      height = options.height || 'auto',
      yes = options.yes || 'global.yes',
      no = options.no || 'global.no',
      swapButtons = options.swapButtons || false,
      restoreFocus = options.restoreFocus || false;

    const ref = this.dialog
      .open(ConfirmationDialog, {
        width: width,
        height: height,
        data: {
          title: title,
          message: message,
          template: template,
          image: image,
          yes: yes,
          no: no,
          showNo: options.showNo === undefined ? true : options.showNo,
          showYes: options.showYes === undefined ? true : options.showYes,
          swapButtons: swapButtons,
          templateRef: templateRef
        },
        restoreFocus: restoreFocus
      });

    ref
      .afterClosed()
      .subscribe(result => {
        if (result) {
          onConfirm(ref);
        }
      });
  }

  // EXAMPLE

  //public confirmProductDelete(product) {
  //  this.dialogService.confirm(
  //    {
  //      title: 'Kas oled kindel et soovid toote eemaldada?',
  //      message: 'Eemalda ' + product.name + ' pakkumisest?',
  //      template: `
  //        <br><br>
  //        Eemalda <strong>${product.name}</strong> pakkumisest?
  //        <br><br>
  //        Template tõlke näide: ${this.dialogService.translate('cards.offers.offer_supplier_create_offer_basic_info.label.offer_description')}`,
  //      height: '300px',
  //      yes: 'Eemalda pakkumisest',
  //      no: 'global.no'
  //    }, () => {
  //      let index = this.productsArray.value.findIndex(x => x.productId === product.productId);
  //      this.productsArray.removeAt(index)
  //      this.updateProductSource();
  //    })
  //}

}

@Injectable({ providedIn: 'root' })
export class DialogHelper {

  constructor(private _action: ActionHelper) {}
  
  public ClvType = ClvType;


  public openFormNewClassificatorValueDialog(clvType: ClvType, formRef: FormControl, optionsRefName: string, valueAsArray = false, formData: any, parent): void {

    const data = CLV_TYPE_DATA[clvType]

    if (!data) {
      return;
    }
    
    this._action.openDialog(ClassificatorValueDialog, {
      ...appSettings.DIALOG_SIZES.L,
      restoreFocus: false,
      data: {
        parent: {
          classificatorId: data.classificatorId,
          baseUrl: data.controller,
          formData: formData
        },
        row: data
      }
    }, res => {
      if (!res) {
        return;
      }

        const { form, response } = res;
        const { value } = form?.value || {}; 
  
        const newOption = {
          value: value,
          id: response,
          translatedValue: null,
          code: null
        } 
  
        // // New array to trigger select component ngOnChanges
        const optionsRef = parent[optionsRefName];
        parent[optionsRefName]= [newOption, ...optionsRef]

        if (valueAsArray) {
          if (!formRef.value?.length) {
            formRef.setValue([newOption])
          }
        } else if (!formRef.value) {
          formRef.setValue(newOption)
        }
    }
    
    );

  }
 
}




export interface IConfirmOptions {
  restoreFocus?: boolean;
  no?: string;
  showNo?: boolean;
  yes?: string;
  showYes?: boolean;
  height?: string;
  width?: string;
  title?: string;
  message?: string;
  template?: string;
  templateRef?: TemplateRef<any>;
  image?: string;
  swapButtons?: boolean;
}
