import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppState } from 'src/app/state/app.state';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { IUser, IChatMessage } from 'src/app/models';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.scss']
})
export class ChatComponent {

  @Input() public readonly = false;
  @Input() public messages: IChatMessage[];
  @Input() public chatId: string;
  @Input() public handleNewMessage: (message: string) => Observable<any>;

  @Output() public newMessage: EventEmitter<string> = new EventEmitter();

  public currentUser: IUser;
  public sendingMessage = false;
  private _chatMessageRegex: RegExp = /^(?<product>@\d+;.+) - (?<note>.+)$/;

  public form = new FormGroup({
    message: new FormControl('')
  });

  constructor(
    private _state: AppState,
    private _router: Router) {
    this.currentUser = this._state.user;
  }

  public hasProductData(chatMessage: IChatMessage) {
    return chatMessage?.product && this._chatMessageRegex.test(chatMessage.message);
  }

  public getProductData(chatMessage: IChatMessage) {
    const res = this._chatMessageRegex.exec(chatMessage.message);
    return res.groups["product"];
  }

  public productDataClick(chatMessage: IChatMessage) {
    const url = this._router.createUrlTree(['/products', chatMessage.productId]).toString();
    window.open(window.location.origin + url, '_blank');
  }

  public messageText(chatMessage: IChatMessage) {
    if (!this.hasProductData(chatMessage)) {
      return chatMessage.message;
    }

    const res = this._chatMessageRegex.exec(chatMessage.message);
    return res.groups["note"];
  }

  public sendMessage() {
    const f = this.form.value;
    const message = (f.message || '').trim();

    if (!message) {
      return;
    }

    this.form.patchValue({
      message: ''
    });

    this.sendingMessage = true;
    this.newMessage.emit(message);

    if (this.handleNewMessage) {
      this.handleNewMessage(message)
        .subscribe(x => {
        }, error => {
          this._messageComplete();
        }, () => {
          this._messageComplete();
        });
    } else {
      this._messageComplete();
    }
  }

  private _messageComplete() {
    this.sendingMessage = false;
  }

}
