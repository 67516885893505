import { Component, Input } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { appSettings } from 'src/app/app.settings';
import { asArray, LxmAppModule, UserAction } from 'src/app/enum';
import { OfferExportType } from 'src/app/enum/offer-export-type';
import { AppState } from 'src/app/state/app.state';
import { ClientsService, IClientCard, IClientFormData } from 'src/app/_services/clients.service';
import { ClientState } from '../../state/client.state';

@Component({
  selector: 'client-offer-settings-card',
  templateUrl: './offer-settings.card.html',
  styleUrls: ['./offer-settings.card.scss']
})
export class OfferSettingsCard {

  public edit = false;
  public saveLoading = false;
  public formData: IClientFormData;

  public UserAction = UserAction;

  public isClient = false;

  public languages = appSettings.languages;

  public deliveryMethodOptions = [];
  public offerExportTypes = [];
  public currencyOptions = [];
  public clientTagOptions = [];

  @Input() public clientId;

  private _snapshot;

  public form = new FormGroup({
    offerLanguage: new FormControl(),
    offerExportType: new FormControl(),
    // deliveryMethod: new FormControl(),
    currencyId: new FormControl(),
    paymentDeadlineInDays: new FormControl(),
    fineForDelay: new FormControl(),
    clientTags: new FormControl()
  })

  constructor(
    private _route: ActivatedRoute,
    private _clientService: ClientsService,
    private _state: ClientState,
    private _appState: AppState) {

    const clientCard = this._route.snapshot.data.initialData.clientCard as IClientCard;

    this.formData = clientCard.formData;
    const clientInfo = clientCard.clientInfo;

    this.isClient = clientCard?.clientInfo?.isSystemClient === false;

    // this.deliveryMethodOptions = this.formData.deliveryMethods || [];
    this.currencyOptions = this.formData.currencies || [];
    this.clientTagOptions = this.formData.clientTags || [];
    this.offerExportTypes = asArray(OfferExportType);

    if (!_appState.hasModule(LxmAppModule.Pdf)) {
      const pdfExportTypes = [OfferExportType.OfferPdf, OfferExportType.OfferAndProductsPdf];
      this.offerExportTypes = this.offerExportTypes.filter(x => !pdfExportTypes.includes(x.id));
    }

    this.form.patchValue({
      offerLanguage: clientInfo.defaultLanguage?.value,
      offerExportType: clientInfo.offerExportType,
      // deliveryMethod: clientInfo.deliveryMethodId,
      currencyId: clientInfo.currencyId,
      paymentDeadlineInDays: clientInfo.paymentDeadlineInDays,
      fineForDelay: clientInfo.fineForDelay,
      clientTags: clientInfo.clientTags
    });

  }

  public get canEdit() {
    return this._state.canEdit;
  }

  ngOnInit() {
    if (this.clientId) {
      this.saveInitialData();
    }
  }

  public toggleEdit() {
    if (this.edit) {
      this.revertToInitialData();
    }

    this.edit = !this.edit;
  }

  public saveInitialData() {
    this._snapshot = this.form.value;
  }

  public revertToInitialData() {
    this.form.patchValue(this._snapshot);
  }

  public save() {
    this.saveLoading = true;
    let req = this._createSaveRequest();

    this._clientService.saveOfferSettings(this.clientId, req)
      .result(this.form, () => {
        this.saveLoading = false;
        this.saveInitialData();
        this.edit = false;
      }, error => { this.saveLoading = false; });
  }

  public getItemClass(item: any) {
    return `color-tag color-${item.color}`;
  }

  private _createSaveRequest() {
    const form = this.form.value;
    const req = {
      defaultLanguage: form.offerLanguage,
      offerExportType: form.offerExportType,
      currencyId: form.currencyId,
      // deliveryMethodId: form.deliveryMethod,
      paymentDeadlineInDays: form.paymentDeadlineInDays,
      fineForDelay: form.fineForDelay,
      clientTags: form.clientTags
    };

    return req;
  }
}