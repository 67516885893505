import { Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SignalRService } from 'src/app/_services';
import { IOfferCard, IOfferChangeLog } from 'src/app/models';
import { Subscription } from 'rxjs';
import { HubConnection } from '@microsoft/signalr';

@Component({
  selector: 'offer-history-card',
  templateUrl: './offer-history.card.html'
})
export class OfferHistoryCardComponent implements OnDestroy {

  public history: IOfferChangeLog[];
  private _hubConnection: HubConnection;
  private _signalRSubscription: Subscription;

  constructor(
    private _route: ActivatedRoute,
    private _signalRService: SignalRService
  ) {
    const offerCard = this._route.snapshot.data.offerCard as IOfferCard;
    this.history = offerCard.changes;

    this._signalRSubscription = this._signalRService.commonHub.subscribe(x => {
      this._hubConnection = x;

      if (x === null) {
        return;
      }

      x.on('offerChanged', (data: IOfferChangeLog[]) => {
        this.history = data;
      });
    });
  }

  ngOnDestroy(): void {
    if (this._hubConnection) {
      this._hubConnection.off('offerChanged');
    }

    if (this._signalRSubscription) {
      this._signalRSubscription.unsubscribe();
    }
  }
}
