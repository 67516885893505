import { Component, Input, OnInit, ViewChild, Inject, ElementRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { DialogComponent } from 'src/app/components';
import { LxmDialog, LxmMessage } from 'src/app/_helpers';
import { asArray, Status, UserAction } from 'src/app/enum';
import { appSettings } from 'src/app/app.settings';
import { ActivatedRoute } from '@angular/router';
import { ClientsService, IClientCard, IClientContactListItem, IClientFormData, IClientInfo } from 'src/app/_services/clients.service';
import { ClientState } from '../../state/client.state';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'client-contacts-card',
  templateUrl: './contacts.card.html',
  styleUrls: ['./contacts.card.scss']
})
export class ClientContactsCard {

  @Input() public clientId;

  public displayedColumns: string[] = ['name', 'role', 'portfolioDescription', 'email', 'phoneNumber'];
  public dataUrl;

  public clientCard: IClientCard;
  public clientInfo: IClientInfo;
  public formData: IClientFormData;
  public UserAction = UserAction;

  public contacts: IClientContactListItem[];

  public phoneCodes = [];

  constructor(
    private _route: ActivatedRoute,
    private _dialog: LxmDialog,
    private _clientsService: ClientsService,
    private _state: ClientState
  ) {
    const { clientCard, contacts } = this._route.snapshot.data.initialData;

    this.clientCard = clientCard;
    this.contacts = contacts?.items;

    if (!this.clientCard) {
      return;
    }

    this.formData = this.clientCard.formData;
    this.clientInfo = this.clientCard.clientInfo;
    this.phoneCodes = this.formData?.phoneCodes;
  }

  public showAllContacts = false;

  public toggleShowAllContacts(toSet = !this.showAllContacts) {
    this.showAllContacts = toSet;
  }

  public getPhoneCodeById(id: string) {
    return this.phoneCodes?.find(x => x.id === id)?.value;
  }

  public get isSelfSupplier() {
    return this._state.isSelfSupplier;
  }

  public get isClient() {
    return this._state.isClient;
  }

  public get hasContacts() {
    return this.contacts?.length > 0;
  }

  public get canEdit() {
    return this._state.isClient && this._state.canEdit;
  }

  public getContacts() {
    const clientId = this.clientInfo?.id;
    if (!clientId) return;
    this._clientsService.getClientContacts(clientId).result(null, res => {
      const items = res.items;
      if (items) {
        this.contacts = items;
      }
    })
  }

  public get contactsCount() {
    if (!this.contacts) return 0;
    return this.contacts.length;
  }

  public openDialog(row?: IClientContactListItem) {

    this._dialog.open(AddContactDialogComponent, {
      ...appSettings.DIALOG_SIZES.M,
      data: {
        clientId: this.clientId,
        formData: this.formData,
        contact: row,
        edit: this.canEdit
      }
    }, res => {
      this.getContacts();
    });
  }

}

@Component({
  selector: "client-contact",
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ClientContactComponent {

  @Input() public contact: any;

  constructor(private _translateService: TranslateService) {}

  public get phoneCode() {
    return this.contact?.phoneCode?.value;
  }

  public get phoneShort() {
    return this.contact?.phoneNumberShort;
  }

  public getStatusClass() {
    if (!this.contact) return;
    if (this.contact.isLinked) return 'status-linked';
    if (this.contact.status === Status.Active) return 'status-active';
    if (this.contact.status === Status.Disabled) return 'status-disabled';
  }

  public translatedPortfolioDescription: string;

  public getTranslatedPortfolioDescription() {
    if (!this.contact) {
      return null;
    }

    const lang = this._translateService.currentLang;

    const { translatedPortfolioDescription, translatedPortfolioDescriptionLanguageOrder, portfolioDescription } = this.contact || {};

    if (translatedPortfolioDescription) {

      if (translatedPortfolioDescription[lang]) {
        return this.contact.translatedPortfolioDescription[lang];
      }

      for (const l of translatedPortfolioDescriptionLanguageOrder) {
        if (translatedPortfolioDescription[l]) {
          return translatedPortfolioDescription[l];
        }
      }
    }

    return portfolioDescription;
  }

  ngOnInit() {
    this.translatedPortfolioDescription = this.getTranslatedPortfolioDescription();
  }

}

@Component({
  selector: "add-contact-dialog",
  host: { 'class': 'dialog' },
  templateUrl: './add-contact.dialog.html'
})
export class AddContactDialogComponent {

  public saveLoading = false;
  public deleteLoading = false;

  public edit = false;

  public clientId: string;
  public formData: IClientFormData;
  public contact: any;

  public statusOptions = asArray(Status);

  public translatedPortfolioDescription: string;

  @ViewChild('dialog', { static: true, read: DialogComponent })
  private _dialog: DialogComponent;

  public form = new FormGroup({
    id: new FormControl(),
    name: new FormControl(),
    role: new FormControl(),
    portfolioDescription: new FormControl(),
    email: new FormControl(),
    phoneNumberShort: new FormControl(),
    phoneCode: new FormControl(),
    status: new FormControl(Status.Active)
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public dialogRef: MatDialogRef<AddContactDialogComponent>,
    private _lxmDialog: LxmDialog,
    private _clientsService: ClientsService,
    private _translateService: TranslateService
  ) {

    const { contact, edit, clientId, formData } = _data || {};

    this.edit = edit;
    this.formData = formData;
    this.clientId = clientId;
    this.contact = contact;

    if (!contact) {
      return;
    }

    this.form.patchValue({
      id: contact.id,
      name: contact.name,
      role: contact.role,
      portfolioDescription: contact.portfolioDescription,
      email: contact.email,
      phoneNumberShort: contact.phoneNumberShort,
      phoneCode: contact.phoneCode,
      status: contact.status
    });
  }

  public getTranslatedPortfolioDescription() {
    if (!this.contact) {
      return null;
    }

    const lang = this._translateService.currentLang;

    const { translatedPortfolioDescription, translatedPortfolioDescriptionLanguageOrder, portfolioDescription } = this.contact || {};

    if (translatedPortfolioDescription) {

      if (translatedPortfolioDescription[lang]) {
        return this.contact.translatedPortfolioDescription[lang];
      }

      for (const l of translatedPortfolioDescriptionLanguageOrder) {
        if (translatedPortfolioDescription[l]) {
          return translatedPortfolioDescription[l];
        }
      }
    }

    return portfolioDescription;
  }

  public get contactId() {
    return this.form.get('id').value;
  }

  public get phoneCode() {
    return this.form.get('phoneCode').value;
  }

  public get phoneNumberShort() {
    return this.form.get('phoneNumberShort').value;
  }

  public closeDialog(res = null) {
    this.dialogRef.close(res);
  }

  public deleteContact() {
    this._lxmDialog.confirm({
      title: 'cards.clients.contacts.dialog.delete.title',
      message: 'cards.clients.contacts.dialog.delete.message'
    }, () => {
      this.deleteLoading = true;
      this._clientsService.deleteContact(this.contactId, this.clientId)
        .result(null, res => {
          this.deleteLoading = false;
          this.closeDialog();
        }, e => {
          console.error(e);
          this.deleteLoading = false;
        })
    });

  }

  public save() {
    let f = this.form.value;

    let req = {
      id: f.id,
      clientId: this.clientId,
      name: f.name,
      phoneCodeId: f.phoneCode?.id,
      phoneNumberShort: f.phoneNumberShort,
      email: f.email,
      role: f.role,
      portfolioDescription: f.portfolioDescription,
      status: f.status
    };

    this.saveLoading = true;
    this._clientsService
      .saveContact(req.id, req)
      .result(this.form, res => {
        let contact = req;

        Object.assign(contact, {
          id: res.tenantUserId,
          userId: res.userId
        });

        this.closeDialog(contact);
        this.saveLoading = false;
      }, e => {
        this.saveLoading = false;
      });
  }

  ngOnInit() {
    this.translatedPortfolioDescription = this.getTranslatedPortfolioDescription();
  }
}
