import { Component, Input, Optional, OnInit, Host, HostBinding } from "@angular/core";
import { FormGroup, ControlContainer } from '@angular/forms';
import { ReadonlyDirective } from 'src/app/directives';
import { ICurrency } from "src/app/models";

@Component({
  selector: "product-payment-manager",
  templateUrl: "./product-payment-manager.component.html",
  styleUrls: ['./product-payment-manager.component.scss']
})
export class ProductPaymentManagerComponent implements OnInit {

  @HostBinding('class.disabled') @Input() public disabled = false;

  @Input() public formGroup: FormGroup;
  @Input() public readonly = false;
  @Input() public suppliersOptions: any[];
  @Input() public currency: ICurrency = null;

  public addingItems = false;

  constructor(
    @Optional() @Host() private _readonlyQuery: ReadonlyDirective,
    @Optional() private _controlContainer: ControlContainer) {
  }

  public get hasValues() {
    if (this.addingItems) {
      return true;
    }

    const { payment } = this.formGroup.value || {};

    if (payment) {
      return true
    }

    return false;
  }


  public addItem() {
    this.addingItems = !this.addingItems;
  }

  public ngOnInit(): void {

    if (this._readonlyQuery && this._readonlyQuery.readonly) {
      this.readonly = this._readonlyQuery.readonly;
    }

    if (this._controlContainer && this._controlContainer.control instanceof FormGroup) {
      this.formGroup = <FormGroup>this._controlContainer.control;
    }
  }
}
