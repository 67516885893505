import { Component, OnInit } from '@angular/core';
import { TitleService } from 'src/app/_services/title.service';
import { AppState } from 'src/app/state/app.state';
import { Observable, BehaviorSubject } from 'rxjs';
import { LocalStorage } from 'src/app/_helpers';
import { LOCAL_STORAGE_KEY } from 'src/app/config';
import { LxmAppModule } from 'src/app/enum';

@Component({
  selector: 'products-layout',
  templateUrl: './products.layout.html',
  host: { 'class': 'content' },
  styleUrls: ['./products.layout.scss']
})
export class ProductsLayout implements OnInit {
  title_supplier = 'cards.products.products_supplier.title'

  public loading: boolean;
  public search: Observable<any>;
  public searchSubject = new BehaviorSubject<any>(null);
  public LxmAppModule = LxmAppModule;

  public searchPanelLocalStorageKey = LOCAL_STORAGE_KEY.PRODUCTS_SEARCH_PANEL_OPEN;
  public isSearchPanelOpen = false;

  constructor(
    public appState: AppState,
    private _titleService: TitleService
  ) {
    this.isSearchPanelOpen = LocalStorage.getValue(this.searchPanelLocalStorageKey);
    this.search = this.searchSubject.asObservable();
  }

  public ngOnInit() {
    this._titleService.setTitle(this.title_supplier);
  }

}
