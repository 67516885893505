import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { TitleService } from 'src/app/_services/title.service';

@Component({
  selector: 'reports-layout',
  templateUrl: './reports.layout.html',
  host: { 'class': 'content' },
  styleUrls: ['./reports.layout.scss']
})
export class ReportsLayout {
  title = 'Raportid';

  constructor(private _titleService: TitleService) { }

  public ngOnInit() {
    this._titleService.setTitle(this.title);
  }

}
