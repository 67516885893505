import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
} from "@angular/core";

@Component({
  selector: "price-change-display",
  templateUrl: "./price-change-display.component.html",
  styleUrls: ["./price-change-display.component.scss"],
})
export class PriceChangeDisplayComponent {
  @Input() public previous: any;
  @Input() public current: any;

  @Input() public value: any;
  @Input() public theme = "default";
  @Input() public type: string = "percent";

  @Input() public valueClasses: string;

  public changeClass: any = ChangeClass.NoChange;

  @HostBinding("class") public get hostClasses() {
    return `${this.changeClass} theme--${this.theme}`;
  }

  public prefix: string = "";

  constructor(private _cd: ChangeDetectorRef) {}

  ngOnChanges() {
    this.getValue();
    // console.log(this.previous, this.current, this.value)
    this._cd.detectChanges();
  }

  ngAfterViewInit() {
    this._cd.detach();
  }

  public setChangeClass() {
    const previous = this.previous;
    const current = this.current;

    if (previous == null || current == null) {
      this.changeClass = "";
    } else if (previous == current) {
      this.changeClass = ChangeClass.NoChange;
    } else {
      this.changeClass =
        previous > current ? ChangeClass.Decreased : ChangeClass.Increased;
    }
  }

  public setPrefix() {
    const previous = this.previous;
    const current = this.current;

    if (previous == null || current == null) {
      this.prefix = "";
    } else if (previous == current) {
      this.prefix = "=";
    } else {
      this.prefix = previous > current ? "-" : "+";
    }
  }

  public getValue() {
    const previous = this.previous;
    const current = this.current;
    let value: number;

    if (previous == null || current == null || previous == current) {
      value = 0;
    } else {
      if (this.type === "percent") {
        value = Math.abs(current / previous - 1);
      } else {
        value = Math.abs(current - previous);
      }
    }

    this.value = value;

    this.setPrefix();
    this.setChangeClass();
  }
}

enum ChangeClass {
  NoChange = "priceEquals",
  Increased = "priceIncreased",
  Decreased = "priceDecreased",
}
