<lxm-dialog [dialogRef]="dialogRef" #dialog>
  <span header-title
    >{{ (isProcurementOffer ? 'cards.offer.import_product_dialog.fruitsVegetables.title' : "cards.offer.import_product_dialog.title") | translate }}</span
  >

  <div body>
    <div class="row flex-nowrap">
      <info-array
        [info]="importInstructions.text"
        [prelude]="importInstructions.prelude"
        class="row mb20"
      >
      </info-array>
    </div>

    <div class="row justify-end">
      <lxm-button
        (click)="downloadOfferImportXls()"
        icon="download"
        type="transparent"
        [label]="(isProcurementOffer ? 'cards.offer.import_product_dialog.fruitsVegetables.export_button' : 'cards.offer.import_product_dialog.export_button')"
      >
      </lxm-button>
    </div>

    <div class="row">
      <div class="col-12 no-padding">
        <file-drop-box
          (onChange)="uploadFile($event)"
          (onDelete)="deleteAttachment($event)"
          [files]="files"
          [multiple]="false"
          addFileText="cards.offer.import_product_dialog.drag_item_here"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        >
        </file-drop-box>
      </div>
    </div>

    <div class="row" *ngIf="!isImportInfoValid">
      <div class="col-12 no-padding">
        <card-message type="error">
          <div class="flex w-full justify-between">
            <span>
              {{'cards.import.import_main.label.incorrect_warning' |
              translate:{number: validationErrorsCount} }}
            </span>
            <span class="view-errors generic-hover" (click)="viewErrors()"
              >{{'cards.import.import_main.action.show' | translate}}</span
            >
          </div>
        </card-message>
      </div>
      <!-- <div class="col-12 no-padding">
        <form-field label="cards.import.import_main.label.errors">
          <span any class="input-padding">
            <span class="view-errors" (click)="viewErrors()"
              >{{'cards.import.import_main.action.show' | translate}}</span
            >
          </span>
        </form-field>
      </div> -->
    </div>
  </div>

  <section footer>
    <lxm-button
      type="primary"
      label="action.import"
      (click)="import()"
      buttonType="submit"
      [loading]="isProcessing"
      [disabled]="!fileId || isFileLoading || isProcessing || !isImportInfoValid"
    >
    </lxm-button>
    <lxm-button
      type="secondary"
      label="action.close"
      (click)="closeDialog()"
      class="ml10"
    >
    </lxm-button>
  </section>
</lxm-dialog>

<a class="no-display" #download></a>
