export enum SupplierField {
  Name = 1,
  RegNo = 2,
  VatNo = 3,
  Address = 4,
  IsFieOrForeigner = 5,
  Status = 6,
  DefaultLanguage = 7,
  ExcelExport = 8,
  DeliveryMethod = 9,
  Currency = 10,
  PaymentDeadlineInDays = 11,
  FineForDelay = 12,
  Homepage = 13,
  Email = 14,
  LogoFile = 15,
  Contacts = 16,
  Owner = 17,
  AddressText = 18,
  ShortName = 19,
  Country = 20,
  Code = 21
}

(SupplierField as any).__typeName = 'SupplierField';