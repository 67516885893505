import { Component } from '@angular/core';

@Component({
  selector: 'not-found',
  host: { 'class': 'content' },
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']

})
export class NotFoundComponent {

}
