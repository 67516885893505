import { Component, ViewChild, Input, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { IAttachment, IOfferCard, IOfferCardDetail } from 'src/app/models';
import { FilesListComponent } from 'src/app/components/_partials/files-list/files-list.component';
import { AddFileDialog, IAddFileDialogData } from 'src/app/dialogs/add-file/add-file.dialog';
import { Guid } from 'src/app/util/Guid';
import { LxmMessage, DateHandler, LxmDialog } from 'src/app/_helpers';
import { OfferService, AuthenticationService } from 'src/app/_services';
import { UserAction, SupplierOfferStatus, OfferKind, OfferType } from 'src/app/enum';
import { AppState } from 'src/app/state/app.state';

@Component({
  selector: 'offer-files-card',
  templateUrl: './offer-files.card.html',
  styleUrls: ['./offer-files.card.scss']
})
export class OfferFilesCardComponent {

  @ViewChild('download') private _download: ElementRef;

  public UserAction = UserAction;
  public OfferKind = OfferKind;

  public offerId: string;
  public offer: IOfferCardDetail;
  public attachments: IAttachment[];

  @ViewChild('filesList') filesList: FilesListComponent;

  @Input() public isEditable: boolean;
  @Input() public offerKind: OfferKind;
  @Input() public retailerId: string;

  @Input() public offerType: OfferType;
  
  public isAssessmentFormLoading = false;

  constructor(
    public appState: AppState,
    private _router: Router,
    private _route: ActivatedRoute,
    private _dateHandler: DateHandler,
    private _offerService: OfferService,
    private _dialog: LxmDialog,
    private _message: LxmMessage,
    private _authService: AuthenticationService
  ) {
    this._route.params.subscribe(params => {
      this.offerId = params['offerId'] || null;
    });

    const offerCard = _route.snapshot.data.offerCard as IOfferCard;
    this.attachments = offerCard?.attachments || [];
    this.offer = offerCard?.offerDetail;
  }

  public get canEdit() {
    if (this.offerKind == OfferKind.SupplierOffer) {
      return this._authService.hasRight([UserAction.ManageSupplierOffers]);
    }
    else if (this.offerKind == OfferKind.ReceivingOffer) {
      return this._authService.hasRight([UserAction.ManageReceivedOffers]);
    }

    return false;
  }

  public getAssessmentForm() {
    console.log(this.offerId);
    if (this.offerId) {
      this.isAssessmentFormLoading = true;
      this._offerService.exportNewItemProposal(this.offerId).toPromise().then(res => {
        this._offerService.downloadXls(res.id).toPromise().then(blob => {
          const url = window.URL.createObjectURL(blob);
          const link = this._download.nativeElement;
          link.href = url;
          link.download = res.fileName;
          link.click();
          window.URL.revokeObjectURL(url);
          this.isAssessmentFormLoading = false;
        }).catch(e => {
          console.error(e);
          this.isAssessmentFormLoading = false;
        });
      }).catch(e => {
        console.error(e);
        this.isAssessmentFormLoading = false;
      })
    }
  }


  public get hasAssessmentForm() {
    if (!this.offerId) {
      return false;
    }
    const hasSupportedAssessmentFormExport = this.appState.supportedAssessmentFormExports.required
      .concat(this.appState.supportedAssessmentFormExports.optional)
      .indexOf(this.retailerId) >= 0;

    if (hasSupportedAssessmentFormExport) {
      return true;
    }

    if (this.offerType == OfferType.MainAssortment) {
      return this.appState.supportedAssessmentFormExports.requiredForAssortmentForms
        .concat(this.appState.supportedDeliveryFormExports.requiredForAssortmentForms)
        .indexOf(this.retailerId) >= 0;
    }

    if (this.offerType == OfferType.Logistics) {
      return this.appState.supportedDeliveryFormExports.requiredForLogisticsForms
        .indexOf(this.retailerId) >= 0;
    }

    return false;
  }

  public get assessmentFormInstructionsPrelude(): string {
    if(!this.hasAssessmentForm) {
      return "";
    }
    if (this.appState.supportedAssessmentFormExports.optional.indexOf(this.retailerId) >= 0) {
      return "cards.offer.base_info.assessment_instructions.optional.prelude";
    } else if (this.appState.supportedAssessmentFormExports.required
      .concat(this.appState.supportedAssessmentFormExports.requiredForAssortmentForms)
      .indexOf(this.retailerId) >= 0) {
      return "cards.offer.base_info.assessment_instructions.required.prelude";
    } else if (this.appState.supportedDeliveryFormExports.requiredForAssortmentForms.indexOf(this.retailerId) >= 0) {
      return "cards.offer.base_info.assessment_instructions.delivery_form.prelude_assortment";
    } else if (this.appState.supportedDeliveryFormExports.requiredForLogisticsForms.indexOf(this.retailerId) >= 0) {
      return "cards.offer.base_info.assessment_instructions.delivery_form.prelude_logistics";
    } else {
      return "";
    }
  }

  public get assessmentFormInstructions(): string[] {
    if (!this.hasAssessmentForm) {
      return [];
    }
    if (this.appState.supportedAssessmentFormExports.optional.indexOf(this.retailerId) >= 0) {
      return [
        'cards.offer.base_info.assessment_instructions.download_form',
        'cards.offer.base_info.assessment_instructions.optional.text1',
        'cards.offer.base_info.assessment_instructions.upload_form'
      ];
    } else if (this.appState.supportedAssessmentFormExports.required
      .concat(this.appState.supportedAssessmentFormExports.requiredForAssortmentForms)
      .indexOf(this.retailerId) >= 0) {
      return [
        'cards.offer.base_info.assessment_instructions.download_form',
        'cards.offer.base_info.assessment_instructions.required.text1',
        'cards.offer.base_info.assessment_instructions.required.text2',
        'cards.offer.base_info.assessment_instructions.upload_form'
      ];
    } else if (this.appState.supportedDeliveryFormExports.requiredForAssortmentForms
      .concat(this.appState.supportedDeliveryFormExports.requiredForLogisticsForms)
      .indexOf(this.retailerId) >= 0) {
      return [
        'cards.offer.base_info.assessment_instructions.delivery_form.download_form',
        'cards.offer.base_info.assessment_instructions.delivery_form.fill_form',
        'cards.offer.base_info.assessment_instructions.upload_form'
      ];
    } else {
      return [];
    }
  }

  public get assessmentFormButtonLabel() {
    if (this.appState.supportedDeliveryFormExports.requiredForAssortmentForms
      .concat(this.appState.supportedDeliveryFormExports.requiredForLogisticsForms)
      .indexOf(this.retailerId) >= 0) {
      return "cards.offer.base_info.action.delivery_form";
    }

    return "cards.offer.base_info.action.assessment_form";
  }

  public openAddFileDialog(attachment: IAttachment = null) {

    const fileDialogData: IAddFileDialogData = {
      dataTableRef: this.filesList.dataSource,
      attachment: attachment,
      add: (data) => {
        return this.offerId
          ? this._offerService.addAttachment(this.offerId, this._getUpsertAttachmentDto(data))
          : of(Object.assign({}, data, { id: Guid.newGuid() }));
      },
      save: (data) => {
        return this.offerId
          ? this._offerService.saveAttachment(this.offerId, attachment.id, this._getUpsertAttachmentDto(data))
          : of(data);
      }
    };

    this._dialog.open(AddFileDialog, {
      data: fileDialogData
    });
  }

  private _getUpsertAttachmentDto(attachment: IAttachment) {
    return {
      id: attachment.id,
      name: attachment.name,
      description: attachment.description,
      fileId: attachment.fileId
    };
  }

  public downloadAttachment(attachment: IAttachment) {
    this._offerService.downloadAttachment(this.offerId, attachment.id);
  }

  public removeAttachment(attachment: IAttachment) {
    this._dialog.confirm({
      title: 'cards.products.product_additional_info_and_instructions.attachments.remove_product_attachment_title',
      message: 'cards.products.product_additional_info_and_instructions.attachments.remove_product_attachment_message'
    }, () => {

      const o = this.offerId
        ? this._offerService.removeAttachment(this.offerId, attachment.id)
        : of({});

      o.result(null, res => {
        const i = this.filesList.dataSource.data.indexOf(attachment);
        this.filesList.dataSource.data.splice(i, 1);
        this.filesList.dataSource._updateChangeSubscription();
        this._message.ok({
          message: 'cards.products.product_additional_info_and_instructions.attachments.remove_success'
        })
      }, e => {
        console.error(e);
        this._message.error({
          message: 'cards.products.product_additional_info_and_instructions.attachments.remove_failed'
        })
      });
    });
  }
}


@Component({
  selector: 'offer-files-section',
  templateUrl: './offer-files.section.html'
})
export class OfferFilesSectionComponent extends OfferFilesCardComponent {


}
