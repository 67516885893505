import { Output, Input, EventEmitter, Directive, } from '@angular/core';
import { AppState } from 'src/app/state/app.state';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IEnumValue } from 'src/app/enum';
import { OffersSearchDataService } from 'src/app/_services';
import { IRetailerOption } from 'src/app/models';
import { LocalStorage } from 'src/app/_helpers';

// @Component({
//   template: ''
// })

@Directive()
export abstract class OffersSearchCardBase {
  
    public formData;

    @Output()
    public search = new EventEmitter();
  
    @Input()
    public isOpen = false;

    @Input()
    private localStorageKey: string;
    
    public offerTypeOptions: any[];
    public retailerOptions: IRetailerOption[];
    public statusOptions: IEnumValue[];
    public recipientsOptions: IRetailerOption[];
    public userOptions: any[];

    constructor(
      public appState: AppState,
      private _state: any,
      public offersSearchDataService: OffersSearchDataService,
      route: ActivatedRoute
    ) {
      this.formData = route?.snapshot?.data?.formData;
      this.userOptions = this.formData?.users || [];
    }

    public get isLoading() {
      return this._state.isSearchLoading;
    }

    public toggleOpen(isOpen: boolean) {
      this.isOpen = isOpen;
      LocalStorage.set(this.localStorageKey, isOpen);
    }
    
    protected _getCurrentUserAsSelection() {
      const user = this.formData?.users?.find(x => x.id === this.appState.user.id);
      if (!user) return;
      return [user];
    }
    protected abstract _serializeSearchData();

    public ngOnInit(): void {
      this.submit();
    }

    public submit(key = null) {
      const val = this._serializeSearchData();
      this.search.emit(val);
    }

  }