import { Component, Injector } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProductCardBase } from '../product-card-base';
import { IProductCardMeatInfo } from '../product-info/product-info.model';
import { Observable } from 'rxjs';
import { formUtil, keep } from 'src/app/util/form-util';
import { UserAction, ProductCardSection } from 'src/app/enum';
import { ProductValidity } from 'src/app/_services';
import { LOCAL_STORAGE_KEY } from 'src/app/config';

@Component({
  selector: 'product-meat-card',
  templateUrl: './product-meat.card.html',
  styleUrls: ['./product-meat.card.scss']
})
export class ProductMeatCardComponent extends ProductCardBase<IProductCardMeatInfo> {

  public UserAction = UserAction;

  public get title() { return 'cards.products.product_meat.title'; }

  public cardSection = ProductCardSection.MeatInfo;

  public isExpandedKey = LOCAL_STORAGE_KEY.PRODUCT_MEAT_CARD_OPEN;

  constructor(injector: Injector, public productValidity: ProductValidity) {
    super(injector, 'meatInfo', 'productMeatInfoChanged');
  }

  protected get successMessage(): string {
    return 'cards.products.product_meat.save_successful';
  }

  protected _getNormalizedFormData(data: IProductCardMeatInfo) {
    return formUtil.transformData(data,  {
      raisedCountry: keep,
      slaughteredCountry: keep
    });
  }

  protected _createFormGroup(data: IProductCardMeatInfo): FormGroup {
    return formUtil.createFormGroup(this._getNormalizedFormData(data));
  }

  protected _createSaveRequest() {
    const f = this.form.value;
    return {
      raisedCountryId: f.raisedCountry?.id,
      slaughteredCountryId: f.slaughteredCountry?.id
    };
  }

  protected _saveInternal(req: any): Observable<any> {
    return this._productService.saveMeatInfo(this.productId, req);
  }
}
