import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { Color, COLOR_MAP } from 'src/app/enum/color';
import { SupplierProductAssortmentStatus, Status } from 'src/app/enum';

@Component({
  selector: "image-popover",
  templateUrl: './image-popover.component.html',
  styleUrls: ['./image-popover.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImagePopoverComponent implements OnChanges {

  public COLOR_MAP = COLOR_MAP;

  @Input() public StatusEnum: any = undefined;

  private readonly _fallbackImage = '/assets/img/icons/no-content/no-image.svg';
  private readonly _thumbnailFallbackImage = '/assets/img/icons/no-content/no-image-small.svg';
  public imageSource: string;
  public thumbnailSource: string;
  public tooltipContentVisible = false;
  public popperTrigger = 'hover';
  public popperHideOnMouseLeave = true;
  public popperShowOnStart = false;

  public get localThumbnail(): boolean {
    return this.thumbnailSource === this._thumbnailFallbackImage;
  }

  public get localImage(): boolean {
    return this.imageSource === this._fallbackImage;
  }

  @Input() public thumbnail: string;
  @Input() public thumbnailBase64: boolean = false;
  @Input() public thumbnailContentType: string;

  @Input() public image: string;
  @Input() public imageBase64: boolean = false;
  @Input() public imageContentType: string;

  @Input() public status: number = undefined;
  @Input() public statusText: any;
  @Input() public color: Color = undefined;
  @Input() public description: string;
  @Input() public position: string = 'right';
  @Input() public delay: number = 300;
  @Input() public appendTo: string = 'app-root';
  @Input() public type: string = ImagePopoverType.Product;
  @Input() public popperPositionFixed = true;
  @Input() public imageFit = 'contain';

  @Input() public isActive: boolean = undefined;
  @Input() public isInactive: boolean = undefined;
  @Input() public isPending: boolean = undefined;
  @Input() public isActiveBottom: boolean = false;
  @Input() public isImageIllustrative: boolean = false;

  public getIsActive() {
    switch (this.type) {
      case ImagePopoverType.Product:
        this.isActive = this.status == SupplierProductAssortmentStatus.Active;
        break;
      case ImagePopoverType.Regular:
      default:
        this.isActive = this.status == Status.Active;
        break;
    }
  }

  public getIsInactive() {
    switch (this.type) {
      case ImagePopoverType.Product:
        this.isInactive = this.status == SupplierProductAssortmentStatus.EndOfLife || this.status == SupplierProductAssortmentStatus.Inactive;
        break;
      case ImagePopoverType.Regular:
      default:
        this.isInactive = this.status == Status.Disabled;
        break;
    }
  }

  public getIsPending() {
    switch (this.type) {
      case ImagePopoverType.Product:
        this.isPending = this.status == (SupplierProductAssortmentStatus.SchemeStartsTomorrow);
        break;
    }
  }

  public getStatusEnum() {
    switch (this.type) {
      case ImagePopoverType.Product:
        this.StatusEnum = SupplierProductAssortmentStatus;
        break;
      case ImagePopoverType.Regular:
      default:
        this.StatusEnum = Status;
        break;
    }
  }

  public get statusColor() {
    if (this.color === undefined) {
      return null;
    }
    return COLOR_MAP[this.color] || 'var(--light-pink)';
  };

  public ngOnChanges() {
    let thumbnailSource = this.thumbnail;
    if (thumbnailSource && this.thumbnailBase64) {
      thumbnailSource = `data:${this.thumbnailContentType};base64,${thumbnailSource}`;
    }

    let imageSource = this.image;
    if (imageSource && this.imageBase64) {
      imageSource = `data:${this.imageContentType};base64,${imageSource}`;
    }

    this.thumbnailSource = thumbnailSource || imageSource || this._thumbnailFallbackImage;
    this.imageSource = imageSource || this._fallbackImage;

    if (this.StatusEnum === undefined) this.getStatusEnum();
    if (this.isActive === undefined) this.getIsActive();
    if (this.isInactive === undefined) this.getIsInactive();
    if (this.isPending === undefined) this.getIsPending();
  }

  public get hasStatus() {
    return (this.status !== undefined && this.status !== null) || (this.statusText ? true : false);
  }

  public onShown() {
    this.tooltipContentVisible = true;
  }

  public preventPropagation() {
    event.stopImmediatePropagation();
  }
}

export enum ImagePopoverType {
  Regular = 'regular',
  Product = 'product'
}