<scope>
  <card-section>
    <span header-title style="font-size: 24px; font-weight: bold;">
      {{'cards.lexi_market_settings.lxm_settings_user.title' | translate }}
    </span>
    <div
      header-actions
      *ngIf="appState.hasRight([UserAction.ManageUsers])"
      class="flex"
    >
      <lxm-button
        type="beige"
        icon="plus-dark"
        label="cards.lexi_market_settings.lxm_settings_user.action.add_user"
        (click)="openInviteDialog()"
        [disabled]="isUsersSyncInProgress"
      >
      </lxm-button>
      <lxm-button
        *ngIf="hasAzureAdClientSettings"
        class="ml15"
        type="beige"
        icon="plus-dark"
        label="cards.lexi_market_settings.lxm_settings_user.action.sync_azure_ad_users"
        (click)="syncAzureAdUsers()"
        [loading]="isUsersSyncInProgress"
      >
      </lxm-button>
    </div>
    <div body>
      <users-table></users-table>
    </div>
  </card-section>
</scope>
