import { Component, Input, ViewEncapsulation } from '@angular/core';
import { LxmTreeComponent } from '../../../lxm-tree/lxm-tree.component';

@Component({
    selector: "atc-tree",
    templateUrl: './atc-tree.component.html',
    styleUrls: [ './atc-tree.scss' ],
    encapsulation: ViewEncapsulation.None
})
export class AtcTreeComponent {

    @Input() public initialData: any;

    public displayColumns: string[] = ['code', 'name', 'targetGroup', 'canAssignToProduct', 'status'];
}
