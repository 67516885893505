export enum SubscriptionPlan {
    Free = 1,
    XS = 2,
    S = 3,
    L = 4,
    XL = 5,
    M = 6,
    Client = 98,
    RetailChain = 99,
    XSy = 102,
    Sy = 103,
    Ly = 104,
    XLy = 105,
    My = 106,
    XXSy = 107
}

(SubscriptionPlan as any).__typeName = "SubscriptionPlan";
