import { ChangeDetectorRef, Component, Inject, Injector } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DialogHelper, LxmDialog, LxmMessage } from "src/app/_helpers";
import { ProductPricelistService, AuthenticationService, ProductValidity } from "src/app/_services";
import { AppState } from "src/app/state/app.state";
import { IProductCard } from "../product-info/product-info.model";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { FormControl, FormGroup } from "@angular/forms";
import moment from "moment";
import { ActionHelper } from "src/app/_helpers/action";

@Component({
  selector: "product-pricelist-expiration-dialog",
  host: { 'class': 'dialog' },
  templateUrl: './template/product-pricelist-expiration.dialog.html'
})
export class ProductPricelistExpirationDialog {

  public actionLoading = {} as any;

  public formGroup: FormGroup;

  public scheme: any;
  public product: any;

  public minValidTo = moment().add(1, 'day');

  constructor(
    protected _appState: AppState,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ProductPricelistExpirationDialog>,
    public dialog: LxmDialog,
    private _productPricelistService: ProductPricelistService
    ) {}

    ngOnInit() {
      const { scheme, product } = this.data || {};
      const { validFrom, validTo } = scheme || {};

      this.scheme = scheme;
      this.product = product;

      this.formGroup = new FormGroup({
        validFrom: new FormControl({ value: validFrom?.editableDate, disabled: true }),
        validTo: new FormControl(validTo?.editableDate)
      });
    }

    public get schemeId() {
      return this.scheme?.id;
    }

    public get schemeType() {
      return this.scheme?.schemeType;
    }

    public closeDialog(res = null) {
      this.dialogRef.close(res);
    }

    public save() {
      const formValue = this.formGroup.value;
      const validTo = formValue?.validTo
      const req = {
        id: this.schemeId,
        validTo: validTo
      };

      this._productPricelistService.saveRetailPriceEndDate(this.schemeId, req).result(this.formGroup, res => {
        this.closeDialog(validTo);
      }, err => {}, 
      "save", 
      this.actionLoading)

    }

    
}