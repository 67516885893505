import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';

@Injectable({ 
    providedIn: "root" 
})
export class LxmTreeService {
    constructor(private _http: HttpClient) { }

    public getSegments(url: string, parentId: string): Observable<ILxmTreeNode[]> {
        return this._http.get("api/" + url, {
            params: new HttpParams().set("parentId", parentId || "")
        }) as Observable<ILxmTreeNode[]>;
    }
}

export interface ILxmTreeNode {
    id: string,
    parentId: string,
    isParent: boolean,
    isParentActive: boolean,
    level: number
}