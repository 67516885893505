import { Component } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { SettingsService } from 'src/app/_services';
import { LxmMessage } from 'src/app/_helpers';
import { ActivatedRoute } from '@angular/router';
import { IProductExtraParametersSettings } from 'src/app/models/settings/product-fields-settings';
import { ProductField } from 'src/app/enum';

@Component({
  selector: "product-fields-settings-card",
  templateUrl: './product-fields-settings.card.html',
  styleUrls: ['./product-fields-settings.card.scss']
})
export class ProductFieldsSettingsCardComponent {

}

@Component({
  selector: "extra-parameters-settings-card",
  templateUrl: './extra-parameters-settings/extra-parameters-settings.card.html',
  styleUrls: ['./extra-parameters-settings/extra-parameters-settings.card.scss']
})
export class ExtraParametersSettingsCardComponent {

  public saveLoading = false;
  public form: FormGroup;

  constructor(
    route: ActivatedRoute,
    private _message: LxmMessage,
    private _settingsService: SettingsService) {

    const { parameters } = route.snapshot.data.settings as IProductExtraParametersSettings;

    const parametersAsFormGroup = parameters.map(x => new FormGroup({
      fieldCode: new FormControl(x.fieldCode),
      name: new FormControl(x.name),
      isVisible: new FormControl(!x.isDisabled),
    }));

    this.form = new FormGroup({
      parameters: new FormArray(parametersAsFormGroup)
    });
  }

  public get extraParameters() {
    return <FormArray>this.form.get("parameters");
  }

  public getFieldCode(fg: FormGroup): ProductField {
    return fg.get('fieldCode').value;
  }

  public save() {
    this.saveLoading = true;

    const req = {
      parameters: this.form.value.parameters.map((x: any) => ({
        fieldCode: x.fieldCode,
        name: x.name,
        isDisabled: !x.isVisible
      }))
    };

    this._settingsService.saveProductExtraParametersSettings(req)
      .result(this.form, () => {
        this._message.ok({
          message: 'cards.lexi_market_settings.product_fields_settings.save_successful'
        });
        this.saveLoading = false;
      }, () => {
        this._message.error({
          message: 'cards.lexi_market_settings.product_fields_settings.save_failed'
        });
        this.saveLoading = false;
      });
  }
}
