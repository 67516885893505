
<search [formGroup]="searchData" [isOpen]="isOpen" [isLoading]="isLoading" (onToggle)="toggleOpen($event)" placeholder="cards.products.products_retailer_search.placeholder" (onSubmit)="submit()">
  <ng-container panel-left>
    <scope translationsPath="cards.products.products_retailer_search">
      <form-field panel label="brand">
        <lxm-select select
                    labelField="value"
                    [searchable]="true"
                    for="brands"
                    [multiple]="true"
                    additionalField="tenantName"
                    url="api/marketSearch/brands/">
        </lxm-select>
      </form-field>
      <form-field panel label="sub_brand">
        <lxm-select select
                    labelField="value"
                    [searchable]="true"
                    for="subBrands"
                    [multiple]="true"
                    additionalField="tenantName"
                    url="api/marketSearch/subbrands/">
        </lxm-select>
      </form-field>
      <form-field panel label="origin">
        <lxm-select select for="countries"
          value="id"
          [options]="countryOptions"
          [isTranslated]="true"
          labelField="translatedName"
          [sortByLabel]="true"
          [searchable]="true"
          [multiple]="true">
        </lxm-select>
      </form-field>
      <form-field panel label="supplier">
        <lxm-select select
                    labelField="name"
                    [searchable]="true"
                    for="suppliers"
                    [multiple]="true"
                    url="api/clvac/suppliers/">
        </lxm-select>
      </form-field>
    </scope>
  </ng-container>
  <ng-container panel-right>
    <scope translationsPath="cards.products.products_retailer_search">
      <form-field panel label="gs1_tree">
        <lxm-select select
                    labelField="name"
                    [searchable]="true"
                    [multiple]="true"
                    for="gs1Segments"
                    url="api/clvac/gs1-segments/">
        </lxm-select>
      </form-field>
      <form-field panel label="nutritional_claims">
        <lxm-select select for="nutritionalClaims"
                    value="id"
                    labelField="name"
                    [options]="nutritionalClaimsOptions"
                    [sortByLabel]="true"
                    [multiple]="true"
                    [searchable]="true">
        </lxm-select>
      </form-field>
      <form-field panel label="product_attributes">
        <lxm-select select
                    labelField="translatedValue"
                    [options]="formData.productAttributes"
                    [searchable]="true"
                    for="productAttributes"
                    [multiple]="true"
                    [isTranslated]="true"
                    compareField="id">
        </lxm-select>
      </form-field>
      <form-field panel label="product_status">
        <lxm-select select
                    labelField="name"
                    [multiple]="true"
                    value="id"
                    [options]="statuses"
                    for="assortmentStatuses">
        </lxm-select>
      </form-field>
    </scope>
  </ng-container>
</search>
