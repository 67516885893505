
<card>

  <span header-title>{{'cards.lexi_market_settings.lxm_settings.title' | translate }}</span>

  <div body>
    <lxm-settings-navigator></lxm-settings-navigator>
    <router-outlet></router-outlet>
  </div>
</card>
