import { Component, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ILxmTreeNode } from "src/app/_services/lxm-tree.service";
import { LxmTreeComponent, LxmTreeNodeComponent } from "src/app/components/lxm-tree/lxm-tree.component";
import { SegmentDialog } from "src/app/components/_partials/settings/product-segment-tree/dialog/segment.dialog";
import { Status, Bool, UserAction } from "src/app/enum";
import { appSettings } from 'src/app/app.settings';
import { LxmDialog } from 'src/app/_helpers';
import { SegmentService } from 'src/app/_services';
import { forkJoin } from 'rxjs';

@Component({
    selector: "product-segment-tree",
    templateUrl: "./product-segment-tree.component.html",
    styleUrls: [ "./product-segment-tree.scss" ],
    encapsulation: ViewEncapsulation.None
})
export class ProductSegmentTreeComponent {

    @ViewChild(LxmTreeComponent, { static: true })
    private _segmentTree: LxmTreeComponent;

    public displayColumns: string[] = ['code', 'name', 'description', 'canAssignToProduct', 'gpc', 'status', 'actions'];

    // for template
    public UserAction = UserAction;
    public Status = Status;
    public Bool = Bool;

    constructor(
      private _dialog: LxmDialog,
      private _segmentService: SegmentService) { }

    public openSegmentDialog(segment?: ILxmTreeNode, parentSegment?: ILxmTreeNode) {
        if (this._dialog.openDialogs.length !== 0) {
            return;
        }

        if (!parentSegment && segment && segment.parentId) {
          parentSegment = this._segmentTree.getNode(segment.parentId).node;
        }

        this._dialog.open(SegmentDialog, {
            ...appSettings.DIALOG_SIZES.M,
            restoreFocus: false,
            data: {
              parentSegment: parentSegment
            },
              resolve: () => {
                const resolvers: any = {
                  segment: this._segmentService.getDetail(segment?.id)
                };

                return forkJoin(resolvers);
              }
          }, res => {
            if (!res) {
              return;
            }
      
            this._segmentTree.load(parentSegment ? parentSegment.id : null);
            if (!parentSegment) {
              return;
            }

            let nodeComponent = this._segmentTree.getNode(parentSegment.id) as LxmTreeNodeComponent;
            nodeComponent.expanded = true;
          });
    }
}