import { Component, OnInit, HostBinding } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { TitleService } from 'src/app/_services/title.service';

@Component({
  selector: 'user-settings-layout',
  templateUrl: './user-settings.layout.html',
  host: { 'class': 'content' },
  styleUrls: ['./user-settings.layout.scss']
})

export class UserSettingsLayout {
  title = 'Kasutaja seaded';

  constructor(private _titleService: TitleService) { }

  public ngOnInit() {
    this._titleService.setTitle(this.title);
  }

}
