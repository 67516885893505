import { Component } from '@angular/core';
import { ILanguage, LocaleService } from 'src/app/_services';
import { FormGroup, FormControl } from '@angular/forms';
import { appSettings } from 'src/app/app.settings';

@Component({
  selector: "guest-language-selector",
  templateUrl: './template.html',
  styleUrls: ['./styles.scss'],
})
export class GuestLanguageSelectorComponent {

  public languageForm: FormGroup;

  public languageOptions = [];

  constructor(
    private _localeService: LocaleService,
  ) {
    const userLanguage = _localeService.getGuestLanguage();

    this.languageForm = new FormGroup({
      language: new FormControl(userLanguage)
    });

    const uiLanguages = appSettings.uiLanguages;
    this.languageOptions = [uiLanguages[0], uiLanguages[1]];

    // this._localeService.setGuestLanguage(userLanguage.value);

    this.languageForm.get('language').valueChanges.subscribe(lang => {
      this._localeService.setGuestLanguage(lang);
    });

  }

}
