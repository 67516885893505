import {
  Component,
  Input,
  ChangeDetectorRef,
  OnInit,
  Injector,
} from "@angular/core";
import { formatNumber } from "@angular/common";
import { FormGroup, FormControl } from "@angular/forms";
import { forkJoin, Subscription, merge, Observable } from "rxjs";
import {
  ProductPricelistService,
  AuthenticationService,
  ProductValidity,
} from "src/app/_services";
import { LxmDialog } from "src/app/_helpers/dialogs";
import {
  calculateMargin,
  calculateDiscount,
  calculateDiscountNumber,
  nextSchemeDay,
} from "src/app/_helpers/calculate";
import {
  IPricesInfo,
  ISupplierBasePriceScheme,
  ISupplierRetailerPriceScheme,
  IRetailPriceSchemeFormData,
} from "./product-pricelist.model";
import { ActivatedRoute } from "@angular/router";
import {
  IClvDto,
  IProductCard,
  IProductCardFormData,
  IRetailChain,
} from "../product-info/product-info.model";
import {
  Bool,
  UserAction,
  ProductCardSection,
  asArray,
  ProductPriceSchemeType,
  asObject,
} from "src/app/enum";
import { numberUtil } from "src/app/util/number-util";
import moment from "moment-timezone";
import { locale } from "src/app/_services";
import "src/app/ext/date";
import { AppState } from "src/app/state/app.state";
import { appSettings } from "src/app/app.settings";
import {
  IDialogDataBase,
  ProductPricelistDialogBase,
} from "./product-pricelist-dialog-base";
import { LxmMessage } from "src/app/_helpers";
import { ProductCardBase } from "../product-card-base";
import { LOCAL_STORAGE_KEY } from "src/app/config";
import { ProductPricelistExpirationDialog } from "./product-pricelist-expiration.dialog";
import { ProductHeaderState } from "../product-header/product-header.state";
import { ProductPricelistRetailerSchemeDialog } from "./dialog/retailer-scheme/retailer-scheme.dialog";

@Component({
  selector: "product-client-prices-card",
  templateUrl: "./product-client-prices.card.html",
  styleUrls: ["./product-pricelist.card.scss"],
})
export class ProductClientPricesCardComponent extends ProductCardBase<IPricesInfo> {
  public title = "cards.products.product_pricelist.title_clientprices";

  public UserAction = UserAction;
  public Bool = Bool;

  public formData: IProductCardFormData;
  public pricesInfo: IPricesInfo;
  public retailersWithPrices = [];

  public cardSection = ProductCardSection.PriceSchemes;

  public tableSupplier = {
    columns: [
      "list_user",
      "list_valid",
      "list_scheme_type",
      "list_publicSalesPrice",
      "list_discount",
      "list_retailSalesPrice",
      "list_margin",
      "list_approved",
      "list_actions",
    ],
  };

  @Input()
  public productId: string;

  public expanded = true;

  public cardValid = true;
  public canEdit: boolean;

  private _loadSupplierPricesSubscription: Subscription;
  private _loadRetailPricesSubscription: Subscription;

  public productCard: IProductCard;

  public isExpandedKey = LOCAL_STORAGE_KEY.PRODUCT_CLIENT_PRICES_CARD_OPEN;

  constructor(
    injector: Injector,
    private _appState: AppState,
    private _cd: ChangeDetectorRef,
    private _dialog: LxmDialog,
    private _productPricelistService: ProductPricelistService,
    private _route: ActivatedRoute,
    private _authService: AuthenticationService,
    public productValidity: ProductValidity,
    public productState: ProductHeaderState,
    private _msg: LxmMessage,
  ) {
    super(injector, "productClientPrices", "productClientPricesChanged");

    this.productCard = _route.snapshot.data.productCard as IProductCard;
  }

  public get table() {
    return this.tableSupplier;
  }

  public get retailPrices() {
    return this.pricesInfo?.supplierRetailerPrices;
  }

  public getRetailersWithPrices() {
    this.retailersWithPrices = this.pricesInfo?.supplierRetailerPrices
      ? Object.keys(this.pricesInfo?.supplierRetailerPrices)
          .map((x) => ({ id: x, name: this.retailChains[x].name }))
          .sort((a, b) => (a.name > b.name ? 1 : -1))
      : [];
  }

  public retailChains: { [key: string]: IRetailChain } = {};

  public ngOnInit() {
    super.ngOnInit();
    this.canEdit = this._authService.hasRight([UserAction.ManagePrices]);
    this.pricesInfo = this._route.snapshot.data.productCard
      .pricesInfo as IPricesInfo;
    this.formData = this._route.snapshot.data.productCard
      .formData as IProductCardFormData;
    this.retailChains = this.formData.retailChains?.reduce(
      (seed, x) => ({ ...seed, [x.id]: x }),
      {},
    );
    this.getRetailersWithPrices();
  }

  public calculateSupplierDiscount(row: ISupplierBasePriceScheme) {
    return calculateDiscount(row.manufacturerSalesPrice, row.buyInPrice);
  }

  public calculateRetailDiscount(row: ISupplierRetailerPriceScheme) {
    return calculateDiscount(row.publicSalesPrice, row.retailSalesPrice);
  }

  public calculateMargin(row: ISupplierRetailerPriceScheme) {
    const retailerCountryId = row.retailChain?.country?.id;
    const date = row.validFrom.displayDate;
    const baseScheme = this.pricesInfo.supplierBasePrices?.find(
      (x) =>
        x.country.id === retailerCountryId &&
        x.validFrom.displayDate <= date &&
        (!x.validTo || x.validTo.displayDate >= date),
    );

    let buyInPrice = baseScheme?.buyInPrice;

    if (baseScheme) {
      if (
        row.publicSalesPriceCurrency?.id &&
        row.publicSalesPriceCurrency.id != this._appState.defaultCurrency.id
      ) {
        var p1 = baseScheme.publicSalesPrices.find(
          (x) => x.currency.id == this._appState.defaultCurrency.id,
        )?.price;
        var p2 = baseScheme.publicSalesPrices.find(
          (x) => x.currency.id == row.publicSalesPriceCurrency?.id,
        )?.price;

        var rate = p2 / p1;
        buyInPrice = buyInPrice * rate;
      }
    }

    return calculateMargin(row.retailSalesPrice, buyInPrice);
  }

  public loadSupplierPrices(): void {
    if (this._loadSupplierPricesSubscription) {
      this._loadSupplierPricesSubscription.unsubscribe();
    }

    this._loadSupplierPricesSubscription = this._productPricelistService
      .getSupplierPricesGridData(this.productId)
      .subscribe(
        (result) => {
          this.pricesInfo.supplierBasePrices = result;
        },
        (err) => {
          console.error(err);
          this._msg.error({
            message: "cards.products.product_pricelist.table.load_failed",
          });
        },
      );
  }

  public loadRetailPrices(retailChainId: string): void {
    if (this._loadRetailPricesSubscription) {
      this._loadRetailPricesSubscription.unsubscribe();
    }

    this._loadRetailPricesSubscription = this._productPricelistService
      .getRetailPricesGridData(this.productId, retailChainId)
      .subscribe(
        (result) => {
          this.pricesInfo.supplierRetailerPrices[retailChainId] = result;
          this.getRetailersWithPrices();
          this._cd.markForCheck();
        },
        (err) => {
          console.error(err);
          this._msg.error({
            message: "cards.products.product_pricelist.table.load_failed",
          });
        },
      );
  }

  public openRetailerSchemeDialog(
    row?: ISupplierRetailerPriceScheme,
    retailChain?: IRetailChain,
  ) {
    if ((row && !row.isEditable) || !this.canEdit) {
      return;
    }

    this._dialog.open(ProductPricelistRetailerSchemeDialog, {
      ...appSettings.DIALOG_SIZES.M,
      data: {
        pricelistCard: this,
        retailChain: retailChain,
        scheme: row ? { ...row } : null,
        editable: this.canEdit,
      },
      resolve: () => {
        const resolvers: any = {
          formData: this._productPricelistService.getRetailFormData(row?.id),
        };
        return forkJoin(resolvers);
      },
    });
  }

  public openPricelistExpirationDialog(row?: ISupplierRetailerPriceScheme) {
    if (!this.canEdit) {
      return;
    }

    this._dialog.open(
      ProductPricelistExpirationDialog,
      {
        ...appSettings.DIALOG_SIZES.M,
        data: {
          pricelistCard: this,
          scheme: row ? { ...row } : null,
          editable: this.canEdit,
          product: this.productState.displayProductData,
        },
      },
      (res) => {
        if (res) {
          row.validTo = {
            editableDate: res,
            displayDate: moment(res).toDate(),
          };
        }
      },
    );
  }
}
