<div class="lxm-message-container">

  <span class="message-indicator"></span>

  <div class="message">

    <div class="message-data">

      {{data.message | translate: data.args}}

      <div *ngIf="data.template" [innerHTML]="data.template"></div>

      <!--<ng-container *ngTemplateOutlet="template"></ng-container>-->

      <div *ngIf="data.messages">
        <span *ngFor="let message of data.messages">
          {{message | translate}}
        </span>
      </div>

    </div>

    <lxm-button class="message-close" type="transparent" icon="crossmark-sm" (click)="dismiss()"></lxm-button>

  </div>

  <div *ngIf="data.buttonAction" class="action-container">
    <lxm-button type="beige" [icon]="data.buttonIcon" [label]="data.buttonText" (click)="data.buttonAction()"></lxm-button>
  </div>
</div>