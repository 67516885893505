<div [formGroup]="languageForm" class="wrapper">
  <div class="label">
    {{ 'components.guest_language_selector.label.select_language' | translate }}
  </div>
  <div class="select-container">
    <lxm-select 
      [options]="languageOptions" 
      [clearable]="false" 
      labelIconField="iso3"
      labelIconFallback="global"
      labelIconClass="flag-icon"
      for="language">
    </lxm-select>
  </div>
</div>