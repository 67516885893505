import { Injectable } from "@angular/core";
import { AuthenticationService } from "./authentication.service";
import * as signalR from "@microsoft/signalr";
import { BehaviorSubject, Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { HubConnection, HubConnectionState } from "@microsoft/signalr";

@Injectable({
  providedIn: "root"
})
export class SignalRService {

  private _connections: {
    [key: string]: BehaviorSubject<signalR.HubConnection>;
  } = {};

  private _subscriptions: {
    [key: string]: Subscription
  } = {};

  public constructor(private _authenticationService: AuthenticationService) {}

  public get commonHub(): BehaviorSubject<signalR.HubConnection> {
    return this.getConnection("common");
  }

  public get commonHubValue(): signalR.HubConnection {
    return this._connections["common"]?.value;
  }

  public isHubConnected(connection: HubConnection) {
    return connection && connection.state === HubConnectionState.Connected;
  }

  public getConnection(hub: string): BehaviorSubject<signalR.HubConnection> {
    if (!this._connections[hub]) {
      this._connections[hub] = new BehaviorSubject<signalR.HubConnection>(null);

      if (this._subscriptions[hub]) { 
        this._subscriptions[hub].unsubscribe();
      };

      this._subscriptions[hub] = this._authenticationService.jwt.subscribe(jwt => {
        let connection = this._connections[hub].value;
        if (connection && this.isHubConnected(connection)) {
          connection.stop();
        }

        if (jwt) {
          return this._createHub(hub);
        }
      });
    }

    return this._connections[hub];
  }

  private _createConnection(
    hub: string,
    connected: (connection: signalR.HubConnection) => void,
    failed?: (err: any) => void
  ): Promise<signalR.HubConnection> {
    return new Promise<signalR.HubConnection>((resolve, reject) => {
      const connection = new signalR.HubConnectionBuilder()
        .withUrl(`${environment.apiUrl}hub/${hub}`)
        .build();

      connection.onclose(error => {
        if (error) {
          console.error(`Signalr connection in hub ${hub} closed with error, attempting to reconnect`, error);
        } else {
          console.log(`Signalr connection in hub ${hub} closed, attempting to reconnect`);
        }
        // Reconnect
        this._createHub(hub, 30);
      });

      connection
        .start()
        .then(() => {
          connected(connection);
          resolve(connection);
        })
        .catch(err => {
          failed ? failed(err) : "";
          reject(connection);
        });
    });
  }

  private _createHub(
    hub: string, 
    retryCount?: number,
  ) {
    return this._createConnection(
      hub,
      connection => {
        this._connections[hub].next(connection);
      },
      err => {
        console.error(`Error creating signalr connection in hub ${hub}, attempting to reconnect`, err);
        if (retryCount) {
          this._reconnect(hub, retryCount);
        }
      }
    ).then(res => {
      return this._connections[hub];
    });
  }

  private _reconnect(hub: string, count: number = 30, timer = 2000) {
    if (count <= 0) {
      return;
    }

    setTimeout(() => {
      this._createHub(hub, count - 1)
    }, timer);
  }
}
