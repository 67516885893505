import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LocalStorage } from "src/app/_helpers/local-storage";
import { LOCAL_STORAGE_KEY } from "src/app/config";
import { ProductService } from 'src/app/_services';
import { LxmMessage } from 'src/app/_helpers';
import { ActionHelper } from 'src/app/_helpers/action';
import { Router } from '@angular/router';

@Component({
  selector: "product-clone-dialog",
  host: { 'class': 'dialog' },
  templateUrl: './product-clone.dialog.html'
})

export class ProductCloneDialogComponent {

  public form: FormGroup;
  public cloneBaseInfo = true;
  public cloneUnits = false;
  public clonePriceInfo = false;
  public cloneAssortments = false;
  public cloneImages = false;

  public get productId() {
    return this.data.product.productId;
  }

  public actionLoading = {} as any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ProductCloneDialogComponent>,
    private _productService: ProductService,
    private _action: ActionHelper,
    private _router: Router
  ) {
    this.cloneUnits = LocalStorage.getValue(LOCAL_STORAGE_KEY.CLONE_PRODUCT_UNITS);
    this.clonePriceInfo = LocalStorage.getValue(LOCAL_STORAGE_KEY.CLONE_PRODUCT_PRICESCHEMES);
    this.cloneAssortments = LocalStorage.getValue(LOCAL_STORAGE_KEY.CLONE_PRODUCT_ASSORTMENT);
    this.cloneImages = LocalStorage.getValue(LOCAL_STORAGE_KEY.CLONE_PRODUCT_IMAGES);

    this.form = new FormGroup({
      cloneBaseInfo: new FormControl(this.cloneBaseInfo),
      cloneUnits: new FormControl(this.cloneUnits),
      clonePriceInfo: new FormControl(this.clonePriceInfo),
      cloneAssortments: new FormControl(this.cloneAssortments),
      cloneImages: new FormControl(this.cloneImages)
    });
  }

  public saveLocalValue(value: any, formKey: string) {
    switch (formKey) {
      case 'cloneUnits':
        LocalStorage.set(LOCAL_STORAGE_KEY.CLONE_PRODUCT_UNITS, value.checked);
        break;
      case 'clonePriceInfo':
        LocalStorage.set(LOCAL_STORAGE_KEY.CLONE_PRODUCT_PRICESCHEMES, value.checked);
        break;
      case 'cloneAssortments':
        LocalStorage.set(LOCAL_STORAGE_KEY.CLONE_PRODUCT_ASSORTMENT, value.checked);
        break;
      case 'cloneImages':
        LocalStorage.set(LOCAL_STORAGE_KEY.CLONE_PRODUCT_IMAGES, value.checked);
        break;
    }
  }

  public closeDialog(res = null) {
    this.dialogRef.close(res);
  }

  public clone() {
    const cloneRequest = {
      productId: this.productId,
      cloneUnits: LocalStorage.getValue(LOCAL_STORAGE_KEY.CLONE_PRODUCT_UNITS) ?? false,
      clonePriceInfo: LocalStorage.getValue(LOCAL_STORAGE_KEY.CLONE_PRODUCT_PRICESCHEMES) ?? false,
      cloneAssortments: LocalStorage.getValue(LOCAL_STORAGE_KEY.CLONE_PRODUCT_ASSORTMENT) ?? false,
      cloneImages: LocalStorage.getValue(LOCAL_STORAGE_KEY.CLONE_PRODUCT_IMAGES) ?? false
    }

    const cloneMessage = this._action.createMessage('common.message.product_clone_success');

    this._productService.cloneProduct(cloneRequest)
      .result(null, id => {
        this.closeDialog(id);
      }, 
      error => {},
      "clone",
      this.actionLoading,
      cloneMessage
      );
  }
}