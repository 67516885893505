import { Component } from '@angular/core';
import { LocaleService } from 'src/app/_services';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: "lxm-terms-dialog",
  host: { 'class': 'dialog' },
  templateUrl: './terms.dialog.html',
  styleUrls: ['./terms.scss'],
})
export class TermsDialogComponent {

  public language: string;

  public constructor(
    localeService: LocaleService,
    public dialogRef: MatDialogRef<TermsDialogComponent>
  ) {
    this.language = localeService.currentLanguage.value;
  }

  public closeDialog() {
    this.dialogRef.close();
  }
}
