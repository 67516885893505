import { Component } from "@angular/core";

@Component({
  selector: "lxm-settings-layout",
  templateUrl: "./lxm-settings.layout.html",
  host: { 'class': 'content' },
  styleUrls: ["./lxm-settings.layout.scss"]
})
export class LxmSettingsLayout {

}
