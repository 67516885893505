import { Component, Input, Optional, OnInit, Host, Output, EventEmitter, HostBinding } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormGroup, ControlContainer} from '@angular/forms';
import { ReadonlyDirective } from 'src/app/directives';
import { UserAction } from 'src/app/enum';

@Component({
  selector: "slide-toggle",
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SlideToggleComponent implements OnInit {

  @Input() public yes: string = 'global.yes';
  @Input() public no: string = 'global.no';
  @Input() public label: string;
  @Input() public formGroup: FormGroup;
  @Input() public for: string;
  @HostBinding('class.readonly') @Input() public readonly = false;
  @Output() public change = new EventEmitter();

  constructor(
    @Optional() @Host() private _readonlyQuery: ReadonlyDirective,
    @Optional() @Optional() private _controlContainer: ControlContainer
  ) {
  }

  public ngOnInit(): void {

    if (this._readonlyQuery && this._readonlyQuery.readonly) {
      this.readonly = this._readonlyQuery.readonly;
    }

    if (this._controlContainer && this._controlContainer.control instanceof FormGroup) {
      this.formGroup = <FormGroup>this._controlContainer.control;
    }
  }

  public preventDefault() {
    event.preventDefault();
  }

}
