import { Component, Inject, Optional, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AssortmentSchemesService, AuthenticationService } from 'src/app/_services';
import { finalize } from 'rxjs/operators';
import { DialogComponent } from 'src/app/components/_layout/dialog/dialog.component';
import { UserAction } from 'src/app/enum';

@Component({
  selector: "assortment-scheme-dialog",
  host: { 'class': 'dialog' },
  templateUrl: './assortment-scheme.dialog.html',
  styleUrls: []
})
export class AssortmentSchemeDialogComponent {

  public UserAction = UserAction;

  public form: FormGroup;

  public isNew: boolean;
  public canEdit: boolean = true;

  public saveInProgress = false;

  @ViewChild('dialog', { static: true, read: DialogComponent })
  private _dialog: DialogComponent;

  constructor(
    private _assortmentSchemesService: AssortmentSchemesService,
    public dialogRef: MatDialogRef<AssortmentSchemeDialogComponent>,
    authService: AuthenticationService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isNew = !this.data.item;
    const assortmentScheme = this.data.item || {};

    this.form = new FormGroup({
      id: new FormControl(assortmentScheme.id),
      validFrom: new FormControl(assortmentScheme.validFrom),
      validTo: new FormControl(assortmentScheme.validTo)
    });

    this.canEdit = authService.hasRight([UserAction.ManageBuyerAssortment]);
  }

  public save(): void {
    this.saveInProgress = true;
    const f = this.form.value;

    const req = {
      validFrom: f.validFrom,
      validTo: f.validTo
    };

    const o = f.id
      ? this._assortmentSchemesService.save(f.id, req)
      : this._assortmentSchemesService.create(req);

    o.pipe(
        finalize(() => {
          this.saveInProgress = false;
        })
      ).result(this.form, response => {
      this.closeDialog(response);
    });
  }

  public closeDialog(data?: any): void {
    this.dialogRef.close(data);
  }
}
