import { Pipe, PipeTransform } from '@angular/core';
import { numberUtil } from '../util/number-util';
import { locale as appLocale } from 'src/app/_services';

@Pipe({
    name: 'numericValue',
    pure: true
})
export class NumericValuePipe implements PipeTransform {
    transform(value: any, digits: number = 3, locale = appLocale) {
        const digitsInfo = `1.${digits}-${digits}`;
        return numberUtil.formatNumber(value, digitsInfo, locale);
    }
}
