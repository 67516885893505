<lxm-dialog [dialogRef]="dialogRef" [formGroup]="form">
  <span header-title>
    {{ 'cards.offer.order_dialog.title' | translate }}
  </span>

  <div body>

    <display-product class="dialog-display-product-sticky" [data]="product"> </display-product>

    <form-field
      dialog
      label="cards.offer.order_dialog.label.product_is_orderable">
      <slide-toggle toggle for="canOrder" [readonly]="readonly"></slide-toggle>
    </form-field>

    <form-field
      dialog
      label="cards.offer.order_dialog.label.order_unit">
      <lxm-select select [readonly]="readonly" class="dialog-input-fixed-width-3" for="orderUnitId" value="id" [options]="orderUnitOptions" labelField="translatedValue" [isTranslated]="true" [clearable]="false"></lxm-select>
    <validation-errors
      for="minAmount"
    ></validation-errors>
  </form-field>

    <form-field
      dialog
      label="cards.offer.order_dialog.label.order_min_amount">
      <lxm-input class="dialog-input-fixed-width-1" [readonly]="readonly" input for="minOrderUnits"></lxm-input>
      <validation-errors
        for="minAmount"
      ></validation-errors>
    </form-field>

    <form-field
    dialog
    label="cards.offer.order_dialog.label.order_min_price">
    <lxm-input class="dialog-input-fixed-width-1" [readonly]="readonly" input for="minPrice"></lxm-input>
    <validation-errors
      for="minPrice"
    ></validation-errors>
  </form-field>

  </div>

  <div footer>
    <lxm-button
      type="primary"
      label="action.save"
      (click)="save()"
      buttonType="submit"
      *ngIf="!readonly"
    ></lxm-button>
    <lxm-button
      type="secondary"
      [class.ml15]="!readonly"
      label="action.cancel"
      (click)="closeDialog()"
    ></lxm-button>
  </div>
</lxm-dialog>
