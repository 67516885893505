import { formatNumber } from "@angular/common";
import { IVatDto } from "../cards/product/product-pricelist/product-pricelist.model";
import { locale } from "../_services";

export function nextSchemeDay(minDate?: Date): Date {
    const now = new Date();
    minDate = minDate || now;
    const next = minDate > now ? minDate : now;
    return new Date(next.getFullYear(), next.getMonth(), next.getDate() + 1).stripTimezone();
  }

export function pastOrTodayNextSchemeDay(minDate?: Date): Date {
    const now = new Date();
    minDate = minDate || now;
    if (minDate > now) {
      return minDate;
    }
    
    return nextSchemeDay();
  }

export function getVats(vats: IVatDto[], countryId: string) {
    let vatOptions = vats
      .filter(x => countryId ? x.country.id === countryId : true)
      .map(v => formatVat(v));
  
    if (vatOptions.length === 0) {
      vatOptions = vats
        .map(v => formatVat(v));
    }
  
    return vatOptions;
  }
  
  export function formatVat(vat: IVatDto): any {
    return {
      id: vat.id,
      value: `${formatNumber(vat.numericValue, locale, '1.0-0')}% (${vat.country.name})`,
      numericValue: vat.numericValue
    };
  }