<lxm-dialog [dialogRef]="dialogRef" [formGroup]="form" [readonly]="!canEdit">
    <span header-title>
      {{ ('cards.settings.product_management_settings.content_languages.' + (isNew ? 'title_new_item' : 'title_edit')) | translate }}
    </span>

    <div body style="padding-bottom: 40px;">
        <scope translationsPath="cards.settings.product_management_settings.content_languages">

          <form-field dialog label="twoLetterIso">
              <span text>{{ form.value.language?.id | formValue }}</span>
          </form-field>

          <form-field dialog label="language" [required]="canEdit">
              <lxm-select select
                          labelField="name"
                          [searchable]="true"
                          for="language"
                          [acData]="languageExcludeIds"
                          url="api/clvac/languages/">
              </lxm-select>
              <validation-errors for="languageTwoLetterIso" aliasTo="language"></validation-errors>
          </form-field>

          <form-field dialog label="order_nr">
            <lxm-input input for="orderNr"></lxm-input>
            <validation-errors for="orderNr"></validation-errors>
          </form-field>

          <form-field dialog label="is_required">
            <slide-toggle toggle for="isRequired"></slide-toggle>
            <validation-errors for="isRequired"></validation-errors>
          </form-field>

          <form-field dialog label="status">
            <lxm-select select for="status" class="w-full" 
                        value="id"
                        [searchable]="true" 
                        [options]="statuses">
            </lxm-select>
            <validation-errors for="status"></validation-errors>
          </form-field>

        </scope>
    </div>

    <div footer *requiredRights="[UserAction.ManageClvs]">
      <lxm-button type="primary" label="cards.settings.product_management_settings.segments_tree_edit.action.save" (click)="save()" [loading]="saveInProgress" buttonType="submit"></lxm-button>
      <lxm-button type="secondary" class="ml15" label="cards.settings.product_management_settings.segments_tree_edit.action.cancel" (click)="closeDialog()"></lxm-button>
    </div>
</lxm-dialog>
