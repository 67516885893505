<div
  class="overlay-content {{ animation }} flex flex-1 flex-col overflow-auto"
  [class.animation-closing]="OVService.animationClosingIndex === index"
  (click)="$event.stopImmediatePropagation()"
>
  <!-- HEADER -->
  <div
    class="flex min-h-15x {{
      content?.headerBorder ? 'border-b border-b-grey-30' : ''
    }} px-8x py-6x"
  >
    <div
      class="relative flex flex-1 shrink-0 items-center gap-5x text-ellipsis pr-10"
    >
      <!-- HEADER CONTENT GOES HERE -->
      <ng-container [ngSwitch]="content?.headerRef ? true : false">
        <ng-container
          [ngTemplateOutlet]="content.headerRef"
          *ngSwitchCase="true"
        ></ng-container>
        <ov-header
          [label]="content?.headerLabel"
          [icon]="content?.headerIcon"
          [close]="close.bind(this)"
          *ngSwitchDefault
        ></ov-header>
      </ng-container>

      <ng-content select="[header]"></ng-content>
      <!-- ACTIONS -->
      <div class="absolute right-0 flex justify-end">
        <button
          matRipple
          class="flex size-15x items-center justify-center"
          (click)="OVService.clear()"
        >
          <svg-icon key="x"></svg-icon>
        </button>
      </div>
    </div>
  </div>

  <!-- CONTENT -->
  <div class="flex flex-1 flex-col overflow-auto px-8x py-5x">
    <ng-container [ngTemplateOutlet]="content?.contentRef"></ng-container>
    <ng-content></ng-content>
  </div>


  <!-- FOOTER -->
  <ng-container *ngIf="content?.footerRef">
    <ng-container [ngTemplateOutlet]="content.footerRef"></ng-container>
  </ng-container>


</div>
