import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({ providedIn: "root" })
export class RoleService {
    public constructor(private _http: HttpClient) {}

    public save(id: string, data: any) {
        var url = `api/roles/${id || ''}`;
        return this._http.post(url, data);
    }

    public delete(id: string) {
        var url = `api/roles/${id}`;
        return this._http.delete(url);
    }
}