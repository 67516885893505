import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'address',
  pure: true
})
export class AddressPipe implements PipeTransform {

  public transform(address: any): string {
    if (!address) {
      return null;
    }

    const streetPart = this.join(' ', [
      address.street,
      address.nr
    ]);

    return this.join(', ', [
      streetPart,
      address.city,
      address.postalCode,
      address.county,
      address.country?.name
    ]);
  }

  private join(separator: string, items: string[]) {
    return items
      .filter(x => x)
      .join(separator);
  }
}
