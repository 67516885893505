<table [formGroup]="fgInternal" class="w-full" style="table-layout: fixed">
  <thead>
    <tr>
      <th class="col-buyinprice">
        <span
          [innerHTML]="
            'cards.products.product_pricelist_retailer_supplier.table.buy_in_price'
              | translate
          "
        ></span>
      </th>
      <th class="col-markup">
        <span
          [innerHTML]="
            'cards.products.product_pricelist_retailer_supplier.table.' +
              (markupMethod == MarkupMethod.Coverage ? 'coverage' : 'margin')
              | translate
          "
        ></span>
      </th>
      <th class="col-price" *ngIf="pricesWithoutVat">
        <span
          [innerHTML]="
            'cards.products.product_pricelist_retailer_supplier.table.retail_price_without_vat'
              | translate
          "
        ></span>
      </th>
      <th class="col-price">
        <span
          [innerHTML]="
            'cards.products.product_pricelist_retailer_supplier.table.retail_price'
              | translate
          "
        ></span>
      </th>
    </tr>
  </thead>
  <tbody [formArrayName]="for">
    <tr
      *ngFor="let retailPrice of formArray?.controls; let i = index"
      class="price-row"
      style="position: relative;"
    >
      <td class="col-buyinprice" [formGroup]="formGroup">
        <div class="td-content" *ngIf="i === 0">
          <div class="triple-rows">
            <div class="cell-row list-price">
              {{
                formGroup.get("listPrice").value
                  | priceValue: 3:defaultCurrency?.value
              }}
            </div>
            <div class="cell-row alt-text">
              {{
                calculateMargin(
                  formGroup.get("buyInPrice").value,
                  formGroup.get("listPrice").value
                ) | percentValue
              }}
            </div>

            <div class="cell-row bold" [ngSwitch]="buyInPriceChanged">
              <div class="flex f-0 align-center">
                <ng-container *ngSwitchCase="true">
                  <lxm-input
                    for="buyInPrice"
                    class="w-full"
                    type="price"
                    [readonly]="readonly"
                    [currency]="buyInPriceCurrency"
                    [pricePrecision]="digits">
                  </lxm-input>
                </ng-container>

                <ng-container *ngSwitchCase="false">
                  <span class="f-1" style="white-space: nowrap;">
                    {{
                      formGroup.get("buyInPrice").value
                        | priceValue: 3:defaultCurrency?.value
                    }}
                  </span>
                  <lxm-button
                    *ngIf="
                      !readonly && isBuyInPriceChangeable && !buyInPriceChanged
                    "
                    class="f-0"
                    type="transparent"
                    [icon]="'edit'"
                    color="grey"
                    (click)="editBuyInPrice()"
                  >
                  </lxm-button>
                </ng-container>
              </div>
            </div>

            <div class="cell-row alt-text">
              {{
                calculateUnitPrice(formGroup.get("buyInPrice").value)
                  | priceValue: pricePrecision:defaultCurrency?.value
              }}{{
                calculateUnitPrice(formGroup.get("buyInPrice").value)
                  ? "/" +
                    (formGroup.value.contentUnitId | translateEnum: "UnitShort")
                  : null
              }}
            </div>
            <!-- <div class="cell-row" *ngIf="!readonly && isBuyInPriceChangeable"> && !buyInPriceChanged
                <lxm-button class="f-1"
                  type="transparent"
                  [icon]="'edit'"
                  color="grey"
                  (click)="editBuyInPrice()">
                </lxm-button>
              </div> -->
          </div>
        </div>
        <div
          class="flex flex-row align-center justify-end alt-text"
          *ngIf="i > 0"
          style="height: var(--input-height); padding: var(--input-padding); padding-left: 0;"
        >
          {{ retailPrice.value.currency?.value }}
        </div>
      </td>

      <td class="col-markup" [formGroup]="retailPrice">
        <div class="td-content">
          <lxm-input
            input
            for="margin"
            class="f-1"
            type="percent"
            digits="1"
            [readonly]="readonly"
          >
            <validation-errors for="margin"></validation-errors>
          </lxm-input>
        </div>
      </td>

      <td class="col-price" *ngIf="pricesWithoutVat" [formGroup]="retailPrice">
        <div class="td-content">
          <lxm-input
            input
            class="f-1"
            for="priceWithoutVat"
            [inputId]="for"
            [readonly]="readonly || retailPrice.value.deleted"
            [currency]="retailPrice.value.currency"
            type="price"
            [pricePrecision]="digits"
          >
            <validation-errors
              [for]="'priceScheme.' + for + '[' + i + '].priceWithoutVat'"
              [errorsFormGroup]="formGroup"
              aliasTo="priceWithoutVat"
            ></validation-errors>
          </lxm-input>
        </div>
      </td>

      <td class="col-price" [formGroup]="retailPrice">
        <lxm-input
          for="price"
          [inputId]="for"
          class="f-1"
          [readonly]="readonly"
          [currency]="retailPrice.value.currency"
          type="price"
          [pricePrecision]="digits"
        >
          <validation-errors
            [for]="'priceScheme.' + for + '[' + i + '].price'"
            [errorsFormGroup]="formGroup"
            aliasTo="price"
          ></validation-errors>
        </lxm-input>
      </td>
    </tr>
  </tbody>
</table>
