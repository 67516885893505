export enum TenantField {
  Name = 1,
  RegNo = 2,
  KmkrNr = 3,
  Address = 4,
  IsFIEOrForeign = 5,
  Status = 6,
  DefaultLanguage = 7,
  ExcelExport = 8,
  DeliveryMethod = 9,
  Currency = 10,
  PaymentDeadlineInDays = 11,
  FineForDelay = 12,
  Homepage = 13,
  Email = 14,
  LogoFile = 15,
  Contacts = 16,
  Owner = 17,
  AddressText = 18,
  ShortName = 19,
  Country = 20,
  Gln = 24
}

(TenantField as any).__typeName = 'TenantField';