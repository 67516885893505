<div
  class="combined-input"
  [formGroup]="formGroup"
  [class.ng-invalid]="isValid === false"
>
  <div class="input">
    <lxm-input
      [for]="for"
      (blur)="blur.emit($event)"
      [type]="inputType"
      [inputId]="id || for"
      placeholder="{{ placeholder | translate }}"
      [readonly]="readonly"
    >
    </lxm-input>
  </div>
  <div class="suffix">
    <ng-content *ngTemplateOutlet="suffixTpl"></ng-content>
    {{ suffix }}
    <span
      *ngIf="icon"
      class="{{ icon }}-icon"
      [class.disabled]="disabled"
    ></span>
  </div>
  <ng-content select="validation-errors"></ng-content>
</div>

<ng-template #suffixTpl
  ><ng-content select="[suffix]"></ng-content
></ng-template>
