import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AppState } from 'src/app/state/app.state';
import { ILanguageText, IProductCode } from '../cards/product/product-info/product-info.model';

@Injectable({ providedIn: "root" })
export class LanguageTextControlBuilder {
  constructor(
    private _fb: FormBuilder,
    private _appState: AppState
  ) {}

  public createLanguageTextControls(
    val?: ILanguageText[],
    source = this._appState.userSettings.contentLanguages
  ) {
    const normalizedItems = this.getNormalizedItems(val, source);
    return this._fb.array(this.createLanguageTextArray(normalizedItems));
  }

  public getNormalizedItems(
    val?: ILanguageText[],
    source = this._appState.userSettings.contentLanguages
  ) {
    const res: ILanguageText[] = [];
    const dict: { [index: string]: ILanguageText } = {};
    for (
      let i = 0;
      i < source?.length;
      i++
    ) {
      const language = source?.[i];
      const x = {
        language: language,
        value: ""
      };

      res.push(x);
      dict[language] = x;
    }

    if (val) {
      for (let i = 0; i < val.length; i++) {
        const language = val[i].language;
        if (dict[language] !== undefined) {
          dict[language].value = val[i].value;
        }
      }
    }

    return res;
  }

  public createLanguageTextArray(array: ILanguageText[]) {
    let propArray = [];
    for (let i = 0; i < array.length; i++) {
      propArray.push(this.createLanguageTextControl(array[i]));
    }
    return propArray;
  }

  public createLanguageTextControl(item: ILanguageText) {
    return this._fb.group(item);

    // return this._fb.group({
    //   language: item.language,
    //   value: item.value
    // });
  }
}

@Injectable({ providedIn: 'root' })
export class CodeControlBuilder {

  constructor(private _fb: FormBuilder) { }

  createCodeControls(array) {
    return this._fb.array(this.createCodeArray(array));
  }

  createCodeArray(array) {
    let propArray = [];
    for (let i = 0; i < array.length; i++) {
      propArray.push(this.createCodeControl(array[i]));
    }
    return propArray;
  }

  createCodeControl(item) {
    return this._fb.group(this.getNormalizedItem(item));
  }

  getNormalizedItem(item: any) {
    if (item.isNormalized) {
      return item;
    }

    return {
      id: item.id,
      isSystemCode: item.isSystemCode,
      isPrimary: item.isPrimary,
      formatId: item.format?.id || null,
      typeId: item.type?.id || null,
      group: item.group,
      prevGroup: item.group,
      gs1CheckStatus: item.gs1CheckResult || null,
      value: item.value,
      isDeleted: item.isDeleted || false,
      isNew: item.isNew || false,
      isNormalized: true
    };
  }

  getNormalizedItems(items: any[]) {
    return items.map(x => this.getNormalizedItem(x));
  }
}

@Injectable({ providedIn: 'root' })
export class DepositControlBuilder {

  constructor(private _fb: FormBuilder) { }

  createDepositControls(array) {
    return this._fb.array(this.createDepositArray(array));
  }

  createDepositArray(array) {
    let propArray = [];
    for (let i = 0; i < array.length; i++) {
      propArray.push(this.createDepositControl(array[i]));
    }
    return propArray;
  }

  createDepositControl(item) {
    return this._fb.group({
      id: item.id,
      deposit: item.depositType?.id,
      amount_pc: item.amount_pc,
      isDeleted: item.isDeleted || false,
      isNew: item.isNew || false
    });
  }

}

@Injectable({ providedIn: 'root' })
export class TareControlBuilder {

  constructor(private _fb: FormBuilder) { }

  public createTareControls(array) {
    return this._fb.array(this.createTareArray(array));
  }

  public createTareArray(array) {
    let propArray = [];
    for (let i = 0; i < array.length; i++) {
      propArray.push(this.createTareControl(array[i]));
    }
    return propArray;
  }

  public createTareControl(item) {
    return this._fb.group({
      id: item.id,
      tare: item.tareType?.id,
      amount_pc: item.amount_pc,
      isDeleted: item.isDeleted || false,
      isNew: item.isNew || false
    });
  }
}

@Injectable({ providedIn: 'root' })
export class PackageMaterialControlBuilder {

  constructor(private _fb: FormBuilder) { }

  public createPackageMaterialControls(array) {
    return this._fb.array(this.createPackageMaterialArray(array));
  }

  public createPackageMaterialArray(array) {
    let propArray = [];
    for (let i = 0; i < array.length; i++) {
      propArray.push(this.createPackageMaterialControl(array[i]));
    }
    return propArray;
  }

  public createPackageMaterialControl(item) {
    return this._fb.group({
      id: item.id,
      material: item.materialType?.id,
      amount_g: item.amount_g,
      isReusable: item.isReusable,
      isDeleted: item.isDeleted || false
    });
  }

}

export let emptyExpiryModel = {
  type: { id: null, name: '' },
  expiryDays: null,
  expiryRetailer: null,
  isDeleted: false,
  isNew: true
};

@Injectable({ providedIn: 'root' })
export class ProductControlBuilder {

  constructor(private _fb: FormBuilder) { }

  public createProductControls(array) {
    return this._fb.array(this.createProductArray(array));
  }

  public createProductArray(array) {
    let propArray = [];
    for (let i = 0; i < array.length; i++) {
      propArray.push(this.createProductControl(array[i]));
    }
    return propArray;
  }

  public createProductControl(item) {
    return this._fb.group({
      order: item.order,
      productId: item.productId,
      preview: item.preview,
      name: item.name,
      ean: item.ean,
      brand: item.brand,
      availableFrom: item.availableFrom,
      internalPrice: item.internalPrice,
      internalPricelist: item.internalPricelist,
      priceRetailer: item.priceRetailer,
      priceRecommended: item.priceRecommended,
      shops: item.shops,
      isDeleted: item.isDeleted || false
    });
  }
}
