import { Component, Injector, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IProductCard, IProductChangeLog } from '../product-info/product-info.model';
import { SignalRService } from 'src/app/_services';
import { HubConnection } from '@microsoft/signalr';
import { Observable, Subscription } from 'rxjs';
import { ProductCardSection } from 'src/app/enum';
import { ProductCardBase } from '../product-card-base';
import { FormGroup } from '@angular/forms';
import { LOCAL_STORAGE_KEY } from 'src/app/config';

@Component({
  selector: 'product-history-card',
  templateUrl: './product-history.card.html',
  styleUrls: ['./product-history.card.scss']
})
export class ProductHistoryCardComponent extends ProductCardBase<IProductChangeLog[]> implements OnDestroy {

  public title = '';

  private _hubConnection: HubConnection;
  private _signalRSubscription: Subscription;

  public history: IProductChangeLog[];

  public cardSection = ProductCardSection.History;

  @Input()
  public productId: string;

  public isExpandedKey = LOCAL_STORAGE_KEY.PRODUCT_HISTORY_CARD_OPEN;

  constructor(
    injector: Injector,
    private _route: ActivatedRoute,
    private _signalRService: SignalRService
  ) {
    super(injector, 'productHistory', 'productHistoryChanged');
    const productCard = this._route.snapshot.data.productCard as IProductCard;
    this.history = productCard.changeLog;

    this._signalRSubscription = this._signalRService.commonHub.subscribe(x => {
      this._hubConnection = x;
      if (x === null) {
        return;
      }

      x.on('productChanged', (data: IProductChangeLog[]) => {
        this.history = data;
      });
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._hubConnection) {
      this._hubConnection.off('productChanged');
    }

    if (this._signalRSubscription) {
      this._signalRSubscription.unsubscribe();
    }
  }
}
