import { Component, Injector, Input } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { ProductCardBase } from "../product-card-base";
import {
  IProductCardMarkingAndNutritionInfo,
  IClvDto
} from "../product-info/product-info.model";
import { Observable } from "rxjs";
import {
  asArray,
  GmoContent,
  IEnumValue,
  asObject,
  UserAction,
  ProductCardSection,
  ProductField,
  ValidType
} from "src/app/enum";
import { formUtil, keep } from "src/app/util/form-util";
import { ProductValidity } from "src/app/_services";
import { LOCAL_STORAGE_KEY } from "src/app/config";
import { ProductHeaderState } from "../product-header/product-header.state";

@Component({
  selector: "product-nutritional-card",
  templateUrl: "./product-nutritional.card.html",
  styleUrls: ["./product-nutritional.card.scss"]
})
export class ProductNutritionalCard extends ProductCardBase<
  IProductCardMarkingAndNutritionInfo
> {
  public UserAction = UserAction;

  public get title() {
    return "cards.products.product_certificate_info_and_nutrition_info.title";
  }

  public cardSection = ProductCardSection.MarkingAndNutritionInfo;

  public form: FormGroup;
  public allergenOptions: IClvDto[];
  public gmoContentOptions: IClvDto[];
  public dairyTypes: IClvDto[];
  public ethicalMarkings: IClvDto[];
  public selectedLabelLanguages: any[];

  public isExpandedKey = LOCAL_STORAGE_KEY.PRODUCT_NUTRITIONAL_CARD_OPEN;

  @Input() public showGrainFree: boolean;

  constructor(
    injector: Injector,
    public productValidity: ProductValidity,
    private _productHeaderState: ProductHeaderState
  ) {
    super(injector, "markingAndNutritionInfo", "productNutritionInfoChanged");
    this.allergenOptions = this._productCard.formData.allergens;
    this.gmoContentOptions = this._productCard.formData.gmoContents;
    this.dairyTypes = this._productCard.formData.dairyTypes;
    this.ethicalMarkings = this._productCard.formData.ethicalMarkings;

    this.bindAllergenFree();
  }

  public get cardValidType(): ValidType {
    return this.productValidity.getCardStatusValidType(this.cardSection);
  }

  public get shouldExpandSections() {
    return this.productValidity.shouldValidate
      ? this.cardValidType === ValidType.Invalid ||
        this.cardValidType === ValidType.Recommended
        ? true
        : false
      : false;
  }

  protected get successMessage(): string {
    return "cards.products.product_certificate_info_and_nutrition_info.save_successful";
  }

  protected _getNormalizedFormData(data: IProductCardMarkingAndNutritionInfo) {
    this.selectedLabelLanguages = data.labelLanguages;
    return formUtil.transformData(data, {
      ethicalMarkingId: () => data.ethicalMarkings?.map(x => x.id),
      ecoFriendly: keep,
      healthyOption: keep,
      alcoholFree: keep,
      labelLanguages: keep,
      allergySealOfApproval: keep,
      allergenFree: keep,
      sensitive: keep,
      parabenFree: keep,
      odorless: keep,
      natural: keep,
      gmoContentId: () => data.gmoContent?.id,
      additiveFree: keep,
      soyBased: keep,
      suitableForVegans: keep,
      suitableForVegetarians: keep,
      lactoseContentReduced: keep,
      lactoseFree: keep,
      dairyFree: keep,
      dairyTypeId: () => data.dairyType?.id,
      reducedEnergyFood: keep,
      lowCalorie: keep,
      calorieFree: keep,
      lowGluten: keep,
      glutenFree: keep,
      sourceOfFiber: keep,
      highFiber: keep,
      sourceOfProtein: keep,
      highProtein: keep,
      reducedSugarFood: keep,
      lowSugar: keep,
      sugarFree: keep,
      noAddedSugar: keep,
      lowSodium: keep,
      veryLowSodium: keep,
      sodiumFree: keep,
      noAddedSodium: keep,
      highInMonounsaturatedFattyAcid: keep,
      highInPolyunsaturatedFattyAcid: keep,
      highInUnsaturatedFattyAcid: keep,
      reducedSaturatedFat: keep,
      lowSaturatedFat: keep,
      saturatedFatFree: keep,
      lowFat: keep,
      fatFree: keep,
      lightOrLite: keep,
      sourceOfOmega3: keep,
      omega3Rich: keep,
      ingredients: keep,
      grainFree: keep,
      enrichedWithProtein: keep,
      enrichedWithVitamins: keep,
      yeastFree: keep,
      noAddedYeast: keep,
      eggFree: keep,
      meatContentIncreased: keep,

      allergens: keep,

      energy_kJ: keep,
      energy_kcal: keep,
      totalFat_g: keep,
      saturatedFat_g: keep,
      monounsaturatedFat_g: keep,
      polyunsaturatedFat_g: keep,
      totalCarbohydrate_g: keep,
      totalSugars_g: keep,
      sugarAlcohol_g: keep,
      starch_g: keep,
      dietaryFiber_g: keep,
      protein_g: keep,
      sodium_g: keep,

      vitaminA_mcg: keep,
      vitaminB6_mg: keep,
      vitaminB12_mcg: keep,
      vitaminC_mg: keep,
      vitaminD_mcg: keep,
      vitaminE_mg: keep,
      vitaminK_mcg: keep,
      biotin_mcg: keep,
      fluoride_mg: keep,
      folicAcid_mcg: keep,
      phosphorus_mg: keep,
      iodine_mcg: keep,
      potassium_mg: keep,
      calcium_mg: keep,
      chromium_mcg: keep,
      magnesium_mg: keep,
      manganese_mg: keep,
      molybdenum_mcg: keep,
      niacin_mg: keep,
      pantothenicAcid_mg: keep,
      iron_mg: keep,
      riboflavin_mg: keep,
      selenium_mcg: keep,
      thiamin_mg: keep,
      zinc_mg: keep,
      copper_mg: keep
    });
  }

  protected _createFormGroup(
    data: IProductCardMarkingAndNutritionInfo
  ): FormGroup {
    return formUtil.createFormGroup(this._getNormalizedFormData(data));
  }

  protected _createSaveRequest() {
    const data = this.form.value;
    return {
      ethicalMarkingIds: data.ethicalMarkingId,
      ecoFriendly: data.ecoFriendly || false,
      healthyOption: data.healthyOption || false,
      alcoholFree: data.alcoholFree || false,
      labelLanguages: data.labelLanguages?.map(x => x.id),
      allergenFree: data.allergenFree || false,
      sensitive: data.sensitive || false,
      parabenFree: data.parabenFree || false,
      odorless: data.odorless || false,
      natural: data.natural || false,
      gmoContentId: data.gmoContentId,
      additiveFree: data.additiveFree || false,
      soyBased: data.soyBased || false,
      suitableForVegans: data.suitableForVegans || false,
      suitableForVegetarians: data.suitableForVegetarians || false,
      lactoseContentReduced: data.lactoseContentReduced || false,
      lactoseFree: data.lactoseFree || false,
      dairyFree: data.dairyFree || false,
      dairyTypeId: data.dairyTypeId,
      reducedEnergyFood: data.reducedEnergyFood || false,
      lowCalorie: data.lowCalorie || false,
      calorieFree: data.calorieFree || false,
      lowGluten: data.lowGluten || false,
      glutenFree: data.glutenFree || false,
      sourceOfFiber: data.sourceOfFiber || false,
      highFiber: data.highFiber || false,
      sourceOfProtein: data.sourceOfProtein || false,
      highProtein: data.highProtein || false,
      reducedSugarFood: data.reducedSugarFood || false,
      lowSugar: data.lowSugar || false,
      sugarFree: data.sugarFree || false,
      noAddedSugar: data.noAddedSugar || false,
      lowSodium: data.lowSodium || false,
      veryLowSodium: data.veryLowSodium || false,
      sodiumFree: data.sodiumFree || false,
      noAddedSodium: data.noAddedSodium || false,
      highInMonounsaturatedFattyAcid:
        data.highInMonounsaturatedFattyAcid || false,
      highInPolyunsaturatedFattyAcid:
        data.highInPolyunsaturatedFattyAcid || false,
      highInUnsaturatedFattyAcid: data.highInUnsaturatedFattyAcid || false,
      reducedSaturatedFat: data.reducedSaturatedFat || false,
      lowSaturatedFat: data.lowSaturatedFat || false,
      saturatedFatFree: data.saturatedFatFree || false,
      lowFat: data.lowFat || false,
      fatFree: data.fatFree || false,
      lightOrLite: data.lightOrLite || false,
      sourceOfOmega3: data.sourceOfOmega3 || false,
      omega3Rich: data.omega3Rich || false,
      enrichedWithProtein: data.enrichedWithProtein || false,
      enrichedWithVitamins: data.enrichedWithVitamins || false,
      yeastFree: data.yeastFree || false,
      noAddedYeast: data.noAddedYeast || false,
      eggFree: data.eggFree || false,
      meatContentIncreased: data.meatContentIncreased || false,
      ingredients: data.ingredients
        ? Object.assign(
            {},
            ...data.ingredients.map(o => ({ [o.language]: o.value }))
          )
        : {},
      grainFree: data.grainFree || false,

      allergySealOfApproval: data.allergySealOfApproval || false,
      allergens: data.allergens?.filter((x: any) => !x.isDeleted),

      energy_kJ: data.energy_kJ,
      energy_kcal: data.energy_kcal,
      totalFat_g: data.totalFat_g,
      saturatedFat_g: data.saturatedFat_g,
      monounsaturatedFat_g: data.monounsaturatedFat_g,
      polyunsaturatedFat_g: data.polyunsaturatedFat_g,
      totalCarbohydrate_g: data.totalCarbohydrate_g,
      totalSugars_g: data.totalSugars_g,
      sugarAlcohol_g: data.sugarAlcohol_g,
      starch_g: data.starch_g,
      dietaryFiber_g: data.dietaryFiber_g,
      protein_g: data.protein_g,
      sodium_g: data.sodium_g,

      vitaminA_mcg: data.vitaminA_mcg,
      vitaminB6_mg: data.vitaminB6_mg,
      vitaminB12_mcg: data.vitaminB12_mcg,
      vitaminC_mg: data.vitaminC_mg,
      vitaminD_mcg: data.vitaminD_mcg,
      vitaminE_mg: data.vitaminE_mg,
      vitaminK_mcg: data.vitaminK_mcg,
      biotin_mcg: data.biotin_mcg,
      fluoride_mg: data.fluoride_mg,
      folicAcid_mcg: data.folicAcid_mcg,
      phosphorus_mg: data.phosphorus_mg,
      iodine_mcg: data.iodine_mcg,
      potassium_mg: data.potassium_mg,
      calcium_mg: data.calcium_mg,
      chromium_mcg: data.chromium_mcg,
      magnesium_mg: data.magnesium_mg,
      manganese_mg: data.manganese_mg,
      molybdenum_mcg: data.molybdenum_mcg,
      niacin_mg: data.niacin_mg,
      pantothenicAcid_mg: data.pantothenicAcid_mg,
      iron_mg: data.iron_mg,
      riboflavin_mg: data.riboflavin_mg,
      selenium_mcg: data.selenium_mcg,
      thiamin_mg: data.thiamin_mg,
      zinc_mg: data.zinc_mg,
      copper_mg: data.copper_mg
    };
  }

  protected _afterSaved(req) {
    this._productHeaderState.setData({
      markingAndNutritionInfo: req,
      ethicalMarkingIds: req.ethicalMarkingIds
    });
  }

  protected _saveInternal(req: any): Observable<any> {
    return this._productService.saveMarkingAndNutritionInfo(
      this.productId,
      req
    );
  }

  public bindAllergenFree() {
    this.form.get("allergens").valueChanges.subscribe(() => {
      const allergenList = (this.form.get("allergens").value as any[]) || [];
      const activeAllergenItems = allergenList.filter(
        x => !x.isDeleted && (x.allergenId || x.allergen?.id)
      );
      this.form.patchValue({ allergenFree: activeAllergenItems.length == 0 });
    });
  }
}
