import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[single-line]'
})
export class SingleLineDirective {

  @HostListener('paste', ['$event']) onPaste(e) {
    e.preventDefault();
    const text = (e.originalEvent || e).clipboardData.getData('text/plain').replace(/\n/g, '');
    document.execCommand("insertHTML", false, text);
  }

  @HostListener('keydown', ['$event']) onKeyDown(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.target.blur();
    }
  }
}
