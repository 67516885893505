import { Component, Directive, Inject, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormGroup, FormControl, FormArray } from "@angular/forms";
import { DialogComponent } from "src/app/components";
import {
  AuthenticationService,
  ICreateOfferProductRetailerAssortmentAndPriceScheme,
  IUpdateOfferProductRetailerAssortmentAndPriceScheme,
  OfferService,
  locale
} from "src/app/_services";
import { LxmAppModule, StatusRule, UserAction } from "src/app/enum";
import { LxmDialog, LxmMessage } from "src/app/_helpers";
import moment from "moment";
import { ICurrency } from "src/app/models";
import { ActivatedRouteSnapshot } from "@angular/router";
import {
  ICountry,
  ISupplier
} from "src/app/cards/product/product-info/product-info.model";
import { MarkupMethod } from "src/app/enum/markup-mehtod";
import { IVatDto } from "src/app/cards/product/product-pricelist/product-pricelist.model";
import { formatNumber } from "@angular/common";
import {
  getVats,
  nextSchemeDay,
  pastOrTodayNextSchemeDay
} from "src/app/_helpers/utilities";
import { IDisplayProduct } from "src/app/models/IDisplayProduct";

@Directive()
export class ProductAssortmentAndPricesDialogBase {
  @ViewChild("dialog", { static: true, read: DialogComponent })
  public _dialog: DialogComponent;

  public formData: IAssortmentAndPricesFormData;

  public retailerSuppliers = [];
  public offerRetailerSupplierId: string;

  public readonly = false;
  public showAssortmentStatusReminder = true;

  public tagsByParentTag = [];

  public assortmentStatusOptions = [];
  public offerProductDecisionOptions = [];

  public offer: any;
  public decision: any;

  public assortmentData: any;
  public priceSchemeData: any;

  public actionLoading = {} as any;

  public vatOptions = [];

  public markupMethod = 0;

  public formGroup: FormGroup;

  public get nextDay() {
    return nextSchemeDay();
  }

  public buyInPriceCurrencyRetailPrice: any;

  public displayProduct: IDisplayProduct;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ProductAssortmentAndPricesDialogBase>
  ) {}

  ngOnInit() {
    const { product, offer, decision } = this.data || {};
    const {
      id,
      name,
      ean,
      imageUrl,
      salePrice,
      listPrice,
      retailPrice,
      retailPriceWithoutVat,
      retailPriceMargin,
      vat,
      contentUnitId,
      drainedWeightInGrams,
      netContent
    } = product || {};

    this.decision = decision;
    this.offer = offer;

    this.displayProduct = {
      name: name,
      id: id,
      ean: ean,
      imageUrl: imageUrl
    };

    const { currency, supplier } = offer || {};

    this.buyInPriceCurrencyRetailPrice = {
      currency: currency,
      price: retailPrice,
      priceWithoutVat: retailPrice ? retailPriceWithoutVat : null
    };

    // this.formData = formData;
    this.offer = offer;
    this.decision = decision;
    this.formGroup = new FormGroup({
      // Product
      productPaymentSupplier: new FormControl(),
      payment: new FormControl(),
      supplier: new FormControl(supplier),
      vat: new FormControl(vat),
      currencies: new FormControl([currency]),
      contentUnitId: new FormControl(contentUnitId),
      drainedWeightInGrams: new FormControl(drainedWeightInGrams),
      netContent: new FormControl(netContent),

      // Assortment

      assortmentSchemeValidFrom: new FormControl(),
      status: new FormControl(),
      assortmentGroups: new FormControl([]),
      offerProductDecisionId: new FormControl(),
      parentTagId: new FormControl(),

      // Price scheme
      priceSchemeValidFrom: new FormControl(
        pastOrTodayNextSchemeDay(this.priceSchemeValidFrom)
      ),
      priceSchemeValidTo: new FormControl(),
      buyInPrice: new FormControl(salePrice),
      buyInPriceChanged: new FormControl(false),
      listPrice: new FormControl(listPrice),
      discount: new FormControl(),
      buyInPriceCurrency: new FormControl(currency),
      logisticsPartner: new FormControl(),
      retailPrices: new FormControl([this.buyInPriceCurrencyRetailPrice]),
      margin: new FormControl(),
      schemeType: new FormControl(),
      procurement: new FormControl(),
      procurementFullfillmentTime: new FormControl(),
      procurementSupplyConditions: new FormControl(),
      procurementNotes: new FormControl(),
      isCampaign: new FormControl(),
      priceGroups: new FormControl([])
    });
  }

  ngAfterViewInit(): void {
    this._dialog.dataLoaded$.subscribe(state => {
      if (!state) {
        return;
      }

      const { formData, assortmentAndPriceSchemeData } = this.data || {};
      const { assortmentFormData, priceSchemeFormData } = formData || {};
      const { vats, retailerCountry, markupMethod } = priceSchemeFormData || {};
      const { assortment, priceScheme } = assortmentAndPriceSchemeData || {};
      this.formData = formData;

      const { retailerSuppliers } = assortmentFormData || {};

      this.vatOptions = getVats(vats, retailerCountry?.id);
      this.markupMethod = markupMethod;
      this.assortmentData = assortment;
      this.priceSchemeData = priceScheme;
      this.retailerSuppliers = retailerSuppliers;
    });
  }

  public get product() {
    return this.data?.product;
  }

  public get productId() {
    return this.data?.product?.productId;
  }

  public get productName() {
    return this.product?.name;
  }

  public get isActiveAssortment() {
    return this.product?.isActiveAssortment;
  }

  public get offerProductId() {
    return this.data?.product?.id;
  }

  public get offerId() {
    return this.offer?.id;
  }

  public get decisionId() {
    return this.decision?.id;
  }

  public get offerCurrency(): ICurrency {
    return this.offer?.currency;
  }

  public get offerCurrencyId() {
    return this.offerCurrency?.id;
  }

  public get productImage() {
    const { imageUrl } = this.product || {};
    return {
      url: imageUrl
    };
  }

  public get selectedDecision() {
    const decisionId = this.formGroup.value.offerProductDecisionId;
    return this.offerProductDecisionOptions.find(x => x.id === decisionId);
  }

  public get hasAssortmentId() {
    return this.assortmentId ? true : false;
  }

  public get assortmentFormData(): IAssortmentFormData {
    return this.formData?.assortmentFormData;
  }

  public get priceSchemeFormData(): IPriceSchemeFormData {
    return this.formData?.priceSchemeFormData;
  }

  public get supplierOptions() {
    return this.priceSchemeFormData?.suppliers;
  }

  public get logisticsPartnerOptions() {
    return this.priceSchemeFormData?.logisticsPartners;
  }

  public get acceptedCurrencies() {
    return this.priceSchemeFormData?.acceptedCurrencies;
  }

  public get salesCurrencies() {
    return this.priceSchemeFormData?.salesCurrencies;
  }

  public get selectedCurrencies() {
    return this.formGroup.get("currencies").value;
  }

  public get assortmentStatuses() {
    return this.assortmentFormData?.assortmentStatuses ?? [];
  }

  public get assortmentId() {
    return this.assortmentData?.id;
  }

  public get priceSchemeId() {
    return this.priceSchemeData?.id;
  }

  public get parentTags() {
    return this.assortmentFormData?.parentTags ?? [];
  }

  public get buyInPriceCurrency() {
    return this.formGroup.get("buyInPriceCurrency").value;
  }

  public get isAssortmentStatusSelected(): boolean {
    return this.formGroup.get("status").value ? true : false;
  }

  protected get schemeType() {
    return this.priceSchemeData?.schemeType;
  }

  protected get retailerSupplierId() {
    return this.offer?.retailerSupplierId;
  }

  protected get priceSchemeValidFrom() {
    return this.offer?.from?.editableDate;
  }

  public closeDialog(res) {
    this.dialogRef.close(res);
  }

  public mapRetailPrice(x) {
    const { price, priceWithoutVat, currency } = x;
    return {
      price: price ?? 0,
      priceWithoutVat: priceWithoutVat,
      currencyId: currency?.id
    };
  }

  public mapPriceGroup(x) {
    const { priceGroupId, retailPrices } = x || {};
    return {
      priceGroupId: priceGroupId,
      retailPrices: retailPrices.map(r => this.mapRetailPrice(r))
    };
  }

  protected save() {}
}

export interface IAssortmentAndPricesFormData {
  assortmentFormData: IAssortmentFormData;
  priceSchemeFormData: IPriceSchemeFormData;
}

export interface IAssortmentFormData {
  assortmentGroupTags: any;
  assortmentGroups: any;
  assortmentGroupsByTags: any;
  assortmentStatuses: any[];
  offerProductDecisions: any[];
  offerRetailerSupplierId: string;
  parentTags: any[];
  retailerSuppliers: any[];
  tags: any;
}

export interface IPriceSchemeFormData {
  acceptedCurrencies: ICurrency[];
  canSetSupplier: boolean;
  countries: ICountry[];
  defaultLogisticsPartner: any;
  logisticsPartners: any[];
  markupMethod: MarkupMethod;
  priceGroups: any[];
  procurements: any[];
  retailerCountry: ICountry;
  retailerSupplier: ISupplier;
  salesCurrencies: ICurrency[];
  suppliers: ISupplier[];
  vats: IVatDto[];
}
