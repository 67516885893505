export enum SupplierOfferStatus {
  Created = 1,
  Sent = 2,
  Negotiating = 4,
  Confirmed = 5,
  Rejected = 6,
  Cancelled = 7,
  Expired = 8
}

(SupplierOfferStatus as any).__typeName = 'SupplierOfferStatus';
