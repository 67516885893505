<div class="user-table">

    <div class="loading-container" *ngIf="dataSource.loading$ | async">
        <div class="loading">
            {{'global.table.loading' | translate}}
        </div>
    </div>

    <mat-table [dataSource]="dataSource" matSort [@.disabled]="true">

        <!-- General fields -->

        <ng-container matColumnDef="fullName">
            <mat-header-cell *matHeaderCellDef>
                <div mat-sort-header data-table-filter-text>
                    {{'cards.lexi_market_settings.lxm_settings_user.table.user_full_name' | translate}}
                </div>
            </mat-header-cell>

            <mat-cell *matCellDef="let user">{{user.fullName}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef>
                <div data-table-filter-text>
                    {{'cards.lexi_market_settings.lxm_settings_user.table.user_email' | translate}}
                </div>
            </mat-header-cell>

            <mat-cell *matCellDef="let user">{{user.email}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="jobTitle">
            <mat-header-cell *matHeaderCellDef>
                <div mat-sort-header data-table-filter-text>
                    {{'cards.lexi_market_settings.lxm_settings_user.table.user_job_title' | translate}}
                </div>
            </mat-header-cell>

            <mat-cell *matCellDef="let user">{{user.jobTitle}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="phoneNumber">
            <mat-header-cell *matHeaderCellDef>
                <div data-table-filter-text>
                    {{'cards.lexi_market_settings.lxm_settings_user.table.user_phone_short' | translate}}
                </div>
            </mat-header-cell>

            <mat-cell *matCellDef="let user">
                <div>
                    <phone-number [code]="user?.phoneCode?.value" [number]="user?.phoneNumberShort"></phone-number>
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="roles">
            <mat-header-cell *matHeaderCellDef>
                <div data-table-filter-dropdown [options]="roles" class="flex" anyOf="roleId">
                    {{'cards.lexi_market_settings.lxm_settings_user.table.user_role' | translate}}
                </div>
            </mat-header-cell>

            <mat-cell *matCellDef="let user">
              <div *ngIf="user.roles" class="comma-delimited-list">
                <span *ngFor="let role of user.roles">
                  {{ role.roleName }}
                </span>
              </div>
            </mat-cell>
        </ng-container>

        <ng-container *ngIf="showLocations" matColumnDef="locations">
            <mat-header-cell *matHeaderCellDef>
                <div data-table-filter-dropdown [options]="locations" class="flex" anyOf="locationId">
                    {{'cards.lexi_market_settings.lxm_settings_user.table.user_locations' | translate}}
                </div>
            </mat-header-cell>

            <mat-cell *matCellDef="let user">
            <div *ngIf="user.locations" stop-propagation>
                <div>
                    {{userLocations(user)}}   
                </div>
                <!-- <div class="comma-delimited-list">
                    <span *ngFor="let location of user.locations | slice:0:userLocationsRenderCount">
                        {{ location.locationName }}
                    </span>
                </div> -->
                <span
                    class="list-overflow-count"
                    *ngIf="user.locations?.length > userLocationsRenderCount"
                    (click)="toggleAllUserLocations(user)"
                    [class.show-less]="user.showAllLocations"
                >
                {{ user.showAllLocations ? '-' : '+' }} 
                ({{user.locations.length - userLocationsRenderCount}})
              </span>
            </div>
            </mat-cell>
        </ng-container>
        
        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef>
                <div>
                    {{'cards.lexi_market_settings.lxm_settings_user.table.status' | translate}}
                </div>
            </mat-header-cell>

            <mat-cell *matCellDef="let user">
              <div>
                {{user.status | translateEnum : 'UserStatus'}}
                <div *ngIf="user.status == UserStatus.Pending">
                    <div>
                        ({{ user.lastInvitationSentAt | formDate: "L LT" }})
                    </div>
                    <div (click)="copyInviteLinkToClipboard(user); $event.stopPropagation();">
                        {{ 'cards.lexi_market_settings.lxm_settings_user_invite.action.copy_link' | translate }}
                    </div>
                </div>
              </div>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns" class="user-table-head"></mat-header-row>

        <mat-row *matRowDef="let row; columns: displayedColumns" class="user-table-row" (click)="onRowClick(row)"></mat-row>
    </mat-table>

    <data-table-footer
        [localStorageKey]="LOCAL_STORAGE_KEY"
        (paginator)="hookPaginator($event)">
    </data-table-footer>

</div>
