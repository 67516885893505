<ng-container [formGroup]="formGroup">

  <form-field dialog label="cards.settings.product_management_settings.campaign_type_edit.label.value_type">
    <lxm-select
      select
      value="id"
      for="valueType"
      [options]="campaignTypeValueTypeOptions">
    </lxm-select>
    <validation-errors for="valueType"></validation-errors>
  </form-field>

  <form-field dialog label="cards.settings.clv.common.table.numeric_value" for="numericValue" type="input-number">
    <validation-errors for="numericValue"></validation-errors>
  </form-field>

  <form-field dialog label="cards.settings.clv.common.table.vat_type">
    <lxm-select
      select
      value="id"
      for="vatType"
      [options]="campaignTypeVatTypeOptions">
    </lxm-select>
    <validation-errors for="vatType"></validation-errors>
  </form-field>

  <form-field dialog label="cards.settings.product_management_settings.campaign_type_edit.label.display_retail_price">
    <slide-toggle toggle [formGroup]="formGroup" for="displayRetailPrice"></slide-toggle>
  </form-field>

</ng-container>