<ng-container>
  <offer-card *ngIf="offerKind === OfferKind.SupplierOffer" [isEditable]="isEditable" (editing)="editing = $event" (onFormChange)="onFormChange($event)"></offer-card>
  <receiving-offer-card *ngIf="offerKind === OfferKind.ReceivingOffer" [isEditable]="isEditable"></receiving-offer-card>
  <request-offer-card *ngIf="offerKind === OfferKind.OfferRequest" [isEditable]="isEditable"></request-offer-card>
  <offer-files-card #files class="mt20" [isEditable]="isEditable && !appState.isVisitor()" [offerKind]="offerKind" [offerType]="offerType" [retailerId]="retailerId"></offer-files-card>
  <ng-container *ngIf="offerId"> 
    <ng-container *ngIf="offerKind !== OfferKind.OfferRequest">
      <offer-comments-card class="mt20" [isEditable]="isEditable"></offer-comments-card>
    </ng-container>
    <offer-history-card class="mt20"></offer-history-card>
  </ng-container>
</ng-container>
