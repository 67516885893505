import { Component, Input, Optional, Host, OnInit } from '@angular/core';
import { FormGroup, ControlContainer } from '@angular/forms';

@Component({
  selector: "lxm-rangepicker",
  templateUrl: './rangepicker.component.html',
  styleUrls: ['./rangepicker.component.scss']
})
export class LxmRangepickerComponent implements OnInit {

  @Input() public formGroup: FormGroup;
  @Input() public from: string;
  @Input() public to: string;
  @Input() public readonly = false;
  @Input() public width = '100%';
  @Input() public min: any;
  @Input() public hideInput = false;

  constructor(@Optional() @Host() private _controlContainer: ControlContainer) {}

  public ngOnInit(): void {
    if (this._controlContainer && this._controlContainer.control instanceof FormGroup) {
      this.formGroup = <FormGroup>this._controlContainer.control;
    }
  }

}
