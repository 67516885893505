import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { ODataResponse } from "./data-table.service";
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';
import { NotificationType } from "../enum";
import { PAGE_SIZE } from 'src/app/config/pagination';
import { LOCAL_STORAGE_KEY } from "../config";

@Injectable({ providedIn: "root" })
export class NotificationsService {
    public constructor(private _http: HttpClient) {}

    public getNotificationsList(isNew = false, top: number = PAGE_SIZE[LOCAL_STORAGE_KEY.PAGE_SIZE_NOTIFICATIONS_LIST]) {
        const params = {
            $top: `${top}`,
            $count: 'true'
        }

        if (isNew) {
            const filter = `isNew eq ${isNew}`;
            Object.assign(params, { $filter: filter });
        }

        return this._http.post<any>(`api/notifications`, {}, { params: params });
    }

    public getNotificationUrl(url: string): Observable<any> {
        if (!url) return;
        return this._http.get(url.substr(1)) as Observable<any>;
    }

    public deleteNotifications(items: any[]): Observable<any> {
        const options = {
            body: {
                items: items.map(x => {
                    return { 
                        id: x.id,
                        type: x.type,
                        itemId: x.itemId
                    }
                })
            }
        };
        return this._http.request('DELETE', 'api/notifications', options);
    }

    public markAsRead(items: any[]): Observable<any> {
        const data = {
            items: items.map(x => {
                if (x.isNew) {
                    x.isNew = false;
                }
                return {
                    id: x.id,
                    type: x.type,
                    itemId: x.itemId
                }
            })
        }
        return this._http.post('api/notifications/markAsRead', data);
    }
}

export interface INotificationListItem {
    createdAt : Date;
    fromTenant: IFromTenant;
    fromTenantId: string;
    fromUser: IFromUser;
    fromUserId: string;
    id: string;
    isNew: boolean;
    itemId: string;
    params: any;
    previewUrl: string;
    type: NotificationType;
    isLoading?: boolean;
}

interface IFromTenant {
    id: string;
    name: string;
    shortName: string;
}

interface IFromUser {
    email: string;
    id: string;
    name: string;
}