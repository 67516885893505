import { formatNumber } from "@angular/common";
import { Component, Injector, Input, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import moment from "moment";
import { forkJoin, merge, Observable } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { appSettings } from "src/app/app.settings";
import { LOCAL_STORAGE_KEY } from "src/app/config";
import {
  asArray,
  asObject,
  ProductCardSection,
  ProductPriceSchemeType,
  UserAction,
} from "src/app/enum";
import { MarkupMethod } from "src/app/enum/markup-mehtod";
import { IDateInfo } from "src/app/models";
import { AppState } from "src/app/state/app.state";
import { numberUtil } from "src/app/util/number-util";
import { LxmDialog } from "src/app/_helpers";
import {
  calculateDiscount,
  calculateDiscountNumber,
  calculateMargin,
  calculateMarkup,
  calculateRetailPriceWithoutVatByBuyInPriceAndMargin,
  nextSchemeDay,
} from "src/app/_helpers/calculate";
import { locale, ProductPricelistService } from "src/app/_services";
import { ProductCardBase } from "../product-card-base";
import {
  IClvDto,
  ICountry,
  ICountryTranslated,
  IPriceGroup,
  IProductCardFormData,
} from "../product-info/product-info.model";
import {
  IDialogDataBase,
  ProductPricelistDialogBase,
} from "./product-pricelist-dialog-base";
import {
  IPricesInfo,
  IRetailerSupplierPriceScheme,
  IVatDto,
} from "./product-pricelist.model";
import { formUtil } from "src/app/util/form-util";
import { ProductPricelistSupplierSchemeDialog } from "./dialog/supplier-scheme/product-supplier-scheme.dialog";

@Component({
  selector: "product-supplier-prices-card",
  templateUrl: "./product-supplier-prices.card.html",
  styleUrls: ["./product-supplier-prices.card.scss"],
})
export class ProductSupplierPricesCardComponent extends ProductCardBase<IPricesInfo> {
  private _defaultColumns = [
    "user",
    "valid",
    "scheme_type",
    "supplier",
    "buyInPrice",
    "margin",
    // 'listPrice',
    // 'discount',
    "retailPriceWithoutVat",
    "retailPrice",
    "vat",
    "info",
  ];

  private _priceGroupedColumns = [
    "user",
    "valid",
    "scheme_type",
    "supplier",
    "buyInPrice",
    "margin",
    // 'listPrice',
    // 'discount',
    "retailPriceWithoutVat",
    "retailPrice",
    "price_group",
    "vat",
    "info",
  ];

  public UserAction = UserAction;
  public cardSection = ProductCardSection.PriceSchemes;

  @Input()
  public productId: string;

  @Input()
  public title: string;

  public pricesInfo: IPricesInfo;
  public formData: IProductCardFormData;

  public expanded = true;

  public cardValid = true;
  public canEdit = true;
  public isOwnProduct: boolean;
  public markupMethod: MarkupMethod;

  public isExpandedKey = LOCAL_STORAGE_KEY.PRODUCT_SUPPLIER_PRICES_CARD_OPEN;

  constructor(
    injector: Injector,
    public appState: AppState,
    private _dialog: LxmDialog,
    private _route: ActivatedRoute,
    private _productPricelistService: ProductPricelistService,
  ) {
    super(injector, "productSupplierPrices", "productSupplierPricesChanged");
    this.pricesInfo = this._route.snapshot.data.productCard
      .pricesInfo as IPricesInfo;
    this.formData = this._route.snapshot.data.productCard
      .formData as IProductCardFormData;
    this.isOwnProduct = this._route.snapshot.data.productCard.isOwnProduct;

    this.canEdit =
      this.isOwnProduct && appState.hasRight([UserAction.ManagePrices]);
    this.markupMethod = this.formData.markupMethod;
  }

  public get columns(): string[] {
    return this.hasAnyPriceGroups
      ? this._priceGroupedColumns
      : this._defaultColumns;
  }

  public get hasAnyPriceGroups(): boolean {
    return this.pricesInfo?.retailerSupplierPrices?.find(
      (x) => x.priceGroups?.length > 0,
    )
      ? true
      : false;
  }

  public calculateDiscount(row: any) {
    return calculateDiscount(row.listPrice, row.buyInPrice);
  }

  public calculateMargin(row: any) {
    const vat = row.vat?.numericValue / 100 || 0;
    var currencyId = row.buyInPriceCurrency?.id;
    const retailPrice = (row.retailPrices || []).find(
      (x) => x.currency.id == currencyId,
    )?.price;
    const buyInPrice = row.buyInPrice;
    if (this.markupMethod == MarkupMethod.Coverage) {
      return calculateMarkup(
        retailPrice,
        buyInPrice,
        row.vat?.numericValue,
        this.markupMethod,
      );
    }
    return (retailPrice / (1 + vat) - buyInPrice) / buyInPrice;
  }

  public openDialog(row?: any) {
    if ((row && !row.isEditable) || !this.canEdit) {
      return;
    }

    this._dialog.open(ProductPricelistSupplierSchemeDialog, {
      width: "1000px",
      height: "auto",
      data: {
        pricelistCard: this,
        scheme: row ? { ...row } : null,
        editable: this.canEdit,
      },
      resolve: () => {
        const resolvers: any = {
          formData:
            this._productPricelistService.getRetailerSupplierPriceSchemeFormData(
              this.productId,
              row?.id,
            ),
        };
        return forkJoin(resolvers);
      },
    });
  }

  public reload() {
    this._productPricelistService
      .getRetailerSupplierPrices(this.productId)
      .subscribe(
        (result) => {
          this.pricesInfo.retailerSupplierPrices = result;
        },
        (err) => {
          // todo
        },
      );
  }
}

export interface IRetailDialogData
  extends IDialogDataBase<
    IRetailerSupplierPriceAndAssortmentSchemeFormData,
    IRetailerSupplierPriceScheme
  > {
  supplier: IRetailerSupplier;
  editable: boolean;
  vatId: string;
  listPrice: number;
  buyInPrice: number;
  retailPrice: number;
  retailPriceWithoutVat: number;
  currency: IClvDto;
  validFrom: Date;
  validTo: Date;
  schemeType: ProductPriceSchemeType;
}

export interface IRetailerSupplier {
  id: string;
  name: string;
  regNo: string;
  country: ICountry;
}

export interface IRetailerSupplierPriceSchemeFormData {
  campaignTypes: any[];
  countries?: ICountryTranslated[];
  retailerSupplier: IRetailerSupplier;
  retailerCountry: ICountry;
  canSetSupplier: boolean;
  vats: IVatDto[];
  defaultLogisticsPartner: IClvDto;
  acceptedCurrencies: IClvDto[];
  salesCurrencies: IClvDto[];
  suppliers: IClvDto[];
  logisticsPartners: IClvDto[];
  procurements: [];
  markupMethod: MarkupMethod;
  priceGroups: IPriceGroup[];
}

export interface IRetailerSupplierPriceAndAssortmentSchemeFormData {
  priceSchemeFormData: IRetailerSupplierPriceSchemeFormData;
  assortmentFormData: any;
}
