export enum ProductUnitCodeField {
  IsPrimary = 1,
  Type = 2,
  Format = 3,
  Value = 4,
  Unit = 5,
}

(ProductUnitCodeField as any).__typeName = 'ProductUnitCodeField';

(ProductUnitCodeField as any).__altTranslationKeys = {
  [ProductUnitCodeField.IsPrimary]: 'cards.products.product_base_info.codes.label.is_primary',
  [ProductUnitCodeField.Type]: 'cards.products.product_base_info.codes.label.type',
  [ProductUnitCodeField.Format]: 'cards.products.product_base_info.codes.label.format',
  [ProductUnitCodeField.Value]: 'cards.products.product_base_info.codes.label.value',
  [ProductUnitCodeField.Unit]: 'cards.products.product_base_info.codes.label.unit'
};
