<div class="view-icon-container background-{{background}}"
  [class.clickable]="isClickable"
  [popper]="content" 
  [popperTrigger]="trigger" 
  popperAppendTo="app-root"
  [class.disabled--03]="disabled"
  [popperApplyClass]="popperApplyClass"
  (click)="$event.stopPropagation()">
  <div class="data-icon {{icon}}-icon">
    <popper-content #content>
      <ng-content></ng-content>
    </popper-content>
  </div>
</div>
