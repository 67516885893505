<div
  class="tt-wrapper"
  [popper]="tooltipContent"
  [popperTrigger]="tooltipTrigger"
  [popperPlacement]="tooltipPosition"
  [popperPositionFixed]="true"
  [popperHideOnMouseLeave]="false"
  [popperHideOnClickOutside]="true"
  [popperShowOnStart]="false"
  [popperAppendTo]="tooltipAppendTo"
  [popperTimeout]="tooltipTimeout"
  [popperApplyClass]="tooltipApplyClass"
  [popperDelay]="tooltipDelay"
  [popperHideOnScroll]="true"
  [popperBoundaries]="tooltipBoundaries"
>
  <ng-content></ng-content>
  <popper-content #tooltipContent>
    <ng-container *ngIf="isOpen">
      <ng-container *ngTemplateOutlet="tooltip"> </ng-container>
    </ng-container>
  </popper-content>
</div>
