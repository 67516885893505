import { Component, Injector } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProductCardBase } from '../product-card-base';
import { IProductCardSeafoodInfo, IClvDto } from '../product-info/product-info.model';
import { Observable } from 'rxjs';
import { formUtil, keep } from 'src/app/util/form-util';
import { UserAction, ProductCardSection } from 'src/app/enum';
import { ProductValidity } from 'src/app/_services';
import { LOCAL_STORAGE_KEY } from 'src/app/config';

@Component({
  selector: 'product-seafood-card',
  templateUrl: './product-seafood.card.html',
  styleUrls: ['./product-seafood.card.scss']
})
export class ProductSeafoodCard extends ProductCardBase<IProductCardSeafoodInfo> {

  public UserAction = UserAction;

  public get title() { return 'cards.products.product_fish.title'; }

  public cardSection = ProductCardSection.SeafoodInfo;

  public stateOptions: IClvDto[];
  public fishTrimOptions: IClvDto[];

  public isExpandedKey = LOCAL_STORAGE_KEY.PRODUCT_SEAFOOD_CARD_OPEN;

  constructor(injector: Injector, public productValidity: ProductValidity) {
    super(injector, 'seafoodInfo', 'productSeafoodInfoChanged');
    this.stateOptions = this._productCard.formData.statesOfFish;
    this.fishTrimOptions = this._productCard.formData.fishTrims;
  }

  protected get successMessage(): string {
    return 'cards.products.product_fish.save_successful';
  }

  protected _getNormalizedFormData(data: IProductCardSeafoodInfo) {
    return formUtil.transformData(data,  {
      latinName: keep,
      fishingArea: keep,
      stateId: () => data.state?.id,
      fishTrimId: () => data.fishTrim?.id
    });
  }

  protected _createFormGroup(data: IProductCardSeafoodInfo): FormGroup {
    return formUtil.createFormGroup(this._getNormalizedFormData(data));
  }

  protected _createSaveRequest() {
    const f = this.form.value;
    return {
      latinName: f.latinName,
      fishingArea: f.fishingArea,
      stateId: f.stateId,
      fishTrimId: f.fishTrimId
    };
  }

  protected _saveInternal(req: any): Observable<any> {
    return this._productService.saveSeafoodInfo(this.productId, req);
  }
}
