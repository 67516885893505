import { Component, ViewContainerRef, AfterContentInit, Input, TemplateRef, ViewChild } from "@angular/core";
import { ILxmTreeNode } from 'src/app/_services/lxm-tree.service';

@Component({
    selector: "lxm-tree-cell",
    template: "<ng-template #value></ng-template>"
})
export class LxmTreeCellComponent implements AfterContentInit {
    @Input()
    public templateRef: TemplateRef<any>;

    @Input()
    public node: ILxmTreeNode;

    @ViewChild("value", { static: true, read: ViewContainerRef })
    private _valueVc: ViewContainerRef;

    public ngAfterContentInit(): void {
        let context = {
            $implicit: this.node,
            node: this.node
        };
        this._valueVc.createEmbeddedView(this.templateRef, context);
    }
}