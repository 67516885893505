import { Component, EventEmitter, Input, Output } from "@angular/core";
import { addZoom, decZoom } from "src/app/directives";

@Component({
  selector: "zoom-controls",
  templateUrl: "./zoom-controls.component.html",
  styleUrls: ['./zoom-controls.component.scss']
})
export class ZoomControlsComponent {

  // Use [(value)] for two-way binding

  @Input() public value: number = 1;
  @Output() valueChange = new EventEmitter<number>();

  public addZoom() {
    this.value = addZoom(this.value);
    this.valueChange.emit(this.value);
  }

  public decZoom() {
    this.value = decZoom(this.value);
    this.valueChange.emit(this.value);
  }

  public formatValue(value) {
    if (!value) return 100;
    return Math.round(value * 100);
  }

}
