import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DataTableService } from "src/app/_services";
import { DataTableComponent } from "../../../data-table/data-table.component";

@Component({ template: '' })
export class ClvTableComponent
  extends DataTableComponent<IClvItem, IClvDataModel> {

  public displayedColumns: string[];
  public translationPrefix: string;

  private _classificatorId: string;
  private _baseUrl: string;

  public get baseUrl(): string {
    return this._baseUrl;
  }

  public get classificatorId(): string {
    return this._classificatorId;
  }

  constructor(
    dataService: DataTableService<any, any>,
    cd: ChangeDetectorRef
  ) {
    super(dataService, cd);
    this.loadOnInit = false;
  }

  @Input()
  public set classificatorId(val: string) {
    this._classificatorId = val;
    this.clearSorts();
    this.clearFilters();
    this.load();
  }

  public getData(): IClvDataModel {
    return {
      classificatorId: this._classificatorId
    };
  }

  public setData(data: IClvTabData) {
    if (!data) {
      return;
    }

    this._baseUrl = data.controller;
    this.dataUrl = this._baseUrl + "/griddata";
    this.displayedColumns = data.displayedColumns;
    this.translationPrefix = data.translationPrefix;

    this.classificatorId = data.classificatorId;
  }
}

export interface IClvDataModel {
  classificatorId: string;
}

export interface IClvItem {
  id: string;
  code: string;
  value: string;
  description: string;
  status: string;
}

export interface IClvTabData {
  classificatorId: string;
  controller: string;
  displayedColumns: string[];
  translationPrefix: string;
}
