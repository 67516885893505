import { Component, OnInit, Input, Optional, Host, OnChanges, SimpleChanges, EventEmitter, Output, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, ControlContainer, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { merge } from 'rxjs';
import { IClvDto } from 'src/app/cards/product/product-info/product-info.model';
import { ReadonlyDirective } from 'src/app/directives';
import { AppState } from 'src/app/state/app.state';
import { numberUtil } from 'src/app/util/number-util';

@Component({
  selector: 'currency-price',
  templateUrl: './currency-price.component.html',
  styleUrls: ['./currency-price.scss']
})
export class CurrencyPriceComponent implements OnInit, OnChanges {

  private _shouldUpdateRowsOptions = false;
  private _arr: FormArray;

  @Input() public formGroup: FormGroup;
  @Input() public for: string;
  @Input() public readonly = false;
  @Input() public currencyOptions: IClvDto[];
  @Input() public defaultCurrency: IClvDto;
  @Input() public pricesWithoutVat = false;
  @Input() public vat = undefined;
  @Input() public digits: number = 2;

  @Output() blur: EventEmitter<any> = new EventEmitter();

  public fgInternal = new FormGroup({});

  public get formArray() {
    return this._arr;
  }

  constructor(
    appState: AppState,
    private _cd: ChangeDetectorRef,
    @Optional() private _controlContainer: ControlContainer,
    @Optional() @Host() private _readonlyQuery: ReadonlyDirective

  ) { 
    this.defaultCurrency = appState.defaultCurrency;
  }

  public isCurrencyReadonly(field, index): boolean {
    return this.readonly || index == 0 || field.value.deleted;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['currencyOptions'] && !changes['currencyOptions'].firstChange) {
        this._updateRowsOptions();
    }

    if (changes['defaultCurrency'] && !changes['defaultCurrency'].firstChange) {

      let existingrow = this._arr.controls.find(x => x.value.currency?.id == this.defaultCurrency.id);
      if (existingrow) {
        const i = this._arr.controls.indexOf(existingrow);
        this._arr.controls.splice(i, 1);
        this._arr.controls.splice(0, 0, existingrow);
      }
      else {

        if (this._arr.length == 0) {
          this._arr.push(this._createRow({ currency: this.defaultCurrency }))
        }
        else {
          this._arr.controls[0].get("currency").setValue(this.defaultCurrency);
        }
      }
      this._updateRowsOptions();
    }
  }

  public ngOnInit(): void {
    if (this._controlContainer && this._controlContainer.control instanceof FormGroup) {
      this.formGroup = <FormGroup>this._controlContainer.control;
    }
    
    if (this._readonlyQuery && this._readonlyQuery.readonly) {
      this.readonly = this._readonlyQuery.readonly;
    }

    const ref = this.formGroup.get(this.for);
    const value = this._initValue(ref.value);
    this._arr = new FormArray(
      value.map(x => this._createRow(x))
    );

    this.fgInternal.addControl(this.for, this._arr);
    this._bindControls(ref, this._arr);
  }

  private _initValue(value) {

    var res = value || [];

    if (this.defaultCurrency) {
      if (!res.find(x => x.currency.id == this.defaultCurrency.id)) {
        res.push({ currency: this.defaultCurrency });
      }
    }

    return res;
  }

  private _createRow(x) {

    const { currency, price, priceWithoutVat, isNew } = x || {};

    const formGroup = new FormGroup({
      currency: new FormControl(currency),
      price: new FormControl(price),
      priceWithoutVat: new FormControl(priceWithoutVat),
      isNew: new FormControl(isNew || x === null),
      deleted: new FormControl(false),
      currencyOptions: new FormControl([])
    });

    if (this.pricesWithoutVat && this.vat !== undefined) {

      formGroup.get('price').valueChanges.subscribe(x => {
        formGroup.get('priceWithoutVat').patchValue(numberUtil.excludeVat(x, this.vat), { emitEvent: false});
      })
      formGroup.get('priceWithoutVat').valueChanges.subscribe(x => {
        formGroup.get('price').patchValue(numberUtil.applyVat(x, this.vat), { emitEvent: false});
      })
    }

    formGroup.valueChanges.subscribe(x => {
      this._shouldUpdateRowsOptions = true;
    })

    return formGroup;
  }

  private _updateRowsOptions() {
    const selectedCurrencies = this._arr?.value.map(row => row.currency?.id);
    for(var i = 0; i < this._arr?.length; i++) {
      let row = this._arr.controls[i];
      this._updateRowOptions(row, selectedCurrencies);
    }
  }

  private _updateRowOptions(row, selectedCurrencies = null) {
    selectedCurrencies = selectedCurrencies || this._arr.value.map(row => row.currency?.id);
    const rowCurrency = row.get("currency").value?.id;
    const rowOptions = this.currencyOptions?.filter(x => x.id == rowCurrency || selectedCurrencies.indexOf(x.id) < 0);
    row.get("currencyOptions").setValue(rowOptions, { emitEvent: false });
  }

  public addRow() {
    let row = this._createRow(null);
    this._arr.insert(this._arr.length, row);
    this._updateRowOptions(row);
  }

  public removeRow(index) {
    let row = this._arr.at(index);
    if (row.value.isNew) {
      this._arr.removeAt(index);
    }
    else {
      row.get('deleted').setValue(!row.value.deleted);
    }
  }

  private _bindControls(ref: AbstractControl, arr: FormArray) {

    let innerChange = false;

    ref.valueChanges.subscribe(value => {
      if (innerChange) {
        innerChange = false;
        return;
      }

      arr.clear({ emitEvent: false });

      value = this._initValue(value);

      for (var row of value) {
        arr.insert(arr.length, this._createRow(row), { emitEvent: false });
      }
      
    });

    arr.valueChanges.subscribe(rows => {
      innerChange = true;

      if (this._shouldUpdateRowsOptions) {
        this._shouldUpdateRowsOptions = false;
        this._updateRowsOptions();
      }

      if (rows) {
        ref.setValue(rows
          .filter(x => !x.deleted)
          .map(x => ({ currency: x.currency, price: x.price, priceWithoutVat: x.priceWithoutVat, isNew: x.isNew })));
      }
      else {

        ref.setValue(rows);
      }
    });

  }

  ngAfterContentChecked() : void {
    this._cd.detectChanges();
}

}
