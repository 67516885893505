import {
  AbstractControl,
  FormArray,
  FormGroup,
  ControlContainer,
} from "@angular/forms";
import {
  formUtil,
  IReactiveFormsDeepMappingOptions,
} from "src/app/util/form-util";
import { MatTableDataSource } from "@angular/material/table";
import {
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Directive,
} from "@angular/core";
import { Subscription } from "rxjs";

@Directive()
export abstract class EditableListBase implements OnInit, OnChanges {
  protected _arr: FormArray;
  private _init = false;

  public dataSource = new MatTableDataSource<AbstractControl>();
  public fgInternal = new FormGroup({});

  @Input() public formGroup: FormGroup;
  @Input() public for: string;
  @Input() public readonly = false;
  @Input() public manualInit = false;

  @Input() public errorsFormGroup: FormGroup;
  @Input() public errorsStartIndex = 0;

  @Input() public selectionKey = "productId";

  public selection: any[] = [];
  public currentPageSelected: string[] = [];

  private _activeRowsCount = 0;

  @Input()
  public reactiveFormsMappingOptions: IReactiveFormsDeepMappingOptions = {
    deepMapping: false,
    skipKeys: null,
  };

  protected abstract get _defaultItem(): any;
  public abstract get columns(): string[];

  protected constructor(private _controlContainer: ControlContainer) {}

  public ngOnInit(): void {
    if (
      this._controlContainer &&
      this._controlContainer.control instanceof FormGroup
    ) {
      this.formGroup = <FormGroup>this._controlContainer.control;
    }

    if (!this.manualInit) {
      this.initEditableList();
    }

    if (!this.errorsFormGroup) {
      this.errorsFormGroup = this.formGroup;
    }
  }

  //

  // ** SELECT **

  public isAllSelected() {
    const currentPageSelectedCount = this.currentPageSelected.length;
    const selectableItemsCount = this.dataSource.data.filter((x: FormGroup) =>
      this.canSelectItem(x),
    )?.length;
    return currentPageSelectedCount === selectableItemsCount;
  }

  public masterToggle() {
    if (this.isAllSelected()) {
      this.dataSource.data?.forEach((rowFg: FormGroup) => {
        const index = this._getSelectionProductIndex(rowFg);
        if (index > -1) {
          this.selection.splice(index, 1);
        }
      });
    } else {
      this.dataSource.data.forEach((rowFg: FormGroup) => {
        if (this.canSelectItem(rowFg) && !this.isItemSelected(rowFg)) {
          this.selection.push(rowFg);
        }
      });
    }
    this._updateCurrentPageSelection();
  }

  public toggleSelection(rowFg: FormGroup): void {
    if (!this.canSelectItem(rowFg)) {
      return;
    }
    const index = this._getSelectionProductIndex(rowFg);
    if (index < 0) {
      this.selection.push(rowFg);
      this.currentPageSelected.push(rowFg.value?.[this.selectionKey]);
    } else {
      this.selection.splice(index, 1);
      const i = this._getCurrentPageProductIndex(rowFg);
      if (i > -1) {
        this.currentPageSelected.splice(i, 1);
      }
    }
  }

  public emptySelectedProducts() {
    this.selection = [];
    this.currentPageSelected = [];
  }

  @Input() public canSelectItem(row: FormGroup): boolean {
    return true;
  }

  protected _getSelectionProductIndex(row: FormGroup): number {
    return this.selection.findIndex(
      (x) => x.value?.[this.selectionKey] === row.value?.[this.selectionKey],
    );
  }

  protected _getCurrentPageProductIndex(row: FormGroup): number {
    return this.currentPageSelected.findIndex(
      (x) => x === row.value?.[this.selectionKey],
    );
  }

  protected _updateCurrentPageSelection(data = this.dataSource.data) {
    this.currentPageSelected = [];
    data?.forEach((rowFg: FormGroup) => {
      this.isItemSelected(rowFg) && this.canSelectItem(rowFg)
        ? this.currentPageSelected.push(rowFg.value?.[this.selectionKey])
        : null;
    });
    this._activeRowsCount = data ? data.length : 0;
  }

  public isItemSelected(row: FormGroup): boolean {
    return this.selection.find(
      (x) => x.value?.[this.selectionKey] === row.value?.[this.selectionKey],
    )
      ? true
      : false;
  }

  //

  public initEditableList() {
    const ref = this.formGroup.get(this.for);
    this._arr = this._createFormArray(ref?.value || []);
    ref?.setValue(this._arr.value, { emitEvent: false });
    this.fgInternal.addControl(this.for, this._arr);
    this.dataSource.data = this._arr.controls;
    this._bindControls(ref, this._arr);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (!this._init) {
      this._init = true;
      return;
    }

    if (changes?.readonly) {
      this._readonlyChange();
    }
  }

  protected _createFormArray(
    array: any[],
    mappingOptions = this.reactiveFormsMappingOptions,
  ): FormArray {
    return new FormArray(
      array.map((item) => {
        return this._createRowObject(item, mappingOptions);
      }),
    );
  }

  protected _createRowObject(
    item: any,
    mappingOptions = this.reactiveFormsMappingOptions,
  ): FormGroup {
    return formUtil.createFormGroup(
      this._getNormalizedItem(item),
      null,
      mappingOptions,
    );
  }

  private _refValueChangesSubscription: Subscription;
  private _arrValueChangesSubscription: Subscription;

  protected _bindControls(ref: AbstractControl, arr: FormArray) {
    let innerChange = false;

    if (ref) {
      this._refValueChangesSubscription = ref.valueChanges.subscribe((x) => {
        if (innerChange) {
          innerChange = false;
          return;
        }

        const normalizedArray = this._getNormalizedItems(x);
        while (arr.controls.length < normalizedArray.length) {
          this.addItem();
        }
        while (arr.controls.length > normalizedArray.length) {
          arr.removeAt(normalizedArray.length);
        }

        arr.setValue(normalizedArray);
        this._updateTable();
      });
    }

    if (arr) {
      this._arrValueChangesSubscription = arr.valueChanges.subscribe((x) => {
        innerChange = true;
        ref.setValue(x);
      });
    }
  }

  public get hasData() {
    return this.dataSource?.data?.length ? true : false;
  }

  protected _getNormalizedItems(items: any[]) {
    return items?.map((x) => this._getNormalizedItem(x)) || [];
  }

  protected abstract _getNormalizedItem(item: any);

  public addItem(item: any = this._defaultItem) {
    this._arr.push(this._createRowObject(item));
    this._updateTable();
  }

  public removeItem(index: number) {
    const item = this._arr.at(index);
    const isNew = item.get("isNew").value;
    const isDeleted = item.get("isDeleted").value;

    if (isNew) {
      this._arr.removeAt(index);
    } else {
      item.get("isDeleted").patchValue(!isDeleted);
    }

    this._updateTable();
  }

  protected _updateTable() {
    this.dataSource.data = this._arr.controls;
  }

  protected _readonlyChange() {
    this._removeDeleted();
    this._undoNew();
    this._updateTable();
  }

  protected _undoNew() {
    const map = this._arr.value;
    for (let i = map.length; i-- > 0; ) {
      this._arr.controls[i].get("isNew")?.setValue(false);
    }
  }

  protected _removeDeleted(): void {
    const map = this._arr.value;
    for (let i = map.length; i-- > 0; ) {
      if (map[i].isDeleted) {
        this._arr.removeAt(i);
      }
    }
  }

  ngOnDestroy() {
    if (this._refValueChangesSubscription) {
      this._refValueChangesSubscription.unsubscribe();
    }
    if (this._arrValueChangesSubscription) {
      this._arrValueChangesSubscription.unsubscribe();
    }
  }
}
