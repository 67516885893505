import { Pipe, PipeTransform, ChangeDetectorRef, Injector } from '@angular/core';
import moment from 'moment-timezone';
import { AuthenticationService } from '../_services';
import { IDateInfo } from '../models';

@Pipe({
  name: 'formDate'
})
export class FormDatePipe implements PipeTransform {

  private _timeZone: string;

  constructor(
    private _authService: AuthenticationService,
    injector: Injector
  ) {

    const cdRef = injector.get(ChangeDetectorRef);
    this._authService.tenantTimeZone.subscribe(value => {
      this._timeZone = value;
      cdRef.markForCheck();
    });
  }

  public transform(value: any, ...args: any[]): string {
    const date = this.getActualValue(value);

    if (!date) {
      return '...';
    }

    let format = args[0] || 'L';
    let offset = parseInt(args[1]);

    const convertedMoment = moment(date).tz(this._timeZone);
    if (isNaN(offset) && convertedMoment) {
      offset = convertedMoment.utcOffset();
    }

    if (offset !== date.utcOffset()) {
      format += ' Z';
    }

    return moment(date).format(format);
  }

  private getActualValue(value: any): moment.Moment {
    if (!value) {
      return null;
    }

    if (moment.isMoment(value)) {
      // MAT_MOMENT_DATE_ADAPTER_OPTIONS use { useUtc: true }
      // but when we display date, we consider current locale offset
      // therefore we must convert utc date to local date
      return moment.parseZone(value.toDate());
    }

    if (value instanceof Date || typeof value === 'string') {
      return moment.parseZone(value);
    }

    return moment.parseZone((<IDateInfo>value).displayDate);
  }
}
