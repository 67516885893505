<div *ngIf="!displayHeadersWhenEmpty && dataSource.data?.length === 0">
  <no-content image="no-files" text="global.no_links_added" [inline]="true"></no-content>
</div>

<div *ngIf="dataSource.data?.length > 0 || displayHeadersWhenEmpty">

  <mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="type_column">
      <mat-header-cell *matHeaderCellDef>
        {{'cards.products.product_additional_info_and_instructions.links.type' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{getTypeValue(row.type) | translatedValue}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="link_column">
      <mat-header-cell *matHeaderCellDef>
        {{'cards.products.product_additional_info_and_instructions.links.link' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <a class="link" href="{{row.link}}" target="_new">{{row.link}}</a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="comment_column">
      <mat-header-cell *matHeaderCellDef>
        {{'cards.products.product_additional_info_and_instructions.links.comment' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{row.comment}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="flex flex-row justify-end">
          <lxm-button type="transparent" icon="edit" title="action.edit" (click)="openAddLinkDialog(row)" *ngIf="!readonly"></lxm-button>
          <lxm-button type="transparent" icon="trash" title="action.remove" (click)="removeLink(row)" *ngIf="!readonly"></lxm-button>
        </span>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columns;" [ngClass]="{'row-to-delete readonly' : row.isDeleted}"></mat-row>
  </mat-table>
</div>
