<ng-container [ngSwitch]="isGroupedByTags">
  <ng-container *ngSwitchCase="true">
      <ng-container *ngFor="let tag of groupByTags; trackBy: trackById">
        <div class="flex flex-col mb5"  *ngIf="readonly ? tagHasAnySelected(tag.id) : true">
          <div class="group-header justify-between" (click)="collapseGroup(tag.id)">
            <div class="flex flex-row">
              <toggle-arrow [toggled]="!isGroupCollapsed(tag.id)" class="mr12"></toggle-arrow> <strong>{{tag.name}}</strong>
            </div>
            <div>
              {{childSelectedCount[tag.id]}}
            </div>
          </div>
          <expander [trigger]="!isGroupCollapsed(tag.id)" [formGroup]="formGroup">
            <assortment-group-selector 
              for="assortmentGroups" 
              [inner]="true"
              [assortmentGroups]="assortmentGroups"
              [assortmentGroupTags]="assortmentGroupTags"
              [assortmentGroupsByTags]="assortmentGroupsByTags"
              [data]="getAssortmentGroupsByTagId(tag.id)"
              (childSelectedCountChange)="updateChildSelectedCount(tag.id, $event)"
              [readonly]="readonly">
            </assortment-group-selector>
          </expander>
        </div>
      </ng-container>
    <!-- </ng-container> -->
  </ng-container>

  <ng-container [ngSwitch]="readonly" *ngSwitchDefault>
    <ng-container *ngSwitchDefault [ngSwitch]="hasAssortmentGroups">
      <ng-container *ngSwitchCase="false">
        <ng-container *ngTemplateOutlet="noGroupsTemplate">
        </ng-container>
      </ng-container>
      <div *ngSwitchCase="true" class="w-full mt5" style="user-select: none" [ngSwitch]="isGroupedByTags">
        <div class="user-action-row user-action-header" [class.disabled]="!readonly && disabled"
          [class.all-selected]="isAllGroupsSelected" (click)="toggleAllAssortmentGroups()" *ngIf="!readonly">
          <lxm-checkbox [checked]="isAllGroupsSelected" label="action.select_all" style="pointer-events: none">
          </lxm-checkbox>
          <!-- <span>{{childSelectedCount}}</span> -->
        </div>
        <ng-container *ngFor="let i of assortmentGroupsList?.controls; trackBy: trackByAssortmentGroupId">
          <div class="user-action-row" *ngIf="readonly ? isAssortmentGroupSelected(i.value.assortmentGroup) : true"
          [class.disabled]="!readonly && disabled || hasChildGroupSelected(i.value.assortmentGroup)" 
          [formGroup]="i"
          (mousedown)="toggleSelection(i.value.assortmentGroup)"
          (mouseenter)="onCheckboxDrag($event, i.value.assortmentGroup)">
          <div class="user-action-row-content">
            <div class="group-wrapper">
              <lxm-checkbox style="pointer-events: none" [checked]="isAssortmentGroupSelected(i.value.assortmentGroup)"
              [label]="i.value.assortmentGroup.name">
              </lxm-checkbox>
            </div>
            <div class="tags-wrapper" *ngIf="assortmentGroupTags">
              <tag *ngFor="let tag of getTagsByAssortmentGroupId(i.value.assortmentGroup?.id);" [name]="tag.name" [color]="tag.color" class="ml5"></tag>
            </div>
          </div>

            <span class="descriptive-text"
              [innerHTML]="'global.hold_key_to_select_multiple' | translate:{ key:'SHIFT' }"></span>
          </div>
        </ng-container>

      </div>
    </ng-container>
    <!-- <div class="input-padding" *ngSwitchCase="true">
      {{selectedAssortmentGroupNames}}
    </div> -->
  </ng-container>


</ng-container>

<ng-template #noGroupsTemplate>
  <card-message type="warning" class="mt10">
    {{'components.assortment_group_selector.readonly_no_segments' | translate}}
  </card-message>
  <!-- <div class="flex flex-row align-center input-padding">
    <span class="warning-icon mr15"></span>
    <span>
      {{'components.assortment_group_selector.readonly_no_segments' | translate}}
    </span>
  </div> -->
</ng-template>