<card-expanding [formGroup]="form">
  <span header-title>{{'cards.clients.client_details.title' | translate}}</span>
  <div header-actions>
    <ng-container *ngIf="isClient" [ngSwitch]="edit">
      <ng-container *ngSwitchCase="false">
        <div class="flex align-center">
          <lxm-button class="ml15" type="beige" icon="edit" title="action.edit" (click)="toggleEdit()" *ngIf="canEdit"></lxm-button>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="true">
        <div class="flex align-center">
          <lxm-button class="ml15" type="grey" icon="back" label="action.cancel" (click)="toggleEdit()">
          </lxm-button>
          <lxm-button class="ml15" type="beige" icon="save" label="action.save" [loading]="saveLoading" (click)="save()"
            buttonType="submit"></lxm-button>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div body>

    <div class="row">
      <div class="col-12 no-padding">

        <form-field [readonly]="!edit" label="enums.TenantField.RegNo" for="clientRegCode" type="input-text" [required]="edit">
          <validation-errors for="clientRegCode"></validation-errors>
        </form-field>

        <form-field label="enums.TenantField.Address" type="input-text" for="clientAddress" [readonly]="!edit" [required]="edit">
          <validation-errors for ="address" aliastTo="clientAddress"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit" label="enums.TenantField.KmkrNr" for="clientKMKR" type="input-text">
          <validation-errors for="clientKMKR"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit" label="enums.TenantField.IsFIEOrForeign">
          <slide-toggle toggle for="clientIsFIEOrForeign" [readonly]="!edit"></slide-toggle>
          <validation-errors for="clientIsFIEOrForeign"></validation-errors>
        </form-field>

      </div>

    </div>
  </div>

</card-expanding>