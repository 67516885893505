import { forwardRef, Inject, Directive, ViewContainerRef, ComponentRef, Input, OnInit, OnDestroy, ComponentFactoryResolver, Type } from "@angular/core"
import { DataTableFilter } from "../components/data-table/filters/data-table-filter";
import { FilterType, TextFilterValueType } from "../enum";
import { TextDataTableFilterComponent } from "../components/data-table/filters/text-filter/text-data-table-filter.component";
import { DropdownDataTableFilterComponent, FilterValueType } from "../components/data-table/filters/dropdown-filter/dropdown-data-table-filter.component";

@Directive({
  selector: "[data-table-filter]"
})
export class DataTableFilterDirective implements OnInit, OnDestroy {

  public compRef: ComponentRef<DataTableFilter>;

  protected filterType: FilterType;

  constructor(
    private _viewContainerRef: ViewContainerRef,
    private _componentFactoryResolver: ComponentFactoryResolver) { }

  protected setupComponent(): void { }

  public ngOnInit(): void {
    const componentType = this.getComponentType();

    if (!componentType) {
      // console.log("Unknown filter type " + this.filterType);
      return;
    }

    const componentFactory = this._componentFactoryResolver.resolveComponentFactory(componentType);
    this.compRef = this._viewContainerRef.createComponent(componentFactory);

    this.setupComponent();
  }

  public ngOnDestroy(): void {
    this.compRef.destroy();
  }

  private getComponentType(): Type<DataTableFilter> {

    switch (this.filterType) {

      case FilterType.Text:
        return TextDataTableFilterComponent;

      case FilterType.Dropdown:
        return DropdownDataTableFilterComponent;

      default:
        return null;
    }
  }
}

@Directive({
  selector: "[data-table-filter-text]",
  providers: [{ provide: DataTableFilterDirective, useExisting: forwardRef(() => DataTableTextFilterDirective) }]
})
export class DataTableTextFilterDirective extends DataTableFilterDirective {
  
  public filterType = FilterType.Text;

  @Input()
  public valueType: TextFilterValueType = TextFilterValueType.String;

  override setupComponent(): void {
    const filter = this.compRef.instance as TextDataTableFilterComponent;
    filter.valueType = this.valueType;
  }
}

@Directive({
  selector: "[data-table-filter-dropdown]",
  providers: [{ provide: DataTableFilterDirective, useExisting: forwardRef(() => DataTableDropdownFilterDirective) }]
})
export class DataTableDropdownFilterDirective extends DataTableFilterDirective {
  @Input()
  public options: any[];

  @Input()
  public isTranslated = false;

  @Input()
  public labelField = 'name';

  @Input()
  public value: string;

  @Input()
  public valueType: FilterValueType = FilterValueType.String;

  public filterType = FilterType.Dropdown;

  @Input()
  public anyOf: string;

  override setupComponent(): void {
    const filter = this.compRef.instance as DropdownDataTableFilterComponent;
    filter.options = this.options;
    filter.valueType = this.valueType;
    filter.isTranslated = this.isTranslated;
    filter.labelField = this.labelField;
    filter.value = this.value;
    filter.anyOf = this.anyOf;
  }
}
