<div class="row overflow-x" [formGroup]="fgInternal" *ngIf="hasData">
  <mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="role_column">
      <mat-header-cell *matHeaderCellDef>
        <span>
          {{'cards.products.product_book_info.role_section.role' | translate}}
        </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
        <div class="flex f-1" style="position: relative;">
          <lxm-select for="roleId" value="id" labelField="translatedValue" [isTranslated]="true" [searchable]="true"
            [options]="bookRoleOptions" [sortByLabel]="true" [readonly]="readonly">
            <validation-errors [for]="'roles['+(i)+'].RoleId'" aliasTo="roleId" [errorsFormGroup]="formGroup">
            </validation-errors>
          </lxm-select>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="personal_column">
      <mat-header-cell *matHeaderCellDef>
        <span>
          {{'cards.products.product_book_info.role_section.personal' | translate}}
        </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
        <div class="flex f-1" style="position: relative;">
          <lxm-input style="width: 100%;" for="personal" [id]="'personal_' + i" [readonly]="readonly">
          </lxm-input>
          <validation-errors [for]="'roles['+(i)+'].Personal'" aliasTo="personal" [errorsFormGroup]="formGroup">
          </validation-errors>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description_column">
      <mat-header-cell *matHeaderCellDef>
        <span>
          {{'cards.products.product_book_info.role_section.description' | translate}}
        </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
        <lxm-input style="width: 100%;" for="description" [id]="'description_' + i" [readonly]="readonly">
        </lxm-input>
        <validation-errors [for]="'roles['+(i)+'].Description'" aliasTo="description" [errorsFormGroup]="formGroup">
        </validation-errors>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="remove_column">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
        <lxm-button *ngIf="!readonly" type="transparent" icon="trash" title="action.remove" padding="8px 0"
          (click)="removeItem(i)"></lxm-button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="table.columns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: table.columns"
      [ngClass]="{'row-to-delete readonly' : row.get('isDeleted').value}"></mat-row>
  </mat-table>


</div>

<div class="row f-1" *ngIf="!hasData" style="padding: 8px;">
  <no-content image="no-book-roles" text="cards.products.product_book_info.role_section.no_roles" [inline]="true" style="margin: 0;"></no-content>
</div>

<div class="row mt10" *ngIf="!readonly">
  <lxm-button type="transparent" icon="plus-dark" label="cards.products.product_book_info.role_section.add_new" (click)="addItem()"></lxm-button>
</div>