export enum LocationShelfDirection {
    RightToLeft = 1,
    LeftToRight = 2
}

(LocationShelfDirection as any).__typeName = 'LocationShelfDirection';

const _ = 'enums.LocationShelfDirection.';

(LocationShelfDirection as any).__altTranslationKeys = {
  [LocationShelfDirection.RightToLeft] : _ + 'RightToLeft',
  [LocationShelfDirection.LeftToRight] : _ + 'LefToRight',
};
