<div class="layout-wrapper">
  <locker [active]="editing" [buffer]="true" (click)="confirmDiscard()"></locker>
  <div class="cards-container">

    <ng-container [ngSwitch]="isMarketProduct">

      <ng-container *ngSwitchCase="false" [ngSwitch]="hasBuyerPimExtensions">
        <ng-container *ngSwitchCase="true">
          <product-search-retailer-card class="mb12" (search)="searchSubject.next($event)"
            *nonVisitor></product-search-retailer-card>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <product-search-supplier-card class="mb12" (search)="searchSubject.next($event)"
            *nonVisitor></product-search-supplier-card>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="true">
        <market-search-card *nonVisitor class="mb12" (search)="searchSubject.next($event)"></market-search-card>
      </ng-container>

    </ng-container>

    <card-message *ngIf="productValidity.shouldValidate && !productValidity.hasBuyerPimExtensions"
      [type]="productValidity.isProductValid ? 'success' : 'error'" [ngSwitch]="productValidity.isProductValid">
      <div *ngSwitchCase="false" [ngSwitch]="productValidity.fieldValuesValid && !productValidity.unitValuesValid">
        <ng-container *ngSwitchDefault>
          <span *ngIf="productValidity.requiredByRetailChainNames"
            [innerHTML]="'cards.products.product_validation.summary.required_by_retailers' | translate: {retailChains: productValidity.requiredByRetailChainNames }"></span>
        </ng-container>

        <span *ngSwitchCase="true">
          Jätka sisestamist!
        </span>

      </div>
      <div *ngSwitchCase="true">
        {{'cards.products.product_validation.summary.product_valid' | translate}}
      </div>
    </card-message>

    <ng-container *ngIf="productId">
      <product-header-card [productId]="productId" class="mb20"
        [isMarketProduct]="isMarketProduct"></product-header-card>
    </ng-container>

    <product-info-card [productId]="productId" (relevantProductCardSectionsChanged)="showHideSections($event)"
      (editing)="setEditingState($event)" (productGs1SegmentChanged)="setCurrentProductGs1Segment($event)">
    </product-info-card>

    <ng-container *ngIf="productId" [ngSwitch]="hasBuyerPimExtensions">

      <ng-container *ngSwitchDefault>
        <ng-container *ngTemplateOutlet="defaultTemplate"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="true">
        <ng-container *ngTemplateOutlet="buyerPimTemplate"></ng-container>
      </ng-container>

    </ng-container>
  </div>

  <!-- DEFAULT TEMPLATE -->
  <ng-template #defaultTemplate>

    <product-custom-card *ngIf="appState.hasModule(LxmAppModule.Pim) && isOwnProduct" class="mt20"
      [productId]="productId" [productGs1Segment]="productGs1Segment"
      (editing)="setEditingState($event)"></product-custom-card>
    <product-alcohol-card class="mt20" [productId]="productId" *ngIf="showAlcoholCard"
      (editing)="setEditingState($event)"></product-alcohol-card>
    <product-seafood-card class="mt20" [productId]="productId" *ngIf="showSeafoodCard"
      (editing)="setEditingState($event)"></product-seafood-card>
    <product-meat-card class="mt20" [productId]="productId" *ngIf="showMeatCard"
      (editing)="setEditingState($event)"></product-meat-card>
    <product-movie-card class="mt20" [productId]="productId" *ngIf="showMovieCard"
      (editing)="setEditingState($event)"></product-movie-card>
    <product-music-card class="mt20" [productId]="productId" *ngIf="showMusicCard"
      (editing)="setEditingState($event)"></product-music-card>
    <product-game-card class="mt20" [productId]="productId" *ngIf="showGameCard"
      (editing)="setEditingState($event)"></product-game-card>
    <product-medication-card class="mt20" [productId]="productId" *ngIf="showMedicationCard"
      (editing)="setEditingState($event)"></product-medication-card>
    <product-electronics-card class="mt20" [productId]="productId" *ngIf="showElectronicsCard"
      (editing)="setEditingState($event)"></product-electronics-card>
    <product-book-card class="mt20" [productId]="productId" *ngIf="showBookCard"
      (editing)="setEditingState($event)"></product-book-card>
    <product-nutritional-card class="mt20" [productId]="productId" [showGrainFree]="showGrainFreeOnNutritionCard"
      *ngIf="showNutritionCard" (editing)="setEditingState($event)"></product-nutritional-card>
    <product-additional-card class="mt20" [productId]="productId"
      (editing)="setEditingState($event)"></product-additional-card>
    <product-attributes-card class="mt20" [productId]="productId"
      (editing)="setEditingState($event)"></product-attributes-card>
    <product-expiry-card class="mt20" [productId]="productId" *ngIf="showExpiryCard"
      (editing)="setEditingState($event)"></product-expiry-card>
    <product-packaging-card class="mt20" [productId]="productId"
      (editing)="setEditingState($event)"></product-packaging-card>
    <product-images-card class="mt20" [productId]="productId" (editing)="setEditingState($event)"></product-images-card>

    <ng-container *ngIf="isOwnProduct && appState.hasRight([UserAction.ViewPrices])">

      <ng-container *ngIf="hasBuyerPimExtensions">
        <product-supplier-prices-card class="mt20" [productId]="productId" (editing)="setEditingState($event)"
          [title]="'cards.products.product_buyin_prices.title'"></product-supplier-prices-card>
      </ng-container>

      <ng-container *ngIf="hasSellerPimExtensions">
        <product-pricelist-card class="mt20" [productId]="productId"
          (editing)="setEditingState($event)"></product-pricelist-card>
        <ng-container>
          <product-client-prices-card class="mt20" [productId]="productId"
            (editing)="setEditingState($event)"></product-client-prices-card>
        </ng-container>
      </ng-container>

    </ng-container>

    <ng-container *ngIf="!isOwnProduct && appState.hasRight([UserAction.ViewMarketPrices])">
      <product-supplier-prices-card class="mt20" [productId]="productId" (editing)="setEditingState($event)"
        [title]="'cards.products.product_clientprices.title'"></product-supplier-prices-card>
    </ng-container>

    <ng-container *ngIf="isOwnProduct && appState.hasModule(LxmAppModule.BuyerAssortment)">
      <retailer-product-assortment-card class="mt20" [productId]="productId"
        (editing)="setEditingState($event)"></retailer-product-assortment-card>
    </ng-container>

    <ng-container *ngIf="!isOwnProduct && appState.hasModule(LxmAppModule.ViewMarket)">
      <supplier-product-assortment-card class="mt20" [productId]="productId"
        (editing)="setEditingState($event)"></supplier-product-assortment-card>
    </ng-container>

    <ng-container *ngIf="isOwnProduct && appState.hasModule(LxmAppModule.SellerPimExtensions)">
      <supplier-product-assortment-card class="mt20" [productId]="productId"
        (editing)="setEditingState($event)"></supplier-product-assortment-card>
    </ng-container>

    <product-history-card class="mt20" [productId]="productId"></product-history-card>

  </ng-template>


  <!-- BUYER PIM TEMPLATE -->
  <ng-template #buyerPimTemplate>
    <product-custom-card *ngIf="appState.hasModule(LxmAppModule.Pim) && isOwnProduct" class="mt20"
      [productId]="productId" [productGs1Segment]="productGs1Segment"
      (editing)="setEditingState($event)"></product-custom-card>

    <ng-container *ngIf="isOwnProduct && appState.hasModule(LxmAppModule.BuyerAssortment)">
      <retailer-product-assortment-card class="mt20" [productId]="productId"
        (editing)="setEditingState($event)"></retailer-product-assortment-card>
    </ng-container>

    <ng-container *ngIf="!isOwnProduct && appState.hasModule(LxmAppModule.ViewMarket)">
      <supplier-product-assortment-card class="mt20" [productId]="productId"
        (editing)="setEditingState($event)"></supplier-product-assortment-card>
    </ng-container>

    <ng-container *ngIf="isOwnProduct && appState.hasModule(LxmAppModule.SellerPimExtensions)">
      <supplier-product-assortment-card class="mt20" [productId]="productId"
        (editing)="setEditingState($event)"></supplier-product-assortment-card>
    </ng-container>

    <ng-container *ngIf="isOwnProduct && appState.hasRight([UserAction.ViewPrices])">

      <ng-container *ngIf="hasBuyerPimExtensions">
        <product-supplier-prices-card class="mt20" [productId]="productId" (editing)="setEditingState($event)"
          [title]="'cards.products.product_buyin_prices.title'"></product-supplier-prices-card>
      </ng-container>

      <ng-container *ngIf="hasSellerPimExtensions">
        <product-pricelist-card class="mt20" [productId]="productId"
          (editing)="setEditingState($event)"></product-pricelist-card>
        <ng-container>
          <product-client-prices-card class="mt20" [productId]="productId"
            (editing)="setEditingState($event)"></product-client-prices-card>
        </ng-container>
      </ng-container>

    </ng-container>

    <ng-container *ngIf="!isOwnProduct && appState.hasRight([UserAction.ViewMarketPrices])">
      <product-supplier-prices-card class="mt20" [productId]="productId" (editing)="setEditingState($event)"
        [title]="'cards.products.product_clientprices.title'"></product-supplier-prices-card>
    </ng-container>

    <product-alcohol-card class="mt20" [productId]="productId" *ngIf="showAlcoholCard"
      (editing)="setEditingState($event)"></product-alcohol-card>
    <product-seafood-card class="mt20" [productId]="productId" *ngIf="showSeafoodCard"
      (editing)="setEditingState($event)"></product-seafood-card>
    <product-meat-card class="mt20" [productId]="productId" *ngIf="showMeatCard"
      (editing)="setEditingState($event)"></product-meat-card>
    <product-movie-card class="mt20" [productId]="productId" *ngIf="showMovieCard"
      (editing)="setEditingState($event)"></product-movie-card>
    <product-music-card class="mt20" [productId]="productId" *ngIf="showMusicCard"
      (editing)="setEditingState($event)"></product-music-card>
    <product-game-card class="mt20" [productId]="productId" *ngIf="showGameCard"
      (editing)="setEditingState($event)"></product-game-card>
    <product-medication-card class="mt20" [productId]="productId" *ngIf="showMedicationCard"
      (editing)="setEditingState($event)"></product-medication-card>
    <product-electronics-card class="mt20" [productId]="productId" *ngIf="showElectronicsCard"
      (editing)="setEditingState($event)"></product-electronics-card>
    <product-book-card class="mt20" [productId]="productId" *ngIf="showBookCard"
      (editing)="setEditingState($event)"></product-book-card>
    <product-nutritional-card class="mt20" [productId]="productId" [showGrainFree]="showGrainFreeOnNutritionCard"
      *ngIf="showNutritionCard" (editing)="setEditingState($event)"></product-nutritional-card>
    <product-additional-card class="mt20" [productId]="productId"
      (editing)="setEditingState($event)"></product-additional-card>
    <product-attributes-card class="mt20" [productId]="productId"
      (editing)="setEditingState($event)"></product-attributes-card>
    <product-expiry-card class="mt20" [productId]="productId" *ngIf="showExpiryCard"
      (editing)="setEditingState($event)"></product-expiry-card>
    <product-packaging-card class="mt20" [productId]="productId"
      (editing)="setEditingState($event)"></product-packaging-card>
    <product-images-card class="mt20" [productId]="productId" (editing)="setEditingState($event)"></product-images-card>
    <product-history-card class="mt20" [productId]="productId"></product-history-card>

  </ng-template>