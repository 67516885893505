function nullIfUndefined(val: any) {
  return val === undefined
    ? null
    : val;
}

function nullIfEmptyString(val: any) {
  return val === ""
    ? null
    : val;
}

function stringIsNullOrEmpty(val: any) {
  return val === undefined || val === null || val === "";
}

function joinAssortmentGroupNames(row) {
  const { assortmentGroups } = row || {};
  if (assortmentGroups?.length > 0) {
    return assortmentGroups.filter(x => x.name).map(x => {
      if (x.name) {
        return x.name
      }
    }).join(', ');
  }

  return null;
}

export {
  nullIfUndefined,
  nullIfEmptyString,
  stringIsNullOrEmpty,
  joinAssortmentGroupNames
}


