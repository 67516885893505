import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SignalRService } from 'src/app/_services/signalR.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  public constructor(private _signalRService: SignalRService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.startsWith('api/')) {
      return next.handle(req);
    }

    req = req.clone({
      url: environment.apiUrl + req.url,
      setHeaders: {
        'lxm-hub-common-id': this._signalRService.commonHubValue?.connectionId || ''
      },
      withCredentials: true
    });

    return next.handle(req);
  }
}
