<div class="row overflow-x" [formGroup]="fgInternal" *ngIf="hasData">
    <mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="allergen_name">
          <mat-header-cell *matHeaderCellDef>
            <span>
              {{'cards.products.product_certificate_info_and_nutrition_info.table.name' | translate}}
            </span>
          </mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
              <div class="flex f-1" style="position: relative;">
                  <lxm-select for="allergenId"
                              value="id"
                              labelField="translatedValue"
                              [isTranslated]="true"
                              [searchable]="true"
                              [options]="allergenOptions"
                              [sortByLabel]="true"
                              [readonly]="readonly">
                              <validation-errors [for]="'allergens['+(i)+'].allergenId'" aliasTo="allergenId" [errorsFormGroup]="formGroup"></validation-errors>
                  </lxm-select>
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="allergen_contains">
          <mat-header-cell *matHeaderCellDef>
            <span>
              {{'cards.products.product_certificate_info_and_nutrition_info.table.contains' | translate}}
            </span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
            <div class="flex f-1" style="position: relative;">
                <lxm-select 
                  for="contentType" 
                  [options]="containmentOptions" 
                  value="id" 
                  [readonly]="readonly">
                  <validation-errors [for]="'allergens['+(i)+'].contentType'" aliasTo="contentType" [errorsFormGroup]="formGroup"></validation-errors>
                </lxm-select>
              </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="allergen_remove">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
            <lxm-button *ngIf="!readonly" type="transparent" title="action.remove" padding="8px 0" (click)="removeItem(i)" [icon]="isItemDeleted(row) ? 'generate-small' : 'trash' "></lxm-button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="table.columns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: table.columns" [ngClass]="{'row-to-delete readonly' : row.get('isDeleted').value}"></mat-row>
    </mat-table>

  </div>

  <div class="row f-1" *ngIf="!hasData" style="padding: 8px;">
    <no-content image="no-allergens" text="cards.products.product_certificate_info_and_nutrition_info.product_allergens.no_allergens" [inline]="true" style="margin: 0;"></no-content>
  </div>
  
  <div class="row mt10" *ngIf="!readonly">
    <lxm-button type="transparent" icon="plus-dark" label="cards.products.product_certificate_info_and_nutrition_info.product_allergens.add_allergen" (click)="addItem()"></lxm-button>
  </div>