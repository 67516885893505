<card-expanding [valid]="productValidity.getCardStatusValidType(cardSection)" [expanded]="isExpanded" (onExpand)="saveLocalExpandedValue($event)">

  <span header-title>{{'cards.products.product_images.title' | translate}}</span>

  <div header-actions>
    <ng-container *ngIf="isOwnProduct">
      <ng-container *requiredRights="[UserAction.ManageProducts]">
          <lxm-button icon="plus-dark" type="beige" label="cards.products.product_images.action.add_image" (click)="openDialog()"></lxm-button>
      </ng-container>
    </ng-container>
  </div>

  <div body>

    <div *ngIf="!images?.length">
      <no-content image="no-product-images" text="global.no_images_added" [inline]="true"></no-content>
    </div>

    <div class="row mb30" *ngFor="let image of images; let i = index; trackBy: imageComparer">

      <div class="col-2 no-padding">
        <div class="product-image-container">
          <img class="product-image" [src]="image.thumbnailMediumUrl" draggable="false"  />
        </div>
      </div>

      <div class="col-5">

        <div>
          <div class="row ml30">

            <div class="col-6">
              <span class="image-label bold">
                <a [href]="image.url" target="_blank">{{ 'cards.products.product_images.label.base_image' | translate }}</a>
              </span>
            </div>
            <div class="col-3">
              <span class="image-label">{{image.width}} x {{image.height}}</span>
            </div>
            <div class="col-3">
              <span class="image-label">{{image.size | filesize}}</span>
            </div>
          </div>
        </div>

        <div *ngFor="let format of image.formats">
          <div class="row ml30">
            <div class="col-6">
              <span class="image-label bold">{{ format.formatType | translateEnum: 'ProductImageFormatType' }}</span>
            </div>
            <div class="col-3">
              <span class="image-label">{{ format.width }} x {{ format.height }}</span>
            </div>
            <div class="col-3">
              <span class="image-label">{{ format.size | filesize }}</span>
            </div>
          </div>
        </div>

        <!-- <mat-table [dataSource]="dataSource" class="no-table-header no-row-separator ml30">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
              <span class="bold">
                {{row.name}}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="dimensions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
              <span>
                {{row.dimensions}}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="size">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
              <span>
                {{row.size}}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="remove">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
              <span class="trash-icon"></span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </mat-table> -->

        <ng-container *ngIf="isOwnProduct">
          <div *requiredRights="[UserAction.ManageProducts]">
            <div class="row ml30 mt30">
              <lxm-button icon="edit" type="beige" label="cards.products.product_images.action.edit" (click)="openDialog(image)"></lxm-button>
              <!-- <lxm-button class="ml15" icon="plus-dark" type="beige" label="cards.products.product_images.action.add_format"></lxm-button> -->
              <lxm-button class="ml15" icon="trash" type="grey" label="cards.products.product_images.action.delete" (click)="deleteImage(image.id, i)"></lxm-button>
            </div>
          </div>
        </ng-container>

      </div>

      <div class="col-5">

        <form-field [readonly]="true">
          <span label>{{ 'cards.products.product_images.label.image_status' | translate}}</span>
          <span text>{{ image.status | translateEnum: 'Status'}}</span>
        </form-field>

        <form-field [readonly]="true">
          <span label>{{'cards.products.product_images.label.master' | translate}}</span>
          <span text>{{ image.isMaster | translateEnum: 'Bool'}}</span>
        </form-field>

        <form-field>
          <span label>{{ 'cards.products.product_images.label.forRetailChain' | translate }}</span>
          <span text> {{ image.isForRetailChain | translateEnum: 'Bool' }}</span>
        </form-field>

      </div> 

    </div>
  </div>
</card-expanding>
