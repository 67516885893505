import { Component, Inject, Optional } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { asArray, Status, UserAction } from 'src/app/enum';
import { ContentLanguageService, AuthenticationService } from 'src/app/_services';
import { AppState } from 'src/app/state/app.state';
import { finalize } from 'rxjs/operators';

@Component({
  selector: "content-language-dialog",
  host: { 'class': 'dialog' },
  templateUrl: './content-language.dialog.html',
  styleUrls: ['./content-language.dialog.scss']
})
export class ContentLanguageDialogComponent {

  public form: FormGroup;

  public UserAction = UserAction;
  public statuses: any[] = asArray(Status);

  public isNew: boolean;
  public canEdit: boolean;

  public saveInProgress = false;

  constructor(
    private _appState: AppState,
    private _contentLanguageService: ContentLanguageService,
    public dialogRef: MatDialogRef<ContentLanguageDialogComponent>,
    authService: AuthenticationService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isNew = !this.data.item;
    const contentLanguage = this.data.item || {};

    this.form = new FormGroup({
      id: new FormControl(contentLanguage.id),
      language: new FormControl(this.isNew ? null : { id: contentLanguage.twoLetterIso, name: contentLanguage.title }),
      isRequired: new FormControl(contentLanguage.isRequired),
      orderNr: new FormControl(contentLanguage.orderNr),
      status: new FormControl(contentLanguage.status === undefined ? Status.Active : contentLanguage.status)
    });

    this.canEdit = authService.hasRight([UserAction.ManageClvs]);
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public save(): void {
    this.saveInProgress = true;
    const f = this.form.value;

    const req = {
      languageTwoLetterIso: f.language?.id,
      isRequired: f.isRequired || false,
      status: f.status,
      orderNr: f.orderNr
    };

    const o = f.id
      ? this._contentLanguageService.save(f.id, req)
      : this._contentLanguageService.create(req);

    o.pipe(
        finalize(() => {
          this.saveInProgress = false;
        })
      ).result(this.form, response => {
      this.closeDialog(response);
    });
  }

  public languageExcludeIds = () => {
    return this._appState.userSettings.contentLanguages;
  }

  public closeDialog(data?: any): void {
    this.dialogRef.close(data);
  }
}
