import { Component, HostBinding, Input } from "@angular/core";

@Component({
  selector: "locker",
  templateUrl: "./locker.component.html",
  styleUrls: ['./locker.component.scss']
})
export class LockerComponent {

  @HostBinding('class.active') @Input() public active = false;
  @Input() public buffer = false;

}
