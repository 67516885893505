import { Component, Inject, Optional } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: "offer-product-price-changes-dialog",
  host: { 'class': 'dialog' },
  templateUrl: './offer-product-price-changes.dialog.html',
  styleUrls: ['./offer-product-price-changes.dialog.scss']
})
export class ProductPriceChangesInfoDialogComponent {

  public form: FormGroup;
  public readonly = false;

  public product: any;

  constructor(
    public dialogRef: MatDialogRef<ProductPriceChangesInfoDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.readonly = data.readonly;
    this.product = data.product;

    this.form = new FormGroup({
      note: new FormControl(data.product.priceChangeNote),
    });
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public save(): void {
    this.closeDialog(this.form.value);
  }

  public closeDialog(data?: any): void {
    this.dialogRef.close(data);
  }
}
