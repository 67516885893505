import { Component, Injector } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ProductCardBase } from '../product-card-base';
import { IProductCardGameInfo, IClvDto } from '../product-info/product-info.model';
import { Observable } from 'rxjs';
import { asArray, IEnumValue, asObject, UserAction, ProductCardSection, ProductField ,ValidType} from 'src/app/enum';
import { formUtil, keep } from 'src/app/util/form-util';
import { ProductValidity } from 'src/app/_services';
import { LOCAL_STORAGE_KEY } from 'src/app/config';

@Component({
  selector: 'product-game-card',
  templateUrl: './product-game.card.html',
  styleUrls: ['./product-game.card.scss']
})
export class ProductGameCard extends ProductCardBase<IProductCardGameInfo> {

  public UserAction = UserAction;
  public get title() { return 'cards.products.product_game_info.title'; }

  public cardSection = ProductCardSection.GameInfo;

  public gameFormatOptions: IClvDto[];
  public gameGenreOptions: IClvDto[];
  public gameContentRatingOptions: IClvDto[];
  public gamePegiRatingOptions: IClvDto[];

  public isExpandedKey = LOCAL_STORAGE_KEY.PRODUCT_GAME_CARD_OPEN;

  constructor(injector: Injector, public productValidity: ProductValidity) {
    super(injector, 'gameInfo', 'productGameInfoChanged');
    this.gameFormatOptions = this._productCard.formData.gameFormats;
    this.gameGenreOptions = this._productCard.formData.gameGenres;
    this.gameContentRatingOptions = this._productCard.formData.gameContentRatings;
    this.gamePegiRatingOptions = this._productCard.formData.gamePegiRatings;
  }

  public get cardValidType(): ValidType {
    return this.productValidity.getCardStatusValidType(this.cardSection);
  }

  public get shouldExpandSections() {
    return this.productValidity.shouldValidate ? (
      this.cardValidType === (ValidType.Invalid || ValidType.Recommended) ? true : false
      ) : false;
  }

  protected _getNormalizedFormData(data: IProductCardGameInfo) {
    return formUtil.transformData(data, {
      gameFormatId: () => data.gameFormat?.id,
      age: keep,
      players:keep,
      duration: keep,
      genres: val => val?.map(x => x.id),
      releaseYear: keep,
      publisher: keep,
      contentRatings: val => val?.map(x => x.id),
      pegiRatingId: () => data.pegiRating?.id,
      isCe: keep,
      isInfant: keep,
    });
  }
  protected _createFormGroup(data: IProductCardGameInfo): FormGroup {
    return formUtil.createFormGroup(this._getNormalizedFormData(data));
  }

  protected _createSaveRequest() {
    const data = this.form.value;
    return {
      gameFormatId:data.gameFormatId,
      age:data.age,
      players:data.players,
      duration:data.duration,
      genres:data.genres,
      releaseYear:data.releaseYear,
      publisher:data.publisher,
      contentRatings:data.contentRatings,
      pegiRatingId:data.pegiRatingId,
      isCe:data.isCe || false,
      isInfant:data.isInfant || false
    };
  }

  protected _saveInternal(req: any): Observable<any> {
    return this._productService.saveGameInfo(this.productId, req);
  }

  protected get successMessage(): string {
    return 'cards.products.product_game_info.save_successful';
  }

}
