import { Component, ViewChild, AfterViewInit } from "@angular/core";
import { RoleDialog } from "src/app/components/_partials/lxm-settings/roles-table/dialogs/role.dialog";
import { RolesTableComponent } from 'src/app/components/_partials/lxm-settings/roles-table/roles-table.component';
import { LxmDialog } from 'src/app/_helpers';
import { appSettings } from 'src/app/app.settings';
import { ActivatedRoute } from '@angular/router';
import { IRolesFormData } from 'src/app/resolve/lxm-settings/users.resolve';

@Component({
  selector: "roles-card",
  templateUrl: "./roles.card.html",
  styleUrls: ["./roles.scss"]
})
export class RolesCard implements AfterViewInit {

  @ViewChild(RolesTableComponent, { static: false }) private _rolesTable: RolesTableComponent;

  private _formData: IRolesFormData;

  constructor(
    private _route: ActivatedRoute,
    private _dialog: LxmDialog
  ) {
    const data = this._route.snapshot.data;
    this._formData = data.formData;
  }

  ngAfterViewInit(): void {
    this._rolesTable.onRowClick$.subscribe(row => this.openRoleDialog(row));
  }

  public openRoleDialog(role?: any): void {
    this._dialog.open(RoleDialog, {
      ...appSettings.DIALOG_SIZES.L,
      restoreFocus: false,
      data: {
        role,
        formData: this._formData
      },
    }, res => {
      if (!res) {
        return;
      }

      this._rolesTable.load();
    });
  }
}
