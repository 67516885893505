import { Component } from "@angular/core";
import { DataTableFilter } from "../data-table-filter";
import { FilterType } from "../../../../enum/filter-type";
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: "dropdown-filter",
  templateUrl: "./dropdown-data-table-filter.component.html",
  styleUrls: ["./dropdown-data-table-filter.component.scss"]
})
export class DropdownDataTableFilterComponent extends DataTableFilter {

  private _value: string;
  public options: any[] = [];
  public isTranslated: boolean;
  public labelField: string;
  public value: string;
  public valueType: FilterValueType = FilterValueType.String;
  public anyOf: string;
  public form: FormGroup = new FormGroup({ filter: new FormControl() });

  public getFilterType(): FilterType {
    return FilterType.Dropdown;
  }

  public getValue(): string {
    return this._value;
  }

  public onValueChanged(val: string): void {
    this._value = val;
    super.onValueChanged(val);
  }
}

export enum FilterValueType {
  String = 1,
  Literal = 2
}