import { environment } from "src/environments/environment";

export const LINKS = {
    LXM_MANUAL_ET: 'https://lexi.market/lexi-market-kasutusjuhend/',
    LXM_MANUAL_EN: 'https://lexi.market/lexi-market-user-manual/',
    DOWNLOAD_PRODUCT_CARD_IMPORT_TEMPLATE: 'https://lexi.market/wp-content/uploads/2022/02/Lexi.Market-tootekaardi-import-v3.0.xlsx',
    DOWNLOAD_CUSTOM_IMPORT_TEMPLATE:  environment.apiUrl +'api/import/myTemplate',
    DOWNLOAD_PRICELIST_IMPORT_TEMPLATE: 'https://lexi.market/wp-content/uploads/2024/04/Lexi.Market-hinnakiri-import-v1.1.xlsx',
    LXM_TERMS_ET: 'https://lexi.market/et/kasutustingimused/',
    LXM_TERMS_EN: 'https://lexi.market/termsandconditions/',
    LXM_CONTATCT: 'https://lexi.market/',
    SELVER_CONTACT: 'https://webforms.pipedrive.com/f/HWq0owdXOgduwhyYZk4Lu6RfKV3oUPEZOU1JDRnozPp6s2aDlw23BHaK1X6lyOT',
    SELVER_ORDERS: 'https://lexi.market/wp-content/uploads/2024/03/Selver-Tellimused-kiirjuhend-V2.0.pdf',
    SELVER_MANUAL: 'https://lexi.market/wp-content/uploads/2024/03/LexiMarket-Selver-v1.2.pdf',
    SELVER_PUJU: 'https://lexi.market/wp-content/uploads/2024/05/LexiMarket-Selver-PuJu-v2.0.pdf',
    USER_MANAGEMENT_ET: 'https://lexi.market/wp-content/uploads/2024/05/Kasutajate-haldus-ET.pdf',
    USER_MANAGEMENT_EN: 'https://lexi.market/wp-content/uploads/2024/05/User-Managment-EN.pdf',
    PACKAGING_DATA_ET: 'https://lexi.market/wp-content/uploads/2024/05/Jaepakend-ja-hulgipakendid-ET-1.pdf',
    PACKAGING_DATA_EN: 'https://lexi.market/wp-content/uploads/2024/05/Packaging-data-EN-1.pdf',
    SUPPLIER_FRVG_ET: 'https://lexi.market/wp-content/uploads/2024/06/Puu-ja-juurvili-ET.pdf',
    SUPPLIER_FRVG_EN: 'https://lexi.market/wp-content/uploads/2024/06/Fruit-and-vegetables-EN.pdf'
}