<ng-container [formGroup]="formGroup">
  
  <form-field dialog label="global.assortment_status_rule">
    <lxm-select select for="statusRule" class="w-full restricted-height" value="id"
      [searchable]="true" [options]="statusRules" [clearable]="true">
    </lxm-select>
    <validation-errors for="statusRule"></validation-errors>
  </form-field>

  <form-field dialog label="global.color">
    <div any class="flex f-1 flex-row align-center">
      <color-picker any class="f-0 mr10" for="color"></color-picker>
    </div>
  </form-field>
</ng-container>
