import { Component, Input, ViewChild, ViewEncapsulation } from "@angular/core";
import { ILxmTreeNode } from "src/app/_services/lxm-tree.service";
import { LxmTreeComponent, LxmTreeNodeComponent } from "src/app/components/lxm-tree/lxm-tree.component";
import { Status, Bool } from "src/app/enum";
import { appSettings } from 'src/app/app.settings';
import { LxmDialog } from 'src/app/_helpers';
import { AssortmentGroupTagDialog } from "./dialog/assortment-group-tag.dialog";
import { AssortmentGroupTagsService } from "src/app/_services/assortment-group-tags.service";
import { forkJoin } from "rxjs";

@Component({
  selector: "assortment-group-tags",
  templateUrl: "./assortment-group-tags.component.html",
  styleUrls: ["./assortment-group-tags.scss"],
  encapsulation: ViewEncapsulation.None
})
export class AssortmentGroupTagsComponent {

  @ViewChild(LxmTreeComponent, { static: true })
  private _tree: LxmTreeComponent;

  @Input() public canEdit: boolean;

  public displayColumns: string[] = ['code', 'sortOrder', 'name', 'description', 'isChild', 'status', 'color', 'actions'];

  public Status = Status;
  public Bool = Bool;

  constructor(
    private _dialog: LxmDialog,
    private _assortmentGroupTagsService: AssortmentGroupTagsService) { }

  public openDialog(tag?: ILxmTreeNode, parentTag?: ILxmTreeNode) {
    if (this._dialog.openDialogs.length !== 0) {
      return;
    }

    if (!parentTag && tag && tag.parentId) {
      parentTag = this._tree.getNode(tag.parentId).node;
    }

    this._dialog.open(AssortmentGroupTagDialog, {
      ...appSettings.DIALOG_SIZES.M,
      restoreFocus: false,
      data: {
        parentTag: parentTag
      },
      resolve: () => {
        const resolvers: any = {
          tag: this._assortmentGroupTagsService.getDetails(tag?.id)
        };

        return forkJoin(resolvers);
      }
    }, res => {
      if (!res) {
        return;
      }

      this._tree.load(parentTag ? parentTag.id : null);
      if (!parentTag) {
        return;
      }

      let nodeComponent = this._tree.getNode(parentTag.id) as LxmTreeNodeComponent;
      nodeComponent.expanded = true;
    });
  }
}