import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, HostListener, Input, TemplateRef, ViewChild, ViewEncapsulation } from "@angular/core";
import { Subject } from "@microsoft/signalr";
import { BehaviorSubject, Observable, Subscription } from "rxjs";

@Component({
  selector: "dot-menu",
  templateUrl: "./dot-menu.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./dot-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DotMenuComponent {

  @ViewChild('menuContent') actionsRef;

  @Input() public position: string = 'bottom';
  @Input() public trigger: string = 'click';
  @Input() public hideOnClick: boolean = false;
  @Input() public appendTo: string = 'app-root';
  @Input() public popperPositionFixed = true;
  @Input() public boundaries: string;

  @Input() template: TemplateRef<any>;

  @Input() public close: EventEmitter<any>;

  private _closeSubscription: Subscription;

  public onClick() {
    if (this.hideOnClick) {
      this.hide();
    }
  }

  public hide() {
    this.actionsRef.hide();
  }

  public get isOpen() {
    if (!this.actionsRef?.opacity) {
      return false;
    }
    return true;
  }

  public ngOnInit() {
    if (this.close) {
      this._closeSubscription = this.close.subscribe(_ => {
        this.hide();
      })
    }
  }

  public ngOnDestroy() {
    if (this._closeSubscription) {
      this._closeSubscription.unsubscribe();
    }
  }

}
