import { Component, EventEmitter, Host, Input, Optional, Output, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { FormGroup, ControlContainer } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { ScopeComponent } from "src/app/components/_layout/card/card.component";
import { ReadonlyDirective } from "src/app/directives";
import { FieldRequiredType } from "src/app/enum";

@Component({
  selector: "description-popover",
  templateUrl: "./description-popover.component.html",
  styleUrls: ['./description-popover.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DescriptionPopoverComponent {


  @Input() public showDescription = false;
  @Output() public showDescriptionChange = new EventEmitter();

  @Input() public readonly = false;
  @Input() public description: string;

  @Input() public position: string = 'auto';
  @Input() public trigger: string = 'click';

  @Input() public translationsPath: string;
  public descriptionKey: string;

  public labelTranslated: string;
  public descriptionTranslated: string;

  private _languageChangeSubscription: Subscription;

  constructor(
    @Optional() @Host() private _scope: ScopeComponent,
    @Optional() @Host() private _readonlyQuery: ReadonlyDirective,
    private _translateService: TranslateService) {
  }

  public getDescriptionKey() {
    if (!this.description) {
      return;
    }
    if (this.translationsPath) {
      this.descriptionKey = this.translationsPath + '.label_description.' + this.description;
    } else {
      this.descriptionKey = this.description ?? '';
    }
    if (this.descriptionKey) {
      this.descriptionTranslated = this._translateService.instant(this.descriptionKey);
    }
  }

  private _getTranslations() {
    this.getDescriptionKey();
  }

  public ngOnInit(): void {
    if (this._scope) {
      this.translationsPath = this._scope.translationsPath;
    }

    if (this._readonlyQuery && this._readonlyQuery.readonly) {
      this.readonly = this._readonlyQuery.readonly;
    }
    
    this._getTranslations();
  }

  ngOnChanges(changes: SimpleChanges) {
    const { description } = changes || {};
    if (description) {
      if (!description.firstChange) {
        this.getDescriptionKey();
      }
    }
  }

  ngAfterContentInit() {
    this._languageChangeSubscription = this._translateService.onLangChange.subscribe(x => this._getTranslations());
    this.showDescription = this.descriptionTranslated ? true : false;
    this.showDescriptionChange.emit(this.showDescription);
  }

  ngOnDestroy() {
    if (this._languageChangeSubscription) {
      this._languageChangeSubscription.unsubscribe();
    }
  }
}
