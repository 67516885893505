<ng-container [formGroup]="formGroup" [ngSwitch]="readonly">
  <ng-container *ngSwitchDefault [ngSwitch]="type">
    <input
      *ngSwitchDefault
      class="lxm-input {{ inputClass }}"
      placeholder="{{ placeholder | translate }}"
      [type]="type"
      [formControlName]="for"
      [id]="id"
      [maxlength]="maxlength"
      [attr.disabled]="disabled ? true : undefined"
      [autocomplete]="autocomplete"
      [pattern]="pattern"
      (blur)="blur.emit($event)"
      [tabindex]="tabIndex"
      #input
    />

    <input
      *ngSwitchCase="'price'"
      class="lxm-input {{ inputClass }}"
      placeholder="{{ placeholder | translate }}"
      type="text"
      [formControlName]="for"
      [id]="id"
      [maxlength]="maxlength"
      [autocomplete]="autocomplete"
      [pattern]="pattern"
      [attr.disabled]="disabled ? true : undefined"
      (blur)="blur.emit($event)"
      mask="separator.{{ pricePrecision }}"
      prefix="{{ currencyPrefix }} "
      decimalMarker=","
      [tabindex]="tabIndex"
      #input
    />

    <input
      *ngSwitchCase="'numeric'"
      class="lxm-input {{ inputClass }}"
      placeholder="{{ placeholder | translate }}"
      [formControlName]="for"
      [id]="id"
      type="text"
      [maxlength]="maxlength"
      [autocomplete]="autocomplete"
      [pattern]="pattern"
      [attr.disabled]="disabled ? true : undefined"
      [allowNegativeNumbers]="true"
      (blur)="blur.emit($event)"
      mask="separator.{{ digits }}"
      decimalMarker=","
      [suffix]="suffix"
      [tabindex]="tabIndex"
      #input
    />

    <input
      *ngSwitchCase="'percent'"
      class="lxm-input {{ inputClass }}"
      placeholder="{{ placeholder | translate }}"
      [formControlName]="for"
      [id]="id"
      [maxlength]="maxlength"
      type="text"
      [autocomplete]="autocomplete"
      [pattern]="pattern"
      [attr.disabled]="disabled ? true : undefined"
      (blur)="blur.emit($event)"
      mask="separator.{{digits}}"
      suffix="%"
      decimalMarker=","
      [allowNegativeNumbers]="true"
      [tabindex]="tabIndex"
      #input
    />
  </ng-container>

  <ng-container *ngSwitchCase="true">
    <span class="lxm-input input-readonly w-full" [ngClass]="inputClass">
      {{ inputValue }}
    </span>
  </ng-container>

  <ng-content></ng-content>
</ng-container>
