import { Component, Input, OnInit } from "@angular/core";
import { ViewEncapsulation } from '@angular/core';
import { IClvDto, IPriceGroup } from "src/app/cards/product/product-info/product-info.model";
import { ICurrency } from "src/app/models";
import { calculateDiscount } from "src/app/_helpers/calculate";

@Component({
  template: ''
})
export class ProductPriceInfoPopoverBase {

  @Input() public display = '';
  @Input() public position: string = 'left';
  @Input() public delay: number = 200;
  @Input() public pricePrecision: number = 3;
  @Input() public procurementHasPriority = false;

  @Input() public colored = false;

  @Input() public displayPriceField: string = 'retailPrice';

  @Input() public hasInvalidValues = false;

  public preventPropagation() {
    event.stopImmediatePropagation();
  }

  public endDate(date: string) {
    return new Date(new Date(date).getTime() - 86400 - 1);
  }
}

@Component({
  selector: "retailer-product-price-info-popover",
  templateUrl: "./retailer-product-price-info-popover.component.html",
  styleUrls: ['./product-price-info-popover.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RetailerProductPriceInfoPopoverComponent extends ProductPriceInfoPopoverBase {

  @Input() public scheme: IRetailerSchemeInfo;
  @Input() public retailPriceField: string = 'retailPrice';

  public get mainPriceScheme(): IRetailerMainPriceScheme {
    return this.scheme?.mainPriceScheme;
  }

  public get campaignScheme(): IRetailerCampaignPriceScheme {
    return this.scheme?.campaignPriceScheme;
  }

  public get procurementScheme(): IRetailerProcurementPriceScheme {
    return this.scheme?.procurementPriceScheme;
  }

  public get hasMainPriceSchemePriceGroups() {
    return this.mainPriceScheme?.priceGroups?.length > 0 ?? false
  }

  public get hasCampaignSchemePriceGroups() {
    return this.campaignScheme?.priceGroups?.length > 0 ?? false;
  }

  public get hasProcurementSchemePriceGroups() {
    return this.procurementScheme?.priceGroups?.length > 0 ?? false;
  }

  public get priceGroups() {
    return this.scheme?.priceGroups;
  }

  public get campaignPrice(): number {
    return this.campaignScheme?.[this.displayPriceField];
  }

  public get procurementPrice(): number {
    return this.procurementScheme?.[this.displayPriceField];
  }

  public get procurementDiscount() {
    const { listPrice, buyInPrice } = this.procurementScheme || {};
    return calculateDiscount(listPrice, buyInPrice);
  }

  public get campaignDiscount() {
    const { listPrice, buyInPrice } = this.campaignScheme || {};
    return calculateDiscount(listPrice, buyInPrice);
  }

  public get mainSchemeCurrency() {
    return this.mainPriceScheme?.currency;
  }

  public get procurementCurrency() {
    return this.procurementScheme?.currency;
  }

  public get campaignCurrency(): ICurrency {
    return this.campaignScheme?.currency;
  }

  public get mainSchemePrice() {
    return this.mainPriceScheme?.[this.displayPriceField];
  }

  public get campaignSchemeRetailPrice() {
    return this.campaignScheme?.[this.retailPriceField];
  }

  public get procurementSchemeRetailPrice() {
    return this.procurementScheme?.[this.retailPriceField];
  }

  public get mainSchemeListPrice() {
    return this.mainPriceScheme?.listPrice;
  }

  public get mainSchemeDiscount() {
    return this.mainSchemeListPrice && this.mainSchemePrice ? ((this.mainSchemeListPrice - this.mainSchemePrice) / this.mainSchemeListPrice) : '-'
  }

  // public calculateDiscount(row: any) {
  //   return calculateDiscount(row.listPrice, row.buyInPrice);
  // }

}

@Component({
  selector: "supplier-product-price-info-popover",
  templateUrl: "./supplier-product-price-info-popover.component.html",
  styleUrls: ['./product-price-info-popover.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SupplierProductPriceInfoPopoverComponent extends ProductPriceInfoPopoverBase {

  @Input() public scheme: ISupplierSchemeInfo;
  @Input() public displayPriceField: string = 'retailPrice';

  public get mainPriceScheme(): ISupplierMainPriceScheme {
    return this.scheme?.mainPriceScheme;
  }

  public get campaignScheme(): ISupplierMainPriceScheme {
    return this.scheme?.campaignPriceScheme;
  }

  public get procurementScheme(): ISupplierProcurementPriceScheme {
    return this.scheme?.procurementPriceScheme;
  }

  public get campaignPrice(): number {
    return this.campaignScheme?.[this.displayPriceField];
  }

  public get procurementPrice(): number {
    return this.procurementScheme?.[this.displayPriceField];
  }

  public get mainSchemeCurrency(): ICurrency {
    return this.mainPriceScheme?.currency;
  }

  public get campaignCurrency(): ICurrency {
    return this.campaignScheme?.currency;
  }

  public get procurementCurrency(): ICurrency {
    return this.procurementScheme?.currency;
  }

  public get mainSchemeMainPrice() {
    return this.mainPriceScheme?.[this.displayPriceField];
  }

  public get mainSchemePublicPrice() {
    return this.mainPriceScheme?.publicPrice;
  }

  public get mainSchemeDiscount() {
    return calculateDiscount(this.mainSchemePublicPrice, this.mainSchemeMainPrice);
  }

  public get procurementDiscount() {
    const retailPrice = this.procurementScheme?.retailPrice;
    const publicPrice = this.procurementScheme?.publicPrice;
    return calculateDiscount(publicPrice, retailPrice);
  }

  public get campaignDiscount() {
    const retailPrice = this.campaignScheme?.retailPrice;
    const publicPrice = this.campaignScheme?.publicPrice;
    return calculateDiscount(publicPrice, retailPrice);
  }

}

@Component({
  selector: "market-product-price-info-popover",
  templateUrl: "./supplier-product-price-info-popover.component.html",
  styleUrls: ['./product-price-info-popover.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MarketProductPriceInfoPopoverComponent extends SupplierProductPriceInfoPopoverComponent {

  @Input() public colored = true;

}

@Component({
  selector: "pmd-product-price-info-popover",
  templateUrl: "./pmd-product-price-info-popover.component.html",
  styleUrls: ['./product-price-info-popover.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PurchaseManagerDesktopProductPriceInfoPopoverComponent extends ProductPriceInfoPopoverBase {

  @Input() public scheme: IRetailerSchemeInfo;
  @Input() public retailPriceField: string = 'retailPrice';

  public get campaignScheme(): IRetailerCampaignPriceScheme {
    return this.scheme?.campaignPriceScheme;
  }

  public get campaignDiscount() {
    const { listPrice, buyInPrice } = this.campaignScheme || {};
    return calculateDiscount(listPrice, buyInPrice);
  }

  public get campaignCurrency(): ICurrency {
    return this.campaignScheme?.currency;
  }

}

@Component({
  selector: "purchase-order-product-price-info-popover",
  templateUrl: "./purchase-order-product-price-info-popover.component.html",
  styleUrls: ['./product-price-info-popover.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PurchaseOrderProductPriceInfoPopoverComponent extends ProductPriceInfoPopoverBase {

  @Input() public scheme: IRetailerSchemeInfo;

  public get campaignScheme(): IRetailerCampaignPriceScheme {
    return this.scheme?.campaignPriceScheme;
  }

}

interface ISupplierSchemeInfo {
  mainPriceScheme: ISupplierMainPriceScheme;
  campaignPriceScheme: ISupplierCampaignPriceScheme;
  procurementPriceScheme: ISupplierProcurementPriceScheme;
  publicPriceScheme?: ISupplierPublicPriceScheme;
}


interface IRetailerSchemeInfo {
  mainPriceScheme: IRetailerMainPriceScheme;
  campaignPriceScheme: IRetailerCampaignPriceScheme;
  procurementPriceScheme: IRetailerProcurementPriceScheme;
  priceGroups: IPriceGroupPriceScehme;
}

interface ISupplierPublicPriceScheme {

}


interface ISupplierProcurementPriceScheme {
  currency: ICurrency;
  publicPrice: number;
  retailPrice: number;
  validFrom: Date;
  validTo: Date;
}

interface IRetailerProcurementPriceScheme {
  buyInPrice: number;
  buyInUnitPrice: number;
  currency: ICurrency;
  listPrice: number;
  logisticsPartner: string;
  retailPrice: number;
  retailPriceWithoutVat: number;
  retailUnitPrice: number;
  retailUnitPriceWithoutVat: number;
  validFrom: Date;
  validTo: Date;
}

interface ISupplierMainPriceScheme {
  currency: ICurrency;
  publicPrice: number;
  retailPrice: number;
  validFrom: Date;
  validTo: Date;
}

interface ISupplierCampaignPriceScheme {
  currency: ICurrency;
  publicPrice: number;
  retailPrice: number;
  validFrom: Date;
  validTo: Date;
}

interface ISupplierProcurementPriceScheme {
  currency: ICurrency;
  publicPrice: number;
  retailPrice: number;
  validFrom: Date;
  validTo: Date;
}
interface IRetailerMainPriceScheme {
  buyInPrice: number;
  buyInUnitPrice: number;
  currency: ICurrency;
  listPrice: number;
  logisticsPartner: string;
  priceGroups: IPriceGroupPriceScehme[];
  retailPrice: number;
  retailPriceWithoutVat: number;
  retailUnitPrice: number;
  retailUnitPriceWithoutVat: number;
  validFrom: Date;
  validTo: Date;
}

interface IRetailerCampaignPriceScheme {
  buyInPrice: number;
  buyInUnitPrice: number;
  currency: ICurrency;
  listPrice: number;
  logisticsPartner: string;
  priceGroups: IPriceGroupPriceScehme[];
  retailPrice: number;
  retailPriceWithoutVat: number;
  retailUnitPrice: number;
  retailUnitPriceWithoutVat: number;
  validFrom: Date;
  validTo: Date;
}

interface IRetailerProcurementPriceScheme {
  buyInPrice: number;
  buyInUnitPrice: number;
  currency: ICurrency;
  listPrice: number;
  logisticsPartner: string;
  priceGroups: IPriceGroupPriceScehme[];
  retailPrice: number;
  retailPriceWithoutVat: number;
  retailUnitPrice: number;
  retailUnitPriceWithoutVat: number;
  validFrom: Date;
  validTo: Date;
}

interface IPriceGroupPriceScehme {
  priceGroupName: string;
  price: number;
  priceWithoutVat: number;
  currency: ICurrency;
}