<lxm-dialog [dialogRef]="dialogRef" [formGroup]="form" [readonly]="!canEdit" #dialog>
    <span header-title>
      {{ ('cards.settings.assortment_management.assortment_schemes.' + (isNew ? 'title_new' : 'title_edit')) | translate }}
    </span>

    <div body style="padding-bottom: 40px;">
        <scope translationsPath="cards.settings.assortment_management.assortment_schemes">

          <form-field dialog label="valid_from">
            <lxm-datepicker input for="validFrom" [readonly]="!canEdit"></lxm-datepicker>
            <validation-errors for="validFrom"></validation-errors>
          </form-field>

          <form-field dialog label="valid_to">
            <lxm-datepicker input for="validTo" [readonly]="!canEdit"></lxm-datepicker>
            <validation-errors for="validTo"></validation-errors>
          </form-field>

        </scope>
    </div>

    <div footer>
      <lxm-button type="primary" *ngIf="canEdit" label="cards.settings.product_management_settings.segments_tree_edit.action.save" (click)="save()" [loading]="saveInProgress" buttonType="submit"></lxm-button>
      <lxm-button type="secondary" class="ml15" label="cards.settings.product_management_settings.segments_tree_edit.action.cancel" (click)="closeDialog()"></lxm-button>
    </div>
</lxm-dialog>
