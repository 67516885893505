<div class="file-container" [class.edit]="!readonly">
    <div class="files-list" *ngFor="let file of files; let i= index" (click)="$event.stopImmediatePropagation()">
        <img class="files-list-image" [src]="imageSrc">
        <div class="files-list-footer">
          <div class="files-list-info">
            <span class="files-list-text line-clamp-2">
              {{ file.name }}
            </span>
            <lxm-button type="transparent"
                        class="delete-file"
                        icon="trash"
                        title="action.delete"
                        padding="8px 0"
                        (click)="deleteFile(i)">
            </lxm-button>
          </div>
          <div class="file-progress" *ngIf="file.progress !== undefined">
            <mat-progress-bar [mode]="progressMode" [value]="file.progress" [class.invalid]="file.progress < 0"></mat-progress-bar>
            <span class="file-progress-text">
              <ng-container *ngIf="!file.error && file.progress < 100">
                {{('components.file_drop_box.uploading' | translate) + " " + file.progress + "%"}}
              </ng-container>
              <ng-container *ngIf="file.progress === 100">
                {{((file.customText ? file.customText : 'components.file_drop_box.upload_success') | translate)}}
              </ng-container>
              <ng-container *ngIf="file.progress < 0 && file.error">
                {{ file.error | translate }}
              </ng-container>
            </span>
          </div>
        </div>
    </div>
    <ng-container *ngIf="showAddFileCondition && multiple ? true : files.length < 1">
      <input hidden type="file" #fileInput (change)="emitFiles($event.target.files)">
      <div class="add-file-container" fileDrop 
        [ngSwitch]="hasImage"
        [multiple]="multiple"
        [accept]="accept"
        (onFileDropped)="emitFiles($event)">
        <img class="" *ngSwitchCase="true" [src]="imageSrc" />
        <div class="add-file-center" *ngSwitchDefault>
          <span class="add-file-image"></span>
          <span class="add-file-text">
            {{ addFileText ? (addFileText | translate) : 'components.file_drop_box.drag_file_here' | translate}}
          </span>
        </div>
      </div>
    </ng-container>
    <ng-content select="[validation-errors]"></ng-content>
  </div>
