<lxm-dialog [dialogRef]="dialogRef">
    <span header-title>
      {{'cards.offer.products.dialogs.replace_product.title' | translate}}
    </span>
    <ng-container body>
      <card-section>
        <mat-table [dataSource]="dataSource" body>

          <ng-container matColumnDef="product_image" sticky>
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let row" [formGroup]="row">
              <!-- <image-popover [image]="row.get('imageThumbUrl')?.value" [thumbnail]="row.get('thumbnail')?.value"
                [thumbnailBase64]="true" [thumbnailContentType]="row.get('thumbnailContentType')?.value">
              </image-popover> -->
              <image-popover [image]="row.get('imageUrl').value"
                [status]="row.get('assortmentStatus').value"></image-popover>
            </mat-cell>
          </ng-container>
      
          <ng-container matColumnDef="product_name" sticky>
            <mat-header-cell *matHeaderCellDef>
              <span>
                {{'cards.offer.products.table.full_name' | translate}}
              </span>
              &nbsp;
              <span>
                ({{'cards.offer.products.table.brand' | translate}})
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row" [formGroup]="row">
              <span class="flex flex-col">
                <!-- (click)="productClick(row.get('productId').value)" -->
                <span class="link line-clamp-2" >
                  {{row.get('name').value}}
                  <ng-container *ngIf="row.get('brand').value">
                    ({{row.get('brand').value}})
                  </ng-container>
                </span>
                <span class="alt-text">
                  {{row.get('ean').value}}
                  <ng-container *ngIf="row.get('supplierCode').value">
                    ({{row.get('supplierCode').value}})
                  </ng-container>
                </span>
              </span>
            </mat-cell>
          </ng-container>
      
          <ng-container matColumnDef="available_from">
            <mat-header-cell *matHeaderCellDef>
              <span [innerHTML]="'cards.offer.products.table.offer_product_available_from' | translate">
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row" [formGroup]="row">
              {{row.get('availableFrom').value | formDate}}
            </mat-cell>
          </ng-container>
      
          <ng-container matColumnDef="list_price">
            <mat-header-cell *matHeaderCellDef>
              <span class="w-full text-end" innerHTML="{{'cards.offer.products.table.internal_pricelist' | translate}}">
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
              <div class="text-end triple-rows">
                <div class="cell-row text-end new-price">
                  <ng-container *ngIf="hasValidSchemePrice(row.value.validSchemeListPrice, row.value.listPrice)">
                    {{ row.value.validSchemeListPrice | priceValue:3:currency?.code }}
                  </ng-container>
                </div>
                <div class="cell-row text-end"
                  [class.old-price]="hasValidSchemePrice(row.value.validSchemeListPrice, row.value.listPrice)">
                  {{ row.value.listPrice | priceValue:3:currency?.code }}
                </div>
                <div class="cell-row alt-text text-end">
                  {{ calculateUnitPrice(row.value.listPrice, row.value) | priceValue:pricePrecision:displayCurrency?.value }}{{ calculateUnitPrice(row.value.listPrice, row.value) ? '/' + ( row.value.contentUnitId | translateEnum:'UnitShort') : null }}
                </div>
              </div>
            </mat-cell>
          </ng-container>
      
          <ng-container matColumnDef="discount">
            <mat-header-cell *matHeaderCellDef>
              <span class="w-full text-center" [innerHTML]="'cards.offer.products.table.offer_discount' | translate">
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
              <div class="flex flex-col f-1 text-center">
                <lxm-input 
                  [readonly]="readonly" 
                  type="percent" 
                  [digits]="2"
                  [class.new-price]="hasValidSchemePrice(row.value.validSchemeListPrice, row.value.listPrice)" 
                  for="discount"
                  [id]="'discount_' + i">
                </lxm-input>
                <div class="old-price"
                  *ngIf="hasValidSchemePrice(row.value.validSchemeListPrice, row.value.listPrice) && readonly">
                  {{ calculateMargin(row.get('salePrice').value, getListPrice(row.value)) | percentValue }}
                </div>
              </div>
            </mat-cell>
          </ng-container>
      
          
          <ng-container matColumnDef="price_retailer">
            <mat-header-cell *matHeaderCellDef>
              <span class="w-full text-end" [innerHTML]="'cards.offer.products.table.client_price_vat_excluded' | translate">
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
              <div class="text-end triple-rows">
                <div class="cell-row"></div>
                <div class="cell-row">
                  <lxm-input 
                    type="price"
                    [currency]="currency"
                    [readonly]="readonly" 
                    for="salePrice"
                    [id]="'salePrice_' + i" displayFormat="1.3-3">
                  </lxm-input>
                </div>
                <div class="cell-row w-full alt-text text-end">
                  {{ calculateUnitPrice(row.value.salePrice, row.value) | priceValue:pricePrecision:displayCurrency?.value }}{{ calculateUnitPrice(row.value.salePrice, row.value) ? '/' + ( row.value.contentUnitId | translateEnum:'UnitShort') : null }}
                </div>
              </div>
            </mat-cell>
          </ng-container>
      
          <ng-container matColumnDef="action_detail">
            <mat-header-cell *matHeaderCellDef>
            </mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
              <lxm-button 
                type="transparent"
                style="pointer-events: none"
                [icon]="row.value.replacementForProductId ? 'product-replacement-in' : 'product-replacement-out'">
              </lxm-button>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
              <lxm-button type="grey"  width="34px" height="34px"
                [icon]="row.value.isDeleted || row.value.status === OfferProductStatus.Removed ? 'generate' : 'trash'"
                (click)="removeItem(i)" *ngIf="row.value.replacementForProductId" title="cards.offer.products.table.delete_product_title">
              </lxm-button>
            </mat-cell>
          </ng-container>
      
          <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: columns; let i = dataIndex;" class="table-row-hover" [class.target-product]="!row.value.replacementForProductId" [ngClass]="getRowCssClass(row, i)">
          </mat-row>
        </mat-table>
      </card-section>

      <ng-container>
        <div class="separator"></div>

        <card-section [formGroup]="formGroup">
          <div header-title>
            {{'cards.offer.products.dialogs.replace_product.replacement_product' | translate}}
          </div>
          <div body>
            <info-array prelude="cards.offer.products.dialogs.replace_product.info_prelude"></info-array>
            <lxm-select 
              class="mt15 ml30" 
              width="400px" 
              for="replacementProductAc" 
              labelField="label" 
              [searchable]="true" 
              placeholder="cards.offer.products.search_placeholder" 
              url="api/products/ac/" 
              [acLabelFormat]="formatProductAcLabel"
              [acData]="getProductAcExcludeIds">
            </lxm-select>
          </div>
        </card-section>
      </ng-container>

    </ng-container>

    <section footer>
      <lxm-button 
        type="primary" 
        label="action.save"
        (click)="save()"
        buttonType="submit"
        [loading]="isSaveLoading">
      </lxm-button>
      <lxm-button type="secondary" class="ml10" label="action.cancel" (click)="closeDialog()"></lxm-button>
    </section>

  </lxm-dialog>
  