import { Component, Input, Optional, ViewChild, ViewEncapsulation } from "@angular/core";
import { ControlContainer, FormGroup } from "@angular/forms";
import { Color } from "src/app/enum/color";

@Component({
  selector: "color-picker",
  templateUrl: "./color-picker.component.html",
  styleUrls: ['./color-picker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ColorPickerComponent {

  @Input() public formGroup: FormGroup;
  @Input() public for: string;

  @ViewChild('colorPicker') colorPicker;

  constructor(@Optional() private _controlContainer: ControlContainer) { }

  public ngOnInit(): void {
    if (this._controlContainer && this._controlContainer.control instanceof FormGroup) {
      this.formGroup = <FormGroup>this._controlContainer.control;
    }
  }

  public get fgValue() {
    return this.formGroup.get(this.for).value;
  }

  public selectColor(color: Color) {
    event.stopPropagation();
    this.formGroup.get(this.for)?.setValue(color);
    this.colorPicker.hide();
  }

  public categoriesMap = [
    { title: 'Kollane kategooria', hex: '#fcdf76', color: Color.Yellow },
    { title: 'Roheline kategooria', hex: '#9ace81', color: Color.Green },
    { title: 'Helesinine kategooria', hex: '#71cff5', color: Color.LightBlue },
    { title: 'Sinine kategooria', hex: '#6f9ceb', color: Color.Blue },
    { title: 'Lilla kategooria', hex: '#c490d1', color: Color.Purple },
    { title: 'Pruun kategooria', hex: '#b89494', color: Color.Brown },
    { title: 'Oranž kategooria', hex: '#f2736d', color: Color.Orange },
    { title: 'Punane kategooria', hex: '#944b56', color: Color.Red },
    { title: 'Must kategooria', hex: '#5e5357', color: Color.Black },
    { title: 'Valge kategooria', hex: '#ffffff', color: Color.White },
  ];

}
