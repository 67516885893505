<lxm-dialog [dialogRef]="dialogRef" [formGroup]="form">
  <span header-title>{{'components.add_image_dialog.title' | translate}}</span>
  <section body>

    <form-field for="name" 
      label="components.add_image_dialog.label.name" 
      type="input-text">
      <validation-errors for="name"></validation-errors>
    </form-field>

    <form-field for="description" 
      label="components.add_image_dialog.label.description" 
      type="textarea">
      <validation-errors for="description"></validation-errors>
    </form-field>

    <file-drop-box 
      (onChange)="uploadFile($event)"
      (onDelete)="deleteAttachment($event)"
      [files]="files"
      [uploadInProgress]="uploadInProgress" 
      [uploadingFile]="uploadingFile">
    </file-drop-box>

  </section>

  <section footer>
    <lxm-button 
      type="primary" 
      label="components.add_image_dialog.action.save_image"
      [disabled]="uploadInProgress || uploadingFile"
      [loading]="uploadingFile"
      (click)="save()"
      buttonType="submit">
    </lxm-button>
    <lxm-button type="secondary" class="ml10" label="components.add_image_dialog.action.cancel" (click)="closeDialog()"></lxm-button>
  </section>

</lxm-dialog>
