import {
  Component,
  Directive,
  Inject,
  Optional,
  ViewChild
} from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  AuthenticationService,
  OfferService,
  SegmentService
} from "src/app/_services";
import { Unit, UserAction } from "src/app/enum";
import {
  IClvDto,
  IProductUnitCodeType,
  ISegment,
  ISegmentTreeOption
} from "src/app/cards/product/product-info/product-info.model";
import { stringIsNullOrEmpty } from "src/app/util/fn";
import { Guid } from "src/app/util/Guid";
import { RETAILER_ID } from "src/app/config/retailers";
import { DialogComponent } from "src/app/components/_layout/dialog/dialog.component";

@Directive()
export class ProductRetailerDetailsDialogBase {
  @ViewChild("dialog", { static: true, read: DialogComponent })
  public _dialog: DialogComponent;

  public RETAILER_ID = RETAILER_ID;

  public product: any;
  public attributeOptions: IClvDto[];
  public tagsOptions: IClvDto[];
  public codeTypeOptions: IProductUnitCodeType[];
  public form: FormGroup;
  public readonly = false;
  public isSaveLoading = false;
  public visibleParameters: string[];
  public productBrands = [];
  public productSubBrands = [];
  public countries = [];
  public units = [];

  public segmentOptions: ISegment[];
  public segmentOtherOptions: ISegmentTreeOption[];
  public showOtherSegments: boolean = false;
  public externalFormData: any;
  public markingPriceRequiredVisible = false;

  public _segmentOther: ISegment = {
    id: Guid.empty,
    code: "",
    name: "",
    title: "cards.products.product_base_info.label.segment_other"
  };

  constructor(
    public dialogRef: MatDialogRef<ProductRetailerDetailsDialogBase>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public _segmentService: SegmentService
  ) {
    // this.readonly = data.readonly || !authService.hasRight([UserAction.ManageReceivedOffers]);
    // this.form = new FormGroup({
    //   fullName: new FormControl(data.product.retailerDetails?.fullName),
    //   shortName: new FormControl(data.product.retailerDetails?.shortName),
    //   segment: new FormControl(data.product?.retailerDetails?.segment),
    //   segmentOther: new FormControl(),
    //   expiryInDays: new FormControl(data.product.retailerDetails?.expiryInDays),
    //   minExpiryOnDelivery: new FormControl(data.product.retailerDetails?.minExpiryOnDelivery),
    //   expiryForTargetInDays: new FormControl(data.product.retailerDetails?.expiryForTargetInDays),
    //   tags: new FormControl(data.product.retailerDetails?.tags),
    //   attributes: new FormControl(data.product.retailerDetails?.attributes?.map(x => x.id)),
    //   codes: new FormControl(this.getNormalizedCodes(data.product.retailerDetails?.codes) || []),
    //   parameter1: new FormControl(data.product.retailerDetails?.parameter1),
    //   parameter2: new FormControl(data.product.retailerDetails?.parameter2),
    //   parameter3: new FormControl(data.product.retailerDetails?.parameter3),
    //   parameter4: new FormControl(data.product.retailerDetails?.parameter4),
    //   parameter5: new FormControl(data.product.retailerDetails?.parameter5),
    //   parameter6: new FormControl(data.product.retailerDetails?.parameter6),
    //   parameter7: new FormControl(data.product.retailerDetails?.parameter7),
    //   parameter8: new FormControl(data.product.retailerDetails?.parameter8),
    //   parameter9: new FormControl(data.product.retailerDetails?.parameter9),
    //   parameter10: new FormControl(data.product.retailerDetails?.parameter10),
    //   productTopic: new FormControl(data.product.externalData?.productTheme?.lxmId),
    //   markable: new FormControl(data.product.externalData?.isMarkable ?? false),
    //   returnType: new FormControl(data.product.externalData?.returnType?.lxmId),
    //   transportUnitRepetition: new FormControl(data.product.externalData?.minAmount),
    //   storageGroup: new FormControl(data.product.externalData?.warehouseGroup?.lxmId),
    //   purchaseClass: new FormControl(data.product.externalData?.buyInGroup?.lxmId),
    //   priceClass: new FormControl(data.product.externalData?.priceGroup?.lxmId),
    //   coopImport: new FormControl(data.product.externalData?.isImport ?? false),
    //   brand: new FormControl(data.product.retailerDetails?.brand),
    //   subBrand: new FormControl(data.product.retailerDetails?.subBrand),
    //   model: new FormControl(data.product.retailerDetails?.model),
    //   countryOfOrigin: new FormControl(data.product.retailerDetails?.countryOfOrigin),
    //   markingPriceRequired: new FormControl(data.product.retailerDetails?.markingPriceRequired),
    //   description: new FormControl(data.product.retailerDetails?.description)
    // });
    // this.product = data.product;
    // this.attributeOptions = data.attributes;
    // this.tagsOptions = data.productTags;
    // this.codeTypeOptions = data.productUnitCodeTypes;
    // this.visibleParameters = this.getVisibleParameters();
    // this.segmentOtherOptions = data.segmentTreeOptions;
    // this.countries = data.countries;
    // this.markingPriceRequiredVisible = data.product.salesUnitId == Unit.Kg;
    // this.productBrands = data.productBrands;
    // this.productSubBrands = data.productSubBrands;
    // if (data.product.gs1BrickCodeId) {
    //   this._segmentService.getSegmentsByGpcBrickCode(data.product.gs1BrickCodeId)
    //     .subscribe(res => {
    //       this.segmentOptions = res;
    //       this.segmentOptions.push(this._segmentOther);
    //       this.setSegmentValue();
    //     });
    // } else {
    //   this.segmentOptions = [this._segmentOther];
    //   this.setSegmentValue();
    // }
    // this.form.get('segment').valueChanges.subscribe(segment => {
    //   this.showOtherSegments = segment?.id == Guid.empty;
    //   this.form.get("segmentOther").setValue(null);
    // });
  }

  protected get productImage() {
    const { imageUrl } = this.product || {};
    return {
      url: imageUrl
    };
  }

  public get productName() {
    return this.product?.name || this.product?.productFullName;
  }

  public productGs1SegmentId = () => {
    let res = [];

    if (this.product.gs1BrickCodeId) {
      res.push(this.product.gs1BrickCodeId);
    }

    return res;
  };

  public get isAddParameterButtonVisible() {
    const parameterFieldNames = this.getParameterFieldNames();
    return this.visibleParameters.length < parameterFieldNames.length;
  }

  public isParameterVisible(formFieldName: string): boolean {
    return this.visibleParameters.indexOf(formFieldName) > -1;
  }

  public addParameter(): void {
    const parameterFieldNames = this.getParameterFieldNames();
    for (const name of parameterFieldNames) {
      if (!this.isParameterVisible(name)) {
        this.visibleParameters.push(name);
        break;
      }
    }
  }

  public disableSegmentTreeOption(i) {
    return !i.canAssignToProduct;
  }

  public segmentTreeLabel(i) {
    return `${"--".repeat(i.level)} ${i.code} ${i.name}`.trim();
  }

  protected save(): void {}

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public closeDialog(data?: any): void {
    this.dialogRef.close(data);
  }

  public getParameterFieldNames(): string[] {
    const formValue = this.form.value;
    return Object.keys(formValue).filter(key => key.startsWith("parameter"));
  }

  public getVisibleParameters(): string[] {
    const formValue = this.form.value;
    return Object.entries(formValue)
      .filter(
        ([key, value]) =>
          key.startsWith("parameter") && !stringIsNullOrEmpty(value)
      )
      .map(([key]) => key);
  }

  public getNormalizedCodes(codes) {
    return codes
      ?.filter(x => !x.isDeleted)
      ?.map(x => ({
        id: x.id,
        group: x.group,
        isPrimary: x.isPrimary,
        type: x.type ? x.type : x.typeId ? { id: x.typeId } : null,
        format: x.format ? x.format : x.formatId ? { id: x.formatId } : null,
        value: x.value
      }));
  }

  public setSegmentValue() {
    if (this.product.retailerDetails?.segment) {
      if (
        !this.segmentOptions.find(
          x => x.id == this.product.retailerDetails.segment.id
        )
      ) {
        this.form
          .get("segment")
          .setValue(this._segmentOther, { emitEvent: false });
        this.form
          .get("segmentOther")
          .setValue(this.product.retailerDetails.segment);
        this.showOtherSegments = true;
      }
    }
  }

  protected openRetailerDetailsCopyDialog() {}
}
