import { Component, Input, Output, EventEmitter, HostBinding, OnInit, Optional } from '@angular/core';
import { FormGroup, ControlContainer } from '@angular/forms';

@Component({
  selector: 'package-info',
  templateUrl: './package-info.component.html',
  styleUrls: ['./package-info.scss']
})
export class PackageInfoComponent implements OnInit {

  @Input() public period;
  @Input() public data;
  @Input() public formGroup: FormGroup;
  @Input() public for: string;
  @Input() public isRequested: boolean;

  @Output() public chosen: EventEmitter<void> = new EventEmitter<void>();

  @HostBinding('class.active') public get isActive() {
    return this.formGroup.get(this.for).value === this.data.id;
  }

  constructor(@Optional() @Optional() private _controlContainer: ControlContainer) { }

  public ngOnInit(): void {

    if (this._controlContainer && this._controlContainer.control instanceof FormGroup) {
      this.formGroup = <FormGroup>this._controlContainer.control;
    }
  }

  public choosePackage() {
    this.formGroup.get(this.for).setValue(this.data.id);
  }
}

export interface IPackageInfo {
  type: string;
  isActive: boolean;
  validTo: string;
}
