import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input } from "@angular/core";
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { OfferType, SupplierOfferStatus, ReceivingOfferStatus, asArray, asGuidArray } from 'src/app/enum';
import { AppState } from 'src/app/state/app.state';
import { OfferService } from 'src/app/_services';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label, SingleDataSet } from 'ng2-charts';

@Component({
  selector: "dashboard-offers-card",
  templateUrl: "./dashboard-offers.card.html",
  styleUrls: ['./dashboard-offers.card.scss']
})
export class DashboardOffersCard implements OnInit, OnDestroy {

  @Input() public data;
  @Input() public title;
  @Input() public link;
  @Input() public type;

  public awaitingConfirmation: number = 0;

  public offerTypeOptions: any[] = asGuidArray(OfferType).filter(x => x.id != OfferType.Logistics.toString());
  public supplierOfferStatuses: any[] = [];
  public receivingOfferStatuses: any[] = [];

  public controls = new FormGroup({
    offerType: new FormControl(this.offerTypeOptions[0]?.id)
  });

  public hasAssortments = false;
  public hasCampaigns = false;
  public hasPriceChanges = false;
  public hasProcurements = false;

  public showLoader = true;

  // BAR CHART
  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      backgroundColor: '#fff',
      bodyFontColor: '#5e5357',
      borderColor: 'rgba(94, 83, 87, 0.55)',
      borderWidth: 1,
      cornerRadius: 3,
      caretPadding: 15,
      displayColors: true,
      enabled: true,
      intersect: true,
      mode: 'x',
      titleFontColor: '#5e5357',
      titleMarginBottom: 5,
      xPadding: 15,
      yPadding: 10,
    },
    legend: {
      position: 'bottom',
      align: 'start'
    },
    scales: {
      xAxes: [{
         gridLines: {
            display: false
         }
      }],
      yAxes: [{
        display: false,
         gridLines: {
            display: false
         }
      }]
    }
  };

  public barChartLabels: Label[] = [];
  public barChartType: ChartType = "bar";
  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData: ChartDataSets[] = [];

  private _maximumChartColumns = 12;
  private _sortChartData = true;

  private _ALLOWED_STATUSES_SUPPLIER = [
    SupplierOfferStatus.Sent, 
    SupplierOfferStatus.Rejected, 
    SupplierOfferStatus.Confirmed,
    SupplierOfferStatus.Negotiating
  ];

  private _CHART_COLORS_SUPPLIER = {
    [SupplierOfferStatus.Created]: '#fff',
    [SupplierOfferStatus.Sent]: '#71cff5',
    [SupplierOfferStatus.Negotiating]: '#f7921e',
    [SupplierOfferStatus.Confirmed]: '#18a349',
    [SupplierOfferStatus.Rejected]: '#5e5357',
    [SupplierOfferStatus.Cancelled]: '#f04b54',
    [SupplierOfferStatus.Expired]: 'white',
  }

  // PIE CHART
  public pieChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      backgroundColor: '#fff',
      bodyFontColor: '#5e5357',
      borderColor: 'rgba(94,83,87,0.15)',
      borderWidth: 1,
      cornerRadius: 3,
      caretPadding: 15,
      displayColors: true,
      enabled: true,
      titleFontColor: '#5e5357',
      titleMarginBottom: 5,
      xPadding: 15,
      yPadding: 10,
    },
    legend: {
      position: 'bottom',
      align: 'center',
    }
  };

  public pieChartLabels: Label[] = [];
  public pieChartData: SingleDataSet = [];
  public pieChartColors = [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;

  private _ALLOWED_STATUSES_RETAILER = [
    ReceivingOfferStatus.Pending, 
    ReceivingOfferStatus.Negotiating, 
    ReceivingOfferStatus.Confirmed,
    ReceivingOfferStatus.Rejected,
  ];

  private _CHART_COLORS_RETAILER = {
    [ReceivingOfferStatus.Pending]: '#71cff5',
    [ReceivingOfferStatus.Negotiating]: '#f7921e',
    [ReceivingOfferStatus.Confirmed]: '#18a349',
    [ReceivingOfferStatus.Rejected]: '#5e5357'
  }

  // 
  private _onlangChangeSubscription: Subscription;
  constructor(
    private _offerService: OfferService, 
    private _translateService: TranslateService, 
    private _appState: AppState) {

    this.supplierOfferStatuses = asArray(SupplierOfferStatus);
    this.receivingOfferStatuses = asArray(ReceivingOfferStatus);

    this.controls.get('offerType').valueChanges.subscribe(_ => {
      this._updateChart();
    })

    this._onlangChangeSubscription = this._translateService.onLangChange.subscribe(_ => {
      this._updateChart();
    })
  }

  ngOnInit() {
    this._updateChart();
    this.hasAssortments = this.data?.aggregatedValues[OfferType.MainAssortment] ? true : false;
    this.hasCampaigns = this.data?.aggregatedValues[OfferType.Campaign] ? true : false;
    this.hasPriceChanges = this.data?.aggregatedValues[OfferType.PriceChanges] ? true : false;
    this.hasProcurements = this.data?.aggregatedValues[OfferType.Procurement] ? true : false;

    setTimeout(_ => {
      this.showLoader = false;
    }, 85);

  }

  public get hasOffers() {
    return this.hasAssortments || this.hasCampaigns || this.hasPriceChanges || this.hasProcurements;
  }

  private _updateChart() {
    switch (this.type) {
      case "out":
        this._transformBarChartData();
        break;
      case "in":
        this._transformPieChartData();
        break;
    }
  }

  private _transformPieChartData(
    aggregatedValues = this.data?.aggregatedValues,
    offerType = this.controls.get('offerType').value
  ): void {
    if (aggregatedValues && offerType) { 
      const data = aggregatedValues[offerType];
      const chartDataSet: SingleDataSet = [];
      const chartLabels = [];
      const chartColors = [];
      this.awaitingConfirmation = 0;
      this.receivingOfferStatuses
      .filter(f => this._ALLOWED_STATUSES_RETAILER.includes(f.id))
      .map(s => {
          const translatedName = this._translateService.instant(s.name);
          const count = data?.[s.id] || 0;
          chartLabels.push(translatedName);
          chartDataSet.push(count);
          chartColors.push(this._CHART_COLORS_RETAILER[s.id]);
          if (s.id === ReceivingOfferStatus.Negotiating) {
            this.awaitingConfirmation += count;
          };
      });

      this.pieChartLabels = chartLabels;
      this.pieChartData = chartDataSet;
      this.pieChartColors = [{ backgroundColor: chartColors }];
    }
  }

  private _transformBarChartData(
    aggregatedValues = this.data?.aggregatedValues,
    offerType = this.controls.get('offerType').value
  ): void {
    if (aggregatedValues && offerType) {
      let data = aggregatedValues[offerType];
      if (this._sortChartData) {
        data = Object.entries(data || {})
        .splice(0, this._maximumChartColumns)
        .sort((a: any, b: any) => a[1].retailer?.shortName > b[1].retailer?.shortName ? 1 : -1)
        .reduce((accum, [k, v]) => {
          accum[k] = v;
          return accum;
        }, {});
      };
      
      const chartLabels = Object.keys(data).map(r => {
        const { retailer } = data[r];
        return retailer.shortName;
      });

      const chartData = this.supplierOfferStatuses
      .filter(f => this._ALLOWED_STATUSES_SUPPLIER.includes(f.id))
      .map(s => {
        this.awaitingConfirmation = 0;
        const translatedName = this._translateService.instant(s.name);
        const datapoints = Object.keys(data).map(r => {
          const { statusCounts } = data[r];
          this.awaitingConfirmation += statusCounts[SupplierOfferStatus.Negotiating] || 0;
          return statusCounts[s.id] || 0;
        });
        const color = this._CHART_COLORS_SUPPLIER[s.id];
        return {
            label: translatedName,
            backgroundColor: color,
            hoverBackgroundColor: color,
            borderColor: 'transparent',
            data: datapoints,
            stack: 'a',
            barPercentage: 1.1,
        }
      });

      this.barChartLabels = chartLabels;
      this.barChartData = chartData;
    };
  }

  public customColors = this.supplierOfferStatuses.map(status => {
    return {
      name: status.name,
      value: this._CHART_COLORS_SUPPLIER[status.id]
    }
  });

  ngOnDestroy() {
    this._onlangChangeSubscription.unsubscribe();
  }

  public getStatusProgress(status: SupplierOfferStatus) {
    return this._offerService.getSendingOfferStatusProgress(status);
  }

}