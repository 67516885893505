import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from 'rxjs';
import { ISegment } from '../cards/product/product-info/product-info.model';

@Injectable({ providedIn: "root" })
export class SegmentService {
    public constructor(private _http: HttpClient) {}

    public save(id: string, data: any) {
        var url = `api/segments/${id || ''}`;
        return this._http.post(url, data);
    }

    public getDetail(id: string) {

      if (!id) {
        return of(null);
      }

      return this._http.get(`api/segments/${id}`);
    }

    public getSegmentsByGpcBrickCode(id: string): Observable<ISegment[]> {
      return this._http.get<ISegment[]>(`api/gpcTree/${id}/segments`);
    }
}