import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IAttachment } from 'src/app/models';
import { MatTableDataSource } from '@angular/material/table';
import { FileService } from 'src/app/_services';
import { HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'add-image-dialog',
  host: { 'class': 'dialog' },
  templateUrl: './add-image.dialog.html'
})
export class AddImageDialog {

  public form = new FormGroup({
    name: new FormControl(this._data.attachment?.name),
    description: new FormControl(this._data.attachment?.description),
    uploadDate: new FormControl(this._data.attachment?.uploadedAt)
  });

  public files: any = [];
  public uploadInProgress = false;
  public uploadingFile = false;

  public fileList: FileList;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: IAddImageDialogData,
    public dialogRef: MatDialogRef<AddImageDialog>,
    private _fileService: FileService
  ) {
    if (this._data.attachment) {
      this.files.push({
        id: this._data.attachment.fileId,
        name: this._data.attachment.fileName,
      });
    }

  }

  public uploadFile(fileList: FileList) {
    const form = this.form;
    this.fileList = fileList;
    for (let index = 0; index < fileList.length; index++) {
      const element = fileList[index];

      const file = {
        id: '',
        name: element.name,
        progress: 0
      };

      this.files.push(file);
  
      const formData = new FormData();
      formData.append('file', element);
      this._fileService.uploadImage(formData)
        .subscribe(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round(100 * event.loaded / event.total);
              this.uploadInProgress = true;
              break;
            case HttpEventType.Response:
              file.id = event.body;
              this.uploadInProgress = false;
              break;
          }
        });
    }
    if (!form.get('name').value) {
      form.get('name').setValue(this.files[0].name);
    }
  }

  public save() {

    const data = {
      id: this._data.attachment?.id,
      name: this.form.get('name').value,
      description: this.form.get('description').value,
      fileId: this.files[0]?.id,
      fileName: this.files[0]?.name,
      uploadedAt: this._data.attachment?.uploadedAt || new Date(),
    };
    this.uploadingFile = true;

    if (this._data.attachment?.id) {
      this._data.save(data)
        .result(this.form, res => {
          // todo - success message
          
          const item = this._data.dataRef?.find(x => x.id === this._data.attachment.id);
          Object.assign(item, res);
          this.uploadingFile = false;
          this.dialogRef.close();
        });
    } else {
        this._data.add(data)
        .result(this.form, res => {
          // todo - success message

          const image = this.fileList.item(0);

          let file = {
            imageId: res.fileId,
            name: data.name,
            caption: data.description,
            src: undefined
          };
    
          if (FileReader && this.fileList?.length) {
            const fr = new FileReader();
            fr.onload = (e) => {
              file.src = fr.result;
              Object.assign(res, file);
            }
            fr.readAsDataURL(image);
          }

          this._data.dataRef?.push(res);

          this.uploadingFile = false;
          this.dialogRef.close();
        });
    }

  }

  public deleteAttachment(index: number) {
    event.stopImmediatePropagation();
    this._fileService.delete(this.files[index].id).subscribe(res => {
      this.files?.splice(index, 1);
    });
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public closeDialog() {
    this.dialogRef.close();
  }


}

export interface IAddImageDialogData {
  save: (data: IAttachment) => Observable<any>;
  add: (data: IAttachment) => Observable<any>;
  attachment: IAttachment;
  dataRef: any[];
}
