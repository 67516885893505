<lxm-dialog [dialogRef]="dialogRef" [formGroup]="form">
  <span header-title>
    {{ 'cards.offer.price_changes_info.title' | translate }}
  </span>

  <div body>

    <display-product class="dialog-display-product-sticky" [data]="product"> </display-product>

    <form-field
      dialog
      label="cards.offer.price_changes_info.label.note"
      type="textarea"
      inputClass="w60p"
      for="note"
      [readonly]="readonly"
    >
      <validation-errors
        for="priceChangesNote"
        aliasTo="note"
      ></validation-errors>
    </form-field>
  </div>

  <div footer>
    <lxm-button
      type="primary"
      label="action.save"
      (click)="save()"
      buttonType="submit"
      *ngIf="!readonly"
    ></lxm-button>
    <lxm-button
      type="secondary"
      [class.ml15]="!readonly"
      label="action.cancel"
      (click)="closeDialog()"
    ></lxm-button>
  </div>
</lxm-dialog>
