import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { NotificationType } from "src/app/enum";
import { NotificationBuilder } from 'src/app/_helpers';
import { NotificationsService } from "src/app/_services";

@Component({
  selector: "dashboard-notifications-card",
  templateUrl: "./notifications.card.html",
  styleUrls: ['./notifications.card.scss']
})
export class NotificationsCard {

  @Input() public data;

  constructor(private _notificationService: NotificationsService, private _router: Router) {}

  public get hasNotifications() {
    return this.data?.items.length > 0;
  }

  public navigate(notification) {
    let id = notification.id;
    if (!id) {
      if (notification.type === NotificationType.ReleaseNotes || notification.type === NotificationType.Procurement) {
        this._notificationService
          .getNotificationUrl(notification.previewUrl)
          .result(null,
            res => {
              this._router.navigate([`/notifications/${res.id}`]);
            }, 
            e => { 
              console.error(e) 
            }
          );
      }
      return;
    }

    this._router.navigate([`/notifications/${id}`]);
  }

}


@Component({
  selector: "card-notification",
  templateUrl: "./template/notification.html",
  host: { 'class': 'card-notification' },
  styleUrls: ['./template/notification.scss']
})
export class CardNotification {

  constructor(private _builder: NotificationBuilder) {}

  @Input() public data;

  public getNotificationPreview(row) {
    return this._builder.getPreview(row, true);
  }

  public getNotificationHeader(row) {
    return this._builder.getHeader(row, false);
  }

  public getNotificationSubject(row) {
    return this._builder.getSubject(row, false);
  }

}
