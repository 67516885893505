export enum Gs1CodeCheckResponseType {
    Other = -1,
    Found = 0,
    Found_CountryInternal = 1,
    NotFound_ExactMatch = 10,
    NotFound_CannotCheck_ExternalOrganization = 12,
    Other_CannotCheck_Error = 13,
    NotFound_CannotCheck_CompanyInternal = 14,
    InvalidCode_NotAllowed = 20,
    InvalidCode_Error = 21
  }
  
  (Gs1CodeCheckResponseType as any).__typeName = 'Gs1CodeCheckResponseType';
  