export enum ClientDiscountRuleType {
  General = 1,
  Gs1Segment = 2,
  StorageConditions = 3,
  Brand = 4
}

(ClientDiscountRuleType as any).__typeName = 'ClientDiscountRuleType';

// const _ = 'cards.offer.base_info.label.';
// (ClientDiscountRuleType as any).__altTranslationKeys = {
//   [ClientDiscountRuleType.General] : `${_}general`,
//   [ClientDiscountRuleType.Gs1Segment] : _ + 'offer_name',
//   [ClientDiscountRuleType.StorageConditions] : _ + 'offer_description',
//   [ClientDiscountRuleType.Brand] : _ + 'offer_date_from'
// };
