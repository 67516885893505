import { Component, Input } from '@angular/core';

@Component({
  selector: 'welcome-card',
  templateUrl: './welcome.card.html',
  styleUrls: ['./welcome.card.scss']
})
export class WelcomeCard {

  public userLocationsRenderBaseCount = 3;
  public userLocationsRenderCount = this.userLocationsRenderBaseCount;

  @Input() public data: any = {};

  public logoSrc = '/assets/img/icons/no-content/no-image.svg';

  public ngOnInit() {
    this.getLogoSrc(this.data.logo, this.data.logoContentType);
  }

  public getLogoSrc(thumbnailSource?: string, thumbnailContentType?: string) {
    if (thumbnailSource && thumbnailContentType) {
      this.logoSrc = `data:${thumbnailContentType};base64,${thumbnailSource}`;
    }
  }

  public toggleAllUserLocations() {
    if (this.userLocationsRenderCount === this.userLocationsRenderBaseCount) {
      if (this.data.locations?.length > 0) {
        this.userLocationsRenderCount = this.data.locations?.length;
      }
    } else {
      this.userLocationsRenderCount = this.userLocationsRenderBaseCount;
    }
  }

  public get phoneCode() {
    return this.data?.phoneCode;
  }

  public get phoneNumberShort() {
    return this.data?.phoneNumberShort;
  }

}
