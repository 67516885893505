<div class="flex flex-row" [class.readonly]="readonly">
  <button class="toggle-button greyed-button" [class.toggled]="!formGroup.get(for).value" (click)="setToggle(false)">
    <span *ngIf="!readonly" class="toggle-indicator" [class.campaign]="campaign === 1"></span>
    <type-indicator *ngIf="readonly" [type]="campaign === 1 ? 'campaign' : 'weekly'"></type-indicator>
    <ng-content select="[text1]"></ng-content>
  </button>
  <button class="toggle-button greyed-button ml15" [class.toggled]="formGroup.get(for).value" (click)="setToggle(true)">
    <span *ngIf="!readonly" class="toggle-indicator" [class.campaign]="campaign === 2"></span>
    <type-indicator *ngIf="readonly" [type]="campaign === 2 ? 'campaign' : ''"></type-indicator>
    <ng-content select="[text2]"></ng-content>
  </button>
</div>
