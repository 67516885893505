<lxm-dialog [dialogRef]="dialogRef" [formGroup]="form" #dialog>
    <span header-title>{{'cards.lexi_market_settings.lxm_settings_user_edit.title' | translate}}</span>

    <div body style="padding-bottom: 40px;" [readonly]="!canEdit">
        <scope translationsPath="cards.lexi_market_settings.lxm_settings_user_edit">

            <form-field dialog label="user_first_name" for="firstName" type="input-text" [required]="canEdit">
                <validation-errors for="firstName"></validation-errors>
            </form-field>

            <form-field dialog label="user_last_name" for="lastName" type="input-text" [required]="canEdit">
                <validation-errors for="lastName"></validation-errors>
            </form-field>

            <form-field dialog label="user_email" for="email" type="input-text" [required]="canEdit">
                <validation-errors for="email"></validation-errors>
            </form-field>

            <form-field dialog label="user_job_title" for="jobTitle" type="input-text" [recommended]="canEdit">
                <validation-errors for="jobTitle"></validation-errors>
            </form-field>

            <form-field dialog label="user_portfolio_description">
                <language-text language-text for="portfolioDescription" newline="false"></language-text>
            </form-field>

            <form-field dialog label="user_id_code" for="idCode" type="input-text">
                <validation-errors for="idCode"></validation-errors>
            </form-field>

            <form-field dialog label="user_phone_short" [recommended]="canEdit">
                <span any row class="w-full">
                    <lxm-select class="dialog-input-fixed-width-1" [searchable]="true"
                        [options]="data.formData?.phoneCodes" labelField="value" value="id" for="phoneCode">
                    </lxm-select>
                    <!-- <input type="text" class="dialog-field-text dialog-input-fixed-width-3 ml10" formControlName="phoneNumberShort" /> -->
                    <input type="text" class="lxm-input ml10" formControlName="phoneNumberShort" />
                </span>
                <validation-errors for="phoneCode"></validation-errors>
                <validation-errors for="phoneNumberShort"></validation-errors>
                <validation-errors for="phoneNumber"></validation-errors>
            </form-field>

            <form-field dialog label="user_role" [required]="canEdit">
                <lxm-select select class="w-full" [searchable]="true" [options]="data.formData?.roles" value="id" for="roles" [multiple]="true"></lxm-select>
                <validation-errors for="roles"></validation-errors>
                <validation-errors for="roleIds"></validation-errors>
            </form-field>

            <form-field dialog label="assistants">
                <lxm-select select for="assistants" 
                  class="w-full"
                  labelField="label"                  
                  [searchable]="true"
                  [multiple]="true"
                  [options]="assistantsOptions"
                  value="id">
                </lxm-select>
                <validation-errors for="assistants"></validation-errors>
            </form-field>
            
            <form-field *ngIf="showLocations" dialog label="user_locations">
                <lxm-select select class="w-full" [searchable]="true" [options]="data.formData?.locations" value="id" for="locations" [multiple]="true"></lxm-select>
                <validation-errors for="locations"></validation-errors>
                <validation-errors for="locationIds"></validation-errors>
            </form-field>
        </scope>
    </div>

    <div footer class="justify-between">

      <div class="flex flex-row">
        <lxm-button type="primary" *ngIf="canEdit"
                    label="cards.lexi_market_settings.lxm_settings_user_edit.action.save"
                    class="mr10"
                    (click)="save()"
                    [loading]="saveLoading"
                    buttonType="submit">
        </lxm-button>
        <lxm-button type="secondary"
                    class="mr10"
                    label="cards.lexi_market_settings.lxm_settings_user_edit.action.cancel"
                    (click)="closeDialog()">
        </lxm-button>
      </div>

      <div class="flex flex-row">
        <lxm-button *ngIf="data.user?.id && canEdit"
                    type="transparent"
                    icon="trash"
                    [loading]="deleteLoading"
                    label="cards.lexi_market_settings.lxm_settings_user_edit.action.remove_user"
                    (click)="delete()">
        </lxm-button>
      </div>

    </div>

</lxm-dialog>
