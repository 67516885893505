import {
  Component,
  OnInit,
  AfterViewInit,
  Optional,
  Input,
  ViewChild,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { ControlContainer, FormGroup } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ISupplierBasePriceScheme } from "src/app/cards/product/product-pricelist/product-pricelist.model";
import { forkJoin, Observable, of } from "rxjs";
import {
  ProductPricelistService,
  OfferService,
  AuthenticationService,
} from "src/app/_services";
import {
  calculateMargin,
  calculatePriceChange,
  calculateMarkup,
  calculateUnitPrice,
} from "src/app/_helpers/calculate";
import {
  OfferType,
  UserAction,
  OfferProductStatus,
  LxmAppModule,
  OfferKind,
  CampaignTypeVatType,
  CampaignTypeValueType,
} from "src/app/enum";
import {
  IOfferCard,
  IOfferCardDetail,
  IOfferCardFormData,
} from "src/app/models";
import { LxmDialog, LxmMessage } from "src/app/_helpers";
import { appSettings } from "src/app/app.settings";
import { ContentLanguage } from "src/app/_helpers/content-language";
import { nullIfUndefined } from "src/app/util/fn";
import {
  IClvDto,
  IProductUnitCodeType,
  ISegmentTreeOption,
} from "src/app/cards/product/product-info/product-info.model";
import { AppState } from "src/app/state/app.state";
import { numberUtil } from "src/app/util/number-util";
import { OfferProductProcurementDetailDialogComponent } from "../dialog/offer-product-procurement-detail/offer-product-procurement-detail.dialog";
import moment from "moment";
import { OfferProductOrderDetailDialogComponent } from "../dialog/offer-product-order-detail/offer-product-order-detail.dialog";
import { OfferProductMassChangesDialogComponent } from "../dialog/offer-product-mass-changes/offer-product-mass-changes.dialog";
import { MatTable } from "@angular/material/table";
import { MarkupMethod } from "src/app/enum/markup-mehtod";
import { ProductPriceChangesInfoDialogComponent } from "../dialog/offer-product-price-changes/offer-product-price-changes.dialog";
import { ProductRetailerDetailsDialogComponent } from "../dialog/receiving-offer-product-retailer-details/receiving-offer-product-retailer-details.dialog";
import { ProductCampaignInfoDialogComponent } from "../dialog/supplier-offer-product-campaign/supplier-offer-product-campaign.dialog";
import { OfferProductAssortmentAndPricesDialog } from "../dialog/offer-product-assortment-and-prices/offer-product-assortment-and-prices.dialog";
import { OfferProductsListBase } from "../offer-products-list-base";
import { CalendarGroupCampaignType } from "src/app/enum/calendar-group-campaign-type";

@Component({
  selector: "receiving-offer-products-list",
  templateUrl: "./receiving-offer-products.component.html",
  styleUrls: [
    "../offer-products.component.scss",
    "./receiving-offer-table.scss",
  ],
})
export class ReceivingOfferProductsListComponent
  extends OfferProductsListBase
  implements OnInit, AfterViewInit, OnChanges
{
  private static _cols = [
    //'offer_product_expander',
    "replacement_indicator",
    "offer_product_select",
    "offer_product_image",
    "offer_product_name",
    // 'offer_product_brand',
    "offer_product_available_from",
    // 'offer_product_internal_price',
    // 'offer_product_list_price',
    // 'offer_product_price_retailer',
    "offer_product_buy_in_price",
    "offer_product_requested_price",
    // 'offer_product_requested_price_markup',
    // 'offer_product_offer_margin',
    "offer_product_recommended_retail_price",
    "offer_product_retail_price",
    // 'offer_product_retail_price_markup',
    "offer_details",
    "actions",
  ];

  private static _campaignCols = [
    //'offer_product_expander',
    "offer_product_select",
    "offer_product_image",
    "offer_product_name",
    "offer_product_display",
    "offer_product_limit",
    // 'offer_product_internal_price',
    "offer_product_main_assortment_price",
    "offer_product_price_retailer",
    // 'offer_product_offer_margin',
    "offer_product_recommended_retail_price",
    "offer_campaign",
    "offer_details",
    "actions",
  ];

  private static _campaignWithTypesCols = [
    "offer_product_select",
    "offer_product_image",
    "offer_product_name",
    "offer_product_campaign_type",
    "offer_product_main_assortment_price_campaign_with_types",
    "offer_product_price_retailer_campaign_with_types",
    "offer_product_campaign_recommended_retail_price_and_retail_price_discount",
    "offer_product_campaign_retail_price_with_vat",
    "offer_product_campaign_retail_price_info",
    "offer_campaign",
    "offer_details",
    "actions",
  ];

  private static _logisticsCols = [
    //'offer_product_expander',
    "offer_product_image",
    "offer_product_name",
    "offer_product_available_from",
    "offer_product_segment",
    "offer_product_storage_conditions",
    "offer_product_transport_unit_ean",
    "offer_product_pallet_type",
    "offer_details",
    "actions",
  ];

  private static _priceChangesCols = [
    "offer_product_select",
    "offer_product_image",
    "offer_product_name",
    "offer_product_price_change_date",
    "offer_product_current_price",
    "offer_product_new_buy_in_price",
    "offer_product_requested_price",
    // 'offer_product_requested_price_markup',
    "offer_product_retail_price",
    // 'offer_product_retail_price_markup',
    "offer_product_price_change_reason",
    "offer_product_price_change_note",
    "offer_details",
    "actions",
  ];

  private static _procurementCols = [
    //'offer_product_expander',
    "offer_product_select",
    "offer_product_image",
    "offer_product_name",
    // 'offer_product_brand',
    "offer_product_available_from",
    // 'offer_product_internal_price',
    // 'offer_product_list_price',
    // 'offer_product_price_retailer',
    "offer_product_buy_in_price",
    "offer_product_requested_price",
    // 'offer_product_requested_price_markup',
    // 'offer_product_offer_margin',
    "offer_product_recommended_retail_price",
    "offer_product_retail_price",
    // 'offer_product_retail_price_markup',
    "offer_product_procurement_note",
    "offer_details",
    "actions",
  ];

  public UserAction = UserAction;
  public OfferProductStatus = OfferProductStatus;
  public OfferType = OfferType;
  public priceChangeReasons: IClvDto[];
  public attributes: IClvDto[];
  public productTags: IClvDto[];
  public productUnitCodeTypes: IProductUnitCodeType[];
  public segmentTreeOptions: ISegmentTreeOption[];
  public tenantColumns: {};
  public productBrands = [];
  public productSubBrands = [];
  public countries = [];
  public units = [];

  @Input() public showTotal = true;
  @Input() public isKnownSupplier = false;
  @Input() public campaignType: CalendarGroupCampaignType;

  @Output() public requestBindSupplierDialog = new EventEmitter();

  public translationsPath = "cards.offer.products.table";

  public canViewProducts = false;

  public hasBuyerPimExtensions = false;
  public canManageRetailerDetails = false;
  public canManageRetailerAssortment = false;
  public canManageRetailerSupplierPrices = false;
  public canManageRetailerProcurement = false;

  public selectionKey = "productId";

  public markupMethod: MarkupMethod;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("table") table: MatTable<any>;

  protected get _defaultItem(): any {
    return {
      productId: null,
      preview: {},
      campaignInfo: {},
      name: null,
      display: false,
      limit: null,
      ean: null,
      supplierCode: null,
      brand: null,
      availableFrom: null,
      listPrice: null,
      vat: null,
      mainAssortmentCurrency: null,
      mainAssortmentPrice: null,
      salePrice: null,
      salesUnitId: null,
      suggestedRetailPrice: null,
      shops: [],
      assortmentStatus: null,
      isDeleted: false,
      isNew: true,
      imageUrl: null,
      thumbnailUrl: null,
      thumbnail: null,
      thumbnailContentType: null,

      contentUnitId: null,
      netContent: null,
      drainedWeightInGrams: null,

      requestedPriceInput: null,
      requestedPrice: null,
      retailPrice: null,

      segment: null,
      storageConditions: null,
      storageMinTemp: null,
      storageMaxTemp: null,
      transportUnitDefaultEan: null,
      palletType: null,
      isValid: true,
      replacementForProductId: null,
      isSuspended: false,
    };
  }

  @Input() public offer: IOfferCardDetail;
  @Input() public offerId: string;
  @Input() public currency: IClvDto;
  @Input() public date: Date;
  @Input() public dateTo: Date;
  @Input() public retailerId: string;
  @Input() public offerType: OfferType;
  @Input() public countryCode: string;
  @Input() public showNoContentText = true;
  @Input() public readonly = true;
  @Input() public canOrder = false;

  public expandedElements = [];
  public expanders = [];
  public campaignTypes: IClvDto[];

  public get columns(): string[] {
    const columns: string[] = this.tenantColumns?.[this.offerType];
    switch (this.offerType) {
      case OfferType.MainAssortment:
        return columns ? columns : ReceivingOfferProductsListComponent._cols;

      case OfferType.Campaign:
        if (columns) {
          return columns;
        }
        if (
          this.campaignType ==
          CalendarGroupCampaignType.RetailSalesPriceDiscount
        ) {
          return ReceivingOfferProductsListComponent._campaignWithTypesCols;
        }
        return ReceivingOfferProductsListComponent._campaignCols;

      case OfferType.Logistics:
        return columns
          ? columns
          : ReceivingOfferProductsListComponent._logisticsCols;
      case OfferType.PriceChanges:
        return columns
          ? columns
          : ReceivingOfferProductsListComponent._priceChangesCols;
      case OfferType.Procurement:
        return columns
          ? columns
          : ReceivingOfferProductsListComponent._procurementCols;
      default:
        return [];
    }
  }

  constructor(
    route: ActivatedRoute,
    private _appState: AppState,
    private _router: Router,
    private _dialog: LxmDialog,
    private _offerService: OfferService,
    private _pricesService: ProductPricelistService,
    private _authenticationService: AuthenticationService,
    private _message: LxmMessage,
    public contentLanguage: ContentLanguage,
    @Optional() controlContainer: ControlContainer,
  ) {
    super(controlContainer, contentLanguage);

    const offerCard = route.snapshot.data.offerCard as IOfferCard;
    if (offerCard) {
      this.offer = offerCard.offerDetail;
      this.offerId = offerCard.offerDetail.id;
    }

    const formData = route.snapshot.data.offerCard
      .formData as IOfferCardFormData;
    if (formData) {
      this.priceChangeReasons = formData.priceChangeReasons;
      this.attributes = formData.attributes;
      this.productTags = formData.productTags;
      this.productUnitCodeTypes = formData.productUnitCodeTypes;
      this.segmentTreeOptions = formData.segmentTreeOptions;
      this.tenantColumns = formData.productColumns;
      this.markupMethod = formData.markupMethod;
      this.productBrands = formData.productBrands;
      this.productSubBrands = formData.productSubBrands;
      this.countries = formData.countries;
      this.units = formData.units;
      this.campaignTypes = formData?.campaignTypes || [];
    }
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.canViewProducts = this._appState.hasRight([UserAction.ViewMarket]);
    this.hasBuyerPimExtensions = this._appState.hasModule(
      LxmAppModule.BuyerPimExtensions,
    );

    this.canManageRetailerDetails = this.hasBuyerPimExtensions;
    this.canManageRetailerAssortment = this.hasBuyerPimExtensions;
    this.canManageRetailerSupplierPrices = this.hasBuyerPimExtensions;
    this.canManageRetailerProcurement = this.hasBuyerPimExtensions;
  }

  public get hasBuyerCrm() {
    return this._appState.hasModule(LxmAppModule.BuyerCrm);
  }

  public isRowInvalid(row: FormGroup): boolean {
    return false;
  }

  public openMassChangesDialog() {
    const selectionMap = this.selection.map((x: FormGroup) => x.value);

    this._dialog.open(
      OfferProductMassChangesDialogComponent,
      {
        width: "740px",
        height: "auto",
        data: {
          products: selectionMap,
          attributes: this.attributes,
          productUnitCodeTypes: this.productUnitCodeTypes,
          segmentTreeOptions: this.segmentTreeOptions,
        },
      },
      (res) => {
        if (!res) {
          return;
        }

        const { tags, attributes, segment } = res || {};
        const selectionIds = selectionMap.map((x) => x.id);

        this.dataSource.data.forEach((x) => {
          const itemId = x.value.id;
          const isItemSelected = selectionIds.includes(itemId);
          if (isItemSelected) {
            let details = x.value.retailerDetails;

            details.segment = segment ?? null;
            details.tags = tags ?? null;
            details.attributes = attributes ?? null;

            for (let i = 0; i < 10; i++) {
              const paramName = `parameter${i}`;
              const formValue = res[paramName];
              if (formValue) {
                details[paramName] = formValue;
              }
            }

            x.get("retailerDetails").patchValue(details, { emitEvent: false });
          }
        });
      },
    );
  }

  public ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;

    const arr = this.dataSource.data;
    for (let i = 0; i < arr.length; i++) {
      arr[i].get("priceChangeDate").valueChanges.subscribe((val) => {
        const date = val || this.date;
        const productId = arr[i].get("productId").value;

        const offerProductRelationsObservable =
          this.offerType == OfferType.PriceChanges && this.offerId && date
            ? this._offerService.getOfferProductRelations(
                [productId],
                date,
                this.offerId,
              )
            : of({});

        offerProductRelationsObservable.subscribe((res) => {
          arr[i]
            .get("activeCampaignPriceSchemes")
            .setValue(res?.activeCampaignPriceSchemes?.[productId]);
        });
      });

      arr[i].get("limit").valueChanges.subscribe((val) => {
        var campaignInfo = arr[i].get("campaignInfo");
        if (campaignInfo) {
          campaignInfo.value.limit = val;
        }
      });

      arr[i].get("retailPrice").valueChanges.subscribe((val) => {
        const vat = arr[i].get("vat").value?.numericValue || 0;
        const priceWithoutVat = numberUtil.excludeVat(val, vat);
        arr[i]
          .get("retailPriceWithoutVat")
          .setValue(priceWithoutVat, { emitEvent: false });
      });

      arr[i].get("retailPriceWithoutVat").valueChanges.subscribe((val) => {
        const vat = arr[i].get("vat").value?.numericValue || 0;
        const priceWithVat = numberUtil.applyVat(val, vat);
        arr[i].get("retailPrice").setValue(priceWithVat, { emitEvent: false });
      });
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    const dateChanged =
      changes?.date &&
      changes.date.previousValue?.toISOString() !==
        changes.date.currentValue?.toISOString();
    const countryCodeChanged =
      changes?.countryCode &&
      changes.countryCode.previousValue !== changes.countryCode.currentValue;
    const retailerIdChanged =
      changes?.retailerId &&
      changes.retailerId.previousValue !== changes.retailerId.currentValue;
    if (dateChanged || countryCodeChanged || retailerIdChanged) {
      const productIds = this.dataSource.data.map((x) => x.value.productId);
      this._updatePrices(productIds);
    }

    if (this.table) {
      // Fix sticky columns on readonly change
      setTimeout((_) => {
        this.table.updateStickyColumnStyles();
        this.table.renderRows();
      });
    }
  }

  public updatePrices() {
    this._offerService.updatePrices(this.offerId).subscribe((res) => {
      const arr = this.dataSource.data;
      for (let i = 0; i < arr.length; i++) {
        const productId = arr[i].value.productId;
        if (!res.hasOwnProperty(productId)) {
          continue;
        }

        arr[i].get("listPrice").setValue(res[productId]?.listPrice || null);
        arr[i]
          .get("mainAssortmentPrice")
          .setValue(res[productId]?.mainAssortmentPrice || null);
      }

      // this.anyPriceValueChanged = false;

      this._message.ok({
        message: "cards.offer.products.prices_updated",
      });
    });
  }

  private _updatePrices(productIds: string[]) {
    const basePrices = this._getBasePrices(productIds);
    basePrices.subscribe((res) => {
      const arr = this.dataSource.data;
      for (let i = 0; i < arr.length; i++) {
        const productId = arr[i].value.productId;
        if (!res.hasOwnProperty(productId)) {
          continue;
        }

        arr[i].get("assortmentStatus").setValue(res[productId]?.status || null);
        arr[i]
          .get("listPrice")
          .setValue(res[productId]?.publicSalesPrice || null);
        arr[i]
          .get("mainAssortmentPrice")
          .setValue(
            res[productId]?.mainAssortmentPrice ||
              res[productId]?.publicSalesPrice ||
              null,
          );
        arr[i].get("vat").setValue(res[productId]?.vat || null);
      }
    });
  }

  private _getBasePrices(
    productIds: string[],
  ): Observable<{ [productId: string]: ISupplierBasePriceScheme }> {
    return !this.retailerId || !this.date || !productIds.length
      ? of(Object.assign({}, ...productIds.map((x) => ({ [x]: null }))))
      : this._pricesService.getProductPrices(
          this.retailerId,
          this.date,
          productIds,
          null,
        );
  }

  protected _getNormalizedItem(item: any) {
    if (item.isNormalized) {
      return item;
    }

    return {
      id: item.id,
      productId: item.productId,
      gs1BrickCodeId: item.gs1BrickCodeId,
      preview: item.preview || {},
      campaignInfo: item.campaignInfo || {},
      name: this.contentLanguage.get(item.name),
      display: item.display || false,
      limit: item.campaignInfo?.limit || null,
      ean: item.ean,
      supplierCode: item.supplierCode,
      brand: item.brand?.value || null,
      availableFrom: item.availableFrom?.editableDate || null,
      listPrice: item.listPrice,
      salePrice: item.salePrice,
      salesUnitId: item.salesUnitId,
      vat: item.vat,
      mainAssortmentCurrency: item.mainAssortmentCurrency,
      mainAssortmentPrice: item.mainAssortmentPrice,
      suggestedRetailPrice: item.suggestedRetailPrice,
      shops: item.shops || [],
      assortmentStatus: item.assortmentStatus,
      isDeleted: item.isDeleted || false,
      isNew: item.isNew || false,
      isNormalized: true,
      thumbnailUrl: item.thumbnailUrl,
      thumbnail: item.thumbnail,
      thumbnailContentType: item.thumbnailContentType,
      imageUrl: item.imageUrl,

      contentUnitId: item.contentUnitId,
      netContent: item.netContent,
      drainedWeightInGrams: item.drainedWeightInGrams,

      requestedPriceInput: item.requestedPrice,
      requestedPrice: item.requestedPrice,
      retailPrice: item.retailPrice,
      retailPriceWithoutVat: item.retailPriceWithoutVat,
      accepted: item.status === OfferProductStatus.Confirmed,
      rejected: item.status === OfferProductStatus.Rejected,
      editRequestedPrice: false,

      segment: nullIfUndefined(item.logisticsInfo?.segment),
      storageConditions: nullIfUndefined(item.logisticsInfo?.storageConditions),
      storageMinTemp: nullIfUndefined(item.logisticsInfo?.storageMinTemp),
      storageMaxTemp: nullIfUndefined(item.logisticsInfo?.storageMaxTemp),
      transportUnitDefaultEan: nullIfUndefined(
        item.logisticsInfo?.transportUnitDefaultEan,
      ),
      palletType: nullIfUndefined(item.logisticsInfo?.palletType),

      mainAssortmentListPrice: item.mainAssortmentListPrice,

      priceChangeDate: nullIfUndefined(
        item.priceChangesInfo?.priceChangeDate?.editableDate,
      ),
      priceChangeReason: nullIfUndefined(
        item.priceChangesInfo?.priceChangeReason,
      ),
      priceChangeNote: nullIfUndefined(item.priceChangesInfo?.priceChangeNote),
      activeCampaignPriceSchemes: nullIfUndefined(
        item.priceChangesInfo?.activeCampaignPriceSchemes,
      ),

      retailerDetails: item.retailerDetails || {},
      retailerAssortment: item.retailerAssortment || {},
      retailerSupplierPriceScheme: item.retailerSupplierPriceScheme || {},
      orderDetails: item.orderDetails || {},
      procurementDetails: item.procurementDetails || {},

      isSuspended: item.isSuspended,
      status: item.status,
      isValid: item.isValid,
      replacementForProductId: item.replacementForProductId,

      campaignType: this.campaignTypes?.find(
        (x) => x.id == item.campaignTypeId,
      ),
      validRetailerProductBuyInPriceScheme:
        item.validRetailerProductBuyInPriceScheme,
      editCampaignSalePrice: false,
      discountCampaignInput: null, //this.calculateDiscount(item.salePrice, this.getCampaignDiscountPrice(this.getMainAssortmentPrice(item), this.getListPrice(item))),
      salePriceCampaignInput: item.salePrice,
    };
  }

  public toggleExpandRow(row: any) {
    if (this.expandedElements.includes(row)) {
      const index = this.expandedElements.indexOf(row);
      this.expandedElements.splice(index, 1);
    } else {
      this.expandedElements.push(row);
    }
  }

  public productClick(productId: string) {
    if (!this.canViewProducts) {
      return;
    }
    const url = this._router.createUrlTree(["/market", productId]).toString();
    window.open(window.location.origin + url, "_blank");
  }

  public calculateMargin(salesPrice, listPrice) {
    return calculateMargin(salesPrice, listPrice);
  }

  public calculateRequestedPriceMarkup(
    retailPrice: number,
    requestedPrice: number,
    vatPercent: number,
  ) {
    return calculateMarkup(
      retailPrice,
      requestedPrice,
      vatPercent,
      this.markupMethod,
    );
  }

  public calculateRetailPriceMarkup(
    retailPrice: number,
    salePrice: number,
    vatPercent: number,
  ) {
    return calculateMarkup(
      retailPrice,
      salePrice,
      vatPercent,
      this.markupMethod,
    );
  }

  public isEmpty(o: any) {
    return (
      o &&
      Object.keys(o)
        .map((x) => o[x])
        .filter((x) => x !== "" && x !== null && x !== undefined).length === 0
    );
  }

  public hasExistingCampaignInfo(row: any) {
    if (!row.campaignInfo) {
      return false;
    }
    const {
      addressed,
      campaignNr,
      comments,
      displaySize,
      invoiceRecipient,
      limit,
      marketingFee,
      netting,
      returnableItems,
      storeCount,
      withAd,
    } = row.campaignInfo;

    return (
      addressed ||
      campaignNr ||
      comments ||
      displaySize ||
      invoiceRecipient ||
      limit ||
      marketingFee ||
      netting ||
      returnableItems ||
      storeCount ||
      withAd
    );
  }

  public getProcurementIcon(row: any) {
    const { procurementDetails } = row.value || {};
    return this.isEmpty(procurementDetails)
      ? "retailer-supplier-prices-info-empty-without-procurement"
      : "retailer-supplier-prices-info-filled-with-procurement";
  }

  public openProcurementDialog(row: any) {
    const dialogRef = this._dialog.open(
      OfferProductProcurementDetailDialogComponent,
      {
        ...appSettings.DIALOG_SIZES.M,
        data: {
          product: row.value,
          readonly: true,
        },
      },
      (res) => {
        if (!res) {
          return;
        }
        row.get("procurementDetails").setValue(res);
      },
    );
  }

  public openCampaignDialog(row: any) {
    this._dialog.open(
      ProductCampaignInfoDialogComponent,
      {
        ...appSettings.DIALOG_SIZES.L,
        data: {
          retailerId: this.retailerId,
          product: row.value,
          readonly: this.readonly,
          offerKind: OfferKind.ReceivingOffer,
        },
      },
      (res) => {
        if (!res) {
          return;
        }

        row.get("campaignInfo").setValue(res);
        row.get("limit").setValue(res?.limit);
      },
    );
  }

  public openOrderDialog(row: any) {
    this._dialog.open(
      OfferProductOrderDetailDialogComponent,
      {
        ...appSettings.DIALOG_SIZES.M,
        data: {
          product: row.value,
          readonly: this.readonly,
        },
      },
      (res) => {
        if (!res) {
          return;
        }
      },
    );
  }

  public getAssortmentAndPricesIcon(row: any): string {
    const { retailerAssortment } = row || {};
    const { assortmentGroups, decisionId, statusId, validFrom } =
      retailerAssortment || {};

    // const hasDecision = retailerAssortment?.decisionId ? true : false;
    const hasAssortmentGroups = (assortmentGroups || []).length > 0;
    // const hasStatus = nullIfUndefined(statusId) !== null;
    // const hasValidFrom = nullIfUndefined(validFrom) !== null;

    if (!hasAssortmentGroups) {
      return "assortment-scheme";
    }
    if (hasAssortmentGroups) {
      const hasDecision = nullIfUndefined(decisionId) !== null;
      // this._appState.hasModule(LxmAppModule.PurchaseManagerDesktop) &&
      if (!hasDecision) {
        return "assortment-scheme-filled-without-decision";
      }
      // return 'assortment-scheme-partly-filled';
    }
    return "assortment-scheme-filled";
  }

  public getRetailerDetailsIcon(row: any): string {
    const { retailerDetails } = row || {};
    if (!retailerDetails || retailerDetails.segment === null) {
      return "product-info";
    }
    if (retailerDetails.hasInvalidTranslatedValues) {
      return "product-info-partly-filled";
    }
    return "product-info-filled";
  }

  public openRetailerDetailsDialog(row: any) {
    this._dialog.open(
      ProductRetailerDetailsDialogComponent,
      {
        width: "80vw",
        minWidth: 1230,
        height: "auto",
        data: {
          product: row.value,
          attributes: this.attributes,
          productTags: this.productTags,
          productUnitCodeTypes: this.productUnitCodeTypes,
          segmentTreeOptions: this.segmentTreeOptions,
          readonly: this.readonly,
          productBrands: this.productBrands,
          productSubBrands: this.productSubBrands,
          countries: this.countries,
          units: this.units,
        },
      },
      (res) => {
        if (!res) {
          return;
        }

        row.get("retailerDetails").setValue(res);
      },
    );
  }

  public openProductAssortmentAndPricesDialog(row: any) {
    if (this.hasBuyerCrm) {
      if (!this.readonly && !this.isKnownSupplier) {
        this.requestBindSupplierDialog.emit();
        return;
      }
    }

    const offer = this.offer;

    let validFrom: any = this.date;
    if (this.offerType == OfferType.PriceChanges) {
      validFrom = row.value.priceChangeDate;
    } else if (this.offerType == OfferType.Procurement) {
      validFrom = moment(this.offer?.procurement.supplyFrom);
    }

    const retailerAssortment = row.get("retailerAssortment").value;
    if (retailerAssortment?.decisionId) {
      validFrom = moment(retailerAssortment.validFrom);
    }

    Object.assign(offer, {
      validFrom: validFrom,
      validTo: this.dateTo,
      isCampaign: this.offerType == OfferType.Campaign,
    });

    this._dialog.open(
      OfferProductAssortmentAndPricesDialog,
      {
        width: "90vw",
        minWidth: 1200,
        height: "90vh",
        data: {
          product: row.value,
          offer: offer,
          readonly: this.readonly,
        },
        resolve: () => {
          const rowValue = row.value;

          const offerId = this.offerId;
          const offerProductId = rowValue.id;
          const schemeId = rowValue.retailerSupplierPriceScheme?.id;
          const assortmentId = rowValue.retailerAssortment?.id;
          const segmentId = rowValue.retailerDetails?.segment?.id;

          const resolvers: any = {
            formData:
              this._offerService.getOfferProductRetailerAssortmentAndPriceSchemeFormData(
                offerId,
                offerProductId,
                {
                  offerId: offerId,
                  shemeId: schemeId,
                  offerProductId: offerProductId,
                  segmentId: segmentId,
                },
              ),
            assortmentAndPriceSchemeData:
              this._offerService.getOfferProductRetailerAssortmentAndPriceScheme(
                offerId,
                offerProductId,
                {
                  schemeId: schemeId,
                  offerId: offerId,
                  offerProductId: offerProductId,
                  assortmentId: assortmentId,
                },
              ),
          };

          return forkJoin(resolvers);
        },
      },
      (res) => {
        if (!res) {
          return;
        }

        const { assortment, priceScheme } = res || {};

        if (assortment) {
          const { assortmentGroups, id, validFrom, status, decisionId } =
            assortment || {};

          const retailerAssortment = {
            assortmentGroups: assortmentGroups,
            decisionId: decisionId,
            id: id,
            status: status,
            statusId: status?.id,
            validFrom: validFrom,
          };

          row.get("retailerAssortment").setValue(retailerAssortment);
        }

        if (priceScheme) {
          const {
            retailPrices,
            buyInPrice,
            listPrice,
            vat,
            logisticsPartner,
            buyInPriceCurrency,
          } = priceScheme || {};
          const mainRetailPrice = retailPrices?.find(
            (x) => x.currency?.id === buyInPriceCurrency?.id,
          );
          const { price, priceWithoutVat } = mainRetailPrice || {};

          const retailerSupplierPriceScheme = {
            buyInPrice: buyInPrice,
            id: priceScheme?.id,
            listPrice: listPrice,
            logisticsPartnerId: logisticsPartner?.id,
            retailPrices: retailPrices,
            vatId: vat?.id,
          };

          row
            .get("retailerSupplierPriceScheme")
            .setValue(retailerSupplierPriceScheme);
          row.get("salePrice").setValue(buyInPrice);

          if (mainRetailPrice) {
            row.patchValue({
              retailPrice: price,
              priceWithoutVat: priceWithoutVat,
            });
          }
        }
      },
    );
  }

  public acceptProduct(row: FormGroup) {
    row.controls["accepted"].setValue(!row.value.accepted);
    if (row.value.accepted) {
      row.controls["rejected"].setValue(false);
    }

    this._updateStatus(row);
  }

  public rejectProduct(row: FormGroup) {
    row.controls["rejected"].setValue(!row.value.rejected);
    if (row.value.rejected) {
      row.controls["accepted"].setValue(false);
    }

    this._updateStatus(row);
  }

  private _updateStatus(row: FormGroup) {
    if (row.value.accepted) {
      row.controls["status"].setValue(OfferProductStatus.Confirmed);
    } else if (row.value.rejected) {
      row.controls["status"].setValue(OfferProductStatus.Rejected);
    } else {
      row.controls["status"].setValue(OfferProductStatus.Pending);
    }
  }

  public setRequestedPrice(row: FormGroup) {
    let inputValue = row.controls["requestedPriceInput"].value;
    if (typeof inputValue === "string") {
      inputValue = parseFloat(inputValue?.replace(",", "."));
    }

    row.controls["requestedPrice"].setValue(inputValue);
    row.controls["editRequestedPrice"].setValue(false);
  }

  public discardRequestedPrice(row: FormGroup) {
    row.controls["requestedPriceInput"].setValue(
      row.controls["requestedPrice"].value,
    );
    row.controls["editRequestedPrice"].setValue(false);
  }

  public getActiveCampaignPriceScheme(row: any): any {
    let date = row.get("priceChangeDate").value || this.date;
    let items = row.get("activeCampaignPriceSchemes").value;
    return items?.find(
      (x) => x.validFrom <= date && (x.validTo == null || x.validTo >= date),
    );
  }

  public calculatePriceChange(row: any) {
    const mainAssortmentPrice = row.get("mainAssortmentPrice").value;
    const salePrice = row.get("salePrice").value;
    return calculatePriceChange(mainAssortmentPrice, salePrice);
  }

  public getPriceChangeNoteIcon(row: any) {
    const { priceChangeNote } = row.value || {};
    return priceChangeNote ? "price-change-filled" : "price-change-empty";
  }

  public openPriceChangeDialog(row: any) {
    this._dialog.open(ProductPriceChangesInfoDialogComponent, {
      ...appSettings.DIALOG_SIZES.M,
      data: {
        product: row.value,
        readonly: true,
      },
    });
  }

  public get showMassChangesDialogButton() {
    return (
      this.canManageRetailerDetails &&
      this.columns.includes("offer_product_select") &&
      !this.readonly
    );
  }

  public showRetailerSupplierPricesDialogButton(row) {
    // row.value.status === OfferProductStatus.Rejected ? false :
    return this.canManageRetailerSupplierPrices;
  }

  public showRetailerDetailsDialogButton(row) {
    // row.value.status === OfferProductStatus.Rejected ? false :
    return this.canManageRetailerDetails;
  }

  public showAssortmentDialogButton(row) {
    // row.value.status === OfferProductStatus.Rejected ? false :
    return this.canManageRetailerAssortment;
  }

  public showOrderDialogButton(row) {
    // row.value.status === OfferProductStatus.Rejected ? false :
    return this.formGroup.get("canOrder")?.value;
  }

  public showProcurementDialogButton(row) {
    // row.value.status === OfferProductStatus.Rejected ? false :
    return this.canManageRetailerProcurement;
  }

  public calculateUnitPrice(price: number, row: any) {
    return calculateUnitPrice(
      price,
      row.contentUnitId,
      row.drainedWeightInGrams,
      row.netContent,
    );
  }

  public getValidRetailerProductBuyInPriceScheme(row: FormGroup) {
    const buyInPriceScheme = row.get(
      "validRetailerProductBuyInPriceScheme",
    ).value;
    const retailPrices = buyInPriceScheme?.retailPrices;

    if (retailPrices) {
      return retailPrices[0];
    }

    return null;
  }

  public getSuggestedRetailPriceWithoutVat(row: FormGroup) {
    const suggestedRetailPrice = row.get("suggestedRetailPrice").value;
    const vat = row.get("vat").value?.numericValue || 0;
    const priceWithoutVat = numberUtil.excludeVat(suggestedRetailPrice, vat);

    return priceWithoutVat;
  }

  public getDiscountCampaignWithType(row: FormGroup) {
    const campaignType = row.get("campaignType").value;
    const suggestedRetailPrice = row.get("suggestedRetailPrice").value;
    const validPrice = this.getValidRetailerProductBuyInPriceScheme(row);

    const { vatType, valueType } = campaignType || {};
    const price =
      vatType == CampaignTypeVatType.WithVat
        ? validPrice?.price
        : validPrice?.priceWithoutVat;

    if (!price) {
      return null;
    }

    if (valueType == CampaignTypeValueType.Value) {
      return {
        type: "price",
        value: (price - suggestedRetailPrice) * -1,
      };
    } else {
      return {
        type: "percent",
        value: ((price - suggestedRetailPrice) / price) * -100,
      };
    }
  }
}
