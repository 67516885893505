export enum DecisionProductStatus {
  None = 0,
  Other = 1,

  Confirmed = 2,
  PriceChange = 3,
  RemovedFromAssortment = 4,
  Rejected = 5
}

(DecisionProductStatus as any).__typeName = 'DecisionProductStatus';