export enum ProductMovieFormatField {
  Format = 1,
  Rating = 2
}

(ProductMovieFormatField as any).__typeName = 'ProductMovieFormatField';

(ProductMovieFormatField as any).__altTranslationKeys = {
  [ProductMovieFormatField.Format]: 'cards.products.product_movie_info.format_section.format',
  [ProductMovieFormatField.Rating]: 'cards.products.product_movie_info.format_section.rating'
};

export enum ProductMovieAudioField {
  Language = 1,
  Type = 2,
  IsDeaf = 3,
  SortOrder = 4
}

(ProductMovieAudioField as any).__typeName = 'ProductMovieAudioField';

(ProductMovieAudioField as any).__altTranslationKeys = {
  [ProductMovieAudioField.Language]: 'cards.products.product_movie_info.audio_section.language',
  [ProductMovieAudioField.Type]: 'cards.products.product_movie_info.audio_section.type',
  [ProductMovieAudioField.IsDeaf]: 'cards.products.product_movie_info.audio_section.isDeaf',
  [ProductMovieAudioField.SortOrder]: 'cards.products.product_movie_info.audio_section.sortOrder'
};

export enum ProductMovieActorField {
  Role = 1,
  Personal = 2,
  Desription = 3,
  SortOrder = 4
}

(ProductMovieActorField as any).__typeName = 'ProductMovieActorField';

(ProductMovieActorField as any).__altTranslationKeys = {
  [ProductMovieActorField.Role]: 'cards.products.product_movie_info.actors_section.role',
  [ProductMovieActorField.Personal]: 'cards.products.product_movie_info.actors_section.personal',
  [ProductMovieActorField.Desription]: 'cards.products.product_movie_info.actors_section.description',
  [ProductMovieActorField.SortOrder]: 'cards.products.product_movie_info.actors_section.sortOrder'
};
