import { Component, Input, HostBinding, Output, EventEmitter } from "@angular/core";
import { ViewEncapsulation } from '@angular/core';

@Component({
  selector: "card-expanding",
  templateUrl: "./card-expanding.component.html",
  host: { 'class': 'card', '[class.expanded]': 'expanded' }
})
export class CardExpandingComponent {

  @Input() public expanded = true;
  @Input() public valid: boolean = null;
  @Output() public onExpand = new EventEmitter();

  @Input() public showArrow = true;

  constructor() { }

  public toggleExpand() {
    this.expanded = !this.expanded;
    this.onExpand.emit(this.expanded);
    console.log(this.expanded);
  }

}
