import { Component, Inject, Optional, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { asArray, Status, UserAction } from 'src/app/enum';
import { AssortmentGroupsService, AuthenticationService } from 'src/app/_services';
import { AppState } from 'src/app/state/app.state';
import { finalize } from 'rxjs/operators';
import { DialogComponent } from 'src/app/components/_layout/dialog/dialog.component';
import { IClvDto } from 'src/app/cards/product/product-info/product-info.model';

@Component({
  selector: "assortment-group-dialog",
  host: { 'class': 'dialog' },
  templateUrl: './assortment-group.dialog.html',
  styleUrls: ['./assortment-group.dialog.scss']
})
export class AssortmentGroupDialogComponent {

  public form: FormGroup;

  public UserAction = UserAction;
  public statuses: any[] = asArray(Status);
  public childGroupOptions: IClvDto[];

  public isNew: boolean;
  public canEdit: boolean;

  public saveInProgress = false;

  @ViewChild('dialog', { static: true, read: DialogComponent })
  private _dialog: DialogComponent;

  constructor(
    private _appState: AppState,
    private _assortmentGroupsService: AssortmentGroupsService,
    public dialogRef: MatDialogRef<AssortmentGroupDialogComponent>,
    authService: AuthenticationService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isNew = !this.data.item;
    const assortmentGroup = this.data.item || {};

    this.form = new FormGroup({
      id: new FormControl(assortmentGroup.id),
      code: new FormControl(assortmentGroup.code),
      name: new FormControl(assortmentGroup.name),
      description: new FormControl(assortmentGroup.description),
      children: new FormControl(assortmentGroup.children?.filter(x => x.type == 1).map(x => x.id)),
      suggestedChildren: new FormControl(assortmentGroup.children?.filter(x => x.type == 2).map(x => x.id)),
      status: new FormControl(assortmentGroup.status === undefined ? Status.Active : assortmentGroup.status),
      sortOrder: new FormControl(assortmentGroup.sortOrderDefined ? assortmentGroup.sortOrder : undefined)
    });

    this.canEdit = authService.hasRights([UserAction.ManageBuyerAssortment]);
  }

  public ngAfterViewInit(): void {

    this._dialog.dataLoaded$
      .subscribe(state => {
        if (!state) {
          return;
        }

        this.childGroupOptions = this.data.formData.groups;
      });
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public save(): void {
    this.saveInProgress = true;
    const f = this.form.value;

    const req = {
      code: f.code,
      name: f.name,
      description: f.description,
      status: f.status,
      childIds: f.children,
      suggestedChildIds: f.suggestedChildren,
      sortOrder: f.sortOrder
    };

    const o = f.id
      ? this._assortmentGroupsService.save(f.id, req)
      : this._assortmentGroupsService.create(req);

    o.pipe(
        finalize(() => {
          this.saveInProgress = false;
        })
      ).result(this.form, response => {
      this.closeDialog(1);
    });
  }

  public closeDialog(data?: any): void {
    this.dialogRef.close(data);
  }
}
