<ng-container [ngSwitch]="readonly">

  <div class="lxm-timepicker-wrapper" [ngSwitch]="displayType" [formGroup]="formGroup" *ngSwitchCase="false">
    <ng-container *ngSwitchCase="TimepickerDisplayType.Regular">
      <input class="lxm-input" [ngxTimepicker]="timepicker" [format]="format" [formControlName]="for"
        pattern="[0-9]{2}:[0-9]{2}" readonly>
      <ngx-material-timepicker-toggle [for]="timepicker"></ngx-material-timepicker-toggle>
      <ngx-material-timepicker [appendToInput]="appendToInput" [enableKeyboardInput]="enableKeyboardInput"
        [disableAnimation]="disableAnimation" [value]="value" (timeChanged)="onTimeChanged($event)" [theme]="theme"
        #timepicker>
      </ngx-material-timepicker>
    </ng-container>

    <ng-container *ngSwitchCase="TimepickerDisplayType.Field">
      <ngx-timepicker-field [format]="format" [readonly]="readonly" [clockTheme]="theme" buttonAlign='left'
        [formControlName]="for" [defaultTime]="defaultTime" [disableAnimation]="disableAnimation"
        (timeChanged)="onTimeChanged($event)">
      </ngx-timepicker-field>
    </ng-container>

    <ng-content></ng-content>

  </div>

  <span class="lxm-input input-readonly w-full" *ngSwitchCase="true">
    {{value || '-'}}
  </span>

</ng-container>