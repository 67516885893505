import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NotificationType, UserAction } from "src/app/enum";
import { TranslatedValuePipe } from "../pipes/translated-value.pipe";
import { FormDatePipe } from "../pipes/form-date.pipe";
import { AuthenticationService } from "../_services";

@Injectable()
export class NotificationBuilder {
  constructor(
    private _translate: TranslateService,
    private _formDate: FormDatePipe,
    private _translatedValue: TranslatedValuePipe,
    private _authService: AuthenticationService
  ) {}

  public translationPath = "cards.notifications.content.";

  private _serializeListItem(data: any) {
    const { type, fromTenant, fromUser, params, itemId } = data || {};
    let serialized = {
      offerName: null,
      procurementName: null,
      validFrom: null,
      validTo: null,
      type: type,
      fromTenant: fromTenant,
      fromUser: fromUser,
      itemId: itemId,
      title: null,
      subject: null,
      preview: null,
      assortmentInCount: null,
      assortmentOutCount: null,
      params: params
    };

    switch (data.type) {
      case NotificationType.RetailerRequestedOffer:
      case NotificationType.RetailerRejectedOffer:
      case NotificationType.RetailerConfirmedOffer:
      case NotificationType.RetailerMadeProposalIntoOffer:
      case NotificationType.RetailerAddedOfferComment:
      case NotificationType.SupplierAddedOfferComment:
      case NotificationType.SalesOrderComment:
      case NotificationType.RetailerRequestedOffer:
      case NotificationType.RetailerExpiredOffer:
      {
        const {
          OfferName,
          OfferValidFrom,
          OfferValidTo,
          OfferProductsCount,
          ChatMessage
        } = params || {};

        serialized.offerName = OfferName;
        serialized.validFrom = OfferValidFrom;
        serialized.validTo = OfferValidTo;

        switch(data.type) {
          case NotificationType.RetailerRequestedOffer:
            serialized.preview = this._translate.instant(
              this.translationPath + "RetailerRequestedOffer.text.offer_products_count",
              {
                offerProductsCount: OfferProductsCount
              }
            );
            break;
          case NotificationType.RetailerMadeProposalIntoOffer:
            
            break;
          case NotificationType.RetailerAddedOfferComment:
          case NotificationType.SupplierAddedOfferComment:
            serialized.preview = ChatMessage;
            break;

        }

        break;
      }
      
      case NotificationType.ChangesInActiveProducts:
        const { AssortmentInCount, AssortmentOutCount } = params || {};
        serialized.assortmentInCount = AssortmentInCount;
        serialized.assortmentOutCount = AssortmentOutCount;
        break;

      case NotificationType.ReleaseNotes:
        const { ReleaseNoteTitle, ReleaseNotePreviewDescription, ReleaseNoteSubtitle } = params || {};
        serialized.title = ReleaseNoteTitle;
        serialized.subject = ReleaseNoteSubtitle;
        serialized.preview = ReleaseNotePreviewDescription;
        break;

      case NotificationType.Procurement:

        const {
          ProcurementName,
          ProcurementValidFrom,
          ProcurementValidTo
        } = params || {};

        serialized.validFrom = ProcurementValidFrom;
        serialized.validTo = ProcurementValidTo;
        serialized.procurementName = ProcurementName;
        break;
    }

    return serialized;
  }

  private _serializeNotification(data: any) {
    const { type, fromTenant, fromUser, itemId } = data || {};

    let serialized = {
      offerName: null,
      procurementName: null,
      validFrom: null,
      validTo: null,
      type: type,
      fromTenant: fromTenant,
      fromUser: fromUser,
      itemId: itemId,
      title: null,
      subject: null,
      preview: null,
      assortmentInCount: null,
      assortmentOutCount: null,
      params: {} as any
    };

    switch (data.type) {

      case NotificationType.SalesOrderReceived:
      case NotificationType.SalesOrderComment:
      case NotificationType.SalesOrderRejected:
      case NotificationType.SalesOrderForwarded:
      case NotificationType.SalesOrderConfirmed:
      case NotificationType.SalesOrderCancelled:
      case NotificationType.PurchaseOrderReceived:
      case NotificationType.PurchaseOrderComment:
      case NotificationType.PurchaseOrderRejected:
      case NotificationType.PurchaseOrderSentForConfirmation:
      case NotificationType.PurchaseOrderForwarded:
      case NotificationType.PurchaseOrderConfirmed:
      case NotificationType.PurchaseOrderCancelled:
      case NotificationType.SalesOrderCancelled:
      case NotificationType.SalesOrderSentForConfirmation:
      case NotificationType.SalesOrderComplete:
        const { orderNumber } = data || {};
        serialized.params.OrderNumber = orderNumber;
        break;

      case NotificationType.RetailerRequestedOffer:
      case NotificationType.RetailerRejectedOffer:
      case NotificationType.RetailerConfirmedOffer:
      case NotificationType.RetailerMadeProposalIntoOffer:
      case NotificationType.RetailerAddedOfferComment:
      case NotificationType.SupplierAddedOfferComment:
      case NotificationType.RetailerRequestedOffer:
      case NotificationType.RetailerExpiredOffer:
        const { offerName, offerValidFrom, offerValidTo } = data || {};
        serialized.offerName = offerName;
        serialized.validFrom = offerValidFrom;
        serialized.validTo = offerValidTo;
        break;
      case NotificationType.ChangesInActiveProducts:
        serialized.assortmentInCount = data.in?.length;
        serialized.assortmentOutCount = data.out?.length;
      case NotificationType.ReleaseNotes:
        const { title } = data;
        serialized.title = title;
        break;
      case NotificationType.Procurement:
        const { procurementName, procurementValidFrom, procurementValidTo } = data || {};
        serialized.procurementName = procurementName;
        serialized.validFrom = procurementValidFrom;
        serialized.validTo = procurementValidTo;
        break;
    }

    return serialized;
  }

  public serializeData(data: any) {
    if (!data) return null;
    const { params } = data || {};
    return params ? this._serializeListItem(data) : this._serializeNotification(data);
  }

  public getHeader(data, plainText?: boolean, link?: boolean) {
    const serialized = this.serializeData(data);
    if (!serialized?.type) return;

    const {
      type,
      fromTenant,
      fromUser,
      title,
      assortmentInCount,
      assortmentOutCount,
      params
    } = serialized;

    const translateKey = NotificationType[type];

    let translateParams = {
      tenantName: fromTenant?.name,
      user: fromUser?.name || this._translate.instant('global.user').toLowerCase(),
      ...params
    } as any;

    switch (type) {

      case NotificationType.ChangesInActiveProducts:
        if (assortmentInCount > 0) {
          return this._translate.instant(
            this.translationPath + "ChangesInActiveProducts.header.product_activation", translateParams
          );
        }
        if (assortmentOutCount > 0) {
          return this._translate.instant(
            this.translationPath + "ChangesInActiveProducts.header.product_deactivation", translateParams
          );
        }

      case NotificationType.ReleaseNotes:
        return title;
      default:
        return this._translate.instant(
          this.translationPath + `${translateKey}.header`, translateParams
        );;
    }
  }

  public getSubject(data, plainText?: boolean, link?: boolean) {
    const serialized = this.serializeData(data);
    if (!serialized?.type) return;

    const { type, offerName, validFrom, validTo, itemId, subject, procurementName, params: { OrderNumber, OrderId} } = serialized || {};

    switch (type) {


      case NotificationType.PurchaseOrderReceived:
      case NotificationType.PurchaseOrderComment:
      case NotificationType.PurchaseOrderRejected:
      case NotificationType.PurchaseOrderSentForConfirmation:
      case NotificationType.PurchaseOrderForwarded:
      case NotificationType.PurchaseOrderConfirmed:
      case NotificationType.PurchaseOrderCancelled:
        return `<a class="highlighted" href="/orders/purchase/${itemId}" target="_blank">${OrderNumber}</a>`

      case NotificationType.SalesOrderReceived:
      case NotificationType.SalesOrderComment:
      case NotificationType.SalesOrderRejected:
      case NotificationType.SalesOrderForwarded:
      case NotificationType.SalesOrderConfirmed:
      case NotificationType.SalesOrderCancelled:
      case NotificationType.SalesOrderCancelled:
      case NotificationType.SalesOrderSentForConfirmation:
      case NotificationType.SalesOrderComplete:
        return `<a class="highlighted" href="/orders/sales/${itemId}" target="_blank">${OrderNumber}</a>`

      case NotificationType.Procurement:

        const translatedProcurementName = this._translatedValue.transform(procurementName);
        const procurementSubject = `${translatedProcurementName ? translatedProcurementName + ', ' : ''}${this._formDate.transform(
          validFrom
        )} - ${this._formDate.transform(validTo) || "..."}`;

        
        if (plainText) {
          return procurementSubject;
        }

        if (link && itemId && this._authService.hasRight(this.getRequiredRightsForOffer(type))) {
          return `<a class="highlighted" href="/calendar/${itemId}" target="_blank">${procurementSubject}</a>`;
        } 
        return `<span class="highlighted">${procurementSubject}</span>`;

      case NotificationType.RetailerRequestedOffer:
      case NotificationType.RetailerRejectedOffer:
      case NotificationType.RetailerConfirmedOffer:
      case NotificationType.RetailerMadeProposalIntoOffer:
      case NotificationType.RetailerAddedOfferComment:
      case NotificationType.SupplierAddedOfferComment:
      case NotificationType.RetailerExpiredOffer:
          const offerCommentSubject = `${offerName ? offerName + ', ' : ''}${this._formDate.transform(
          validFrom
        )} - ${this._formDate.transform(validTo) || "..."}`;

        if (plainText) {
          return offerCommentSubject;
        }

        if (link && itemId && this._authService.hasRight(this.getRequiredRightsForOffer(type))) {
          return `<a class="highlighted" href="/offers/${itemId}" target="_blank">${offerCommentSubject}</a>`;
        } 
        return `<span class="highlighted">${offerCommentSubject}</span>`;
      case NotificationType.ChangesInActiveProducts:
        return "";
      case NotificationType.ReleaseNotes:
        if (subject) {
          if (plainText) return subject;
          return ` <span class="highlighted">${subject}</span>`
        }
      default:
        return subject || '';
    }
  }

  private getRequiredRightsForOffer(type) {
    switch (type) {
      case NotificationType.RetailerRequestedOffer:
      case NotificationType.RetailerRejectedOffer:
      case NotificationType.RetailerConfirmedOffer:
      case NotificationType.RetailerMadeProposalIntoOffer:
      case NotificationType.RetailerAddedOfferComment:
      case NotificationType.RetailerExpiredOffer:
        return [UserAction.ViewSupplierOffers];
      case NotificationType.SupplierAddedOfferComment:
        return [UserAction.ViewReceivedOffers];
      default:
        return [];
    }
  }

  public getPreview(data, plainText?: boolean, link?: boolean) {
    const serialized = this.serializeData(data);
    if (!serialized?.type) return;

    const {
      type,
      offerName,
      validFrom,
      validTo,
      itemId,
      fromTenant,
      fromUser,
      title,
      preview,
      assortmentInCount,
      assortmentOutCount,
      params
    } = serialized || {};

    switch (type) {

      case NotificationType.RetailerAddedOfferComment:
      case NotificationType.SupplierAddedOfferComment:
        return preview;
      case NotificationType.ChangesInActiveProducts:
        if (assortmentInCount > 0) {
          return this._translate.instant(this.translationPath + "ChangesInActiveProducts.text.product_activation")};
        if (assortmentOutCount > 0) {
          return this._translate.instant(this.translationPath + "ChangesInActiveProducts.text.product_deactivation");
        }
      case NotificationType.CalendarEntryIsComingDue:
        return this._translate.instant(this.translationPath + "CalendarEntryIsComingDue.text");
      case NotificationType.ReleaseNotes:
        return preview;
      default:
        return preview || "";
    }
  }
}
