import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { DialogComponent } from 'src/app/components';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'products-retailer-export-dialog',
  host: { 'class': 'dialog' },
  templateUrl: './export-products-retailer.dialog.html'
})
export class ProductsRetailerExportDialogComponent implements AfterViewInit {
  @ViewChild('dialog', { static: true, read: DialogComponent })
  private _dialog: DialogComponent;

  constructor(
    public dialogRef: MatDialogRef<ProductsRetailerExportDialogComponent>) {
  }

  public ngAfterViewInit(): void {
    this._dialog.dataLoaded$
      .subscribe(state => {
        if (!state) {
          return;
        }

        this._dialog.closeDialog();
      });
  }
}
