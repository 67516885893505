<div class="role-table">

    <div class="loading-container" *ngIf="dataSource.loading$ | async">
        <div class="loading">
            {{'global.table.loading' | translate}}
        </div>
    </div>

    <mat-table [dataSource]="dataSource" matSort [@.disabled]="true">

        <!-- General fields -->

        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>
                <div mat-sort-header data-table-filter-text>
                    {{'cards.lexi_market_settings.lxm_settings_user_role.table.user_role_name' | translate}}
                </div>
            </mat-header-cell>

            <mat-cell *matCellDef="let role">{{role.name}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="role_user_actions">
            <mat-header-cell *matHeaderCellDef>
                <div>
                    {{'cards.lexi_market_settings.lxm_settings_user_role.table.user_role_permissions' | translate}}
                </div>
            </mat-header-cell>

            <mat-cell *matCellDef="let role">{{formatUserActions(role.userActions)}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef>
                <div mat-sort-header data-table-filter-dropdown [options]="statuses">
                    {{'cards.lexi_market_settings.lxm_settings_user_role.table.user_role_status' | translate}}
                </div>
            </mat-header-cell>

            <mat-cell *matCellDef="let role">{{role.status | translateEnum : Status}}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns" class="role-table-head"></mat-header-row>

        <mat-row *matRowDef="let row; columns: displayedColumns" class="role-table-row" (click)="onRowClick(row)"></mat-row>
    </mat-table>

    <data-table-footer
        [localStorageKey]="LOCAL_STORAGE_KEY"
        (paginator)="hookPaginator($event)">
    </data-table-footer>

</div>
