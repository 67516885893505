<lxm-button class="dot-menu-button" 
    type="transparent" 
    icon="dot-menu" 
    [popper]="menuContent"
    [popperTrigger]="trigger"
    [popperPlacement]="position"
    [popperPositionFixed]="popperPositionFixed"
    [popperHideOnMouseLeave]="false"
    [popperHideOnClickOutside]="true"
    [popperAppendTo]="appendTo"
    [popperBoundaries]="boundaries"
    [popperPreventOverflow]="true"
    [class.open]="isOpen"
    popperApplyClass="dot-menu-content">

</lxm-button>

    <popper-content #menuContent (click)="onClick()">
        <ng-content></ng-content>
        <ng-container 
            *ngTemplateOutlet="template">
        </ng-container>
    </popper-content>
