<card-expanding
  [valid]="true"
  [expanded]="isExpanded"
  (onExpand)="saveLocalExpandedValue($event)"
>
  <span header-title>{{ title | translate}}</span>

  <div header-actions>
    <lxm-button
      *ngIf="canEdit"
      type="beige"
      icon="plus-dark"
      label="cards.products.product_pricelist.action.add_schema"
      (click)="openDialog(null)"
    >
    </lxm-button>
  </div>

  <div body>
    <div *ngIf="!pricesInfo?.retailerSupplierPrices?.length">
      <no-content
        image="no-product-pricelist"
        text="global.no_product_pricelist"
        [inline]="true"
      ></no-content>
    </div>

    <div
      class="row overflow-x"
      *ngIf="pricesInfo?.retailerSupplierPrices?.length"
    >
      <div class="col-12 no-padding">
        <mat-table [dataSource]="pricesInfo.retailerSupplierPrices" matSort>
          <ng-container matColumnDef="valid">
            <mat-header-cell *matHeaderCellDef>
              <span
                [innerHTML]="'cards.products.product_pricelist_retailer_supplier.table.valid' | translate"
              >
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{row.validFrom | formDate}} - {{row.validTo | formDate}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="user">
            <mat-header-cell *matHeaderCellDef>
              <span
                [innerHTML]="'cards.products.product_pricelist_retailer_supplier.table.user' | translate"
              >
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{row.modifiedBy | formValue}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="listPrice">
            <mat-header-cell *matHeaderCellDef>
              <span
                [innerHTML]="'cards.products.product_pricelist_retailer_supplier.table.list_price' | translate"
              ></span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{row.listPrice | priceValue:3:row.buyInPriceCurrency?.value}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="discount">
            <mat-header-cell *matHeaderCellDef>
              <span
                [innerHTML]="'cards.products.product_pricelist_retailer_supplier.table.discount' | translate"
              >
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{calculateDiscount(row) | percentValue:1}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="margin">
            <mat-header-cell *matHeaderCellDef>
              <span
                [innerHTML]="(markupMethod == 1 ? 'cards.products.product_pricelist_retailer_supplier.table.coverage'
                : 'cards.products.product_pricelist_retailer_supplier.table.margin') | translate"
              >
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{calculateMargin(row) | percentValue:1}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="buyInPrice">
            <mat-header-cell *matHeaderCellDef>
              <span
                [innerHTML]="'cards.products.product_pricelist_retailer_supplier.table.buy_in_price' | translate"
              ></span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <div class="triple-rows">
                <div class="cell-row">
                  {{row.listPrice | priceValue:3:row.buyInPriceCurrency?.value}}
                </div>
                <div class="cell-row">
                  {{calculateDiscount(row) | percentValue}}
                </div>
                <div class="cell-row">
                  {{row.buyInPrice |
                  priceValue:3:row.buyInPriceCurrency?.value}}
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="retailPrice">
            <mat-header-cell *matHeaderCellDef>
              <span
                [innerHTML]="'cards.products.product_pricelist_retailer_supplier.table.retail_price' | translate"
              ></span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <div>
                <div *ngFor="let price of row.retailPrices">
                  {{ price.price | priceValue:2:price.currency.value}}
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="retailPriceWithoutVat">
            <mat-header-cell *matHeaderCellDef>
              <span
                [innerHTML]="'cards.products.product_pricelist_retailer_supplier.table.retail_price_without_vat' | translate"
              ></span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <div>
                <div *ngFor="let price of row.retailPrices">
                  {{ price.priceWithoutVat | priceValue:2:price.currency.value}}
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="price_group">
            <mat-header-cell *matHeaderCellDef>
              <span
                [innerHTML]="'cards.products.product_pricelist_retailer_supplier.table.price_group' | translate"
              >
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <div>
                <div
                  class="flex flex-row"
                  *ngFor="let group of row.priceGroups"
                >
                  <div>
                    <span class="bold">
                      {{group.priceGroup?.value}}:&nbsp;
                    </span>
                  </div>
                  <div>
                    <span *ngFor="let price of group.retailPrices">
                      {{price.priceWithoutVat |
                      priceValue:2:price.currency.value}}&nbsp;|&nbsp;{{price.price
                      | priceValue:2:price.currency.value}}
                    </span>
                  </div>
                </div>

                <!-- <div class="flex flex-row" *ngFor="let group of row.priceGroups">
                    <div class="flex flex-row">
                      <span class="bold" [tooltip]="pricesTemplate">
                        {{group.priceGroup?.value}} <span class="info-icon ml5"></span>
                      </span>
                      <ng-template #pricesTemplate>
                        <span *ngFor="let price of group.retailPrices">
                          {{price.priceWithoutVat | priceValue:2:price.currency.value}}&nbsp;|&nbsp;{{price.price | priceValue:2:price.currency.value}}
                        </span>
                      </ng-template>
                    </div>
                  </div> -->
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="vat">
            <mat-header-cell *matHeaderCellDef>
              <span
                [innerHTML]="'cards.products.product_pricelist_retailer_supplier.table.vat' | translate"
              >
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span *ngIf="row.vat?.numericValue">
                {{row.vat.numericValue}}%
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="supplier">
            <mat-header-cell *matHeaderCellDef>
              <span
                [innerHTML]="'cards.products.product_pricelist_retailer_supplier.table.supplier' | translate"
              >
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <div>
                {{row.supplier?.name }}
                <div
                  *ngIf="row.logisticsPartner"
                  class="alt-text"
                  title="{{ 'cards.products.product_pricelist_retailer_supplier.table.logistics_partner' | translate }}"
                >
                  {{ row.logisticsPartner.value }}
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="scheme_type">
            <mat-header-cell *matHeaderCellDef>
              <span
                [innerHTML]="'cards.products.product_pricelist_retailer_supplier.table.scheme_type' | translate"
              >
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <div class="flex">
                <type-indicator
                  style="height: unset; align-self: unset"
                  [typeId]="row.schemeType"
                  enum="ProductPriceSchemeType"
                ></type-indicator>
                <div class="flex flex-col">
                  {{row.schemeType | translateEnum : "ProductPriceSchemeType" }}
                  <span
                    class="font-display text-sm text-grey-60"
                    *ngIf="row.campaignType?.value"
                  >
                    {{row.campaignType?.value}}
                  </span>
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="info">
            <mat-header-cell *matHeaderCellDef>
              <!-- <span [innerHTML]="'cards.products.product_pricelist_retailer_supplier.table.info' | translate">
                </span> -->
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <procurement-details-popover
                *ngIf="row.procurement"
                [procurement]="row.procurement"
              ></procurement-details-popover>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
          <mat-row
            class="pricelist-table-row"
            [class.table-row-hover]="canEdit && row?.isEditable"
            [class.current]="row?.isCurrent"
            [class.upcoming]="!row.isCurrent && row?.isEditable"
            [class.expired]="!row.isCurrent && !row.isEditable"
            *matRowDef="let row; columns: columns;"
            (click)="openDialog(row)"
          >
          </mat-row>
        </mat-table>
      </div>
    </div>
  </div>
</card-expanding>
