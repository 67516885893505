import { Component, Input } from "@angular/core";
import { appSettings } from 'src/app/app.settings';

@Component({
  selector: "copyright-block",
  templateUrl: "./copyright.component.html",
  styleUrls: ['./copyright.component.scss']
})
export class CopyrightBlockComponent {

  public currentYear = new Date().getFullYear();
  public mailTo = 'support@lexi.market';

}
