<lxm-dialog [dialogRef]="dialogRef" [formGroup]="form">
  <span header-title>{{'cards.products.product_file_management.title' | translate}}</span>
  <section body>

    <form-field for="name" label="cards.products.product_file_management.label.file_name" type="input-text">
      <validation-errors for="name"></validation-errors>
    </form-field>

    <form-field for="description" label="cards.products.product_file_management.label.file_description" type="textarea">
      <validation-errors for="description"></validation-errors>
    </form-field>
    

    <file-drop-box 
      (onChange)="uploadFile($event)"
      (onDelete)="deleteAttachment($event)"
      [files]="files"
      [uploadInProgress]="uploadInProgress" 
      [uploadingFile]="uploadingFile">
    </file-drop-box>

  </section>

  <section footer>
    <lxm-button 
      type="primary" 
      label="cards.products.product_file_management.save_file"
      [disabled]="uploadInProgress || uploadingFile"
      [loading]="uploadingFile"
      (click)="save()"
      buttonType="submit">
    </lxm-button>
    <lxm-button type="secondary" class="ml10" label="cards.products.product_file_management.cancel_file" (click)="closeDialog()"></lxm-button>
  </section>

</lxm-dialog>
