import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from "@angular/core";

const _isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,7})?[\+-]\d{2}:\d{2}$/;

function reviver(key, value) {
  if (typeof value === 'string' && _isoDateFormat.test(value)) {
    return new Date(value);
  }

  return value;
}

@Injectable()
export class JsonDateInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.responseType !== 'json') {
      return next.handle(req);
    }

    req = req.clone({
      responseType: 'text'
    });

    return next.handle(req)
      .pipe(
        catchError(err => {
          if (err instanceof HttpErrorResponse) {
            return throwError(new HttpErrorResponse({
              error: JSON.parse(err.error, reviver),
              headers: err.headers,
              status: err.status,
              statusText: err.statusText,
              url: err.url
            }));
          }
          return throwError(err);
        })
      )
      .pipe(
        map((val: HttpEvent<any>) => {
          if (val instanceof HttpResponse && val.body) {
            val = val.clone({
              body: JSON.parse(val.body, reviver)
            });
          }
          return val;
        })
      );
  }
}
