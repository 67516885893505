<card-expanding
  [valid]="productValidity.getCardStatusValidType(cardSection)"
  [expanded]="isExpanded"
  (onExpand)="saveLocalExpandedValue($event)"
>
  <span header-title>{{title | translate}}</span>

  <div header-actions *ngIf="productCard.isOwnProduct">
    <ng-container *requiredRights="[UserAction.ManageProducts]">
      <lxm-button
        type="beige"
        icon="plus-dark"
        label="cards.products.product_assortment_management.action.add_schema"
        (click)="openBaseSchemaDialog()"
      >
      </lxm-button>
    </ng-container>
  </div>

  <div body>
    <div *ngIf="!assortments?.length">
      <no-content
        image="no-product-catalog"
        text="global.no_product_catalog"
        [inline]="true"
      ></no-content>
    </div>

    <div class="row overflow-x" *ngIf="assortments?.length">
      <div class="col-12 no-padding">
        <mat-table
          [dataSource]="assortments"
          matSort
          multiTemplateDataRows
          [@.disabled]="true"
        >
          <!-- User Column -->
          <ng-container matColumnDef="assortment_base_user">
            <mat-header-cell
              *matHeaderCellDef
              [innerHTML]="'cards.products.product_assortment_management.table.user' | translate"
            >
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span>
                <span> {{row.modifiedBy}} </span>
              </span>
            </mat-cell>
          </ng-container>

          <!-- Validity Column -->
          <ng-container matColumnDef="assortment_base_valid">
            <mat-header-cell
              *matHeaderCellDef
              [innerHTML]="'cards.products.product_assortment_management.table.valid' | translate"
            >
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span
                >{{row.validFrom | formDate}} - {{row.validTo | formDate}}</span
              >
            </mat-cell>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="assortment_base_status">
            <mat-header-cell
              *matHeaderCellDef
              [innerHTML]="'cards.products.product_assortment_management.table.status' | translate"
            >
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <status-circle class="mr5" [status]="row.status"></status-circle>
              <span
                >{{row.status | translateEnum: 'SupplierProductAssortmentStatus'
                }}</span
              >
            </mat-cell>
          </ng-container>

          <!-- Visibility -->
          <ng-container matColumnDef="assortment_base_visibility">
            <mat-header-cell *matHeaderCellDef>
              <div
                class="w-full"
                [innerHTML]="'cards.products.product_assortment_management.table.visibility' | translate"
              ></div>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <div class="triple-rows">
                <div class="cell-row">
                  {{ row.retailersListIsExclusive | translateEnum : Bool }}
                </div>

                <div class="cell-row" *ngIf="row.retailerIds?.length">
                  {{
                  'cards.products.product_assortment_management_edit.label.except'
                  | translate }}:

                  <div class="comma-delimited-list">
                    <span *ngFor="let retailerId of row.retailerIds">
                      {{ retailChainsById[retailerId]?.name }}
                    </span>
                  </div>
                </div>


              </div>
            </mat-cell>
          </ng-container>

          <!-- Is Orderable -->
          <ng-container matColumnDef="is_orderable">
            <mat-header-cell *matHeaderCellDef>
              <div
                class="w-full"
                [innerHTML]="'cards.products.product_assortment_management.table.is_orderable' | translate"
              ></div>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{row.isOrderable | translateEnum: Bool}}
            </mat-cell>
          </ng-container>

          <!-- Orderable for clients -->
          <ng-container matColumnDef="orderable_for_clients">
            <mat-header-cell *matHeaderCellDef>
              <!-- <div
                class="w-full"
                [innerHTML]="'cards.products.product_assortment_management.table.orderable_for_clients' | translate"
              ></div> -->
                        <div
                class="w-full"
                [innerHTML]="'cards.products.product_assortment_management.table.is_orderable' | translate"
              ></div>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <div class="triple-rows">
                <div class="cell-row">
                  {{row.isOrderable | translateEnum: Bool}}
                </div>
                <div class="cell-row" *ngIf="row.withPriceSchemeOnly">
                  <div [innerHTML]="'cards.products.product_assortment_management.table.with_price_scheme_only' | translate">
                  </div>
                </div>
                <div class="cell-row" *ngIf="row.priceLists?.length">
                  <div class="comma-delimited-list line-clamp-1">
                    <span *ngFor="let item of row.priceLists">
                      {{ item.value }}
                    </span>
                  </div>
                </div>
                <div class="cell-row" *ngIf="row.orderableRetailerIds?.length">
                  <div
                    class="comma-delimited-list line-clamp-1">
                    <span *ngFor="let retailerId of row.orderableRetailerIds">
                      {{ retailChainsById[retailerId]?.name }}
                    </span>
                  </div>
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <!-- <ng-container *ngFor="let chain of retailChains" [matColumnDef]="chain.id">
            <mat-header-cell *matHeaderCellDef class="mat-column-assortment_base_chain">
              {{chain.shortName}}
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="mat-column-assortment_base_chain">
              <span>{{isAvailable(row, chain.id) | translateEnum: Bool}}</span>
            </mat-cell>
          </ng-container> -->

          <!-- Active Column -->
          <!-- <ng-container matColumnDef="assortment_base_activeOffer">
            <mat-header-cell *matHeaderCellDef>
              {{'cards.products.product_assortment_management.table.active_offer' | translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span>{{row.activeOffer}}</span>
            </mat-cell>
          </ng-container> -->

          <mat-header-row
            *matHeaderRowDef="table.base.columns"
          ></mat-header-row>
          <mat-row
            class="assortment-table-row"
            [class.table-row-hover]="canEdit && row?.isEditable"
            [class.current]="row?.isCurrent"
            [class.upcoming]="row?.isEditable"
            [class.expired]="!row.isCurrent && !row.isEditable"
            *matRowDef="let row; columns: table.base.columns;"
            (click)="openBaseSchemaDialog(row)"
          ></mat-row>
        </mat-table>
      </div>
    </div>
  </div>
</card-expanding>
