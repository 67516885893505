<div class="flex flex-row" [class.readonly]="readonly">
  <button class="toggle-button left beige-button"
          [class.toggled]="leftToggled"
          (click)="leftClicked.emit($event)">
    <ng-content select="[text1]"></ng-content>
  </button>
  <button class="toggle-button right beige-button"
          [class.toggled]="rightToggled"
          (click)="rightClicked.emit($event)">
    <ng-content select="[text2]"></ng-content>
  </button>
</div>
