<div class="progress-container">

  <mat-progress-bar class="progress-bar"
                    [mode]="mode"
                    [value]="progress">
  </mat-progress-bar>

  <div class="progress-footer">
    <span class="progress-bottom-left">
      <ng-content select="[bottomLeft]"></ng-content>

    </span>
    <span class="progress-text">

      <ng-container *ngIf="progress < 100">
        <ng-content select="[loading]"></ng-content>

        <ng-container *ngIf="showProgressText">
          {{progress | number: '1.0-1'}} %
        </ng-container>
      </ng-container>


      <ng-container *ngIf="progress === 100">
        <ng-content select="[completed]"></ng-content>
      </ng-container>

    </span>

  </div>




</div>
