import { Directive, Input, TemplateRef, ViewContainerRef, OnInit } from '@angular/core';
import { AuthenticationService } from '../_services';
import { LxmAppModule, UserAction } from '../enum';
import { Subscription } from 'rxjs';
import { AppState } from '../state/app.state';

@Directive({
  selector: '[requiredModules]'
})
export class RequiredModulesDirective implements OnInit {

  private _requiredModules: LxmAppModule[] = [];

  @Input() set requiredModules(modules: Partial<keyof LxmAppModule>[]) {
    this._requiredModules = modules?.map(module => LxmAppModule[module]);
  }

  // Whether all or any of the modules are required
  @Input() public any = true;

  private _subscription: Subscription;

  constructor(
    private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef,
    private _authService: AuthenticationService,
    private _appState: AppState) {
  }

  ngOnInit() {
    this._subscription = this._authService.jwt.subscribe(
      (jwt) => {

        this._viewContainer.clear();

        if (jwt) {
          if (
            this.any ? 
            this._requiredModules.some(module => this._appState.hasModule(module)) : 
            this._requiredModules.every(module => this._appState.hasModule(module))
          ) {
            this._viewContainer.createEmbeddedView(this._templateRef);
          }
        }

      }
    );
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

}
