import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  ViewChild
} from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { appSettings } from "src/app/app.settings";
import { DataTableComponent, DialogComponent } from "src/app/components";
import {
  asArray,
  ClientDiscountRuleType,
  Status,
  translate,
  UserAction
} from "src/app/enum";
import { TranslatedValuePipe } from "src/app/pipes";
import { ContentLanguage, LxmDialog, LxmMessage } from "src/app/_helpers";
import { DataTableService } from "src/app/_services";
import {
  ClientsService,
  IClientCard,
  IClientFormData
} from "src/app/_services/clients.service";
import { ClientState } from "../../state/client.state";

@Component({
  selector: "client-contract-condition-card",
  templateUrl: "./contract-condition.card.html",
  styleUrls: ["./contract-condition.card.scss"]
})
export class ContractConditionCard extends DataTableComponent<any, any> {
  @Input() public clientId;

  public clientCard: IClientCard;
  public formData: IClientFormData;

  public isClient = false;
  public userCanEdit = false;

  public clientDiscountRules = [];

  public showAllRules = false;

  constructor(
    dataService: DataTableService<any, any>,
    cd: ChangeDetectorRef,
    private _dialog: LxmDialog,
    private _clientsService: ClientsService,
    private _route: ActivatedRoute,
    public _state: ClientState
  ) {
    super(dataService, cd);

    const { initialData } = this._route.snapshot.data;
    this.clientCard = initialData?.clientCard;
    this.clientDiscountRules = this.clientCard?.clientDiscountRules;
    this.formData = this.clientCard?.formData;
  }

  public get canEdit() {
    return this._state.canEdit;
  }

  public toggleShowAllRules(toSet = !this.showAllRules) {
    this.showAllRules = toSet;
  }

  public get hasContractConditions() {
    return this.clientDiscountRules?.length > 0;
  }

  public getDiscountRules() {
    return this._clientsService
      .getClientDiscountRules(this.clientId, { $count: "true", $top: "100" })
      .result(
        null,
        res => {
          const items = res.items;
          if (items) {
            this.clientDiscountRules = items;
          }
        },
        e => {}
      );
  }

  private _getNextAvailableOrderNr(): number {
    const numArray = this.clientDiscountRules?.map(x => x.orderNr);
    if (numArray?.length > 0) {
      for (let i = 0; i < numArray.length; i++) {
        const nextPotentialNumber = numArray[i] + 1;
        const nextInArray = numArray[i + 1];
        if (nextPotentialNumber !== nextInArray) {
          return nextPotentialNumber;
        }
      }
    }
    return 1;
  }

  public openDialog(row?: any) {
    this._dialog.open(
      AddContractConditionDialogComponent,
      {
        ...appSettings.DIALOG_SIZES.M,
        data: {
          clientId: this.clientId,
          formData: this.formData,
          orderNext: this._getNextAvailableOrderNr(),
          rule: row,
          edit: this.canEdit
        }
      },
      res => {
        this.getDiscountRules();
      }
    );
  }
}

@Component({
  selector: "client-discount-item",
  templateUrl: "./discount-item.html",
  styleUrls: ["./discount-item.scss"],
  providers: [TranslatedValuePipe]
})
export class ClientDiscountItem {
  constructor(
    private _translateService: TranslateService,
    private _translatedValue: TranslatedValuePipe
  ) {}

  @Input() public data: any;

  public get type() {
    return this.data?.type;
  }

  public get typeName() {
    if (!this.type) return "-";
    return translate(ClientDiscountRuleType, this.type, this._translateService);
  }

  public get brand() {
    return this.data?.brand;
  }

  public get gs1Segment() {
    return this.data?.gs1Segment;
  }

  public get storageCondition() {
    return this.data?.storageConditions;
  }

  public get description() {
    return this.data?.description;
  }

  public get conditionName() {
    switch (this.type) {
      case ClientDiscountRuleType.General:
        return this.description || "";
      case ClientDiscountRuleType.Brand:
        return this.brand?.value || "-";
      case ClientDiscountRuleType.Gs1Segment:
        return this.gs1Segment?.name || "-";
      case ClientDiscountRuleType.StorageConditions:
        const storageCondition = this.storageCondition;
        return (
          (storageCondition
            ? this._translatedValue.transform(storageCondition.translatedValue)
            : storageCondition?.value) || "-"
        );
      default:
        return "-";
    }
  }
}

@Component({
  selector: "add-contract-condition-dialog",
  host: { class: "dialog" },
  templateUrl: "./add-contract-condition.dialog.html"
})
export class AddContractConditionDialogComponent {
  public saveLoading = false;
  public deleteLoading = false;

  @ViewChild("dialog", { static: true, read: DialogComponent })
  private _dialog: DialogComponent;

  public form: FormGroup;

  public edit = false;

  public statusOptions = asArray(Status);
  public typeOptions = asArray(ClientDiscountRuleType);

  public formData: IClientFormData;

  public ClientDiscountRuleType = ClientDiscountRuleType;

  public clientId: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public dialogRef: MatDialogRef<AddContractConditionDialogComponent>,
    private _clientsService: ClientsService,
    private _lxmMessage: LxmMessage,
    private _lxmDialog: LxmDialog
  ) {
    const { orderNext, clientId, formData, edit } = _data;

    this.clientId = clientId;
    this.formData = formData;
    this.edit = edit;

    const {
      orderNr,
      type,
      description,
      status,
      discountPercent,
      gs1Segment,
      brand,
      storageConditions,
      id
    } = _data.rule || {};

    const conditionId = this._data.conditionId || null;
    this.form = new FormGroup({
      orderNr: new FormControl(orderNr || orderNext),
      discountRuleType: new FormControl(type || ClientDiscountRuleType.General),
      description: new FormControl(description),
      discountPercent: new FormControl(discountPercent),
      gs1Segment: new FormControl(gs1Segment),
      brand: new FormControl(brand),
      storageCondition: new FormControl(storageConditions?.id),
      id: new FormControl(id),
      conditionId: new FormControl(conditionId),
      status: new FormControl(isNaN(status) ? Status.Active : status)
    });
  }

  public get showConditionField() {
    const type = this.form.get("discountRuleType").value;
    return type && type !== ClientDiscountRuleType.General;
  }

  public get discountRuleType(): ClientDiscountRuleType {
    const type = this.form.get("discountRuleType").value;
    return type;
  }

  public get conditionLabelKey() {
    switch (this.discountRuleType) {
      case ClientDiscountRuleType.Gs1Segment:
        return "cards.clients.contract_conditions.dialog.label.gs1_segment";
      case ClientDiscountRuleType.StorageConditions:
        return "cards.clients.contract_conditions.dialog.label.storage_conditions";
      case ClientDiscountRuleType.Brand:
        return "cards.clients.contract_conditions.dialog.label.brand";
    }
  }

  public get ruleId() {
    return this.form.get("id").value;
  }

  public get conditionId() {
    switch (this.discountRuleType) {
      case ClientDiscountRuleType.Gs1Segment:
        return this.form.get("gs1Segment").value?.id;
      case ClientDiscountRuleType.StorageConditions:
        return this.form.get("storageCondition").value;
      case ClientDiscountRuleType.Brand:
        return this.form.get("brand").value?.id;
    }
  }

  public deleteRule() {
    this._lxmDialog.confirm(
      {
        title: "cards.clients.contract_conditions.dialog.delete.title",
        message: "cards.clients.contract_conditions.dialog.delete.message"
      },
      () => {
        this.deleteLoading = true;
        this._clientsService
          .deleteDiscountRule(this.clientId, this.ruleId)
          .result(
            null,
            res => {
              this.closeDialog();
              this.deleteLoading = false;
            },
            e => {
              console.error(e);
              this.deleteLoading = false;
            }
          );
      }
    );
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public save() {
    const form = this.form.value;
    const clientId = this.clientId;
    const data = {
      type: form.discountRuleType,
      description: form.description,
      discountPercent: form.discountPercent,
      orderNr: form.orderNr,
      status: form.status,
      conditionId: this.conditionId,
      clientId: clientId,
      id: form.id
    };
    this.saveLoading = true;
    this._clientsService.saveDiscountRule(form.id, data).result(
      this.form,
      res => {
        this._lxmMessage.ok({
          message: "cards.clients.contract_conditions.dialog.add_success"
        });
        this.closeDialog();
        this.saveLoading = false;
      },
      e => {
        // this._lxmMessage.error({ message: e.validationSummary });
        this.saveLoading = false;
      }
    );
  }
}
