<search 
    [formGroup]="searchData" 
    placeholder="cards.clients.clients_search.placeholder"
    [isLoading]="isLoading"
    [isOpen]="isOpen"
    (onToggle)="toggleOpen($event)"
    (onSubmit)="submit()">
    <ng-container panel-left>
        <scope translationsPath="cards.clients.clients_search">
            <form-field panel label="client_tags" for="clientTags">
                <lxm-select select
                            labelField="value"
                            [searchable]="true"
                            for="clientTags"
                            [multiple]="true"
                            [options]="clientTagOptions"
                            [classFn]="getItemClass">
                </lxm-select>
            </form-field>
        </scope>
    </ng-container>
</search>