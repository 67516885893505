import { Component, HostBinding, Input, OnInit } from "@angular/core";
import { ICurrency } from "src/app/models";

@Component({
  template: ``
})
export class ProductSchemePriceInfoBase {

  @Input() public scheme: ISchemeInfo;
  @Input() public pricePrecision: number = 3;

  @Input() public displayPriceField: string = 'retailPrice';
  @Input() public currencyField: string = 'currency';

  @Input() public procurementHasPriority = false;

  public get mainPriceScheme(): IMainPriceScheme {
    return this.scheme?.mainPriceScheme;
  }

  public get campaign(): ICampaignPriceScheme {
    return this.scheme?.campaignPriceScheme;
  }

  public get procurement(): any {
    return this.scheme?.procurementPriceScheme;
  }

  public get publicPriceScheme(): any {
    return this.scheme?.publicPriceScheme;
  }

  public get hasMainPriceScheme(): boolean {
    return this.mainPriceScheme ? true : false;
  }

  public get hasCampaign(): boolean {
    return this.campaign ? true : false;
  }

  public get hasProcurement(): boolean {
    return this.procurement ? true : false;
  }

  public get hasPublicPriceScheme(): boolean {
    return this.publicPriceScheme ? true : false;
  }

  public get priorityScheme() {
    if (this.procurementHasPriority && this.hasProcurement) {
      return this.procurement;
    }
    if (this.hasCampaign) {
      return this.campaign;
    }
    if (this.hasMainPriceScheme) {
      return this.mainPriceScheme;
    }
    if (this.hasProcurement) {
      return this.procurement;
    }
    if (this.hasPublicPriceScheme) {
      return this.publicPriceScheme;
    }
    return null;
  }

  public getPrioritySchemeField(field: string) {
    return this.priorityScheme?.[field];
  }

  public get displayPrice(): number {
    return this.getPrioritySchemeField(this.displayPriceField);
  }

  public get displayCurrency(): ICurrency {
    return this.getPrioritySchemeField(this.currencyField);
  }

}


@Component({
  selector: "product-scheme-display-price",
  templateUrl: "./product-scheme-display-price.component.html"
})
export class ProductSchemeDisplayPriceComponent extends ProductSchemePriceInfoBase implements OnInit {

  @Input() public colored = true;
  @HostBinding('class') public displayScheme: string;

  ngOnInit() {
    if (this.colored) {
      if (this.procurementHasPriority && this.hasProcurement) {
        this.displayScheme = 'procurement-scheme';
      } else if (this.hasCampaign) {
        this.displayScheme = 'campaign-scheme';
      } else if (this.hasMainPriceScheme) {
        this.displayScheme = 'main-scheme';
      }  else if (this.hasProcurement) {
        this.displayScheme = 'procurement-scheme';
      } else if (this.hasPublicPriceScheme) {
        this.displayScheme = 'public-scheme';
      }
    }
  }

}

@Component({
  selector: "product-scheme-unit-price",
  templateUrl: "./product-scheme-unit-price.component.html"
})
export class ProductSchemeUnitPriceComponent extends ProductSchemePriceInfoBase {

  @Input() public unitPriceField: 'retailUnitPrice';
  @Input() public contentUnitId: string;

  public get unitPrice() {
    return this.getPrioritySchemeField(this.unitPriceField);
  }

}




export interface ISchemeInfo {
  mainPriceScheme: IMainPriceScheme;
  campaignPriceScheme: ICampaignPriceScheme;
  procurementPriceScheme: IRetailerProcurementPriceScheme | ISupplierProcurementPriceScheme;
  publicPriceScheme?: IPublicPriceScheme;
}


export interface ICampaignInfo {
  type: string;
  campaignPrice: number;
  from: string;
  to: string;
  campaignDiscount: number;
  retailSalesPrice: number;
  retailerDiscount: number;
}

export interface IProductCampaignScheme {
  mainPriceScheme: IMainPriceScheme;
  campaignPriceScheme: ICampaignPriceScheme;
  procurementPriceScheme: ISupplierProcurementPriceScheme | IRetailerProcurementPriceScheme;
  mainPrice: number;
  publicPrice: number;
  retailPrice: number;
  mainCurrency: ICurrency;
}

export interface IPublicPriceScheme {

}

export interface ICampaignPriceScheme {
  currency: ICurrency;
  publicPrice: number;
  retailPrice: number;
  validFrom: Date;
  validTo: Date;
}

export interface ISupplierProcurementPriceScheme {
  currency: ICurrency;
  publicPrice: number;
  retailPrice: number;
  validFrom: Date;
  validTo: Date;
}

export interface IRetailerProcurementPriceScheme {
  buyInPrice: number;
  buyInUnitPrice: number;
  currency: ICurrency;
  listPrice: number;
  logisticsPartner: string;
  retailPrice: number;
  retailPriceWithoutVat: number;
  retailUnitPrice: number;
  retailUnitPriceWithoutVat: number;
  validFrom: Date;
  validTo: Date;
}

export interface IMainPriceScheme {
  currency: ICurrency;
  publicPrice: number;
  retailPrice: number;
  validFrom: Date;
  validTo: Date;
}