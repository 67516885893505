export enum ProductUnitField {
    NetWeight = 1,
    GrossWeight = 2,
    Height = 3,
    Width = 4,
    Length = 5,
    IsCompressable = 6,
    CompressedHeight = 7,
    CompressedWidth = 8,
    CompressedLength = 9,
    HasPegHole = 10,
    IsStackable = 11,
    MaxStack = 12,
    IsOrderUnit = 13,
    IsReturnablePackage = 14,
    IsShelfReadyPackage = 15,
    ProductFaceCount = 16,
    BaseUnitsCountInLayer = 17,
    LayerCount = 18,
    SubUnitCount = 19,
    BaseUnitCount = 20,
    IsReusablePackage = 21,
    PackageName = 22,
    Status = 23,
    Type = 24,
    RetailChain = 25,
    SubUnit = 26,
    PackageType = 27,
    PalletType = 28,
    Codes = 29,
    PackageDeposits = 30,
    PackageMaterials = 31,
    Tares = 32,
    PickingUnit = 33,
    HasVariableWeight = 34,
    IsInventoryUnit = 35
}

(ProductUnitField as any).__typeName = 'ProductUnitField';

const xBase = 'cards.products.product_packaging_edit_basic_unit.label.';
const xPallet = 'cards.products.product_packaging_edit_pallet.label.';
const xDisplay = 'cards.products.product_packaging_edit_display.label.';
const xTransport = 'cards.products.product_packaging_edit_transport_unit.label.';

(ProductUnitField as any).__altTranslationKeys = {
  [ProductUnitField.NetWeight]: `${xBase}unit_net_weight`,
  [ProductUnitField.GrossWeight]: `${xBase}unit_gross_weight`,
  [ProductUnitField.Height]: `${xBase}unit_height`,
  [ProductUnitField.Width]: `${xBase}unit_width`,
  [ProductUnitField.Length]: `${xBase}unit_length`,
  [ProductUnitField.IsCompressable]: `${xBase}is_compressable.label`,
  [ProductUnitField.CompressedHeight]: `${xBase}unit_compressed_height`,
  [ProductUnitField.CompressedWidth]: `${xBase}unit_compressed_width`,
  [ProductUnitField.CompressedLength]: `${xBase}unit_compressed_length`,
  [ProductUnitField.HasPegHole]: `${xBase}has_peghole.label`,
  [ProductUnitField.IsStackable]: `${xBase}is_stackable.label`,
  [ProductUnitField.MaxStack]: `${xBase}max_units_in_stack`,
  [ProductUnitField.IsOrderUnit]: `${xDisplay}unit_ordering.label`,
  [ProductUnitField.IsReturnablePackage]: `${xDisplay}unit_packaging_returnable.label`,
  [ProductUnitField.IsShelfReadyPackage]: `${xTransport}unit_shelf_ready.label`,
  [ProductUnitField.ProductFaceCount]: `${xTransport}unit_facings`,
  [ProductUnitField.BaseUnitsCountInLayer]: `${xPallet}unit_contained_base_units_amount`,
  [ProductUnitField.LayerCount]: `${xPallet}pallet_layer_amount`,
  [ProductUnitField.SubUnitCount]: `${xDisplay}unit_contained_count`,
  [ProductUnitField.BaseUnitCount]: `${xDisplay}base_unit_count`,
  [ProductUnitField.IsReusablePackage]: `${xBase}unit_is_package_reusable`,
  [ProductUnitField.PackageName]: `${xBase}unit_package_name`,
  // [ProductUnitField.Status]: ``,
  [ProductUnitField.Type]: `${xBase}unit_type`,
  [ProductUnitField.RetailChain]: `${xPallet}retailer`,
  [ProductUnitField.SubUnit]: `${xDisplay}unit_contained`,
  [ProductUnitField.PackageType]: `${xBase}unit_package_type`,
  [ProductUnitField.PalletType]: `${xPallet}pallet_type`,
  [ProductUnitField.Codes]: `${xDisplay}unit_code`,
  [ProductUnitField.PackageDeposits]: `${xBase}deposit`,
  [ProductUnitField.PackageMaterials]: `${xBase}package_material`,
  [ProductUnitField.Tares]: `${xDisplay}unit_tare`,
  [ProductUnitField.PickingUnit]: `${xBase}picking_unit`,
  [ProductUnitField.HasVariableWeight]: `${xBase}has_variable_weight`,
  [ProductUnitField.IsInventoryUnit]: `${xTransport}unit_is_inventory_unit.label`,

};
