import { Component, Injector } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ProductCardBase } from '../product-card-base';
import { IProductCardMovieInfo, IClvDto } from '../product-info/product-info.model';
import { Observable } from 'rxjs';
import { asArray, IEnumValue, asObject, UserAction, ProductCardSection, ProductField , ValidType} from 'src/app/enum';
import { formUtil, keep } from 'src/app/util/form-util';
import { ProductValidity } from 'src/app/_services';
import { LOCAL_STORAGE_KEY } from 'src/app/config';

@Component({
  selector: 'product-movie-card',
  templateUrl: './product-movie.card.html',
  styleUrls: ['./product-movie.card.scss']
})
export class ProductMovieCard extends ProductCardBase<IProductCardMovieInfo> {

  public UserAction = UserAction;
  public get title() { return 'cards.products.product_movie_info.title'; }

  public cardSection = ProductCardSection.MovieInfo;

  public movieMediaFormatOptions: IClvDto[];
  public movieDvdRegionOptions: IClvDto[];
  public movieAspectRatioOptions: IClvDto[];
  public movieGenreOptions: IClvDto[];
  public movieContentRatingOptions: IClvDto[];
  public movieRatingFormatOptions: IClvDto[];
  public movieSubtitleTypeOptions: IClvDto[];
  public movieActorRoleOptions: IClvDto[];

  public isExpandedKey = LOCAL_STORAGE_KEY.PRODUCT_MOVIE_CARD_OPEN;

  constructor(injector: Injector, public productValidity: ProductValidity) {
    super(injector, 'movieInfo', 'productMovieInfoChanged');
    this.movieMediaFormatOptions = this._productCard.formData.movieMediaFormats;
    this.movieDvdRegionOptions = this._productCard.formData.movieDvdRegions;
    this.movieAspectRatioOptions = this._productCard.formData.movieAspectRatios;
    this.movieGenreOptions = this._productCard.formData.movieGenres;
    this.movieContentRatingOptions = this._productCard.formData.movieContentRatings;
    this.movieRatingFormatOptions = this._productCard.formData.movieRatingFormats;
    this.movieSubtitleTypeOptions = this._productCard.formData.movieSubtitleTypes;
    this.movieActorRoleOptions = this._productCard.formData.movieActors;
  }

  public get cardValidType(): ValidType {
    return this.productValidity.getCardStatusValidType(this.cardSection);
  }

  public get shouldExpandSections() {
    return this.productValidity.shouldValidate ? (
      (this.cardValidType === ValidType.Invalid || this.cardValidType === ValidType.Recommended) ? true : false
      ) : false;
  }

  protected _getNormalizedFormData(data: IProductCardMovieInfo) {
    return formUtil.transformData(data, {
      mediaFormatId: () => data.mediaFormat?.id,
      dvdRegionId: () => data.dvdRegion?.id,
      aspectRatioId: () => data.aspectRatio?.id,
      duration: keep,
      genres: val => val?.map(x => x.id),
      year: keep,
      releaseYear: keep,
      productionCountries: val => val?.map(x => x.id),
      productionCompany: keep,
      contentRatings: val => val?.map(x => x.id),
      formats: keep,
      audio:keep,
      actors:keep
    });
  }
  protected _createFormGroup(data: IProductCardMovieInfo): FormGroup {
    return formUtil.createFormGroup(this._getNormalizedFormData(data));
  }

  protected _createSaveRequest() {
    const data = this.form.value;
    return {
      mediaFormatId:data.mediaFormatId,
      dvdRegionId:data.dvdRegionId,
      aspectRatioId:data.aspectRatioId,
      duration:data.duration,
      genres:data.genres,
      year:data.year,
      releaseYear:data.releaseYear,
      productionCountries: data.productionCountries,
      productionCompany: data.productionCompany,
      contentRatings: data.contentRatings,
      formats: data.formats?.filter((x: any) => !x.isDeleted)
        .map((x: any) => ({
          id: x.id,
          formatId: x.formatId,
          ratingId: x.ratingId
        })),
      audio: data.audio?.filter((x: any) => !x.isDeleted)
        .map((x: any) => ({
          id: x.id,
          language: x.language?.id || x.language,
          typeId: x.typeId,
          isDeaf: x.isDeaf || false
        })),
      actors: data.actors?.filter((x: any) => !x.isDeleted)
        .map((x: any) => ({
          id: x.id,
          roleId: x.roleId,
          personal: x.personal,
          description:x.description
        })),
    };
  }

  protected _saveInternal(req: any): Observable<any> {
    return this._productService.saveMovieInfo(this.productId, req);
  }

  protected get successMessage(): string {
    return 'cards.products.product_movie_info.save_successful';
  }

}
