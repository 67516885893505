import { Component, OnInit } from "@angular/core";
import { TitleService } from 'src/app/_services/title.service';

@Component({
  selector: "subscription-layout",
  templateUrl: "./subscription.layout.html",
  host: { 'class': 'tab-content' },
  styleUrls: ['./subscription.layout.scss']
})
export class SubscriptionLayout implements OnInit {
  title = "cards.lexi_market_settings.lxm_settings_subscription.title";

  constructor(private _titleService: TitleService) { }

  public ngOnInit() {
    this._titleService.setTitle(this.title);
  }

}
