<ng-container [formGroup]="form">
  <scope translationsPath="cards.lexi_market_settings.lxm_settings_company_profile">
    <card-section>
      <span header-title style="font-size: 24px; font-weight: bold;">
        {{'cards.lexi_market_settings.lxm_settings_company_profile.title' | translate }}
      </span>
      <span header-actions>
        <lxm-button type="beige" class="ml15" icon="save" label="action.save" [loading]="actionLoading.save" (click)="saveProfile()"></lxm-button>
      </span>
      <div body class="row">
        <div class="col-8 no-padding">
          <form-field for="companyName" label="company_name" type="input-text">
            <validation-errors for="companyName"></validation-errors>
          </form-field>
          <form-field for="regNo" label="company_register_code" type="input-text" [readonly]="true">
            <validation-errors for="regNo"></validation-errors>
          </form-field>
          <form-field label="company_address_country">
            <lxm-select select
              [options]="countryOptions"
              [isTranslated]="true"
              labelField="translatedName"
              [sortByLabel]="true"
              [searchable]="true"
              for="country">
            </lxm-select>
            <validation-errors for="address.countryId" aliasTo="country"></validation-errors>
          </form-field>
          <form-field for="county" label="company_address_county" type="input-text"></form-field>
          <form-field for="city" label="company_address_city" type="input-text"></form-field>
          <form-field for="postalCode" label="company_address_zip" type="input-text"></form-field>
          <form-field for="addressStreet" label="company_address_street" type="input-text"></form-field>
          <form-field for="addressNumber" label="company_address_number" type="input-text"></form-field>
          <form-field for="email" label="company_e_mail" type="input-email">
            <validation-errors for="email"></validation-errors>
          </form-field>
          <form-field label="company_phone_short">
            <span any row class="w-full">
              <lxm-select for="phoneCodeId" class="dialog-input-fixed-width-1 mr10"
                          value="id"
                          labelField="value"
                          [searchable]="true"
                          [options]="phoneCodes">
              </lxm-select>
              <input type="tel" class="lxm-input" formControlName="contactPhone" />
            </span>
            <validation-errors for="phoneCode"></validation-errors>
            <validation-errors for="contactPhone"></validation-errors>
          </form-field>
          <form-field for="vatNo" label="company_vat_number" type="input-text"></form-field>
          <form-field for="gln" label="company_gln" type="input-text"></form-field>
        </div>
        <div class="col-4 no-padding"></div>
      </div>
    </card-section>
  </scope>

  <div class="separator"></div>

  <scope translationsPath="cards.lexi_market_settings.lxm_settings_company_description">
    <card-section>
      <span header-title>{{'cards.lexi_market_settings.lxm_settings_company_description.title' | translate }}</span>
      <div body>
        <div class="row">
          <div class="col-8 no-padding">
            <form-field for="www" label="company_website" type="input-text"></form-field>
            <form-field label="company_about">
              <lxm-textarea class="w-full" any for="description"
                            [minRows]="2"
                            [maxlength]="2000">
              </lxm-textarea>
            </form-field>
            <form-field label="company_logo">
              <image-drop-box any
                (onChange)="uploadFile($event)"
                (onDelete)="deleteAttachment($event)"
                [files]="files"
                [src]="logoSrc"
                [contentType]="logoContentType"
                [base64]="logoBase64"
                [uploadInProgress]="uploadInProgress"
                [uploadingFile]="uploadingFile">
              </image-drop-box>
            </form-field>
          </div>
          <div class="col-4 no-padding">

          </div>
        </div>
      </div>
    </card-section>
  </scope>

</ng-container>
