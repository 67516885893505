import { Component, Input, Optional, Host, OnInit, SimpleChange, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormGroup, ControlContainer } from '@angular/forms';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';

@Component({
  selector: "lxm-timepicker",
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LxmTimepickerComponent implements OnInit {

  public TimepickerDisplayType = TimepickerDisplayType;

  @Input() public formGroup: FormGroup;
  @Input() public for: string;
  @Input() public readonly = false;
  @Input() public format = 24;
  @Input() public appendToInput = false;
  @Input() public enableKeyboardInput = true;
  @Input() public defaultTime: string = null;
  @Input() public displayType = TimepickerDisplayType.Field;
  @Input() public disabled = false;
  @Input() public is24h = true;

  public disableAnimation = true;

  public theme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: 'white',
      buttonColor: 'var(--dirty-purple)'
    },
    dial: {
        dialBackgroundColor: 'white',
        dialActiveColor: 'var(--tangerine)',
        dialEditableActiveColor: 'var(--tangerine)',
        dialInactiveColor: 'var(--dirty-purple)'
    },
    clockFace: {
        clockFaceBackgroundColor: 'white',
        clockHandColor: 'var(--tangerine)',
        clockFaceTimeInactiveColor: 'var(--dirty-purple)'
    }
  }

  constructor(@Optional() @Host() private _controlContainer: ControlContainer) {}

  public ngOnInit(): void {
    if (this._controlContainer && this._controlContainer.control instanceof FormGroup) {
      this.formGroup = <FormGroup>this._controlContainer.control;
    }
    if (!this.defaultTime) {
      this.defaultTime = this.value;
    }
    this.handleDisabled();
  }

  public handleDisabled(disabled = this.disabled) {
    if (disabled === true) {
      this.formGroup.get(this.for).disable();
    } else if(disabled === false) {
      this.formGroup.get(this.for).enable();
    }
  }

  public ngOnChanges(changes: SimpleChanges) {

    if (changes?.disabled && !changes.disabled.isFirstChange()) {
      const { currentValue } = changes.is24h || {};
      this.handleDisabled(currentValue);
    }

    if (changes?.is24h && !changes.is24h.isFirstChange()) {
      const { currentValue, previousValue } = changes.is24h || {};

      if (currentValue === true) {
        this.toggle24h(true);
      } else if (currentValue === false) {
        this.toggle24h(false);
      }

    }
  }

  public get value() {
    return this.formGroup.get(this.for)?.value;
  }

  public toggle24h(is24h: boolean) {
    const ref = this.formGroup.get(this.for);
    if (!ref) return;

    is24h ? ref.setValue('00:00') : ref.setValue(this.defaultTime);
  }

  public onTimeChanged(e) {
    this.formGroup.get(this.for)?.setValue(e);
  }

}

enum TimepickerDisplayType {
  Regular = 1,
  Field = 2
}