import { ChangeDetectorRef, Component, Input, ViewEncapsulation } from "@angular/core";
import { Router, RouterEvent, NavigationEnd } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";

@Component({
  selector: "lxm-breadcrumb",
  templateUrl: "./lxm-breadcrumb.component.html",
  styleUrls: ['./lxm-breadcrumb.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LxmBreadcrumbComponent {

  private _subscription: Subscription;
  private _langSubscription: Subscription;

  constructor(private _cd: ChangeDetectorRef, private _router: Router, private _translateService: TranslateService) {}

  ngAfterViewInit() {
    this._cd.detach();

    this._subscription = this._router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
      .subscribe(event => {
        this._cd.detectChanges();
      }
    );

    this._langSubscription = this._translateService.onLangChange.subscribe(() => {
      this._cd.detectChanges();
    });
  }

  ngOnDestroy() { 
    if (this._subscription) {
      this._subscription.unsubscribe();
    }

    if (this._langSubscription) {
      this._langSubscription.unsubscribe();
    }
  }

}
