import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserAction } from '../enum';
import { AuthenticationService } from '../_services';

@Directive({
  selector: '[hasRight]'
})
export class HasRightDirective {

  constructor(
    private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef,
    private _authService: AuthenticationService) {
  }

  @Input()
  set hasRight(actions: UserAction[]) {
    const hasRight = this._authService.hasRight(actions);
    if(hasRight) {
      this._viewContainer.createEmbeddedView(this._templateRef);
    } else {
      this._viewContainer.clear();
    }
  }

}
