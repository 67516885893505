<card-expanding>
  <span header-title>{{'cards.settings.assortment_management.title' | translate}}</span>

  <div body>
    <tab-options [localStorageKey]="tabLocalStorageKey" [options]="options" (change)="onTabSelect(elTabOptions?.selectedOption)" #elTabOptions></tab-options>
    <assortment-clv-table [canEdit]="canEdit" [initialData]="initialData" *ngIf="tabType === TabType.Clv"></assortment-clv-table>
    <assortment-groups [canEdit]="canEdit" *ngIf="tabType === TabType.AssortmentGroups"></assortment-groups>
    <assortment-schemes [canEdit]="canEdit" [initialData]="initialData" *ngIf="tabType === TabType.AssortmentSchemes"></assortment-schemes>
    <assortment-group-tags [canEdit]="canEdit" *ngIf="tabType === TabType.AssortmentGroupTags"></assortment-group-tags>
  </div>

</card-expanding>
