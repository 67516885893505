import { Component } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ICompanyProfileCard } from 'src/app/models/settings/company-profile';
import { FileService, SettingsService } from 'src/app/_services';
import { tap } from 'rxjs/operators';
import { LxmMessage } from 'src/app/_helpers';
import { ICountryTranslated } from '../../product/product-info/product-info.model';
import { environment } from 'src/environments/environment';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: "company-profile-card",
  templateUrl: './company-profile.card.html',
  styleUrls: ['./company-profile.scss']
})
export class CompanyProfileCardComponent {

  public form: FormGroup;

  public profileInfo;
  public phoneCodes: any[];
  public countryOptions: ICountryTranslated[];

  public saveProfileLoading = false;
  public uploadInProgress = false;
  public uploadingFile = false;

  public actionLoading = {} as any;

  private _logoPromise: Promise<void> = new Promise((resolve, reject) => {
    resolve();
  })

  constructor(
    route: ActivatedRoute,
    private _fileService: FileService,
    private _message: LxmMessage,
    private _settingsService: SettingsService) {

    const card = route.snapshot.data.companyProfileCard as ICompanyProfileCard;

    this.profileInfo = card?.profileInfo;
    this.phoneCodes = card.formData.phoneCodes;
    this.countryOptions = card.formData.countries;

    this.form = new FormGroup({
      companyName: new FormControl(this.profileInfo?.companyName),
      regNo: new FormControl(this.profileInfo?.regNo),
      country: new FormControl(this.profileInfo?.country),
      county: new FormControl(this.profileInfo?.address?.county),
      city: new FormControl(this.profileInfo?.address?.city),
      postalCode: new FormControl(this.profileInfo?.address?.postalCode),
      addressStreet: new FormControl(this.profileInfo?.address?.street),
      addressNumber: new FormControl(this.profileInfo?.address?.nr),
      email: new FormControl(this.profileInfo?.email),
      phoneCodeId: new FormControl(this.profileInfo?.phoneCodeId),
      contactPhone: new FormControl(this.profileInfo?.contactPhone),
      vatNo: new FormControl(this.profileInfo?.vatNo),
      bankIban: new FormControl(),
      bankSwift: new FormControl(),
      www: new FormControl(this.profileInfo?.www),
      description: new FormControl(this.profileInfo?.description),
      logoFileId: new FormControl(this.profileInfo?.logoFileId),
      gln: new FormControl(this.profileInfo?.gln)
    });
  }

  public saveProfile() {

    const f = this.form.value;
    const req = {
      companyName: f.companyName,
      regNo: f.regNo,
      vatNo: f.vatNo,
      address: {
        countryId: f.country?.id,
        county: f.county,
        city: f.city,
        street: f.addressStreet,
        nr: f.addressNumber,
        postalCode: f.postalCode
      },
      email: f.email,
      phoneCodeId: f.phoneCodeId,
      contactPhone: f.contactPhone,
      www: f.www,
      description: f.description,
      gln: f.gln,
      logoFileId: this.files[0]?.id || f.logoFileId
    };

    this._settingsService.saveCompanyProfile(req)
      .result(this.form, () => {
        this._message.ok({
          message: 'cards.lexi_market_settings.lxm_settings_company_profile.save_successful'
        });
      }, () => {
        this._message.error({
          message: 'cards.lexi_market_settings.lxm_settings_company_profile.save_failed'
        });
      }, "save", this.actionLoading);
  }


  public files = [];


  public customLogoSrc: any;

  public get logoThumbnail() {
    return this.profileInfo?.logoThumbnail;
  }

  public get logoContentType() {
    return this.profileInfo?.logoThumbnailContentType;
  }

  public get logoBase64() {
    return this.customLogoSrc ? false : true;
  }

  public get logoSrc() {
    if (this.customLogoSrc) {
      return this.customLogoSrc;
    }
    return this.logoThumbnail;
  }


  public async uploadFile(FileList: FileList) {

    var image = FileList.item(0);

    if (FileReader && FileList && FileList.length) {
      const fr = new FileReader();
      fr.onload = (e) => {
        this.customLogoSrc = fr.result;
      }
      fr.readAsDataURL(image);
    }

    const file = {
      id: '',
      name: image.name,
      progress: 0,
      error: undefined
    };
    this.files.push(file);

    const formData = new FormData();
    formData.append('file', image);

    this._logoPromise = new Promise((resolve, reject) => {

      this._fileService.uploadImage(formData)
        .subscribe(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round(100 * event.loaded / event.total);
              this.uploadInProgress = true;
              break;
            case HttpEventType.Response:
              file.id = event.body;
              this.uploadInProgress = false;
              resolve();
              break;
          }
        }, error => {
          this.uploadInProgress = false;
          resolve();
          file.progress = -1;
          const field = error?.validationResults?.stream;
          if (field) {
            file.error = field[0].value;
          }
          this._message.error({
            message: 'cards.clients.client_info.error.image_upload_failed'
          });
        });

    });

    await this._logoPromise;
  }

  public deleteLoading = false;
  public deleteAttachment(index: number) {
    this.deleteLoading = true;
    this._fileService.delete(this.files[index].id)
      .toPromise()
      .then(res => {
        if (this.customLogoSrc) {
          this.customLogoSrc = undefined;
        }
        this.files?.splice(index, 1);
        this.deleteLoading = false;
      }).catch(e => {
        this.deleteLoading = false;
      });
  }



}
