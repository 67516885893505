<ng-container *ngIf="anyPriceValueChanged">
  <div
    class="mt10 mb20 flex"
    style="align-items: center; justify-content: space-between"
    [ngSwitch]="offerType"
  >
    <span class="has-changed-warning" *ngSwitchDefault>{{
      "cards.offer.products.prices_has_changed" | translate
    }}</span>
    <span class="has-changed-warning" *ngSwitchCase="OfferType.PriceChanges">{{
      "cards.offer.products.price_agreements_have_changed" | translate
    }}</span>
    <lxm-button
      type="green"
      icon="check"
      (click)="updatePrices()"
      [loading]="updatingPrices"
      label="cards.offer.products.action.update_prices"
      *requiredRights="[UserAction.ManageSupplierOffers]"
    ></lxm-button>
  </div>
</ng-container>

<div *ngIf="showNoContentText && dataSource.data?.length === 0">
  <no-content
    image="no-products-inline"
    text="global.no_products_added"
    [inline]="true"
  ></no-content>
</div>

<div class="sticky-table" *ngIf="dataSource.data?.length > 0">
  <div class="sticky-table-body w-full overflow-x" [formGroup]="fgInternal">
    <mat-table
      #table
      [dataSource]="dataSource"
      matSort
      [@.disabled]="false"
      cdkDropList
      [cdkDropListData]="dataSource"
      (cdkDropListDropped)="dropTable($event)"
      multiTemplateDataRows
      style="position: relative"
      [cdkDropListSortPredicate]="sortPredicate"
    >
      <!-- common -->

      <ng-container matColumnDef="replacement_indicator" sticky>
        <mat-header-cell *matHeaderCellDef> </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <ng-container
            [ngSwitch]="row.value.replacementForProductId ? true : false"
          >
            <div
              class="replacement-indicator-container replacement"
              *ngSwitchCase="true"
            >
              <span class="replacement-indicator-horizontal"></span>
              <span class="replacement-indicator-vertical"></span>
            </div>
            <ng-container *ngSwitchCase="false">
              <div
                class="replacement-indicator-container replaced"
                *ngIf="isRowReplaced(row)"
              >
                <span class="replacement-indicator-horizontal"></span>
                <span class="replacement-indicator-vertical"> </span>
              </div>
            </ng-container>
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="drag" [sticky]="!readonly">
        <mat-header-cell *matHeaderCellDef [hidden]="readonly">
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [hidden]="readonly">
          <div class="f-1 flex justify-center">
            <lxm-button
              type="transparent"
              icon="drag"
              cdkDrag
              cdkDragHandle
              cdkDragRootElement="mat-row"
              [cdkDragData]="row"
              [cdkDragDisabled]="dragDisabled || isRowReplaced(row)"
              padding="0"
            >
            </lxm-button>
          </div>
        </mat-cell>
      </ng-container>

      <!--<ng-container matColumnDef="offer_product_expander">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span *ngIf="row.get('shops').value.length > 0"
              class="expanded-row-icon"
              [class.active]="expandedElements.includes(row.get('productId').value)"
              (click)="toggleExpandRow(row.get('productId').value)">
        </span>
      </mat-cell>
    </ng-container>-->

      <ng-container matColumnDef="product_image" sticky>
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row">
          <image-popover
            [image]="row.get('imageUrl').value"
            [thumbnail]="row.get('thumbnail').value"
            [thumbnailBase64]="true"
            [thumbnailContentType]="row.get('thumbnailContentType').value"
            [status]="row.get('assortmentStatus').value"
          ></image-popover>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_name" sticky>
        <mat-header-cell *matHeaderCellDef>
          <span>
            {{ "cards.offer.products.table.full_name" | translate }}
          </span>
          &nbsp;
          <span> ({{ "cards.offer.products.table.brand" | translate }}) </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row">
          <span class="flex flex-col">
            <span
              class="link line-clamp-2"
              (click)="productClick(row.get('productId').value)"
            >
              {{ row.get("name").value }}
              <ng-container *ngIf="row.get('brand').value">
                ({{ row.get("brand").value }})
              </ng-container>
            </span>
            <span class="alt-text">
              {{ row.get("ean").value }}
              <ng-container *ngIf="row.get('supplierCode').value">
                ({{ row.get("supplierCode").value }})
              </ng-container>
            </span>
          </span>
        </mat-cell>
      </ng-container>

      <!-- <ng-container matColumnDef="offer_product_brand">
      <mat-header-cell *matHeaderCellDef>
        <span [innerHTML]="'cards.offer.products.table.brand' | translate"></span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row" [formGroup]="row">
        {{row.get('brand').value}}
      </mat-cell>
    </ng-container> -->

      <ng-container matColumnDef="offer_product_available_from">
        <mat-header-cell *matHeaderCellDef>
          <span
            [innerHTML]="
              'cards.offer.products.table.offer_product_available_from'
                | translate
            "
          >
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row">
          <lxm-datepicker
            for="availableFrom"
            [readonly]="
              readonly ||
              row.value.isDeleted ||
              row.value.status === OfferProductStatus.Removed
            "
          ></lxm-datepicker>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_recommended_retail_price">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="w-full text-end"
            [innerHTML]="
              'cards.offer.products.table.offer_recommended_retail_price'
                | translate
            "
          >
          </span>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          [formGroup]="row"
          [ngSwitch]="isRowReplaced(row) || row.value.isSuspended"
        >
          <div
            class="f-1 flex flex-row justify-end"
            *ngSwitchCase="true"
            [ngSwitch]="row.value.isSuspended"
          >
            <ng-container *ngSwitchCase="true">
              <span class="suspend-product-icon mr8"></span>
              <span style="flex: 0; word-break: normal">
                {{
                  "cards.offer.products.table.text.product_suspended"
                    | translate
                }}
              </span>
            </ng-container>
            <ng-container *ngSwitchCase="false">
              <span class="product-replacement-out-icon mr8"></span>
              <span style="flex: 0; word-break: normal">
                {{
                  "cards.offer.products.table.text.product_replaced" | translate
                }}
              </span>
            </ng-container>
          </div>
          <ng-container *ngSwitchDefault>
            <div class="triple-rows text-end">
              <div class="cell-row"></div>
              <div class="cell-row text-end">
                <lxm-input
                  class="w-full text-center"
                  for="suggestedRetailPrice"
                  type="price"
                  [pricePrecision]="2"
                  [currency]="currency"
                  [readonly]="
                    readonly ||
                    row.value.isDeleted ||
                    row.value.status === OfferProductStatus.Removed
                  "
                >
                </lxm-input>
              </div>
              <div class="cell-row alt-text text-end">
                <ng-container *ngIf="row.value.suggestedRetailPrice">
                  {{
                    calculateUnitPrice(
                      row.value.suggestedRetailPrice,
                      row.value
                    ) | priceValue: pricePrecision : displayCurrency?.value
                  }}{{
                    calculateUnitPrice(
                      row.value.suggestedRetailPrice,
                      row.value
                    )
                      ? "/" +
                        (row.value.contentUnitId | translateEnum: "UnitShort")
                      : null
                  }}
                </ng-container>
              </div>
            </div>
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell
          *matHeaderCellDef
          [hidden]="readonly"
        ></mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = dataIndex"
          [formGroup]="row"
          [hidden]="readonly"
        >
          <lxm-button
            type="grey"
            width="34px"
            height="34px"
            [icon]="
              row.value.isDeleted ||
              row.value.status === OfferProductStatus.Removed
                ? 'generate'
                : 'trash'
            "
            (click)="!readonly ? removeItem(i) : null"
            title="cards.offer.products.table.delete_product_title"
          >
          </lxm-button>
        </mat-cell>
      </ng-container>

      <!-- main assortment -->

      <ng-container matColumnDef="offer_product_internal_price">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="w-full text-end"
            [innerHTML]="
              'cards.offer.products.table.internal_price' | translate
            "
          >
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row">
          <!-- INTERNAL PRICE -->
          <div class="triple-rows">
            <!-- <div class="cell-row text-end new-price">
            <ng-container *ngIf="hasValidSchemePrice(row.value.validSchemeSelfPrice, row.value.selfPrice)">
              {{ row.value.validSchemeSelfPrice | priceValue }}
            </ng-container>
          </div> -->
            <div
              class="cell-row text-end"
              [class.hidden]="!row.value.selfPrice"
            >
              <!-- [class.old-price]="hasValidSchemePrice(row.value.validSchemeSelfPrice, row.value.selfPrice)" -->
              {{ calculateRowMargin(row.value) | percentValue }}
            </div>
            <div
              class="cell-row w-full text-end"
              [class.old-price]="
                hasValidSchemePrice(
                  row.value.validSchemeSelfPrice,
                  row.value.selfPrice
                )
              "
            >
              {{ row.value.selfPrice | priceValue }}
            </div>
            <div
              class="cell-row alt-text text-end"
              [class.hidden]="!row.value.selfPrice"
            >
              {{
                calculateUnitPrice(row.value.selfPrice, row.value)
                  | priceValue: pricePrecision : displayCurrency?.value
              }}{{
                calculateUnitPrice(row.value.selfPrice, row.value)
                  ? "/" + (row.value.contentUnitId | translateEnum: "UnitShort")
                  : null
              }}
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_list_price">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="w-full text-end"
            innerHTML="{{
              'cards.offer.products.table.internal_pricelist' | translate
            }}"
          >
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row" class="text-center">
          <div class="triple-rows text-end">
            <div class="cell-row new-price text-end">
              <ng-container
                *ngIf="
                  hasValidSchemePrice(
                    row.value.validSchemeListPrice,
                    row.value.listPrice
                  )
                "
              >
                {{
                  row.value.validSchemeListPrice
                    | priceValue: 3 : currency?.code
                }}
              </ng-container>
            </div>
            <div
              class="cell-row text-end"
              [class.old-price]="
                hasValidSchemePrice(
                  row.value.validSchemeListPrice,
                  row.value.listPrice
                )
              "
            >
              {{ row.value.listPrice | priceValue: 3 : currency?.code }}
            </div>
            <div class="cell-row alt-text text-end">
              {{
                calculateUnitPrice(row.value.listPrice, row.value)
                  | priceValue: pricePrecision : displayCurrency?.value
              }}{{
                calculateUnitPrice(row.value.listPrice, row.value)
                  ? "/" + (row.value.contentUnitId | translateEnum: "UnitShort")
                  : null
              }}
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_price_discount">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="w-full text-center"
            [innerHTML]="
              'cards.offer.products.table.offer_discount' | translate
            "
          >
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = dataIndex" [formGroup]="row">
          <div class="f-1 flex flex-col text-center">
            <lxm-input
              [readonly]="
                readonly ||
                row.value.isDeleted ||
                row.value.status === OfferProductStatus.Removed
              "
              type="percent"
              [digits]="2"
              [class.new-price]="
                hasValidSchemePrice(
                  row.value.validSchemeListPrice,
                  row.value.listPrice
                )
              "
              for="discount"
              [id]="'discount_' + i"
            >
            </lxm-input>
            <div
              class="old-price"
              *ngIf="
                hasValidSchemePrice(
                  row.value.validSchemeListPrice,
                  row.value.listPrice
                ) && readonly
              "
            >
              {{
                calculateMargin(
                  row.get("salePrice").value,
                  getListPrice(row.value)
                ) | percentValue
              }}
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_price_retailer">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="w-full text-end"
            [innerHTML]="
              'cards.offer.products.table.client_price_vat_excluded' | translate
            "
          >
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = dataIndex" [formGroup]="row">
          <div class="triple-rows text-end">
            <div class="cell-row"></div>
            <div class="cell-row">
              <lxm-input
                class="text-center"
                type="price"
                [currency]="currency"
                [class.text-end]="
                  readonly ||
                  row.value.isDeleted ||
                  row.value.status === OfferProductStatus.Removed
                "
                [readonly]="
                  readonly ||
                  row.value.isDeleted ||
                  row.value.status === OfferProductStatus.Removed
                "
                for="salePrice"
                [id]="'salePrice_' + i"
                displayFormat="1.3-3"
              >
              </lxm-input>
              <validation-errors
                [for]="'products[' + i + '].salePrice'"
                aliasTo="salePrice"
                [errorsFormGroup]="formGroup"
                [showErrorText]="false"
              ></validation-errors>
            </div>
            <div class="cell-row w-full alt-text text-end">
              {{
                calculateUnitPrice(row.value.salePrice, row.value)
                  | priceValue: pricePrecision : displayCurrency?.value
              }}{{
                calculateUnitPrice(row.value.salePrice, row.value)
                  ? "/" + (row.value.contentUnitId | translateEnum: "UnitShort")
                  : null
              }}
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_requested_price">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="w-full text-end"
            [innerHTML]="
              'cards.offer.products.table.offer_product_requested_price'
                | translate
            "
          >
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row" class="text-end">
          <div class="triple-rows text-end">
            <div class="cell-row"></div>
            <div class="cell-row">
              <span class="w-full text-end">
                {{
                  row.get("requestedPrice").value
                    | priceValue: 3 : currency?.code
                }}
              </span>
            </div>
            <div class="cell-row alt-text text-end">
              <ng-container *ngIf="row.get('requestedPrice').value">
                {{
                  calculateUnitPrice(row.value.requestedPrice, row.value)
                    | priceValue: pricePrecision : displayCurrency?.value
                }}{{
                  calculateUnitPrice(row.value.requestedPrice, row.value)
                    ? "/" +
                      (row.value.contentUnitId | translateEnum: "UnitShort")
                    : null
                }}
              </ng-container>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_offer_margin">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="w-full text-center"
            [innerHTML]="'cards.offer.products.table.offer_margin' | translate"
          >
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row">
          <div class="triple-rows">
            <div class="cell-row new-price text-center">
              <ng-container
                *ngIf="
                  hasValidSchemePrice(
                    row.value.validSchemeSelfPrice,
                    row.value.selfPrice
                  )
                "
              >
                {{ calculateRowValidSchemeMargin(row.value) | percentValue }}
              </ng-container>
            </div>
            <div
              class="cell-row text-center"
              [class.old-price]="
                hasValidSchemePrice(
                  row.value.validSchemeSelfPrice,
                  row.value.selfPrice
                )
              "
            >
              {{ calculateRowMargin(row.value) | percentValue }}
            </div>
            <div class="cell-row text-center"></div>
          </div>
        </mat-cell>
      </ng-container>

      <!-- campaign -->

      <ng-container matColumnDef="offer_product_display">
        <mat-header-cell *matHeaderCellDef>
          <span [innerHTML]="'cards.offer.products.table.display' | translate">
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row">
          <slide-toggle
            for="display"
            [readonly]="readonly || !row.value.productHasDisplay"
          ></slide-toggle>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_price_valid_from_campaign">
        <mat-header-cell *matHeaderCellDef>
          <span
            [innerHTML]="
              'cards.offer.products.table.offer_product_price_valid_from_campaign'
                | translate
            "
          >
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row">
          <lxm-datepicker
            for="campaignPriceValidFrom"
            [readonly]="
              readonly ||
              row.value.isDeleted ||
              row.value.status === OfferProductStatus.Removed
            "
          ></lxm-datepicker>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_self_and_list_price_campaign">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="w-full text-end"
            [innerHTML]="
              'cards.offer.products.table.offer_internal_and_list_price_campaign'
                | translate
            "
          >
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row" class="text-center">
          <div class="triple-rows text-end">
            <div class="cell-row w-full new-price text-end">
              <ng-container
                *ngIf="
                  row.value.validSchemeSelfPrice ||
                  row.value.validSchemeMainAssortmentListPrice ||
                  row.value.validSchemeListPrice
                "
              >
                {{ row.value.validSchemeSelfPrice | priceValue }} /
                {{
                  row.value.validSchemeMainAssortmentListPrice ||
                    row.value.validSchemeListPrice
                    | priceValue: 3 : currency?.code
                }}
              </ng-container>
            </div>
            <div
              class="cell-row w-full text-end"
              *ngIf="
                row.value.validSchemeSelfPrice ||
                row.value.validSchemeMainAssortmentListPrice ||
                row.value.validSchemeListPrice
              "
            >
              {{
                calculateUnitPrice(row.value.validSchemeSelfPrice, row.value)
                  | priceValue: pricePrecision : displayCurrency?.value
              }}{{
                calculateUnitPrice(row.value.validSchemeSelfPrice, row.value)
                  ? "/" + (row.value.contentUnitId | translateEnum: "UnitShort")
                  : null
              }}
              /
              {{
                calculateUnitPrice(
                  row.value.validSchemeMainAssortmentListPrice,
                  row.value
                ) | priceValue: pricePrecision : displayCurrency?.value
              }}{{
                calculateUnitPrice(
                  row.value.validSchemeMainAssortmentListPrice,
                  row.value
                )
                  ? "/" + (row.value.contentUnitId | translateEnum: "UnitShort")
                  : null
              }}
            </div>
            <div
              class="cell-row w-full text-end"
              [class.old-price]="
                row.value.validSchemeSelfPrice ||
                row.value.validSchemeMainAssortmentListPrice ||
                row.value.validSchemeListPrice
              "
            >
              {{ row.value.selfPrice | priceValue }} /
              {{
                row.value.mainAssortmentListPrice || row.value.listPrice
                  | priceValue: 3 : currency?.code
              }}
            </div>
            <div class="cell-row w-full alt-text justify-end text-end">
              {{
                calculateUnitPrice(row.value.selfPrice, row.value)
                  | priceValue: pricePrecision : displayCurrency?.value
              }}{{
                calculateUnitPrice(row.value.selfPrice, row.value)
                  ? "/" + (row.value.contentUnitId | translateEnum: "UnitShort")
                  : null
              }}
              /
              {{
                calculateUnitPrice(row.value.mainAssortmentListPrice, row.value)
                  | priceValue: pricePrecision : displayCurrency?.value
              }}{{
                calculateUnitPrice(row.value.mainAssortmentListPrice, row.value)
                  ? "/" + (row.value.contentUnitId | translateEnum: "UnitShort")
                  : null
              }}
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_main_assortment_price_campaign">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="w-full text-end"
            [innerHTML]="
              'cards.offer.products.table.main_assortment_price_campaign'
                | translate
            "
          >
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row">
          <div class="triple-rows">
            <div class="cell-row new-price">
              <ng-container *ngIf="row.value.validSchemeMainAssortmentPrice">
                {{
                  row.value.validSchemeMainAssortmentPrice
                    | priceValue: 3 : currency?.code
                }}
                /
                {{
                  calculateListPriceDiscount(
                    row.value.validSchemeMainAssortmentPrice,
                    getListPrice(row.value)
                  ) | percentValue
                }}
              </ng-container>
            </div>
            <div
              class="cell-row w-full alt-text justify-end text-end"
              *ngIf="row.value.validSchemeMainAssortmentPrice"
            >
              {{
                calculateUnitPrice(
                  row.value.validSchemeMainAssortmentPrice,
                  row.value
                ) | priceValue: pricePrecision : displayCurrency?.value
              }}{{
                calculateUnitPrice(
                  row.value.validSchemeMainAssortmentPrice,
                  row.value
                )
                  ? "/" + (row.value.contentUnitId | translateEnum: "UnitShort")
                  : null
              }}
            </div>
            <div
              class="cell-row text-end"
              [class.old-price]="row.value.validSchemeMainAssortmentPrice"
            >
              {{
                row.value.mainAssortmentPrice | priceValue: 3 : currency?.code
              }}
              /
              {{
                calculateListPriceDiscount(
                  row.value?.mainAssortmentPrice,
                  getListPrice(row.value)
                ) | percentValue
              }}
            </div>
            <div class="cell-row w-full alt-text justify-end text-end">
              {{
                calculateUnitPrice(row.value.mainAssortmentPrice, row.value)
                  | priceValue: pricePrecision : displayCurrency?.value
              }}{{
                calculateUnitPrice(row.value.mainAssortmentPrice, row.value)
                  ? "/" + (row.value.contentUnitId | translateEnum: "UnitShort")
                  : null
              }}
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_price_discount_campaign">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="w-full text-center"
            [innerHTML]="
              'cards.offer.products.table.offer_discount_campaign' | translate
            "
          >
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = dataIndex" [formGroup]="row">
          <div class="f-1 flex flex-col text-center">
            <lxm-input
              [readonly]="
                readonly ||
                row.value.isDeleted ||
                row.value.status === OfferProductStatus.Removed
              "
              type="percent"
              [digits]="2"
              [class.new-price]="row.value.validSchemeMainAssortmentPrice"
              for="discountCampaign"
              [id]="'discount_campaign_' + i"
            >
            </lxm-input>
            <div
              class="old-price"
              *ngIf="row.value.validSchemeMainAssortmentPrice && readonly"
            >
              {{ calculateCampaignDiscountMargin(row.value) | percentValue }}
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_price_retailer_campaign">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="w-full text-end"
            [innerHTML]="
              'cards.offer.products.table.offer_product_price_retailer_campaign'
                | translate
            "
          >
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = dataIndex" [formGroup]="row">
          <div class="triple-rows text-end">
            <div class="cell-row"></div>
            <div class="cell-row w-full text-end" [ngSwitch]="readonly">
              <lxm-input
                class="text-center"
                *ngSwitchCase="false"
                for="salePriceCampaign"
                type="price"
                [currency]="currency"
                [id]="'salePriceCampaign_' + i"
                [readonly]="
                  row.value.isDeleted ||
                  row.value.status === OfferProductStatus.Removed
                "
              >
              </lxm-input>
              <ng-container *ngSwitchCase="true">
                {{ row.get("salePriceCampaign").value | priceValue }}
              </ng-container>
            </div>
            <div class="cell-row w-full alt-text justify-end text-end">
              {{
                calculateUnitPrice(row.value.mainAssortmentPrice, row.value)
                  | priceValue: pricePrecision : displayCurrency?.value
              }}{{
                calculateUnitPrice(row.value.mainAssortmentPrice, row.value)
                  ? "/" + (row.value.contentUnitId | translateEnum: "UnitShort")
                  : null
              }}
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_campaign">
        <mat-header-cell *matHeaderCellDef class="mat-cell-center">
          <!-- <span [innerHTML]="'cards.offer.products.table.campaign_info' | translate">
        </span> -->
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = dataIndex"
          [formGroup]="row"
          class="mat-cell-center"
        >
          <div class="f-1 flex justify-center">
            <lxm-button
              type="grey"
              width="34px"
              height="34px"
              (click)="openCampaignDialog(row)"
              [icon]="
                hasExistingCampaignInfo(row.value || {})
                  ? 'campaign-info-filled'
                  : 'campaign-info-empty'
              "
              [title]="
                hasExistingCampaignInfo(row.value || {})
                  ? 'cards.offer.products.table.action.campaign_info_edit_dialog'
                  : 'cards.offer.products.table.action.campaign_info_view_dialog'
              "
            >
            </lxm-button>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_campaign_type">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="w-full"
            [innerHTML]="
              'cards.offer.products.table.offer_product_campaign_type'
                | translate
            "
          >
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = dataIndex" [formGroup]="row">
          <lxm-select
            for="campaignType"
            [options]="campaignTypes"
            [searchable]="true"
            [sortByLabel]="true"
            [readonly]="readonly"
            labelField="value"
            class="w-full"
            (change)="onCampaignTypeChange($event, row)"
          >
            <!-- <validation-errors [for]="'products['+(i)+'].campaignTypeId'" aliasTo="campaignType"
          [errorsFormGroup]="formGroup" [showErrorText]="false"></validation-errors> -->
          </lxm-select>
        </mat-cell>
      </ng-container>

      <ng-container
        matColumnDef="offer_product_campaign_discount_price_agreement"
      >
        <mat-header-cell *matHeaderCellDef>
          <span
            class="w-full"
            [innerHTML]="
              'cards.offer.products.table.offer_product_campaign_discount_price_agreement'
                | translate
            "
          >
          </span>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          [formGroup]="row"
          [class.clickable-cell]="
            !readonly && row.value.status !== OfferProductStatus.Rejected
          "
          (click)="
            !readonly && row.value.status !== OfferProductStatus.Rejected
              ? row.controls['editCampaignSalePrice'].setValue(
                  !row.value.editCampaignSalePrice
                )
              : undefined
          "
        >
          <div
            class="flex flex-1 flex-col"
            [class.gap-1]="row.value.editCampaignSalePrice"
          >
            <div>
              {{
                (campaignWithTypesDiscountPrice(row.value)
                  | priceValue
                    : 3
                    : row.get("mainAssortmentCurrency").value?.value) || "-"
              }}
            </div>
            <div class="alt-text">
              <div class="cell-row f-1">
                <lxm-input
                  *ngIf="row.value.editCampaignSalePrice"
                  stop-propagation
                  for="discountCampaignInput"
                  [readonly]="
                    readonly ||
                    row.value.isDeleted ||
                    row.value.status === OfferProductStatus.Removed ||
                    !row.value.editCampaignSalePrice
                  "
                  type="percent"
                  [digits]="2"
                  [id]="'discount_campaign_' + i"
                ></lxm-input>
                <ng-container *ngIf="!row.value.editCampaignSalePrice">
                  {{
                    calculateCampaignWithTypesDiscountMargin(row.value)
                      | percentValue
                  }}
                </ng-container>
              </div>
            </div>
            <div class="flex flex-row flex-wrap items-center">
              <div class="bold">
                <lxm-input
                  *ngIf="row.value.editCampaignSalePrice"
                  stop-propagation
                  class="text-center"
                  for="salePriceCampaignInput"
                  type="price"
                  [currency]="currency"
                  [id]="'salePriceCampaign_' + i"
                  [readonly]="
                    row.value.isDeleted ||
                    row.value.status === OfferProductStatus.Removed ||
                    !row.value.editCampaignSalePrice
                  "
                ></lxm-input>
                <ng-container *ngIf="!row.value.editCampaignSalePrice">
                  {{ row.get("salePriceCampaign").value | priceValue }}
                </ng-container>
              </div>
            </div>
            <div
              class="size-[34px]"
              *ngIf="!readonly && row.value.editCampaignSalePrice"
              [class.no-display]="
                row.value.status === OfferProductStatus.Rejected
              "
            >
              <div
                style="
                  position: absolute;
                  display: flex;
                  z-index: 1;
                  width: 100%;
                  gap: 20px;
                "
              >
                <lxm-button
                  stop-propagation
                  type="transparent"
                  (click)="discardCampaignDiscountPriceAgreement(row)"
                  width="16px"
                  padding="0"
                  icon="undo"
                ></lxm-button>
                <lxm-button
                  stop-propagation
                  type="transparent"
                  (click)="setCampaignDiscountPriceAgreement(row)"
                  width="16px"
                  padding="0"
                  icon="accept"
                ></lxm-button>
              </div>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_campaign_retail_price_discount">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="w-full text-center"
            [innerHTML]="
              'cards.offer.products.table.offer_product_campaign_retail_price_discount'
                | translate
            "
          >
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = dataIndex" [formGroup]="row">
          <div class="f-1 flex flex-col text-center">
            <lxm-input
              *ngIf="isPercentCampaignType(row)"
              [readonly]="
                readonly ||
                row.value.isDeleted ||
                row.value.status === OfferProductStatus.Removed
              "
              type="percent"
              [digits]="2"
              [class.new-price]="row.value.validSchemeMainAssortmentPrice"
              for="discountCampaignWithType"
              [id]="'discount_campaign_' + i"
            >
            </lxm-input>
            <lxm-input
              *ngIf="isValueCampaignType(row)"
              [readonly]="
                readonly ||
                row.value.isDeleted ||
                row.value.status === OfferProductStatus.Removed
              "
              type="price"
              [digits]="2"
              [class.new-price]="row.value.validSchemeMainAssortmentPrice"
              for="discountCampaignWithType"
              [id]="'discount_campaign_' + i"
            >
            </lxm-input>
            <div
              class="old-price"
              *ngIf="row.value.validSchemeMainAssortmentPrice && readonly"
            >
              {{ calculateCampaignDiscountMargin(row.value) | percentValue }}
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_campaign_retail_price_info">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="w-full text-center"
            [innerHTML]="
              'cards.offer.products.table.offer_product_campaign_retail_price_info'
                | translate
            "
          >
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = dataIndex" [formGroup]="row">
          <div
            class="triple-rows text-end"
            *ngIf="getValidRetailerProductBuyInPriceScheme(row) != null"
          >
            <div class="cell-row w-full text-end">
              {{
                getValidRetailerProductBuyInPriceScheme(row)?.priceWithoutVat
                  | priceValue
              }}
              +{{ "cards.offer.products.table.text.vat" | translate }}
            </div>
            <div class="cell-row w-full text-end">
              {{
                getValidRetailerProductBuyInPriceScheme(row)?.price | priceValue
              }}
            </div>
          </div>
          <div
            class="triple-rows text-end"
            *ngIf="getValidRetailerProductBuyInPriceScheme(row) == null"
          >
            <div class="cell-row w-full text-end">
              {{
                "cards.offer.products.table.text.price_info_is_not_available"
                  | translate
              }}
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <!-- logistics -->

      <ng-container matColumnDef="offer_product_segment">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="w-full"
            [innerHTML]="
              'cards.offer.products.table.offer_product_segment' | translate
            "
          >
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row">
          <div class="w-full">
            {{ row.get("segment").value?.name | formValue }}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_storage_conditions">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="w-full"
            [innerHTML]="
              'cards.offer.products.table.offer_product_storage_conditions'
                | translate
            "
          >
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row">
          <div class="w-full">
            {{ row.get("storageConditions").value | systemClvName | formValue }}
            <ng-container
              *ngIf="
                row.get('storageMinTemp').value &&
                row.get('storageMaxTemp').value
              "
            >
              , {{ row.get("storageMinTemp").value }} ...
              {{ row.get("storageMaxTemp").value }} °C
            </ng-container>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_transport_unit_ean">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="w-full"
            [innerHTML]="
              'cards.offer.products.table.offer_product_transport_unit_ean'
                | translate
            "
          >
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row">
          <div class="w-full">
            {{ row.get("transportUnitDefaultEan").value | formValue }}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_pallet_type">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="w-full"
            [innerHTML]="
              'cards.offer.products.table.offer_product_pallet_type' | translate
            "
          >
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row">
          <div class="w-full">
            {{ row.get("palletType").value | systemClvName | formValue }}
          </div>
        </mat-cell>
      </ng-container>

      <!-- procurement -->

      <ng-container matColumnDef="offer_product_procurement_note">
        <mat-header-cell *matHeaderCellDef> </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = dataIndex" [formGroup]="row">
          <lxm-button
            type="grey"
            width="34px"
            height="34px"
            [icon]="getProcurementIcon(row)"
            (click)="openProcurementDialog(row)"
          >
          </lxm-button>
        </mat-cell>
      </ng-container>

      <!-- price changes -->

      <ng-container matColumnDef="offer_product_price_change_date">
        <mat-header-cell *matHeaderCellDef>
          <span
            [innerHTML]="
              'cards.offer.products.table.offer_product_price_change_date'
                | translate
            "
          >
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row">
          <div class="align-center flex flex-row">
            <div class="f-1 flex-row">
              <lxm-datepicker
                for="priceChangeDate"
                [readonly]="
                  readonly ||
                  row.value.isDeleted ||
                  row.value.status === OfferProductStatus.Removed
                "
              ></lxm-datepicker>
            </div>
            <div class="align-center ml5 flex flex-col justify-center">
              <ng-container *ngIf="getActiveOffer(row) != null">
                <span class="warning-icon" [tooltip]="warningTemp"></span>
                <ng-template #warningTemp>
                  <div class="flex flex-col">
                    <div class="product-popover-row">
                      <type-indicator [typeId]="OfferType.MainAssortment">
                      </type-indicator>
                      <span class="timeframe">
                        {{ getActiveOffer(row).validFrom | formDate }} -
                        {{ getActiveOffer(row).validTo | formDate }}
                      </span>
                    </div>
                    <div class="product-popover-row">
                      <span>
                        {{
                          "cards.offer.products.table.in_active_offer"
                            | translate
                        }}:
                      </span>
                    </div>
                    <div class="product-popover-row">
                      <span class="bold line-clamp-1">
                        {{ getActiveOffer(row).name }}
                      </span>
                    </div>
                    <div class="product-popover-row justify-between">
                      <span class="mr5">
                        {{
                          "cards.offer.products.table.list_price" | translate
                        }}:
                      </span>
                      <span>
                        {{ getActiveOffer(row).listPrice | priceValue }}
                      </span>
                    </div>
                    <div class="product-popover-row justify-between">
                      <span class="mr5">
                        {{
                          "cards.offer.products.table.discount_percentage"
                            | translate
                        }}:
                      </span>
                      <span class="alt-text">
                        {{
                          calculateMargin(
                            getActiveOffer(row).salePrice,
                            getActiveOffer(row).listPrice
                          ) | percentValue
                        }}
                      </span>
                    </div>
                    <div class="product-popover-row justify-between">
                      <span class="mr5">
                        {{
                          "cards.offer.products.table.client_price" | translate
                        }}:
                      </span>
                      <span class="bold">
                        {{ getActiveOffer(row).salePrice | priceValue }}
                      </span>
                    </div>
                  </div>
                </ng-template>
              </ng-container>
              <ng-container
                *ngIf="
                  getActiveOffer(row) == null &&
                  getActiveCampaignPriceScheme(row) != null
                "
              >
                <span
                  class="campaign-icon"
                  [tooltip]="campaignTemp"
                  tooltipApplyClass="product-popover"
                ></span>

                <ng-template #campaignTemp>
                  <div class="flex flex-col">
                    <div class="product-popover-row">
                      <type-indicator [typeId]="OfferType.Campaign">
                      </type-indicator>
                      <span class="timeframe">
                        {{
                          getActiveCampaignPriceScheme(row)?.validFrom
                            | formDate
                        }}
                        -
                        {{
                          getActiveCampaignPriceScheme(row)?.validTo | formDate
                        }}
                      </span>
                    </div>

                    <!-- <div class="product-popover-row">
                  <span>
                    {{ 'cards.offer.products.table.in_active_campaign' | translate }}:
                  </span>
                </div> -->

                    <div class="product-popover-row justify-between">
                      <span class="mr5">
                        {{
                          "cards.offer.products.table.campaign_price"
                            | translate
                        }}:
                      </span>
                      <span>
                        {{
                          getActiveCampaignPriceScheme(row)?.retailSalesPrice
                            | priceValue
                        }}
                      </span>
                    </div>

                    <div class="product-popover-row justify-between">
                      <span class="mr5">
                        {{
                          "cards.offer.products.table.discount_percentage"
                            | translate
                        }}:
                      </span>
                      <span class="alt-text">
                        {{
                          calculateMargin(
                            getActiveCampaignPriceScheme(row)?.retailSalesPrice,
                            getActiveCampaignPriceScheme(row)?.publicSalesPrice
                          ) | percentValue
                        }}
                      </span>
                    </div>
                  </div>
                </ng-template>
              </ng-container>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_current_price">
        <mat-header-cell *matHeaderCellDef>
          <span
            [innerHTML]="
              'cards.offer.products.table.offer_product_current_price'
                | translate
            "
          >
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row">
          <div class="f-1 flex flex-col">
            <div>
              {{
                (row.get("mainAssortmentListPrice").value
                  | priceValue
                    : 3
                    : row.get("mainAssortmentCurrency").value?.value) || "-"
              }}
            </div>
            <div class="alt-text">
              {{
                calculateMargin(
                  row.get("mainAssortmentPrice").value,
                  row.get("mainAssortmentListPrice").value
                ) || "-" | percentValue
              }}
            </div>
            <div class="align-center flex flex-row" style="flex-wrap: wrap">
              <div
                class="bold"
                *ngIf="row.get('mainAssortmentListPrice').value"
              >
                {{
                  (row.get("mainAssortmentPrice").value
                    | priceValue
                      : 3
                      : row.get("mainAssortmentCurrency").value?.value) || "-"
                }}
              </div>
              <div
                class="bold"
                *ngIf="!row.get('mainAssortmentListPrice').value"
              >
                {{ "-" }}
              </div>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_editable_list_price">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="w-full text-end"
            [innerHTML]="
              'cards.offer.products.table.offer_product_editable_list_price'
                | translate
            "
          ></span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = dataIndex" [formGroup]="row">
          <div class="triple-rows text-end">
            <div class="cell-row"></div>
            <div class="cell-row">
              <lxm-input
                class="text-center"
                [class.text-end]="
                  readonly ||
                  row.value.isDeleted ||
                  row.value.status === OfferProductStatus.Removed
                "
                [readonly]="
                  readonly ||
                  row.value.isDeleted ||
                  row.value.status === OfferProductStatus.Removed
                "
                for="editableListPrice"
                type="price"
                [currency]="currency"
                [id]="'editableListPrice_' + i"
              >
              </lxm-input>
            </div>
            <div class="cell-row alt-text text-end">
              {{
                calculateUnitPrice(row.value.editableListPrice, row.value)
                  | priceValue: pricePrecision : displayCurrency?.value
              }}{{
                calculateUnitPrice(row.value.editableListPrice, row.value)
                  ? "/" + (row.value.contentUnitId | translateEnum: "UnitShort")
                  : null
              }}
            </div>
          </div>
          <validation-errors
            [for]="'products[' + i + '].listPrice'"
            aliasTo="editableListPrice"
            [errorsFormGroup]="formGroup"
            [showErrorText]="false"
          ></validation-errors>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_price_discount_price_changes">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="w-full text-center"
            [innerHTML]="
              'cards.offer.products.table.offer_discount_price_changes'
                | translate
            "
          >
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = dataIndex" [formGroup]="row">
          <div class="f-1 flex flex-col text-center">
            <lxm-input
              [readonly]="
                readonly ||
                row.value.isDeleted ||
                row.value.status === OfferProductStatus.Removed
              "
              for="discountPriceChanges"
              type="percent"
              [digits]="2"
              [id]="'discount_price_changes_' + i"
            >
            </lxm-input>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_price_retailer_with_markup">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="w-full text-end"
            [innerHTML]="
              'cards.offer.products.table.offer_product_price_retailer_with_markup'
                | translate
            "
          ></span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = dataIndex" [formGroup]="row">
          <div class="triple-rows text-end">
            <div
              class="cell-row alt-text justify-end"
              style="height: 0; line-height: 1em; align-items: baseline"
            >
              {{
                calculateMargin(
                  row.get("salePricePriceChanges").value,
                  row.get("calculatedSelfPriceInForeignCurrency").value ||
                    row.get("selfPrice").value
                ) | percentValue
              }}
            </div>
            <div class="cell-row f-1 flex flex-col text-end">
              <lxm-input
                class="text-center"
                [class.text-end]="
                  readonly ||
                  row.value.isDeleted ||
                  row.value.status === OfferProductStatus.Removed
                "
                [readonly]="
                  readonly ||
                  row.value.isDeleted ||
                  row.value.status === OfferProductStatus.Removed
                "
                for="salePricePriceChanges"
                type="price"
                [currency]="currency"
                [id]="'salePrice_' + i"
              >
              </lxm-input>
            </div>
            <div class="cell-row alt-text text-end">
              {{
                calculateUnitPrice(row.value.salePricePriceChanges, row.value)
                  | priceValue: pricePrecision : displayCurrency?.value
              }}{{
                calculateUnitPrice(row.value.salePricePriceChanges, row.value)
                  ? "/" + (row.value.contentUnitId | translateEnum: "UnitShort")
                  : null
              }}
            </div>
          </div>
          <validation-errors
            [for]="'products[' + i + '].salePrice'"
            aliasTo="salePricePriceChanges"
            [errorsFormGroup]="formGroup"
            [showErrorText]="false"
          ></validation-errors>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_retail_price">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="w-full text-end"
            [innerHTML]="
              translationsPath + '.offer_product_retail_price' | translate
            "
          ></span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row" class="justify-end">
          <div
            class="f-1 flex flex-col text-end"
            style="min-width: 120px; max-width: 120px"
          >
            <lxm-input
              class="f-1"
              for="retailPrice"
              type="price"
              [currency]="currency"
              [pricePrecision]="2"
              [readonly]="
                readonly || row.value.status === OfferProductStatus.Rejected
              "
            >
            </lxm-input>
            <div
              class="alt-text justify-end"
              style="height: 0; line-height: 1em; align-items: baseline"
            >
              {{
                calculateRetailPriceMarkup(
                  row.value.retailPrice,
                  row.value.salePrice,
                  row.value.vat?.numericValue
                ) | percentValue
              }}
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <!-- <ng-container matColumnDef="offer_product_price_retailer_with_markup_margin">
      <mat-header-cell *matHeaderCellDef>
      </mat-header-cell>
      <mat-cell *matCellDef="let row; let i = dataIndex" [formGroup]="row">
        <div class="alt-text" style="min-width: 60px; padding-left: 6px;">
          <div class="w-full">
            {{ calculateMargin(row.get('salePrice').value, row.get('selfPrice').value) | percentValue }}
          </div>
        </div>
      </mat-cell>
    </ng-container> -->

      <ng-container matColumnDef="offer_product_price_change_reason">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="w-full"
            [innerHTML]="
              'cards.offer.products.table.offer_product_price_change_reason'
                | translate
            "
          >
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = dataIndex" [formGroup]="row">
          <!-- {{priceChangeReasons | json}} -->
          <lxm-select
            for="priceChangeReason"
            [options]="priceChangeReasons"
            [searchable]="true"
            [sortByLabel]="true"
            [readonly]="readonly"
            labelField="translatedValue"
            [isTranslated]="true"
            class="w-full"
          >
            <validation-errors
              [for]="'products[' + i + '].priceChangeReasonId'"
              aliasTo="priceChangeReason"
              [errorsFormGroup]="formGroup"
              [showErrorText]="false"
            ></validation-errors>
          </lxm-select>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_price_change_note">
        <mat-header-cell *matHeaderCellDef>
          <!-- <span [innerHTML]="'cards.offer.products.table.offer_product_price_change_note' | translate">
        </span> -->
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = dataIndex" [formGroup]="row">
          <lxm-button
            type="grey"
            width="34px"
            height="34px"
            [icon]="getPriceChangeIcon(row)"
            (click)="openPriceChangeDialog(row)"
          >
          </lxm-button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_order_settings">
        <mat-header-cell *matHeaderCellDef [hidden]="!canOrder">
          <!-- <span [innerHTML]="'cards.offer.products.table.offer_product_price_change_note' | translate">
        </span> -->
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = dataIndex"
          [formGroup]="row"
          [hidden]="!canOrder"
        >
          <lxm-button
            type="grey"
            width="34px"
            height="34px"
            icon="product-order-details"
            (click)="openOrderDialog(row)"
          >
          </lxm-button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="product_replacement">
        <mat-header-cell *matHeaderCellDef [hidden]="readonly">
          <!-- <span [innerHTML]="'cards.offer.products.table.offer_product_price_change_note' | translate">
        </span> -->
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = dataIndex"
          [formGroup]="row"
          [hidden]="readonly"
        >
          <ng-container *ngIf="canReplaceProduct(row) && !isRowSuspended(row)">
            <lxm-button
              padding="0 8px"
              type="grey"
              (click)="openReplaceProductDialog(row, i)"
              icon="replace"
              title="cards.offer.products.table.replace_product_title"
            >
            </lxm-button>
            <!-- <lxm-button *ngSwitchCase="false" padding="0 8px" type="grey" (click)="removeItem(i)"
            [icon]="row.value.isDeleted ? 'generate-small' : 'x'">
          </lxm-button> -->
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="product_suspension">
        <mat-header-cell *matHeaderCellDef [hidden]="readonly">
          <!-- <span [innerHTML]="'cards.offer.products.table.offer_product_price_change_note' | translate">
        </span> -->
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = dataIndex"
          [formGroup]="row"
          [hidden]="readonly"
        >
          <lxm-button
            padding="0 8px"
            type="grey"
            (click)="toggleSuspendProduct(row)"
            [icon]="isRowSuspended(row) ? 'undo' : 'suspend-product'"
            *ngIf="!isRowReplacement(row) && !isRowReplaced(row)"
            title="cards.offer.products.table.suspend_product_title"
          >
          </lxm-button>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="columns"
        class="products-table-head"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: columns; let i = dataIndex"
        class="table-row-hover"
        style="cursor: default"
        [attr.id]="row.get('productId').value"
        [ngClass]="getRowCssClass(row, i)"
      >
      </mat-row>
    </mat-table>
  </div>
</div>

<div class="w-full flex flex-col">
  <lxm-select
    *ngIf="!readonly && canAddProducts"
    class="mt15"
    width="400px"
    for="newProduct"
    labelField="label"
    [formGroup]="fgInternal"
    [searchable]="true"
    placeholder="cards.offer.products.search_placeholder"
    url="api/products/ac/"
    [acLabelFormat]="formatProductAcLabel"
    [acData]="getProductAcExcludeIds"
  >
  </lxm-select>

  <span
    class="products-table-total"
    *ngIf="showTotal && dataSource.data.length"
  >
    {{ "cards.offer.products.table.total" | translate }}:
    {{ dataSource.data.length }}
  </span>
</div>
