import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from "@angular/common/http";
import { Observable, throwError, of } from "rxjs";
import { catchError } from "rxjs/operators";

import { AuthenticationService } from "../_services/authentication.service";
import {
  HttpBadRequestResponse,
  HttpNotFoundResponse,
  HttpUnauthorizedResponse
} from "./BadRequest";
import { LxmMessage } from "./messages";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private _router: Router,
    private _authService: AuthenticationService,
    private _lxmMessage: LxmMessage
  ) {}

  private _navigateWithReturnUrl(url: string) {
    const location = document.location.pathname;
    const returnUrl = location == url ? undefined : location;
    this._router
      .navigate([url], { queryParams: { returnUrl: returnUrl } })
      .then((res) => {})
      .catch((err) => {});
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        const errorMessage = err.error?.validationSummary;

        if (err.error && errorMessage) {
          this._lxmMessage.error({
            message: errorMessage ?? "global.generic_error",
            args: err.error.args
          });
        }

        if (err.status === 401) {
          // auto logout if 401 response returned from api
          this._authService.logout();
          this._navigateWithReturnUrl("/login");
          return new Observable<never>();
        } else if (err.status == 403) {
          const res = new HttpUnauthorizedResponse();
          Object.assign(res, err.error);

          this._router
            .navigate(["/"])
            .then((res) => {})
            .catch((err) => {
              this._navigateWithReturnUrl("/login");
            });

          return throwError(res);
        } else if (err.status === 400) {
          const res = new HttpBadRequestResponse();
          Object.assign(res, err.error);
          return throwError(res);
        } else if (err.status === 404) {
          const res = new HttpNotFoundResponse();
          return throwError(res);
        } else if (err.status === 499) {
          this._router.navigate(["/activate-account"]);
          return new Observable<never>();
        }

        const error = err.message || err.statusText;
        return throwError(error);
      })
    );
  }
}
