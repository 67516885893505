export enum ReceivingOfferStatus {
  Pending = 2,
  Negotiating = 4,
  Confirmed = 5,
  Rejected = 6,
  Cancelled = 7,
  Expired = 8
}

(ReceivingOfferStatus as any).__typeName = 'ReceivingOfferStatus';
