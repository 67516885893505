export enum ProductMusicTrackField {
  TrackNo = 1,
  Title = 2,
  Authors = 3,
  Duration = 4,
}

(ProductMusicTrackField as any).__typeName = 'ProductMusicTrackField';

(ProductMusicTrackField as any).__altTranslationKeys = {
  [ProductMusicTrackField.TrackNo]: 'cards.products.product_music_info.track_section.trackNo',
  [ProductMusicTrackField.Title]: 'cards.products.product_music_info.track_section.title',
  [ProductMusicTrackField.Authors]: 'cards.products.product_music_info.track_section.authors',
  [ProductMusicTrackField.Duration]: 'cards.products.product_music_info.track_section.duration'
};
