<card-section>
  <span header-title>{{ 'cards.offer.files.title' | translate}}</span>

  <div header-actions *ngIf="canEdit">
    <ng-container *ngIf="isEditable">
      <div class="flex align-center">
        <lxm-button
          type="beige"
          icon="plus-dark"
          label="cards.offer.files.action.offer_add_files"
          (click)="openAddFileDialog()"
        >
        </lxm-button>
      </div>
    </ng-container>
  </div>

  <div body>
    <div class="row overflow-x">
      <files-list
        #filesList
        [readonly]="!canEdit || !isEditable"
        [data]="attachments"
        [displayHeadersWhenEmpty]="true"
        (open)="openAddFileDialog($event)"
        (download)="downloadAttachment($event)"
        (remove)="removeAttachment($event)"
      >
      </files-list>
    </div>
  </div>
</card-section>
