<lxm-dialog [dialogRef]="dialogRef" [formGroup]="form">
    <span header-title>
      {{(edit ? 
        (contactId ? 'cards.clients.contacts.dialog.title_edit' : 'cards.clients.contacts.dialog.title_add') 
        : 'cards.clients.contacts.dialog.title_passive')  
        | translate}}
    </span>
    <div body style="padding-bottom: 40px;">

            <form-field dialog label="cards.clients.contacts.dialog.label.name" [readonly]="!edit" for="name" type="input-text">
                <validation-errors for="name"></validation-errors>
            </form-field>

            <form-field dialog label="cards.clients.contacts.dialog.label.role" [readonly]="!edit" for="role" type="input-text">
                <validation-errors for="role"></validation-errors>
            </form-field>
            
            <ng-container *ngIf="edit">
              <form-field dialog label="cards.clients.contacts.dialog.label.portfolio_description" for="portfolioDescription" type="input-text">
                  <validation-errors for="portfolioDescription"></validation-errors>
              </form-field>
            </ng-container>
            <ng-container *ngIf="!edit">
              <form-field dialog label="cards.clients.contacts.dialog.label.portfolio_description">
                <span text style="padding: 0 10px">
                  {{ translatedPortfolioDescription || '-'}}
                </span>
              </form-field>
            </ng-container>

            <form-field dialog label="cards.clients.contacts.dialog.label.email" [readonly]="!edit" for="email" type="input-text" inputType="email">
                <validation-errors for="email"></validation-errors>
            </form-field>

            <form-field dialog label="cards.clients.contacts.dialog.label.phone" [readonly]="!edit" [ngSwitch]="edit">
                <span any row class="w-full" *ngSwitchCase="true">
                    <lxm-select class="dialog-input-fixed-width-1" 
                        [searchable]="true"
                        [clearable]="false"
                        [options]="formData?.phoneCodes || []" 
                        labelField="value" 
                        for="phoneCode">
                    </lxm-select>
                    <input type="numeric" class="lxm-input ml10" formControlName="phoneNumberShort" />
                </span>
                <span text class="lxm-input input-readonly" *ngSwitchCase="false">
                  <phone-number *ngSwitchCase="false" [code]="phoneCode?.value" [number]="phoneNumberShort"></phone-number>
                </span>
                <validation-errors for="phoneCode"></validation-errors>
                <validation-errors for="phoneNumberShort"></validation-errors>
                <validation-errors for="phoneNumber"></validation-errors>
            </form-field>

            <form-field dialog label="cards.clients.client_info.label.status" [readonly]="!edit">
              <lxm-select select
                [readonly]="!edit"
                [options]="statusOptions"
                [clearable]="false"
                for="status"
                value="id">
              </lxm-select>
              <validation-errors for="status"></validation-errors>
            </form-field>
    </div>

    <div footer class="justify-between">

      <div class="flex flex-row">
        <lxm-button *ngIf="edit" 
          type="primary"
          class="mr10"
          [loading]="saveLoading"
          label="action.save"
          (click)="save()"
          buttonType="submit">
        </lxm-button>
        <lxm-button 
          type="secondary"
          class="mr10"
          label="action.close"
          (click)="closeDialog()">
        </lxm-button>
      </div>

      <div>
        <lxm-button *ngIf="edit && contactId && clientId"
          type="transparent" 
          label="action.delete"
          [loading]="deleteLoading"
          icon="trash" 
          class="ml30" 
          (click)="deleteContact()">
        </lxm-button>
      </div>

    </div>

</lxm-dialog>
