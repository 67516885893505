import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { LocaleService } from 'src/app/_services/locale.service';

@Component({
  selector: 'history-card',
  templateUrl: './history.card.html',
  styleUrls: ['./history.card.scss']
})
export class HistoryCardComponent implements OnInit, OnChanges {

  @Input()
  public data: any[];

  @Input()
  public changeType: string;

  @Input()
  public defaultFieldType: string;

  public historyColumns: string[];
  public historySource: MatTableDataSource<any> = new MatTableDataSource<any>([]);

  @Input() public expanded = true;

  @Output() public onExpand = new EventEmitter();

  public table = {
    columns: ['history_date', 'history_user', 'history_action', 'history_change']
  };

  constructor(public localeService: LocaleService) {
  }

  ngOnInit(): void {

    const cols = this.table.columns;
    if (!this.changeType) {
      cols.splice(2, 1);
    }

    this.historyColumns = cols;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
        this.historySource.data = changes.data.currentValue as any;
    }
  }
}
