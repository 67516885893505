import { Component, Output, EventEmitter, Input, OnInit, Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ProductsRetailerSearchDataService } from 'src/app/_services';
import { asArray, SupplierProductAssortmentStatus, IEnumValue, ProductField, Bool, asBoolArray, LxmAppModule, CampaignReturnableItemOption } from 'src/app/enum';
import { AppState } from 'src/app/state/app.state';
import { IProductListFilterDataDto, ICountryTranslated, IRetailerProductsSearchFormData, IClvDto, IAssortmentGroup, IRetailerProductListFilterDataDto, ILocationOption, ISegmentTreeOption } from 'src/app/cards/product/product-info/product-info.model';
import { ActivatedRoute } from '@angular/router';
import { LocalStorage } from 'src/app/_helpers';
import { filter } from 'rxjs/operators';
import { AssortmentMovement } from 'src/app/enum/assortment-movement';
import { ProductsListState } from 'src/app/cards/products/products-list.state';
import { Guid } from 'src/app/util/Guid';

@Component({
  selector: 'product-search-retailer-card',
  templateUrl: './product-search-retailer.html',
  host: { 'class': 'product-search-retailer-card' },
  styleUrls: ['./product-search-retailer.scss']
})
export class RetailerProductsSearchCard implements OnInit {

  public formData: IRetailerProductsSearchFormData;

  @Output()
  public search = new EventEmitter();

  @Input()
  public isOpen = false;

  @Input()
  private localStorageKey: string;

  public assortmentStatusOptions: IClvDto[];
  public assortmentGroupOptions: IAssortmentGroup[];
  public locationOptions: ILocationOption[];
  public isInCampaignOptions = asBoolArray(Bool);
  public assortmentMovementOptions = asArray(AssortmentMovement);

  private searchKey: string;

  public countryOptions: ICountryTranslated[];
  public nutritionalClaimsOptions: IEnumValue[];

  public hasAssortment: boolean;

  public segmentOptions: ISegmentTreeOption[];

  constructor(
    private _state: ProductsListState,
    public appState: AppState,
    private _productsSearchDataService: ProductsRetailerSearchDataService,
    route: ActivatedRoute) {
    this.hasAssortment = this.appState.hasModule(LxmAppModule.BuyerAssortment);

    this.formData = (route.snapshot.data.formData || route.snapshot.data.productCard.formData) as IRetailerProductsSearchFormData;
    this.segmentOptions = this.formData.segmentTreeOptions;

    this.countryOptions = this.formData.countries;
   
    this.assortmentStatusOptions = this.formData.assortmentStatuses || [];
    this.assortmentStatusOptions.push({ id: Guid.empty,
      code: "-1",
      value: "cards.products.products_retailer_search.label.noStatus"
    });
    
    this.assortmentGroupOptions = this.formData.assortmentGroups;
    this.locationOptions = this.formData.locations;
    this.nutritionalClaimsOptions = asArray(ProductField).filter(x => this.formData.nutritionalClaims?.includes(x.id));
  }

  public get searchData(): FormGroup {
    return this._productsSearchDataService.searchData;
  }

  public get isLoading() {
    return this._state.isSearchLoading;
  }

  public segmentTreeLabel(i: ISegmentTreeOption) {
    return `${'--'.repeat(i.level)} ${i.code} ${i.name}`.trim();
  }

  public disableOption(i: ISegmentTreeOption) {
    return !i.canAssignToProduct;
  }

  public toggleOpen(isOpen: boolean) {
    this.isOpen = isOpen;
    LocalStorage.set(this.localStorageKey, isOpen);
  }

  public ngOnInit(): void {
    this.submit();
  }

  public submit() {
    const val = this._productsSearchDataService.serializeSearchData();
    this.search.emit(val);
  }

}
