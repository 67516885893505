import { Component, ViewChild, ChangeDetectorRef, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContentLanguageDialogComponent } from './dialog/content-language.dialog';
import { Status, asArray, UserAction } from 'src/app/enum';
import { DataTableService } from 'src/app/_services';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { TranslateService } from '@ngx-translate/core';
import { appSettings } from 'src/app/app.settings';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'product-content-languages',
    templateUrl: './product-content-languages.component.html',
    styleUrls: [ './product-content-languages.scss' ]
})
export class ProductContentLanguagesTableComponent extends DataTableComponent<any, any> {

    public displayedColumns: string[] = ['twoLetterIso', 'title', 'isRequired', 'status'];
    public dataUrl = 'contentLanguages/griddata';

    public UserAction = UserAction;
    public statuses = asArray(Status);

    constructor(
      private _dialog: MatDialog,
      dataService: DataTableService<any, any>,
      cd: ChangeDetectorRef,
    ) {
      super(dataService, cd);
    }

    public openDialog(item?: any) {

      const dialogRef = this._dialog.open(ContentLanguageDialogComponent, {
          ...appSettings.DIALOG_SIZES.M,
          restoreFocus: false,
          data: {
            item: item,
          }
        });

        dialogRef.afterClosed().subscribe(res => {
          if (!res) {
            return;
          }

          this.load();
        });
    }
}
