import {
  Component,
  Host,
  Input,
  OnInit,
  Optional,
  ViewEncapsulation,
} from "@angular/core";
import { ControlContainer, FormGroup } from "@angular/forms";
import { Moment } from "moment";

@Component({
  selector: "lxm-calendar",
  templateUrl: "./calendar.component.html",
  styleUrls: ["./calendar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LxmCalendarComponent implements OnInit {
  @Input() public formGroup: FormGroup;
  @Input() public for: string;

  constructor(
    @Optional() @Host() private _controlContainer: ControlContainer,
  ) {}

  public ngOnInit(): void {
    if (
      this._controlContainer &&
      this._controlContainer.control instanceof FormGroup
    ) {
      this.formGroup = <FormGroup>this._controlContainer.control;
    }
  }

  get selected(): Moment | undefined {
    return this.formGroup?.get(this.for)?.value;
  }

  set selected(value: Moment | undefined) {
    this.formGroup?.get(this.for)?.setValue(value);
  }
}
