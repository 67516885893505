export enum ProductRetailerExpiryField {
  RetailerId = 1,
  Retailer = 2,
  ExpirationInfoTarget = 3,
  ExpiryInDays = 4,
  ExpiryForRetailerInDays = 5,
  MinExpiryOnDelivery = 6
}

(ProductRetailerExpiryField as any).__typeName = 'ProductRetailerExpiryField';

(ProductRetailerExpiryField as any).__altTranslationKeys = {
  [ProductRetailerExpiryField.RetailerId]: 'cards.products.product_storage_info.table.retailer',
  [ProductRetailerExpiryField.Retailer]: 'cards.products.product_storage_info.table.retailer',
  [ProductRetailerExpiryField.ExpirationInfoTarget]: 'cards.products.product_storage_info.table.type',
  [ProductRetailerExpiryField.ExpiryInDays]: 'cards.products.product_storage_info.table.expiry_days',
  [ProductRetailerExpiryField.ExpiryForRetailerInDays]: 'cards.products.product_storage_info.table.expiry_days_retailer',
  [ProductRetailerExpiryField.MinExpiryOnDelivery]: 'cards.products.product_storage_info.table.min_expiry_on_delivery',
};
