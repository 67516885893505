<card-expanding>
  <span header-title>{{'cards.settings.lxm_settings.title' | translate}}</span>

  <div body>
    <tab-options [localStorageKey]="tabLocalStorageKey" [options]="options" (change)="onTabSelect(elTabOptions?.selectedOption?.data)" #elTabOptions></tab-options>
    <lxm-clv-table [initialData]="initialData" *ngIf="tabType === TabType.Clv"></lxm-clv-table>
    <gpc-tree [initialData]="initialData" *ngIf="tabType === TabType.GpcTree"></gpc-tree>
    <atc-tree [initialData]="initialData" *ngIf="tabType === TabType.AtcTree"></atc-tree>
  </div>

</card-expanding>
