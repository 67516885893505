<div class="row overflow-x" [formGroup]="fgInternal" *ngIf="hasData">
  <mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="language_column">
      <mat-header-cell *matHeaderCellDef>
        <span>
          {{'cards.products.product_movie_info.audio_section.language' | translate}}
        </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
        <div class="flex f-1" style="position: relative;">
          <lxm-select for="language" labelField="name" [searchable]="true" url="api/clvac/languages/"
            [readonly]="readonly">
            <validation-errors [for]="'audio['+(i)+'].language'" aliasTo="language" [errorsFormGroup]="formGroup">
            </validation-errors>
          </lxm-select>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="type_column">
      <mat-header-cell *matHeaderCellDef>
        <span>
          {{'cards.products.product_movie_info.audio_section.type' | translate}}
        </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
        <div class="flex f-1" style="position: relative;">
          <lxm-select for="typeId" value="id" labelField="translatedValue" [isTranslated]="true" [searchable]="true"
            [options]="movieSubtitleTypeOptions" [sortByLabel]="true" [readonly]="readonly">
            <validation-errors [for]="'audio['+(i)+'].typeId'" aliasTo="typeId" [errorsFormGroup]="formGroup"></validation-errors>
          </lxm-select>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="deaf_column">
      <mat-header-cell *matHeaderCellDef>
        <span>
          {{'cards.products.product_movie_info.audio_section.isDeaf' | translate}}
        </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
        <slide-toggle toggle for="isDeaf" [readonly]="readonly"></slide-toggle>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="remove_column">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
        <lxm-button *ngIf="!readonly" type="transparent" icon="trash" title="action.remove" padding="8px 0"
          (click)="removeItem(i)"></lxm-button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="table.columns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: table.columns"
      [ngClass]="{'row-to-delete readonly' : row.get('isDeleted').value}"></mat-row>
  </mat-table>

</div>

<div class="row f-1" *ngIf="!hasData" style="padding: 8px;">
  <no-content image="no-film-audio" text="cards.products.product_movie_info.audio_section.no_audio" [inline]="true" style="margin: 0;"></no-content>
</div>

<div class="row mt10" *ngIf="!readonly">
  <lxm-button type="transparent" icon="plus-dark" label="cards.products.product_movie_info.audio_section.add_new" (click)="addItem()"></lxm-button>
</div>