import { Component, Input, OnInit, OnChanges, SimpleChanges } from "@angular/core";
import { DateHandler } from 'src/app/_helpers/date';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "time-diff",
  templateUrl: "./time-diff.component.html",
  styleUrls: ['./time-diff.scss']
})
export class TimeDiffComponent {

  @Input() public date;

  //public ngOnChanges(changes: SimpleChanges) {}



}


