<card [alignSections]="true">
  <span header-title>{{'cards.dashboard_notifications.title' | translate}}</span>
  <section header-actions>
    <a class="card-header-action" routerLink="/notifications">{{'cards.dashboard_notifications.action' | translate}}</a>
  </section>
  <section body [ngSwitch]="hasNotifications">
    <div class="flex-col" *ngSwitchCase="true">
      <card-notification 
        class="card-notification table-row-hover"
        *ngFor="let notification of data?.items" 
        [data]="notification" 
        (click)="navigate(notification)">
      </card-notification>
    </div>

    <no-content 
      text="cards.dashboard_notifications.no_entries" 
      image="no-notifications" 
      *ngSwitchCase="false">
    </no-content>

  </section>
</card>
