<card-expanding>
  <span header-title>{{ 'cards.offer.comments.title' | translate}}</span>

  <section body>
    <div class="row" class="mb10" *ngIf="canEdit && isEditable">
      <info-array prelude="cards.offer.comments.instructions"
        class="row mb20">
      </info-array>
    </div>

    <app-chat [messages]="messages" [chatId]="chatId" [handleNewMessage]="send" [readonly]="!canEdit || !isEditable"></app-chat>
  </section>
</card-expanding>
