import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ListBase } from '../list-base';
import { LxmDialog } from 'src/app/_helpers';
import { FileService } from 'src/app/_services';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'files-list',
  templateUrl: './files-list.component.html',
  styleUrls: ['./files-list.scss']
})
export class FilesListComponent extends ListBase implements OnInit {

  @ViewChild('download') private _download: ElementRef;

  private _defaultColumns: string[] = ['file_name', 'file_description', 'file_upload_date', 'actions'];

  public get columns(): string[] {
    return this._defaultColumns;
  }

  @Input() public dialogRef: any;
  @Input() public displayHeadersWhenEmpty = false;
  @Input() public softDelete = false;
  @Input() public readonly = false;

  @Output() public open = new EventEmitter();
  @Output() public download = new EventEmitter();
  @Output() public remove = new EventEmitter();

  constructor(
    private _dialog: LxmDialog,
    private _fileService: FileService
  ) {
    super();
  }

  public openAddFileDialog(row: any = null) {
    if (this.open.observers.length > 0) {
      this.open.emit(row);
    } else if (this.dialogRef) {
      this._dialog.open(this.dialogRef, {
        data: {
          row: row,
          dataTableRef: this.dataSource
        }
      });
    }
  }

  public async downloadAttachment(row: any) {
    if (this.download.observers.length > 0) {
      this.download.emit(row);
    } else if (row) {
      const { fileId, fileName } = row;
      this._fileService.download(fileId);
    }
  }

  public removeAttachment(row: any) {
    if (this.softDelete) {
      if (row.isNew) {
        const i = this.dataSource.data.indexOf(row);
        this.dataSource.data.splice(i, 1);
      } else {
        row.isDeleted = true;
      }
    } else {
      this.remove.emit(row);
    }
  }
}
