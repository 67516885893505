import { Component, EventEmitter, forwardRef, Input, Output, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTable } from "@angular/material/table";
import { Observable, Subscription } from "rxjs";
import { PAGE_SIZE, PAGE_SIZE_OPTIONS } from "src/app/config";
import { LocalStorage } from "src/app/_helpers";

@Component({
  selector: "data-table-footer",
  templateUrl: "./data-table-footer.component.html",
  styleUrls: ['./data-table-footer.component.scss'],
  providers: [{
    provide: MatTable,
      useExisting: forwardRef(() =>  DataTableFooterComponent)
  }]
})
export class DataTableFooterComponent {

  @ViewChild(MatPaginator, {static: true}) matPaginator: MatPaginator;

  @Output() public paginator = new EventEmitter();
  @Output() public zoomChange = new EventEmitter<number>();
  @Output() public loaded = new EventEmitter<boolean>();

  @Output() public onLoad = new EventEmitter();
  @Output() public onSearch = new EventEmitter();

  @Output() public onToggleFullScreen = new EventEmitter();

  @Input() public zoom: number;

  @Input() public pageIndex: number = 0;
  @Input() public localStorageKey: string;
  @Input() public pageSize: any = PAGE_SIZE.DEFAULT;
  @Input() public pageSizeOptions: any = PAGE_SIZE_OPTIONS.DEFAULT;
  @Input() public length = 0;
  @Input() public isLoading = false;
  @Input() public isFullScreen = undefined;

  @Input() public showPagination = true;

  @Input() public search: Observable<any>;

  private _searchSubscription: Subscription;
  private _paginatorSubscription: Subscription;

  private _searchCount = 0;

  public ngOnInit() {

    this.paginator.emit(this.matPaginator);

    if (this.localStorageKey) {
      const pageSize = LocalStorage.getValue(this.localStorageKey) || PAGE_SIZE[this.localStorageKey] || PAGE_SIZE.DEFAULT;

      const pageSizeKey = this.localStorageKey.replace('PAGE_SIZE_', '');
      const pageSizeOptions = PAGE_SIZE_OPTIONS[pageSizeKey];

      if (pageSize) {
        this.pageSize = pageSize;
      }
      if (pageSizeOptions) {
        this.pageSizeOptions = pageSizeOptions
      }
    }

  }
  
  ngAfterViewInit() {

    if (this.search) {
      this._searchSubscription = this.search.subscribe(x => {
        this.matPaginator.pageIndex = 0;
        this.onSearch.emit(x);
        if (this._searchCount > 0) {
          this.onLoad.emit();
        }
        this.loaded.emit(true);
        this._searchCount++;
      });
    }

    this._paginatorSubscription = this.matPaginator?.page.subscribe(p => {
      const pageSize = p?.pageSize;
      if (pageSize) {
        this.matPaginator.pageSize = pageSize;
        PAGE_SIZE[this.localStorageKey] = pageSize;
        LocalStorage.set(this.localStorageKey, pageSize);
      }
    })
  }

  public toggleFullScreen(updatedState = !this.isFullScreen) {
    this.isFullScreen = updatedState;
    this.onToggleFullScreen.emit(updatedState);
  }

  ngOnDestroy() {
    if (this._searchSubscription) {
      this._searchSubscription.unsubscribe();
    }
    if (this._paginatorSubscription) {
      this._paginatorSubscription.unsubscribe();
    }
  }

}
