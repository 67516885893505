import { Component, Input, Output, EventEmitter } from "@angular/core";
import { TabOption } from './tab-option.model';
import { LocalStorage } from 'src/app/_helpers';

@Component({
  selector: "tab-options",
  templateUrl: "./tab-options.component.html",
  styleUrls: ['./tab-options.scss']
})
export class TabOptionsComponent {

  @Input()
  public localStorageKey: string;

  public selectedOption: TabOption;

  private _options: TabOption[];
  private _change = new EventEmitter<TabOption>();

  @Input()
  public get options(): TabOption[] {
    return this._options;
  }

  public set options(val: TabOption[]) {
    this._options = val;
    if (!this._options) {
      return;
    }

    let toSelect = this._options[0];
    if (this.localStorageKey) {
      const localValue = LocalStorage.getValue(this.localStorageKey);
      if (localValue) {
        let selectedTab: any;

        const { data, translationKey } = localValue;
        if (data.data) {
          const { data: { classificatorId, controller }} = data;
          if (classificatorId || controller) {
            if (classificatorId) {
              selectedTab = this._options.find(x => x.data?.data?.classificatorId === classificatorId);
            } else if (controller) {
              selectedTab = this._options.find(x => x.data?.data?.controller === controller);
            }
          }
        } else if (translationKey) {
          selectedTab = this._options.find(x => x.translationKey === translationKey);
        }

        if (selectedTab) {
          toSelect = selectedTab;
        }
        
      }
    }
    this.setOption(toSelect);
  }

  @Output()
  public change = this._change.asObservable();

  public setOption(option): void {

    if (option === this.selectedOption) {
      return;
    }

    if (this.localStorageKey && option) {
      LocalStorage.set(this.localStorageKey, option);
    }

    this.selectedOption = option;
    this._change.emit(option);
  }
}
