import { Component, Input, SimpleChanges } from '@angular/core';
import { ProductPricelistService } from 'src/app/_services';
import { DialogHelper } from 'src/app/_helpers';
import { AppState } from 'src/app/state/app.state';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup } from '@angular/forms';
import { numberUtil } from 'src/app/util/number-util';
import { ICurrency } from 'src/app/models';
import { nextSchemeDay } from 'src/app/_helpers/utilities';

@Component({
  selector: "offer-product-supplier-prices",
  templateUrl: "./offer-product-supplier-prices.component.html",
  styleUrls: ['./offer-product-supplier-prices.component.scss']
})
export class OfferProductSupplierPricesComponent {

  @Input() public data: any;
  @Input() public formGroup: FormGroup;
  @Input() public readonly = false;
  @Input() public markupMethod = 0;

  @Input() public formData: any;

  @Input() public currencies: ICurrency[];
  @Input() public currencyOptions: ICurrency[];
  @Input() public defaultCurrency: ICurrency;
  @Input() public priceSchemeData: any;


  @Input() public buyInPriceCurrencyRetailPrice;

  constructor(
    protected _appState: AppState,
    protected _productPricelistService: ProductPricelistService,
    _dialogHelper: DialogHelper,
    _translateService: TranslateService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.priceSchemeData) {
      if (this.priceSchemeData) {
        const {
          buyInPrice,
          buyInPriceCurrency,
          conditionApprovedAt,
          createdAt,
          decisionId,
          id,
          isConditionApproved,
          isCurrent,
          isEditable,
          listPrice,
          logisticsPartner,
          modifiedBy,
          modifiedById,
          offerId,
          priceGroups,
          procurement,
          // productId,
          // retailPrice,
          // retailPriceWithoutVat,
          retailPrices,
          schemeType,
          // supplier,
          validFrom,
          validTo,
          vat
        } = this.priceSchemeData || {};

        let modifiedRetailPrices = retailPrices ?? [];

        if (this.buyInPriceCurrencyRetailPrice?.price) {
          const buyInPriceCurrencyId = this.formGroup.value.buyInPriceCurrency?.id;
          const buyInPriceCurrencyRetailPriceIndex = modifiedRetailPrices.findIndex(x => x.currency?.id === buyInPriceCurrencyId);

          if (buyInPriceCurrencyRetailPriceIndex > -1) {
            modifiedRetailPrices[buyInPriceCurrencyRetailPriceIndex] = this.buyInPriceCurrencyRetailPrice;
          } else {
            modifiedRetailPrices.unshift(this.buyInPriceCurrencyRetailPrice);
          }
        }

        let priceSchemePatch = {
          buyInPrice: buyInPrice,
          buyInPriceCurrency: buyInPriceCurrency,
          listPrice: listPrice,
          priceGroups: priceGroups,
          retailPrices: modifiedRetailPrices,
          schemeType: schemeType,
          procurement: procurement,
          logisticsPartner: logisticsPartner
        };

        if (vat) {
          Object.assign(priceSchemePatch, {
            vat: vat
          })
        }

        const fgPriceSchemeValidFrom = this.formGroup.value.priceSchemeValidFrom;
        if (!fgPriceSchemeValidFrom) {
          Object.assign(priceSchemePatch, {
            priceSchemeValidFrom: validFrom?.editableDate
          })
        }

        const fgPriceSchemeValidTo = this.formGroup.value.priceSchemeValidTo;
        if (!fgPriceSchemeValidTo) {
          Object.assign(priceSchemePatch, {
            priceSchemeValidTo: validTo?.editableDate
          })
        }

        this.formGroup.patchValue(priceSchemePatch);
      }
    }
  }

  public get productId() {
    return this.data?.product?.productId;
  }

  public get offerProductId() {
    return this.data?.product?.id;
  }

  public get offerId() {
    return this.data?.offerId;
  }

  public get offerCurrencyId() {
    return this.data?.currency?.id;
  }

  public get isCampaign() {
    return this.formGroup?.get('isCampaign').value
  }

  public get buyInPriceCurrency() {
    return this.formGroup?.value.buyInPriceCurrency
  }

  public get salesCurrencies() {
    return this.data?.formData?.salesCurrencies || [];
  }

  public get priceGroupOptions() {
    return this.formData?.priceGroups;
  }

  public get existingPriceGroups(): any[] {
    return this.formGroup?.get('priceGroups').value;
  }

  public get selectedVat() {
    const selectedVat = this.formGroup?.get('vat').value;
    const vat = numberUtil.toNumber(selectedVat?.numericValue) || 0;
    return vat;
  }

  public get nextDay() {
    return nextSchemeDay();
  }

  public get isBuyInPriceChangeable() {
    return this.data?.offer?.canChangeBuyInPriceByRetailer ||
      this.data?.formData?.canChangeBuyInPriceByRetailer ||
      false;
  }

}
