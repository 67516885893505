import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AssortmentGroupsService {

  constructor(private _http: HttpClient) {}

  public create(req: any) {
    return this._http.post<any>(`api/assortmentGroups`, req);
  }

  public save(id: string, req: any) {
    return this._http.post<any>(`api/assortmentGroups/${id}`, req);
  }

  public getAssortmentGroupFormData(id: string) {
    return this._http.get<any>(`api/assortmentGroups/formData/${id || ''}`);
  }

  public getListFormData() {
    return this._http.get<any>(`api/assortmentGroups/listFormData`);
  }
  
  public getAssortmentGroups() {
    return this._http.get(`api/assortmentGroups`, { params: { parentId: '', $count: 'true', $top: '10'} }) as Observable<any>
  }

}
