import { Component, OnInit, Optional, Input, Output, EventEmitter, ViewChild, HostBinding, forwardRef } from "@angular/core";
import { NgSelectConfig } from '@ng-select/ng-select';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Observable, Subject } from 'rxjs';
import { ViewEncapsulation } from '@angular/core';
import { FormGroup, ControlContainer } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { HttpClient } from '@angular/common/http';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: "lxm-autocomplete",
  templateUrl: "./autocomplete.component.html",
  styleUrls: ['./autocomplete.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AutocompleteComponent implements OnInit {

  // Not tested

  @ViewChild('select', { static: false }) public select: NgSelectComponent;

  @Output() change: EventEmitter<any> = new EventEmitter();

  @Input() public options: any[];
  @Input() public url: string;
  @Input() public acData: () => any | any;
  @Input() public acLabelFormat: (item: any) => string;
  @Input() public formGroup: FormGroup;
  @Input() public for: string;
  @Input() public placeholder: string;
  @Input() public maxSelectedItems: number;
  @Input() public labelField: string = 'name';
  @Input() public value: string;
  @Input() public disabled = false;
  @Input() public hideSelected;
  @Input() public isTranslated = false;

  @HostBinding('style.width') @Input() public width: string = '100%';
  @HostBinding('class.readonly') @Input() public readonly = false;

  // todo - doesnt seem to get errors from control
  public get hasError() {
    return false
  }

  public selected: any;
  public overlay = false;
  public hasValue: boolean;

  private _acRes: Observable<any>;
  private _acSubject = new Subject();

  constructor(
    private _http: HttpClient,
    @Optional() private _controlContainer: ControlContainer) {

  }


  public onChange(value: any): void {
    this.select.blur();
    this.change.emit(value);
  }

  public onOpen(e) {
    this.overlay = true;
  }

  public onClose(e) {
    this.overlay = false;
    this.options = [];
  }

  public showOptions(event: MatCheckboxChange): void {
    // console.log(event.checked);
  }

  public valueChange(item, event) {
    // console.log(item, event);
    return;
  }

  public ngOnInit() {
    if (this._controlContainer && this._controlContainer.control instanceof FormGroup) {
      this.formGroup = <FormGroup>this._controlContainer.control;

      if (this.for) {
        const ref = this.formGroup.get(this.for);
        this.selected = ref.value;
        this._updateHasValue(this.selected);

        ref.valueChanges.subscribe(x => {
          this._updateHasValue(x);
        });

      }

    }

    const _this = this;
    this._acRes = this._acSubject.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => {
        if (_this.acData) {
          const data = typeof _this.acData === 'function' ? _this.acData() : _this.acData;
          return _this._http.post<any[]>(_this.url + term, data);
        } else {
          return _this._http.get<any[]>(_this.url + term);
        }
      })
    );

    this._acRes
      .subscribe(o => {
        o.subscribe((res: any[]) => {
          if (_this.acLabelFormat) {
            _this.options = res.map(x => Object.assign(x, { [_this.labelField]: _this.acLabelFormat(x) }));
          } else {
            _this.options = res;
          }
        });
      });

  }

  public find(search: { term: string, items: any[] }) {

    if (!search.term) {
      this.options = [];
      return;
    }

    this._acSubject.next(search.term);
  }

  private _updateHasValue(x: any) {
    this.hasValue = (x !== undefined && x !== null && x !== '') || (x instanceof Array && x.length > 0);
  }
}
