import { Component, ViewChild, AfterViewInit, Inject } from '@angular/core';
import { DialogComponent } from 'src/app/components';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { SuppliersService } from 'src/app/_services/suppliers.service';
import { finalize } from 'rxjs/operators';
import { LxmMessage } from 'src/app/_helpers';

@Component({
  selector: 'bind-supplier-dialog',
  host: { 'class': 'dialog' },
  templateUrl: './bind-supplier.dialog.html',
  styleUrls: ['./bind-supplier.dialog.scss']
})
export class BindSupplierDialogComponent implements AfterViewInit {
  @ViewChild('dialog', { static: true, read: DialogComponent })
  private _dialog: DialogComponent;

  public lxmSupplierId: string;
  public lxmSupplierName: string;
  public suggestedOptions: any[];

  public form: FormGroup;

  public loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _lxmMessage: LxmMessage,
    private _suppliersService: SuppliersService,
    public dialogRef: MatDialogRef<BindSupplierDialogComponent>) {

      this.lxmSupplierName = data.lxmSupplierName;
      this.lxmSupplierId = data.lxmSupplierId;

      this.form = new FormGroup({
        retailerSupplier: new FormControl(),
        shortName: new FormControl()
      });
  }

  public get hasMatches() {
    return this.suggestedOptions?.length ? true : false;
  }

  public selectSupplier(supplier: any) {

    const retailerSupplier = this.form.get('retailerSupplier').value;

    if (retailerSupplier?.id === supplier.retailerSupplierId) {
      this.form.patchValue({
        retailerSupplier: null
      })
    } else {
      this.form.patchValue({
        retailerSupplier: { id: supplier.retailerSupplierId, name: supplier.name, regNo: supplier.regNo }
      })
    }

  }

  public supplierAcLabel(item: any) {
    var label = item.name;
    if (item.regNo) {
      label += ` (${item.regNo})`;
    }
    return label;
  }

  public ngAfterViewInit(): void {

    this._dialog.dataLoaded$
      .subscribe(state => {
        if (!state) {
          return;
        }

        this.suggestedOptions = this.data.match;
      });
  }

  public bind() {

    this.loading = true;
    const f = this.form.value;

    this._suppliersService.bind(f.retailerSupplier.id, this.lxmSupplierId)
      .pipe(finalize(() => {
        this.loading = false;
      }))
      .result(this.form, (res) => {

        this._lxmMessage.ok({
          message: 'cards.offer.bind_supplier.msg.suppliers_bound'
        });
        this.dialogRef.close(res);

      }, err => {

        // this._lxmMessage.error({
        //   message: 'cards.offer.bind_supplier.msg.binding_failed'
        // });

      });

  }

  public createNew() {

    this.loading = true;
    const f = this.form.value;

    this._suppliersService.createFromLxmSupplier(this.lxmSupplierId, f.shortName)
      .pipe(finalize(() => {
        this.loading = false;
      }))
      .result(this.form, (res) => {

        this._lxmMessage.ok({
          message: 'cards.offer.bind_supplier.msg.supplier_created'
        });
        this.dialogRef.close(res);

      }, err => {

        // this._lxmMessage.error({
        //   message: 'cards.offer.bind_supplier.msg.creating_supplier_failed'
        // });

      });

  }

  public closeDialog(res = null): void {
      this.dialogRef.close(res);
  }
}
