import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { NotificationsService, UserService } from '../_services';
import { forkJoin } from 'rxjs';

@Injectable()
export class DashboardResolve implements Resolve<any> {

  constructor(private _userService: UserService, private _notificationsService: NotificationsService) { }
  
  resolve() {
    const resolvers: any = {
      primary: this._userService.dashboard(),
      notifications: this._notificationsService.getNotificationsList(true, 4)
    };

    return forkJoin(resolvers);
  }
  
}