import { Component, Input } from "@angular/core";
import { Color } from "src/app/enum/color";

@Component({
  selector: "tag",
  templateUrl: "./tag.component.html",
  styleUrls: ['./tag.component.scss']
})
export class TagComponent {

  @Input() public name: string;
  @Input() public color: Color;

}
