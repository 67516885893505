import { Component, OnInit } from '@angular/core';
import { TitleService } from 'src/app/_services/title.service';
import { AppState } from 'src/app/state/app.state';
import { BehaviorSubject, Observable } from 'rxjs';
import { LOCAL_STORAGE_KEY } from 'src/app/config/local-storage-keys';
import { LocalStorage } from 'src/app/_helpers';
import { ActivatedRoute, Router } from '@angular/router';
import { LxmAppModule, OfferKind, UserAction } from 'src/app/enum';

@Component({
  template: ''
})
export class OffersRouter {

  constructor(
    appState: AppState,
    route: ActivatedRoute,
    router: Router
    ) {

    if (appState.hasModule(LxmAppModule.ReceiveOffers) && appState.hasRight([UserAction.ViewReceivedOffers])) {
      route.snapshot.data.offerKind = OfferKind.ReceivingOffer;
      router.navigate(['/offers/receiving'], {
        replaceUrl: true
      });
    }
    else {
      route.snapshot.data.offerKind = OfferKind.SupplierOffer;
      router.navigate(['/offers/sending'], {
        replaceUrl: true
      });
    }
  }
}

@Component({
  selector: 'offers-layout',
  templateUrl: './offers.layout.html',
  host: { 'class': 'content' },
  styleUrls: ['./offers.layout.scss']
})
export class OffersLayout implements OnInit {

  public search: Observable<any>;
  public searchSubject = new BehaviorSubject<any>(null);

  public searchPanelLocalStorageKey = LOCAL_STORAGE_KEY.OFFERS_SEARCH_PANEL_OPEN;
  public isSearchPanelOpen = false;

  public OfferKind = OfferKind;
  public offerKind: OfferKind;

  constructor(
    private _titleService: TitleService,
    public appState: AppState,
    route: ActivatedRoute
  ) {
    this.offerKind = route.snapshot.data.offerKind;
    this.isSearchPanelOpen = LocalStorage.getValue(this.searchPanelLocalStorageKey);
    this.search = this.searchSubject.asObservable();
  }

  public ngOnInit() {
    this._titleService.setTitle('cards.offers.title');
  }

}
