import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ISupplierPriceSchemeFormData, IRetailPriceSchemeFormData, ISupplierBasePriceScheme, ISupplierRetailerPriceScheme } from '../cards/product/product-pricelist/product-pricelist.model';

@Injectable({ providedIn: 'root' })
export class ProductPricelistService {

  constructor(private _http: HttpClient) { }

  public getSupplierPricesGridData(productId: string) {
    return this._http.get<any>(`api/productprices/supplier/griddata/${productId}`);
  }

  public getRetailPricesGridData(productId: string, retailChainId: string) {
    return this._http.get<any>(`api/productprices/retail/griddata/${productId}/${retailChainId}`);
  }

  public getSupplierFormData(id: string) {
    return this._http.get<ISupplierPriceSchemeFormData>(`api/productprices/supplier/formdata/${id || ''}`);
  }

  public getRetailFormData(id: string) {
    return this._http.get<IRetailPriceSchemeFormData>(`api/productprices/retail/formdata/${id || ''}`);
  }

  public saveSupplierPrice(id: string, data: any) {
    return this._http.post<string>(`api/productprices/supplier/${id || ''}`, data);
  }

  public saveRetailPrice(id: string, data: any) {
    return this._http.post<string>(`api/productprices/retail/${id || ''}`, data);
  }

  public deleteSupplierPrice(id: string) {
    return this._http.delete<string>(`api/productprices/supplier/${id || ''}`);
  }

  public deleteRetailPrice(id: string) {
    return this._http.delete<string>(`api/productprices/retail/${id || ''}`);
  }

  public saveRetailPriceEndDate(id: string, data: any) {
    return this._http.post<string>(`api/productprices/retail/${id}/endDate`, data);
  }

  public getProductPrices(retailerId: string, date: Date, productIds: string[], currencyId: string) {
    const url = 'api/productprices/supplier/productPrices';
    const data = {
      retailerId: retailerId,
      currencyId: currencyId,
      date: date,
      productIds: productIds
    };
    return this._http.post<{ [index: string]: ISupplierBasePriceScheme }>(url, data);
  }

  public getRetailerProductPrices(retailerId: string, date: Date, productIds: string[]) {
    const url = 'api/productprices/supplier/retailerProductPrices';
    const data = {
      retailerId: retailerId,
      date: date,
      productIds: productIds
    };
    return this._http.post<{ [index: string]: any }>(url, data);
  }

  // retailer supplier prices --

  public getRetailerSupplierPrices(productId: string) {
    return this._http.get<any>(`api/products/${productId}/retailerSupplierPrices`);
  }

  public getRetailerSupplierPriceSchemeFormData(productId: string, schemeId?: string) {
    return this._http.get<any>(`api/products/${productId}/retailerSupplierPrices/formData/${schemeId || ''}`);
  }

  public saveRetailerSupplierPrice(productId: string, schemeId: string, data: any) {
    return this._http.post<string>(`api/products/${productId}/retailerSupplierPrices/${schemeId || ''}`, data);
  }

  public deleteRetailerSupplierPrice(productId: string, schemeId: string) {
    return this._http.delete<string>(`api/products/${productId}/retailerSupplierPrices/${schemeId}`);
  }

  // -- retailer supplier prices


  public getDefaultDiscountPercent(productId: string, clientId: string) {
    return this._http.get<string>(`api/clients/${clientId}/discountRules/discount/${productId}`);
  }

}
