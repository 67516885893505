import { Component, OnInit, OnChanges, Input, Optional, SimpleChanges } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { IExpiryTarget } from 'src/app/cards/product/product-info/product-info.model';
import { ExpiryTargetType, getKey, LxmAppModule } from 'src/app/enum';
import { EditableListBase } from '../editable-list-base';
import { AppState } from 'src/app/state/app.state';
import { ProductService } from 'src/app/_services';

@Component({
  selector: 'product-retailer-expiry-list',
  templateUrl: './product-retailer-expiry-list.component.html',
  styleUrls: ['./product-retailer-expiry-list.scss']
})
export class ProductRetailerExpiryListComponent extends EditableListBase implements OnInit, OnChanges {

  @Input() type: "buyer" | "seller" | "market";

  protected get _defaultItem() {
    return {
      id: null,
      target: 0,
      expiryInDays: null,
      expiryForRetailerInDays: null,
      minExpiryOnDelivery: null,
      isDeleted: false,
      isNew: true,
      retailerId: null,
      retailerName: null
    };
  }

  public tableSupplier = {
    columns: ['expiry_type', 'expiry_days', 'expiry_retailer', 'expiry_actions']
  };

  public tableRetailer = {
    columns: ['expiry_days', 'min_expiry_on_delivery', 'expiry_to_target']
  };

  public tableMarket = {
    columns: ['expiry_days', 'min_expiry_on_delivery']
  };

  public get table() {
    if (this.type == "seller") {
      return this.tableSupplier;
    }
    else if (this.type == "buyer") {
      return this.tableRetailer;
    }
    else if (this.type == "market") {
      return this.tableMarket;
    }

    return { columns: [] };
  }

  public get columns() {
    return this.table.columns;
  }

  @Input() public expiryTargets: IExpiryTarget[];
  @Input() public singleRow = false;

  public ExpiryTargetType = ExpiryTargetType;
  public expiryTargetsOptions: any[];

  constructor(
    @Optional() controlContainer: ControlContainer, 
    private _appState: AppState,
    private _productService: ProductService) {
    super(controlContainer);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._getExpiryTargetOptions();
    this.sortDataSource();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    this.sortDataSource();
  }

  public targetCompare = (a: any, b: any) => {
    return a.type === b.type && a.retailerId === b.retailerId;
  }

  private _getExpiryTargetOptions(): void {
    this.expiryTargetsOptions = this.expiryTargets?.map(x => {
      const item = Object.assign({}, x);

      const labelKey = this._getTargetLabelKey(x);
      let sortKey = labelKey;

      if (x.type === ExpiryTargetType.Internal) {
        sortKey = '\u0000' + sortKey;
      }

      (item as any).labelKey = labelKey;
      (item as any).sortKey = sortKey;

      return item;
    });
  }

  public expiryTargetSort = function (a: any, b: any) {
    return a.sortKey > b.sortKey ? 1 : -1;
  }

  private _getTargetLabelKey(x: IExpiryTarget) {
    return x.retailerId ? x.name : getKey(ExpiryTargetType, x.type);
  }

  protected _getNormalizedItem(item: any) {
    if (item.isNormalized) {
      return item;
    }

    const labelKey = this._getTargetLabelKey({
      type: item.target,
      retailerId: item.retailerId,
      name: item.retailerName
    });

    return {
      id: item.id,
      target: item.target ? { type: item.target, retailerId: item.retailerId, labelKey: labelKey } : null,
      expiryInDays: item.expiryInDays,
      expiryForRetailerInDays: item.expiryForRetailerInDays,
      minExpiryOnDelivery: item.minExpiryOnDelivery,
      isDeleted: item.isDeleted || false,
      isNew: item.isNew || false,
      isNormalized: true,
      sortOrder: (item.target == ExpiryTargetType.Public ? 0 : item.target).toString() + "_" + item.retailerName
    };
  }

  private sortDataSource(): void {
    let data = this.dataSource.data;
    if (data.length == 0) {
      return;
    }

    let sorted = data.sort((a, b) => a.value.sortOrder.localeCompare(b.value.sortOrder));
    this.dataSource.data = sorted;
  }

  public get showContent() {
    if (this.singleRow) {
      const hasData = this.dataSource.data[0]?.value?.id ? true : false;
      return hasData ? true : !this.readonly;
    }
    return this.hasData;
  }

  public calcExpiry(row) {
   
    const retailerId = row.value.target?.retailerId; 
    if (!retailerId) {
      return;
    }

    const expiryInDays = row.value.expiryInDays;
    if (!expiryInDays) {
      return;
    }

    this._productService.calcClientExpiry(retailerId, expiryInDays)
      .subscribe(res => {
        if (res) {
          row.get('expiryForRetailerInDays').setValue(res);
        }
      });
  }
}
