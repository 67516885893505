<ng-container [formGroup]="formGroup">
    <form-field dialog label="cards.settings.product_management_settings.product_tag_edit.label.gs1_segments" [readonly]="!canEdit">
      <lxm-select select
                  [searchable]="true"
                  labelField="title"
                  compareField="id"
                  [multiple]="true"
                  for="gs1Segments"
                  url="api/clvac/product-level-gs1-segments/">
      </lxm-select>
      <validation-errors for="gs1Segments"></validation-errors>
      <validation-errors for="gs1SegmentIds" aliasTo="gs1Segments"></validation-errors>
    </form-field>
</ng-container>
