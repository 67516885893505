import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";

@Component({
  selector: "[ov-menu-item]",
  templateUrl: "./ov-menu-item.component.html",
  styleUrls: ["./ov-menu-item.component.scss"],
  host: {
    class:
      "flex min-h-big cursor-pointer items-center gap-12x px-8x py-4x text-grey-100 hover:bg-veryLightPeach [&.selected]:bg-veryLightPeach [&.active]:bg-veryLightPeach", // [&.selected]:bg-[#f3f2f2]
  },
})
export class OverlayViewMenuItemComponent {
  @Input() public icon: string;
  @Input() public label: string;

  constructor() {}
}
