import { Component, Inject, Optional } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { of, forkJoin } from 'rxjs';
import { ContentLanguage } from 'src/app/_helpers';
import { ProductService } from 'src/app/_services';
import { IDisplayProduct } from 'src/app/models/IDisplayProduct';
import { formatProductAcLabel } from 'src/app/modules/orders/helpers/shared';
import { joinAssortmentGroupNames, stringIsNullOrEmpty } from 'src/app/util/fn';
import { Guid } from 'src/app/util/Guid';
import { environment } from 'src/environments/environment';

@Component({
  selector: "product-assortment-copy-dialog",
  host: { 'class': 'dialog' },
  templateUrl: './assortment-copy.dialog.html',
  styleUrls: ['./assortment-copy.dialog.scss']
})
export class ProductAssortmentCopyDialog {

  public formGroup: FormGroup;
  public readonly = false;

  public targetProduct: IDisplayProduct;
  public copyProduct: IDisplayProduct

  public dataSource = new MatTableDataSource<any>();

  public displayedColumns = ['valid', 'status', 'assortment_class'];

  public copyProductHasNoData = false;

  private _segmentId: string;

  constructor(
    public dialogRef: MatDialogRef<ProductAssortmentCopyDialog>,
    private _contentLanguage: ContentLanguage,
    private _productService: ProductService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.targetProduct = data.product;
    this._segmentId = data.segmentId;

    console.log(data);

    this.formGroup = new FormGroup({
      copyProductAc: new FormControl()
    });

    const copyProductAc = this.formGroup.get('copyProductAc');
    copyProductAc.valueChanges.subscribe(x => {
      if (x) {
        const productId = x.id;
        const copyProduct = {
          name: this._contentLanguage.get(x.name),
          id: productId,
          ean: x.ean,
          imageUrl: x.hasImage ? `${environment.apiUrl}api/products/${productId}/images/thumbnailMedium` : null
        };

        this.copyProduct = copyProduct;
        this._productService.getProductCurrentAssortment(productId, this._segmentId).result(null, res => {
          if (res) {
            this.dataSource.data = [res];
            this.copyProductHasNoData = false;
          } else {
            this.dataSource.data = [];
            this.copyProductHasNoData = true;
            
          }
        }, err => {
          console.log(err);
        });

        copyProductAc.setValue(null, { emitEvent: false });
      }
    });
  }

  public get hasCopyProductData() {
    return this.dataSource.data[0] ? true : false;
  }

  public get hasCopyProduct() {
    return this.copyProduct ? true : false;
  }

  
  public joinAssortmentGroupNames(row) {
    return joinAssortmentGroupNames(row);
  }

  public removeCopyProduct() {
    this.copyProduct = null;
    this.dataSource.data = [];
  }

  public productAcLabel = (item: any) => {
    return formatProductAcLabel(item, this._contentLanguage);
  }

  public getProductAcExcludeIds = () => {
    const originalProductId = null; //this.originalProduct?.productId;
    return originalProductId ? [originalProductId] : [];
  }

  public save(): void {

    const productId = this.copyProduct?.id;
    this._productService.getProductCurrentAssortment(productId, this._segmentId).result(null, res => {
      this.closeDialog(res);
    }, e => {
      console.error(e);
    })
  }

  public closeDialog(data?: any): void {
    this.dialogRef.close(data);
  }
}
