import { Directive, Input, ViewContainerRef, TemplateRef, ContentChild, ComponentFactoryResolver } from "@angular/core";
import { LxmTreeHeaderComponent } from "./lxm-tree-header.component";


@Directive({
    selector: "[lxmTreeCellDef]"
})
export class LxmTreeCellDefDirective {
    private _context: any = {};

    @Input()
    set lxmTreeCellDef(context: any) {
        this._context.$implicit = this._context.segment = context;
    }

    constructor(public templateRef: TemplateRef<any>) { }
}

@Directive({
    selector: "[lxmTreeHeaderDef]"
})
export class LxmTreeHeaderDefDirective {
    constructor(public templateRef: TemplateRef<any>) { }
}

@Directive({
    selector: "[lxmTreeColumnDef]"
})
export class LxmTreeColumnDefDirective {
    public colName: string;
    
  @ContentChild(LxmTreeHeaderDefDirective, { static: false })
    public headerDef: LxmTreeHeaderDefDirective;
    
  @ContentChild(LxmTreeCellDefDirective, { static: false })
    public cellDef: LxmTreeCellDefDirective;
    
    @Input()
    set lxmTreeColumnDef(context: string) {
        this.colName = context;
    }
    
    constructor(private _compFactoryResolver: ComponentFactoryResolver) { }

    public createHeader(vc: ViewContainerRef) {
        let compFactory = this._compFactoryResolver.resolveComponentFactory(LxmTreeHeaderComponent);
        let compRef = vc.createComponent(compFactory);

        compRef.instance.colName = this.colName;
        compRef.instance.templateRef = this.headerDef.templateRef;
    }
}
