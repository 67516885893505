import { Component, Input } from "@angular/core";

@Component({
  selector: "alias",
  templateUrl: "./alias.component.html",
  styleUrls: ['./alias.scss']
})
export class AliasComponent {

  @Input()
  public name: string;

}
