import { Component, Input } from "@angular/core";

@Component({
  selector: "lxm-progress-bar",
  templateUrl: "./progress-bar.component.html",
  styleUrls: ['./progress-bar.scss']
})
export class LxmProgressBarComponent {

  @Input() public progress = 0;
  @Input() public mode: string = 'determinate';
  @Input() public showProgressText = true;

}
