export enum DayOfWeek {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7
}

(DayOfWeek as any).__typeName = 'DayOfWeek';

const _ = 'enums.DayOfWeek.';

(DayOfWeek as any).__altTranslationKeys = {
  [DayOfWeek.Monday] : _ + 'Monday',
  [DayOfWeek.Tuesday] : _ + 'Tuesday',
  [DayOfWeek.Wednesday] : _ + 'Wednesday',
  [DayOfWeek.Thursday] : _ + 'Thursday',
  [DayOfWeek.Friday] : _ + 'Friday',
  [DayOfWeek.Saturday] : _ + 'Saturday',
  [DayOfWeek.Sunday] : _ + 'Sunday',
};