import { Injectable } from "@angular/core";
import { FormControl } from "@angular/forms";
import moment from "moment";
import { Subject, Observable, BehaviorSubject } from "rxjs";
import { ClientTimelineItemType, UserAction } from "src/app/enum";
import { AuthenticationService } from "src/app/_services";
import { ClientsService, IClientCard, IClientFormData, IClientInfo, IClientTimelineItem } from "src/app/_services/clients.service";

@Injectable({ providedIn: "root" })
export class ClientState {
  constructor(private _authService: AuthenticationService) {}

  public clientCard: IClientCard;
  public clientInfo: IClientInfo;
  public formData: IClientFormData;

  public clientId: string;

  public isSelfSupplier = false;
  public isClient = false;
  public isUserOwner = false;

  public userCanViewOthers = false;

  public userCanEdit = false;
  public userCanEditOthers = false;
  public userCanViewOffers = false;

  public timelineSubject = new BehaviorSubject<IClientTimelineItem[]>(null);
  public getTimelineSubject = new Subject<IClientTimelineItem[]>();

  public get timeline() {
    return this.timelineSubject.value;
  }

  public canEdit = false;
  public canChangeOwner = false;

  public timelineFilter: ClientTimelineItemType = null;

  public setListFilter(itemType: ClientTimelineItemType) {
    this.timelineFilter = itemType;
  }

  public getUserPerimissions(userId = this.clientInfo?.owner?.id): void {
    const isUserOwner = this._authService.isUserOwner(userId); 
    const userCanEdit = this._authService.hasRight([UserAction.ManageClients]);
    const userCanEditOthers = this._authService.hasRight([UserAction.ManageOthersClients]);
    const userCanViewOthers = this._authService.hasRight([UserAction.ViewOthersClients]);
    const userCanViewOffers = this._authService.hasRight([UserAction.ViewSupplierOffers, UserAction.ViewOthersSupplierOffers, UserAction.ConfirmSupplierOffers, UserAction.ReceiveOffers]);

    this.isUserOwner = isUserOwner;
    this.userCanEdit = userCanEdit;
    this.userCanEditOthers = userCanEditOthers;
    this.userCanViewOthers = userCanViewOthers;
    this.userCanViewOffers = userCanViewOffers;

    this.canEdit = isUserOwner ? userCanEdit : userCanEditOthers;
    this.canChangeOwner = isUserOwner ? userCanEdit : (userCanEditOthers ? true : false);
  }

}
