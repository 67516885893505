<lxm-dialog [dialogRef]="dialogRef" [formGroup]="form">
  <span header-title>{{translationsPath + ".title" | translate}}</span>

  <div body style="padding-bottom: 40px;">
      <ng-container *ngIf="classificatorId == ClvType.Manufacturer">
          <manufacturer-info [formGroup]="form" [countryOptions]="countryOptions" [phoneCodes]="phoneCodes"></manufacturer-info>
      </ng-container>
      <ng-container *ngIf="classificatorId != ClvType.Manufacturer" [ngSwitch]="classificatorId">
        <classificator-value-info [formGroup]="form" [translationsPath]="translationsPath" [canEdit]="appState.hasRights(requiredRightsForEdit)"></classificator-value-info>
        <product-brand-info [formGroup]="form" *ngSwitchCase="ClvType.ProductBrand"></product-brand-info>
        <logistics-partner-info [formGroup]="form" *ngSwitchCase="ClvType.LogisticsPartner" [countryOptions]="countryOptions"></logistics-partner-info>
        <product-unit-code-type-format-info [formGroup]="form" [unitCodeTypes]="unitCodeTypes" *ngSwitchCase="ClvType.ProductUnitCodeTypeFormat"></product-unit-code-type-format-info>
        <calendar-group-info [formGroup]="form" *ngSwitchCase="ClvType.CalendarGroup" [campaignTypes]="campaignTypes"></calendar-group-info>
        <product-tag-info [formGroup]="form" *ngSwitchCase="ClvType.ProductTag"></product-tag-info>
        <assortment-status-info [formGroup]="form" *ngSwitchCase="ClvType.AssortmentStatus"></assortment-status-info>
        <price-group-info [formGroup]="form" *ngSwitchCase="ClvType.PriceGroup"></price-group-info>
        <client-tag-info [formGroup]="form" *ngSwitchCase="ClvType.ClientTag"></client-tag-info>
        <campaign-type-info [formGroup]="form" *ngSwitchCase="ClvType.CampaignType"></campaign-type-info>
      </ng-container>
  </div>

  <div footer>
    <lxm-button type="primary" label="action.save" (click)="save()" buttonType="submit" [loading]="saveInProgress" *requiredRights="requiredRightsForEdit"></lxm-button>
    <lxm-button type="secondary" class="ml15" label="action.cancel" (click)="closeDialog()"></lxm-button>
  </div>

</lxm-dialog>
