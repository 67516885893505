import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Renderer2 } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ScriptService {
 
  constructor(
    @Inject(DOCUMENT) private document: Document
  ) { }
 
  public loadExternalJsScript(renderer: Renderer2, src: string, async: boolean = false): HTMLScriptElement {
    const script = renderer.createElement('script');
    script.async = async;
    script.type = 'text/javascript';
    script.src = src;
    renderer.appendChild(this.document.body, script);
    return script;
  }

  public loadJsScript(renderer: Renderer2, src: string, async: boolean = false): HTMLScriptElement {
    const script = renderer.createElement('script') as HTMLScriptElement;
    script.async = async;
    script.type = 'text/javascript';
    script.innerHTML = src;
    renderer.appendChild(this.document.body, script);
    return script;
  }
}