<card-expanding [valid]="productValidity.getCardStatusValidType(cardSection)" [expanded]="isExpanded" (onExpand)="saveLocalExpandedValue($event)">

  <span header-title>{{'cards.products.product_assortment_management.title' | translate}}</span>

  <div header-actions *requiredRights="[UserAction.ManageProducts]">
    <lxm-button type="beige" icon="plus-dark"
      label="cards.products.product_assortment_management.action.add_schema" (click)="openSchemaDialog()">
    </lxm-button>
  </div>

  <div body>

    <div *ngIf="!assortments?.length">
      <no-content image="no-product-catalog" text="global.no_product_assortments" [inline]="true"></no-content>
    </div>

    <div class="row overflow-x" *ngIf="assortments?.length">
      <div class="col-12 no-padding">

        <mat-table [dataSource]="assortments" matSort multiTemplateDataRows [@.disabled]="true">

          <!-- User Column -->
          <ng-container matColumnDef="user">
            <mat-header-cell *matHeaderCellDef>
              {{'cards.products.product_assortment_management.table.user' | translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span>
                <span>
                  {{row.modifiedBy}}
                </span>
              </span>
            </mat-cell>
          </ng-container>

          <!-- Validity Column -->
          <ng-container matColumnDef="valid">
            <mat-header-cell *matHeaderCellDef>
              {{'cards.products.product_assortment_management.table.valid' | translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span>{{row.validFrom | formDate}} - {{row.validTo | formDate}}</span>
            </mat-cell>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef>
              {{'cards.products.product_assortment_management.table.status' | translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <status-circle [status]="'color-' + row.status.color" class="mr5"></status-circle>
              <span>{{row.status.value}}</span>
            </mat-cell>
          </ng-container>

          <!-- Assortment groups -->
          <ng-container matColumnDef="assortment_groups">
            <mat-header-cell *matHeaderCellDef>
              {{'cards.products.product_assortment_management.table.assortment_groups' | translate}}
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{joinAssortmentGroupNames(row)}}
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="table"></mat-header-row>
          <mat-row class="assortment-table-row" 
            [class.table-row-hover]="canEdit && row?.isEditable"
            [class.current]="row?.isCurrent" 
            [class.upcoming]="!row.isCurrent && row?.isEditable" 
            [class.expired]="!row.isCurrent && !row.isEditable" 
            *matRowDef="let row; columns: table;"
            (click)="openSchemaDialog(row)"></mat-row>
        </mat-table>

      </div>

    </div>

  </div>

</card-expanding>