import { Component, HostBinding, Input } from "@angular/core";

@Component({
  selector: "toggle-arrow",
  templateUrl: "./toggle-arrow.component.html",
  styleUrls: ['./toggle-arrow.component.scss']
})
export class ToggleArrowComponent {

  @HostBinding('class.toggled') @Input() public toggled: boolean = false;

}
