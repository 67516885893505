import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { IClvDto } from '../cards/product/product-info/product-info.model';
import { LocaleService } from './locale.service';

@Injectable({ providedIn: "root" })
export class ClvService {
    public constructor(private _http: HttpClient, private _localeService: LocaleService) {}

    public save(dataUrl: string, id: string, data: any): Observable<object> {
        return this._http.post(`api/${dataUrl}/${id || ''}`, data);
    }

    public getMovieRatings(formatId: string) {
      return this._http.get<any>(`api/clvac/movieRatings/${formatId}`, {});
    }

    public translate(clv: IClvDto, labelField?: string): string {
        if (!clv) {
            return null;
        }

        const lang = this._localeService.currentLanguage.value;

        let label = labelField ? clv[labelField] : null;
        if (typeof label === 'string') {
          return label;
        }

        if (label === null) {
          label = clv.translatedValue;
        }

        if (label === null) {
          return clv.value;
        }

        return label[lang];
    }
}
