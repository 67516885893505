<lxm-dialog [dialogRef]="dialogRef" [formGroup]="form">
  <span header-title>
    {{ 'cards.offer.mass_changes.title' | translate }}
  </span>

  <div body>
    <div class="selected-products" *ngIf="products.length > 0">
      <span class="selected-count" [tooltip]="productsTemplate">
        {{(products.length)}}
      </span>
      <span class="selected-text">
        {{'global.selected_products' | translate}}
      </span>
    </div>

    <ng-template #productsTemplate>
      <div class="selected-products-wrapper">
        <display-product
          class="mt10"
          *ngFor="let product of products"
          [data]="product"
        ></display-product>
      </div>
    </ng-template>

    <div class="separator"></div>

    <div class="row mt20 mb20">
      <div class="col-12 no-padding">
        <info-array prelude="cards.offer.mass_changes.info_text"></info-array>
      </div>
    </div>

    <div class="row">
      <div class="col-12 no-padding">
        <form-field
          dialog
          [readonly]="readonly"
          label="cards.offer.retailer_details.label.segment"
        >
          <lxm-select
            select
            for="segment"
            labelField="title"
            [options]="segmentOptions"
            [readonly]="readonly"
            [searchable]="true"
            [labelFn]="segmentTreeLabel"
            [disableOption]="disableSegmentTreeOption"
          >
          </lxm-select>
          <validation-errors
            for="segment"
            aliasTo="segmentId"
          ></validation-errors>
        </form-field>

        <form-field dialog label="cards.offer.retailer_details.label.tags">
          <lxm-select
            select
            for="tags"
            labelField="value"
            compareField="id"
            url="api/clvac/productTags/"
            [acData]="productGs1SegmentId"
            [readonly]="readonly"
            [searchable]="true"
            [multiple]="true"
          >
          </lxm-select>
          <validation-errors for="tags"></validation-errors>
        </form-field>

        <form-field
          dialog
          label="cards.offer.retailer_details.label.attributes"
        >
          <lxm-select
            select
            for="attributes"
            labelField="translatedValue"
            [options]="attributeOptions"
            [readonly]="readonly"
            [multiple]="true"
            [isTranslated]="true"
            [searchable]="true"
            [sortByLabel]="true"
          >
          </lxm-select>
          <validation-errors for="attributes"></validation-errors>
        </form-field>

        <!--  -->

        <form-field
          dialog
          for="parameter1"
          type="input-text"
          label="cards.offer.retailer_details.label.parameter1"
          [readonly]="readonly"
          *ngIf="isParameterVisible('parameter1')"
        >
          <validation-errors for="parameter1"></validation-errors>
        </form-field>

        <form-field
          dialog
          for="parameter2"
          type="input-text"
          label="cards.offer.retailer_details.label.parameter2"
          [readonly]="readonly"
          *ngIf="isParameterVisible('parameter2')"
        >
          <validation-errors for="parameter2"></validation-errors>
        </form-field>

        <form-field
          dialog
          for="parameter3"
          type="input-text"
          label="cards.offer.retailer_details.label.parameter3"
          [readonly]="readonly"
          *ngIf="isParameterVisible('parameter3')"
        >
          <validation-errors for="parameter3"></validation-errors>
        </form-field>

        <form-field
          dialog
          for="parameter4"
          type="input-text"
          label="cards.offer.retailer_details.label.parameter4"
          inputClass="w60p"
          [readonly]="readonly"
          *ngIf="isParameterVisible('parameter4')"
        >
          <validation-errors for="parameter4"></validation-errors>
        </form-field>

        <form-field
          dialog
          for="parameter5"
          type="input-text"
          label="cards.offer.retailer_details.label.parameter5"
          [readonly]="readonly"
          *ngIf="isParameterVisible('parameter5')"
        >
          <validation-errors for="parameter5"></validation-errors>
        </form-field>

        <form-field
          dialog
          for="parameter6"
          type="input-text"
          label="cards.offer.retailer_details.label.parameter6"
          [readonly]="readonly"
          *ngIf="isParameterVisible('parameter6')"
        >
          <validation-errors for="parameter6"></validation-errors>
        </form-field>

        <form-field
          dialog
          for="parameter7"
          type="input-text"
          label="cards.offer.retailer_details.label.parameter7"
          [readonly]="readonly"
          *ngIf="isParameterVisible('parameter7')"
        >
          <validation-errors for="parameter7"></validation-errors>
        </form-field>

        <form-field
          dialog
          for="parameter8"
          type="input-text"
          label="cards.offer.retailer_details.label.parameter8"
          [readonly]="readonly"
          *ngIf="isParameterVisible('parameter8')"
        >
          <validation-errors for="parameter8"></validation-errors>
        </form-field>

        <form-field
          dialog
          for="parameter9"
          type="input-text"
          label="cards.offer.retailer_details.label.parameter9"
          [readonly]="readonly"
          *ngIf="isParameterVisible('parameter9')"
        >
          <validation-errors for="parameter9"></validation-errors>
        </form-field>

        <form-field
          dialog
          for="parameter10"
          type="input-text"
          label="cards.offer.retailer_details.label.parameter10"
          [readonly]="readonly"
          *ngIf="isParameterVisible('parameter10')"
        >
          <validation-errors for="parameter10"></validation-errors>
        </form-field>

        <lxm-button
          label="cards.offer.retailer_details.label.add_parameter"
          type="transparent"
          class="ml15"
          *ngIf="!readonly && isAddParameterButtonVisible"
          [icon]="'plus-dark'"
          (click)="addParameter()"
        >
        </lxm-button>
      </div>
    </div>
  </div>

  <div footer>
    <lxm-button
      type="primary"
      label="action.save"
      (click)="save()"
      buttonType="submit"
      *ngIf="!readonly"
    ></lxm-button>
    <lxm-button
      type="secondary"
      [class.ml15]="!readonly"
      label="action.cancel"
      (click)="closeDialog()"
    ></lxm-button>
  </div>
</lxm-dialog>
