<lxm-dialog [dialogRef]="dialogRef" [formGroup]="formGroup" #dialog>
  <span header-title>
    {{'cards.products.product_pricelist.expiration_dialog.title' | translate}}
  </span>
  <div body>
    <display-product [data]="product" class="mt20 mb20"></display-product>
    <form-field label="cards.products.product_pricelist.expiration_dialog.label.scheme_type">
      <div any class="flex flex-row nowrap">
        <type-indicator [typeId]="schemeType" enum="ProductPriceSchemeType"></type-indicator>
        {{schemeType | translateEnum : "ProductPriceSchemeType" }}
      </div>
    </form-field>

    <form-field label="cards.products.product_pricelist.expiration_dialog.label.valid">
      <div any class="flex flex-row align-center">
        <lxm-datepicker for="validFrom" [disabled]="true"></lxm-datepicker>
        <span class="date-range__separator">
          -
        </span>
        <lxm-datepicker for="validTo" [min]="minValidTo"></lxm-datepicker>
      </div>
    </form-field>

    <card-message type="error" *ngIf="formGroup.errors?.validTo">
      {{formGroup.errors?.validTo[0]?.value | translate}}
    </card-message>

  </div>

  <div footer class="justify-between">

    <span class="flex flex-row">
      <lxm-button type="orange" label="action.save" [loading]="actionLoading.save" (click)="save()" buttonType="submit">
      </lxm-button>
      <lxm-button type="grey" label="action.cancel" class="ml10" (click)="closeDialog()"></lxm-button>
    </span>

  </div>

</lxm-dialog>