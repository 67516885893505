import { Component, OnInit, AfterViewInit, Optional, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { EditableListBase } from 'src/app/components/_partials/editable-list-base';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ISupplierBasePriceScheme } from 'src/app/cards/product/product-pricelist/product-pricelist.model';
import { forkJoin, Observable, of } from 'rxjs';
import { ProductPricelistService, OfferService, AuthenticationService } from 'src/app/_services';
import { calculateMargin } from 'src/app/_helpers/calculate';
import { MatDialog } from '@angular/material/dialog';
import { OfferType, UserAction, OfferProductStatus, OfferKind } from 'src/app/enum';
import { IOfferCard, IUserSettings } from 'src/app/models';
import { LxmMessage } from 'src/app/_helpers';
import { appSettings } from 'src/app/app.settings';
import { ContentLanguage } from 'src/app/_helpers/content-language';
import { environment } from 'src/environments/environment';
import { ProductCampaignInfoDialogComponent } from '../dialog/supplier-offer-product-campaign/supplier-offer-product-campaign.dialog';

@Component({
  selector: 'request-offer-products-list',
  templateUrl: './request-offer-products.component.html',
  styleUrls: ['../offer-products.component.scss', './request-offer-table.scss']
})
export class RequestOfferProductsListComponent extends EditableListBase implements OnInit, AfterViewInit, OnChanges {

  private static _cols = [
    //'offer_product_expander',
    'offer_product_image',
    'offer_product_name',
    'offer_product_supplier',
    'offer_product_list_price',
    // 'offer_product_price_retailer',
    'offer_product_requested_price',
    // 'offer_product_recommended_retail_price',
    // 'offer_product_retail_price',
    'request_offer_actions'
  ];

  private static _campaignCols = [
    //'offer_product_expander',
    'offer_product_image',
    'offer_product_name',
    'offer_product_supplier',
    'offer_product_display',
    'offer_product_main_assortment_price',
    'offer_product_recommended_retail_price',
    'offer_campaign',
    'request_offer_actions'
  ];

  public UserAction = UserAction;
  public OfferProductStatus = OfferProductStatus;
  public OfferType = OfferType;

  public translationsPath = 'cards.offer.products.table';

  @ViewChild(MatPaginator) paginator: MatPaginator;

  protected get _defaultItem(): any {
    return {
      productId: null,
      preview: {},
      campaignInfo: {},
      name: null,
      display: false,
      limit: null,
      ean: null,
      brand: null,
      supplier: null,
      availableFrom: null,
      selfPrice: null,
      listPrice: null,
      vat: null,
      mainAssortmentPrice: null,
      salePrice: null,
      suggestedRetailPrice: null,
      shops: [],
      assortmentStatus: null,
      isDeleted: false,
      isNew: true,
      imageUrl: null,
      thumbnailUrl: null,
      thumbnail: null,
      thumbnailContentType: null,
      validSchemeSelfPrice: null,
      validSchemeListPrice: null,
      validSchemeMainAssortmentPrice: null,

      requestedPriceInput: null,
      requestedPrice: null,
      retailPrice: null
    };
  }

  @Input() public offerId: string;
  @Input() public date: Date;
  @Input() public offerType: OfferType;
  @Input() public readonly = true;
  @Input() public showNoContentText = true;

  public expandedElements = [];
  public expanders = [];

  public get retailerId() {
    return this._auth.currentTenantId.value;
  }

  public get columns(): string[] {
    return this.offerType === OfferType.MainAssortment
      ? RequestOfferProductsListComponent._cols
      : RequestOfferProductsListComponent._campaignCols;
  }

  constructor(
    route: ActivatedRoute,

    private _auth: AuthenticationService,
    private _router: Router,
    private _dialog: MatDialog,
    private _offerService: OfferService,
    private _pricesService: ProductPricelistService,
    private _message: LxmMessage,
    private _contentLanguage: ContentLanguage,
    @Optional() controlContainer: ControlContainer
  ) {
    super(controlContainer);
    const offerCard = route.snapshot.data.offerCard as IOfferCard;
    if (offerCard) {
      this.offerId = offerCard.offerDetail.id;
    }
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._productsListComponentInit();
  }

  private _productsListComponentInit() {
    const newProductAc = new FormControl();
    this.fgInternal.addControl('newProduct', newProductAc);

    newProductAc.valueChanges.subscribe(x => {
      if (x) {

        var productId = x.id;

        const basePricesObservable = this._getBasePrices([productId]);
        // const productsWithDisplayObservable = this._offerService.productsWithDisplay([productId], this.retailerId);

        forkJoin([basePricesObservable])
          .subscribe(results => {
            const basePrices = results[0];
            // const productsWithDisplay = results[1];
            this.addItem({
              productId: productId,
              // productHasDisplay: productsWithDisplay[productId],
              name: x.name,
              ean: x.ean,
              status: basePrices[productId]?.status,
              brand: { id: x.brand?.id, value: x.brand?.value },
              supplier: { id: x.supplier?.id, name: x.supplier?.name },
              listPrice: basePrices[productId]?.publicSalesPrice,
              vat: basePrices[productId]?.vat,
              mainAssortmentPrice: basePrices[productId]?.mainAssortmentPrice || basePrices[productId]?.publicSalesPrice,
              imageUrl: x.hasImage ? `${environment.apiUrl}api/products/${productId}/images/thumbnailMedium` : null,
              isNew: true
            });
            // this._bindCalculations();
          });

        // this.addItem({
        //   productId: productId,
        //   // productHasDisplay: productsWithDisplay[productId],
        //   name: { et: x.name_et, ru: x.name_ru },
        //   ean: x.ean,
        //   assortmentStatus: basePrices[productId]?.status,
        //   brand: { id: x.brand?.id, value: x.brand?.name },
        //   supplier: { id: x.supplier?.id, value: x.supplier?.name },
        //   // listPrice: basePrices[productId]?.publicSalesPrice,
        //   // vat: basePrices[productId]?.vat,
        //   // mainAssortmentPrice: basePrices[productId]?.mainAssortmentPrice || basePrices[productId]?.publicSalesPrice,
        //   imageUrl: `${environment.apiUrl}/api/products/${productId}/images/thumbnailMedium`,
        //   isNew: true
        // });

        newProductAc.setValue(null);
      }
    });
  }

  public ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  public ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    const dateChanged = changes?.date && changes.date.previousValue?.toISOString() !== changes.date.currentValue?.toISOString();
    if (dateChanged) {
      const productIds = this.dataSource.data.map(x => x.value.productId);
      this._updatePrices(productIds);
    }
  }

  public updatePrices() {
    this._offerService.updatePrices(this.offerId)
      .subscribe(res => {
        const arr = this.dataSource.data;
        for (let i = 0; i < arr.length; i++) {
          const productId = arr[i].value.productId;
          if (!res.hasOwnProperty(productId)) {
            continue;
          }

          arr[i].get('validSchemeListPrice').setValue(null);
          arr[i].get('validSchemeMainAssortmentPrice').setValue(null);

          arr[i].get('listPrice').setValue(res[productId]?.listPrice || null);
          arr[i].get('mainAssortmentPrice').setValue(res[productId]?.mainAssortmentPrice || null);
        }

        // this.anyPriceValueChanged = false;

        this._message.ok({
          message: 'cards.offer.products.prices_updated'
        });
      });
  }

  private _updatePrices(productIds: string[]) {
    const basePrices = this._getBasePrices(productIds);
    basePrices
      .subscribe(res => {
        const arr = this.dataSource.data;
        for (let i = 0; i < arr.length; i++) {
          const productId = arr[i].value.productId;
          if (!res.hasOwnProperty(productId)) {
            continue;
          }

          arr[i].get('assortmentStatus').setValue(res[productId]?.status || null);
          arr[i].get('listPrice').setValue(res[productId]?.publicSalesPrice || null);
          arr[i].get('mainAssortmentPrice').setValue(res[productId]?.mainAssortmentPrice || (res[productId]?.publicSalesPrice || null));
          arr[i].get('vat').setValue(res[productId]?.vat || null);
        }
      });
  }

  private _getBasePrices(productIds: string[]): Observable<{ [productId: string]: ISupplierBasePriceScheme }> {
    return !this.retailerId || !this.date || !productIds.length
      ? of(Object.assign({}, ...productIds.map(x => ({ [x]: null }))))
      : this._pricesService.getProductPrices(this.retailerId, this.date, productIds, null);
  }

  protected _getNormalizedItem(item: any) {
    if (item.isNormalized) {
      return item;
    }

    return {
      id: item.id,
      productId: item.productId,
      preview: item.preview || {},
      campaignInfo: item.campaignInfo || {},
      name: this._contentLanguage.get(item.name),
      display: item.display || false,
      limit: item.campaignInfo?.limit || null,
      ean: item.ean,
      brand: item.brand?.value || null,
      supplier: item.supplier?.name || null,
      availableFrom: item.availableFrom?.editableDate || null,
      listPrice: item.listPrice,
      salePrice: item.salePrice,
      vat: item.vat,
      mainAssortmentPrice: item.mainAssortmentPrice,
      suggestedRetailPrice: item.suggestedRetailPrice,
      shops: item.shops || [],
      assortmentStatus: item.status,
      isDeleted: item.isDeleted || false,
      isNew: item.isNew || false,
      isNormalized: true,
      thumbnailUrl: item.thumbnailUrl,
      thumbnail: item.thumbnail,
      thumbnailContentType: item.thumbnailContentType,
      imageUrl: item.imageUrl,
      // validSchemeSelfPrice: item.validSchemeSelfPrice,
      // validSchemeListPrice: item.validSchemeListPrice,
      // validSchemeMainAssortmentPrice: item.validSchemeMainAssortmentPrice,

      requestedPriceInput: item.requestedPrice,
      requestedPrice: item.requestedPrice,
      retailPrice: item.retailPrice,
      accepted: item.status === OfferProductStatus.Confirmed,
      rejected: item.status === OfferProductStatus.Rejected,
      editRequestedPrice: false,
      status: item.status
    };
  }

  public getProductAcExcludeIds = () => {
    return this.dataSource.data.map(x => x.value.productId);
  }

  public formatProductAcLabel = (item: any) => {
    const segments = [];
    if (item.ean) {
      segments.push(item.ean);
    }
    if (item.supplierCode) {
      segments.push(item.ean ? `(${item.supplierCode})` : item.supplierCode);
    }
    segments.push(this._contentLanguage.get(item.name));

    return segments.join(' ');
  }

  public toggleExpandRow(row: any) {
    if (this.expandedElements.includes(row)) {
      const index = this.expandedElements.indexOf(row);
      this.expandedElements.splice(index, 1);
    } else {
      this.expandedElements.push(row);
    }
  }

  public productClick(productId: string) {
    const url = this._router.createUrlTree(['/market', productId]).toString();
    window.open(window.location.origin + url, '_blank');
  }

  public calculateMargin(salesPrice, selfPrice) {
    return calculateMargin(
      salesPrice,
      selfPrice);
  }

  public isEmpty(o: any) {
    return (o && (Object.keys(o).length === 0));
  }

  public openCampaignDialog(row: any) {

    const dialogRef = this._dialog.open(ProductCampaignInfoDialogComponent, {
      ...appSettings.DIALOG_SIZES.L,
      data: {
        retailerId: this.retailerId,
        product: row.value,
        readonly: this.readonly,
        offerKind: OfferKind.OfferRequest
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }

      row.get('campaignInfo').setValue(res);
      // this.load();
    });

  }

  public setRequestedPrice(row: FormGroup) {

    let inputValue = row.controls['requestedPriceInput'].value;
    if (typeof (inputValue) === "string") {
        inputValue = parseFloat(inputValue?.replace(',', '.'));
    }
    row.controls['requestedPrice'].setValue(inputValue);
    row.controls['editRequestedPrice'].setValue(false);
  }

  public discardRequestedPrice(row: FormGroup) {
    row.controls['requestedPriceInput'].setValue(row.controls['requestedPrice'].value);
    row.controls['editRequestedPrice'].setValue(false);
  }
}
