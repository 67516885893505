import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SignalRService, ProductValidity } from 'src/app/_services';
import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import { IGs1Segment, IProductCard } from 'src/app/cards/product/product-info/product-info.model';
import { LxmAppModule, ProductCardSection, UserAction } from 'src/app/enum';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';
import { LxmMessage, LxmDialog } from 'src/app/_helpers';
import { CanComponentDeactivate } from 'src/app/_guards/can-deactivate.guard';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from 'src/app/state/app.state';

@Component({
  selector: 'product-layout',
  templateUrl: './product.layout.html',
  host: { 'class': 'content will-change-transform' },
  styleUrls: ['./product.layout.scss']
})
export class ProductLayout implements OnInit, OnDestroy, CanComponentDeactivate {

  public UserAction = UserAction;
  public LxmAppModule = LxmAppModule;

  private _routeSub: Subscription;
  public productId: string;
  public productGs1Segment: IGs1Segment;
  private _hubConnection: HubConnection;
  private _signalRSubscription: Subscription;

  public isOwnProduct: boolean = true;
  public isMarketProduct: boolean;

  public showAlcoholCard: boolean;
  public showSeafoodCard: boolean;
  public showElectronicsCard: boolean;
  public showNutritionCard: boolean;
  public showMeatCard: boolean;
  public showExpiryCard: boolean;
  public showMovieCard: boolean;
  public showMusicCard: boolean;
  public showBookCard: boolean;
  public showGameCard: boolean;
  public showMedicationCard: boolean;

  public search: Observable<any>;
  public searchSubject = new BehaviorSubject<any>(null);

  public editing = false;
  private _currentSection: any;

  public hasBuyerPimExtensions = false;
  public hasSellerPimExtensions = false;

  public petSuppliesGs1Segments = [];
  public showGrainFreeOnNutritionCard = false;

  constructor(
    public appState: AppState,
    private _router: Router,
    private _route: ActivatedRoute,
    private _signalRService: SignalRService,
    private _message: LxmMessage,
    private _dialog: LxmDialog,
    private _translate: TranslateService,
    public productValidity: ProductValidity,
  ) {
    this.hasBuyerPimExtensions = appState.hasModule(LxmAppModule.BuyerPimExtensions);
    this.hasSellerPimExtensions = appState.hasModule(LxmAppModule.SellerPimExtensions);
    this.search = this.searchSubject.asObservable();
  }

  public ngOnInit() {

    this._routeSub = this._route.params.subscribe(params => {
      this.productId = params['productId'];

      if (this.productId) {
        this.isMarketProduct = this._route.snapshot.data.isMarketProduct === true;

        const productCard = this._route.snapshot.data.productCard as IProductCard;
        this.isOwnProduct = productCard.isOwnProduct;

        this.petSuppliesGs1Segments = productCard.formData.petSuppliesGs1Segments;
        this.showHideSections(productCard.baseInfo.gs1Segment?.relevantProductCardSections || 0);
        this.setCurrentProductGs1Segment(productCard.baseInfo.gs1Segment);

        this._signalRSubscription = this._signalRService.commonHub
          .subscribe(connection => {
            this._hubConnection = connection;

            if (connection?.state === HubConnectionState.Connected) {
              connection.send('viewProduct', this.productId);
            }
          });
      }
    });

    // first is null, second is init from product-search-card
    this.search.pipe(skip(2)).subscribe(x => {
      if (this.isMarketProduct) {
        this._router.navigate(['market']);
      }
      else {
        this._router.navigate(['products']);
      }
    });
  }

  public showHideSections(relevantProductCardSections: ProductCardSection) {
    this.showAlcoholCard = (relevantProductCardSections & ProductCardSection.AlcoholInfo) > 0;
    this.showSeafoodCard = (relevantProductCardSections & ProductCardSection.SeafoodInfo) > 0;
    this.showElectronicsCard = (relevantProductCardSections & ProductCardSection.ElectronicsInfo) > 0;

    const showNutritionClaims = (relevantProductCardSections & ProductCardSection.NutritionClaims) > 0;
    const showNutritionFacts = (relevantProductCardSections & ProductCardSection.NutritionFacts) > 0;
    const showVitaminsAndMinerals = (relevantProductCardSections & ProductCardSection.VitaminsAndMinerals) > 0;

    this.showNutritionCard = (relevantProductCardSections & ProductCardSection.MarkingAndNutritionInfo) > 0;
    this.showMeatCard = (relevantProductCardSections & ProductCardSection.MeatInfo) > 0;
    this.showExpiryCard = (relevantProductCardSections & ProductCardSection.ExpiryInfo) > 0;

    this.showMovieCard = (relevantProductCardSections & ProductCardSection.MovieInfo) > 0;
    this.showMusicCard = (relevantProductCardSections & ProductCardSection.MusicInfo) > 0;
    this.showBookCard = (relevantProductCardSections & ProductCardSection.BookInfo) > 0;
    this.showGameCard = (relevantProductCardSections & ProductCardSection.GameInfo) > 0;
    this.showMedicationCard = (relevantProductCardSections & ProductCardSection.MedicationInfo) > 0;
  }

  public showHideGrainFreeOnNutritionCard(gs1SegmentId: string) {
    this.showGrainFreeOnNutritionCard = this.petSuppliesGs1Segments.includes(gs1SegmentId);
  }

  public ngOnDestroy() {
    if (this._hubConnection?.state === HubConnectionState.Connected && this.productId) {
      this._hubConnection.send('leaveProduct', this.productId);
    }

    if (this._signalRSubscription) {
      this._signalRSubscription.unsubscribe();
    }

    if (this._routeSub) {
      this._routeSub.unsubscribe();
    }
  }

  public setEditingState(editingEvent: any) {
    this.editing = editingEvent.editing;
    if (this.editing) {
      this._currentSection = editingEvent.section;
    } else {
      this._currentSection = null;
    }
  }

  public confirmDiscard() {
    this._confirmDiscardInternal(() => { this._currentSection.toggleEdit(); });
  }

  public setCurrentProductGs1Segment(segment: IGs1Segment) {
    this.productGs1Segment = segment;
    this.showHideGrainFreeOnNutritionCard(segment?.id)
  }

  canDeactivate(): Observable<boolean> | boolean | Promise<boolean> {

    if (!this.editing) {
      return true;
    }

    return new Promise<boolean>((resolve, reject) => {
      this._confirmDiscardInternal(() => { resolve(true); });
    });
  }

  private _confirmDiscardInternal(onConfirm: () => void) {
    const title = this._translate.instant(
      'cards.products.discard_changes.title', [
      this._translate.instant(this._currentSection.title)
    ]
    );

    this._dialog.confirm({
      'title': title,
      'message': 'cards.products.discard_changes.message',
    }, onConfirm);
  }
}
