import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class ContentLanguageService {

  constructor(private _http: HttpClient) {}

  public create(req: any) {
    return this._http.post<any>(`api/contentLanguages`, req);
  }

  public save(id: string, req: any) {
    return this._http.post<any>(`api/contentLanguages/${id}`, req);
  }
}
