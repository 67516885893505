import { Pipe, PipeTransform } from "@angular/core";
import { CurrencyPipe, formatNumber } from "@angular/common";
import { numberUtil } from 'src/app/util/number-util';
import { locale } from 'src/app/_services'

@Pipe({
  name: "spacer",
  pure: false
})
export class SpacerPipe implements PipeTransform {

  constructor() {};

  transform(value: any, placeAfter: number = 4) {
    if (value != null) {
        const re = new RegExp(`(.{${placeAfter}})`, "g");
        value = value.replace(/[^\dA-Z]/g, '').replace(re, '$1 ').trim();
      }
      return value;
  }
}
