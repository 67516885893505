import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormGroup, ControlContainer } from "@angular/forms";
import { Subscription } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { ICurrency } from "src/app/models";
import { NumericValuePipe, PriceValuePipe } from "src/app/pipes";
import { AppState } from "src/app/state/app.state";
import { numberUtil } from "src/app/util/number-util";

@Component({
  selector: "lxm-input",
  templateUrl: "./lxm-input.component.html",
  styleUrls: ["./lxm-input.scss"],
})
export class LxmInputComponent implements OnChanges {
  @HostBinding("class.disabled") @Input() public disabled = false;
  @ViewChild("input") inputRef: ElementRef;

  @Input() public formGroup: FormGroup;
  @Input() public for: string;
  @Input() public type = "text";

  @HostBinding("class.readonly")
  @Input()
  public readonly = false;
  @Input() public maxlength: string;
  @Input() public inputClass: string;
  @Input() public autocomplete: string = "off";
  @Input() public placeholder: string;
  @Input() public pattern: string;

  @Input() public inputId: string;

  @Input() public labelField: string;

  @Input() public emitValueChanges = false;

  @Input() public pricePrecision = 3;
  @Input() public digits = 2;
  @Input() public displayFormat: string | ((val) => string);
  @Input() public currency: ICurrency;
  @Input() public tabIndex: number = null;

  @Input()
  public suffix = "";

  @Output() blur: EventEmitter<any> = new EventEmitter();
  @Output() onValueChange: EventEmitter<any> = new EventEmitter();

  private _valueChangeSubscription: Subscription;

  constructor(
    @Optional() private _controlContainer: ControlContainer,
    private _priceValue: PriceValuePipe,
    private _numericValue: NumericValuePipe,
    private _appState: AppState,
  ) {}

  public get displayCurrency() {
    return this.currency || this._appState.defaultCurrency || { code: "€" };
  }

  public get id() {
    return this.inputId || this.for;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (!this.formGroup) {
      if (
        this._controlContainer &&
        this._controlContainer.control instanceof FormGroup
      ) {
        this.formGroup = <FormGroup>this._controlContainer.control;
      }
    }

    if (this.emitValueChanges) {
      if (this._valueChangeSubscription) {
        this._valueChangeSubscription.unsubscribe();
      }
      this._valueChangeSubscription = this.formGroup
        .get(this.for)
        ?.valueChanges.pipe(distinctUntilChanged())
        .subscribe((value) => {
          this.onValueChange.emit(value);
        });
    }
  }

  ngOnDestroy() {
    if (this._valueChangeSubscription) {
      this._valueChangeSubscription.unsubscribe();
    }
  }

  public get currencyPrefix() {
    return this.displayCurrency?.code;
  }

  public get inputValue() {
    let value = this.formGroup.get(this.for)?.value;

    if (this.type === "price") {
      return this._priceValue.transform(
        value,
        this.pricePrecision,
        this.displayCurrency?.code,
      );
    }

    if (this.type === "numeric") {
      return this._numericValue.transform(value, this.digits);
    }

    if (this.type === "percent") {
      if (!value && value !== 0) {
        value = 0;
      }

      const digits = `1.${this.digits}-${this.digits}`;
      return numberUtil.formatNumber(value, digits) + " %";
    }

    if (!value && value !== 0) {
      return "-";
    }

    if (this.displayFormat) {
      if (typeof this.displayFormat === "string") {
        return numberUtil.formatNumber(value, this.displayFormat);
      }
      if (typeof this.displayFormat === "function") {
        return this.displayFormat(value);
      }
    }
    return value;
  }
}
