import { Component, OnInit, OnChanges, Input, Optional } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { EditableListBase } from '../editable-list-base';

@Component({
  selector: 'music-track-list',
  templateUrl: './music-track-list.component.html',
  styleUrls: ['./music-track-list.component.scss']
})


export class MusicTrackListComponent extends EditableListBase implements OnInit, OnChanges {

  protected get _defaultItem(): any {

    const trackNoRegex : RegExp = /^[1-9]+\.[1-9]+$/;
    var nextTrack = "1.1";

    if(this._arr.length > 0)
    {
        let lastTrack = this._arr.controls[this._arr.length - 1].get('trackNo').value;
        if(trackNoRegex.test(lastTrack))
        {
           var trackArr = lastTrack.split(".");
           nextTrack = trackArr[0] + "." + (parseInt(trackArr[1]) + 1);
        }
    }

    return {
      id: null,
      trackNo: nextTrack,
      title: null,
      authors:null,
      duration:null,
      isDeleted: false,
      isNew: true
    };
  }

  public table = {
    columns: ['trackNo_column', 'title_column', 'authors_column', 'duration_column', 'remove_column']
  }

  public get columns(): string[] {
    return this.table.columns;
  }

  constructor(@Optional() controlContainer: ControlContainer) {
    super(controlContainer);
  }

  protected _getNormalizedItem(item: any) {
    return {
      id: item.id,
      trackNo: item.trackNo,
      title:item.title,
      authors:item.authors,
      duration:item.duration,
      isDeleted: item.isDeleted || false,
      isNew: item.isNew || false
    };
  }

}
