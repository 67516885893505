import { UrlSegment, UrlSegmentGroup, UrlMatchResult, Route } from '@angular/router';

export const authExcludedRoutes = ['login', 'activate-account', 'register', 'resetPassword'];

export function authRootMatcher(segments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult {

  if (route.path === undefined && !authExcludedRoutes.includes(segments[0]?.path)) {
    const jwt = localStorage.getItem('jwt');

    if (jwt) {
      return {consumed: []};
    }

    const children = route.children.map(x => x.path);
    if (children.includes(segments[0]?.path)) {
      return {consumed: []};
    }

    return null;

  } else {
    return null;
  }
}
