<lxm-dialog [dialogRef]="dialogRef" [formGroup]="form">
    <span header-title>Logo</span>
    <section body>
  
      <form-field label="cards.products.product_images.label.image_status">
        <lxm-select select [options]="statuses" for="status" [clearable]="false" value="id"></lxm-select>
        <validation-errors for="status"></validation-errors>
      </form-field>

      <form-field label="cards.products.product_images.label.master" *ngIf="imageId">
        <slide-toggle toggle for="isMaster"></slide-toggle>
        <validation-errors for="isMaster"></validation-errors>
      </form-field>

      <file-drop-box 
        (onChange)="uploadFile($event)"
        (onDelete)="removeImage($event)"
        [files]="files"
        [uploadInProgress]="uploadInProgress" 
        [uploadingFile]="uploadingFile"
        [multiple]="true"
        [showAddFileCondition]="!imageId"
        [addFileText]="'cards.products.product_image_upload.label.' + (imageId ? 'drag_image_here' : 'drag_images_here')"
        accept="image/*">
        <ng-container validation-errors>
          <validation-errors for="files"></validation-errors>
          <validation-errors for="fileId"></validation-errors>
        </ng-container>
      </file-drop-box>

    </section>
  
    <section footer>
      <lxm-button type="primary" label="cards.products.product_file_management.save_file"
                  [disabled]="uploadInProgress || uploadingFile"
                  [loading]="uploadingFile"
                  (click)="save()"
                  buttonType="submit">
      </lxm-button>
      <lxm-button type="secondary" class="ml10" label="cards.products.product_file_management.cancel_file" (click)="closeDialog()"></lxm-button>
    </section>
  
  </lxm-dialog>
  