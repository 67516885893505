import { Component, Input } from "@angular/core";
import { label, registry } from 'src/app/enum';

@Component({
  selector: "status-circle",
  templateUrl: "./status-circle.component.html",
  styleUrls: ['./status-circle.component.scss']
})
export class StatusCircleComponent {

  @Input() public status: any;
  @Input() public enum: string;

  public get statusEnum() {
    return registry[this.enum];
  }

  public get progress() {
    if (this.enum) { 
      return label(this.statusEnum, this.status);
    }
    return this.status;
  }

}

export enum StatusCircleStatus {
  Inactive = 0,
  Active = 1
}

(StatusCircleStatus as any).__typeName = 'StatusCircleStatus';