import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[blur-enter]'
})
export class BlurEnterDirective {

  @HostListener('keydown', ['$event']) onKeyDown(e) {

    if (e.keyCode === 13) {
      e.preventDefault();
      e.target.blur();
    }
  }


}
