<div class="links-container">
  <span *ngIf="headerText" class="reactive-link-header">{{headerText}}</span>
  <ul>
    <li class="reactive-link"
     *ngFor="let target of innerTargets; let i = index"
      [class.current]="activeTargetIndex === i"
      (click)="scrollToTargetByIndex(i)">
      <!-- <span class="reactive-link-icon"></span> -->
      <span class="reactive-link-name">
        <status-circle *ngIf="shouldValidate" [status]="getCardStatusValidType(target.section)" class="mr6" enum="ValidType"></status-circle>
        {{target.translateKey | translate}}
      </span>
    </li>
  </ul>
</div>
