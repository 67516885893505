import { ClvType, OfferType } from "../enum";

export const ORDERABLE_OFFER_TYPES = [OfferType.MainAssortment, OfferType.Campaign, OfferType.Logistics, OfferType.Procurement];
export const IMPORTABLE_OFFER_TYPES = [OfferType.MainAssortment];

export const CLV_TYPE_DATA = {
  [ClvType.Award]: {
    classificatorId: ClvType.Award,
    controller: "clvs",
    displayedColumns: ["code", "value", "description", "status"],
    translationPrefix: "cards.settings.common"
  },
  [ClvType.ProductBrand]: {
    classificatorId: ClvType.ProductBrand,
    controller: "productbrands",
    displayedColumns: ["code", "value", "isPrivateLabel", "status"],
    translationPrefix: "cards.settings.product_management_settings.brands"
  },
  [ClvType.ProductUnitCodeTypeFormat]: {
    classificatorId: ClvType.ProductUnitCodeTypeFormat,
    controller: "productunitcodetypeformats",
    displayedColumns: ["typeId", "value", "codeFormat", "nextNumber", "hasChecksum", "status"],
    translationPrefix: "cards.settings.product_management_settings.codes"
  },
  [ClvType.Manufacturer]: {
    classificatorId: ClvType.Manufacturer,
    controller: "manufacturers",
    displayedColumns: ["code", "value", "manufacturerAddress", "manufacturerGln", "status"],
    translationPrefix: "cards.settings.product_management_settings.manufacturers"
  },
  [ClvType.LogisticsPartner]: {
    classificatorId: ClvType.LogisticsPartner,
    controller: "logisticspartners",
    displayedColumns: ["code", "value", "logisticsPartnerAddress", "logisticsPartnerIsDefault", "status"],
    translationPrefix: "cards.settings.product_management_settings.logistics_partners"
  },
  [ClvType.WineRegionOfOrigin]: {
    classificatorId: ClvType.WineRegionOfOrigin,
    controller: "clvs",
    displayedColumns: ["code", "value", "description", "status"],
    translationPrefix: "cards.settings.product_management_settings.wine_regions"
  },
  [ClvType.Winery]: {
    classificatorId: ClvType.Winery,
    controller: "clvs",
    displayedColumns: ["code", "value", "description", "status"],
    translationPrefix: "cards.settings.product_management_settings.wineries"
  },
  [ClvType.CalendarGroup]: {
    classificatorId: ClvType.CalendarGroup,
    controller: "calendarGroups",
    displayedColumns: ["code", "value", "gs1Segments", "status"],
    translationPrefix: 'cards.settings.clv.common'
  },
  [ClvType.ProductTag]: {
    classificatorId: ClvType.ProductTag,
    controller: "productTags",
    displayedColumns: ["code", "value", "gs1Segments", "status"],
    translationPrefix: 'cards.settings.clv.common'
  },
  [ClvType.PriceGroup]: {
    classificatorId: ClvType.PriceGroup,
    controller: "priceGroups",
    displayedColumns: ["code", "value", "description", "status", "color"],
    translationPrefix: 'cards.settings.clv.common'
  },
  [ClvType.ClientTag]: {
    classificatorId: ClvType.ClientTag,
    controller: "clientTags",
    displayedColumns: ["code", "value", "description", "status", "color"],
    translationPrefix: 'cards.settings.clv.common'
  },
  [ClvType.CampaignType]: {
    classificatorId: ClvType.CampaignType,
    controller: "campaignTypes",
    displayedColumns: ["value", "valueType", "numericValue", "vatType", "displayRetailPrice", "description", "status"],
    translationPrefix: 'cards.settings.clv.common'
  },
  [ClvType.PriceList]: {
    classificatorId: ClvType.PriceList,
    controller: "clvs",
    displayedColumns: ["code", "value", "description", "status"],
    translationPrefix: "cards.settings.common"
  },
}

export const ALLERGEN = {
    FISH: [
      "ALLERGEN101",  // "Fish and Their Derivatives"
    ],
    CRUSTACEANS: [
      "ALLERGEN115",  // "Crustaceans and Their Derivatives"
    ],
    MOLLUSCS: [
      "ALLERGEN124",  // "Molluscs and Their Derivatives"
    ],
    PEANUTS: [
      "ALLERGEN126",  // "Peanuts and Their Derivatives"
    ],
    NUTS: [
      "ALLERGEN136",  // "Walnut and Walnut Products"
      "ALLERGEN122",  // "Macadamia Nut and Macadamia Nut Products"
      "ALLERGEN106",  // "Brazil Nut and Brazil Nut Products"
      "ALLERGEN128",  // "Pecan Nut and Pecan Nut"
      "ALLERGEN129",  // "Pistachio and Pistachio Products"
      "ALLERGEN103",  // "Tree Nuts and Their Derivatives"
      "ALLERGEN131",  // "Queensland Nut and Queensland Nut Products",
      "ALLERGEN104",  // "Almond and Almond Products",
      "ALLERGEN108",  // "Cashew and Cashew Products",
      "ALLERGEN118"   // "Hazelnut and Hazelnut Products"
    ],
    CELERY: [
      "ALLERGEN109",  // "Celery or Derivatives"
    ],
    MUSTARD: [
      "ALLERGEN125",  // "Mustard and Derivatives"
    ],
    EGGS: [
      "ALLERGEN116",  // "Eggs and Their Derivatives"
    ],
    SESAME: [
      "ALLERGEN133",  // "Sesame Seeds or Their Derivatives"
    ],
    SOY: [
      "ALLERGEN134",  // "Soybeans and Their Derivatives"
    ],
    GLUTEN: [
      "ALLERGEN110",  // "Cereals Containing Gluten and Their Derivatives"
      "ALLERGEN119",  // "Kamut and Kamut Products"
      "ALLERGEN117",  // "Gluten"
      "ALLERGEN137",  // "Nisu ja nisust valmistatud tooted (gluteeni sisaldavad teraviljad)
      "ALLERGEN105",  // "Barley and Barley Products"
      "ALLERGEN102",  // Oat and Oat Products
      "ALLERGEN132"   // Rye and derivatives
    ],
    LACTOSE: [
      "ALLERGEN123",  // "Milk and Derivatives"
      "ALLERGEN120",  // "Lactose"
    ],
    SULPHITES: [
      "ALLERGEN135"   // "Sulphur Dioxide and Sulphites"
    ]
  }

  export const FOOD_SUGGESTION = {
    '6fd4a027-1e8d-4eb5-bfa5-d51e82d67cdc': { pictogram: 'suggestionAperitif', description: 'global.pictograms.food_suggestions.aperitif' },
    '913291af-2982-42fd-adff-997f9948c25d': { pictogram: 'suggestionAsianFood', description: 'global.pictograms.food_suggestions.asian_food' },
    '0fb745ce-dc5e-40c8-9eec-6473cd04645d': { pictogram: 'suggestionCompany', description: 'global.pictograms.food_suggestions.company' },
    '2e147ade-8941-44c6-acba-1a6d9e1e0149': { pictogram: 'suggestionCheese', description: 'global.pictograms.food_suggestions.cheese' },
    '64e0b24c-b1fc-4def-8b61-3a31e9b1b130': { pictogram: 'suggestionDessert', description: 'global.pictograms.food_suggestions.dessert' },
    'f860a636-b3be-491e-a712-fb4ccfdc41db': { pictogram: 'suggestionFruits', description: 'global.pictograms.food_suggestions.fruits' },
    '3868c59c-9944-477a-9a43-3d50545ee138': { pictogram: 'suggestionLamb', description: 'global.pictograms.food_suggestions.lamb' },
    '36ed7feb-ed84-4c6d-9b59-882826522065': { pictogram: 'suggestionPasta', description: 'global.pictograms.food_suggestions.pasta' },
    'c9b8d0b1-393c-4dac-a039-761bfed3c523': { pictogram: 'suggestionPizza', description: 'global.pictograms.food_suggestions.pizza' },
    '5cee5115-8016-4701-b45c-8020dc249e0f': { pictogram: 'suggestionPork', description: 'global.pictograms.food_suggestions.pork' },
    '669e80b4-6f95-4e2f-b63b-4cb8b6478a5c': { pictogram: 'suggestionPoultry', description: 'global.pictograms.food_suggestions.poultry' },
    '77341df9-94e0-4e88-b34b-596c8c96246e': { pictogram: 'suggestionRedFish', description: 'global.pictograms.food_suggestions.red_fish' },
    'f32c05f7-7079-4fe1-b836-b04aad242642': { pictogram: 'suggestionSeafood', description: 'global.pictograms.food_suggestions.seafood' },
    '74803dbf-e5a4-4a76-96e2-c6bd0633e660': { pictogram: 'suggestionSnacks', description: 'global.pictograms.food_suggestions.snacks' },
    '9ca6815d-8cd6-4440-a169-12580b9c895b': { pictogram: 'suggestionSpicy', description: 'global.pictograms.food_suggestions.spicy' },
    '5e7c53fd-a297-4fc4-a49c-01889dbcab67': { pictogram: 'suggestionBeef', description: 'global.pictograms.food_suggestions.beef' },
    '96ec8078-0a9f-4504-941f-ec8811bcb9cd': { pictogram: 'suggestionVegetables', description: 'global.pictograms.food_suggestions.vegetables' },
    '36efbef6-cebe-4371-9fc5-f81f5352b9bf': { pictogram: 'suggestionVenison', description: 'global.pictograms.food_suggestions.venison' },
    '3099f9ca-f7bc-438f-8c5a-7131455e527f': { pictogram: 'suggestionWhiteFish', description: 'global.pictograms.food_suggestions.white_fish' }
  }