<lxm-dialog [dialogRef]="dialogRef" [formGroup]="form">
  <span header-title>{{'cards.products.product_additional_info_and_instructions.links.dialog_title_new' | translate}}</span>

  <div body style="padding-bottom: 40px;">

    <form-field label="cards.products.product_additional_info_and_instructions.links.type" [required]="true">
      <lxm-select select
        for="typeId"
        value="id"
        labelField="translatedValue"
        [clearable]="false"
        [isTranslated]="true"
        [searchable]="true"
        [options]="linkTypeOptions"
        [sortByLabel]="true">
      </lxm-select>
    </form-field>

    <form-field for="link" label="cards.products.product_additional_info_and_instructions.links.link" type="textarea">
    </form-field>

    <form-field for="comment" label="cards.products.product_additional_info_and_instructions.links.comment" type="textarea">
    </form-field>
  </div>

  <div footer>
    <lxm-button type="primary" label="cards.products.product_additional_info_and_instructions.links.dialog_save" (click)="save()" [loading]="saveInProgress" buttonType="submit"></lxm-button>
    <lxm-button type="secondary" class="ml15" label="cards.products.product_additional_info_and_instructions.links.dialog_cancel" (click)="closeDialog()"></lxm-button>
  </div>
</lxm-dialog>
