import { Component, HostBinding, Input, OnInit } from "@angular/core";

@Component({
  selector: "card-message",
  templateUrl: "./card-message.component.html",
  styleUrls: ["./card-message.scss"]
})
export class CardMessageComponent implements OnInit {
  @Input() public messages: string[] = [];
  @Input() public message: string;
  @Input() public html: string;

  @HostBinding("class.absolute")
  @Input()
  public absolute = false;

  @HostBinding("class")
  @Input()
  public type: CardMessageType = CardMessageType.Message;

  public get hasMessages() {
    return this.messages?.length > 0;
  }

  ngOnInit() {
    if (!this.hasMessages && this.message) {
      this.messages.push(this.message);
    }
  }
}

export enum CardMessageType {
  Warning = 'warning',
  Message = 'message',
  Error = 'error',
  Success = 'success'
}