import { Component, OnInit, Input, Optional, Host } from '@angular/core';
import { PackageMaterialControlBuilder } from 'src/app/_helpers/control.builder';
import { FormGroup, ControlContainer, FormArray } from '@angular/forms';
import { ReadonlyDirective } from 'src/app/directives';

@Component({
  selector: "package-material-manager",
  templateUrl: './package-material.component.html',
  styleUrls: ['./package-material.scss']
})
export class PackageMaterialManagerComponent implements OnInit {

  @Input() public formGroup: FormGroup;
  @Input() public for: string;
  @Input() public readonly = false;
  @Input() public materialOptions: any[];

  private _emptyMaterialModel = {
    material: null,
    amoung_g: null,
    isReusable: false
  };

  constructor(
    @Optional() @Host() private _readonlyQuery: ReadonlyDirective,
    @Optional() private _controlContainer: ControlContainer,
    private _: PackageMaterialControlBuilder) {
  }

  public ngOnInit(): void {

    if (this._readonlyQuery && this._readonlyQuery.readonly) {
      this.readonly = this._readonlyQuery.readonly;
    }

    if (this._controlContainer && this._controlContainer.control instanceof FormGroup) {
      this.formGroup = <FormGroup>this._controlContainer.control;
    }
  }

  get formArray() {
    return this.formGroup.get(this.for) as FormArray;
  }

  public addItem(model = this._emptyMaterialModel) {
    this.formArray.push(this._.createPackageMaterialControl(model));
  }

  public removeItem(index: number) {
    this.formArray.removeAt(index);
  }
}

