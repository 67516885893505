import { Component, HostBinding, HostListener, Input, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "lxm-overlay",
  styleUrls: ['./overlay.component.scss'],
  template: ``
})
export class OverlayComponent {

  @HostBinding('class') @Input() public name;
  @HostBinding('style.z-index') @Input() public zIndex = 100;

}
