import { Component, OnInit, OnChanges, SimpleChanges, Input, Optional, Host } from '@angular/core';
import { CodeControlBuilder } from 'src/app/_helpers/control.builder';
import { FormGroup, ControlContainer, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { IProductUnitCodeType, IProductCode } from 'src/app/cards/product/product-info/product-info.model';
import { ProductUnitService } from 'src/app/_services';
import { Gs1CodeCheckResponseType } from 'src/app/enum';
import { UniqueCodeTypeOption } from 'src/app/enum';
import { LxmMessage } from 'src/app/_helpers';

@Component({
  selector: 'product-code-manager',
  templateUrl: './product-code-manager.component.html',
  styleUrls: ['./product-code-manager.scss']
})
export class ProductCodeManagerComponent implements OnInit, OnChanges {

  private _arr: FormArray;
  private _init = false;

  private _emptyCodeModel = {
    isSystemCode: false,
    isPrimary: false,
    formatId: null,
    typeId: null,
    gs1CheckStatus: null,
    group: 0,
    prevGroup: 0,
    value: null,
    isDeleted: false,
    isNew: true
  };

  @Input() public formGroup: FormGroup;
  @Input() public for: string;
  @Input() public readonly = false;
  @Input() public small = false;
  @Input() public codeTypes: IProductUnitCodeType[];
  @Input() public startIndex = 0;

  public fgInternal = new FormGroup({});

  public getFormats(typeId: string) {
    if (!typeId) {
      return [];
    }
    return this.codeTypes?.find(x => x.id === typeId).formats || [];
  }

  public getGroup(typeId: string) {
    if (!typeId) {
      return 0;
    }
    return this.codeTypes.find(x => x.id === typeId).group;
  }

  public get formArray() {
    return this._arr;
  }

  public get internalArray() {
    return this.fgInternal.get('codes');
  }

  constructor(
    @Optional() private _controlContainer: ControlContainer,
    private _productUnitService: ProductUnitService,
    private _: CodeControlBuilder,
    private _message: LxmMessage
  ) { }

  public ngOnInit(): void {
    if (this._controlContainer && this._controlContainer.control instanceof FormGroup) {
      this.formGroup = <FormGroup>this._controlContainer.control;
    }

    const ref = this.formGroup.get(this.for);
    this._arr = this._.createCodeControls(ref.value);
    this.fgInternal.addControl(this.for, this._arr);

    this._bindControls(ref, this._arr);
  }

  public canValidate(field: FormControl) {
    return field.value.group === UniqueCodeTypeOption.EAN;
  }

  private _bindControls(ref: AbstractControl, arr: FormArray) {

    let innerChange = false;

    ref.valueChanges.subscribe((x: IProductCode[]) => {
      if (innerChange) {
        innerChange = false;
        return;
      }

      const normalizedArray = this._.getNormalizedItems(x);
      while (arr.controls.length < normalizedArray.length) {
        this.addItem();
      }
      while (arr.controls.length > normalizedArray.length) {
        arr.removeAt(normalizedArray.length);
      }

      arr.setValue(normalizedArray, { emitEvent: false });
    });

    arr.valueChanges.subscribe(x => {
      innerChange = true;
      ref.setValue(x);
    });

    // set the initial value to normalized array
    // prevents FormGroup returning non-normalized value if nothing was changed
    ref.setValue(arr.value, { emitEvent: false });
  }

  public ngOnChanges(changes: SimpleChanges) {

    if (!this._init) {
      this._init = true;
      return;
    }

    if (changes?.readonly) {
      this.readonlyChange();
    }

  }

  public readonlyChange() {
    this.removeDeleted();
    this.undoNew();
  }

  public undoNew() {
    const map = this.formArray.value;
    for (let i = map.length; i-- > 0;) {
      this.formArray.controls[i].get('isNew').setValue(false);
    }
  }

  public removeDeleted(): void {
    const map = this.formArray.value;
    for (let i = map.length; i-- > 0;) {
      if (map[i].isDeleted) {
        this.formArray.removeAt(i);
      }
    }
  }

  public generateCode(field: FormControl, index: number): void {
    const formatId = field.get('formatId').value;

    if (!formatId) {
      return;
    }

    this._productUnitService
      .nextCode(formatId)
      .subscribe(code => {
        field.get('value').setValue(code);
        this.validateCode(field, index);
      });
  }

  public isValidResponseType(responseType: number) {
    return Gs1CodeCheckResponseType[responseType] ? true : false;
  }

  public getGs1CheckTranslationKey(responseType: Gs1CodeCheckResponseType) {
    if (this.isValidResponseType(responseType)) {
      return `cards.products.product_base_info.gs1_check_status.${responseType}`;
    }
    return null;
  }

  public isCodeValidationLoading = false;
  public codeRowsValidating = new Set();
  public validateCode(field: FormControl, index: number): void {
    const value = field.value.value;

    if (!this.canValidate(field) || !value) {
      return;
    }

    this.isCodeValidationLoading = true;
    this.codeRowsValidating.add(index);
    this._productUnitService.validateCode(value)
      .result(null, res => {
        if (res?.hasOwnProperty('responseType')) {
          field.get('gs1CheckStatus').setValue(res.responseType);
        }
        this.isCodeValidationLoading = false;
        this.codeRowsValidating.delete(index);
      }, err => {

        this._message.error({
          message: err.validationSummary
        });

        this.isCodeValidationLoading = false;
        this.codeRowsValidating.delete(index);
      })
  }

  public isCodeRowValidationLoading(index: number) {
    return this.codeRowsValidating.has(index);
  }

  public clearRowErrors(item: FormGroup): void {
    Object.keys(item.controls).forEach(key => {
      item.controls[key].setErrors(null)
    });
  }

  public typeChangeHandler(index: number) {
    const item = this.formArray.controls[index] as FormGroup;
    const isPrimary = item.get('isPrimary').value;
    const currentTypeId = item.get('typeId').value;
    const currentGroup = this.getGroup(currentTypeId);
    const prevGroup = item.get('prevGroup').value;

    this.clearRowErrors(item);
    item.get('group').setValue(currentGroup);

    if (isPrimary) {

      const otherWithOldType = this.formArray.controls
        .find((x, i) => i !== index
          && x.get('group').value === prevGroup
          && !x.get('isDeleted').value
        );

      if (otherWithOldType) {
        otherWithOldType.get('isPrimary').setValue(true);
      }

      const otherWithNewType = this.formArray.controls
        .find((x, i) => i !== index
          && x.get('group').value === currentGroup
          && x.get('isPrimary').value
          && !x.get('isDeleted').value
        );

      if (otherWithNewType) {
        item.get('isPrimary').setValue(false);
      }
    } else {

      const otherWithNewType = this.formArray.controls
        .find((x, i) => i !== index
          && x.get('group').value === currentGroup
          && !x.get('isDeleted').value
        );

      if (!otherWithNewType) {
        item.get('isPrimary').setValue(true);
      }
    }

    item.get('prevGroup').setValue(currentGroup);
    item.get('formatId').setValue(null);
  }

  public togglePrimary(index: number) {

    const currentGroup = this.formArray.controls[index].get('group').value;

    const itemsWithSameType = this.formArray.controls
      .filter(x => x.get('group').value === currentGroup);


    for (let j = 0; j < itemsWithSameType.length; j++) {
      const item = itemsWithSameType[j];
      if (!item.get('isSystemCode').value) {
        item.get('isPrimary').setValue(false);
      }
    }
  }

  public addItem(model = this._emptyCodeModel) {
    this.formArray.push(this._.createCodeControl(model));
  }

  public removeItem(index: number) {

    const item = this.formArray.controls[index];
    const isNew = item.get('isNew').value;
    const isDeleted = item.get('isDeleted').value;

    if (item.get('isPrimary').value) {
      const other = this.formArray.controls
        .find((x, i) => i !== index
          && x.get('group').value === item.get('group').value
          && !x.get('isDeleted').value
        );
      if (other) {
        other.get('isPrimary').setValue(true);
      }
    }

    if (isNew) {
      this.formArray.removeAt(index);
    } else {
      this.formArray.controls[index].get('isDeleted').patchValue(!isDeleted);
    }

  }
}
