import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { LOCAL_STORAGE_KEY } from '../config';
import { AppState } from '../state/app.state';
import { NavigatorState } from '../state/navigator.state';
import { LocalStorage } from '../_helpers';

@Directive({
  selector: '[fullScreen]'
})
export class FullscreenDirective {

  @HostBinding('class.full-screen')
  private _isFullscreen = false;

  @HostBinding('class.nav-collapsed') public get isNavCollapsed() {
    return this._navigatorState.collapsed;
  }

  constructor(private _navigatorState: NavigatorState) {
    this._isFullscreen = false;
  }

  @Input() public handleNavigator = true;

  @Input() public set fullScreen(value: boolean) {
    this._navigatorState.isAnyItemFullscreen = value;
    this._isFullscreen = value;

    if (this.handleNavigator) {
      this._handleNavigatorBehavior(value);
    }
  }


  private _handleNavigatorBehavior(fullScreen: boolean) {
    if (fullScreen) {
      const isNavigatorCollapsed = this._navigatorState.collapsed;
      if (!isNavigatorCollapsed) {
        this._navigatorState.collapsed = true;
      }
    } else {
      this._setPreviousValues();
    }
  }

  private _setPreviousValues() {
    this._navigatorState.isAnyItemFullscreen = false;
    if (this.handleNavigator) {
      this._navigatorState.collapsed = LocalStorage.getValue(LOCAL_STORAGE_KEY.NAVIGATOR_OPEN) || false;
    }
  }

  ngOnDestroy() {
    this._setPreviousValues();
  }

}
