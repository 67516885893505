<div class="diff-val" *ngFor="let x of changes" [ngClass]="{'diff-vals-one-liner': oneLiner, 'deleted': deleted }">
  <ng-container *ngIf="showPropertyName">
    <span [innerHTML]="x.field | translateEnum : (x.fieldType || fieldType)">
    </span>
    <span *ngIf="x.valueFormatterType == 8">
      ({{ x.language }})
    </span>
    :&nbsp;
  </ng-container>

  <span
    *ngIf="x.valueFormatterType != DiffValueFormatterType.Complex && x.valueFormatterType != DiffValueFormatterType.Collection && x.valueFormatterType != DiffValueFormatterType.ComplexField">
    <ng-container
      *ngTemplateOutlet="token; context: { x: x, valueTemplate: resolveValueTemplate(x), showOldValue: showOldValue, showNewValue: showNewValue }">
    </ng-container>
  </span>

  <span *ngIf="x.valueFormatterType == DiffValueFormatterType.ComplexField">
    <!-- complex field -->

    <ng-container
      *ngTemplateOutlet="token; context: { x: x.fieldValue, valueTemplate: resolveValueTemplate(x.fieldValue), showOldValue: false, showNewValue: showNewValue }">
    </ng-container>

    <div style="padding-left: 20px; position: relative;">
      <diff [changes]="x.changes"></diff>
    </div>
  </span>

  <span *ngIf="x.valueFormatterType == DiffValueFormatterType.Complex">
    <!-- complex -->
    <div style="padding-left: 20px; position: relative;">
      <div *ngIf="x.newItems.length">
        <ng-container *ngFor="let rowChanges of x.newItems">
          <span style="position: absolute; left: 10px;">+</span>
          <diff [changes]="rowChanges" [oneLiner]="true" [isNew]="true" [showOldValue]="false"
            [fieldType]="x.fieldType"></diff>
        </ng-container>
      </div>
      <div *ngIf="x.updates.length">
        <ng-container *ngFor="let rowChanges of x.updates">
          <diff [changes]="rowChanges" [oneLiner]="true" [fieldType]="x.fieldType"></diff>
        </ng-container>
      </div>
      <div *ngIf="x.removedItems.length">
        <ng-container *ngFor="let rowChanges of x.removedItems">
          <diff [changes]="rowChanges" [oneLiner]="true" [deleted]="true" [showNewValue]="false"
            [fieldType]="x.fieldType"></diff>
        </ng-container>
      </div>
    </div>
  </span>

  <span *ngIf="x.valueFormatterType == DiffValueFormatterType.Collection">
    <!-- collection -->
    <div style="padding-left: 20px; position: relative;">
      <div *ngIf="x.addedValues.length">
        <span style="position: absolute; left: 10px;">+</span>
        <diff [changes]="x.addedValues" [oneLiner]="true" [isNew]="true" [showOldValue]="false"
          [showPropertyName]="false"></diff>
      </div>
      <div style="display: flex;" *ngIf="x.removedValues.length">
        <diff [changes]="x.removedValues" [oneLiner]="true" [deleted]="true" [showNewValue]="false"
          [showPropertyName]="false"></diff>
      </div>
    </div>
  </span>
</div>


<ng-template #token let-showOldValue="showOldValue" let-showNewValue="showNewValue" let-x="x"
  let-valueTemplate="valueTemplate">

  <ng-container *ngIf="!x.changed">
    <ng-container *ngTemplateOutlet="valueTemplate; context: { x: x, value: 'oldValue' }"></ng-container>
  </ng-container>

  <ng-container *ngIf="x.changed">
    <ng-container *ngIf="showOldValue">
      <ng-container *ngTemplateOutlet="valueTemplate; context: { x: x, value: 'oldValue' }"></ng-container>
    </ng-container>
    <ng-container *ngIf="showOldValue && showNewValue">
      >
    </ng-container>
    <ng-container *ngIf="showNewValue">
      <ng-container *ngTemplateOutlet="valueTemplate; context: { x: x, value: 'newValue' }"></ng-container>
    </ng-container>
  </ng-container>

</ng-template>

<ng-template #stringTokenValue let-x="x" let-value="value">
  "{{ x[value] }}"
</ng-template>

<ng-template #systemClvTokenValue let-x="x" let-value="value">
  "{{ x[value] | systemClvName }}"
</ng-template>

<ng-template #clvTokenValue let-x="x" let-value="value">
  "{{ x[value]?.value }}"
</ng-template>

<ng-template #boolTokenValue let-x="x" let-value="value">
  "{{ x[value] | translateEnum : 'Bool' }}"
</ng-template>

<ng-template #enumTokenValue let-x="x" let-value="value">
  <ng-container *ngIf="x[value]">
    "{{ x[value] | translateEnum : x.enumType }}"
  </ng-container>
  <ng-container *ngIf="!x[value]">
    {{ 'global.unset' | translate }}
  </ng-container>
</ng-template>

<ng-template #countryTokenValue let-x="x" let-value="value">
  "{{ x[value]?.name }}"
</ng-template>

<ng-template #userTokenValue let-x="x" let-value="value">
  "{{ x[value]?.name }}"
</ng-template>

<ng-template #gs1SegmentTokenValue let-x="x" let-value="value">
  "{{ x[value]?.title }}"
</ng-template>

<ng-template #localSegmentTokenValue let-x="x" let-value="value">
  "{{ x[value]?.title }}"
</ng-template>

<ng-template #atcSegmentTokenValue let-x="x" let-value="value">
  "{{ x[value]?.title }}"
</ng-template>

<ng-template #retailerTokenValue let-x="x" let-value="value">
  "{{ x[value]?.name }}"
</ng-template>

<ng-template #productUnitTokenValue let-x="x" let-value="value">
  "{{ x[value]?.typeId | translateEnum : 'ProductUnitType' }}<ng-container *ngIf="x[value]?.defaultEan"> {{
    x[value]?.defaultEan }}</ng-container>"
  <!-- <ng-container *ngIf="x[value]?.retailer"> {{ x[value]?.retailer }}</ng-container> -->
</ng-template>

<ng-template #productTokenValue let-x="x" let-value="value">
  "{{ x[value]?.defaultEan || (x[value]?.fullName | translatedValue:true) }}"
</ng-template>

<ng-template #procurementTokenValue let-x="x" let-value="value">
  "{{ x[value]?.name | translatedValue:true }}"
</ng-template>

<ng-template #numericTokenValue let-x="x" let-value="value">
  {{ x[value] === null || x[value] === undefined ? ('global.unset' | translate) : x[value] }}
</ng-template>

<ng-template #dateTokenValue let-x="x" let-value="value">
  {{ x[value] === null || x[value] === undefined ? ('global.unset' | translate) : x[value] | formDate }}
</ng-template>