<lxm-dialog [dialogRef]="dialogRef">

  <span header-title>
    {{data.title | translate}}
  </span>

  <div *ngIf="data.image" header-image class="{{data.image}}"></div>

  <div body>
    <div>{{data.message | translate}}</div>
    <div *ngIf="data.template"
          [innerHTML]="data.template">
    </div>
    <ng-container *ngTemplateOutlet="templateRef">
    </ng-container>
  </div>

  <div footer [ngSwitch]="swapButtons" cdkFocusRegionstart>
    <ng-container *ngSwitchCase="true">
      <lxm-button type="primary" buttonType="button" class="mr10" [label]="data.no" (click)="onDecline()" *ngIf="data.showNo"></lxm-button>
      <lxm-button type="secondary" buttonType="submit" [label]="data.yes" [mat-dialog-close]="true" cdkFocusInitial tabIndex="1" *ngIf="data.showYes"></lxm-button>
    </ng-container>
    <ng-container *ngSwitchCase="false">
      <lxm-button type="primary" buttonType="submit" class="mr10" [label]="data.yes" [mat-dialog-close]="true" cdkFocusInitial tabIndex="1" *ngIf="data.showYes"></lxm-button>
      <lxm-button type="secondary" buttonType="button" [label]="data.no" (click)="onDecline()" *ngIf="data.showNo"></lxm-button>
    </ng-container>
  </div>

</lxm-dialog>
