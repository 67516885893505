export enum UserStatus {

    Deleted = -1,
    Disabled = 0,
    Active = 1,

    // i.e visitor
    Guest = 2,

    // invitation has been sent
    Pending = 3

}
  
(UserStatus as any).__typeName = 'UserStatus';
  