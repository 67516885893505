<!-- FILTER ICON -->
<div
  class="flex size-15x items-center justify-center"
  [ngSwitch]="OVService.overlayCount > 1"
>
  <svg-icon
    *ngSwitchDefault
    [key]="icon"
    class="flex size-15x items-center justify-center"
  ></svg-icon>

  <svg-icon
    *ngSwitchCase="true"
    key="monochromeArrowLeft"
    class="flex size-15x cursor-pointer items-center justify-center text-grey-100"
    (click)="close()"
  ></svg-icon>
</div>
<!-- TEXT -->
<span class="m-auto font-display text-lg font-bold text-grey-100">
  {{ label }}
</span>
