<div class="language-text-wrapper " [formGroup]="fgInternal">
  <div class="language-text-container" [formArrayName]="for">
    <div class="language-text" *ngFor="let field of formArray.controls; let i = index" [formGroupName]="i">
      <span class="language-prefix required-{{getRequiredType(field.get('language')?.value, field.get('value')?.value)}}">
        {{field.get('language')?.value}}
      </span>

      <div *ngIf="!newline" class="language-suffix">
        <lxm-input 
          [readonly]="readonly" 
          [inputClass]="inputClass" 
          [inputWidth]="inputWidth"
          [inputId]="getInputId(i)"
          [maxlength]="maxlength" for="value">
        </lxm-input>
        <validation-errors [for]="for + '.' + field.get('language')?.value" [errorsFormGroup]="formGroup" aliasTo="value"></validation-errors>
      </div>

      <div *ngIf="newline" class="language-suffix">
        <lxm-textarea for="value"
                      [readonly]="readonly"
                      [maxlength]="maxlength"
                      [quill]="quill"
                      [inputId]="getInputId(i)"
                      [style.width]="inputWidth">
        </lxm-textarea>
        <validation-errors [for]="for + '.' + field.get('language')?.value" [errorsFormGroup]="formGroup" aliasTo="value"></validation-errors>
      </div>

    </div>
  </div>
</div>

