import { Directive, TemplateRef, ViewContainerRef, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';

@Directive({
  selector: '[apiPostLink]'
})
export class ApiPostLinkDirective {

  constructor(
    private _viewContainer: ViewContainerRef) {
  }

  @HostListener('click', ['$event']) public onClick(e: MouseEvent) {
    e.preventDefault();

    const a = this._viewContainer.element.nativeElement;
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = environment.apiUrl + a.attributes['href'].value;
    form.target = a.target;
    document.body.append(form);
    form.submit();
    form.remove();

    return false;
  }
}
