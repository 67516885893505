<section class="section-header">
  <div class="flex">
    <ng-content select="[header-title]"></ng-content>
    <div class="ml15">
      <ng-content select="[header-title-after]"></ng-content>
    </div>
  </div>
  <ng-content select="[header-actions]"></ng-content>
</section>

<section class="section-content">
  <ng-content></ng-content>
  <ng-content select="[body]"></ng-content>
</section>

<section class="section-footer">
  <ng-content select="[footer]"></ng-content>
</section>