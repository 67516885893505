import { Injectable } from "@angular/core";
import { FormControl } from "@angular/forms";
import moment from "moment";
import { Subject, Observable, BehaviorSubject } from "rxjs";
import { LOCAL_STORAGE_KEY } from "src/app/config";
import { ClientStatus } from "src/app/enum";
import { DateHandler, LocalStorage } from "src/app/_helpers";
import { ClientsService, IClientCard, IClientFormData, IClientInfo } from "src/app/_services/clients.service";
import { ClientsListViewType } from "../enum/ClientsListViewType";

@Injectable({ providedIn: "root" })
export class ClientsListState {

  public isFullScreen = false;
  public zoom = 1;

  public listViewType: ClientsListViewType = LocalStorage.getValue(LOCAL_STORAGE_KEY.CLIENTS_LIST_VIEW_TYPE) || ClientsListViewType.All;

  public FILTER_ALL = `status eq '${ClientStatus.Active}' or status eq '${ClientStatus.Disabled}'`;
  public FILTER_ACTIVE = `status eq '${ClientStatus.Active}'`;
  public FILTER_RETAILERS = `clientType has 'retailer' and not clientType has 'client'`;
  public FILTER_CLIENTS = `clientType has 'client'`;
  public FILTER_OWNED = `isMyClient eq true`;
  public FILTER_PARTNERS = `clientType has 'partner'`;

  public listFilter = this.getListFilter(this.listViewType);

  public getListFilter(viewType: ClientsListViewType) {
    switch (viewType) {
        case ClientsListViewType.All:
            return this.FILTER_ALL;
        case ClientsListViewType.Active:
            return this.FILTER_ACTIVE;
        case ClientsListViewType.Retailers:
            return this.FILTER_RETAILERS;
        case ClientsListViewType.Owned:
            return this.FILTER_OWNED;
        case ClientsListViewType.Clients:
          return this.FILTER_CLIENTS;
        case ClientsListViewType.Partners:
            return this.FILTER_PARTNERS;
        default: 
        return this.FILTER_ALL;
    }
  }

  public setListFilter(viewType: ClientsListViewType) {
    this.listFilter = this.getListFilter(viewType);
  }

  public setListView(toSet: ClientsListViewType) {
    LocalStorage.set(LOCAL_STORAGE_KEY.CLIENTS_LIST_VIEW_TYPE, toSet);
    this.listViewType = toSet;
  }

}
