import { Component, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { AssortmentGroupDialogComponent } from './dialog/assortment-group.dialog';
import { Status, asArray, UserAction, HttpMethod, TextFilterValueType } from 'src/app/enum';
import { AssortmentGroupsService, DataTableService } from 'src/app/_services';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { appSettings } from 'src/app/app.settings';
import { LocalStorage, LxmDialog } from 'src/app/_helpers';
import { forkJoin } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { LOCAL_STORAGE_KEY, PAGE_SIZE, PAGE_SIZE_OPTIONS } from 'src/app/config';

@Component({
    selector: 'assortment-groups',
    templateUrl: './assortment-groups.component.html',
    styleUrls: [ './assortment-groups.scss' ]
})
export class AssortmentGroupsTableComponent extends DataTableComponent<any, any> {


    public LOCAL_STORAGE_KEY = LOCAL_STORAGE_KEY.PAGE_SIZE_ASSORTMENT_GROUPS_LIST;

    public TextFilterValueType = TextFilterValueType;

    public AssortmentGroupLinkType = AssortmentGroupLinkType;

    @Input() public canEdit: boolean;

    @Output()
    public loaded: EventEmitter<boolean> = new EventEmitter();
    
    public dataUrl = 'assortmentGroups';
    public method = HttpMethod.Get;
    public initialData;

    public UserAction = UserAction;
    public statuses = asArray(Status);

    public displayedColumns: string[] = ['sortOrder', 'code', 'name', 'description', 'children', 'suggested_children','status'];

    constructor(
      private _dialog: LxmDialog,
      private _assortmentGroupsService: AssortmentGroupsService,
      route: ActivatedRoute,
      dataService: DataTableService<any, any>,
      cd: ChangeDetectorRef,
    ) {
      super(dataService, cd);
      this.initialData = route.snapshot.data.assortmentManagementData;
    }

    public isSearchLoading = false;

    public ngOnInit() {
      super.ngOnInit();
  
      this.dataSource.loading$.subscribe((val: boolean) => {
        this.isSearchLoading = val;
      })
  
    }

    public getChildren(row, type) {
      return row.children?.filter(x => x.type == type);
    }

    public ngAfterViewInit() {
      super.ngAfterViewInit();

      this.load();
      this.loaded.emit(true);
    }

    public openDialog(item?: any) {
      if (!this.canEdit) return;

      this._dialog.open(AssortmentGroupDialogComponent, {
          ...appSettings.DIALOG_SIZES.M,
          restoreFocus: false,
          data: {
            item: item
          },
          resolve: () => {
            const resolvers: any = {
              formData: this._assortmentGroupsService.getAssortmentGroupFormData(item?.id)
            };
    
            return forkJoin(resolvers);
          }
        }, res => {
          if (!res) {
            return;
          }
          this.load();
        });
    }
}

enum AssortmentGroupLinkType {
  Includes = 1,
  Suggestion = 2
}