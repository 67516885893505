<table class="lxm-tree">
    <thead class="lxm-tree-headers">
        <th *ngFor="let colName of displayColumns">
            <lxm-tree-header 
            [class]="'lxm-tree-header-' + colName"
            [colName]="colName" 
            [templateRef]="headerRefs[colName]">
            </lxm-tree-header>
        </th>
    </thead>

    <tbody class="lxm-tree-nodes">
        <ng-container *ngFor="let child of children$ | async">
            <tr lxm-tree-node 
                [class.hidden]="!isVisible(child)"
                [node]="child" 
                [displayColumns]="displayColumns"
                [cellRefs]="cellRefs">
            </tr>
        </ng-container>
    </tbody>
</table>