<form class="dialog-container">
  <lxm-button
    class="absolute right-2 top-2"
    type="transparent"
    icon="close"
    (click)="closeDialog()"
  ></lxm-button>

  <section class="flex flex-col items-center" style="padding: 1.875rem">



    <div class="modal-image-container">

    </div>

    <p class="d-title mb-6">
      {{'Paketi muutmise soov on edastatud!' | translate}}
    </p>

    <p class="d-text">
      {{'Oleme teie soovi kätte saanud.' | translate}}
    </p>

     <p class="d-text mb-6">
      {{'Võtame teiega esimesel võimalusel ühendust.' | translate}}
    </p>

    <div class="flex">
      <lxm-button
        type="primary"
        (click)="closeDialog()"
        >{{ 'Selge' | translate }}</lxm-button
      >
    </div>

  </section>
</form>
