<ng-container [formGroup]="formGroup">

  <div class="separator"></div>

  <form-field dialog label="cards.settings.product_management_settings.calendar_group_edit.label.is_for_campaign">
    <div any class="flex f-1 flex-row align-center">
      <lxm-checkbox class="mr15" for="isForCampaign"></lxm-checkbox>
      <lxm-select
        select
        *ngIf="formGroup.value.isForCampaign"
        for="campaignType"
        value="id"
        [options]="calendarGroupCampaignTypesOptions"
      ></lxm-select>
    </div>
  </form-field>

  <ng-container *ngIf="isCampaignTypesSelectVisible" [ngSwitch]="isCampaignTypeAdded">
    <lxm-button
      *ngSwitchCase="false"
      label="cards.settings.product_management_settings.calendar_group_edit.label.add_campaign_type"
      type="transparent"
      padding="8px 0"
      icon="plus-dark"
      (click)="addCampaignType()"
    ></lxm-button>

    <form-field dialog *ngSwitchCase="true">
      <div any class="flex f-1 flex-row align-center">
        <lxm-select
          select
          for="campaignTypes"
          labelField="value"
          [options]="campaignTypes"
          [multiple]="true"
        ></lxm-select>
      </div>
    </form-field>
  </ng-container>

  <div class="separator"></div>

  <form-field dialog label="cards.settings.product_management_settings.calendar_group_edit.label.is_for_procurement">
    <div any class="flex f-1 flex-row align-center">
      <lxm-checkbox class="mr15" for="isForProcurement"></lxm-checkbox>
      <lxm-select
        select
        *ngIf="formGroup.value.isForProcurement"
        for="procurementCalendarCategoryRules"
        value="id"
        [options]="procurementCalendarCategoryRulesOptions"
        [multiple]="true"
      ></lxm-select>
    </div>
  </form-field>

  <ng-container *ngIf="formGroup.value.isForProcurement">
    <form-field dialog label="cards.settings.product_management_settings.calendar_group_edit.label.procurement_items">
      <product-procurement-list any for="procurementItems"></product-procurement-list>
    </form-field>
  </ng-container>

  <div class="separator"></div>

  <form-field dialog label="cards.settings.product_management_settings.calendar_group_edit.label.gs1_segments"
    [readonly]="!canEdit">
    <lxm-select
      select
      for="gs1Segments"
      [searchable]="true"
      labelField="title"
      compareField="id"
      [multiple]="true"
      url="api/clvac/product-level-gs1-segments/"
    >
      <validation-errors for="gs1Segments"></validation-errors>
      <validation-errors for="gs1SegmentIds" aliasTo="gs1Segments"></validation-errors>
    </lxm-select>
  </form-field>

</ng-container>