import { Injectable } from '@angular/core';

@Injectable()
export class PurchaseOrderDataService {
  public items: { productId: string, priceSchemeId: string }[] = [];
  public offerId: string;
  public supplierId: string;
}

@Injectable()
export class SalesOrderDataService {
  public productIds: string[] = [];
}
