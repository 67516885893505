import { MatTableDataSource } from '@angular/material/table';
import { OnInit, Input, OnChanges, SimpleChanges, Directive } from '@angular/core';

@Directive()
export abstract class ListBase implements OnInit, OnChanges {

  private _init = false;

  public dataSource = new MatTableDataSource<any>();

  public abstract get columns(): string[];

  @Input() public data: any[];

  protected constructor() {
  }

  public ngOnInit(): void {
    this.dataSource.data = this.data;
  }

  public ngOnChanges(changes: SimpleChanges) {

    if (!this._init) {
      this._init = true;
      return;
    }

    if (changes?.data) {
      this.dataSource.data = this.data;
    }
  }
}
