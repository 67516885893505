<mat-form-field [formGroup]="formGroup" [class.hide-input]="hideInput">
  <mat-date-range-input [rangePicker]="picker">
    <input matStartDate [formControlName]="from" (focus)="picker.open()">
    <input matEndDate [formControlName]="to" (focus)="picker.open()">
  </mat-date-range-input>    
  <mat-datepicker-toggle matSuffix suffix [for]="picker">
    <span class="date-small-icon" matDatepickerToggleIcon></span>
  </mat-datepicker-toggle>
  <mat-date-range-picker #picker>
  </mat-date-range-picker>
</mat-form-field> 