<card>
  <span header-title
    >{{'cards.user_settings.my_settings.title' | translate }}</span
  >

  <mat-tab-group
    tab-group
    animationDuration="0"
    (selectedTabChange)="onTabChange($event.index)"
  >
    <mat-tab label="{{tabLabels[0] | translate }}">
      <form [formGroup]="profileData">
        <card-section>
          <span header-title style="font-size: 24px; font-weight: bold">
            {{'cards.user_settings.profile.title' | translate }}
          </span>
          <div header-actions>
            <lxm-button
              type="beige"
              buttonType="submit"
              class="ml15"
              icon="save"
              label="action.save"
              [loading]="saveProfileLoading"
              (click)="saveProfile()"
            ></lxm-button>
          </div>
          <div body class="row mt20">
            <div class="col-8 no-padding" style="min-width: 400px">
              <form-field
                label="cards.user_settings.profile.label.user_first_name"
                for="firstName"
                type="input-text"
                [required]="true"
              >
                <validation-errors for="firstName"></validation-errors>
              </form-field>
              <form-field
                label="cards.user_settings.profile.label.user_surname"
                for="lastName"
                type="input-text"
                [required]="true"
              >
                <validation-errors for="lastName"></validation-errors>
              </form-field>
              <form-field
                label="cards.user_settings.profile.label.user_name"
                for="userName"
                type="input-text"
                [required]="true"
              >
                <validation-errors for="userName"></validation-errors>
              </form-field>
              <form-field
                label="cards.user_settings.profile.label.user_id"
                description="cards.user_settings.profile.label_description.user_id"
                for="idCode"
                type="input-number"
              >
                <validation-errors for="idCode"></validation-errors>
              </form-field>
              <form-field
                label="cards.user_settings.profile.label.user_e_mail"
                for="email"
                type="input-email"
                [required]="true"
              >
                <validation-errors for="email"></validation-errors>
              </form-field>
              <form-field
                label="cards.user_settings.profile.label.user_phone_short"
                [required]="true"
              >
                <span any row class="w-full">
                  <lxm-select
                    class="dialog-input-fixed-width-1"
                    [searchable]="true"
                    [options]="phoneCodes"
                    labelField="value"
                    value="id"
                    for="phoneCode"
                  >
                  </lxm-select>
                  <input
                    type="text"
                    class="lxm-input ml10"
                    formControlName="phoneNumberShort"
                  />
                </span>
                <validation-errors for="phoneCode"></validation-errors>
                <validation-errors for="phoneNumberShort"></validation-errors>
                <validation-errors for="phoneNumber"></validation-errors>
              </form-field>

              <form-field
                label="cards.user_settings.profile.label.user_role"
                for="roles"
                description="cards.user_settings.profile.label_description.user_role"
              >
                <span text>{{userRoles}}</span>
                <validation-errors for="roles"></validation-errors>
              </form-field>

              <form-field
                label="cards.user_settings.profile.label.user_job_title"
                for="jobTitle"
                type="input-text"
                [recommended]="true"
              >
                <validation-errors for="jobTitle"></validation-errors>
              </form-field>

              <form-field
                label="cards.user_settings.profile.label.user_portfolio_description"
                description="cards.user_settings.profile.label_description.user_portfolio_description"
              >
                <language-text
                  language-text
                  for="portfolioDescription"
                  newline="false"
                ></language-text>
              </form-field>

              <form-field
                label="cards.user_settings.profile.label.assistants"
                description="cards.user_settings.profile.label_description.assistants"
              >
                <lxm-select
                  select
                  for="assistants"
                  class="w-full"
                  labelField="label"
                  [searchable]="true"
                  [multiple]="true"
                  [options]="assistantsOptions"
                  compareField="id"
                >
                </lxm-select>
                <validation-errors for="assistants"> </validation-errors>
              </form-field>

              <form-field
                *ngIf="showLocations"
                label="cards.user_settings.profile.label.user_locations"
                description="cards.user_settings.profile.label_description.user_locations"
                for="locations"
              >
                <span text>{{userLocations}}</span>
                <validation-errors for="locations"></validation-errors>
              </form-field>
            </div>
          </div>
        </card-section>
      </form>
    </mat-tab>

    <mat-tab label="{{tabLabels[1] | translate}}">
      <form [formGroup]="changePasswordData">
        <card-section>
          <span header-title style="font-size: 24px; font-weight: bold">
            {{'cards.user_settings.profile_change_password.title' | translate }}
          </span>
          <div header-actions>
            <lxm-button
              type="beige"
              buttonType="submit"
              class="ml15"
              icon="save"
              label="action.save"
              [loading]="updatePasswordLoading"
              (click)="updatePassword()"
            >
            </lxm-button>
          </div>
          <div body class="mt20">
            <div class="row mb20">

              <!-- <card-message style="width: 600px">
                <div [innerHTML]="'cards.user_settings.profile_change_password.info_text' |
                translate"></div>
        
              </card-message> -->
              <info-array style="max-width: 600px"
                prelude="cards.user_settings.profile_change_password.info_text"
              ></info-array>
            </div>

            <div class="row">
              <div class="col-8 no-padding" style="width: 600px">
                <form-field
                  label="cards.user_settings.profile_change_password.label.user_password"
                  for="password"
                  autocomplete="current-password"
                  type="input-password"
                >
                  <validation-errors for="password"></validation-errors>
                </form-field>
                <form-field
                  label="cards.user_settings.profile_change_password.label.new_user_password"
                  for="newPassword"
                  autocomplete="new-password"
                  type="input-password"
                >
                  <validation-errors for="newPassword"></validation-errors>
                </form-field>
                <form-field
                  label="cards.user_settings.profile_change_password.label.new_user_password_confirm"
                  for="newPasswordConfirm"
                  autocomplete="confirm-password"
                  type="input-password"
                >
                  <validation-errors
                    for="newPasswordConfirm"
                  ></validation-errors>
                </form-field>
              </div>
            </div>
          </div>
        </card-section>
      </form>
    </mat-tab>

    <mat-tab label="{{tabLabels[2] | translate}}">
      <form [formGroup]="emailNotificationsData">
        <card-section>
          <span header-title style="font-size: 24px; font-weight: bold">
            {{'cards.user_settings.profile_email_notifications.title' |
            translate }}
          </span>
          <div header-actions>
            <lxm-button
              type="beige"
              buttonType="submit"
              class="ml15"
              icon="save"
              label="action.save"
              [loading]="updateMailNotificationSettingsLoading"
              (click)="updateMailNotificationSettings()"
            ></lxm-button>
          </div>
          <div body class="mt20">
            <div class="row mt20 mb20">
              <info-array
                prelude="cards.user_settings.profile_email_notifications.info_activities"
              ></info-array>
            </div>

            <div class="row">
              <form-field
                labelWidth="lg"
                label="cards.user_settings.profile_email_notifications.label.user_inbox_notifications"
                description="cards.user_settings.profile_email_notifications.label_description.user_inbox_notifications"
              >
                <slide-toggle
                  toggle
                  for="shouldReceiveUserInboxNotifications"
                ></slide-toggle>
              </form-field>
            </div>

            <div class="separator"></div>

            <div class="row mt20 mb20">
              <info-array
                prelude="cards.user_settings.profile_email_notifications.info_daily"
              ></info-array>
            </div>
            <div class="row">
              <div class="grid grid-cols-1 xl:grid-cols-2 w-full grid-" style="min-width: 400px">
                <form-field
                  label="cards.user_settings.profile_email_notifications.label.send_daily"
                  description="cards.user_settings.profile_email_notifications.label_description.send_daily"
                  labelWidth="lg"
                >
                  <slide-toggle toggle for="isEnabled"></slide-toggle>
                </form-field>
                <ng-container *ngIf="sendDailyEmails">
                  <form-field
                    labelWidth="lg"
                    label="cards.user_settings.profile_email_notifications.label.send_activities_summary"
                    description="cards.user_settings.profile_email_notifications.label_description.send_activities_summary"
                  >
                    <slide-toggle
                      toggle
                      for="shouldReceiveActivitiesSummary"
                    ></slide-toggle>
                  </form-field>
                  <form-field
                    labelWidth="lg"
                    label="cards.user_settings.profile_email_notifications.label.send_offers_summary"
                    description="cards.user_settings.profile_email_notifications.label_description.send_offers_summary"
                  >
                    <slide-toggle
                      toggle
                      for="shouldReceiveOffersSummary"
                    ></slide-toggle>
                  </form-field>
                  <form-field
                    labelWidth="lg"
                    label="cards.user_settings.profile_email_notifications.label.send_calendar_entries_summary"
                    description="cards.user_settings.profile_email_notifications.label_description.send_calendar_entries_summary"
                  >
                    <slide-toggle
                      toggle
                      for="shouldReceiveCalendarEntriesSummary"
                    ></slide-toggle>
                  </form-field>
                  <form-field
                    labelWidth="lg"
                    label="cards.user_settings.profile_email_notifications.label.send_product_portfolio_changes"
                    description="cards.user_settings.profile_email_notifications.label_description.send_product_portfolio_changes"
                  >
                    <slide-toggle
                      toggle
                      for="shouldReceiveProductPortfolioChanges"
                    ></slide-toggle>
                  </form-field>
                  <form-field
                    labelWidth="lg"
                    label="cards.user_settings.profile_email_notifications.label.send_quiet_day"
                    description="cards.user_settings.profile_email_notifications.label_description.send_quiet_day"
                  >
                    <slide-toggle
                      toggle
                      for="shouldReceiveQuietDayMessage"
                    ></slide-toggle>
                  </form-field>
                </ng-container>
              </div>
            </div>
          </div>
        </card-section>
      </form>
    </mat-tab>

    <mat-tab label="{{tabLabels[3] | translate}}">
      <form [formGroup]="smtpSettingsData">
        <scope translationsPath="cards.lexi_market_settings.mail_settings">
          <card-section>
            <span header-title style="font-size: 24px; font-weight: bold">
              {{'cards.lexi_market_settings.mail_settings.title' | translate }}
            </span>
            <span header-actions *ngIf="userSmtpSettingsIsEnabled">
              <div class="flex">
                <lxm-button
                  type="beige"
                  class="ml15"
                  icon="send"
                  label=".btn_test"
                  [loading]="testSmtpSettingsLoading"
                  (click)="test()"
                ></lxm-button>
                <lxm-button
                  type="beige"
                  class="ml15"
                  icon="save"
                  label="action.save"
                  [loading]="updateSmtpSettingsLoading"
                  (click)="updateSmtpSettings()"
                ></lxm-button>
              </div>
            </span>
            <div body class="row">
              <div class="col-8 no-padding">
                <ng-container *ngIf="!userSmtpSettingsIsEnabled">
                  <div class="row mt20 mb20">
                    <card-message type="message">
                      {{'cards.user_settings.TenantSmtpUsed' | translate }}
                    </card-message>
                  </div>
                </ng-container>

                <ng-container *ngIf="userSmtpSettingsIsEnabled">
                  <form-field label="use_custom_smtp">
                    <slide-toggle toggle for="useCustomSmtp"></slide-toggle>
                    <validation-errors for="useCustomSmtp"></validation-errors>
                  </form-field>

                  <ng-container *ngIf="customSmtp">
                    <form-field for="host" label="host" type="input-text">
                      <validation-errors for="host"></validation-errors>
                    </form-field>

                    <form-field for="port" label="port" type="input-text">
                      <validation-errors for="port"></validation-errors>
                    </form-field>

                    <form-field label="enable_ssl">
                      <slide-toggle toggle for="enableSsl"></slide-toggle>
                      <validation-errors for="enableSsl"></validation-errors>
                    </form-field>

                    <form-field label="with_credentials">
                      <slide-toggle toggle for="withCredentials"></slide-toggle>
                      <validation-errors
                        for="withCredentials"
                      ></validation-errors>
                    </form-field>

                    <ng-container *ngIf="useCredentials">
                      <form-field for="user" label="user" type="input-text">
                        <validation-errors for="user"></validation-errors>
                      </form-field>

                      <form-field
                        for="password"
                        label="password"
                        type="input-password"
                      >
                        <validation-errors for="password"></validation-errors>
                      </form-field>
                    </ng-container>

                    <form-field label="send_from_user_email">
                      <slide-toggle
                        toggle
                        for="sendFromUserEmail"
                        [readonly]="!canEditSendFromUserEmail"
                      ></slide-toggle>
                      <validation-errors
                        for="sendFromUserEmail"
                      ></validation-errors>
                    </form-field>

                    <!-- <form-field label="ip_addresses">
                        <div any>
                          <div>90.190.52.51</div>
                        </div>
                      </form-field> -->
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </card-section>
        </scope>
      </form>
    </mat-tab>
  </mat-tab-group>
</card>
