<div class="client-contact-row">
  <div class="client-contact-name mr5 bold">
    <span class="profile-status-icon {{getStatusClass()}} mr10"></span>
    <span>
      {{contact.name || '-'}}
    </span>
  </div>
  <span class="client-contact-role">
    {{contact.role}}
  </span>
</div>
<div class="client-contact-row mt5">
  <div class="client-contact-details">
    <span class="mr10" *ngIf="phoneShort">
      <phone-number [code]="phoneCode" [number]="phoneShort"></phone-number>
    </span>
    <span *ngIf="contact.email">
      {{contact.email}}
    </span>
  </div>
</div>
<div class="client-contact-row mt5" *ngIf="translatedPortfolioDescription">
  <div class="client-contact-description line-clamp-1">
    <span>{{translatedPortfolioDescription}}</span>
  </div>
</div>