<div class="flex f-1" [formGroup]="formGroup" [class.readonly]="readonly">

  <div class="flex f-1 flex-col" [formArrayName]="for">
    <div class="flex f-1 flex-row align-center mb10"
          *ngFor="let field of formArray.controls; let i = index"
          [formGroupName]="i" [readonly]="readonly">

      <div class="dialog-input-fixed-width-3">
        <lxm-select for="tare" 
                    [searchable]="true" 
                    [options]="tareOptions" 
                    value="id" 
                    labelField="translatedValue" 
                    [isTranslated]="true"
                    [sortByLabel]="true">
          <validation-errors [for]="'tares['+i+'].tareTypeId'" aliasTo="tare" [errorsFormGroup]="formGroup"></validation-errors>
        </lxm-select>
      </div>

      <div class="dialog-input-fixed-width-1 ml10">
        <combined-input for="amount_pc">
          <span suffix>{{'enums.UnitShort.Piece' | translate}}</span>
          <validation-errors [for]="'tares['+i+'].amount_pc'" aliasTo="amount_pc" [errorsFormGroup]="formGroup"></validation-errors>
        </combined-input>

      </div>

      <span class="manager-actions ml10">
        <button class="transparent-button p6"
                [class.hidden]="readonly"
                (click)="removeItem(i)">
          <span class="trash-icon"></span>
        </button>
      </span>

    </div>

    <lxm-button type="transparent"
                class="new-code-button"
                padding="8px 0"
                icon="plus-dark"
                label="cards.products.product_packaging_edit_transport_unit.action.add_tare"
                (click)="addItem()">
    </lxm-button>

  </div>

</div>
