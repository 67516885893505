import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { ClientChangeType, ClientDiscountRuleType, ClientStatus, ClientTimelineItemType, OfferChangeType, OfferType, Status, SupplierOfferStatus } from '../enum';
import { IClvDto, ICountry } from '../cards/product/product-info/product-info.model';
import { ILanguage } from './locale.service';
import { IPhoneCode } from '../components/_partials/lxm-settings/users-table/users-table.component';
import { IUser } from '../models';
import { ODataResponse } from './data-table.service';
import { ClientExpiryRuleType } from '../modules/clients/enum/ClientExpiryRuleType';

@Injectable({ providedIn: 'root' })
export class ClientsService {
  
  constructor(private _http: HttpClient) { }

  public toggleVisibilityInProductsList(clientId: any, isVisible: boolean) {
    return this._http.post<IClientCard>(`api/clients/${clientId}/toggleVisibilityInProductsList`, { isVisible });
  }

  public getClient(clientId: string) {
    return this._http.get<IClientCard>(`api/clients/${clientId}`);
  }

  public getClientTimeline(clientId: string, params?: any) {
    return this._http.get<ODataResponse<IClientTimelineItem>>(`api/clients/${clientId}/timeline`, { params: { 
      ...params,
      $orderby: 'createdAt desc'
    } }); 
  }

  public getClientContacts(clientId: string, params = {  $count: 'true' } ) {
    return this._http.get<ODataResponse<IClientContactListItem>>(`api/clients/${clientId}/contacts`, { params: params })
  }

  public getClientDiscountRules(clientId: string, params = {  $count: 'true',  $top: '8' } ) {
    return this._http.get<ODataResponse<any>>(`api/clients/${clientId}/discountRules`, { params: params })
  }

  public getClientExpiryRules(clientId: any,  params = {  $count: 'true',  $top: '8' } ) {
    return this._http.get<ODataResponse<any>>(`api/clients/${clientId}/expiryRules`, { params: params })
  }

  public saveExpiryRule(clientId: string, ruleId: string, data: ISaveExpiryRuleReq) {
    return this._http.post<string>(`api/clients/${clientId}/expiryRules/${ruleId || ''}`, data);
  }

  public deleteExpiryRule(clientId: string, ruleId: string) {
    return this._http.delete(`api/clients/${clientId}/expiryRules/${ruleId}`);
  }

  public saveClient(clientId: string, data: any) {
    return this._http.post<string>(`api/clients/${clientId || ''}`, data);
  }

  public saveClientDetails(clientId: string, data: any) {
    return this._http.post<string>(`api/clients/${clientId}/details`, data);
  }

  public saveOfferSettings(clientId: string, data: any) {
    return this._http.post<any>(`api/clients/${clientId}/offersettings`, data);
  }

  public setClientOwner(clientId: string, ownerId: string) {
    return this._http.post<any>(`api/clients/${clientId}/owner`, { ownerId: ownerId });
  }

  public saveContact(contactId: string, data: any) {
    return this._http.post<string>(`api/clients/${data.clientId}/contacts/${contactId || ''}`, data);
  }

  public deleteContact(contactId: string, clientId: string) {
    return this._http.delete(`api/clients/${clientId}/contacts/${contactId}`);
  }

  public createClientMemo(clientId: string, data: ISaveMemoReq) {
    return this._http.post<string>(`api/clients/${clientId}/memos`, data);
  }

  public createClientLogComment(clientId: string, parentId: string, data: ISaveMemoReq) {
    return this._http.post<string>(`api/clients/${clientId}/memos/comments/clientlogs/${parentId}`, data);
  }

  public updateComment(clientId: string, id: string, comment: string) {
    return this._http.post<string>(`api/clients/${clientId}/memos/comments/${id}`, { content: comment });
  }

  public createOfferLogComment(clientId: string, parentId: string, data: ISaveMemoReq) {
    return this._http.post<string>(`api/clients/${clientId}/memos/comments/offerlogs/${parentId}`, data);
  }

  public saveMemo(clientId: string, id: string, data: ISaveMemoReq) {
    return this._http.post<string>(`api/clients/${clientId}/memos/${id}`, data);
  }

  public markMemoComplete(clientId: string, id: string, isComplete: boolean = true) {
    return this._http.post<string>(`api/clients/${clientId}/memos/${id}/complete`, { isComplete: isComplete});
  }

  public deleteMemo(clientId: string, id: string) {
    return this._http.delete<any>(`api/clients/${clientId}/memos/${id}`);
  }

  public saveDiscountRule(ruleId: string, data: ISaveDiscountRuleReq) {
    return this._http.post<string>(`api/clients/${data.clientId}/discountrules/${ruleId || ''}`, data);
  }

  public deleteDiscountRule(clientId: string, ruleId: string) {
    return this._http.delete(`api/clients/${clientId}/discountrules/${ruleId}`);
  }

  public getClientsFormdata() {
    return this._http.get<any>(`api/clients/formdata`);
  }

  public getClientListFormdata() {
    return this._http.get<any>(`api/clients/listFormData`);
  }

  public getClientLocations(clientId: string) {
    return this._http.get<any>(`api/clients/${clientId}/locations`);
  }
}

export interface IClientListItem {
  id: string;
  name: string;
  regNo: string;
  address: any;
  isRetailer: boolean;
  status: Status;
  logoFileId: string;
  imageThumbUrl: string;
  thumbnail: string;
  thumbnailContentType: string;
  // clientType: TenantType;
  modifiedAt: Date;
  lastEventDate: Date;
  upcomingEventDate: Date;
  isMyClient: boolean;
  clientTagIds:string[];
}

export interface IClientContactListItem {
  id: string;
  name: string;
  email: string;
  role: string;
  portfolioDescription: string;
  phoneNumber: string;
  phoneCodeId: string;
  status: Status;
}

export interface IClientTimelineItem {
  changeType: ClientChangeType,
  changes: any[],
  children: any[],
  clientId: string,
  comment: IClientTimelineComment,
  content: string,
  createdAt: Date,
  createdBy: IUser,
  id: string;
  isComplete: boolean,
  itemType: ClientTimelineItemType
}

export interface IClientTimelineComment {
  content: string,
  createdAt?: Date,
  createdBy?: IUser,
  id: string;
  parentId?: string;
}

export interface IClientCard {
  clientDiscountRules: any[];
  clientExpiryRules: any[];
  clientInfo: IClientInfo;
  formData: IClientFormData;
  activeOffers: IClientOfferItem[];
  myOfferDrafts: IClientOfferItem[];
  offerArchive: IOfferArchive;
  changes: any;
}

export interface IClientOfferItem {
  id: string;
  name: string;
  status: SupplierOfferStatus;
  type: IClvDto;
  createdBy: IUser;
  productCount: number;
  confirmedProductCount: number;
}

export interface IOfferArchive {
  confirmedCount: number;
  rejectedCount: number;
  expiredCount: number;
}

export interface IClientListFormData {
  clientTags: IClvDto[];
}

export interface IClientFormData {
  phoneCodes: IPhoneCode[];
  currencies: IClvDto[];
  deliveryMethods: IClvDto[];
  excelExports: IExcelExportOption[];
  owners: IUser[];
  countries: ICountry[];
  clientTags: IClvDto[];
}

export interface IClientInfo {
  id: string;
  name: string;
  shortName: string;
  regNo: string;
  kmkrNr: string;
  address: any;
  isRetailer: boolean;
  homepage: string;
  email: string;
  status: ClientStatus;
  logoFileId: string;
  paymentDeadlineInDays: number;
  fineForDelay: number;
  isFIEOrForeign: boolean;
  defaultLanguage: ILanguage;
  currencyId: string;
  clientTags: string[];
  deliveryMethodId: string;
  offerExportType: any;
  owner: IUser;
  // clientType: TenantType;
  isSystemClient: boolean;
  country: ICountry;
  thumbnailSmall: string;
  thumbnailSmallContentType: string;
}

export interface IExcelExportOption {
  id: string;
  name: string;
}

export interface ISaveMemoReq {
  content?: string;
  isComplete?: boolean;
  date?: any;
}

export interface ISaveDiscountRuleReq {
  id: string;
  type: ClientDiscountRuleType;
  description: string;
  discountPercent: number;
  orderNr: number;
  conditionId: string;
  clientId: string;
}

export interface ISaveExpiryRuleReq {
  description: string;
  operator: number;
  conditionValue1: number;
  conditionValue2: number;
  type: ClientExpiryRuleType,
  percent: number;
  value: number;
}