import { Component, OnInit, Input, Optional, Host } from "@angular/core";
import { FormGroup, ControlContainer } from '@angular/forms';

@Component({
  selector: "apart-toggle-button",
  templateUrl: "./apart-toggle-button.component.html",
  styleUrls: ['./apart-toggle-button.scss']
})
export class ApartToggleButtonComponent {

  @Input() public formGroup: FormGroup;
  @Input() public for: string;
  @Input() public toggled = false;
  @Input() public readonly = false;
  @Input() public campaign: any;

  constructor(@Optional() @Optional() private _controlContainer: ControlContainer) { }


  public ngOnInit(): void {

    if (this._controlContainer && this._controlContainer.control instanceof FormGroup) {
      this.formGroup = <FormGroup>this._controlContainer.control;
    }
  }

  public setToggle(bool) {
    this.formGroup.get(this.for).setValue(bool);
  }

}
