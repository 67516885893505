<div *ngIf="!displayHeadersWhenEmpty && !dataSource.data?.length">
  <no-content image="no-files" text="global.no_files_added" [inline]="true"></no-content>
</div>

<div *ngIf="dataSource.data?.length || displayHeadersWhenEmpty">

  <mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="file_name">
      <mat-header-cell *matHeaderCellDef>
        {{'cards.products.product_additional_info_and_instructions.table.file_name' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{row.name}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="file_description">
      <mat-header-cell *matHeaderCellDef>
        {{'cards.products.product_additional_info_and_instructions.table.file_description' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{row.description}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="file_upload_date">
      <mat-header-cell *matHeaderCellDef>
        {{'cards.products.product_additional_info_and_instructions.table.upload_date' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{row.uploadedAt | amDateFormat: 'L'}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="flex flex-row justify-end">
          <lxm-button type="transparent" icon="download" title="action.download" (click)="downloadAttachment(row)"></lxm-button>
          <lxm-button type="transparent" icon="edit" title="action.edit" (click)="openAddFileDialog(row)" *ngIf="!readonly"></lxm-button>
          <lxm-button type="transparent" icon="trash" title="action.remove" (click)="removeAttachment(row)" *ngIf="!readonly"></lxm-button>
        </span>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columns;" [ngClass]="{'row-to-delete readonly' : row.isDeleted}"></mat-row>
  </mat-table>

</div>

<a class="no-display" #download></a>