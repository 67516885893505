import { Component, Input } from '@angular/core';
import { Status, UserAction } from 'src/app/enum';
import { OfferService } from 'src/app/_services';
import { ActivatedRoute } from '@angular/router';
import { ClientsService, IClientCard, IClientFormData, IClientInfo, IClientOfferItem, IOfferArchive } from 'src/app/_services/clients.service';
import { AppState } from 'src/app/state/app.state';

@Component({
  selector: 'client-offers-card',
  templateUrl: './offers.card.html',
  styleUrls: ['./offers.card.scss']
})
export class ClientOffersCard {

  public UserAction = UserAction;
  public Status = Status;

  public clientCard: IClientCard;
  public clientInfo: IClientInfo;

  public activeOffers: IClientOfferItem[];
  public draftedOffers: IClientOfferItem[];
  public archivedOffers: IOfferArchive;

  public formData: IClientFormData;

  public isClient = false;

  public showAllActiveOffers = false;
  public showAllDraftedOffers = false;

  constructor(
    private _route: ActivatedRoute,
    private _offerService: OfferService,
    private _appState: AppState
  ) {
    const { clientCard } = this._route.snapshot.data.initialData;

    this.clientCard = clientCard;

    if (!this.clientCard) {
      return;
    }

    this.activeOffers = this.clientCard.activeOffers;
    this.draftedOffers = this.clientCard.myOfferDrafts;
    this.archivedOffers = this.clientCard.offerArchive;

    this.formData = this.clientCard.formData;
    this.clientInfo = this.clientCard.clientInfo;
  }

  public toggleShowAllActiveOffers(toSet = !this.showAllActiveOffers) {
    this.showAllActiveOffers = toSet;
  }

  public toggleShowAllDraftedOffers(toSet = !this.showAllDraftedOffers) {
    this.showAllDraftedOffers = toSet;
  }

  public get archivedCount() {
    if (!this.archivedOffers) return 0;
    const { confirmedCount, expiredCount, rejectedCount } = this.archivedOffers;
    return confirmedCount + expiredCount + rejectedCount;
  }

  public getArchiveCountFlexPercentage(key: string) {
    const percentage = this.getArchiveCountPercentage(key);
    if (!percentage) return 0;
    return `0 0 ${percentage}%`;
  }

  public getArchiveCount(key: string) {
    return this.archivedOffers[key];
  }

  public getArchiveCountPercentage(key: string, raw = false) {
    const archivedTotalCount = this.archivedCount;
    const count = this.archivedOffers[key];
    if (!count || !archivedTotalCount) return 0;
    if (raw) {
      return count / archivedTotalCount;
    }
    return count * 100 / archivedTotalCount;
  }

  public getArchiveTranslateKey(key: string) {
    const _ = `enums.SupplierOfferStatus.`
    const k = key.replace('Count', '')
    return _ + k.charAt(0).toUpperCase() + k.slice(1);
  }

  public get archivedKeys() {
    if (!this.archivedOffers) return null;
    return Object.keys(this.archivedOffers);
  }

  public get hasActiveOffers() {
    return this.activeOffers?.length > 0;
  }

  public get hasDraftedOffers() {
    return this.draftedOffers?.length > 0;
  }

  public get hasArchivedOffers() {
    return this.archivedCount > 0;
  }

  public get hasOffers() {
    return this.hasActiveOffers || this.hasDraftedOffers || this.hasArchivedOffers;
  }

  public getStatusProgress(status) {
    return this._offerService.getSendingOfferStatusProgress(status);
  }

  public newOffer() {
    const clientId = this.clientInfo?.id;
    if (!clientId || !window) return;
    window.open(`/offers/new/${clientId}`);
  }

}

@Component({
  selector: 'client-offer',
  templateUrl: './offer.html',
  styleUrls: ['./offer.scss']
})
export class ClientOffer {

  @Input() public offer: IClientOfferItem;

  constructor(
    private _offerService: OfferService
  ) {}

  public getStatusProgress(status) {
    return this._offerService.getSendingOfferStatusProgress(status);
  }

}