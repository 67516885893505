import { formatNumber } from '@angular/common';
import { numberUtil } from '../util/number-util';
import { locale } from 'src/app/_services';
import { MarkupMethod } from '../enum/markup-mehtod';
import { Unit } from '../enum';

export function calculateMargin(internalPrice: number, priceRetailer: number) {
  internalPrice = numberUtil.toNumber(internalPrice);
  priceRetailer = numberUtil.toNumber(priceRetailer);
  if (!numberUtil.isNumber(internalPrice) || !numberUtil.isNumber(priceRetailer)) {
    return null;
  }
  return (internalPrice - priceRetailer) / priceRetailer;
}

export function calculateDiscount(basePrice: number, finalPrice: number): number {
  basePrice = numberUtil.toNumber(basePrice);
  finalPrice = numberUtil.toNumber(finalPrice);

  if (!numberUtil.isNumber(finalPrice) || !numberUtil.isNumber(basePrice) || !basePrice) {
    return null;
  }
  const discountValue = 1 - (finalPrice / basePrice);
  return discountValue;
}

export function calculateDiscountNumber(basePrice: number, finalPrice: number, digits = 1) {
  let discount = calculateDiscount(basePrice, finalPrice);
  if (!numberUtil.isNumber(discount)) {
    return null;
  }

  return formatNumber(discount * 100, locale, `1.1-${digits}`);
}

export function calculateSalePriceByDiscount(discount: number, listPrice: number): number {
  listPrice = numberUtil.toNumber(listPrice);
  discount = numberUtil.toNumber(discount * - 1);

  if (!numberUtil.isNumber(discount) || !numberUtil.isNumber(listPrice) || !listPrice) {
    return null;
  }

  return (discount / 100 * listPrice) + listPrice;
}

export function calculatePriceChange(basePrice: number, price: number) {
  basePrice = numberUtil.toNumber(basePrice);
  price = numberUtil.toNumber(price);

  if (!numberUtil.isNumber(basePrice) || !numberUtil.isNumber(price) || !basePrice || !price) {
    return null;
  }

  return (price - basePrice) / basePrice;
}

export function nextSchemeDay(minDate?: Date): Date {
  const now = new Date();
  minDate = minDate || now;
  const next = minDate > now ? minDate : now;
  return new Date(next.getFullYear(), next.getMonth(), next.getDate() + 1).stripTimezone();
}

export function calculateMarkup(retailPrice: number,
  salesPrice: number,
  vatPercent: number,
  markupMethod: MarkupMethod) {
  retailPrice = numberUtil.toNumber(retailPrice);
  salesPrice = numberUtil.toNumber(salesPrice);
  if (!retailPrice || !salesPrice) {
    return 0;
  }
  const vat = vatPercent ? (1 + vatPercent / 100) : 1;

  if (markupMethod == MarkupMethod.Coverage) {
    const retailPriceWithoutVat = numberUtil.excludeVat(retailPrice, vatPercent);
    return (retailPrice / vat - salesPrice) / (retailPriceWithoutVat);
  }
  
  return (retailPrice / vat - salesPrice) / salesPrice;
}

export function calculateRetailPriceWithoutVatByBuyInPriceAndMargin(buyInPrice, margin, method: MarkupMethod = MarkupMethod.Markup) {
  buyInPrice = numberUtil.toNumber(buyInPrice);
  margin = numberUtil.toNumber(margin);

  if (method === MarkupMethod.Coverage) {
    return numberUtil.roundPrice(buyInPrice / (1 - margin / 100));
  }

  // if (method === MarkupMethod.Markup) {
    return numberUtil.applyVat(buyInPrice, margin);
  // }

}

export function calculateUnitPrice(price: number,
  contentUnitId: any,
  drainedWeightInGrams: number,
  netContent: number) {

  if (!price) {
    return null;
  }

  if (contentUnitId == Unit.Kg && drainedWeightInGrams > 0) {
    return price / (drainedWeightInGrams / 1000);
  }

  if (netContent > 0) {
    return price / netContent;
  }

  return null;
}