import { Component, Inject, Optional } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OfferService } from 'src/app/_services';
import { AuthenticationService } from 'src/app/_services';
import { asArray, CampaignReturnableItemOption, OfferKind } from 'src/app/enum';
import { UserAction } from 'src/app/enum';
import { AppState } from 'src/app/state/app.state';

@Component({
  selector: "supplier-offer-product-campaign-dialog",
  host: { 'class': 'dialog' },
  templateUrl: './supplier-offer-product-campaign.dialog.html',
  styleUrls: ['./supplier-offer-product-campaign.dialog.scss']
})
export class ProductCampaignInfoDialogComponent {

  public retailerId: string;

  public product: any;

  public form: FormGroup;

  public readonly = false;

  public returnableItemsOptions = asArray(CampaignReturnableItemOption);

  public isSaveLoading = false;
  public offerKind: OfferKind;

  public get isRetailer() {
    return this.offerKind != OfferKind.SupplierOffer;
  }

  constructor(
    private _offerService: OfferService,
    private _appState: AppState,
    public dialogRef: MatDialogRef<ProductCampaignInfoDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    authService: AuthenticationService
  ) {

    switch(data.offerKind) {
      case OfferKind.SupplierOffer:
        this.readonly = data.readonly || !authService.hasRight([UserAction.ManageSupplierOffers]);
        break;

      case OfferKind.ReceivingOffer:
      case OfferKind.OfferRequest:
        this.readonly = data.readonly || !authService.hasRight([UserAction.ManageReceivedOffers]);
        break;

      default:
        this.readonly = true;
        break;
    }

    this.offerKind = data.offerKind;
    this.retailerId = data.retailerId;
    this.product = data.product;

    this.form = new FormGroup({
      netting: new FormControl(data.product.campaignInfo?.netting),
      addressed: new FormControl(data.product.campaignInfo?.addressed),
      withAd: new FormControl(data.product.campaignInfo?.withAd || false),
      marketingFee: new FormControl(data.product.campaignInfo?.marketingFee),
      invoiceRecipient: new FormControl(data.product.campaignInfo?.invoiceRecipient),
      storeCount: new FormControl(data.product.campaignInfo?.storeCount),
      displaySize: new FormControl(data.product.campaignInfo?.displaySize),
      returnableItems: new FormControl(data.product.campaignInfo?.returnableItems),
      limit: new FormControl(data.product.campaignInfo?.limit),
      comments: new FormControl(data.product.campaignInfo?.comments),
      campaignNr: new FormControl(data.product.campaignInfo?.campaignNr),
    });
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public save(): void {

    const f = this.form.value;

    this.isSaveLoading = true;
    this._offerService
      .validateOfferProductCampaignInfo({
        retailerId: this.retailerId,
        campaignInfo: {
          netting: f.netting || false,
          addressed: f.addressed || false,
          withAd: f.withAd || false,
          marketingFee: f.marketingFee,
          invoiceRecipientId: f.invoiceRecipient?.id,
          storeCount: f.storeCount,
          displaySize: f.displaySize,
          returnableItems: f.returnableItems,
          limit: f.limit,
          comments: f.comments,
          campaignNr: f.campaignNr,
        }
      })
      .result(this.form, () => {
        this.isSaveLoading = false;
        this.closeDialog(this.form.value);
      }, e => {
        this.isSaveLoading = false;
      });
  }

  public closeDialog(data?: any): void {
    this.dialogRef.close(data);
  }
}
