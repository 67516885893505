import { HttpEventType } from '@angular/common/http';
import { resolve } from '@angular/compiler-cli/src/ngtsc/file_system';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import { from, Subscription } from 'rxjs';
import { ICountry } from 'src/app/cards/product/product-info/product-info.model';
import { asArray, ClientStatus, UserAction } from 'src/app/enum';
import { IUser } from 'src/app/models';
import { LxmMessage, RouteNavigator } from 'src/app/_helpers';
import { FileService, SignalRService, TitleService } from 'src/app/_services';
import { ClientsService, IClientCard, IClientFormData, IClientInfo } from 'src/app/_services/clients.service';
import { environment } from 'src/environments/environment';
import { ClientState } from '../../state/client.state';

@Component({
  selector: 'client-info-card',
  templateUrl: './client-info.card.html',
  styleUrls: ['./client-info.card.scss']
})
export class ClientInfoCard implements OnInit {

  public edit = false;
  public panelOpen = false;
  public saveLoading = false;

  public clientCard: IClientCard;
  public clientInfo: IClientInfo;
  public formData: IClientFormData;

  public UserAction = UserAction;

  @Input() public clientId;

  public clientStatusOptions = asArray(ClientStatus);
  public ownerOptions: IUser[] = [];
  public countryOptions: ICountry[] = [];

  private _logoPromise: Promise<void> = new Promise((resolve, reject) => {
    resolve();
  })

  private _snapshot;
  
  private _hubConnection: HubConnection;
  private _signalRSubscription: Subscription;

  public form = new FormGroup({
    clientName: new FormControl(),
    clientStatus: new FormControl(ClientStatus.Active),
    clientOwner: new FormControl(),
    clientShortName: new FormControl(),
    clientCountry: new FormControl()
  })

  public uploadInProgress = false;
  public uploadingFile = false;
  public files = [];

  constructor(
    public _state: ClientState,
    private _routeNavigator: RouteNavigator,
    private _clientsService: ClientsService,
    private _signalRService: SignalRService,
    private _router: Router,
    private _fileService: FileService,
    private _lxmMessage: LxmMessage,
    private _route: ActivatedRoute,
    private _titleService: TitleService) {
      this.clientCard = this._route.snapshot.data.initialData?.clientCard as IClientCard;

      if (!this.clientCard) {
        const formData = this._route.snapshot.data.formData;
        const { owners, countries } = formData;
        this.ownerOptions = owners;
        this.countryOptions = countries;
        return;
      }

      this.formData = this.clientCard?.formData;
      this.ownerOptions = this.formData.owners;
      this.countryOptions = this.formData.countries;

      this.clientInfo = this.clientCard?.clientInfo;

      this.form.patchValue({
        clientName: this.clientInfo.name,
        clientStatus: this.clientInfo.status,
        clientOwner: this.clientInfo.owner,
        clientShortName: this.clientInfo.shortName,
        clientCountry: this.clientInfo.country
      });

      this._signalRSubscription = this._signalRService.commonHub
        .subscribe(connection => {
          this._hubConnection = connection;
          if (connection?.state === HubConnectionState.Connected) {
            connection.send('viewClient', this.clientInfo.id);
          }
        });

  }

  ngOnInit() {
    if (this.clientId) {
      this.saveInitialData();
      this.getLogoSrc();
    } else {
      this.edit = true;
      this.panelOpen = true;
    }
    this._setPageTitle();
  }

  private _setPageTitle() {
    if (this.clientId) {
      this._titleService.setTitle(this.clientName);
    } else {
      this._titleService.setTitle('cards.clients.client_info.new_client');
    }
  }

  public get canEdit() {
      return !this.clientId || this._state.isClient && this._state.canEdit;
  }

  public get isSelfSupplier() {
    return this._state.isSelfSupplier;
  }

  public get canChangeOwner() {
    return this._state.canChangeOwner;
  }

  private readonly _fallbackImage = '/assets/img/icons/no-content/no-image.svg';
  public logoSrc = '/assets/img/icons/no-content/no-image.svg';

  public customLogoSrc: any;
  public getLogoSrc() {
    if (this.customLogoSrc) {
      return this.customLogoSrc;
    }
    const thumbnail = this.clientInfo?.thumbnailSmall;
    const thumbnailContentType = this.clientInfo?.thumbnailSmallContentType;
    this.getThumbnailUrl(thumbnail, thumbnailContentType, true);
  }
  
  public checkImage(imageSrc: string, good: any, bad: any) {
    const img = new Image();
    img.onload = good; 
    img.onerror = bad;
    img.src = imageSrc;
  }

  public getThumbnailUrl(thumbnailSource?: string, thumbnailContentType?: string, thumbnailBase64?: boolean) {
    if (thumbnailSource && thumbnailBase64) {
      this.logoSrc = `data:${thumbnailContentType};base64,${thumbnailSource}`;
    }

    const apiUrl = environment.apiUrl;
    if (!this.clientId || !apiUrl) { 
      this.logoSrc = this._fallbackImage; 
    };
    const imageSrc = `${apiUrl}api/Clients/${this.clientId}/logo`;

    this.checkImage(imageSrc, success => {
      this.logoSrc = imageSrc;
    }, error => {
      this.logoSrc = this._fallbackImage;
    });
  }

  public async uploadFile(FileList: FileList) {
    
    const image = FileList.item(0);

    if (FileReader && FileList && FileList.length) {
      const fr = new FileReader();
      fr.onload = (e) => {
        this.customLogoSrc = fr.result;  
      }
      fr.readAsDataURL(image);
    }
    
    const file = {
      id: '',
      name: image.name,
      progress: 0,
      error: undefined
    };
    this.files.push(file);

    const formData = new FormData();
    formData.append('file', image);

    this._logoPromise = new Promise((resolve, reject) => {

      this._fileService.uploadImage(formData)
        .subscribe(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round(100 * event.loaded / event.total);
              this.uploadInProgress = true;
              break;
            case HttpEventType.Response:
              file.id = event.body;
              this.uploadInProgress = false;
              resolve();
              break;
          }
        }, error => {
          this.uploadInProgress = false;
          resolve();
          file.progress = -1;
          const field = error?.validationResults?.stream;
          if (field) {
            file.error = field[0].value;
          }
          // this._lxmMessage.error({
          //   message: 'cards.clients.client_info.error.image_upload_failed'
          // });
        });

    });

    await this._logoPromise;
  }

  public deleteLoading = false;
  public deleteAttachment(index: number) {
    this.deleteLoading = true;
    this._fileService.delete(this.files[index].id)
      .toPromise()
      .then(res => {
        if (this.customLogoSrc) {
          this.customLogoSrc = undefined;
        }
        this.files?.splice(index, 1);
        this.deleteLoading = false;
      }).catch(e => {
        this.deleteLoading = false;
      });
  }

  public get clientName() {
    if (this.clientId) {
      return this._snapshot.clientName;
    }
    return this.form.get('clientName').value;
  }

  public get isClientActive() {
    return this.form.get('clientStatus').value === ClientStatus.Active;
  }

  public get logoFileId() {
    return this.clientInfo?.logoFileId;
  }

  public newOffer() {
    if (!this.clientInfo?.id) return;
    window.open(`/offers/new/${this.clientInfo.id}`);
  }

  public navigateBack() {
    this._routeNavigator.toPreviousRoute({ fallback: '/clients'});
  }

  public onOwnerChange(owner): void {
    const clientId = this.clientId
    const ownerId = owner?.id;
    if (!clientId || !ownerId) return;
    this._clientsService.setClientOwner(clientId, ownerId).result(this.form, res => {
      this._state.getUserPerimissions(ownerId);
    }, e => {
      console.error(e);
    });
  }

  public toggleEdit() {
    if (this.edit) {
      this.revertToInitialData();
    }

    this.edit = !this.edit;
  }

  public togglePanelOpen(toSet = !this.panelOpen) {
    this.panelOpen = toSet;
  }

  public saveInitialData() {
    this._snapshot = this.form.value;
  }

  public revertToInitialData() {
    this.customLogoSrc = undefined;
    this.files = [];
    this.form.patchValue(this._snapshot);
  }

  public async save() {
    this.saveLoading = true;

    await this._logoPromise;

    let req = this._createSaveRequest();
    
    this._clientsService
      .saveClient(this.clientId, req)
      .result(this.form, id => {
        if (!this.clientId) {
          this._router.navigate(['/clients', id], { replaceUrl: true });
          return;
        }
        this.saveLoading = false;
        this.saveInitialData();
        this._setPageTitle();
        this.files = [];
        this.edit = false;
      }, error => { this.saveLoading = false; });
  }

  private _createSaveRequest() {
    const form = this.form.value;

    const req = {
      name: form.clientName,
      shortName: form.clientShortName,
      logoFileId: this.files[0]?.id || this.clientInfo?.logoFileId,
      status: form.clientStatus,
      ownerId: form.clientOwner?.id,
      countryId: form.clientCountry?.id
    };

    return req;
  }
}