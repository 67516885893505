import { Component, OnInit, OnChanges, Input, Optional } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { IClvDto } from 'src/app/cards/product/product-info/product-info.model';
import { EditableListBase } from '../editable-list-base';

@Component({
  selector: 'book-role-list',
  templateUrl: './book-role-list.component.html',
  styleUrls: ['./book-role-list.component.scss']
})
export class BookRoleListComponent extends EditableListBase implements OnInit, OnChanges {


  @Input() public bookRoleOptions: IClvDto[];

  protected get _defaultItem(): any {
    return {
      id: null,
      roleId: null,
      personal: null,
      description: null,
      isDeleted: false,
      isNew: true
    };
  }

  public table = {
    columns: ['role_column', 'personal_column', 'description_column', 'remove_column']
  }

  public get columns(): string[] {
    return this.table.columns;
  }

  constructor(@Optional() controlContainer: ControlContainer) {
    super(controlContainer);
  }

  protected _getNormalizedItem(item: any) {
    return {
      id: item.id,
      roleId: item.role?.id || item.roleId,
      personal: item.personal,
      description: item.description,
      isDeleted: item.isDeleted || false,
      isNew: item.isNew || false
    };
  }

}
