import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { HttpClient } from "@angular/common/http"
import { Observable } from "rxjs";
import { ISelectItem } from "src/app/components/form/select/select-item";
import { IUserAssistantDto } from 'src/app/models';
import { ODataResponse } from "src/app/_services";
import { IUserRow } from 'src/app/components/_partials/lxm-settings/users-table/users-table.component';
import { IUserRoleRow } from 'src/app/components/_partials/lxm-settings/roles-table/roles-table.component';
import { LxmAppModule, UserAction } from 'src/app/enum';
import { LOCAL_STORAGE_KEY, PAGE_SIZE } from "src/app/config";

@Injectable()
export class UsersResolve implements Resolve<any> {

  constructor(private _http: HttpClient) { }

  resolve(): Observable<ODataResponse<IUserRow>> {
    return this._http.get<ODataResponse<IUserRow>>("api/user/griddata", { params: { $top: PAGE_SIZE[LOCAL_STORAGE_KEY.PAGE_SIZE_USERS_TABLE], $count: 'true' } });
  }
}

@Injectable()
export class UserRolesResolve implements Resolve<any> {

  constructor(private _http: HttpClient) { }

  resolve(): Observable<ODataResponse<IUserRoleRow>> {
    return this._http.get<ODataResponse<IUserRoleRow>>("api/roles/griddata", { params: { $top: PAGE_SIZE[LOCAL_STORAGE_KEY.PAGE_SIZE_ROLES_TABLE], $count: 'true' } });
  }
}

@Injectable()
export class RolesFormDataResolve implements Resolve<any> {

  constructor(private _http: HttpClient) { }

  resolve(): Observable<IRolesFormData> {
    return this._http.get<IRolesFormData>("api/roles/formData");
  }
}


@Injectable()
export class UsersFormDataResolve implements Resolve<any> {

  constructor(private _http: HttpClient) { }

  resolve(): Observable<IUsersFormData> {
    return this._http.get<IUsersFormData>("api/user/formdata");
  }
}

export interface IRolesFormData {
  userActions: { [key: number]: IUserActionDto[]} ;
}

export interface IUserActionDto {
  userAction: UserAction;
  prerequisites: UserAction[];
}

export interface IUsersFormData {
  roles: ISelectItem[];
  locations: ISelectItem[];
  phoneCodes: IPhoneCodeItem[];
  assistants: IUserAssistantDto[];
}

export interface IPhoneCodeItem {
  id: string;
  value: string;
}