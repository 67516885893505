import { ChangeDetectorRef, Component, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { finalize } from "rxjs/operators";
import { appSettings } from "src/app/app.settings";
import { DataTableComponent } from "src/app/components";
import { DialogComponent } from "src/app/components/_layout/dialog/dialog.component";
import { asArray, ClientDiscountRuleType, Status, translate, UserAction } from "src/app/enum";
import { TranslatedValuePipe } from "src/app/pipes";
import { ContentLanguage, LxmDialog, LxmMessage } from "src/app/_helpers";
import { DataTableService } from "src/app/_services";
import { ClientsService, IClientCard, IClientFormData } from "src/app/_services/clients.service"; 
import { ClientExpiryRuleType } from "../../enum/ClientExpiryRuleType";
import { ClientExpiryRuleOperator } from "../../enum/ExpiryRuleOperator";
import { ClientState } from "../../state/client.state";

@Component({
  selector: "client-expiry-rules-card",
  templateUrl: "./expiry-rules.card.html",
  styleUrls: ["./expiry-rules.card.scss"]
})
export class ClientExpiryRulesCardComponent extends DataTableComponent<any, any> {
  @Input() public clientId;

  public clientCard: IClientCard;
  public formData: IClientFormData;

  public isClient = false;
  public userCanEdit = false;

  public expiryRules = [];

  public showAllRules = false;

  constructor(
    dataService: DataTableService<any, any>,
    cd: ChangeDetectorRef,
    private _dialog: LxmDialog,
    private _clientsService: ClientsService,
    private _route: ActivatedRoute,
    public _state: ClientState
  ) {
    super(dataService, cd);

    const { initialData } = this._route.snapshot.data;
    this.clientCard = initialData?.clientCard;
    this.expiryRules = this.clientCard?.clientExpiryRules;
    this.formData = this.clientCard?.formData;
  }

  public get canEdit() {
    return this._state.canEdit;
  }

  public toggleShowAllRules(toSet = !this.showAllRules) {
    this.showAllRules = toSet;
  }

  public get hasRules() {
    return this.expiryRules?.length > 0;
  }

  public getExpiryRules() {
    return this._clientsService
      .getClientExpiryRules(this.clientId, { $count: "true", $top: "100" })
      .result(
        null,
        res => {
          const items = res.items;
          if (items) {
            this.expiryRules = items;
          }
        },
        e => {}
      );
  }

  private _getNextAvailableOrderNr(): number {
    const numArray = this.expiryRules?.map(x => x.orderNr);
    if (numArray?.length > 0) {
      for (let i = 0; i < numArray.length; i++) {
        const nextPotentialNumber = numArray[i] + 1;
        const nextInArray = numArray[i + 1];
        if (nextPotentialNumber !== nextInArray) {
          return nextPotentialNumber;
        }
      }
    }
    return 1;
  }

  public openDialog(row?: any) {
    this._dialog.open(
      AddClientExpiryRuleDialogComponent,
      {
        ...appSettings.DIALOG_SIZES.M,
        data: {
          clientId: this.clientId,
          formData: this.formData,
          orderNext: this._getNextAvailableOrderNr(),
          rule: row,
          edit: this.canEdit
        }
      },
      res => {
        this.getExpiryRules();
      }
    );
  }
}

@Component({
  selector: "expiry-rule-row",
  templateUrl: "./expiry-rule-row.html",
  styleUrls: ["./expiry-rule-row.scss"],
  providers: [TranslatedValuePipe]
})
export class ExpiryRuleRowComponent {
  @Input() public data: any;

  public ClientExpiryRuleType = ClientExpiryRuleType;
  public ClientExpiryRuleOperator = ClientExpiryRuleOperator;
}

@Component({
  selector: "expiry-rule-dialog",
  host: { class: "dialog" },
  templateUrl: "./expiry-rule.dialog.html",
  styleUrls: ["./expiry-rule.dialog.scss"],
})
export class AddClientExpiryRuleDialogComponent {
  public saveLoading = false;
  public deleteLoading = false;

  @ViewChild("dialog", { static: true, read: DialogComponent })
  private _dialog: DialogComponent;

  public form: FormGroup;

  public edit = false;

  public statusOptions = asArray(Status);
  public typeOptions = asArray(ClientExpiryRuleType);
  public operatorOptions = asArray(ClientExpiryRuleOperator);
  public formData: IClientFormData;
  public clientId: string;

  public ClientExpiryRuleType = ClientExpiryRuleType;
  public ClientExpiryRuleOperator = ClientExpiryRuleOperator;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public dialogRef: MatDialogRef<AddClientExpiryRuleDialogComponent>,
    private _clientsService: ClientsService,
    private _lxmMessage: LxmMessage,
    private _lxmDialog: LxmDialog
  ) {
    const { clientId, formData, edit } = _data;

    this.clientId = clientId;
    this.formData = formData;
    this.edit = edit;

    const {
      type,
      orderNr,
      description,
      operator,
      conditionValue1,
      conditionValue2,
      percent,
      value,
      status,
      id
    } = _data.rule || {};

    this.form = new FormGroup({
      orderNr: new FormControl(orderNr),
      description: new FormControl(description),
      operator: new FormControl(operator),
      conditionValue1: new FormControl(conditionValue1),
      conditionValue2: new FormControl(conditionValue2),
      type: new FormControl(type || ClientExpiryRuleType.Percent),
      percent: new FormControl(percent),
      value: new FormControl(value),
      id: new FormControl(id),
      status: new FormControl(isNaN(status) ? Status.Active : status)
    });
  }

  public get ruleId() {
    return this.form.get("id").value;
  }

  public deleteRule() {
    this._lxmDialog.confirm(
      {
        title: "cards.clients.expiry_rules.dialog.delete.title",
        message: "cards.clients.expiry_rules.dialog.delete.message"
      },
      () => {
        this.deleteLoading = true;
        this._clientsService
          .deleteExpiryRule(this.clientId, this.ruleId)
          .pipe(finalize(() => {
            this.deleteLoading = false;
          }))
          .result(
            null,
            res => {
              this._lxmMessage.ok({
                message: "cards.clients.expiry_rules.dialog.delete_success"
              });
              this.closeDialog();
            },
            e => {
              // this._lxmMessage.error({
              //   message: "cards.clients.expiry_rules.dialog.delete_failed"
              // });
              console.error(e);
            }
          );
      }
    );
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public save() {
    const form = this.form.value;
    const data = {
      orderNr: form.orderNr,
      description: form.description,
      operator: form.operator,
      conditionValue1: form.conditionValue1,
      conditionValue2: form.conditionValue2,
      type: form.type,
      percent: form.percent,
      value: form.value,
      status: form.status
    };
    this.saveLoading = true;
    this._clientsService.saveExpiryRule(this.clientId, form.id, data)
      .pipe(finalize(() => {
        this.saveLoading = false;
      }))
      .result(
        this.form,
        res => {
          this._lxmMessage.ok({
            message: "cards.clients.expiry_rules.dialog.add_success"
          });
          this.closeDialog();
        },
        e => {
          // this._lxmMessage.error({ message: e.validationSummary });
        }
      );
  }
}
