<card-expanding [formGroup]="form" [valid]="productValidity.getCardStatusValidType(cardSection)" [expanded]="isExpanded" (onExpand)="saveLocalExpandedValue($event)">
  <span header-title>{{ title | translate}}</span>

  <div header-actions *requiredRights="[UserAction.ManageProducts]">
    <ng-container *ngIf="!locked">
      <lxm-button *ngIf="!edit" class="ml15" type="beige" icon="edit" title="action.edit" (click)="toggleEdit()">
      </lxm-button>
      <lxm-button *ngIf="edit" class="ml15" type="grey" icon="back" label="action.cancel" (click)="toggleEdit()">
      </lxm-button>
      <lxm-button *ngIf="edit" class="ml15" type="beige" icon="save" label="action.save" [loading]="saveLoading"
        (click)="save()" buttonType="submit"></lxm-button>
    </ng-container>
    <ng-container *ngIf="locked">
      {{ 'global.locked_by' | translate }}: {{ lockedBy.name }} {{ lockedAt |
      amTimeAgo }}
    </ng-container>
  </div>

  <div body>
    <card-section [ngSwitch]="showContent">
      <div class="row f-1" *ngSwitchCase="false">
        <no-content image="no-custom" text="cards.products.product_description_fields.no_content" [inline]="true" style="margin: 0;"></no-content>
      </div>
      <div class="row" *ngSwitchDefault>
        <div class="col-12 no-padding">
          <form-field  *ngIf="!edit ? (form.value.tags?.length > 0 ? true : false) : true" [readonly]="!edit" label="cards.products.product_description_fields.label.tags" for="tags" description="cards.products.product_description_fields.label_description.tags">
            <lxm-select select for="tags"
              class="w100p"
              labelField="value"
              compareField="id"
              [readonly]="!edit"
              [options]="tagsOptions"
              [searchable]="true"
              [multiple]="true">
              <lxm-button 
                *requiredRights="[UserAction.ManageClvs]" 
                header
                label="action.add_new" 
                icon="plus-dark"
                (click)="openClassificatorValueDialog(ClvType.ProductTag, form.get('tags'), 'tagsOptions', true)">
              </lxm-button>
            </lxm-select>
            <validation-errors for="tags"></validation-errors>
          </form-field>

          <form-field for="parameter1"
            type="textarea"
            *ngIf="showParameter(productField.ExtraParameter1)"
            [readonly]="!edit"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.ExtraParameter1, form.get('parameter1').value) : null"
            label="cards.products.product_description_fields.label.description_field_1"
            [labelAlt]="getProductFieldLabel(productField.ExtraParameter1)"
            description="cards.products.product_description_fields.label_description.description_field"
            maxlength="2500">
            <validation-errors for="parameter1"></validation-errors>
          </form-field>

          <form-field for="parameter2"
            type="textarea"
            *ngIf="showParameter(productField.ExtraParameter2)"
            [readonly]="!edit"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.ExtraParameter2, form.get('parameter2').value) : null"
            label="cards.products.product_description_fields.label.description_field_2"
            [labelAlt]="getProductFieldLabel(productField.ExtraParameter2)"
            description="cards.products.product_description_fields.label_description.description_field"
            maxlength="2500">
            <validation-errors for="parameter2"></validation-errors>
          </form-field>

          <form-field for="parameter3"
            type="textarea"
            *ngIf="showParameter(productField.ExtraParameter3)"
            [readonly]="!edit"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.ExtraParameter3, form.get('parameter3').value) : null"
            label="cards.products.product_description_fields.label.description_field_3"
            [labelAlt]="getProductFieldLabel(productField.ExtraParameter3)"
            description="cards.products.product_description_fields.label_description.description_field"
            maxlength="2500">
            <validation-errors for="parameter3"></validation-errors>
          </form-field>

          <form-field for="parameter4"
            type="textarea"
            *ngIf="showParameter(productField.ExtraParameter4)"
            [readonly]="!edit"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.ExtraParameter4, form.get('parameter4').value) : null"
            label="cards.products.product_description_fields.label.description_field_4"
            [labelAlt]="getProductFieldLabel(productField.ExtraParameter4)"
            description="cards.products.product_description_fields.label_description.description_field"
            maxlength="2500">
            <validation-errors for="parameter4"></validation-errors>
          </form-field>

          <form-field for="parameter5"
            type="textarea"
            *ngIf="showParameter(productField.ExtraParameter5)"
            [readonly]="!edit"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.ExtraParameter5, form.get('parameter5').value) : null"
            label="cards.products.product_description_fields.label.description_field_5"
            [labelAlt]="getProductFieldLabel(productField.ExtraParameter5)"
            description="cards.products.product_description_fields.label_description.description_field"
            maxlength="2500">
            <validation-errors for="parameter5"></validation-errors>
          </form-field>

          <form-field for="parameter6"
            type="textarea"
            *ngIf="showParameter(productField.ExtraParameter6)"
            [readonly]="!edit"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.ExtraParameter6, form.get('parameter6').value) : null"
            label="cards.products.product_description_fields.label.description_field_6"
            [labelAlt]="getProductFieldLabel(productField.ExtraParameter6)"
            description="cards.products.product_description_fields.label_description.description_field"
            maxlength="2500">
            <validation-errors for="parameter6"></validation-errors>
          </form-field>

          <form-field for="parameter7"
            type="textarea"
            *ngIf="showParameter(productField.ExtraParameter7)"
            [readonly]="!edit"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.ExtraParameter7, form.get('parameter7').value) : null"
            label="cards.products.product_description_fields.label.description_field_7"
            [labelAlt]="getProductFieldLabel(productField.ExtraParameter7)"
            description="cards.products.product_description_fields.label_description.description_field"
            maxlength="2500">
            <validation-errors for="parameter7"></validation-errors>
          </form-field>

          <form-field for="parameter8"
            type="textarea"
            *ngIf="showParameter(productField.ExtraParameter8)"
            [readonly]="!edit"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.ExtraParameter8, form.get('parameter8').value) : null"
            label="cards.products.product_description_fields.label.description_field_8"
            [labelAlt]="getProductFieldLabel(productField.ExtraParameter8)"
            description="cards.products.product_description_fields.label_description.description_field"
            maxlength="2500">
            <validation-errors for="parameter8"></validation-errors>
          </form-field>

          <form-field for="parameter9"
            type="textarea"
            *ngIf="showParameter(productField.ExtraParameter9)"
            [readonly]="!edit"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.ExtraParameter9, form.get('parameter9').value) : null"
            label="cards.products.product_description_fields.label.description_field_9"
            [labelAlt]="getProductFieldLabel(productField.ExtraParameter9)"
            description="cards.products.product_description_fields.label_description.description_field"
            maxlength="2500">
            <validation-errors for="parameter9"></validation-errors>
          </form-field>

          <form-field for="parameter10"
            type="textarea"
            *ngIf="showParameter(productField.ExtraParameter10)"
            [readonly]="!edit"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.ExtraParameter10, form.get('parameter10').value) : null"
            label="cards.products.product_description_fields.label.description_field_10"
            [labelAlt]="getProductFieldLabel(productField.ExtraParameter10)"
            description="cards.products.product_description_fields.label_description.description_field"
            maxlength="2500">
            <validation-errors for="parameter10"></validation-errors>
          </form-field>
        </div>
      </div>
    </card-section>
  </div>
</card-expanding>