import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { DataTableService } from 'src/app/_services';
import { ClvTableComponent } from '../clv-table.component';

@Component({
  selector: "lxm-clv-table",
  templateUrl: './lxm-clv-table.component.html',
  styleUrls: ['../clv-table.component.scss']
})
export class LxmClvTableComponent extends ClvTableComponent {

  @Input()
  public title: string;

  constructor(
    dataService: DataTableService<any, any>,
    cd: ChangeDetectorRef,
  ) {
    super(dataService, cd);
  }

  protected colPropertyMap = {
    'country_iso': 'iso',
    'country_iso3': 'iso3',
    'country_name': 'name'
  };
}
