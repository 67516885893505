import { Component } from "@angular/core";

@Component({
  selector: "product-fields-settings-layout",
  templateUrl: "./product-fields-settings.layout.html",
  host: { 'class': 'content' },
  styleUrls: ["./product-fields-settings.layout.scss"]
})
export class ProductFieldsSettingsLayout {

}

@Component({
  selector: "extra-parameters-settings-layout",
  templateUrl: "./extra-parameters-settings/extra-parameters-settings.layout.html",
  host: { 'class': 'tab-content' },
  styleUrls: ["./extra-parameters-settings/extra-parameters-settings.layout.scss"]
})
export class ExtraParametersSettingsLayout {

}