<card>
  <span header-title>{{'cards.dashboard_welcome.title' | translate}}</span>
  <section header-actions *nonVisitor>
    <a class="card-header-action" routerLink="/user-settings"
      >{{'cards.dashboard_welcome.action' | translate}}</a
    >
  </section>
  <section body>
    <div class="flex flex-row">
      <logo-block [src]="logoSrc"></logo-block>
      <div class="welcome-info f-1 mt5">
        <div class="welcome-info-user mb25">
          <span class="welcome-info-user-name mr10">
            {{ data.fullName }}
          </span>
          <ng-container *nonVisitor>
            <span
              class="welcome-info-user-role comma-delimited-list"
              [class.mt5]="data.locations?.length > 0"
            >
              <span
                *ngFor="let location of data.locations | slice:0:userLocationsRenderCount"
                >{{ location }}</span
              >
              <span
                class="list-overflow-count"
                *ngIf="data.locations?.length > userLocationsRenderBaseCount"
                (click)="toggleAllUserLocations()"
                [class.show-less]="userLocationsRenderCount > userLocationsRenderBaseCount"
              >
                {{ userLocationsRenderCount > userLocationsRenderBaseCount ? '-'
                : '+' }} ({{data.locations.length -
                userLocationsRenderBaseCount}})
              </span>
            </span>
            <span
              class="welcome-info-user-role comma-delimited-list"
              [class.mt5]="data.locations?.length > 0"
            >
              <span *ngFor="let role of data.roles">{{ role }}</span>
            </span>
          </ng-container>
        </div>
        <div class="flex f-1 flex-col">
          <span class="welcome-info-company mb12">
            {{ data.companyName }}
          </span>
          <span class="welcome-info-text-faded">
            <span
              class="welcome-info-text-faded flex flex-row f-1 justify-between flex-wrap mb5"
            >
              <span class="flex align-center mr5" style="width:50px"
                >{{'cards.dashboard_welcome.e_mail' | translate}}:</span
              >
              <a class="flex align-center f-1" href="mailto:{{data.email}}"
                >{{ data.email }}</a
              >
            </span>
            <span
              class="welcome-info-text-faded flex flex-row f-1 align-center justify-between flex-wrap"
            >
              <span class="flex align-center" style="width:50px"
                >{{'cards.dashboard_welcome.phone_short' | translate}}:</span
              >
              <span class="flex align-center f-1 ml5">
                <phone-number
                  [code]="phoneCode?.value"
                  [number]="phoneNumberShort"
                ></phone-number>
              </span>
            </span>
          </span>
        </div>
      </div>
    </div>
  </section>
</card>
