import { Component, OnDestroy, HostBinding  } from "@angular/core";

import {
  Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  GuardsCheckEnd,
  ResolveStart
} from '@angular/router'
import { NavigatorState } from 'src/app/state/navigator.state';

@Component({
  selector: "navigation-spinner",
  templateUrl: "./navigation-spinner.component.html",
  styleUrls: ['./navigation-spinner.scss']
})
export class NavigationSpinnerComponent implements OnDestroy {

  @HostBinding('class.show') 
  public showOverlay = false;

  private _routeListener;

  constructor(private router: Router, public navigator: NavigatorState) {
    this._routeListener = this.router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    })
  }

  @HostBinding('class.navigator-collapsed') public get navigatorCollapsed() {
    return this.navigator.collapsed;
  }

  public ngOnDestroy() {
    this._routeListener.unsubscribe();
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof ResolveStart) {
      this.showOverlay = true;
    }
    if (event instanceof NavigationEnd) {
      setTimeout(_ => {
        this.showOverlay = false;
      }, 50)
    }
    if (event instanceof NavigationCancel) {
      this.showOverlay = false;
    }
    if (event instanceof NavigationError) {
      this.showOverlay = false;
    }
  }

}
