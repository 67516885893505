import { Component, Inject, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IAttachment } from 'src/app/models';
import { MatTableDataSource } from '@angular/material/table';
import { FileService } from 'src/app/_services';
import { HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LxmMessage } from 'src/app/_helpers';

@Component({
  selector: 'add-file-dialog',
  host: { 'class': 'dialog' },
  templateUrl: './add-file.dialog.html'
})
export class AddFileDialog {

  public form = new FormGroup({
    name: new FormControl(this._data.attachment?.name),
    description: new FormControl(this._data.attachment?.description),
    uploadDate: new FormControl(this._data.attachment?.uploadedAt)
  });

  @Input() public files: any = [];
  public uploadInProgress = false;
  public uploadingFile = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: IAddFileDialogData,
    public dialogRef: MatDialogRef<AddFileDialog>,
    private _fileService: FileService,
    private _msg: LxmMessage
  ) {
    if (this._data.attachment) {
      this.files.push({
        id: this._data.attachment.fileId,
        name: this._data.attachment.fileName,
      });
    }

  }

  public uploadFile(fileList: FileList) {
    const form = this.form;
    for (let index = 0; index < fileList.length; index++) {
      const element = fileList[index];

      const file = {
        id: '',
        name: element.name,
        progress: 0
      };
      this.files.push(file);

      const formData = new FormData();
      formData.append('file', element);
      this._fileService.upload(formData)
        .subscribe(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round(100 * event.loaded / event.total);
              this.uploadInProgress = true;
              break;
            case HttpEventType.Response:
              file.id = event.body;
              this.uploadInProgress = false;
              break;
          }
        });
    }
    if (!form.get('name').value) {
      form.get('name').setValue(this.files[0].name);
    }
  }

  public deleteAttachment(index: number) {
    event.stopImmediatePropagation();
    this._fileService.delete(this.files[index].id).subscribe(res => {
      this.files?.splice(index, 1);
    });
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public save() {

    const data = {
      id: this._data.attachment?.id,
      name: this.form.get('name').value,
      description: this.form.get('description').value,
      fileId: this.files[0]?.id,
      fileName: this.files[0]?.name,
      uploadedAt: this._data.attachment?.uploadedAt || new Date(),
    };
    this.uploadingFile = true;

    const { fileId } = this._data.attachment || {};

    if (fileId) {
      this._data.save(data)
        .pipe(
          finalize(() => {
            this.uploadingFile = false;
          }
        ))
        .result(this.form, res => {
          
          this._msg.ok({
            message: 'cards.products.product_additional_info_and_instructions.attachments.save_success'
          });

          const item = this._data.dataTableRef.data.find(x => x.fileId === this._data.attachment.fileId);
          Object.assign(item, res);
          this.uploadingFile = false;
          this.dialogRef.close();
        }, err => {
          this._msg.error({
            message: 'cards.products.product_additional_info_and_instructions.attachments.save_failed'
          });
        });
    } else {
      this._data.add(data)
        .pipe(
          finalize(() => {
            this.uploadingFile = false;
          }
        ))
        .result(this.form, res => {
          
          this._msg.ok({
            message: 'cards.products.product_additional_info_and_instructions.attachments.add_success'
          });

          this._data.dataTableRef.data.push(res);
          this._data.dataTableRef._updateChangeSubscription();

          this.uploadingFile = false;
          this.dialogRef.close();
        }, err => {
          this._msg.error({
            message: 'cards.products.product_additional_info_and_instructions.attachments.add_failed'
          });
        });
    }
  }
}

export interface IAddFileDialogData {
  save: (data: IAttachment) => Observable<any>;
  add: (data: IAttachment) => Observable<any>;
  attachment: IAttachment;
  dataTableRef: MatTableDataSource<any>;
}
