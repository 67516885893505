import { Component, Inject, Optional } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { stringIsNullOrEmpty } from 'src/app/util/fn';
import { Guid } from 'src/app/util/Guid';

@Component({
  selector: "offer-product-mass-changes-dialog",
  host: { 'class': 'dialog' },
  templateUrl: './offer-product-mass-changes.dialog.html',
  styleUrls: ['./offer-product-mass-changes.dialog.scss']
})
export class OfferProductMassChangesDialogComponent {

  public form: FormGroup;
  public readonly = false;

  public products: any[];

  public attributeOptions = [];
  public segmentOptions = [];

  public showOtherSegments = false;

  public visibleParameters: string[];

  constructor(
    public dialogRef: MatDialogRef<OfferProductMassChangesDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.readonly = data.readonly;
    this.products = data.products;

    this.attributeOptions = data.attributes;
    this.segmentOptions = data.segmentTreeOptions;

    this.form = new FormGroup({
      segment: new FormControl(),
      tags: new FormControl(),
      attributes: new FormControl(),
      parameter1: new FormControl(),
      parameter2: new FormControl(),
      parameter3: new FormControl(),
      parameter4: new FormControl(),
      parameter5: new FormControl(),
      parameter6: new FormControl(),
      parameter7: new FormControl(),
      parameter8: new FormControl(),
      parameter9: new FormControl(),
      parameter10: new FormControl()
    });

    this.visibleParameters = this.getVisibleParameters();
  }

  public productGs1SegmentId = () => {
    let res = [];
    return res;
  }

  public disableSegmentTreeOption(i) {
    return !i.canAssignToProduct;
  }

  public segmentTreeLabel(i) {
    return `${'--'.repeat(i.level)} ${i.code} ${i.name}`.trim();
  }

  public get isAddParameterButtonVisible() {
    const parameterFieldNames = this.getParameterFieldNames();
    return this.visibleParameters.length < parameterFieldNames.length;
  }

  public isParameterVisible(formFieldName: string): boolean {
    return this.visibleParameters.indexOf(formFieldName) > -1;
  }

  private getParameterFieldNames(): string[] {
    const formValue = this.form.value;
    return Object.keys(formValue)
      .filter(key => key.startsWith("parameter"));
  }
  
  public addParameter(): void {
    const parameterFieldNames = this.getParameterFieldNames();
    for (const name of parameterFieldNames) {
      if (!this.isParameterVisible(name)) {
        this.visibleParameters.push(name);
        break;
      }
    }
  }

  private getVisibleParameters(): string[] {
    const formValue = this.form.value;
    return Object.entries(formValue)
      .filter(([key, value]) => key.startsWith("parameter") && !stringIsNullOrEmpty(value))
      .map(([key]) => key);
  }

  private _saveValues() {
    const { tags, attributes } = this.form.value || {};

    this.products.forEach(x => {
      let details = x.retailerDetails;

      if (tags?.length) {
        details.tags = tags;
      }

      if (attributes?.length) {
        details.attributes = attributes;
      }

      for(let i = 0; i < 10; i++) {
        const paramName = `parameter${i}`;
        const formValue = this.form.value[paramName];
        if (formValue) {
          details[paramName] = formValue
        }
      }
    })
  }

  public save(): void {

    this.closeDialog(this.form.value);
  }

  public closeDialog(data?: any): void {
    this.dialogRef.close(data);
  }
}
