import { Component, Inject, ViewChild } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UserService } from "src/app/_services/user.service";
import { IUsersFormData } from "src/app/resolve/lxm-settings/users.resolve";
import { IUserAssistantDto } from 'src/app/models';
import { DialogComponent } from 'src/app/components';
import { finalize } from 'rxjs/operators';
import { LxmMessage } from 'src/app/_helpers';

@Component({
  selector: "invite-user-dialog",
  host: { 'class': 'dialog' },
  templateUrl: "./invite.dialog.html",
  styleUrls: []
})
export class InviteUserDialog {

  public isSending = false;
  public cancelInviteInProgress = false;
  public saveInProgress = false;

  public isPending;

  public assistantsOptions: IUserAssistantDto[] = [];
  public form: FormGroup;

  @ViewChild('dialog', { static: true, read: DialogComponent })
  private _dialog: DialogComponent;

  constructor(
    private _message: LxmMessage,
    private _userService: UserService,
    public dialogRef: MatDialogRef<InviteUserDialog>,
    @Inject(MAT_DIALOG_DATA) public data: IUserDialogData) {

    this.isPending = !!data.user;

    this.form = new FormGroup({
      email: new FormControl(data.user?.email),
      jobTitle: new FormControl(),
      roles: new FormControl(),
      assistants: new FormControl(),
    });
  }

  public ngAfterViewInit(): void {

    this._dialog.dataLoaded$
      .subscribe(state => {
        if (!state) {
          return;
        }

        this.assistantsOptions = this._formatAssistantsOptions(this.data.formData.assistants);

        if (this.data.user) {
          this.form.patchValue({
            jobTitle: this.data.user.jobTitle,
            roles: this.data.user.roles?.map(x => x.roleId),
            assistants: this.data.user.assistants?.map(x => x.id)
          });
        }

      });
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public sendInvite(): void {

    this.isSending = true;

    let f = this.form.value;
    let req = {
      userId: this.data.user?.id,
      email: f.email,
      jobTitle: f.jobTitle,
      roleIds: f.roles,
      assistantIds: f.assistants
    };

    this._userService
      .invite(req)
      .pipe(
        finalize(() => {
          this.isSending = false;
        }
      ))
      .result(this.form, () => {
        this._message.ok({
          message: 'cards.lexi_market_settings.lxm_settings_user_invite.message.invitation_sent'
        });
        this.data.usersCard.reload();
        this.dialogRef.close(true);
      }, _ => {
        this._message.error({
          message: 'cards.lexi_market_settings.lxm_settings_user_invite.message.sending_invitation_failed'
        });
      });
  }

  public save(): void {

    this.saveInProgress = true;

    let f = this.form.value;
    let req = {
      jobTitle: f.jobTitle,
      roleIds: f.roles,
      assistantIds: f.assistants
    };

    this._userService.updateInvite(this.data.user?.id, req)
      .pipe(finalize(() => {
        this.saveInProgress = false
      }))
      .result(this.form, () => {
        this._message.ok({
          message: 'cards.lexi_market_settings.lxm_settings_user_invite.message.invite_updated'
        });
        this.data.usersCard.reload();
        this.dialogRef.close(true);
      }, _ => {
        this._message.error({
          message: 'cards.lexi_market_settings.lxm_settings_user_invite.message.update_failed'
        });
      });

  }

  public delete(): void {

    this.cancelInviteInProgress = true;
    this._userService.cancelInvite(this.data.user?.id)
      .pipe(finalize(() => {
        this.cancelInviteInProgress = false
      }))
      .result(null, () => {
        
        this._message.ok({
          message: 'cards.lexi_market_settings.lxm_settings_user_invite.message.invitation_cancelled'
        });

        this.data.usersCard.reload();
        this.dialogRef.close(true);
      }, _ => {
        
        this._message.error({
          message: 'cards.lexi_market_settings.lxm_settings_user_invite.message.cancelling_invitation_failed'
        });
      });

  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  private _formatAssistantsOptions(options: any[]) {
    if (!options) {
      return [];
    }
    return options.map(x => Object.assign({}, x, { label: x.fullName ? `${x.fullName} (${x.email})` : x.email }));
  }
}

interface IUserDialogData {
  formData: IUsersFormData,
  user: any,
  usersCard: any
}