<div class="row overflow-x" [formGroup]="fgInternal" *ngIf="hasData">
  <mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="trackNo_column">
      <mat-header-cell *matHeaderCellDef>
        <span>
          {{'cards.products.product_music_info.track_section.trackNo' | translate}}
        </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
        <div class="flex f-1" style="position: relative;">
          <lxm-input style="width: 100%;" for="trackNo" [id]="'trackNo_' + i" [readonly]="readonly" placeholder="N.N"
            pattern="[1-9]+\.[1-9]+">
            <validation-errors [for]="'tracks['+(i)+'].trackNo'" aliasTo="trackNo" [errorsFormGroup]="formGroup" [showErrorText]="false">
            </validation-errors>
          </lxm-input>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="title_column">
      <mat-header-cell *matHeaderCellDef>
        <span>
          {{'cards.products.product_music_info.track_section.title' | translate}}
        </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
        <div class="flex f-1" style="position: relative;">
          <lxm-input style="width: 100%;" for="title" [id]="'title_' + i" [readonly]="readonly">
            <validation-errors [for]="'tracks['+(i)+'].title'" aliasTo="title" [errorsFormGroup]="formGroup">
            </validation-errors>
          </lxm-input>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="authors_column">
      <mat-header-cell *matHeaderCellDef>
        <span>
          {{'cards.products.product_music_info.track_section.authors' | translate}}
        </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
        <div class="flex f-1" style="position: relative;">
          <lxm-input style="width: 100%;" for="authors" [id]="'authors_' + i" [readonly]="readonly">
            <validation-errors [for]="'tracks['+(i)+'].authors'" aliasTo="authors" [errorsFormGroup]="formGroup">
            </validation-errors>
          </lxm-input>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="duration_column">
      <mat-header-cell *matHeaderCellDef>
        <span>
          {{'cards.products.product_music_info.track_section.duration' | translate}}
        </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
        <div class="flex f-1" style="position: relative;">
          <lxm-input style="width: 100%;" for="duration" [id]="'Duration_' + i" [readonly]="readonly"
            placeholder="MM:SS" pattern="[0-9]+:[0-9]+">
            <validation-errors [for]="'tracks['+(i)+'].duration'" aliasTo="duration" [errorsFormGroup]="formGroup" [showErrorText]="false">
            </validation-errors>
          </lxm-input>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="remove_column">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
        <lxm-button *ngIf="!readonly" type="transparent" icon="trash" title="action.remove" padding="8px 0"
          (click)="removeItem(i)"></lxm-button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="table.columns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: table.columns"
      [ngClass]="{'row-to-delete readonly' : row.get('isDeleted').value}"></mat-row>
  </mat-table>

</div>

<div class="row f-1" *ngIf="!hasData" style="padding: 8px;">
  <no-content image="no-music-tracks" text="cards.products.product_music_info.track_section.no_tracks" [inline]="true" style="margin: 0;"></no-content>
</div>

<div class="row mt10" *ngIf="!readonly">
  <lxm-button type="transparent" icon="plus-dark" label="cards.products.product_music_info.track_section.add_new" (click)="addItem()"></lxm-button>
</div>