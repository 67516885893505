export const RETAILER_ID = {
    A1000: '1eca51d2-a1db-46f9-8e90-b745be3f3cb2',
    ALEXELA: 'ce8a9060-380d-4c9d-82ea-a87a733b3a48',
    KODUEKSTRA: '055d7480-2b84-4742-b056-c5cca7f0f9ff',
    KTY: '3381c7bc-e832-44ba-a897-3d7fd9715ea7',
    PRISMA: 'cf41fbf3-9aa6-4d9e-80f2-669d6781be91',
    SELVER: '171f1e6b-9e95-4f60-84bd-bb3e6c201b10',
    STOCKMANN: 'df9709fc-9dda-4f14-90fa-521323fc0e94',
    RKIOSK: '08cbf787-dbac-4a11-8a79-89660ab64ac7',
    TALUTURG: '40cb7929-0e42-444c-92b3-db82e870422f',
    BIOMARKET: 'a306ac2d-bd18-4ff8-8aa3-28fe00f19f03',
    ALDAR: 'd5211b17-6a17-4d9d-914a-74b2be552bdd',
    GROSS: 'ec37454f-d13e-495c-8080-acf9010ed471',
    KAUBAMAJA: '4cdea29b-1054-4389-a811-f97c408a64f5',
    RRLEKTUS: '8769d1e0-f958-495c-b8d4-23b4eabb0ade',
    OLEREX: '83cc18b8-4645-48d7-9ea9-ed13fb60f3aa',
    VESKI_MARKET: 'afda6d49-1d5e-41fa-9be8-5c9f70adf40c',
    TALU_TOIDAB: 'a3524af1-49f6-4544-8dec-7ac4d6a76c57',
    DECORA: '2be4780c-fca3-4ba2-a237-2bc63d74a9a1',
    CIRCLE_K: '828e01df-647e-4683-ab4f-b2fa3521e860',
    KAUPMEES: '7821f791-f773-4b14-870e-0626d6ef754e',
    CLEAN_KITCHEN: 'a9e37b15-732f-452e-92af-709637bfe27d',
    JAAGUMAE_KAUBANDUS: '5479dd38-d96b-4355-add9-37c45c04a77e',
    PROMO_CC: '8f96212f-367b-4da3-af0e-cba1d2fd78dd',
    RÕNGU_PAGAR: 'eae8fa13-01eb-43e2-8037-c49dcd5786d5',
    BOLT_MARKET: '7642f125-6841-42be-b636-e62863170c9b',
    WOLT_MARKET: 'd7813084-fb0a-43ff-8a6f-c7433a8649d8',
    HORECA: '83f1aba3-d929-4bb6-a854-a8511b895119',
    PINUS: '92267d3c-92bd-4cb2-b7b9-f7363b734ab3',
    BALTIC_RESTAURANTS_ESTONIA: '1b32c43e-0f72-4dba-a577-710c06278808',
    FRUIT_XPRESS: 'f0c1378d-c56d-4bb1-8496-51cdc8799e4f',
    CAMPY: '4f89455f-7a8d-496d-b079-8a5d154b3d76',
    KASTANI_POOR_AARDEMAA: 'b5a1db50-648b-449e-8468-8b0a1f774a32',
    SIP_SHOP: '00cd6c29-dd53-4c1b-beaf-7373080614a4',
    HEPA: '678cb6bc-e6d3-4af8-a75e-821c70161044',
    RADISSON: '2ab60a51-107a-4120-9200-11f236dfe154',
    SYDAMEAPTEEK: 'c3d6b2c5-54f8-4fb2-9030-f09557c2e083',
    FOOD_EXPERT: '303c5f6b-f92e-43ce-ae7b-4b9f38f55fc8',
    SUMENA: 'b0b6c316-2205-45fb-817c-23cfdea57460',
    TYDRUKUD: 'd8165df7-688d-461c-bb26-5c4cbb7d46f2',
    TARTU_TERMINAL: '8a5b5327-c2e5-4d96-83fd-d4de9f392572',
    TALLINK: '9dbcc589-3cb9-4fa8-9300-56b135162e48',

    MAXIMA: 'a2e26d60-5f64-4a8c-b2b4-7fe24a39379f',
    MAXIMA_LV: '45030d4d-094c-41d0-89fb-af23028134cf',
    MAXIMA_LT: 'f1de089c-3a55-40d6-9d1e-d411c4c58ea9',

    RIMI_EST: 'ead92bd9-3758-4d1a-9f73-998e0a4e6edd',
    RIMI_LV: '7d85cf9c-78b6-4244-b20b-9ba0a26b09f1',
    RIMI_LT: '4c998a7e-116c-4cf6-9366-c0def8009515',

    COOP_EST: 'd591b1ef-3b21-40a2-8f9b-8d44a13208c8',
    CoopEstHiiumaaTÜ: '15d33169-26a8-4a37-81ce-8ea71c3ed3c9',
    CoopEstSaaremaaTÜ: 'b5d440ac-4963-4db3-8bc5-f715298f138d',
    CoopEstHaapsaluTÜ: '81325d08-e5d9-490b-8b72-e17d37c93f2f',
    CoopEstLihulaTÜ: '41de3527-579c-482b-8d47-93317fb4423b',
    CoopEstHarjuTÜ: '375339ae-1d2d-414e-9e30-7678078d6874',
    CoopEstJärvaTÜ: 'ef185194-bc55-4112-ab0b-4658774c0a16',
    CoopEstRaplaTÜ: '89286a55-82c7-4454-903d-8973ae02f038',
    CoopEstVändraTÜ: '0f62d193-2ba1-4b51-b109-aaee1a0fa2a7',
    CoopEstPõltsamaaMÜ: 'd4de7806-c15e-4db3-bd0a-ecbc3fd4cd84',
    CoopEstJõgevaMÜ: '8a9793c6-c897-4c4b-870c-a22fde661679',
    CoopEstViljandiTÜ: 'b32648db-758b-4921-98f2-9f33d2f0a194',
    CoopEstTartuTK: '7b670fb1-8723-40c8-ab18-08a9ed12504a',
    CoopEstKilingiNõmmeMÜ: '2504b7f1-fdf7-4773-9b07-9e403088e66d',
    CoopEstAbjaTÜ: 'bbc9993e-eec0-4e13-aee5-d10f89cd749c',
    CoopEstElvaTÜ: '211616ba-5fb3-4217-be7d-ede5ffb03db3',
    CoopEstPõlvaTÜ: '0fc25cc0-25bd-4c53-a52f-b8cb10159ff5',
    CoopEstTõrvaTÜ: '5914c843-8243-4266-a535-b6f97357dbb6',
    CoopEstAntslaTÜ: '281e2458-3a07-49c2-b739-0c1552c090ee',
    CoopEstVõruTÜ: 'f826cc6f-8755-4345-b75f-e922b8d22921',

    // Currently disabled
    FOODBIT: '50781f37-a5e8-4a6b-b36e-5476e8138131'
}