import { Component, Input, Optional, Host, OnInit, EventEmitter, Output, HostBinding } from '@angular/core';
import { FormGroup, ControlContainer } from '@angular/forms';

@Component({
  selector: "lxm-datepicker",
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class LxmDatepickerComponent implements OnInit {

  @HostBinding('class.readonly')
  @Input() public readonly = false;

  @HostBinding('class.disabled')
  @Input() public disabled = false;

  @Input() public formGroup: FormGroup;
  @Input() public for: string;
  @Input() public width = '100%';
  @Input() public min: any;
  @Input() public max: any;
  @Input() public offset = null;
  @Input() public format = 'hh:mm';
  @Input() public inputId: string;
  @Input() public placeholder: string;

  @Output() blur: EventEmitter<any> = new EventEmitter();

  constructor(@Optional() @Host() private _controlContainer: ControlContainer) {}

  public ngOnInit(): void {
    if (this._controlContainer && this._controlContainer.control instanceof FormGroup) {
      this.formGroup = <FormGroup>this._controlContainer.control;
    }
  }

}
