import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { DialogComponent } from 'src/app/components';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: "change-requested-dialog",
  host: { class: "dialog lexi-dialog" },
  templateUrl: "./change-requested.dialog.html",
  styleUrls: ["./change-requested.dialog.scss"]
})
export class SubscriptionChangeRequestedComponent implements AfterViewInit {
  @ViewChild("dialog", { static: true, read: DialogComponent })
  private _dialog: DialogComponent;

  constructor(
    public dialogRef: MatDialogRef<SubscriptionChangeRequestedComponent>
  ) {}

  public ngAfterViewInit(): void {}

  public closeDialog() {
    this.dialogRef.close();
  }
}
