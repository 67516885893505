<lxm-dialog [dialogRef]="dialogRef" [readonly]="!canEdit" #dialog>
    <span header-title>
      {{ 'Lisa tagid' | translate }}
    </span>

    <div body style="padding-bottom: 40px;">
      <div class="tag-container" *ngFor="let tag of tags" (click)="selectTag(tag)">
        <lxm-checkbox class="tag-action" [checked]="tag.checked"></lxm-checkbox>
        <div class="tag-content ml10">
          <color-box [color]="tag.color" class="size-xs mr10"></color-box>
          {{tag.code}} &nbsp; {{tag.name}}
        </div>
      </div>
    </div>

    <div footer>
      <lxm-button type="primary" *ngIf="canEdit" label="cards.settings.product_management_settings.segments_tree_edit.action.save" (click)="save()" [loading]="actionLoading.save" buttonType="submit"></lxm-button>
      <lxm-button type="secondary" class="ml15" label="cards.settings.product_management_settings.segments_tree_edit.action.cancel" (click)="closeDialog()"></lxm-button>
    </div>

</lxm-dialog>
