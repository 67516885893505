export enum OfferChangeType {
  StatusChange = 1,
  Created = 2,
  Saved = 3,
  SentToRetailer = 4,
  SentToSupplier = 5,
  Proposal = 6,
  RetailerChangedOwner = 7,
  OwnerChanged = 8,
  RequestedByRetailer = 9,
  InitialRequest = 10
}

(OfferChangeType as any).__typeName = 'OfferChangeType';
