import { Input, Output, EventEmitter, Directive, Inject, forwardRef } from "@angular/core";
import { MatColumnDef } from "@angular/material/table";
import { FilterType } from "src/app/enum/filter-type";

@Directive()
export abstract class DataTableFilter {

  private _onChange = new EventEmitter<string>();

  @Output()
  public onChange = this._onChange.asObservable();

  constructor(
    @Inject(forwardRef(() => MatColumnDef)) private _columnDef: MatColumnDef) {
  }

  getFilterKey(): string {
    return this._columnDef.name;
  }

  public abstract getFilterType(): FilterType;

  public abstract getValue(): string;

  @Input()
  public onValueChanged(val: string) {
    this._onChange.emit(val);
  };
}
