import { Component, Input, HostBinding } from "@angular/core";
import { label, registry } from "src/app/enum";
import { EnumType } from "typescript";

@Component({
  selector: "status-indicator",
  templateUrl: "./status-indicator.component.html",
  styleUrls: ['./status-indicator.component.scss']
})
export class StatusIndicatorComponent {

  @Input() public status: number = 0;
  @Input() public range: number = 4;
  @Input() public position: string = 'left';
  @Input() public description: string;
  @Input() public template: string;
  @Input() public enum: string;

  @HostBinding('class') public get indicatorRange() { return 'r' + this.range }

  public get statusEnum() {
    return registry[this.enum];
  }

  public get progress() {
    if (this.enum) { 
      return label(this.statusEnum, this.status);
    }
    return this.status;
  }

}
