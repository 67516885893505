<div class="category-color-wrapper" [popper]="colorPicker" popperTrigger="click"
popperPlacement="bottom-start"
[popperShowOnStart]="false" popperApplyClass="stripped" popperApplyArrowClass="hidden">
    <color-box [color]="fgValue"></color-box>
    <popper-content #colorPicker>
        <div class="category-color-picker">
            <color-box
                class="mr10 clickable"
                *ngFor="let category of categoriesMap"
                [color]="category.color"
                [class.active]="fgValue === category.color"
                (click)="selectColor(category.color)">
            </color-box>
        </div>
    </popper-content>
</div>