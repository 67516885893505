import { Component, OnInit, Input, Optional, Host, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormControl, ControlContainer } from '@angular/forms';

@Component({
  selector: "combined-toggle-button",
  templateUrl: "./toggle-button.component.html",
  styleUrls: ['./toggle-button.scss']
})
export class CombinedToggleButtonComponent {

  @Input() public readonly = false;

  @Input() public leftToggled = false;
  @Input() public rightToggled = false;

  @Output() public leftClicked = new EventEmitter();
  @Output() public rightClicked = new EventEmitter();

}
