<card-expanding [expanded]="expanded" (onExpand)="onExpand.emit($event)">
  <span header-title>{{ 'cards.history.title' | translate}}</span>
  <section body>
    <div class="row overflow-x">
      
      <div class="flex f-1" *ngIf="!historySource?.data.length">
        <no-content image="no-history" text="global.no_history" [inline]="true"></no-content>
      </div>

      <mat-table [dataSource]="historySource" *ngIf="historySource?.data.length">

        <ng-container matColumnDef="history_date">
          <mat-header-cell *matHeaderCellDef>
            {{ 'cards.history.table.created_at' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <div class="flex flex-col">
              <span>
                {{row.createdAt | amDateFormat:'LLL':null:localeService.locale}}
              </span>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="history_user">
          <mat-header-cell *matHeaderCellDef>
            {{ 'cards.history.table.created_by' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <!--<lxm-button type="transparent" [label]="row.user.name" icon="profile-dark"></lxm-button>-->
            <div class="flex flex-row align-center">
              <span class="icon profile-dark-icon"></span>
              <span class="ml10">{{row.createdBy.name}}</span>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="history_action">
          <mat-header-cell *matHeaderCellDef>
            {{ 'cards.history.table.action' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{row.type | translateEnum : changeType }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="history_change">
          <mat-header-cell *matHeaderCellDef>
            {{ 'cards.history.table.changes' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <diff [changes]="row.changes" [fieldType]="defaultFieldType"></diff>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="historyColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: historyColumns;"></mat-row>
      </mat-table>
    </div>

  </section>
</card-expanding>