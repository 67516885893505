import { Component, Inject, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormGroup, FormControl, FormArray } from "@angular/forms";
import { DialogComponent } from "src/app/components";
import {
  AuthenticationService,
  ICreateOfferProductRetailerAssortmentAndPriceScheme,
  IUpdateOfferProductRetailerAssortmentAndPriceScheme,
  OfferService,
  locale
} from "src/app/_services";
import { LxmAppModule, StatusRule, UserAction } from "src/app/enum";
import { LxmDialog, LxmMessage } from "src/app/_helpers";
import moment from "moment";
import { ICurrency } from "src/app/models";
import { ActivatedRouteSnapshot } from "@angular/router";
import {
  ICountry,
  ISupplier
} from "src/app/cards/product/product-info/product-info.model";
import { MarkupMethod } from "src/app/enum/markup-mehtod";
import { IVatDto } from "src/app/cards/product/product-pricelist/product-pricelist.model";
import { formatNumber } from "@angular/common";
import { getVats, nextSchemeDay } from "src/app/_helpers/utilities";
import { ProductAssortmentAndPricesDialogBase } from "./product-assortment-and-prices-dialog-base";

@Component({
  selector: "offer-product-assortment-and-prices-dialog",
  host: { class: "dialog" },
  templateUrl: "./product-assortment-and-prices.dialog.html",
  styleUrls: ["./product-assortment-and-prices.dialog.scss"]
})
export class OfferProductAssortmentAndPricesDialog extends ProductAssortmentAndPricesDialogBase {


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ProductAssortmentAndPricesDialogBase>,
    authService: AuthenticationService,
    public _offerService: OfferService,
    public _message: LxmMessage
  ) {
    super(data, dialogRef);
    this.readonly = data.readonly || !authService.hasRight([UserAction.ManageReceivedOffers]);
  }

  public save() {
    const {
      assortmentSchemeValidFrom,
      priceSchemeValidFrom,
      priceSchemeValidTo,
      status,
      assortmentGroups,
      offerProductDecisionId,
      parentTagId,
      payment,
      productPaymentSupplier,
      listPrice,
      retailPrices,
      priceGroups,
      vat,
      buyInPrice,
      buyInPriceChanged,
      schemeType,
      buyInPriceCurrency,
      logisticsPartner
    } = this.formGroup.value || {};

    if (this.showAssortmentStatusReminder) {
      if (
        this.assortmentStatuses?.find(
          x => x.id == status && x.statusRule == StatusRule.Remind
        )
      ) {
        this.showAssortmentStatusReminder = false;
        this._message.warning({
          message: "enums.StatusRule.ReminderMessage"
        });
        return;
      }
    }

    const offerId = this.offerId;
    const offerProductId = this.offerProductId;
    const schemeId = this.priceSchemeId;
    const assortmentId = this.assortmentId;

    if (schemeId) {
      const req: IUpdateOfferProductRetailerAssortmentAndPriceScheme = {
        offerId: offerId,
        offerProductId: offerProductId,
        priceSchemeId: schemeId,
        assortmentId: assortmentId,
        priceScheme: {
          schemeId: schemeId,
          offerId: offerId,
          offerProductId: offerProductId,
          retailerSupplierId: this.retailerSupplierId,
          logisticsPartnerId: logisticsPartner?.id,
          priceSchemeValidFrom: priceSchemeValidFrom,
          validTo: priceSchemeValidTo,
          vatId: vat?.id,
          listPrice: listPrice,
          retailPrices: retailPrices?.map(x => this.mapRetailPrice(x)),
          priceGroups: priceGroups?.map(x => this.mapPriceGroup(x)),
          buyInPrice: buyInPrice,
          buyInPriceCurrencyId: buyInPriceCurrency?.id,
          buyInPriceChangedByRetailer: buyInPriceChanged,
          schemeType: this.schemeType
        },
        assortment: {
          offerId: offerId,
          offerProductId: offerProductId,
          retailerProductAssortmentId: assortmentId,
          assortmentSchemeValidFrom: assortmentSchemeValidFrom,
          status: status,
          assortmentGroups: assortmentGroups,
          offerProductDecisionId: offerProductDecisionId,
          assortmentGroupTagId: parentTagId,
          productPayment: payment,
          productPaymentCurrencyId: this.offerCurrencyId,
          productPaymentSupplierId: productPaymentSupplier
        }
      };

      if (assortmentId == null && req.assortment.status == null) {
        req.assortment = null;
      }

      this._offerService
        .updateOfferProductRetailerAssortmentAndPriceScheme(
          offerId,
          offerProductId,
          schemeId,
          assortmentId,
          req
        )
        .result(
          this.formGroup,
          res => {
            this.closeDialog(res);
          },
          err => {
            console.error(err);
          },
          "save",
          this.actionLoading
        );
    } else {
      const req: ICreateOfferProductRetailerAssortmentAndPriceScheme = {
        offerId: offerId,
        offerProductId: offerProductId,
        priceScheme: {
          offerId: offerId,
          offerProductId: offerProductId,
          retailerSupplierId: this.retailerSupplierId,
          logisticsPartnerId: logisticsPartner?.id,
          priceSchemeValidFrom: priceSchemeValidFrom,
          validTo: priceSchemeValidTo,
          vatId: vat?.id,
          listPrice: listPrice,
          retailPrices: retailPrices?.map(x => this.mapRetailPrice(x)),
          priceGroups: priceGroups?.map(x => this.mapPriceGroup(x)),
          buyInPrice: buyInPrice,
          buyInPriceCurrencyId: buyInPriceCurrency?.id,
          buyInPriceChangedByRetailer: buyInPriceChanged,
          schemeType: this.schemeType
        },
        assortment: {
          offerId: offerId,
          offerProductId: offerProductId,
          assortmentSchemeValidFrom: assortmentSchemeValidFrom,
          status: status,
          assortmentGroups: assortmentGroups,
          offerProductDecisionId: offerProductDecisionId,
          assortmentGroupTagId: parentTagId,
          productPayment: payment,
          productPaymentCurrencyId: this.offerCurrencyId,
          productPaymentSupplierId: productPaymentSupplier
        }
      };

      this._offerService
        .createOfferProductRetailerAssortmentAndPriceScheme(
          offerId,
          offerProductId,
          req
        )
        .result(
          this.formGroup,
          res => {
            if (res) {
              const { assortment, priceScheme } = res;
              this.assortmentData = assortment;
              this.priceSchemeData = priceScheme;
              this.closeDialog(res);
            }

          },
          err => {
            console.error(err);
          },
          "save",
          this.actionLoading
        );
    }
  }
}

export interface IAssortmentAndPricesFormData {
  assortmentFormData: IAssortmentFormData;
  priceSchemeFormData: IPriceSchemeFormData;
}

export interface IAssortmentFormData {
  assortmentGroupTags: any;
  assortmentGroups: any;
  assortmentGroupsByTags: any;
  assortmentStatuses: any[];
  offerProductDecisions: any[];
  offerRetailerSupplierId: string;
  parentTags: any[];
  retailerSuppliers: any[];
  tags: any;
}

export interface IPriceSchemeFormData {
  acceptedCurrencies: ICurrency[];
  canSetSupplier: boolean;
  countries: ICountry[];
  defaultLogisticsPartner: any;
  logisticsPartners: any[];
  markupMethod: MarkupMethod;
  priceGroups: any[];
  procurements: any[];
  retailerCountry: ICountry;
  retailerSupplier: ISupplier;
  salesCurrencies: ICurrency[];
  suppliers: ISupplier[];
  vats: IVatDto[];
}
