import { AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Optional, Output, SimpleChange, SimpleChanges } from "@angular/core";
import { ControlContainer, FormArray, FormControl, FormGroup } from "@angular/forms";
import { Observable, Subscription } from "rxjs";
import { LocalStorage } from "src/app/_helpers";

@Component({
  selector: "assortment-group-selector",
  templateUrl: "./assortment-group-selector.component.html",
  styleUrls: ['./assortment-group-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssortmentGroupSelectorComponent implements OnInit, OnChanges, AfterViewInit {

  @HostBinding('class.disabled') @Input() public disabled = false;
  @HostBinding('class.readonly') @Input() public readonly = false;

  @Input() public formGroup: FormGroup;
  @Input() public for: string;

  @Input() public assortmentGroups = {};

  @Input() public dataLoaded$: Observable<boolean>

  @Input() public groupByTags; // Tags list (parents)
  @Input() public assortmentGroupTags;  // Tags associated with groups
  @Input() public assortmentGroupsByTags; // Groups associated with tags

  @Input() public data = [];

  @Input() public inner = false;

  public tagIdsWithAssortmentGroups: string[] = [];

  public assortmentGroupsList: FormArray;

  @Input() public childSelectedCount: any = {};
  @Output() public childSelectedCountChange = new EventEmitter<number>();

  constructor(@Optional() private _controlContainer: ControlContainer, private _cd: ChangeDetectorRef) {}

  public ngOnInit(): void {
    if (this._controlContainer && this._controlContainer.control instanceof FormGroup) {
      this.formGroup = <FormGroup>this._controlContainer.control;
    };
    this._initList();
  }

  public get isGroupedByTags() {
    return this.groupByTags?.length ? this.hasAnyAssortmentGroupsByTags : false;
  }

  public get hasTagsWithAssortmentGroups() {
    return this.readonly ? this.tagIdsWithAssortmentGroups?.some(x => this.tagHasAnySelected(x)) : this.tagIdsWithAssortmentGroups?.length > 0;
  }

  public get selectedAssortmentGroupIds() {
    return this.formGroup?.get(this.for)?.value || [];
  }

  public trackById(index, item) {
    return item.id;
  }

  public trackByAssortmentGroupId(index, item) {
    return item.value.assortmentGroup.id;
  }

  public getAssortmentGroupsByTagId(tagId) {
    return this.assortmentGroupsByTags[tagId];
  }

  public get hasAnyAssortmentGroupsByTags() {
    return Object.keys(this.assortmentGroupsByTags)?.length > 0 ?? false;
  }

  public getTagsByAssortmentGroupId(assortmentGroupId) {
    return this.assortmentGroupTags[assortmentGroupId];
  }

  private _getAssortmentGroupById(id) {
    return this.assortmentGroups[id];
  }

  public isGroupCollapsed(tagId) {
    const name = this._getGroupHeaderLocalStorageKey(tagId);
    return LocalStorage.getValue(name);
  }

  private _getGroupHeaderLocalStorageKey(tagId) {
    return `AG_COLLAPSED_${tagId}`;
  }

  public collapseGroup(tagId) {
    const name = this._getGroupHeaderLocalStorageKey(tagId);
    if (this.isGroupCollapsed(tagId)) {
      LocalStorage.remove(name);
    } else {
      LocalStorage.set(name, tagId);
    }
    this._cd.detectChanges();
  }

  public isAssortmentGroupSelected(item) {
    if (item && this.selectedAssortmentGroupIds?.length) {
      return this.selectedAssortmentGroupIds.includes(item.id);
    }
    return false;
  }

  public get hasAssortmentGroups() {
    return this.assortmentGroupsList?.controls?.length ? true : false;
  }

  public get isAllGroupsSelected(): boolean {
    const groupsList = this.assortmentGroupsList?.value;
    const selectedGroupsList = this.selectedAssortmentGroupIds;

    if (!groupsList || !selectedGroupsList) {
      return false;
    }

    const isAllSelected = groupsList.every(x => selectedGroupsList.includes(x.assortmentGroup.id));

    if (isAllSelected) {
      return true;
    }
    return false;
  }

  public tagHasAnyGroups(tagId: string) {
    const groups = this.getAssortmentGroupsByTagId(tagId);
    return groups?.length > 0 ? true : false;
  }

  public tagHasAnySelected(tagId: string) {
    const groups = this.getAssortmentGroupsByTagId(tagId);
    const selectedIdsList = groups?.filter(x => this.selectedAssortmentGroupIds.includes(x.id));
    return selectedIdsList?.length > 0 ? true : false;
  }

  public toggleAllAssortmentGroups() {
    const groupsList = this.assortmentGroupsList.value?.map(x => x.assortmentGroup);

    if (this.isAllGroupsSelected) {
      for(let group of groupsList) {
        this._removeAssortmentGroup(group)
      }
    } else {
      for(let group of groupsList) {
        this._selectAssortmentGroup(group);
      }
    }
    this._cd.detectChanges();
  }

  public onCheckboxDrag(event: MouseEvent, assortmentGroup) {
    if (this.hasChildGroupSelected(assortmentGroup)) {
      return;
    }
    const flags = event.buttons !== undefined ? event.buttons : event.which;
    const primaryMouseButtonDown = (flags & 1) === 1;

    if(event.shiftKey && primaryMouseButtonDown) {
      this.toggleSelection(assortmentGroup);
    }
  }

  public hasChildGroupSelected(item: any) {
    if (item.children) {
      for (let childId of item.children) {
        if (this.selectedAssortmentGroupIds.includes(childId)) {
          return true;
        }
      }
    }
    return false;
  }

  public toggleSelection(item) {
    if (this.hasChildGroupSelected(item)) {
      return;
    }

    if (this.isAssortmentGroupSelected(item)) {
      this._removeAssortmentGroup(item);
    } else {
      this._selectAssortmentGroup(item);

      if (item.suggested) {
        for (let id of item.suggested) {
          const group = this._getAssortmentGroupById(id);
          this._selectAssortmentGroup(group);
        }
      }
    }
    this._cd.detectChanges();
  }

  public updateChildSelectedCount(tagId, count) {
    this.childSelectedCount[tagId] = count;
    this._cd.detectChanges();
  }

  private _updateSelectedCount() {
    if (this.inner) {
      const count = this.selectedAssortmentGroups?.length;
      this.childSelectedCountChange.emit(count);
    }
  }

  private _removeAssortmentGroup(item) {
    if (!item) {
      return;
    }

    if (this.isAssortmentGroupSelected(item)) {
      const itemIndex = this.selectedAssortmentGroupIds.findIndex(x => x === item.id);
      this.selectedAssortmentGroupIds.splice(itemIndex, 1);
    }

    this._updateSelectedCount();

    this._cd.detectChanges();
  }
  
  public get selectedAssortmentGroups() {
    if (this.selectedAssortmentGroupIds?.length && this.data?.length) {
      return this.data.filter((x: any) => this.selectedAssortmentGroupIds.includes(x.id));
    }
    return [];
  }

  public get selectedAssortmentGroupNames() {
    const selectedAssortmentGroups = this.selectedAssortmentGroups;
    if (selectedAssortmentGroups?.length > 0) {
      return selectedAssortmentGroups?.map((x: any) => x.name).join(', ');
    }
    return '-';
  }

  private _selectAssortmentGroup(item) {

    if (!item) {
      return;
    }

    if (!this.isAssortmentGroupSelected(item)) {
      this.selectedAssortmentGroupIds.push(item.id);
    }

    if (item.parents) {
      for(let parentId of item.parents) {
        const parentItem = this._getAssortmentGroupById(parentId);
        this._selectAssortmentGroup(parentItem);
      }
    }

    this._updateSelectedCount();
  }

  private _initializing = false;

  private _initList() {

    this._initializing = true;

    if (!this.inner && this.isGroupedByTags && this.assortmentGroupsByTags) {
      this.tagIdsWithAssortmentGroups = this.groupByTags.filter(tag => this.getAssortmentGroupsByTagId(tag.id) ? true : false).map(x => x.id);
    }

    if (!this.data?.length) {
      if (this.assortmentGroups && !this.inner) {
        this.data = Object.keys(this.assortmentGroups).map(i => this.assortmentGroups[i]);
      } else {
        this._initializing = false;
        this._cd.detectChanges();
        return;
      }
    }

    this.assortmentGroupsList = new FormArray(
      this.data?.map(x => new FormGroup({
          assortmentGroup: new FormControl(x)
        }))
    );

    for (const agId of this.selectedAssortmentGroupIds) {
      const group = this._getAssortmentGroupById(agId);
      this._selectAssortmentGroup(group);
    }

    this._updateSelectedCount();

    this._initializing = false;
    this._cd.detectChanges();
  }

  private _valueChangesSub: Subscription;

  public ngAfterViewInit() {
    this._cd.detach();
    this._cd.detectChanges();

    this._valueChangesSub = this.formGroup.get(this.for)?.valueChanges.subscribe(x => {
      this._cd.detectChanges();
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this._initializing) {
      // const { assortmentGroupTags, assortmentGroups } = changes || {};
      // if (!assortmentGroupTags?.isFirstChange || !assortmentGroups?.isFirstChange) {
        this._initList();
      // }
    }
    this._cd.detectChanges();
  }

  ngOnDestroy() {
    if (this._valueChangesSub) {
      this._valueChangesSub.unsubscribe();
    }
  }

}
