<card-expanding [formGroup]="form" [valid]="productValidity.getCardStatusValidType(cardSection)" [expanded]="isExpanded" (onExpand)="saveLocalExpandedValue($event)">

  <span header-title>{{ title | translate}}</span>

  <div header-actions *requiredRights="[UserAction.ManageProducts]">
    <ng-container *ngIf="isOwnProduct">
      <ng-container *ngIf="!locked">
        <lxm-button *ngIf="!edit" class="ml15" type="beige" icon="edit" title="action.edit" (click)="toggleEdit()"></lxm-button>
        <lxm-button *ngIf="edit" class="ml15" type="grey" icon="back" label="action.cancel" (click)="toggleEdit()">
        </lxm-button>
        <lxm-button *ngIf="edit" class="ml15" type="beige" icon="save" label="action.save" [loading]="saveLoading"
          (click)="save()" buttonType="submit"></lxm-button>
      </ng-container>
      <ng-container *ngIf="locked">
        {{ 'global.locked_by' | translate }}: {{ lockedBy.name }} {{ lockedAt | amTimeAgo }}
      </ng-container>
    </ng-container>
  </div>

  <div body>

    <div class="row">
      <div class="col-6 no-padding">

        <form-field [readonly]="!edit" label="cards.products.product_storage_info.label.storage_conditions" 
          description="cards.products.product_storage_info.label_description.storage_conditions"
          for="storageConditionsId"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.StorageConditions, form.get('storageConditionsId').value) : null">
          <lxm-select select for="storageConditionsId" class="w100p" value="id" labelField="translatedValue"
            [isTranslated]="true" [options]="storageConditionsOptions" [readonly]="!edit">
          </lxm-select>
          <validation-errors for="storageConditionsId"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit" label="cards.products.product_storage_info.label.shelf_life_marking" 
          description="cards.products.product_storage_info.label_description.shelf_life_marking"
          for="shelfLifeMarkingId"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.ShelfLifeMarking, form.get('shelfLifeMarkingId').value) : null">
          <lxm-select select for="shelfLifeMarkingId" class="w100p" value="id" labelField="translatedValue"
            [isTranslated]="true" [options]="shelfLifeMarkingOptions" [readonly]="!edit">
          </lxm-select>
          <validation-errors for="shelfLifeMarkingId"></validation-errors>
        </form-field>

        <form-field for="shelfLifeAfterOpeningInDays" type="input-text" description="cards.products.product_storage_info.label_description.shelf_life_open"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.ShelfLifeAfterOpeningInHours, form.get('shelfLifeAfterOpeningInDays').value) : null"
          [readonly]="!edit" label="cards.products.product_storage_info.label.shelf_life_open">
          <validation-errors for="shelfLifeAfterOpeningInDays"></validation-errors>
        </form-field>

        <form-field for="paoMarkingInMonths" type="input-text"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.PaoMarkingInMonths, form.get('paoMarkingInMonths').value) : null"
          [readonly]="!edit" label="cards.products.product_storage_info.label.PAO" description="cards.products.product_storage_info.label_description.PAO">
          <validation-errors for="paoMarkingInMonths"></validation-errors>
        </form-field>

      </div>

      <div class="col-6 no-padding">
        <form-field [readonly]="!edit" label="cards.products.product_storage_info.label.temp_control_required" description="cards.products.product_storage_info.label_description.temp_control_required">
          <slide-toggle toggle for="storageTemperatureControlRequired" [readonly]="!edit"></slide-toggle>
          <validation-errors for="storageTemperatureControlRequired"></validation-errors>
        </form-field>

        <form-field for="storageMinTemp" type="input-text"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.StorageMinTemp, form.get('storageMinTemp').value) : null"
          [readonly]="!edit" label="cards.products.product_storage_info.label.storage_temp_min" description="cards.products.product_storage_info.label_description.storage_temp_min">
          <validation-errors for="storageMinTemp"></validation-errors>
        </form-field>

        <form-field for="storageMaxTemp" type="input-text"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.StorageMaxTemp, form.get('storageMaxTemp').value) : null"
          [readonly]="!edit" label="cards.products.product_storage_info.label.storage_temp_max" description="cards.products.product_storage_info.label_description.storage_temp_max">
          <validation-errors for="storageMaxTemp"></validation-errors>
        </form-field>

      </div>
    </div>

    <div class="separator"></div>

    <ng-container *ngIf="isOwnProduct">

      <card-section *ngIf="hasBuyerPim">
        <span header-title *ngIf="showExpirationsTitles">
          {{ 'cards.products.product_storage_info.label.storage_retailer_title' | translate }}
        </span>
  
        <product-retailer-expiry-list 
          body
          for="locationExpirations" 
          type="buyer"
          [readonly]="!edit"
          [expiryTargets]="expiryTargets" 
          [singleRow]="true">
        </product-retailer-expiry-list>
  
      </card-section>

      <card-section *ngIf="hasSellerPim">
        <span header-title *ngIf="showExpirationsTitles">
          {{ 'cards.products.product_storage_info.label.storage_supplier_title' | translate }}
        </span>

        <product-retailer-expiry-list 
          body
          for="retailerExpirations" 
          type="seller"
          [readonly]="!edit"
          [expiryTargets]="expiryTargets">
        </product-retailer-expiry-list>
  
      </card-section>


    </ng-container>


    <card-section *ngIf="!isOwnProduct">

      <product-retailer-expiry-list 
        for="retailerExpirations" 
        type="market"
        [readonly]="true"
        [expiryTargets]="expiryTargets">
      </product-retailer-expiry-list>
  
    </card-section>


  </div>

</card-expanding>