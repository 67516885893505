import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { SelectComponent } from "src/app/components/form/select/select.component";

@Component({
  selector: "ov-options",
  templateUrl: "./ov-options.component.html",
  styleUrls: ["./ov-options.component.scss"],
})
export class OverlayViewOptionsComponent extends SelectComponent {
  public controls = new FormGroup({
    searchTerm: new FormControl(),
  });

  public isOptionSelected(option) {
    if (!option) return false;

    if (!this.ref.value) {
      return false;
    }

    if (this.multiple) {
      // if (!this.ref.value?.length) {
      //   return false;
      // }

      return this.ref.value?.some((x) => this.compareWith(option, x));
    }

    return this.compareWith(option, this.ref.value);
  }

  public handleOptionClick(option): void {
    if (!option) return;

    if (option.function && typeof option.function === "function") {
      option.function();
    }

    if (this.ref && option) {
      const sanitizedOption = Object.assign({}, option);

      if (option.function) {
        delete sanitizedOption.function;
      }

      if (option.shouldClose) {
        delete sanitizedOption.shouldClose;
      }

      let opt = this.value ? sanitizedOption[this.value] : sanitizedOption;
      let toSet = opt;

      if (this.multiple) {
        let arr = Array.isArray(this.ref.value) ? this.ref.value : [];

        const selectedIndex = arr.findIndex((x) => this.compareWith(opt, x));
        if (selectedIndex > -1) {
          arr.splice(selectedIndex, 1);
          toSet = [...arr];
        } else {
          toSet = [...arr, opt];
        }
      }

      this.ref.setValue(toSet);
      this.change.emit(sanitizedOption);
    }

    // if (this.multiple || !this.closeOnSelect || option.shouldClose === false) {
    //   return;
    // }

    // this.select.close();
  }

  // SEARCH
  public onSearch(term: string) {
    console.log(term);
    this.filteredOptions = [
      ...this.finalizedOptions.filter((option) => this.search(term, option)),
    ];
  }

  public search = (term: string, item: any) => {
    if (this.url || !term) {
      return true;
    }

    if (this.additionalField) {
      return (
        this.getItemValue(item).toLowerCase().indexOf(term.toLowerCase()) >
          -1 ||
        this.getAdditionalValue(item)
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1
      );
    }

    var val = this.getItemValue(item);
    if (!val) {
      return false;
    }

    return val.toLowerCase().indexOf(term.toLowerCase()) > -1;
  };
}
