import { Directive, TemplateRef, ViewContainerRef } from "@angular/core";
import { AppState } from "../state/app.state";
import { SubscriptionPlan } from "../enum/subscription-plan";
import { Subscription } from "rxjs";

@Directive({
  selector: "[standardPlan]"
})
export class StandardPlanDirective {

  private _sub: Subscription;

  constructor(
    private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef,
    private _appState: AppState
  ) {}

  ngOnInit() {
    this._sub = this._appState.tenantSubscriptionPlan.subscribe((val) => {
      this._viewContainer.clear();

      if (val === SubscriptionPlan.Free) {
        this._viewContainer.createEmbeddedView(this._templateRef);
      }
    });
  }

  ngOnDestroy() {
    if (this._sub) {
      this._sub.unsubscribe();
    }
  }
}
