<td *ngFor="let colName of displayColumns; let i = index"
    [class]="'lxm-tree-node-column lxm-tree-node-column-' + colName + (i == 0 ? ' indent-' + node.level : '')">
    <div class="flex">
        <toggle-arrow 
            class="expansion-toggle-arrow" 
            [toggled]="expanded" 
            *ngIf="i == 0 && node.isParent">
        </toggle-arrow>
        <lxm-tree-cell [node]="node" [templateRef]="cellRefs[colName]"></lxm-tree-cell>
    </div>
</td>
