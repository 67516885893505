import { Component, AfterViewInit, Input, ViewChild, TemplateRef, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { DiffValueFormatterType } from 'src/app/enum';

@Component({
  selector: 'diff',
  templateUrl: './diff.html',
  styleUrls: ['./diff.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DiffComponent implements AfterViewInit {

  @Input() public changes: any[];
  @Input() public oneLiner = false;
  @Input() public deleted = false;
  @Input() public isNew = false;
  @Input() public showPropertyName = true;
  @Input() public showNewValue = true;
  @Input() public showOldValue = true;
  @Input() public fieldType;

  @ViewChild('boolTokenValue') public boolTokenValue: TemplateRef<any>;
  @ViewChild('enumTokenValue') public enumTokenValue: TemplateRef<any>;
  @ViewChild('countryTokenValue') public countryTokenValue: TemplateRef<any>;
  @ViewChild('userTokenValue') public userTokenValue: TemplateRef<any>;
  @ViewChild('gs1SegmentTokenValue') public gs1SegmentTokenValue: TemplateRef<any>;
  @ViewChild('systemClvTokenValue') public systemClvTokenValue: TemplateRef<any>;
  @ViewChild('clvTokenValue') public clvTokenValue: TemplateRef<any>;
  @ViewChild('numericTokenValue') public numericTokenValue: TemplateRef<any>;
  @ViewChild('dateTokenValue') public dateTokenValue: TemplateRef<any>;
  @ViewChild('localSegmentTokenValue') public localSegmentTokenValue: TemplateRef<any>;
  @ViewChild('atcSegmentTokenValue') public atcSegmentTokenValue: TemplateRef<any>;
  @ViewChild('retailerTokenValue') public retailerTokenValue: TemplateRef<any>;
  @ViewChild('productUnitTokenValue') public productUnitTokenValue: TemplateRef<any>;
  @ViewChild('productTokenValue') public productTokenValue: TemplateRef<any>;
  @ViewChild('procurementTokenValue') public procurementTokenValue: TemplateRef<any>;
  @ViewChild('stringTokenValue') public stringTokenValue: TemplateRef<any>;

  public DiffValueFormatterType = DiffValueFormatterType;

  constructor(private _cd: ChangeDetectorRef) {
  }

  public ngAfterViewInit(): void {
    this._cd.detectChanges();
  }

  public resolveValueTemplate(x: any) {

    if (x.valueFormatterType === DiffValueFormatterType.Bool) {
      return this.boolTokenValue;
    }
    if (x.valueFormatterType === DiffValueFormatterType.Enum) {
      return this.enumTokenValue;
    }
    if (x.valueFormatterType === DiffValueFormatterType.Country) {
      return this.countryTokenValue;
    }
    if (x.valueFormatterType === DiffValueFormatterType.User) {
      return this.userTokenValue;
    }
    if (x.valueFormatterType === DiffValueFormatterType.RetailChain) {
      return this.retailerTokenValue;
    }
    if (x.valueFormatterType === DiffValueFormatterType.Gs1Segment) {
      return this.gs1SegmentTokenValue;
    }
    if (x.valueFormatterType === DiffValueFormatterType.LocalSegment) {
      return this.localSegmentTokenValue;
    }
    if (x.valueFormatterType === DiffValueFormatterType.AtcSegment) {
      return this.atcSegmentTokenValue;
    }
    if (x.valueFormatterType === DiffValueFormatterType.SystemClv) {
      return this.systemClvTokenValue;
    }
    if (x.valueFormatterType === DiffValueFormatterType.Clv) {
      return this.clvTokenValue;
    }
    if (x.valueFormatterType === DiffValueFormatterType.Numeric) {
      return this.numericTokenValue;
    }
    if (x.valueFormatterType === DiffValueFormatterType.Date) {
      return this.dateTokenValue;
    }
    if (x.valueFormatterType === DiffValueFormatterType.ProductUnit) {
      return this.productUnitTokenValue;
    }
    if (x.valueFormatterType === DiffValueFormatterType.Product) {
      return this.productTokenValue;
    }
    if (x.valueFormatterType === DiffValueFormatterType.Procurement) {
      return this.procurementTokenValue;
    }
    if (x.valueFormatterType === DiffValueFormatterType.None || x.valueFormatterType === DiffValueFormatterType.MultiLanguageText) {
      return this.stringTokenValue;
    }
  }
}
