import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IClvDto, IRetailChain, ISubUnit, IProductUnitCodeType } from '../cards/product/product-info/product-info.model';

@Injectable({ providedIn: 'root' })
export class ProductUnitService {

    constructor(private _http: HttpClient) {}    

    public get(id: string): Observable<any> {
        return this._http.get(`api/productUnits/${id}`);
    }

    public getGridData(productId: string): Observable<any> {
        return this._http.get(`api/productUnits/griddata/${productId}`);
    }

    public getFormData(productId: string, unitId: string): Observable<IProductUnitFormData> {
        return this._http.get("api/productUnits/formdata", { 
            params: new HttpParams()
                .set("productId", productId)
                .set("productUnitId", unitId || "")
        }) as Observable<IProductUnitFormData>;
    }

    public save(id: string, data: any): Observable<any> {
        return this._http.post(`api/productunits/${id || ''}`, data) as Observable<any>;
    }

    public delete(id: string): Observable<any> {
        return this._http.delete(`api/productunits/${id}`) as Observable<any>;
    }

    public nextCode(formatId: string): Observable<string> {
        return this._http.post(`api/productunits/nextcode/${formatId}`, null, {responseType: 'text'}) as Observable<string>;
    }

    public validateCode(code: string): Observable<any> {
        return this._http.post(`api/productunits/gs1CodeCheck`, { code: code }) as Observable<any>;
    }
}

export interface IProductUnitFormData {
    unitTypes: IClvDto[];
    packageDepositTypes: IClvDto[];
    packageMaterialTypes: IClvDto[];
    packageTypes: IClvDto[];
    palletTypes: IClvDto[];
    tareTypes: IClvDto[];
    retailChains: IRetailChain[];
    subUnits: ISubUnit[];
    codeTypes: IProductUnitCodeType[];
    units: IClvDto[];
    isStackable: boolean;
}
