import { Component, Output, EventEmitter, Input, OnInit, Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MarketSearchDataService } from 'src/app/_services';
import { asArray, SupplierProductAssortmentStatus, IEnumValue, ProductField } from 'src/app/enum';
import { AppState } from 'src/app/state/app.state';
import { ActivatedRoute } from '@angular/router';
import { LocalStorage } from 'src/app/_helpers';
import { ICountryTranslated, IProductListFilterDataDto, IProductsSearchFormData } from '../product/product-info/product-info.model';
import { ProductsListState } from '../products/products-list.state';
import { MarketProductsListState } from './products-list.state';

@Component({
  selector: 'market-search-card',
  templateUrl: './market-search.card.html',
  host: { 'class': 'market-search-card' },
  styleUrls: ['./market-search.card.scss']
})
export class MarketSearchCard implements OnInit {

  public formData: IProductsSearchFormData;

  @Output()
  public search = new EventEmitter();

  @Input()
  public isOpen = false;

  @Input()
  private localStorageKey: string;

  public statuses: IEnumValue[];

  public countryOptions: ICountryTranslated[];
  public nutritionalClaimsOptions: IEnumValue[];

  constructor(
    public state: MarketProductsListState,
    public appState: AppState,
    private _marketSearchDataService: MarketSearchDataService,
    route: ActivatedRoute) {

    this.formData = (route.snapshot.data.formData || route.snapshot.data.productCard.formData) as IProductsSearchFormData;

    this.countryOptions = this.formData.countries;

    this.statuses = asArray(SupplierProductAssortmentStatus);
    this.nutritionalClaimsOptions = asArray(ProductField).filter(x => this.formData.nutritionalClaims?.includes(x.id));

    const filterData = route.snapshot.data.filterData as IProductListFilterDataDto;

    if (filterData) {
      this.isOpen = true;
    }

    this.statuses = this.statuses.filter(x => x.id !== SupplierProductAssortmentStatus.EndOfLife && x.id !== SupplierProductAssortmentStatus.NoScheme);
  }

  public get searchData(): FormGroup {
    return this._marketSearchDataService.searchData;
  }

  public get isLoading() {
    return this.state.isSearchLoading;
  }

  public toggleOpen(isOpen: boolean) {
    this.isOpen = isOpen;
    LocalStorage.set(this.localStorageKey, isOpen);
  }

  public ngOnInit(): void {
    this.submit();
  }

  public submit() {
    const val = this._marketSearchDataService.serializeSearchData();
    this.search.emit(val);
  }

}
