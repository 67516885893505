<form class="dialog-container">
  <lxm-button
    class="absolute right-2 top-2"
    type="transparent"
    icon="close"
    (click)="closeDialog()"
  ></lxm-button>

  <section class="flex flex-col items-center" style="padding: 1.875rem">
    <div class="mb-2 flex w-full justify-center">
      <svg-icon key="standardPlanOrange" fontSize="4rem"></svg-icon>
    </div>

    <div class="d-title mb-6">
      {{'components.dialogs.subscription_restricted.text1' | translate}}
    </div>

    <div class="d-text mb-6">
      {{'components.dialogs.subscription_restricted.text2' | translate}}
    </div>

    <div class="mb-10x flex">
      <lxm-button
        type="primary"
        (click)="closeDialog()"
        routerLink="/lxm-settings/subscription"
        >{{ 'components.dialogs.subscription_restricted.action.choose_plan' | translate }}</lxm-button
      >
    </div>

    <a class="d-info" href="https://lexi.market/et/hulgimuujale/" target="_blank">{{'components.dialogs.subscription_restricted.additional_info' | translate}}</a>
  </section>
</form>
