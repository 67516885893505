<lxm-dialog [dialogRef]="dialogRef" #dialog>

  <span header-title>{{ 'components.terms_and_conditions.title' | translate }}</span>

  <div body>
    <ng-container *ngIf="language === 'et'">
      <ng-container *ngTemplateOutlet="et"></ng-container>
    </ng-container>
    <ng-container *ngIf="language === 'en'">
      <ng-container *ngTemplateOutlet="en"></ng-container>
    </ng-container>
  </div>

  <div footer class="justify-between">
    <span class="flex flex-row">
      <lxm-button type="grey" label="action.cancel" class="ml10" (click)="closeDialog()"></lxm-button>
    </span>
  </div>

</lxm-dialog>


<ng-template #et>
  <p>
    Käesolevad teenuslepingu üldtingimused (edaspidi “Teenustingimused”) on Brightspark OÜ poolt kõigile isikutele
    (Klient) müüdavate teenuste osas sõlmitavate teenuslepingute lahutamatuks osaks.
  </p>

  <p>
    1. Üldsätted
  </p>
  <p>
    1.1. Teenustingimusi kohaldatakse juhul, kui Teenuseosutaja ja Klient ei ole Lepingus või selle lisades omavahel
    kokku leppinud teisiti.
  </p>
  <p>
    1.2. Sõltuvalt kontekstist võivad Teenustingimustes esitatud mõisted ainsuses tähendada ka mitmust ja vastupidi.
  </p>
  <p>
    1.3. Teenustingimustes on pealkirju kasutatud üksnes lugemise hõlbustamiseks ja need ei mõjuta ühegi sätte sisu
    tõlgendamist.
  </p>
  <p>
    1.4. Teenustingimuste iga punkti tõlgendatakse koos Lepingu ülejäänud sätetega, lähtudes Lepingu mõttest ja
    eesmärgist. Ühe sätte kehtetus ei mõjuta ülejäänud Lepingu kehtivust, õiguspärasust ega täidetavust.
  </p>

  <p>
    2. Mõisted
  </p>
  <p>
    2.1. Käesolevas Lepingus kasutatud suure algustähega algavad terminid omavad järgnevat tähendust:
  </p>
  <p>
    “Leping” – käesolevad teenusetingimused (mida aeg-ajalt ajakohastatakse), liitumisvormi tingimused ja mis tahes
    muud Teenusega seotud tingimused, millega Klient Teenuseosutaja ajendusel võib nõustuda.
  </p>
  <p>
    „Sisu“ – informatsioon (sealhulgas tekst, pildid, heli- ja videomaterjal), funktsionaalsus, tehnilised vahendid,
    Teenuseosutaja aja- ja töökulu ning Teenuse kättesaadavuse tagamine Teenuseosutaja poolt.
  </p>
  <p>
    „Klient“ – iga isik või üksus, kellele Teenust osutatakse.
  </p>
  <p>
    “Lõppkasutaja” – iga füüsiline isik, kellele Klient annab loa või tagab juurdepääsu Teenuse kasutamiseks.
  </p>
  <p>
    „Intellektuaalne omand“ – kõik olemasolevad ja tulevased kaubamärgid, teenusemärgid, domeeninimed ja ärinimed,
    kõik õigused, mis on seotud leiutiste, disaini, andmebaaside ja ärisaladuse alla kuuluva informatsiooniga
    (sealhulgas ja ilma piiranguteta mis tahes äriteave ja oskusteave), autoriõigused, isiklikud õigused ja kõik muud
    varad ja hüved, mida tavaliselt loetakse intellektuaalse omandi alla kuuluvaks, hoolimata sellest, kas need on
    registreeritud või mitte.
  </p>
  <p>
    „Osapool“ – üldine termin, mis tähistab nii Klienti kui Teenuseosutajat.
  </p>
  <p>
    „Registreerimisandmed“ – informatsioon, mille Klient edastab Teenusega liitumisel, Lexi.Market standard
    funktsiooni osas muudatuste taotlemisel või Teenuse kaudu vormide täitmisel, sealhulgas Kliendi täisnimi või
    ärinimi, registreerimise/äriühingu/faili/ettevõtte või isikukood (või selle ekvivalent), alaline elukoht, aadress,
    e-posti aadress, telefoninumber ja mis tahes sarnane informatsioon, mida Teenuseosutaja võib mõistlikkuse piires
    Kliendilt nõuda.
  </p>

  <p>
    „Teenus“ – võrgupõhine tarkvara teenus (SaaS), millele on tagatud ligipääs käesoleva veebilehe kaudu, Sisu
    kättesaadavus ja klienditugi.
  </p>
  <p>
    „Liitumisvorm“ – võrgus saadaolev vorm, mille kaudu Klient saab liituda Teenuse kasutajaks või taotleda muudatust
    litsentsis või mis tahes muud liitumise või muudatuse vormid, mida Osapooled võivad eelnimetatud eesmärkidel
    täita.
  </p>
  <p>
    „Tarkvara“ – Teenuses sisalduvad äritarkvara rakendused, mis on käesoleval kodulehel kirjeldatud tunnustega,
    sealhulgas sellised muudatused ja asendused, mille Teenuseosutaja võib oma äranägemise järgi aeg-ajalt rakendada.
  </p>
  <p>
    „Teenuseosutaja“ – Brightspark OÜ, äriühing, mis on asutatud Eesti Vabariigi seaduste järgi, äritegevuse peamise
    asukohana Tallinn, Eesti Vabariik.
  </p>
  <p>
    „Kasutajakonto“ – Teenuseosutaja majutatud või administreeritud konto, mida pakutakse Kliendile Teenuse kaudu
    eesmärgiga võimaldada Kliendile Teenuse kasutamine.
  </p>
  <p>
    2.2 „Siinkohal“, „käesolevaga“, „sellesse puutuvalt“, „alljärgnevalt“ ja muud taolised väljendid, mida võib
    Lepingust leida, viitavad käesolevale Lepingule.
  </p>
  <p>
    2.3. Käesolev Leping oma praegusel kujul ja kodulehel avaldatud informatsioon, mis puudutab Teenust ja hindu,
    kujutavad endast Osapoolte vahel sõlmitud lepingut terviklikul kujul ning välistavad kõik muud kokkulepped,
    korraldused ja arusaamised, mis Osapoolte vahel on eelnevalt selles osas kokku lepitud.
  </p>
  <p>
    2.4. Kui peaks tekkima vastuolu või mitmeti mõistetavus käesoleva Lepingu tingimuste ja mis tahes kodulehel
    avaldatud väite, esituse või muu informatsiooni vahel, loetakse ülimuslikuks Lepingus kirjeldatud tingimused.
  </p>
  <p>
    3. Tähtaeg
  </p>
  <p>
    3.1. Käesolev Leping hakkab Osapoolte vahel kehtima hetkest, mil Klient on kas Teenusega liitunud, saanud
    Kasutajakonto või alustanud Teenuse kasutamisega, sõltuvalt sellest, milline tegevus leiab aset esmajärjekorras.
    Leping on sõlmitud tähtajatult ja mõlemal Osapoolel on õigus Leping käesolevas dokumendis sätestatud tingimustel
    lõpetada.
  </p>
  <p>
    4. Litsents
  </p>
  <p>
    4.1. Tuginedes Kliendi Registreerimisandmetele ja käesolevas dokumendis sätestatud tingimustele, tagab
    Teenuseosutaja Kliendile ning Klient nõustub piiratud mittevõõrandatava lihtlitsentsiga kasutamaks käesolevat
    tarkvara ja sellega ettenähtud Sisu.
  </p>
  <p>
    4.2. Tarkvara ja Sisu tohib kasutada ainult Teenuse kasutamiseks ettenähtud otstarbel ning perioodil, mil Leping
    on
    Osapoolte vahel kehtiv. Klient nõustub, et ta ei oma õigust pääseda Tarkvarale ligi lähtekoodiga või lahtimurtud
    koodiga või kommentaari kaudu.
  </p>
  <p>
    4.3. Lähtudes loovutamise ja all-litsentsimise headest tavadest lepivad Osapooled kokku järgnevas: (i) Kliendil
    puudub õigus all-litsentsida või loovutada käesoleva Lepingu hüvesid või kohustusi (kas osaliselt või
    terviklikult)
    ning Klient ei võimalda Tarkvaral ega ühelgi muul Teenuse osal saada mis tahes maksustamise, kinnipidamisõiguse
    või
    koormatise osaks ilma Teenuseosutaja eelneva selgesõnalise nõusolekuta; Teenuseosutaja võib all-litsentsida,
    loovutada, maksustada või mõnel muul moel oma õigustest ja kohustustest Kliendi ees loobuda eeldusel, et ta teatab
    sellest Kliendile mõistliku aja ette.
  </p>
  <p>
    4.4. Teenuseosutaja majutab Tarkvara isiklikes või renditud serverites.
  </p>
  <p>
    5. Teenus
  </p>
  <p>
    5.1. Teenuseosutaja tagab Lepingu vältel äriliselt mõistlike jõupingutustega Kliendile Teenuse vastavalt Kliendi
    tellitud litsentsile.
  </p>
  <p>
    5.2. Klient nõustub, et (i) Teenus ei ole kavandatud vastama tema individuaalsetele nõuetele; (ii) Teenuses võib
    aeg-ajalt esineda tehnilisi või muid probleeme ja teenus ei pruugi tingimata katkematult või ilma vigadeta
    töötada;
    (iii) Teenus ei ole rikkekindel ja ei ole mõeldud kasutamiseks ohtlikku laadi tegevusteks, nagu (näiteks)
    „peamiste
    ohuallikatega“ seotud tegevused, liiklusjuhtimine või elutagamissüsteemid, ohtlike ainete käitlemiseks või muudeks
    tegevusteks, mille puhul Teenuse rike võib viia surma, kehavigastuse või keskkonna kahjuni.
  </p>
  <p>
    5.3. Teenuseosutaja on kohustunud tagama kvaliteetset kasutajatuge ning püüab vastata enamikele klienditoe
    küsimustele 3 tööpäeva jooksul. Sealjuures Teenuseosutaja ei taga kindlat aega, mille jooksul tuge antakse.
  </p>
  <p>
    5.4. Teenust (sealhulgas Tarkvara ja Sisu) tagatakse olemasoleval ja saadavaloleval kujul.
  </p>
  <p>
    5.5. Klient valib ja kasutab Teenust omal vastutusel ning Klient ja Lõppkasutaja vastutavad informatsiooni,
    programmide või muude objektidega kokkupuutumise, alla- ja üleslaadimise, ülekandmise ja omamise eest, milleni nad
    Teenuse kaudu või tõttu jõuavad.
  </p>
  <p>
    5.6. Teenuseosutaja ei ole kohustatud täiendama, muutma või asendama mis tahes Teenuse osa või jätkama selle
    arendamisega või andma välja uusi versioone. Teenuseosutaja ei vastuta kolmanda osapoole tarkvaralahenduste
    toimimise eest, mida kasutatakse Teenust kasutades. Teenuseosutajal on õigus jälgida ja salvestada kuidas Klient
    Teenust kasutab.
  </p>
  <p>
    6. Tasumine
  </p>
  <p>
    6.1. Klient kinnitab, et tellitud litsentsiga liitumisel nõustub ta nende tasude, hindade ja arveldusperioodidega
    nagu on (i) liitumise ajal kodulehel kirjeldatud (ii) aeg-ajalt vastavalt Lepingu tingimustele ajakohastatud.
  </p>
  <p>
    6.2. Kui liitumise ajal ei ole teisiti kokku lepitud, siis on Kliendi arveldusperiood 30 päeva, alates vahetult
    vastava litsentsiga liitumisele järgnenud päeval või kui Kliendile on tagatud tasuta prooviperiood, siis vahetult
    prooviperioodi lõpule järgnenud päeval.
  </p>
  <p>
    6.3. Teenuseosutaja võib täiendada või muul moel muuta Teenust lisatasude eest ja võib 30 päeva etteteatamise
    ajaga
    muuta Kliendi tellitud litsentsile kohaldatavaid tasusid, hindasid ja arveldusperioodi. Juhul kui Klient ei nõustu
    vastavate muudatustega, jääb talle õigus vastava litsentsi tellimusest loobuda, lõpetades Teenuse kasutamine.
  </p>
  <p>
    6.4. Teenuse eest tasutakse enne iga arveldusperioodi algust ette ning makse teostatakse krediitkaardiga
    (erandkorras pangaülekandega). Klient nõustub, et hilinenud makse võib tingida Teenuse peatamise või Lepingu
    lõpetamise.
  </p>
  <p>
    6.5. Kui Klient peaks enne käimasolevat makseperioodi litsentsist loobuma, litsentsi muutma või Lepingu lõpetama,
    siis Kliendile ei hüvitata ühtegi selle makseperioodi alla kuuluvat makset.
  </p>
  <p>
    6.6. Ettemaks tulevaste makseperioodide eest ei kuulu hüvitamisele.
  </p>
  <p>
    6.7. Kõik tasud ja hinnad on ilma käibemaksu, müügimaksu ja muude koormisteta. Klient vastutab ainuisiklikult
    kõikide maksude ja muude koormiste eest, mida võidakse Teenuse ostmisel või kasutamisel kohaldada.
  </p>
  <p>
    7. Kliendi kohustused
  </p>
  <p>
    7.1. Klient peab olema isik (kas füüsiline või juriidiline) või õigusvõimeline üksus.
  </p>
  <p>
    7.2. Kõik Kliendi edastatud Registreerimisandmed peavad olema tõesed, täpsed, ajakohased ning Klient nõustub
    koheselt parandama mis tahes Registreerimisandmeid, mis vananevad või muutuvad ebakorrektseteks. Teenuseosutaja
    võib, kuid ei ole kohustatud kontrollima Kliendi Registreerimisandmeid ja võib toetuda neile andmetele ilma neid
    kontrollimata.
  </p>
  <p>
    7.3. Klient peab lähtuma kõikidest seadustest, määrustest ja korraldustest, mis on Kliendile Teenuse kasutamisel
    kohaldatavad.
  </p>
  <p>
    7.4.Klient kinnitab ja tagab Teenuseosutajale, et Klient omab õigust kogu informatsioonile (sealhulgas tekst,
    pildid, audiovisuaalne materjal ja muu sisu), mida ta Teenuse kaudu üles laadib, edastab või hoiustab.
  </p>
  <p>
    7.5. Klient tagab, et ta ise ja et tema Lõppkasutajad ei kasuta Teenust pealesunnitud teabe edastamiseks või
    kahjulike koodide, pahavara või ebaseadusliku sisuga materjalide üleslaadimiseks, edastamiseks, saatmiseks,
    jooksutamiseks või ladustamiseks.
  </p>
  <p>
    7.6. Mis tahes Kliendi materjal, mis läheb vastuollu käesoleva Lepingu sätetega, võidakse eemaldada, keelata
    ja/või
    hävitada Teenuseosutaja poolt tema äranägemise järgi ilma hoiatuse või etteteatamisega.
  </p>
  <p>
    7.7. Ilma Kliendi seadusjärgseid kohustusi välistamata või piiramata, kinnitab Klient Teenuseosutajale, et Klient
    ise ei ürita ega luba oma Lõppkasutajatel üritada (i) muuta, paljundada või valmistada tuletatud teoseid, või
    dekompileerida, pöördtransleerida või mis tahes muul moel püüda Tarkvarast või Teenuse muust osast selle
    lähtekoodi
    tuletada; (ii) eemaldada, muuta, peita või varjata mis tahes autoriõiguse märget, kaubamärki või muud omandiõiguse
    märget, mis on Teenuse külge kinnitatud, selles avaldub või on muul moel Teenusega seotud; (iii) luua või üritada
    luua mis tahes toodet või teenust, mis on Teenusega sarnane või sellega muul moel konkureeriv või mida
    Teenuseosutaja kavandab luua, tagada või heaks kiita; (iv) liituda Teenusega või muuta Kliendi liitumist
    automatiseeritud vahenditega.
  </p>
  <p>
    8. Esindaja kohustused
  </p>
  <p>
    8.1. Iga isik, kes liitub Kliendi eest Teenusega või esindab Klienti muul moel Lepinguga liitumisel, tagab
    isiklikult Teenuseosutajale, et ta on volitatud Klienti esindama ja et Kliendiga sõlmitud Leping on siduv.
  </p>
  <p>
    8.2. Iga taoline esindaja tagab käesolevaga Teenuseosutajale ja Teenuseosutaja kinnitab, et kui käesolev Leping
    osutub kehtetuks esindaja puudulike volituste tõttu või kui selgub, et esindaja on sõlminud käesoleva Lepingu
    olematu Kliendiga, siis loetakse, et esindaja sõlmis Lepingu enda nimel ning Leping hakkab kehtima (alguses)
    Teenuseosutaja ja vastava esindaja vahel.
  </p>
  <p>
    9. Kasutajakonto, kasutajanimi ja parool
  </p>
  <p>
    9.1. Klient on ainuisikuliselt ja täielikult vastutav tema Kasutajakontoga teostatud toimingute eest ning peab
    viivitamatult Teenuseosutajat teavitama oma Kasutajakonto turvalisuse rikkumisest või loata kasutamisest.
  </p>
  <p>
    9.2. Osapooled nõustuvad, et seoses Kliendi kasutajanime ja parooliga vastutab Klient (i) oma kasutajanime ja
    parooli salajas hoidmise eest, (ii) mis tahes ja kõigi tegevuste eest, mida viivad läbi isikud, kellele Klient
    annab
    ligipääsu või kes muul moel kasutavad tema kasutajanime ja parooli ja (iii) mis tahes ja kõigi kasutajanime ja
    parooli kasutamise või kuritarvitamise tagajärgede eest.
  </p>
  <p>
    9.3. Teenuseosutaja ei vastuta mis tahes kaotuse, kahju või muu tagajärje eest, mille on põhjustanud Kliendi
    Kasutajakonto kasutajanime ja parooli kasutamine kõrvalise isiku poolt.
  </p>
  <p>
    10. Intellektuaalne omand ja varalised õigused
  </p>
  <p>
    10.1. Klient on teadlik, et kogu Teenusega seotud Intellektuaalne omand kuulub Teenuseosutajale. Klient ei omanda
    mingit õigust, voli või huvi eelpool nimetatud Intellektuaalse omandi üle või üldiselt Teenuse üle, välja arvatud
    piiratud kasutusõigus, mis on selgesõnaliselt käesolevas Lepingus sätestatud. Mis tahes muud õigused, mis ei ole
    siinkohal selgesõnaliselt sätestatud, loetakse kehtetuks.
  </p>
  <p>
    10.2. Lepingu sõlmimist, Lepingu sätteid ega mis tahes Lepingus toodud Osapoolte kohustuste rikkumist ei saa
    tõlgendada nõnda, et see annaks Kliendile õiguse omada või omandada varalist õigust (sh. väärtpaberiõigus,
    pandiõigus või mis tahes pantimisõigus) Teenuseosutajale kuuluvate esemete või varade üle.
  </p>
  <p>
    10.3. Teenuseosutaja suhtub lugupidavalt teiste Intellektuaalsesse omandisse ning võib asjakohastel põhjustel ning
    oma äranägemisel blokeerida või lõpetada sellise Kasutajakonto kasutamine, mis rikub teiste õigusi.
  </p>
  <p>
    11. Garantiist loobumine
  </p>
  <p>
    11.1. Kõikidest tingimustest, kinnitustest ja garantiidest, mida ei ole käesolevas Lepingus sätestatud (sealjuures
    ja ilma piiranguteta turustatavus, kindlaks otstarbeks sobilikkus, riive puudumine ja tingimused, mis võivad
    tekkida
    kaubandusliku tegevuse või kasutuse tõttu), võib Teenuseosutaja täielikus ulatuses keelduda.
  </p>
  <p>
    11.2. Teenuseosutaja ei anna mingit garantiid, et (i) Teenus vastab Kliendi ja Lõppkasutaja vajadustele või
    ootustele, (ii) Teenusele ligipääs ja Teenuse kasutamine on häirimatu, õigeaegne, turvaline või veatu, (iii) mis
    tahes Teenuse viga parandatakse, (iv) Teenus või mis tahes Teenusele ligipääsemise viis on vaba pahavarast või
    muudest kahjulikest komponentidest; või (v) seoses mis tahes kolmanda osapoole tarkvara, sisu, materjali,
    informatsiooni, keskkonna või muu kolmanda osapoole ressursi või teenuse osas, mida Klient või Lõppkasutaja võivad
    omandada, kasutada, millele ligi pääseda või millega kokku puutuda.
  </p>
  <p>
    11.3. Iga Osapool kinnitab, et teine Osapool on sõlminud Lepingu toetudes eelmainitud lahtiütlemistele ja et
    eelmainitud lahtiütlemised on peamiseks aluseks Osapoolte vaheliste vaidluste lahendamisel.
  </p>
  <p>
    12. Vastutuse piiramine
  </p>
  <p>
    12.1. Teenuseosutaja ei vastuta Kliendi, ühegi Lõppkasutaja ega ühegi isiku, kes esitab pretensiooni Kliendi või
    Lõppkasuta kaudu, mis tahes kaotuse, kahjustuse, kulu või muu tagajärje eest, mis on tingitud (i) Teenuse
    kasutamisest või selle puudulikkusest, (ii) Teenuse omadustest, (iii) vajadusest Teenuse jaoks hankida või Teenuse
    jaoks hangitud asendavatest toodetest või teenustest või mis tahes muu toode, teenus või muu hüve, mida on Teenuse
    kaudu saadud, omatud, omandatud või muul moel kasutatud, (iv) mis tahes Teenusest või selle kaudu saadud sõnumist
    või muust kommunikatsioonist või sõlmitud tehingust, (v) autoriseerimata juurdepääsust või tehtud muudatustest
    Kliendi ja tema Lõppkasutajate ülekannetele või andmetele, (vi) sellise isiku väidetest või käitumisest, kes omab
    Teenusele ligipääsu, (vii) mis tahes muust Teenusega seotud asjaolust;
  </p>
  <p>
    hoolimata sellest, kas neid kahjusid on kannatatud otseselt või kaudselt või need leiavad aset vahetult või
    tegevusest tulenevalt, ja kas need tekivad lepingu alusel, lepinguväliselt või muul viisil;
  </p>
  <p>
    tagades sealjuures, et see punkt ei välista nõudeid Kliendi otsese rahalise kahju korral, kui selle on põhjustanud
    Teenuseosutaja tahtlik või äärmiselt hooletu Lepingu rikkumine, ja et Teenuseosutaja vastutus, kas lepingu alusel
    või lepingu väliselt või muul moel, ei ületa mitte mingil juhul tasu, mis on võrdväärne summaga, mille Klient on
    tasunud Teenuseosutajale viimase kaheteistkümne kuu jooksul, alustades vahetult rikkumisele eelnenud kuust.
  </p>
  <p>
    12.2. Mis tahes kaebusega, mis Kliendil võib seoses käesoleva Lepinguga tekkida, tuleb asjakohastesse
    instantsidesse
    pöörduda ühe aasta jooksul alates kaebuse tekkimise hetkest, vastasel korral on see jäädavalt aegunud.
  </p>
  <p>

  </p>
  <p>
    13. Vääramatud jõud
  </p>
  <p>
    13.1. Ükski Osapool ei vastuta oma kohustuste rikkumise eest olukordades, mis ei ole tema kontrolli all,
    sealhulgas
    näiteks loodusõnnetus, valitsuse tegevus, sõda, rahutused, terrorirünnakud, streigid, interneti teenusepakkuja
    tõrge
    või mis tahes muu olukord, mis kvalifitseerub vääramatu jõu alla. Ükski neist loetletud põhjustest aga ei vabasta
    Osapoolt maksekohustusest.
  </p>

  <p>
    14. Kahju hüvitamine
  </p>
  <p>
    14.1. Klient kaitseb või vastavalt oma võimalustele lahendab (põhjustamata mis tahes kahju või kulu
    Teenuseosutajale) mis tahes kolmanda osapoole kohtuasja või muu menetluse, mis on algatatud Teenuseosutaja vastu,
    mis on tingitud või muul moel tekkinud Kliendi või mis tahes Lõppkasutaja (i) teenuse kasutamisest, (ii) sisust,
    (iii) isiklike andmete väärkasutamisest, või (iv) Intellektuaalse omandi seaduse rikkumisest.
  </p>

  <p>
    15. Andmekaitse
  </p>
  <p>
    15.1. Klient nõustub, et Teenuseosutaja võib koguda Kliendi ja Lõppkasutajate kohta informatsiooni (i) Kliendi
    liitumisel Lepinguga või muudatuse tegemisel litsentsis (informatsioon, mis on sisestatud liitumisvormi); (ii) kui
    nad külastavad Teenuseosutaja kodulehte (sessiooni informatsioon, Teenuseosutaja kodulehe sirvimise ajalugu,
    IP-aadress, teatud tarkvara ja riistvara atribuudid) või täidavad Teenuse kaudu ankeete (selle käigus edastatud
    informatsioon); (iii) kui nad sisenevad või kasutavad Teenust (ligipääsu kasutamise asukoht, viis, eesmärk ja
    kestvus); ja (iv) või kui nad muul moel annavad teadlikult Teenuseosutajale informatsiooni (andmesubjektide
    avaldatud informatsioon). Teenuseosutaja kodulehe külastamise käigus võidakse külastaja seadmele salvestada
    „küpsiseid“.
  </p>
  <p>
    15.2. Klient nõustub ja tagab Teenuseosutajale, et Lõppkasutajad nõustuvad, (i) nende isiklike andmete
    töötlemisega
    (lähtudes kehtivast andmekaitseseadusest) Teenuseosutaja poolt eesmärgiga täita Lepingus sätestatud kohustusi ja
    vajaduse korral jõustada oma lepingujärgseid õigusi; (ii) et nende isiklike andmeid võidakse töödelda nii nende
    asukohas kui ka väljaspool; (iii) et Teenuseosutaja ei avalda nende isiklike andmeid mis tahes kolmandale
    osapoolele, välja arvatud oma korporatsiooni liikmetele, kui see ei ole seaduse või käesoleva Lepingu täitmisega
    vastuolus.
  </p>
  <p>
    15.3. Teenuseosutaja tagab Kliendile, et isiklike andmete turvaliseks töötlemiseks rakendatakse äriliselt
    mõistlike
    meetmeid. Teenuseosutaja poolt Teenuse osutamisel kogutavaid Kliendi andmeid on Teenuseosutaja õigus muuhulgas
    kasutada masinõppe eesmärgil.
  </p>
  <p>
    15.4. Teenuseosutaja ei ole kohustatud jälgima või omama ligipääsu kliendikontodele, kuid ta võib seda teha juhul,
    kui selleks on piisavalt põhjust (näiteks eesmärgiga ennetada illegaalset või kahjulikku tegevust või anda
    kliendituge).
  </p>
  <p>
    15.5. Kui Lõppkasutaja või Klient otsustab ühineda Teenuseosutaja uudiskirjaga, siis võib Teenuseosutaja kasutada
    nende isiklikke andmeid eesmärgiga saata neile informatsiooni toodete, teenuste, reklaami ja ürituste kohta, mis
    Teenuseosutaja arvamuse kohaselt võiks neile huvi pakkuda. Iga taolise liitumise saab soovi korral tühistada.
  </p>
  <p>
    15.6. Teenuseosutajal pole õigust müüa (või tasuta edastada) kliendiandmeid või nendest andmeid koondatud teavet
    kolmandatele isikutele mis tahes ärilises või mitteärilises valdkonnas. Teenuseosutajal ei ole lubatud kliendi
    andmeid avalikult esitada ilma Kliendikirjaliku loata.
  </p>
  <p>
    16. Muutmine ja peatamine
  </p>
  <p>
    16.1. Teenuseosutaja võib muuta Teenust või mis tahes Lepingu osa igal ajal oma äranägemise järgi ning on
    kohustatud
    taolistest muudatusest Klienti teavitama, kui Klient logib sisse oma Kasutajakontoga. Juhul, kui Klient nende
    muudatustega ei nõustu, on tal õigus Teenuse kasutamine peatada. Vastasel korral jõustuvad taolised muudatused
    Kliendi jaoks eelmainitud teavituse kaudu.
  </p>
  <p>
    16.2. Teenuseosutajal on õigus katkestada kõik või mis tahes osa Teenusest, teatades sellest 30 päeva ette.
  </p>
  <p>
    16.3. Teenuseosutajal on õigus koheselt peatada kas osaliselt või tervenisti käesolevas Lepingus kirjeldatud
    teenuse
    pakkumine kui kohtu määrus, võimuesindajate otsus või pädev reguleeriv asutus nõuab ajutiselt või lõplikult
    siinkirjeldatud kohustuste täitmise peatamist.
  </p>
  <p>
    17. Lõpetamine
  </p>
  <p>
    17.1. Iga Osapool võib tühistada Lepingu igal ajal, teavitades teist Osapoolt sellest 30 päeva ette.
  </p>
  <p>
    17.2. Kui Leping on katkestatud teise Osapoole olulise rikkumise tõttu, ei pea Lepingu lõpetamiseks sellest ette
    teatama (st Leping tühistatakse koheselt).
  </p>
  <p>
    17.3. Lepingu oluliseks rikkumiseks loetakse (muuhulgas) seda, kui: (i) Osapool rikub mis tahes siinkirjeldatud
    kohustust ja tal ei õnnestu seda rikkumist lõpetada või heastada kahe nädala jooksul pärast seda, kui teine
    Osapool
    on teinud selleks ettekirjutuse, kirjeldades rikkumist ning nõudes selle lõpetamist või heastamist; (ii) kui
    Osapool
    rikub pidevalt mis tahes siinkirjeldatud kohustust ja seda rikkumist ei ole võimalik heastada; (iii) kui ühe
    Osapoole rikkumine jätab teise Osapoole ilma kogu hüvest või olulisest osast hüvest, millele Osapoolel oli
    käesolevaga õigus.
  </p>
  <p>
    17.4. Mis tahes Lepingu katkestamine ei tohi piirata Osapoolte õigusi ja heastamise vahendeid, mis on seoses
    katkestamisega lisandunud.
  </p>
  <p>
    17.5. Klient teadvustab ja nõustub, et iga Lepingu lõpetamise korral (i) lõppevad kõik õigused, mis Kliendile
    siinkohal on tagatud; (ii) Klient peatab kõik Lepinguga volitatud tegevused; (iii) peab Klient koheselt tasuma
    kõik
    summad, mis kuuluvad käesolevaga Teenuseosutajale; (iv) Klient ei saa mingit tagastust või asendust
    arveldusperioodil kasutamata jäänud aja eest, ühegi litsentsi või liitumistasu eest, mis tahes sisu või andmete
    eest, mis on seotud tema kontoga, või mis tahes muu eest.
  </p>
  <p>
    18. Kohaldatav õigus ja vaidluste lahendamine
  </p>
  <p>
    18.1. Lepingu täitmisest tulenevad vaidlused ja lahkarvamused lahendatakse läbirääkimiste teel.
  </p>
  <p>
    18.2. Kui läbirääkimised ebaõnnestuvad, kuuluvad vaidlused lahendamisele Harju Maakohtus.
  </p>
  <p>
    18.3. Lepingule kohaldub Eesti Vabariigi õigus.
  </p>
  <p>
    19. Mitmesugust
  </p>
  <p>
    19.1. Käesolevaga nõustub Klient, et Teenuseosutaja kaasab tema nime kliendinimekirja, tagades, et Klient ei ole
    nimekirjas ainuisikuliselt.
  </p>
  <p>
    19.2. Mis tahes käesoleva lepinguga seoses õigeaegselt maksmata jäänud summale rakendatakse viivist kakskümmend
    protsenti (20%) aasta kohta kuni makse laekumiseni.
  </p>
  <p>
    19.3. Mis tahes muutmise ja ülekandmise kulu, mis on seotud käesoleva lepingu maksetega, kannab maksja (mitte
    makse
    saaja).
  </p>
  <p>
    19.4. Olles piisavalt tutvunud Lepingu tingimustega eraldiseisvalt ja koos teiste tingimustega, kinnitab iga
    Osapool
    siinkohal teisele Osapoolele, et nad peavad Lepingut ausaks ja mitte mingil moel ekspluateerivaks või kahjulikuks.
  </p>
  <p>
    19.5. Käesolevaga nõustub Klient, kes asub ja/või töötleb Euroopa Liidus füüsiliste isikute isikuandmeid, et ei
    sisesta Teenusesse ega edasta Teenuse vahendusel isikuandmeid ilma, et oleks eelnevalt sõlminud lepingu osapoolte
    vahel andmetöötluslepingu (DPA). Kui lepingu osapoolte vahel ei sõlmita andmetöötluslepingut, siis vastutab Klient
    täies mahus (100%) toimuva töötluse eest kasutades Teenust või sellega seotud teisi Brightsparki poolt pakutavaid
    Tooteid või Teenuseid.
  </p>
  <p>
    19.6. Käesolevad Teenusetingimused kehtivad alates 01.06.2020.
  </p>
</ng-template>

<ng-template #en>
  <p>
    These general terms and conditions of service agreement (hereinafter ‘the Service Terms and Conditions’) constitute
    an
    integral part of the service agreements to be entered into in respect of the services to be sold to all persons (the
    Customer) by Brightspark OÜ.
  </p>

  <p>
    1. General provisions
  </p>
  <p>
    1.1. The Service Terms and Conditions apply unless otherwise mutually agreed between the Service Provider and the
    Customer in the Agreement or annexes thereto.
  </p>
  <p>
    1.2. Depending on the context, the definitions set out in singular in the Service Terms and Conditions may also mean
    plural and vice versa.
  </p>
  <p>
    1.3. The headings in the Service Terms and Conditions have been inserted for convenience of reading only and have no
    impact on the interpretation of the content of any of the provisions.
  </p>
  <p>
    1.4. Each clause of the Service Terms and Conditions is interpreted along with other provisions of the Agreement
    considering the idea and purpose of the Agreement. The invalidity of an individual provision has no impact on the
    validity, legality or enforceability of the rest of the Agreement.
  </p>

  <p>
    2. Definitions
  </p>
  <p>
    2.1. Whenever used in this Agreement, the following capitalised terms have the following meanings:
  </p>
  <p>
    “Agreement” – these service terms and conditions (as updated from time to time), terms and conditions of the
    subscription form and other terms and conditions related to the Service that the Customer may agree with when
    prompted
    to by the Service Provider.
  </p>
  <p>
    „Content“ – the information (including text, images, audio and video material), functionality, technical resources,
    the amount of time and work spent by the Service Provider and Service availability provided by the Service Provider.
  </p>
  <p>
    „Customer“ – any person or entity to whom the Service is provided.
  </p>
  <p>
    “End User” – any natural person whom the Customer grants their consent or provides access to the use of the Service.
  </p>
  <p>
    „Intellectual Property“ – all existing and future trademarks, service marks, domain names and business names, all
    the
    rights pertaining to inventions, design, databases and information subject to business secrecy (including, without
    limitation, any business information and know-how), copyrights, moral rights and all other assets and benefits
    commonly regarded as intellectual property, whether registered or not.
  </p>
  <p>
    „Party“ – a common term denoting both the Customer and the Service Provider
  </p>
  <p>
    „Registration Data“ – the information provided by the Customer when subscribing to the Service, requesting a change
    of
    the Lexi.Market standard function or filling in forms via the Service, including the Customer’s full name or
    business
    name, registration/company/file/business or personal identification code (or equivalent), permanent residence,
    address, e-mail address, phone number and such other information as the Service Provider may reasonably request from
    the Customer.
  </p>

  <p>
    „Service“ – web-based software service (SaaS), access to which is provided through this website, availability of the
    Content and customer support.
  </p>
  <p>
    „Subscription Form“ – the online form, via which the Customer can subscribe to the Service or request a change of
    the
    licence or such other subscription or modification form as the Parties may fill in for the aforesaid purpose.
  </p>
  <p>
    „Software“ – the business software applications included in the Service with features described on this website,
    including such modifications and replacements thereof as the Service Provider may apply at their discretion from
    time
    to time.
  </p>
  <p>
    „Service Provider“ – Brightspark OÜ, a company established under the laws of the Republic of Estonia, having its
    principal place of business in Tallinn, the Republic of Estonia.
  </p>
  <p>
    „User Account“ – the account hosted or administered by the Service Provider and provided to the Customer through the
    Service for the purpose of enabling the Customer to use the Service.
  </p>
  <p>
    2.2 ‘Herein’, ‘hereto’, ‘hereof’, ‘hereunder’ and other similar expressions, wherever used in the Agreement, refer
    to
    this Agreement.
  </p>
  <p>
    2.3. This Agreement in its current version and the information published on the website, in so far as it describes
    the
    Service and prices, constitute the whole agreement entered into between the Parties and supersede any other
    agreements, arrangements, and understandings agreed between the Parties in this respect beforehand.
  </p>
  <p>
    2.4. In the case of conflict or ambiguity between the terms and conditions of this Agreement and any statement,
    representation or other information published on the website, the terms and conditions described in the Agreement
    will
    prevail.
  </p>
  <p>
    3. Term
  </p>
  <p>
    3.1. This Agreement takes effect between the Parties at the moment when the Customer has subscribed to the Service,
    obtained a User Account or started using the Service, whichever is the earliest. The Agreement is entered into for
    an
    indefinite term and both Parties have the right to terminate the Agreement under the terms and conditions provided
    by
    this document.
  </p>
  <p>
    4. Licence
  </p>
  <p>
    4.1. Relying on the Customer’s Registration Data and the terms and conditions provided by this document, the Service
    Provider grants to the Customer and the latter agrees to a limited non-transferable non-exclusive licence for using
    this software and the Content prescribed therewith.
  </p>
  <p>
    4.2. The Software and the Content may only be used for the purpose prescribed for using the Service and in the
    period
    when the Agreement is in force between the Parties. The Customer agrees that they have no right to access the
    Software
    with the source code or unlocked code or through comments.
  </p>
  <p>
    4.3. Being guided by the good practice of assignment and sub-licensing, the Parties agree as follows: (i) the
    Customer
    has no right to sub-license or assign the benefits or obligations of this Agreement (either in part or in full) and
    the Customer does not allow the Software or any other part of the Service to become the subject of any charge, lien
    or
    encumbrance without the prior explicit consent of the Service Provider; the Service Provider may sub-license,
    assign,
    charge or otherwise dispose of their rights and obligations in respect of the Customer provided that they give the
    Customer reasonable notice thereof.
  </p>
  <p>
    4.4. The Service Provider hosts the Software in their personal or rented servers.
  </p>
  <p>
    5. Service
  </p>
  <p>
    5.1. The Service Provider provides the Customer with the Service throughout the term of the Agreement with
    commercially reasonable efforts in accordance with the licence ordered by the Customer.
  </p>
  <p>
    5.2. The Customer agrees that (i) the Service has not been designed to meet their individual requirements; (ii) the
    Service may have technical or other problems from time to time and the Service might not necessarily function
    uninterruptedly or without errors; (iii) the Service is not fault-tolerant and has not been designed for using for
    inherently dangerous activities, such as (for example) activities related to ‘main sources of danger’, traffic
    control
    or life support systems, for handling hazardous substances or for other activities where the failure of the Service
    could lead to death, bodily injury or environmental damage.
  </p>
  <p>
    5.3. The Service Provider has agreed to provide high-quality user support and endeavours to respond to most of the
    questions posed to the customer support within 3 working days. However, the Service Provider does not guarantee the
    exact time during which the support is provided.
  </p>
  <p>
    5.4. The Service (including the Software and Content) is provided in its existing and available form.
  </p>
  <p>
    5.5. The Customer chooses and uses the Service on their own responsibility, and the Customer and the End User are
    liable for the exposure to, down- and upload, transmission and possession of the information, programmes or other
    objects that they reach through or due to the Service.
  </p>
  <p>
    5.6. The Service Provider is not required to enhance, modify or replace any part of the Service or continue
    developing
    or releasing new versions thereof. The Service Provider is not liable for the functioning of the third-party
    software
    solutions used when using the Service. The Service Provider has the right to monitor and save the use of the Service
    by the Customer.
  </p>
  <p>
    6. Payment
  </p>
  <p>
    6.1. The Customer warrants that, by subscribing to the ordered licence, they agree to the fees, prices and
    settlement
    periods as (i) described on the website at the time of subscription, and (ii) updated from time to time in
    accordance
    with the terms and conditions of the Agreement.
  </p>
  <p>
    6.2. Unless otherwise agreed at the time of subscription, the Customer’s settlement period is 30 days, starting on
    the
    day immediately following their subscription to the corresponding licence or, if a free trial period has been
    granted
    to the Customer, on the day immediately following the end of the trial period.
  </p>
  <p>
    6.3. The Service Provider may enhance or otherwise modify the Service for additional fees and may modify, with a
    term
    of 30 days for advance notice, modify the fees, prices and settlement period applicable to the licence ordered by
    the
    Customer. If the Customer does not agree with the respective modifications, they will have the right to give up
    ordering the licence by terminating the use of the Service.
  </p>
  <p>
    6.4. The Service is paid for in advance before the start of each settlement period and the payment is made by a
    credit
    card (by way of exception by a bank transfer). The Customer agrees that late payment may result in the suspension of
    the Service or termination of the Agreement.
  </p>
  <p>
    6.5. If the Customer gives up or modifies the licence or terminates the Agreement before the expiry of a current
    payment period, the Customer will not be refunded any of the payments relating to that payment period.
  </p>
  <p>
    6.6. Advance payments for future payment periods are not subject to refund.
  </p>
  <p>
    6.7. All the fees and prices are exclusive of value added tax, sales tax, and other encumbrances. The Customer
    assumes
    sole liability for all the taxes and other encumbrances that may be applied to purchasing or using the Service.
  </p>
  <p>
    7. Obligations of Customer
  </p>
  <p>
    7.1. The Customer must be a person (either natural or legal), or an entity with passive legal capacity.
  </p>
  <p>
    7.2. All the Registration Data provided by the Customer must be true, accurate and up-to-date, and the Customer
    agrees
    to promptly correct any Registration Data that become outdated or incorrect. The Service Provider may, but is not
    required to, verify the Customer’s Registration Data and may rely on them without any verification.
  </p>
  <p>
    7.3. The Customer must follow all the laws, regulations and orders applicable to the Customer upon using the
    Service.
  </p>
  <p>
    7.4. The Customer represents and warrants to the Service Provider that the Customer has the right to all the
    information (including the text, images, audiovisual material and other content) that they upload, transmit or store
    via the Service.
  </p>
  <p>
    7.5. The Customer warrants that neither they nor their End Users use the Service for transmitting unsolicited
    information or for uploading, transmitting, sending, running or storing harmful codes, malware or materials of
    illegal
    content.
  </p>
  <p>
    7.6. Any of the Customer’s materials that conflicts with the provisions of this Agreement may be removed, prohibited
    and/or destroyed by the Service Provider at their discretion without any warning or notice.
  </p>
  <p>
    7.7. Without excluding or limiting any of the Customer’s statutory obligations, the Customer warrants to the Service
    Provider that the Customer will not attempt and will not allow their End Users to attempt to (i) modify, reproduce
    or
    prepare derivative works from, or decompile, reverse engineer or otherwise endeavour to derive a source code from
    the
    Software or another part of the Service; (ii) remove, alter, hide or obscure any copyright notice, trademark or
    notice
    of any other right of ownership embedded in, appearing on or otherwise pertaining to the Service; (iii) create or
    attempt to create any product or service that is similar or otherwise competitive to the Service or that the Service
    Provider intends to create, warrant or approve; (iv) subscribe to the Service or modify the Customer’s subscription
    by
    automated means.
  </p>
  <p>
    8. Obligations of representative
  </p>
  <p>
    8.1. Each person subscribing the Customer to the Service or otherwise representing the Customer in entering into the
    Agreement personally warrants to the Service Provider that they are authorised to represent the Customer and that
    the
    Agreement entered into with the Customer is binding.
  </p>
  <p>
    8.2. Each such representative hereby ensures to the Service Provider, and the latter warrants, that if this
    Agreement
    proves to be void due to the representative’s lack of authority or if it appears that the representative has entered
    into this Agreement with a non-existent Customer, it will be deemed that the representative entered into the
    Agreement
    on their own behalf and the Agreement will take effect (ab initio) between the Service Provider and the aforesaid
    representative.
  </p>
  <p>
    9. User Account, username and password
  </p>
  <p>
    9.1. The Customer assumes sole and full liability for the operations performed with their User Account and must
    immediately notify the Service Provider of any breach of security or unauthorised use of their User Account.
  </p>
  <p>
    9.2. The Parties agree that with respect to the Customer’s username and password the Customer is liable for (i)
    maintaining the confidentiality of their username and password, (ii) any and all actions by the persons whom the
    Customer gives access to or who otherwise use their username and password, and (iii) any and all consequences of use
    or misuse of the username and password.
  </p>
  <p>
    9.3. The Service Provider assumes no liability for any loss, damage or other consequences caused by the use of the
    Customer’s User Account, username and password by an unauthorised person.
  </p>
  <p>
    10. Intellectual Property and economic rights
  </p>
  <p>
    10.1. The Customer is aware that all the Service-related Intellectual Property belongs to the Service Provider. The
    Customer has no right, title or interest in respect of the aforesaid Intellectual Property set out above or in
    respect
    of the Service in general, except for the limited right of use explicitly provided by this Agreement. Any other
    rights
    not explicitly provided herein are deemed ineffective.
  </p>
  <p>
    10.2. Entry into or provisions of the Agreement or breach of any of the obligations of the Parties set out in the
    Agreement cannot be interpreted as granting the Customer the right to hold or acquire an economic right (incl.
    security interest, pawn or any other right of security) to items or assets belonging to the Service Provider.
  </p>
  <p>
    10.3. The Service Provider respects the Intellectual Property of others and may, for appropriate reasons and at
    their
    discretion, disable or terminate the use of such a User Account that breaches the rights of others.
  </p>
  <p>
    11. Disclaimer of warranties
  </p>
  <p>
    11.1. The Service Provider may withhold in full all the terms and conditions, representations and warranties other
    than those provided by this Agreement (including, without limitation, marketability, fitness for a particular
    purpose,
    non-infringement and the terms and conditions that may arise from commercial activities or use).
  </p>
  <p>
    11.2. The Service Provider does not provide any warranty (i) that the Service meets the Customer’s and End User’s
    needs or expectations, (ii) that access to and use of the Service is uninterrupted, timely, secure or error-free,
    (iii) that any defects in the Service are corrected, (iv) that the Service or any means by which the Service is
    accessed is free of malware or other harmful components; or (v) with respect to any third-party software, content,
    material, information, environment or other third-party resources or services that the Customer or End User may
    acquire, use, access or be exposed to.
  </p>
  <p>
    11.3. Each Party warrants that the other Party has entered into the Agreement relying on the above disclaimers and
    that the aforesaid disclaimers serve as the main basis for resolving disputes between the Parties.
  </p>
  <p>
    12. Limitation of liability
  </p>
  <p>
    12.1. The Service Provider is not liable to the Customer, any End User or any person claiming through the Customer
    or
    End User for loss, damage, expenses or other consequences resulting from (i) the use or the inability to use the
    Service, (ii) the properties of the Service, (iii) the need to procure or the procurement of substitute products or
    services for the Service or for any product, service or other benefit received, owned, possessed or otherwise used
    through the Service, (iv) any message or other communication received or transaction entered into through or from
    the
    Service, (v) unauthorised access to or alteration of the Customer’s or any End User’s transmissions or data, (vi)
    the
    statements or conduct of any person having access to the Service, (vii) any other matter relating to the Service;
  </p>
  <p>
    regardless of whether the damage is suffered directly or indirectly or is immediate or consequential, and whether
    the
    damage arises in contract, tort or otherwise;
  </p>
  <p>
    provided, however, that this clause does not prevent claims for direct financial loss suffered by the Customer due
    to
    the Service Provider’s intentional or grossly negligent breach of the Agreement, and that the total liability of the
    Service Provider, whether in contract, tort or otherwise in no circumstances exceeds a sum equal to the amount that
    the Customer has paid to the Service Provider during the twelve months immediately preceding that month in which the
    breach occurred.
  </p>
  <p>
    12.2. Any claim that the Customer may have in connection with this Agreement must be filed with the appropriate
    authorities within one year after such claim arose, otherwise it has expired for good.
  </p>
  <p>

  </p>
  <p>
    13. Force majeure
  </p>
  <p>
    13.1. No Party is liable for breaching their obligations in circumstances beyond their control, such as, for
    example,
    a natural disaster, act of government, war, riots, terrorist attacks, strike, Internet service provider failure or
    any
    other circumstance qualifying as force majeure. However, none of the reasons listed above release a Party from their
    payment obligation.
  </p>

  <p>
    14. Indemnification
  </p>
  <p>
    14.1. The Customer defends, or at its option resolves (without causing any damage or expenses to the Service
    Provider), any third party lawsuit or other proceedings brought against the Service Provider and caused by or
    otherwise arisen out of the Customer’s or any End User’s (i) use of the Service, (ii) content, (iii) misuse of
    personal data, or (iv) infringement of Intellectual Property law.
  </p>

  <p>
    15. Data protection
  </p>
  <p>
    15.1. The Customer agrees that the Service Provider may collect information about the Customer and End Users (i)
    upon
    the Customer’s entry into the Agreement or change of the licence (the information entered on the Subscription Form);
    (ii) when they visit the Service Provider’s website (session information, browsing history at the Service Provider’s
    website, IP address, certain software and hardware attributes) or fill in forms via the Service (the information
    thus
    provided); (iii) when they access or use the Service (the location, manner, purpose and duration of such use or
    access); and (iv) when they otherwise knowingly make the information available to the Service Provider (the
    information disclosed by the data subjects). When visiting the Service Provider’s website, ‘cookies’ may be stored
    on
    the visitor’s device.
  </p>
  <p>
    15.2. The Customer agrees and warrants to the Service Provider that the End Users agree (i) to the processing of
    their
    personal data (following the Data Protection Act in force) by the Service Provider for the purpose of performing
    their
    obligations provided by the Agreement and, if necessary, enforcing their rights hereunder; (ii) that their personal
    data may be processed in the country of their domicile as well as outside it; (iii) that the Service Provider will
    not
    disclose their personal data to any third party besides the members of their corporate group if this is not in
    conflict with law or the performance of this Agreement.
  </p>
  <p>
    15.3. The Service Provider warrants to the Customer that commercially reasonable measures will be taken to ensure
    that
    personal data are processed securely. The Service Provider has the right to use the Customer’s data collected by the
    Service Provider when providing the Service inter alia for the purpose of machine learning.
  </p>
  <p>
    15.4. The Service Provider is not required to monitor or access customer accounts, but may do so if there is a
    sufficient reason therefor (for example, in order to prevent illegal or harmful activity or to provide customer
    support).
  </p>
  <p>
    15.5. If an End User or the Customer chooses to join the Service Provider’s newsletter, the Service Provider may use
    their personal data to send them information about products, services, advertising and events that the Service
    Provider thinks may be of interest to them. Upon request, any such subscription can be cancelled.
  </p>
  <p>
    15.6. The Service Provider does not have the right to sell (or passfor free)the customer data or information that
    has been aggregated from the data to any third party in any commercial or non-commercial matter. The Service
    Provider is not allowed to publicly present the customer data without such written permission between the
    counterparts.
  </p>
  <p>
    16. Modification and suspension
  </p>
  <p>
    16.1. The Service Provider may modify the Service or any part of the Agreement at any time at their discretion and
    is
    required to notify the Customer of such modifications when the Customer logs on to their User Account. If the
    Customer
    does not agree with the modifications, they have the right to suspend the use of the Service. Otherwise, such
    modifications will take effect with respect of the Customer via the aforesaid notification.
  </p>
  <p>
    16.2. The Service Provider has the right to discontinue all or any part of the Service notifying thereof 30 days in
    advance.
  </p>
  <p>
    16.3. The Service Provider has the right to immediately suspend the provision of the service described in this
    Agreement in part or in full if a court order, decision of representatives of state authorities or a competent
    regulatory authority requests that the obligations hereunder be suspended either temporarily or permanently.
  </p>
  <p>
    17. Termination
  </p>
  <p>
    17.1. Either Party may cancel the Agreement at any time, notifying the other Party thereof 30 days in advance.
  </p>
  <p>
    17.2. If the Agreement is discontinued due to a fundamental breach by the other Party, no advance notice of
    termination of the Agreement must be given (i.e. the Agreement is cancelled immediately).
  </p>
  <p>
    17.3. A fundamental breach of the Agreement is (inter alia) deemed to have occurred if: (i) a Party is in breach of
    any of their obligations hereunder and fails to terminate or remedy such breach within two weeks after the other
    Party
    has issued a precept therefor, describing the breach and requesting termination or remedy thereof; (ii) a Party is
    in
    constant breach of any of their obligations hereunder and the breach cannot be remedied; (iii) a breach by one Party
    deprives the other Party of the whole benefit or a significant part of the benefit, which the latter was entitled
    hereunder.
  </p>
  <p>
    17.4. Any discontinuance of the Agreement may not prejudice the Parties’ rights and remedies that have accrued in
    connection with the discontinuance.
  </p>
  <p>
    17.5. The Customer understands and agrees that in the case of any termination of the Agreement (i) all rights that
    the
    Customer has been granted hereunder will terminate; (ii) the Customer suspends all the activities authorised by the
    Agreement; (iii) the Customer must immediately pay all the amounts due to the Service Provider hereunder; (iv) the
    Customer will receive no refund or exchange for the time not used during the settlement period, for any licence or
    subscription fee, any content or data associated with their account, or for anything else.
  </p>
  <p>
    18. Governing law and resolution of disputes
  </p>
  <p>
    18.1. Any disputes and disagreements arising from the performance of the Agreement are resolved by way of
    negotiations.
  </p>
  <p>
    18.2. If the negotiations fail, the disputes will be subject to resolution in the Harju County Court.
  </p>
  <p>
    18.3. The Agreement is governed by the law of the Republic of Estonia.
  </p>
  <p>
    19. Miscellaneous
  </p>
  <p>
    19.1. The Customer hereby consents to the Service Provider’s inclusion of the Customer’s name in a customer list
    provided that the Customer is not the sole customer listed.
  </p>
  <p>
    19.2. Any amount hereunder not paid when due is subject to default interest at the rate of twenty percent (20%) per
    year until the payment is received.
  </p>
  <p>
    19.3. Any conversion and remittance charges relating to any payment hereunder are borne by the remitter (not the
    beneficiary).
  </p>
  <p>
    19.4. Having sufficiently considered each term of the Agreement both individually and in conjunction with other
    terms,
    each Party hereby confirms to the other that they find the Agreement fair and not oppressive or harmful in any
    respect.
  </p>
  <p>
    19.5. The Customer, who is situated in the European Union and/or processes the personal data of natural persons in
    the
    European Union, hereby agrees to not store personal data in the Service or forward personal data through the Service
    without a previously signed Data Processing Addendum (DPA) between the Parties. If no Data Processing Addendum is
    signed between the Parties, the Customer will be fully (100%) responsible for processing data via the Service or
    other
    related Products or Services offered by Brightspark.
  </p>
  <p>
    19.6. These Service Terms and Conditions are effective as of 1 June 2020.
  </p>
</ng-template>