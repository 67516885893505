<form class="dialog-container">

  <section class="dialog-header">
    <div class="dialog-title">
      <ng-content select="[header-title]"></ng-content>
    </div>
    <ng-content select="[header-image]"></ng-content>
    <div class="dialog-actions">
      <ng-content select="[header-actions]"></ng-content>
      <lxm-button type="transparent" icon="close" (click)="closeDialog()"></lxm-button>
    </div>
  </section>

  <section class="dialog-content" [class.no-margin]="disableContentMargin">
    <ng-content select="[body]"></ng-content>
  </section>

  <section class="dialog-footer">
    <ng-content select="[footer]"></ng-content>
  </section>

  <dialog-spinner *ngIf="!(dataLoaded$ | async)"></dialog-spinner>

</form>
