import { Component, Injector } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ProductCardBase } from '../product-card-base';
import { IProductCardMedicationInfo, IClvDto } from '../product-info/product-info.model';
import { Observable } from 'rxjs';
import { asArray, IEnumValue, asObject, UserAction, ProductCardSection, ProductField , ValidType} from 'src/app/enum';
import { formUtil, keep } from 'src/app/util/form-util';
import { ProductValidity } from 'src/app/_services';
import { LOCAL_STORAGE_KEY } from 'src/app/config';

@Component({
  selector: 'product-medication-card',
  templateUrl: './product-medication.card.html',
  styleUrls: ['./product-medication.card.scss']
})
export class ProductMedicationCard extends ProductCardBase<IProductCardMedicationInfo> {

  public UserAction = UserAction;
  public get title() { return 'cards.products.product_medication_info.title'; }

  public cardSection = ProductCardSection.MedicationInfo;

  public medicationDosageFormOptions: IClvDto[];
  public medicationAdministrationMethodOptions: IClvDto[];
  public medicationPrescriptionTypeOptions: IClvDto[];
  public medicationRiskTypeOptions: IClvDto[];
  public medicationAddictionTypeOptions: IClvDto[];

  public isExpandedKey = LOCAL_STORAGE_KEY.PRODUCT_MEDICATION_CARD_OPEN;

  constructor(injector: Injector, public productValidity: ProductValidity) {
    super(injector, 'medicationInfo', 'productMedicationInfoChanged');
    this.medicationDosageFormOptions = this._productCard.formData.medicationDosageForms;
    this.medicationAdministrationMethodOptions = this._productCard.formData.medicationAdministrationMethods;
    this.medicationPrescriptionTypeOptions = this._productCard.formData.medicationPrescriptionTypes;
    this.medicationRiskTypeOptions = this._productCard.formData.medicationRiskTypes;
    this.medicationAddictionTypeOptions = this._productCard.formData.medicationAddictionTypes;
  }

  public get cardValidType(): ValidType {
    return this.productValidity.getCardStatusValidType(this.cardSection);
  }

  public get shouldExpandSections() {
    return this.productValidity.shouldValidate ? (
      (this.cardValidType === ValidType.Invalid || this.cardValidType === ValidType.Recommended) ? true : false
      ) : false;
  }

  protected _getNormalizedFormData(data: IProductCardMedicationInfo) {
    return formUtil.transformData(data, {
      atcSegment: keep,
      dosageFormId: () => data.dosageForm?.id,
      prescriptionTypeId: () => data.prescriptionType?.id,
      addictionTypeId: () => data.addictionType?.id,
      riskTypeId: () => data.riskType?.id,
      administrationMethods: val => val?.map(x => x.id),
      strength: keep,
      hasSecurityElements: keep,
      riskDescription: keep
    });
  }
  protected _createFormGroup(data: IProductCardMedicationInfo): FormGroup {
    return formUtil.createFormGroup(this._getNormalizedFormData(data));
  }

  protected _createSaveRequest() {
    const data = this.form.value;
    return {
      strength: data.strength,
      hasSecurityElements: data.hasSecurityElements || false,
      riskDescription: data.riskDescription
      ? Object.assign({}, ...data.riskDescription.map(o => ({ [o.language]: o.value })))
      : {},
      
      atcSegmentId: data.atcSegment?.id,
      riskTypeId: data.riskTypeId,
      addictionTypeId: data.addictionTypeId,
      prescriptionTypeId: data.prescriptionTypeId,
      dosageFormId: data.dosageFormId,
      administrationMethods: data.administrationMethods
    };
  }

  protected _saveInternal(req: any): Observable<any> {
    return this._productService.saveMedicationInfo(this.productId, req);
  }

  protected get successMessage(): string {
    return 'cards.products.product_medication_info.save_successful';
  }

}
