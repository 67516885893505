import { NgTemplateOutlet } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Host,
  HostBinding,
  HostListener,
  Input,
  Output,
} from "@angular/core";
import {
  IOverlayItem,
  OverlayViewService,
} from "src/app/_services/overlay-view.service";

@Component({
  selector: "app-overlay-view",
  templateUrl: "./overlay-view.component.html",
  styleUrls: ["./overlay-view.component.scss"],
})
export class OverlayViewComponent {
  @Input() public id: any;
  @Input() public label: string;
  @Input() public icon: string;

  @Output() public onClose: EventEmitter<any> = new EventEmitter();

  public content = null;
  public animation = null;
  public index = 0;

  public animationCloseDuration = 100;

  constructor(public OVService: OverlayViewService) {}

  @HostBinding("class.show-underlay") public get showUnderlay() {
    return this.index === 0;

    // TODO - solve flashing
    // return this.index === this.overlayViewService.overlayCount - 1;
  }

  @HostListener("click") public onClick() {
    this.close();
  }

  public close() {
    if (this.animation) {
      this.OVService.animationClosingIndex = this.index;
      setTimeout((_) => {
        this.OVService.close(this.id);
        this.onClose.emit(this.id);
        this.OVService.animationClosingIndex = null;
      }, this.animationCloseDuration);
    }
  }

  ngOnInit() {
    if (this.id) {
      const overlays = this.OVService.overlays;
      const overlayIndex = overlays.findIndex((x) => x.id === this.id);
      const ref = overlays[overlayIndex];

      if (ref) {
        const { animation } = ref;
        this.content = ref;
        this.index = overlayIndex;

        if (animation) {
          this.animation = animation;
        } else {
          // this.animation = "right-to-left";
          if (this.OVService.overlayCount < 2) {
            this.animation = "right-to-left";
          } else {
            this.animation = "no-animation";
          }
        }
      }
    }
  }
}
