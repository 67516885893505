import { Directive, Output, Input, EventEmitter, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[fileDrop]'
})
export class FileDropDirective {

  @Output() onFileDropped = new EventEmitter<any>();

  @Input() accept: string;
  @Input() multiple: boolean;

  @HostBinding('style.background-color') private background;
  @HostBinding('style.opacity') private opacity;

  public emitFiles(files) {
    if (files.length > 0) {
      this.onFileDropped.emit(files);
    }
  }

  // Click listener
  @HostListener('click', ['$event']) onClick(evt) {

    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = this.accept;
    if (this.multiple) {
      inputElement.multiple = true;
    }

    inputElement.addEventListener('change', () => {
      this.emitFiles(inputElement.files);
    });

    inputElement.dispatchEvent(new MouseEvent('click'));
  }

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#9ecbec';
    this.opacity = '0.8';
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#f5fcff';
    this.opacity = '1';
  }

  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = 'transparent';
    this.opacity = '1';
    this.emitFiles(evt.dataTransfer.files);
  }
}
