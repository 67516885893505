import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { DataTableComponent, DialogComponent } from 'src/app/components';
import { asArray, ClientStatus, HttpMethod, Status, UserAction } from 'src/app/enum';
import { AuthenticationService, DataTableService } from 'src/app/_services';
import { ClientsService, IClientFormData, IClientListFormData, IClientListItem } from 'src/app/_services/clients.service';
import { LocalStorage, LxmMessage } from 'src/app/_helpers';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { PAGE_SIZE, PAGE_SIZE_OPTIONS } from 'src/app/config/pagination';
import { LOCAL_STORAGE_KEY } from 'src/app/config';
import { MatSort } from '@angular/material/sort';
import { ClientsListViewType } from '../../enum/ClientsListViewType';
import { ClientsListState } from '../../state/clients-list.state';
import { ClientsState } from '../../clients.state';

@Component({
  selector: 'clients-list-card',
  templateUrl: './clients-list.card.html',
  host: { 'class': 'data-table-card' },
  styleUrls: ['./clients-list.card.scss']
})
export class ClientsListCard extends DataTableComponent<IClientListItem, any> implements OnInit {

  public LOCAL_STORAGE_KEY = LOCAL_STORAGE_KEY.PAGE_SIZE_CLIENTS_LIST;

  @Output()
  public loaded: EventEmitter<boolean> = new EventEmitter();

  @Input()
  public search: Observable<any>;
  private searchData: any;

  public formData: IClientListFormData;
  public clientTagsById: any;

  public dataUrl = 'clients/griddata';
  public method = HttpMethod.Post;
  public UserAction = UserAction;
  public customFilter: string = this.state.listFilter;

  public ClientsListViewType = ClientsListViewType;

  public table = {
    columns: [],
  };

  private _cols: string[];

  constructor(
    private _clientsState: ClientsState,
    private _location: Location,
    private _clientService: ClientsService,
    private _lxmMessage: LxmMessage,
    private _authService: AuthenticationService,
    public state: ClientsListState,
    route: ActivatedRoute,
    dataService: DataTableService<IClientListItem, any>,
    cd: ChangeDetectorRef) {

    super(dataService, cd);
    this.initialData = route.snapshot.data.initialData;
    this.customFilter = this.state.listFilter;
    this.formData = route?.snapshot?.data?.formData as IClientListFormData;
    this.clientTagsById = this.formData.clientTags
      .reduce((a, v) => ({ ...a, [v.id]: v}), {});

    this._cols = [
      'client_image',
      'client_name',
      'client_tags',
      'client_reg_code',
      'client_address',
      'client_is_retailer',
      'last_event',
      'upcoming_event',
    ];

    if (this._authService.hasRight([UserAction.ManageOthersClients])) {
      this._cols.push('client_visible_in_products_list');
    }

    this._updateColumns();
  }

  private _updateColumns(){
    if (this.state.listViewType == ClientsListViewType.Partners) {
      this.table.columns = this._cols.filter(x => x != 'client_is_retailer' && x != 'client_visible_in_products_list')
    }
    else {
      this.table.columns = this._cols;
    }
  }

  public ngOnInit() {
    super.ngOnInit();

    this.loadAfterViewInit = this.search === null;

    this.dataSource.searchKey$.subscribe(x => {
      if (x) {
        this._location.replaceState(`/clients/${x}`);
      }
    });

    this.dataSource.loading$.subscribe((val: boolean) => {
      this._clientsState.isSearchLoading = val;
    })

  }

  public get isLoading() {
    return this._clientsState.isSearchLoading;
  }

  public createRowFormGroup(row) {

    let visibleInProductsListControl = new FormControl(row.visibleInProductsList)

    let fg = new FormGroup({
      'visibleInProductsList': visibleInProductsListControl
    });

    fg.get('visibleInProductsList').valueChanges.subscribe(x => {

      this._clientService.toggleVisibilityInProductsList(row.id, x)
        .result(null, _ => {

          this._lxmMessage.ok({
            message: 'client.client_visibility_changed'
          });

        }, err => {
          visibleInProductsListControl.setValue(false, { emitEvent: false });
          this._lxmMessage.error({
            message: err.validationSummary,
            args: err.args
          });
        });
    });

    return fg;
  }

  protected getSortParam(sort: MatSort) {
    if (!sort || !sort.active || sort.direction === "") {
      return '';
    }

    let colId = sort.active;

    switch (colId) {
      case 'client_name':
        colId = 'name';
        break;
      case 'last_event':
        colId = 'lastEventDate';
        break;
      case 'upcoming_event':
        colId = 'upcomingEventDate';
        break;
      case 'client_address':
        colId = 'address';
        break;
      case 'client_reg_code':
        colId = 'regNo';
        break;
      case 'client_status':
        colId = 'status';
        break;
      case 'client_is_retailer':
        colId = 'clientType';
        break;
    }

    return colId + ' ' + sort.direction;
  }

  public isListViewActive(view: ClientsListViewType) {
    return this.state.listViewType === view;
  }

  public setListView(toSet: ClientsListViewType) {
    if (this.isLoading || toSet === this.state.listViewType) {
      return;
    }

    this.setListFilter(toSet);
    this.resetPaginator();

    this.state.setListView(toSet);
    this._updateColumns();

    this.load();
  }

  public setListFilter(viewType: ClientsListViewType) {
    const filter = this.state.getListFilter(viewType);
    this.customFilter = filter;
    this.state.listFilter = filter;
  }

  public onSearch(searchData) {
    this.searchData = searchData;
  }

  public onLoad() {
    this.load();
  }

  public limitTags(row: IClientListItem) {
    return row.clientTagIds?.slice(0, 2);
  }

  public getTagClass(tagId: string) {
    const tag = this.clientTagsById[tagId];
    let css = 'color-tag';

    if (tag) {
      css = `${css} color-${tag.color}`;
    }

    return css;
  }

  protected getData() {
    return this.searchData;
  }

  public get hasClients() {
    return this.dataSource.data?.length ? true : false;
  }

}
