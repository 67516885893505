import { Component, Output } from "@angular/core";
import { DataTableFilter } from "../data-table-filter";
import { FilterType, TextFilterValueType } from "../../../../enum";

@Component({
  selector: "text-filter",
  templateUrl: "./text-data-table-filter.component.html",
  styleUrls: [ "./text-data-table-filter.component.scss" ]
})
export class TextDataTableFilterComponent extends DataTableFilter {
  @Output()
  public value: string = "";

  public valueType: TextFilterValueType = TextFilterValueType.String;

  public getFilterType(): FilterType {
    return FilterType.Text;
  }

  public getValue(): string {
    return this.value;
  }

  public onValueChanged(val: string): void {
    this.value = val;
    super.onValueChanged(val);
  }
}
