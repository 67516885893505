import { DataTableFilter } from '../components/data-table/filters/data-table-filter';
import { DropdownDataTableFilterComponent, FilterValueType } from '../components/data-table/filters/dropdown-filter/dropdown-data-table-filter.component';
import { TextDataTableFilterComponent } from '../components/data-table/filters/text-filter/text-data-table-filter.component';
import { FilterType, TextFilterValueType } from '../enum';

export interface IDataTableFilterSerializer {
  serialize(filters: DataTableFilter[], map?: any): string;
}

export class DataTableFilterODataSerializer implements IDataTableFilterSerializer {

  public serialize(filters: DataTableFilter[], map?: any): string {
    return filters
      .filter(x => x.getValue() !== undefined && x.getValue() !== null && x.getValue() !== '')
      .map(x => {
        switch (x.getFilterType()) {
          case FilterType.Text:
            return this._serializeTextFilter(x as TextDataTableFilterComponent, map);
          case FilterType.Dropdown:
            return this._serializeDropdownFilter(x as DropdownDataTableFilterComponent, map);
        }
      })
      .filter(x => x != null)
      .join(' and ');
  }

  private _key(filter: DataTableFilter, map?: any) {
    const key = filter.getFilterKey();
    if (map && map.hasOwnProperty(key)) {
      return map[key];
    }
    return key;
  }

  private _serializeTextFilter(filter: TextDataTableFilterComponent, map?: any): string {

    if (filter.valueType == TextFilterValueType.Number) {
      return this._serializeNumberFilter(filter, map);
    }

    return 'contains(' + this._key(filter, map) + ', \'' + filter.getValue() + '\')';
  }

  private _serializeNumberFilter(filter: TextDataTableFilterComponent, map?: any): string {
    
    var value = parseFloat(filter.getValue());
    if (isNaN(value))
    {
      return null;
    }

    return `${this._key(filter, map)} eq ${value}`;
  }

  private _serializeDropdownFilter(filter: DropdownDataTableFilterComponent, map?: any): string {

    if (filter.anyOf) {
      return `${this._key(filter, map)}/any(x:x/${filter.anyOf} eq ${this._serializeDropdownFilterValue(filter)})`;
    }

    return this._key(filter, map) + ' eq ' + this._serializeDropdownFilterValue(filter);
  }

  private _serializeDropdownFilterValue(filter: DropdownDataTableFilterComponent): string {
    const value = filter.getValue();
    switch (filter.valueType) {
      case FilterValueType.Literal:
        return value.toString();
      case FilterValueType.String:
        return '\'' + value + '\'';
    }

    return null;
  }
}
