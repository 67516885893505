import { formatNumber } from "@angular/common";
import { Component } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { merge } from "rxjs";
import { calculateDiscountNumber, nextSchemeDay } from "src/app/_helpers/calculate";
import { numberUtil } from "src/app/util/number-util";
import { IClvDto } from "../../../product-info/product-info.model";
import { ProductPricelistDialogBase } from "../../product-pricelist-dialog-base";
import { ISupplierPriceSchemeFormData, ISupplierBasePriceScheme, IVatDto } from "../../product-pricelist.model";
import { ISupplierDialogData } from "../../product-pricelist.card";
import { locale } from "src/app/_services/locale.service";

@Component({
    selector: "product-pricelist-base-scheme-dialog",
    host: { 'class': 'dialog' },
    templateUrl: './base-scheme.dialog.html'
  })
  export class ProductPricelistBaseSchemeDialog extends ProductPricelistDialogBase<ISupplierDialogData, ISupplierPriceSchemeFormData, ISupplierBasePriceScheme> {
  
    public vats: any[];
    public currencies: IClvDto[];
    public manufacturersOptions: IClvDto[];
    public logisticsPartnersOptions: IClvDto[];
    public saveLoading = false;
  
    private _isDirty: boolean;
  
    public buyInSectionExpanded = false;
  
    protected createForm(): FormGroup {
      return new FormGroup({
        id: new FormControl(),
        country: new FormControl(),
        validFrom: new FormControl(),
        manufacturer: new FormControl(),
        manufacturerSalesPrice: new FormControl(),
        discount: new FormControl(),
        buyInPrice: new FormControl(),
        logisticsPartner: new FormControl(),
        publicSalesPrice: new FormControl(),
        publicSalesPrices: new FormControl(),
        vat: new FormControl()
      });
    }
  
    public save() {
      this.saveLoading = true;
      const f = this.form.value;
  
      const req = {
        productId: this.data.pricelistCard.productId,
        countryId: f.country ? f.country.id : null,
        validFrom: f.validFrom,
        publicSalesPrices: (f.publicSalesPrices || []).map(x => ({ currencyId: x.currency?.id, price: x.price })),
        manufacturerSalesPrice: f.manufacturerSalesPrice,
        buyInPrice: f.buyInPrice,
        manufacturerId: f.manufacturer ? f.manufacturer.id : null,
        logisticsPartnerId: f.logisticsPartner ? f.logisticsPartner.id : null,
        vatId: f.vat ? f.vat.id : null
      };
  
      this._productPricelistService
        .saveSupplierPrice(f.id, req)
        .result(this.form, () => {
          this.data.pricelistCard.loadSupplierPrices();
          this.closeDialog();
          this.saveLoading = false;
        }, err => {
          this.saveLoading = false;
        });
    }
  
    public get editable() {
      return this.data?.editable;
    }
  
    public deleteScheme(): void {
      this.deleteSchemeInternal(
        id => this._productPricelistService.deleteSupplierPrice(id),
        () => this.data.pricelistCard.loadSupplierPrices()
      );
    }
  
    protected normalizeData(scheme: ISupplierBasePriceScheme): any {
      const result = <any>scheme || {};
      result.validFrom = scheme?.validFrom?.editableDate || this.nextDay;
      result.discount = calculateDiscountNumber(scheme?.manufacturerSalesPrice, scheme?.buyInPrice);
      result.vat = scheme?.vat?.id ? this.vats.find(x => x.id === scheme.vat.id) : null;
      result.logisticsPartner = scheme ? scheme.logisticsPartner : this.data.formData.defaultLogisticsPartner;
      return result;
    }
  
    protected onLoad(): void {
      super.onLoad();
      this._isDirty = !!this.data.scheme?.id;
      this.vats = this.getVats(this.data.scheme?.country?.id);
      this.currencies = this.data.formData.currencies;
      this.manufacturersOptions = this.data.formData.manufacturers;
      this.logisticsPartnersOptions = this.data.formData.logisticsPartners;
    }
  
    private getVats(countryId: string) {
      let vats = this.data.formData.vats
        .filter(x => countryId ? x.country.id === countryId : true)
        .map(this.formatVat);
  
      if (vats.length === 0) {
        vats = this.data.formData.vats
          .map(this.formatVat);
      }
  
      return vats;
    }
  
    private formatVat = (vat: IVatDto): any => {
      return {
        id: vat.id,
        value: `${formatNumber(vat.numericValue, locale, '1.0-0')}% (${vat.country.name})`
      };
    }
  
    protected bindCalculations(): void {
      this.bindCountry();
      this.bindManufacturerSalesPrice();
      this.bindBuyInPrice();
  
      const { manufacturer, manufacturerSalesPrice, discount, buyInPrice } = this.form.value || {};
  
      if (manufacturer || manufacturerSalesPrice || discount || buyInPrice) {
        this.buyInSectionExpanded = true;
      }
    }
  
    private bindCountry(): void {
      merge(
        ...Object
          .keys(this.form.controls)
          .filter(x => x !== 'country' && x !== 'publicSalesPrices')
          .map(x => this.form.get(x).valueChanges)
      )
        .subscribe((val) => {
          this._isDirty = true;
        });
  
      this.form.get('country').valueChanges
        .subscribe(country => {
          this.vats = this.getVats(country?.id);
  
          if (this._isDirty) {
            return;
          }
  
          const basePrices = this.data.pricelistCard.pricesInfo?.supplierBasePrices;
          if (!basePrices) {
            return;
          }
  
          const existingPrice = basePrices.find(x => x.country.id === country?.id) || <ISupplierBasePriceScheme>{};
  
          this._isDirty = true;
  
          const clone = <ISupplierBasePriceScheme>{
            validFrom: {
              editableDate: nextSchemeDay(existingPrice?.validFrom?.editableDate)
            },
            manufacturer: existingPrice.manufacturer,
            manufacturerSalesPrice: existingPrice.manufacturerSalesPrice,
            buyInPrice: existingPrice.buyInPrice,
            logisticsPartner: existingPrice.logisticsPartner === undefined
              ? this.data.formData.defaultLogisticsPartner
              : existingPrice.logisticsPartner,
            publicSalesPrice: existingPrice.publicSalesPrice,
            publicSalesPrices: existingPrice.publicSalesPrices,
            vat: existingPrice.vat
          };
  
          this.form.patchValue(this.normalizeData(clone));
          this._isDirty = false;
        });
    }
  
    private bindManufacturerSalesPrice(): void {
      merge(
        this.form.get('manufacturerSalesPrice').valueChanges,
        this.form.get('discount').valueChanges,
      )
        .subscribe(() => {
          const manufacturerSalesPrice = numberUtil.toNumber(this.form.get('manufacturerSalesPrice').value);
          const discountPercent = numberUtil.toNumber(this.form.get('discount').value);
  
          if (!numberUtil.isNumber(manufacturerSalesPrice) || !numberUtil.isNumber(discountPercent)) {
            return;
          }
  
          this.form.patchValue({
            buyInPrice: formatNumber(manufacturerSalesPrice * (1 - discountPercent / 100), locale, '1.0-3')
          }, {
            emitEvent: false
          });
        });
    }
  
    private bindBuyInPrice(): void {
      this.form.get('buyInPrice').valueChanges
        .subscribe(val => {
          val = numberUtil.toNumber(val);
          const manufacturerSalesPrice = numberUtil.toNumber(this.form.get('manufacturerSalesPrice').value);
  
          this.form.patchValue({
            discount: calculateDiscountNumber(manufacturerSalesPrice, val)
          }, {
            emitEvent: false
          });
        });
    }
  }