import { Component, Input, OnInit, forwardRef, Injector, HostBinding, Output, EventEmitter } from "@angular/core";
import { TranslateService} from '@ngx-translate/core';

interface ITranslationScope {
  translationsPath: string;
}

@Component({
  selector: "scope",
  template: "<ng-content></ng-content>"
})
export class ScopeComponent implements OnInit, ITranslationScope {

  @Input() public translationsPath: string;
  @Input() public breadcrumbs: boolean = false;

  constructor(private _injector: Injector) {
  }

  public ngOnInit(): void {

    //var scope = this._injector.get(ScopeComponent, null, InjectFlags.SkipSelf);
    //console.log(scope, scope === this);

    //if (scope) {
    //  this.translationsPath = `${scope.translationsPath}.${this.translationsPath}`;
    //}


  }
}

@Component({
  selector: "card",
  templateUrl: "./card.component.html",
  host: { 'class': 'card' },
  providers: [{
    provide: ScopeComponent,
      useExisting: forwardRef(() =>  CardComponent)
  }]
})
export class CardComponent extends ScopeComponent {

  @Input() public title: string;
  @Input() public valid: boolean = null;
  @Input() public noHeader: boolean = false;

  @HostBinding('class.aligned')
  @Input() public alignSections: boolean = false;

  @Output() public onToggleFullScreen = new EventEmitter();

  constructor(
    injector: Injector,
    private readonly _translateService: TranslateService
  ) {
    super(injector);
  }

  public get titleValue() {

    if (this.title) {
      return this.title;
    }

    if (this.translationsPath) {
      return this._translateService.instant(`${this.translationsPath}.title`);
    }

    return "";
  }

}
