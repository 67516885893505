<lxm-dialog [dialogRef]="dialogRef" #dialog>
  <span header-title>
    {{ (editable
    ? (hasSchemeId
    ? 'cards.offer.supplier_prices_dialog.title_edit'
    : 'cards.offer.supplier_prices_dialog.title_add' )
    : 'cards.offer.supplier_prices_dialog.title') | translate }}
  </span>

  <div body [formGroup]="form" class="mb20">
    <display-product class="dialog-display-product-sticky" *ngIf="data?.product?.productId"
      [data]="data.product"></display-product>

    <scope translationsPath="cards.offer.supplier_prices_dialog">
      <form-field dialog label="supplier" [required]="editable">
        <lxm-select select for="supplier" [readonly]="!editable || !canSetSupplier" [searchable]="true"
          [labelFn]="supplierAcLabel" [sortByLabel]="true" [options]="suppliersOptions">
        </lxm-select>
        <validation-errors for="retailerSupplierId" aliasTo="supplier"></validation-errors>
      </form-field>

      <form-field dialog label="logistics_partner">
        <lxm-select select for="logisticsPartner" labelField="value" [searchable]="true" [readonly]="!editable"
          [options]="logisticsPartnersOptions">
        </lxm-select>
        <validation-errors for="logisticsPartnerId" aliasTo="logisticsPartner"></validation-errors>
      </form-field>

      <form-field dialog label="currency">
        <lxm-select select for="buyInPriceCurrency" [readonly]="true" [options]="acceptedCurrencies" labelField="value"
          compareField="id">
        </lxm-select>
        <validation-errors for="buyInPriceCurrencyId" aliasTo="buyInPriceCurrency"></validation-errors>
      </form-field>

      <form-field dialog label="campaign">
        <slide-toggle toggle for="isCampaign" [readonly]="!editable"></slide-toggle>
        <validation-errors for="isCampaign"></validation-errors>
      </form-field>

      <form-field dialog label="valid" [required]="editable">
        <div combined class="flex" style="align-items: center;">
          <lxm-datepicker class="dialog-input-fixed-width-1" for="validFrom" [readonly]="!editable"
            [min]="nextDay"></lxm-datepicker>
          <ng-container *ngIf="form.get('isCampaign').value">
            <div class="ml10 mr10">-</div>
            <lxm-datepicker class="dialog-input-fixed-width-1" for="validTo" [readonly]="!editable"
              [min]="nextDay"></lxm-datepicker>
          </ng-container>
        </div>
        <validation-errors for="validFrom"></validation-errors>
        <validation-errors for="validTo"></validation-errors>
      </form-field>

      <div class="row mt10 flex-nowrap">

        <div class="col-4 no-padding">
          <form-field labelPosition="top">
            <ng-container label>{{'cards.offer.supplier_prices_dialog.label.list_price' | translate}}
              ({{'global.vat_excluded' | translate}})</ng-container>
            <!-- <combined-input any [readonly]="true" for="listPrice">
              <validation-errors for="listPrice"></validation-errors>
              <span suffix *ngIf="form.get('listPrice').value">
                {{ form.value.buyInPriceCurrency?.code }}
              </span>
            </combined-input> -->
            <lxm-input input type="price" [readonly]="true" for="listPrice"
              [currency]="form.value.buyInPriceCurrency"></lxm-input>
          </form-field>
        </div>

        <div class="col-4 no-padding">
          <form-field label="discount" labelPosition="top">
            <lxm-input class="f-1" input for="discount" type="percent" digits="1" [readonly]="true"></lxm-input>
          </form-field>
        </div>

        <div class="col-4 no-padding">
          <form-field labelPosition="top">
            <ng-container label>{{'cards.offer.supplier_prices_dialog.label.buy_in_price' | translate}}
              ({{'global.vat_excluded' | translate}})</ng-container>
            <!-- <combined-input class="f-1" any [readonly]="true" for="buyInPrice">
              <validation-errors for="buyInPrice"></validation-errors>
              <span suffix *ngIf="form.get('buyInPrice').value">
                {{ form.value.buyInPriceCurrency?.code }}
              </span>
            </combined-input> -->
            <lxm-input input type="price" [readonly]="true" for="buyInPrice"
              [currency]="form.value.buyInPriceCurrency"></lxm-input>
          </form-field>
        </div>

      </div>

      <div class="row flex-nowrap">

        <div class="col-4 no-padding">
          <form-field label="vat" labelPosition="top">
            <lxm-select select for="vat" labelField="value" [searchable]="true" [sortByLabel]="true"
              [options]="vatOptions" [readonly]="!editable" (change)="calcMargin()">
              <validation-errors for="vatId" aliasTo="vat"></validation-errors>
            </lxm-select>
          </form-field>
        </div>

        <div class="col-4 no-padding">
          <form-field label="{{ (markupMethod == 1 ? 'coverage' : 'margin') }}" labelPosition="top">
            <lxm-input input for="margin" class="f-1" type="percent" digits="1" [readonly]="!editable"
              (blur)="calcRetailPrices()">
              <validation-errors for="margin"></validation-errors>
            </lxm-input>
          </form-field>
        </div>

        <div class="col-4 no-padding">
          <form-field label="retail_price" labelPosition="top" [class.readonly]="!editable">
            <currency-price input for="retailPrices" class="f-1" [currencyOptions]="salesCurrencies"
              [defaultCurrency]="form.value.buyInPriceCurrency" [readonly]="!editable" [pricesWithoutVat]="true"
              [vat]="selectedVat" (blur)="calcMargin()">
            </currency-price>
          </form-field>
        </div>

      </div>
    </scope>


    <scope translationsPath="cards.products.product_pricelist_retailer_supplier.dialog">

      <!-- PRICE GROUPS -->
      <ng-container
        *ngIf="priceGroupsFormArrayRef?.controls?.length || (editable && availablePriceGroupOptions?.length)">

        <div class="separator"></div>

        <ng-container *ngFor="let group of priceGroupsFormArrayRef?.controls; let i = index">

          <div class="row flex-nowrap" [formGroup]="group">

            <div class="col-4 no-padding">
              <form-field label="price_group" description="price_group" for="priceGroup" labelPosition="top">
                <lxm-select select for="priceGroup" [readonly]="!editable" labelField="value" [searchable]="true"
                  (change)="onPriceGroupOptionChange($event, i)" [options]="availablePriceGroupOptions">
                </lxm-select>
              </form-field>
            </div>

            <div class="col-4 no-padding">
              <form-field label="{{ (markupMethod == 1 ? 'coverage' : 'margin') }}" description="margin" for="margin"
                labelPosition="top">
                <lxm-input input for="margin" type="percent" digits="1" class="f-1" [readonly]="!editable"
                  (blur)="handleCalculations('margin', group)">
                  <validation-errors for="margin"></validation-errors>
                </lxm-input>
              </form-field>
            </div>

            <div class="col-4 no-padding">
              <form-field label="retail_price" description="retail_price" for="retailPrices" labelPosition="top">
                <currency-price input class="f-1" for="retailPrices" [currencyOptions]="salesCurrencies"
                  [defaultCurrency]="form.value.buyInPriceCurrency" [pricesWithoutVat]="true" [readonly]="!editable"
                  [vat]="selectedVat" (blur)="handleCalculations('retailPrices', group)">
                </currency-price>
              </form-field>
            </div>

          </div>

        </ng-container>

        <div class="row" *ngIf="editable && availablePriceGroupOptions?.length">
          <div class="col-4 no-padding"></div>
          <div class="col-4 no-padding"></div>
          <div class="col-4 no-padding">
            <lxm-button label="action.add_price_group" icon="plus-dark" (click)="addPriceGroup()"></lxm-button>
          </div>
        </div>

      </ng-container>

    </scope>


  </div>

  <div footer class="justify-between">
    <span class="flex flex-row">
      <lxm-button (click)="save()" type="primary" label="action.save" [loading]="saveLoading"
        *ngIf="editable"></lxm-button>
      <lxm-button (click)="closeDialog()" type="secondary" label="action.cancel" [class.ml10]="editable"></lxm-button>
    </span>

    <span class="flex flex-row">
      <lxm-button (click)="deleteScheme()" type="transparent" label="action.delete" icon="trash" class="ml30"
        [loading]="deleteLoading" *ngIf="editable && hasSchemeId">
      </lxm-button>
    </span>
  </div>

</lxm-dialog>