import { Component, Injector } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ProductCardBase } from '../product-card-base';
import { IProductCardBookInfo, IClvDto } from '../product-info/product-info.model';
import { Observable } from 'rxjs';
import { asArray, IEnumValue, asObject, UserAction, ProductCardSection, ProductField ,ValidType} from 'src/app/enum';
import { formUtil, keep } from 'src/app/util/form-util';
import { ProductValidity } from 'src/app/_services';
import { LOCAL_STORAGE_KEY } from 'src/app/config';

@Component({
  selector: 'product-book-card',
  templateUrl: './product-book.card.html',
  styleUrls: ['./product-book.card.scss']
})
export class ProductBookCard extends ProductCardBase<IProductCardBookInfo> {

  public UserAction = UserAction;
  public get title() { return 'cards.products.product_book_info.title'; }

  public cardSection = ProductCardSection.BookInfo;

  public bookFormatOptions: IClvDto[];
  public bookRoleOptions: IClvDto[];

  public isExpandedKey = LOCAL_STORAGE_KEY.PRODUCT_BOOK_CARD_OPEN;

  constructor(injector: Injector, public productValidity: ProductValidity) {
    super(injector, 'bookInfo', 'productBookInfoChanged');
    this.bookFormatOptions = this._productCard.formData.bookFormats;
    this.bookRoleOptions = this._productCard.formData.bookRoles;
  }

  public get cardValidType(): ValidType {
    return this.productValidity.getCardStatusValidType(this.cardSection);
  }

  public get shouldExpandSections() {
    // return this.productValidity.shouldValidate ? (
    //   this.cardValidType === (StatusCircleValidType.Invalid || StatusCircleValidType.Recommended) ? true : false
    //   ) : false;
    return true;
  }

  protected _getNormalizedFormData(data: IProductCardBookInfo) {
    return formUtil.transformData(data, {
      releaseYear: keep,
      bookFormatId: () => data.bookFormat?.id,
      language: keep,
      pageAmount:keep,
      publisher: keep,
      edition: keep,
      roles: keep,
    });
  }
  protected _createFormGroup(data: IProductCardBookInfo): FormGroup {
    return formUtil.createFormGroup(this._getNormalizedFormData(data));
  }

  protected _createSaveRequest() {
    const data = this.form.value;
    return {
      releaseYear:data.releaseYear,
      bookFormatId:data.bookFormatId,
      language:data.language?.id,
      pageAmount:data.pageAmount,
      publisher:data.publisher,
      edition:data.edition,
      roles: data.roles?.filter((x: any) => !x.isDeleted)
        .map((x: any) => ({
          id: x.id,
          roleId: x.roleId,
          personal: x.personal,
          description:x.description
        })),
    };
  }

  protected _saveInternal(req: any): Observable<any> {
    return this._productService.saveBookInfo(this.productId, req);
  }

  protected get successMessage(): string {
    return 'cards.products.product_book_info.save_successful';
  }

}
