import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import { Subscription } from 'rxjs';
import { asArray, ClientStatus, UserAction } from 'src/app/enum';
import { RouteNavigator } from 'src/app/_helpers';
import { SignalRService } from 'src/app/_services';
import { ClientsService, IClientCard, IClientFormData } from 'src/app/_services/clients.service';
import { ClientState } from '../../state/client.state';

@Component({
  selector: 'client-details-card',
  templateUrl: './client-details.card.html',
  styleUrls: ['./client-details.card.scss']
})
export class ClientDetailsCard implements OnInit {

  public edit = false;
  public saveLoading = false;

  public clientCard: IClientCard;
  public formData: IClientFormData;

  public UserAction = UserAction;

  @Input() public clientId;

  public clientStatusOptions = asArray(ClientStatus);

  private _snapshot;
  
  public form = new FormGroup({
    clientRegCode: new FormControl(),
    clientKMKR: new FormControl(),
    clientAddress: new FormControl(),
    clientIsFIEOrForeign: new FormControl(false)
  })

  public get clientName() {
    return this.form.get('clientName').value;
  }

  public isClient = false;

  constructor(
    private _routeNavigator: RouteNavigator,
    private _clientsService: ClientsService,
    private _signalRService: SignalRService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _state: ClientState) {
      this.clientCard = this._route.snapshot.data.initialData?.clientCard;

      if (!this.clientCard) {
        return;
      }

      this.formData = this.clientCard.formData;
      const clientInfo = this.clientCard.clientInfo;

      this.isClient = this.clientCard?.clientInfo?.isSystemClient === false;

      this.form.patchValue({
        clientRegCode: clientInfo.regNo,
        clientKMKR: clientInfo.kmkrNr,
        clientIsFIEOrForeign: clientInfo.isFIEOrForeign,
        clientAddress: clientInfo.address
      });
    }

  ngOnInit() {
    if (this.clientId) {
      this.saveInitialData();
    }
  }

  public get canEdit() {
    return this._state.isClient && this._state.canEdit;
}

  public backToList() {
    this._routeNavigator.toPreviousRoute({ fallback: '/clients' });
  }

  public toggleEdit() {
    if (this.edit) {
      this.revertToInitialData();
    }

    this.edit = !this.edit;
  }

  public saveInitialData() {
    this._snapshot = this.form.value;
  }

  public revertToInitialData() {
    this.form.patchValue(this._snapshot);
  }

  public save() {
    this.saveLoading = true;
    let req = this._createSaveRequest();
    
    this._clientsService
      .saveClientDetails(this.clientId, req)
      .result(this.form, id => {
        if (!this.clientId) {
          this._router.navigate(['/clients/client', id], { replaceUrl: true });
          return;
        }

        this.saveLoading = false;
        this.saveInitialData();
        this.edit = false;
      }, error => { this.saveLoading = false; });
  }

  private _createSaveRequest() {
    const form = this.form.value;
    const req = {
      regNo: form.clientRegCode,
      kmkrNr: form.clientKMKR,
      isFieOrForeign: form.clientIsFIEOrForeign,
      address: form.clientAddress
    };

    return req;
  }
}