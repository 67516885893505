<lxm-dialog [dialogRef]="dialogRef" [formGroup]="formGroup" #dialog>
  <span header-title [ngSwitch]="isNew">
    <div class="flex flex-col" *ngSwitchCase="true">
      {{'cards.settings.assortment_group_tags.title_new_item' | translate}}
      <span *ngIf="data.parentTag?.code || data.parentTag?.name">
        {{data.parentTag.code}} {{data.parentTag.name}}
      </span>
    </div>
    <div class="flex flex-col" *ngSwitchCase="false">
      {{'cards.settings.assortment_group_tags.title_edit' | translate}}
      <span>
        {{formGroup.get('code').value}} {{formGroup.get('name').value}}
      </span>
    </div>
  </span>

  <div body style="padding-bottom: 40px;">
    <scope translationsPath="cards.settings.assortment_group_tags">

      <form-field dialog label="code" type="input-text" for="code">
        <validation-errors for="code"></validation-errors>
      </form-field>

      <form-field dialog label="sort_order" type="input-text" for="sortOrder">
        <validation-errors for="sortOrder"></validation-errors>
      </form-field>

      <form-field dialog label="name" type="input-text" for="name">
        <validation-errors for="name"></validation-errors>
      </form-field>

      <form-field dialog label="description" type="input-text" for="description">
        <validation-errors for="description"></validation-errors>
      </form-field>

      <form-field dialog label="status">
        <lxm-select select for="status" class="w-full restricted-height" value="id" [readonly]="!isParentActive"
          [searchable]="true" [options]="statuses" [clearable]="false">
        </lxm-select>
        <validation-errors for="status"></validation-errors>
      </form-field>

      <form-field dialog label="color" *ngIf="!isParent">
        <div any class="flex f-1 flex-row align-center">
          <color-picker any class="f-0 mr10" for="color"></color-picker>
        </div>
      </form-field>

    </scope>
  </div>

  <div footer>
    <lxm-button type="primary" label="cards.settings.assortment_group_tags.action.save" (click)="save()"
      buttonType="submit" [loading]="actionLoading.save"></lxm-button>
    <lxm-button type="secondary" class="ml15" label="cards.settings.assortment_group_tags.action.cancel"
      (click)="closeDialog()"></lxm-button>
  </div>
</lxm-dialog>