export enum Color {
    Yellow = 1,
    Green = 2,
    LightBlue = 3,
    Blue = 4,
    Purple = 5,
    Brown = 6,
    Orange = 7,
    Red = 8,
    Black = 9,
    White = 10,

    PurpleyGrey = 100,
    Viridian = 101
}

export const COLOR_MAP = {
    [Color.Yellow]: '#fcdf76',
    [Color.Green]: '#9ace81',
    [Color.LightBlue]: '#71cff5',
    [Color.Blue]: '#6f9ceb',
    [Color.Purple]: '#c490d1',
    [Color.Brown]: '#b89494',
    [Color.Orange]: '#f2736d',
    [Color.Red]: '#944b56',
    [Color.Black]: '#5e5357',
    [Color.White]: '#ffffff',
    [Color.PurpleyGrey]: '#8e8689',
    [Color.Viridian]: '#22974b'
};