<card-expanding [formGroup]="form" [valid]="cardValidType" [expanded]="isExpanded" (onExpand)="saveLocalExpandedValue($event)">

  <span header-title>{{ title | translate }}</span>

  <div header-actions *requiredRights="[UserAction.ManageProducts]">
    <ng-container *ngIf="isOwnProduct">
      <ng-container *ngIf="!locked">
        <lxm-button *ngIf="!edit" class="ml15" type="beige" icon="edit" title="action.edit" (click)="toggleEdit()"></lxm-button>
        <lxm-button *ngIf="edit" class="ml15" type="grey" icon="back" label="action.cancel" (click)="toggleEdit()">
        </lxm-button>
        <lxm-button *ngIf="edit" class="ml15" type="beige" icon="save" label="action.save" [loading]="saveLoading"
          (click)="save()" buttonType="submit"></lxm-button>
      </ng-container>
      <ng-container *ngIf="locked">
        {{ 'global.locked_by' | translate }}: {{ lockedBy.name }} {{ lockedAt | amTimeAgo }}
      </ng-container>
    </ng-container>
  </div>

  <div body>

    <div class="row">
      <div class="col-6 no-padding">

        <form-field
          [readonly]="!edit"
          label="cards.products.product_medication_info.label.atc_segment"
          description="cards.products.product_medication_info.label_description.atc_segment"
          for="atcSegment"
          [required]="true"
        >
          <lxm-select
            select
            [readonly]="!edit"
            [searchable]="true"
            labelField="title"
            for="atcSegment"
            url="api/clvac/product-level-atc-segments/"
          >
          </lxm-select>
          <validation-errors for="atcSegment"></validation-errors>
          <validation-errors
            for="atcSegmentId"
            aliasTo="atcSegment"
          ></validation-errors>
        </form-field>

        <form-field [readonly]="!edit"
          label="cards.products.product_medication_info.label.dosage_form" description="cards.products.product_medication_info.label_description.dosage_form"
          for="dosageFormId"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.MedicationDosageForm, form.get('dosageFormId').value) : null">
          <lxm-select select
            [readonly]="!edit"
            [options]="medicationDosageFormOptions"
            [searchable]="true"
            for="dosageFormId"
            value="id"
            labelField="translatedValue"
            [isTranslated]="true"
            [sortByLabel]="true">
            </lxm-select>
          <validation-errors for="dosageFormId"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit"
          label="cards.products.product_medication_info.label.prescription_type" description="cards.products.product_medication_info.label_description.prescription_type"
          for="prescriptionTypeId"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.MedicationPrescriptionType, form.get('prescriptionTypeId').value) : null">
          <lxm-select select
            [readonly]="!edit"
            [options]="medicationPrescriptionTypeOptions"
            for="prescriptionTypeId"
            value="id"
            labelField="translatedValue"
            [isTranslated]="true"
            [sortByLabel]="true">
            </lxm-select>
          <validation-errors for="prescriptionTypeId"></validation-errors>
        </form-field>

        <form-field
            [readonly]="!edit"
            label="cards.products.product_medication_info.label.has_security_elements"
            description="cards.products.product_medication_info.label_description.has_security_elements"
          >
            <slide-toggle
              toggle
              for="hasSecurityElements"
              [readonly]="!edit"
            ></slide-toggle>
            <validation-errors for="hasSecurityElements"></validation-errors>
          </form-field>
        
      </div>
        
      <div class="col-6 no-padding">
        <form-field [readonly]="!edit" for="strength"
            label="cards.products.product_medication_info.label.strength"
            description="cards.products.product_medication_info.label_description.strength"
            type="input-text"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.MedicationStrength, form.get('strength').value) : null">
            <validation-errors for="strength"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit"
          label="cards.products.product_medication_info.label.administration_method"
          for="administrationMethods"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.MedicationAdministrationMethods, form.get('administrationMethods').value?.length > 0) : null">
          <lxm-select select
            [readonly]="!edit"
            [options]="medicationAdministrationMethodOptions"
            for="administrationMethods"
            value="id"
            labelField="translatedValue"
            [multiple]="true"
            [searchable]="true"
            [isTranslated]="true"
            [sortByLabel]="true">
          </lxm-select>
          <validation-errors for="administrationMethods"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit"
          label="cards.products.product_medication_info.label.addiction_type"
          for="addictionTypeId"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.MedicationAddictionType, form.get('addictionTypeId').value) : null">
          <lxm-select select
            [readonly]="!edit"
            [options]="medicationAddictionTypeOptions"
            for="addictionTypeId"
            value="id"
            labelField="translatedValue"
            [isTranslated]="true"
            [sortByLabel]="true">
            </lxm-select>
          <validation-errors for="addictionTypeId"></validation-errors>
        </form-field>
      </div>
    </div>

    <div class="separator"></div>

    <div class="row">
      <div class="col-12 no-padding">
        <form-field [readonly]="!edit"
          label="cards.products.product_medication_info.label.risk_type"
          for="riskTypeId"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.MedicationRiskType, form.get('riskTypeId').value) : null">
          <lxm-select select
            [readonly]="!edit"
            [options]="medicationRiskTypeOptions"
            for="riskTypeId"
            value="id"
            labelField="translatedValue"
            [searchable]="true"
            [isTranslated]="true"
            [sortByLabel]="true">
            </lxm-select>
          <validation-errors for="riskTypeId"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit" labelTop="true"
            label="cards.products.product_medication_info.label.risk_description" 
            description="cards.products.product_medication_info.label_description.risk_description"
            for="riskDescription" 
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailerLanguageText(productField.MedicationRiskDescription) : null">
            <language-text language-text for="riskDescription" [newline]="true" [readonly]="!edit" [required]="productValidity.shouldValidate ? productValidity.getLanguageTextRequiredTypes(productField.MedicationRiskDescription) : null"></language-text>
          </form-field>
      </div>
    </div>

  </div>

  <div footer class="flex flex-row" *ngIf="edit">
    <lxm-button class="ml15" type="grey" icon="back" label="action.cancel" (click)="toggleEdit()"></lxm-button>
    <lxm-button class="ml15" type="beige" icon="save" label="action.save" [loading]="saveLoading" (click)="save()">
    </lxm-button>
  </div>

</card-expanding>
