<card-expanding>
  <span header-title>{{ 'cards.clients.offers.title' | translate}}</span>
  <div header-actions>
    <ng-container *requiredRights="[UserAction.ManageSupplierOffers]">
      <lxm-button
        *ngIf="isSelfSupplier && clientInfo.status == Status.Active" 
        type="beige" 
        class="ml15" 
        icon="plus-dark" 
        label="cards.clients.offers.action.new_offer" 
        (click)="newOffer()">
      </lxm-button>
    </ng-container>
  </div>
  <div body [ngSwitch]="hasOffers">

    <ng-container *ngSwitchCase="true">
      <div class="offers-section" *ngIf="hasActiveOffers">
        <span class="offers-section_header">{{ 'cards.clients.offers.active_offers' | translate}} ({{activeOffers?.length || 0}})</span>
        <div class="offers-section_body">
          <client-offer *ngFor="let offer of activeOffers | slice:0:2" [offer]="offer"></client-offer>
          <ng-container *ngIf="showAllActiveOffers">
            <client-offer *ngFor="let offer of activeOffers | slice:2:1000" [offer]="offer"></client-offer>
          </ng-container>
          <div *ngIf="activeOffers?.length > 2" class="toggle-show-all-offers" (click)="toggleShowAllActiveOffers()">
            {{(showAllActiveOffers ? 
              'cards.clients.offers.action.show_less_offers' : 
              'cards.clients.offers.action.show_all_offers') | translate
            }}
          </div>
        </div>
      </div>

      <div class="offers-section" *ngIf="hasDraftedOffers">
        <span class="offers-section_header">{{ 'cards.clients.offers.drafted_offers' | translate}} ({{draftedOffers?.length || 0}})</span>
        <div class="offers-section_body">
          <client-offer *ngFor="let offer of draftedOffers | slice:0:2" [offer]="offer"></client-offer>
          <ng-container *ngIf="showAllDraftedOffers">
            <client-offer *ngFor="let offer of draftedOffers | slice:2:1000" [offer]="offer"></client-offer>
          </ng-container>
          <div *ngIf="draftedOffers?.length > 2" class="toggle-show-all-offers" (click)="toggleShowAllDraftedOffers()">
            {{(showAllDraftedOffers ? 
              'cards.clients.offers.action.show_less_offers' : 
              'cards.clients.offers.action.show_all_offers') | translate
            }}
          </div>
        </div>
      </div>

      <div class="offers-section" *ngIf="hasArchivedOffers">
        <span class="offers-section_header">{{ 'cards.clients.offers.archived_offers' | translate}} ({{archivedCount}})</span>
        <div class="offers-section_body offers-archive">
          <div class="line-bar-wrapper">
            <ng-container *ngFor="let key of archivedKeys">
              <span *ngIf="getArchiveCount(key) > 0" class="line-bar {{key}}"
                [style.flex]="getArchiveCountFlexPercentage(key)">
              </span>
            </ng-container>
          </div>
          <table class="w-full mt12">
            <tbody>
              <tr *ngFor="let key of archivedKeys">
                <td>
                  <div class="col-status">
                    <status-circle class="mr12" status="{{key}}"></status-circle>
                    <span>{{getArchiveTranslateKey(key) | translate}}</span>
                  </div>
                </td>
                <td>
                  <div class="col-count">
                    {{archivedOffers[key]}}
                  </div>
                </td>
                <td>
                  <div class="col-percentage">
                    {{getArchiveCountPercentage(key, true) | percentValue}}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ng-container>

    <no-content 
      *ngSwitchCase="false" 
      heading="cards.clients.offers.no_offers_heading"
      text="cards.clients.offers.no_offers_text"
      image="no-proposals">
    </no-content>

  </div>
</card-expanding>