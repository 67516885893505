import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { DialogComponent } from 'src/app/components';
import { AuthenticationService, OfferService } from 'src/app/_services';
import { LxmAppModule, StatusRule, UserAction } from 'src/app/enum';
import { LxmDialog, LxmMessage } from 'src/app/_helpers';
import moment from 'moment';
import { ICurrency } from 'src/app/models';

@Component({
  selector: "receiving-offer-product-assortment-dialog",
  host: { 'class': 'dialog' },
  templateUrl: "./receiving-offer-product-assortment.dialog.html",
  styleUrls: ['./receiving-offer-product-assortment.dialog.scss']
})
export class ReceivingOfferProductAssortmentDialog {

  @ViewChild('dialog', { static: true, read: DialogComponent })
  private _dialog: DialogComponent;

  public form: FormGroup;

  public formData: any;
  public assortmentData: any;
  public assortmentId: string;
  public retailerSuppliers = [];
  public offerCurrency: ICurrency;
  public offerRetailerSupplierId: string;

  public readonly = false;
  public isDecisionSelectAvailable = false;
  public isSaveLoading = false;
  public isDeleteLoading = false;
  public showAssortmentStatusReminder = true;
  public showDateTo = false;

  public assortmentGroups = {};
  public assortmentGroupTags = {};
  public assortmentGroupsByTags = {};
  public assortmentStatuses = [];
  public parentTags = [];
  public tags = {};

  public tagsByParentTag = [];

  public assortmentStatusOptions = [];
  public offerProductDecisionOptions = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ReceivingOfferProductAssortmentDialog>,
    authService: AuthenticationService,
    private _offerService: OfferService,
    private _lxmDialog: LxmDialog,
    private _message: LxmMessage
  ) {

    this.readonly = data.readonly || !authService.hasRight([UserAction.ManageReceivedOffers]);
    this.isDecisionSelectAvailable = authService.hasModule(LxmAppModule.PurchaseManagerDesktop) && authService.hasRight([UserAction.ManageReceivedOffers]);

    this.form = new FormGroup({
      dateFrom: new FormControl(),
      dateTo: new FormControl(),
      status: new FormControl(),
      assortmentGroups: new FormControl([]),
      offerProductDecisionId: new FormControl(),
      parentTagId: new FormControl(),
      payment: new FormControl(),
      productPaymentSupplier: new FormControl()
    });
  }

  public resetToDecisionDate() {
    const decision = this.selectedDecision;
    if (decision) {
      this.onDecisionSelect(decision);
    }
  }

  public getTagsBySelectedParentTag() {
    const { parentTagId } = this.form.value;
    this.tagsByParentTag = this.tags?.[parentTagId] ?? [];
  }

  public get selectedDecision() {
    const decisionId = this.form.value.offerProductDecisionId;
    return this.offerProductDecisionOptions.find(x => x.id === decisionId);
  }

  public get isDecisionSelected() {
    return this.form.get('offerProductDecisionId').value?.length ? true : false;
  }

  public get hasAssortmentId() {
    return this.assortmentId ? true : false;
  }

  public get productId() {
    return this.data?.product?.productId;
  }

  public get offerProductId() {
    return this.data?.product?.id;
  }

  public get offerId() {
    return this.data?.offerId;
  }

  public get decisionHasSameDate() {
    const selectedDate = this.form.get('dateFrom').value;
    if (selectedDate && !this.isDecisionSelected) {
      return true;
    }
    if (this.selectedDecision) {
      const { validFrom } = this.selectedDecision || {};
      const { editableDate } = validFrom || {}
      return moment(editableDate).isSame(selectedDate);
    }
    return true;
  }

  public onDecisionSelect(decision) {
    const { validFrom: { editableDate } } = decision || {};
    this.form.get('dateFrom').setValue(editableDate, { emitEvent: false });
  }

  ngAfterViewInit(): void {
    this._dialog.dataLoaded$
      .subscribe(state => {
        if (!state) {
          return;
        }

        const { formData } = this.data || {};
        const { assortmentFormData: { assortmentGroups, assortmentGroupTags, assortmentStatuses, assortmentGroupsByTags, parentTags,
          tags, offerProductDecisions, retailerSuppliers, offerRetailerSupplierId, assortmentClassValidity, procurementDateFrom, procurementDateTo },
           offerCurrency, product, assortmentData } = formData || {};
        this.assortmentData = assortmentData;
        this.retailerSuppliers = retailerSuppliers;
        this.offerCurrency = offerCurrency;
        this.offerRetailerSupplierId = offerRetailerSupplierId

        this.assortmentStatusOptions = assortmentStatuses ?? [];
        this.offerProductDecisionOptions = offerProductDecisions ?? [];
        this.assortmentGroups = assortmentGroups ?? {};
        this.assortmentStatuses = assortmentStatuses ?? [];
        this.assortmentGroupsByTags = assortmentGroupsByTags ?? {};
        this.assortmentGroupTags = assortmentGroupTags ?? {};
        this.tags = tags ?? {};
        this.parentTags = parentTags ?? [];
        this.showDateTo = assortmentClassValidity;

        if(assortmentClassValidity){
          const { editableDate: procurementDateFromEditable, displayDate: procurementDateFromDisplay } = procurementDateFrom || {};
          const { editableDate: procurementDateToEditable, displayDate: procurementDateToDisplay } = procurementDateTo || {};
          var procurementFrom = procurementDateFromEditable || procurementDateFromDisplay;
          var procurementTo = (procurementDateToEditable || procurementDateToDisplay) ?? "";
        }

        if (assortmentData) {
          const { offerProductDecision, assortmentGroups, status, parentTag, validFrom: { editableDate, displayDate }, validTo,
            id, productPayment, productPaymentSupplierId, isLocal } = assortmentData || {};

          if (assortmentClassValidity){
            const { editableDate: toEditableDate, displayDate: toDisplayDate } = validTo || {};
            var dateToValue = assortmentClassValidity ? (toEditableDate || toDisplayDate) ?? "" : "";
          }

          this.form.patchValue({
            dateFrom: editableDate || displayDate,
            dateTo: dateToValue ?? "",
            status: status?.id ?? assortmentStatuses?.find(x => x.statusRule == StatusRule.ActiveByDefault)?.id,
            assortmentGroups: assortmentGroups?.map(x => x.id) || [],
            offerProductDecisionId: offerProductDecision?.id,
            parentTagId: parentTag?.id || this.parentTags?.[0]?.id || null,
            payment: productPayment,
            productPaymentSupplier: productPaymentSupplierId ?? this.offerRetailerSupplierId
          }, { emitEvent: this.readonly });

          if (!isLocal) {
            // local (rPIM) assortment is for prefilling the dialog with exist assortment detail only
            // on save, new assortment should be created
            this.assortmentId = id;
          }
        } else {
          this.form.patchValue({
            dateFrom: procurementFrom || moment().startOf('day').add(1, 'day'),
            dateTo: procurementTo,
            parentTagId: this.parentTags?.[0]?.id || null,
            status: assortmentStatuses?.find(x => x.statusRule == StatusRule.ActiveByDefault)?.id,
            productPaymentSupplier: this.offerRetailerSupplierId
          }, { emitEvent: false });
        }

        this.getTagsBySelectedParentTag();

      });

    this.form.get('status').valueChanges
      .subscribe(val => {
        if (this.assortmentStatuses?.find(x => x.id == val && x.statusRule == StatusRule.InactiveByDefault)) {
          this.assortmentGroups = []
          this.form.patchValue({
            assortmentGroups: this.assortmentGroups
          });
        }
      });
  }

  public closeDialog(res) {
    this.dialogRef.close(res);
  }

  public deleteAssortment() {
    this._lxmDialog.confirm({
      title: 'cards.offer.assortment_dialog.confirm_delete.title',
      message: 'cards.offer.assortment_dialog.confirm_delete.text'
    }, () => {
      this.isDeleteLoading = true;

      //todo
      const schemeId = null;
      this._offerService.deleteOfferProductRetailerAssortmentScheme(this.offerId, this.offerProductId, this.assortmentId, schemeId)
        .result(null, res => {
          this.isDeleteLoading = false;
          this.closeDialog({});
        }, e => {
          this.isDeleteLoading = false;
        });
    });
  }

  public save() {
    const { dateFrom, dateTo, status, assortmentGroups, offerProductDecisionId, parentTagId, payment, productPaymentSupplier } = this.form.value || {};

    if (this.showAssortmentStatusReminder) {
      if (this.assortmentStatuses?.find(x => x.id == status && x.statusRule == StatusRule.Remind)) {
        this.showAssortmentStatusReminder = false;
        this._message.warning({
          message: 'enums.StatusRule.ReminderMessage'
        });
        this.isSaveLoading = false;

        return;
      }
    }

    const req = {
      validFrom: dateFrom,
      validTo: dateTo,
      status: status,
      assortmentGroups: assortmentGroups,
      offerProductDecisionId: offerProductDecisionId,
      assortmentGroupTagId: parentTagId,
      productPayment: payment,
      productPaymentCurrencyId: this.data.offerCurrency.id,
      productPaymentSupplierId: productPaymentSupplier
    };

    this.isSaveLoading = true;

    if (this.hasAssortmentId) {
      this._offerService.updateOfferProductRetailerAssortmentScheme(this.offerId, this.offerProductId, this.assortmentId, req)
        .result(this.form, res => {
          this.isSaveLoading = false;
          this.closeDialog({
            id: res.id,
            validFrom: res.validFrom?.displayDate,
            validTo: res.validTo?.displayDate,
            statusId: res.status?.id,
            decisionId: offerProductDecisionId,
            assortmentGroups: res.assortmentGroups.map(x => x.id)
          });
        }, err => {
          this.isSaveLoading = false;
        });
    } else {
      this._offerService.createOfferProductRetailerAssortmentScheme(this.offerId, this.offerProductId, req)
        .result(this.form, res => {
          this.isSaveLoading = false;
          this.closeDialog({
            id: res.id,
            validFrom: res.validFrom?.displayDate,
            validTo: res.validTo?.displayDate,
            statusId: res.status?.id,
            decisionId: offerProductDecisionId,
            // throws error here.
            assortmentGroups: res.assortmentGroups.map(x => x.id)
          });
        }, err => {
          this.isSaveLoading = false;
        });
    }
  }

}
