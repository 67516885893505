import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, UserService } from 'src/app/_services/';
import { TitleService } from '../../_services/title.service';
import { FormGroup, FormControl } from '@angular/forms';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  host: { 'class': 'reset-password-content' },
  styleUrls: ['./reset-password.scss'],
})
export class ResetPasswordComponent implements OnInit {

  public title = 'Reset Password';
  public userId: string;
  public token: string;

  public errors = [];

  public passwordView = ResetPasswordView.ResetPassword;
  public successView = ResetPasswordView.ResetSucess;

  public view = this.passwordView;

  public resetForm: FormGroup = new FormGroup({
    password: new FormControl(),
    passwordConfirm: new FormControl()
  });

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _authenticationService: AuthenticationService,
    private _userService: UserService,
    private _titleService: TitleService) {
  }

  public newPasswordLoading = false;
  public requestNewPassword() {
    this.newPasswordLoading = true;
    const form = this.resetForm.value;
    const data = {
      userId: this.userId,
      token: this.token,
      password: form.password,
      passwordConfirm: form.passwordConfirm
    };

    this._userService.resetPassword(data)
      .result(this.resetForm, () => {
        this.newPasswordLoading = false;
        this.view = this.successView;
        this.resetErrors();
      }, (err) => {
        this.newPasswordLoading = false;

        if (err.validationSummary) {
          this.errors = [err.validationSummary];
          if (err.validationResults?.token) {
            this.errors.push(err.validationResults?.token[0].value);
          }
        } else {
          this.resetErrors();
        }
      });
  }
  
  public resetErrors() {
    this.errors = [];
  }

  ngOnInit() {

    this._route.params.subscribe(params => {
      this.userId = params['userId'];
      this.token = params['token'];
    });

    this._titleService.setTitle(this.title);
  }
}

enum ResetPasswordView {
  ResetPassword = 1,
  ResetSucess = 2
}
