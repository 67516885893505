import { Injectable, EventEmitter, Inject, Component } from "@angular/core";
import moment from "moment";
import { TranslateService } from "@ngx-translate/core";
import { DateAdapter } from "@angular/material/core";
import { DOCUMENT } from "@angular/common";
import { CookieService } from "ngx-cookie-service";
import { appSettings } from "src/app/app.settings";

import LanguageET from "src/assets/i18n/et.json";
import LanguageEN from "src/assets/i18n/en.json";
import LanguageDE from "src/assets/i18n/de.json";
import LanguageTAL from "src/assets/i18n/tal.json";

// export const guestLanguages = [
//   { name: 'ENG', value: 'en', locale: 'en-US' },
//   { name: 'EST', value: 'et', locale: 'et-EE' },
// ];

export let currentLanguage: ILanguage;
export let locale: string;

@Injectable({ providedIn: "root" })
export class LocaleService {
  public changeDetectionEmitter: EventEmitter<void> = new EventEmitter<void>();

  public currentLanguage: ILanguage;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private _cookieService: CookieService,
    private _matAdapter: DateAdapter<Date>,
    public translate: TranslateService
  ) {
    this.document.documentElement.classList.add("notranslate"); // disable google translate
  }

  public get locale() {
    return this.currentLanguage.locale;
  }
  public get language() {
    return this.currentLanguage;
  }
  public get languageOptions() {
    return appSettings.uiLanguages;
  }

  public setLanguage(language: ILanguage) {
    if (!language?.value) return;
    const languageToSet = this._getSupportedLanguageById(language.id);
    this.setLocals(languageToSet);
    this.adaptLocale(languageToSet);
    this.changeDetectionEmitter.emit();
  }

  public setLocals(language: ILanguage) {
    this.currentLanguage = language;
    locale = language.locale;
    currentLanguage = language;
  }

  private _getSupportedLanguageById(languageId: number) {
    return (
      appSettings.uiLanguages.find(x => x.id === languageId) ||
      appSettings.defaultLang
    );
  }

  private _getSupportedLanguageByValue(languageValue: string) {
    return (
      appSettings.uiLanguages.find(x => x.value === languageValue) ||
      appSettings.defaultLang
    );
  }

  private _getTranslationFile(language) {
    switch (language) {
      case "EST":
        return LanguageET;
      case "DEU":
        return LanguageDE;
      case "TAL":
        return LanguageTAL;
      default:
        return LanguageEN;
    }
  }

  public adaptLocale(language: ILanguage) {
    const { value, locale, name } = language;
    const translationFile = this._getTranslationFile(name);
    this.translate.setTranslation(value, translationFile);
    this.translate.setDefaultLang(value);
    this.translate.use(value);
    moment.locale(locale);
    this._matAdapter.setLocale(locale);
    this.document.documentElement.lang = value;
  }

  public getGuestLanguage(): ILanguage {
    let language = this._cookieService.get("lang");
    if (!language) {
      language = navigator.language.substr(0, 2);
    }

    return this._getSupportedLanguageByValue(language);
  }

  public setGuestLanguage(language: ILanguage) {
    const guestLanguage = this._getSupportedLanguageByValue(language.value);
    this._cookieService.set("lang", guestLanguage?.value, undefined, "/");
    this.setLanguage(guestLanguage);
  }
}

export interface ILanguage {
  id: number;
  name: string;
  value: string;
  locale: string;
}
