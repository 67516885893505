import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IPhoneCode } from '../components/_partials/lxm-settings/users-table/users-table.component';
import { IClvDto, ICountry } from '../cards/product/product-info/product-info.model';
import { ClientStatus, DayOfWeek, Status } from '../enum';
import { IUser } from '../models';
import { ODataResponse } from '.';
import { SupplierTimelineItemType } from '../modules/suppliers/enum/SupplierTimelineItemType';
import { ILanguage } from './locale.service';

@Injectable({ providedIn: 'root' })
export class SuppliersService {
  
  constructor(private _http: HttpClient) { }

  public bind(retailerSupplierId: string, lxmSupplierId: string) {
    return this._http.post<any>(`api/suppliers/${retailerSupplierId}/bind`, { lxmSupplierId });
  }

  public createFromLxmSupplier(lxmSupplierId: string, shortName: string) {
    return this._http.post<any>(`api/suppliers/createFromLxmSupplier`, { lxmSupplierId, shortName });
  }

  public getSupplier(supplierId: string) {
    return this._http.get<ISupplierCard>(`api/suppliers/${supplierId}`);
  }

  public saveSupplier(supplierId: string, data: any) {
    return this._http.post<string>(`api/suppliers/${supplierId || ''}`, data);
  }

  public patchSupplier(supplierId: string, data: any) {
    return this._http.patch<string>(`api/suppliers/${supplierId || ''}`, data);
  }

  public saveSupplierDetails(supplierId: string, data: any) {
    return this._http.post<string>(`api/suppliers/${supplierId}/details`, data);
  }

  public getSupplierFormdata() {
    return this._http.get<any>(`api/suppliers/formdata`);
  }

  public getSuppliersListFormdata() {
    return this._http.get<any>(`api/suppliers/listFormData`);
  }

  public setOwner(supplierId: string, ownerId: string) {
    return this._http.post<any>(`api/suppliers/${supplierId}/owner`, { ownerId });
  }

  public match(lxmSupplierId: string) {
    return this._http.post<any>(`api/suppliers/match`, { lxmSupplierId });
  }

  public saveSupplierSettings(supplierId: string, data: any) {
    return this._http.post<string>(`api/suppliers/${supplierId}/settings`, data);
  }

  // invites --

  public getInviteSupplierFormData(supplierId: string) {
    return this._http.get<any>(`api/suppliers/${supplierId}/inviteFormData`);
  }

  public sendInvite(supplierId: string, data: any) {
    return this._http.post<any>(`api/suppliers/${supplierId}/invite`, data);
  }

  public cancelInvite(supplierId: string, inviteId: string) {
    return this._http.delete<any>(`api/suppliers/${supplierId}/invite/${inviteId}`);
  }

  // -- invites

  // timeline --

  public getTimeline(supplierId: string, params?: any) {
    return this._http.get<ODataResponse<ISupplierTimelineItem>>(`api/suppliers/${supplierId}/timeline`, { params: { 
      ...params,
      $orderby: 'createdAt desc'
    } }); 
  }

  public createMemo(supplierId: string, data: ISaveMemoReq) {
    return this._http.post<string>(`api/suppliers/${supplierId}/memos`, data);
  }

  public createSupplierLogComment(supplierId: string, parentId: string, data: ISaveMemoReq) {
    return this._http.post<string>(`api/suppliers/${supplierId}/memos/comments/supplierlogs/${parentId}`, data);
  }

  public createOfferLogComment(supplierId: string, parentId: string, data: ISaveMemoReq) {
    return this._http.post<string>(`api/suppliers/${supplierId}/memos/comments/offerlogs/${parentId}`, data);
  }
  
  public updateComment(supplierId: string, id: string, comment: string) {
    return this._http.post<string>(`api/suppliers/${supplierId}/memos/comments/${id}`, { content: comment });
  }
  
  public saveMemo(supplierId: string, id: string, data: ISaveMemoReq) {
    return this._http.post<string>(`api/suppliers/${supplierId}/memos/${id}`, data);
  }

  public markMemoComplete(supplierId: string, id: string, isComplete: boolean = true) {
    return this._http.post<string>(`api/suppliers/${supplierId}/memos/${id}/complete`, { isComplete: isComplete});
  }

  public deleteMemo(supplierId: string, id: string) {
    return this._http.delete<any>(`api/suppliers/${supplierId}/memos/${id}`);
  }

  // -- timeline

  // contacts --

  public getContacts(supplierId: string) {
    return this._http.get<any>(`api/suppliers/${supplierId}/contacts`);
  }

  public deleteContact(supplierId: string, contactId: string) {
    return this._http.delete<any>(`api/suppliers/${supplierId}/contacts/${contactId}`);
  }

  public saveContact(supplierId: string, contactId: string, data: any) {
    return this._http.post<any>(`api/suppliers/${supplierId}/contacts/${contactId || ''}`, data);
  }

  // -- contacts
}

export interface ISupplierListFormData {
  clientTags: IClvDto[];
}

export interface ISupplierListItem {
  id: string;
  name: string;
  regNo: string;
  address: any;
  clientTagIds:string[];
}

export interface ISupplierCard {
  supplierInfo: ISupplierInfo;
  formData: ISupplierFormData;
  activeOffers: ISupplierOfferItem[];
  offerArchive: IOfferArchive;
}

export interface ISupplierFormData {
  owners: IUser[];
  phoneCodes: IPhoneCode[];
  countries: ICountry[];
  clientTags: IClvDto[];
  campaignTypes: IClvDto[];
}

export interface ISupplierInfo {
  id: string;
  name: string;
  regNo: string;
  vatNo: string;
  address: string;
  isFieOrForeigner: boolean;
  country: ICountry;
  status: ClientStatus;
  logoFileId: string;
  thumbnailSmallContentType: string;
  thumbnailSmall: string;
  owner: IUser;
  canInvite: boolean;
  code: string;
  // offer settings
  fineForDelay: number;
  paymentDeadlineInDays: number;
  offerLanguage: ILanguage;
  gln: string;
  clientTags:string[];
  allowedCampaignTypes:string[];
  canViewRetailPrices: boolean;
  hasOrderSubmissionTimeRestrictions: boolean;
  orderSubmissionTimeRestrictions: IOrderSubmissionTimeRestrictions;
}

export interface IOrderSubmissionTimeRestrictions {
  isWholeWeek: boolean;
  orderSubmissionTimes: { [dayOfWeek: number]: string };
}

export interface ISupplierContactListItem {

}

export interface ISupplierOfferItem {
  productCount: number;
  confirmedProductCount: number;
  status: number;
  createdBy: IUser;
  owner: IUser;
  name: string;
  id: string;
}

export interface IOfferArchive {
  confirmedCount: number;
  rejectedCount: number;
  expiredCount: number;
}

export interface ISupplierTimelineItem {
  id: string;
  supplierId: string;
  isComplete: boolean;
  itemType: SupplierTimelineItemType;
  comment: ISupplierTimelineComment;
  createdBy: IUser;
  createdAt: Date;
}

export interface ISupplierTimelineComment {
  content: string;
  createdAt?: Date;
  createdBy?: IUser;
  id: string;
  parentId?: string;
}

export interface ISaveMemoReq {
  content?: string;
  isComplete?: boolean;
  date?: any;
}