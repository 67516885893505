import { Component } from "@angular/core";

@Component({
  selector: "offer-settings-card",
  templateUrl: "./offer-settings.card.html",
  styleUrls: ['./offer-settings.card.scss'],
})
export class OfferSettingsCard {

}
