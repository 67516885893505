<lxm-dialog [dialogRef]="dialogRef" [formGroup]="form" #dialog>
    <span header-title>{{'cards.lexi_market_settings.lxm_settings_user.action.add_user' | translate}}</span>

    <div body style="padding-bottom: 40px;">
        <scope translationsPath="cards.lexi_market_settings.lxm_settings_user_edit">

            <form-field dialog label="user_email" for="email" type="input-text" [readonly]="isPending" [required]="!isPending">
                <validation-errors for="email"></validation-errors>
            </form-field>

            <form-field dialog label="user_job_title" for="jobTitle" type="input-text">
                <validation-errors for="jobTitle"></validation-errors>
            </form-field>

            <form-field dialog label="user_role" [required]="true">
                <lxm-select select class="w-full" [searchable]="true" [options]="data.formData?.roles" value="id" for="roles" [multiple]="true"></lxm-select>
                <validation-errors for="roles"></validation-errors>
                <validation-errors for="roleIds"></validation-errors>
            </form-field>

            <form-field dialog label="assistants">
                <lxm-select select for="assistants" 
                  class="w-full"
                  labelField="label"                  
                  [searchable]="true"
                  [multiple]="true"
                  [options]="assistantsOptions"
                  value="id">
                </lxm-select>
                <validation-errors for="assistants"></validation-errors>
              </form-field>
        </scope>
    </div>

    <div footer class="justify-between">

      <div class="flex flex-row">

        <lxm-button type="primary" *ngIf="isPending"
                class="mr10"
                label="action.save"
                (click)="save()"
                buttonType="submit"
                [loading]="saveInProgress">
        </lxm-button>

        <lxm-button type="primary"
                    [label]="isPending ? 'cards.lexi_market_settings.lxm_settings_user_invite.action.resend_invite' : 'cards.lexi_market_settings.lxm_settings_user_invite.action.send_invite'"
                    (click)="sendInvite()"
                    buttonType="submit"
                    [loading]="isSending">
        </lxm-button>
        <lxm-button type="secondary"
                    class="ml10"
                    label="global.discard"
                    (click)="closeDialog()">
        </lxm-button>
      </div>

      <div class="flex flex-row">
        <lxm-button *ngIf="isPending"
                    type="transparent"
                    icon="trash"
                    label="cards.lexi_market_settings.lxm_settings_user_invite.action.cancel_invite"
                    (click)="delete()">
        </lxm-button>
      </div>
    </div>

</lxm-dialog>
