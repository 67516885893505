export const PICTOGRAMS = {
  suitableForVegans: {
    pictogram: "attributeVegan",
    description: "global.pictograms.attributes.vegan"
  },
  glutenFree: {
    pictogram: "attributeGlutenFree",
    description: "global.pictograms.attributes.gluten_free"
  },
  lactoseFree: {
    pictogram: "attributeLactoseFree",
    description: "global.pictograms.attributes.lactose_free"
  },
  sugarFree: {
    pictogram: "attributeSugarFree",
    description: "global.pictograms.attributes.sugar_free"
  },
  allergenFish: {
    pictogram: "allergenFish",
    description: "global.pictograms.allergens.fish"
  },
  allergenCrustaceans: {
    pictogram: "allergenCrustaceans",
    description: "global.pictograms.allergens.crustaceans"
  },
  allergenMolluscs: {
    pictogram: "allergenMolluscs",
    description: "global.pictograms.allergens.molluscs"
  },
  allergenGluten: {
    pictogram: "allergenGluten",
    description: "global.pictograms.allergens.gluten"
  },
  allergenNuts: {
    pictogram: "allergenNuts",
    description: "global.pictograms.allergens.nuts"
  },
  allergenPeanuts: {
    pictogram: "allergenPeanuts",
    description: "global.pictograms.allergens.peanuts"
  },
  allergenCelery: {
    pictogram: "allergenCelery",
    description: "global.pictograms.allergens.celery"
  },
  allergenMustard: {
    pictogram: "allergenMustard",
    description: "global.pictograms.allergens.mustard"
  },
  allergenEggs: {
    pictogram: "allergenEggs",
    description: "global.pictograms.allergens.eggs"
  },
  allergenLactose: {
    pictogram: "allergenLactose",
    description: "global.pictograms.allergens.lactose"
  },
  allergenSesame: {
    pictogram: "allergenSesame",
    description: "global.pictograms.allergens.sesame"
  },
  allergenSoy: {
    pictogram: "allergenSoy",
    description: "global.pictograms.allergens.soy"
  },
  allergenSulphites: {
    pictogram: "allergenSulphites",
    description: "global.pictograms.allergens.sulphites"
  },
  awarded: { pictogram: "awarded", description: "global.pictograms.awarded" },
  chilled: { pictogram: "chilled", description: "global.pictograms.chilled" },
  frozen: { pictogram: "frozen", description: "global.pictograms.frozen" },
  alcohol: {
    pictogram: "alcohol",
    description: "global.pictograms.alcohol"
  },
  alcoholFree: {
    pictogram: "alcoholFree",
    description: "global.pictograms.alcohol_free"
  },
  rainforestAlliance: {
    pictogram: "rainforestAlliance",
    description: "global.pictograms.rainforest_alliance"
  },
  fairtrade: {
    pictogram: "fairtrade",
    description: "global.pictograms.fairtrade"
  },
  tunnustatudEestiMaitse: {
    pictogram: "eestiMaitse",
    description: "global.pictograms.tunnustatud_eesti_maitse"
  },
  organicFarming: {
    pictogram: "organicFarming",
    description: "global.pictograms.organic_farming"
  }
};
