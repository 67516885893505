<div class="flex" [formGroup]="formGroup" [ngSwitch]="readonly">
  <mat-slide-toggle 
    *ngSwitchCase="false"
    class="panel-slider-toggle"
    (change)="change.emit($event)"
    tabIndex="-1"
    [formControlName]="for">
    <ng-content *ngTemplateOutlet="displayTpl"></ng-content>
  </mat-slide-toggle>
  <span *ngSwitchCase="true" class="input-padding">
    <ng-content *ngTemplateOutlet="displayTpl"></ng-content>
  </span>
</div>

<ng-template #displayTpl>
  <ng-container *ngIf="label">
    <span>{{label | translate}}</span>
  </ng-container>
  <ng-container *ngIf="!label">
    <span *ngIf="formGroup.get(for).value">
      {{yes | translate}}
    </span>
    <span *ngIf="!formGroup.get(for).value">
      {{no | translate}}
    </span>
  </ng-container>
</ng-template>