import { Component, Inject, Input, Directive, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ClvService } from 'src/app/_services/clv.service';
import { asArray, ClvType, ProcurementType, Status, UserAction, StatusRule, CampaignTypeValueType, CampaignTypeVatType } from 'src/app/enum';
import { ProductClvTableComponent } from 'src/app/components/_partials/settings/clv-table/product-management/product-clv-table.component';
import { ISelectItem } from 'src/app/components/form/select/select-item';
import { AuthenticationService } from 'src/app/_services';
import { IClvDto, ICountryTranslated } from 'src/app/cards/product/product-info/product-info.model';
import { finalize } from 'rxjs/operators';
import { AppState } from 'src/app/state/app.state';
import { ProcurementCalendarCategoryRules } from 'src/app/enum/procurement-calendar-category-rules';
import { Color } from 'src/app/enum/color';
import { IPhoneCodeItem } from 'src/app/resolve/lxm-settings/users.resolve';
import { CalendarGroupCampaignType } from 'src/app/enum/calendar-group-campaign-type';

@Component({
  selector: "classificator-value-dialog",
  host: { 'class': 'dialog' },
  templateUrl: './classificator-value.dialog.html'
})
export class ClassificatorValueDialog {

  public get translationsPath(): string {
    return this.getTranslationPath();
  }

  private _parent: ProductClvTableComponent;

  // For template
  public UserAction = UserAction;
  public ClvType = ClvType;
  public statuses = asArray(Status);
  public statusRules = asArray(StatusRule);

  private _data: any;

  public unitCodeTypes: IClvDto[];
  public countryOptions: ICountryTranslated[];
  public phoneCodes: IPhoneCodeItem[];
  public campaignTypes: IClvDto[];
  public form: FormGroup;

  public saveInProgress = false;

  public get classificatorId(): string {
    return this._parent.classificatorId;
  }

  public requiredRightsForEdit = [UserAction.ManageClvs];

  constructor(
    public appState: AppState,
    private _translateService: TranslateService,
    private _clvService: ClvService,
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ClassificatorValueDialog>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any) {

    this.unitCodeTypes = this.dialogData.parent.formData?.unitCodeTypes;
    this.countryOptions = this.dialogData.parent.formData?.countries;
    this.phoneCodes = this.dialogData.parent.formData?.phoneCodes;
    this.campaignTypes = this.dialogData.parent.formData?.campaignTypes;

    this._parent = this.dialogData.parent;
    this._data = this.dialogData.row || {};
    this._data.address = this._data.address || {};

    if (this.dialogData.requiredRightsForEdit) {
      this.requiredRightsForEdit = this.dialogData.requiredRightsForEdit;
    }

    var procurementCalendarCategoryRulesArr = this.getProcurementCalendarCategoryRulesArr(this._data.procurementCalendarCategoryRules);

    this.form = this._formBuilder.group({
      classificatorId: new FormControl(this._parent.classificatorId),
      code: new FormControl(this._data.code),
      value: new FormControl(this._data.value),
      description: new FormControl(this._data.description),
      status: new FormControl(this._data.status === undefined ? Status.Active : this._data.status),
      isDefault: new FormControl(this._data.isDefault),

      // brand fields
      isPrivateLabel: new FormControl(this._data.isPrivateLabel || false),

      // calendar groups
      gs1Segments: new FormControl(this._data.gs1Segments?.map(x => ({ ...x, title: `${x.code} ${x.value}` })) || []),
      isForProcurement: new FormControl(this._data.isForProcurement),
      procurementType: new FormControl(this._data.procurementType),
      procurementCalendarCategoryRules: new FormControl(procurementCalendarCategoryRulesArr),
      procurementItems: new FormControl(this._data.procurementItems),
      isForCampaign: new FormControl(this._data.isForCampaign),
      campaignType: new FormControl(this._data.campaignType || CalendarGroupCampaignType.BuyInPriceDiscount),
      campaignTypes: new FormControl(this._data.campaignTypes),

      // manufacturer fields
      gln: new FormControl(this._data.gln),
      legalForm: new FormControl(this._data.legalForm),
      email: new FormControl(this._data.email),
      homepage: new FormControl(this._data.homepage),
      phoneCode: new FormControl(this._data.phoneCode?.id),
      phoneNumberShort: new FormControl(this._data.phoneNumberShort),
      address: this._formBuilder.group({
        country: new FormControl(this._data.address.country),
        county: new FormControl(this._data.address.county),
        city: new FormControl(this._data.address.city),
        street: new FormControl(this._data.address.street),
        nr: new FormControl(this._data.address.nr),
        postalCode: new FormControl(this._data.address.postalCode)
      }),

      // code format fields
      codeType: new FormControl(this._data.typeId),
      codeBeginning: new FormControl(this._data.codeBeginning),
      nextNumber: new FormControl(this._data.nextNumber),
      lastNumber: new FormControl(this._data.lastNumber),
      numberLength: new FormControl(this._data.numberLength),
      hasChecksum: new FormControl(this._data.hasChecksum),

      // assortment status fields
      color: new FormControl(this._data.color ?? Color.White),
      statusRule: new FormControl(this._data.statusRule),

      // campaign type fields
      valueType: new FormControl(this._data.valueType),
      vatType: new FormControl(this._data.vatType),
      numericValue: new FormControl(this._data.numericValue),
      displayRetailPrice: new FormControl(this._data.displayRetailPrice ?? false),
    });

    this.form.get('isForProcurement').valueChanges.subscribe(val => {
      this.form.get('isForCampaign').setValue(false, { emitEvent: false });
    });

    this.form.get('isForCampaign').valueChanges.subscribe(val => {
      this.form.get('isForProcurement').setValue(false, { emitEvent: false });
    });

  }

  protected getProcurementCalendarCategoryRulesArr(rules: number) {
    var rulesArr: number[] = [];

    asArray(ProcurementCalendarCategoryRules).forEach(element => {
      if (rules & element.id) {
        rulesArr.push(element.id);
      }
    });

    return rulesArr;
  }

  protected getProcurementCalendarCategoryRules(rulesArr: number[]) {
    let rules = 0;

    if (rulesArr != null && rulesArr.length > 0) {
      rulesArr.forEach(element => {
        rules |= element;
      });
    }

    return rules;
  }

  protected getData(): object {
    const f = this.form.value;

    const req: any = {
      classificatorId: this._parent.classificatorId,
      code: f.code,
      value: f.value,
      description: f.description,
      status: f.status,
      isDefault: f.isDefault || false,

      // brand fields
      isPrivateLabel: f.isPrivateLabel || false,

      // manufacturer fields
      gln: f.gln,
      legalForm: f.legalForm,
      email: f.email,
      homepage: f.homepage,
      phoneCodeId: f.phoneCode,
      phoneNumberShort: f.phoneNumberShort,
      address: {
        countryId: f.address.country?.id,
        county: f.address.county,
        city: f.address.city,
        street: f.address.street,
        nr: f.address.nr,
        postalCode: f.address.postalCode
      },

      // code format fields
      typeId: f.codeType,
      codeBeginning: f.codeBeginning,
      nextNumber: parseInt(f.nextNumber),
      lastNumber: parseInt(f.lastNumber),
      numberLength: parseInt(f.numberLength),
      hasChecksum: f.hasChecksum || false,

      // assortment status fields
      color: f.color,
      statusRule: f.statusRule,

      // campaign type fields
      valueType: f.valueType,
      vatType: f.vatType,
      displayRetailPrice: f.displayRetailPrice,
      numericValue: f.numericValue
    };

    if (this._parent.classificatorId == ClvType.CalendarGroup || this._parent.classificatorId == ClvType.ProductTag) {
      req.gs1SegmentIds = f.gs1Segments?.map(x => x.id);
    }

    if (this._parent.classificatorId == ClvType.CalendarGroup) {
      const isForProcurement = f.isForProcurement || false;
      const isForCampaign = f.isForCampaign || false;

      req.isForProcurement = isForProcurement;
      req.isForCampaign = isForCampaign;

      if (isForProcurement) {
        req.procurementType = f.procurementType;
        req.procurementCalendarCategoryRules = this.getProcurementCalendarCategoryRules(f.procurementCalendarCategoryRules);
        req.procurementItems = f.procurementItems;
      }

      if (isForCampaign) {
        req.campaignType = f.campaignType;
        req.campaignTypes = f.campaignType == CalendarGroupCampaignType.RetailSalesPriceDiscount ? f.campaignTypes?.map(x => x.id) : [];
      }
    }

    return req;
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public save(): void {
    this.saveInProgress = true;
    const url = this._parent.baseUrl;

    this._clvService
      .save(url, this._data.id, this.getData())
      .pipe(
        finalize(() => {
          this.saveInProgress = false;
        })
      )
      .result(this.form, response => {
        this.dialogRef.close({
          response: response,
          form: this.form
        });
      });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  private getTranslationPath(): string {
    switch (this.classificatorId) {
      case ClvType.Award:
        return 'cards.settings.product_management_settings.awards_edit';
      case ClvType.ProductBrand:
        return 'cards.settings.product_management_settings.brands_edit';
      case ClvType.Manufacturer:
        return 'cards.settings.product_management_settings.manufacturers_edit';
      case ClvType.ProductUnitCodeTypeFormat:
        return 'cards.settings.product_management_settings.codes_edit';
      case ClvType.Winery:
        return 'cards.settings.product_management_settings.wineries_edit';
      case ClvType.WineRegionOfOrigin:
        return 'cards.settings.product_management_settings.wine_regions_edit';
      case ClvType.LogisticsPartner:
        return 'cards.settings.product_management_settings.logistics_partners_edit';
      case ClvType.CalendarGroup:
        return 'cards.settings.product_management_settings.calendar_group_edit';
      case ClvType.ChainBrand:
        return 'cards.settings.product_management_settings.chain_brands_edit';
      case ClvType.AssortmentStatus:
        return 'cards.settings.assortment_statuses_edit';
      case ClvType.ProductTag:
        return 'cards.settings.product_management_settings.product_tag_edit';
      case ClvType.PriceGroup:
        return 'cards.settings.product_management_settings.price_group_edit';
      case ClvType.ClientTag:
        return 'cards.settings.product_management_settings.client_tag_edit';
      case ClvType.CampaignType:
        return 'cards.settings.product_management_settings.campaign_type_edit';
    }
  }
}

@Directive()
class InfoBase {
  @Input()
  public formGroup: FormGroup;

  @Input()
  public canEdit: boolean;

  constructor(authService: AuthenticationService) {
    if (this.canEdit === undefined) {
      this.canEdit = authService.hasRight([UserAction.ManageClvs]);
    }
  }
}


@Component({
  selector: "classificator-value-info",
  templateUrl: './template/classificator-value-info.html'
})
export class ClassificatorValueInfo extends InfoBase {
  public statuses = asArray(Status);

  @Input()
  public translationsPath: string;
}


@Component({
  selector: "product-brand-info",
  templateUrl: './template/product-brand-info.html'
})
export class ProductBrandInfo extends InfoBase {
}


@Component({
  selector: "calendar-group-info",
  templateUrl: './template/calendar-group-info.html'
})
export class CalendarGroupInfo extends InfoBase implements AfterViewInit {
  @Input()
  public campaignTypes: IClvDto[];

  public procurementTypeOptions = asArray(ProcurementType);
  public procurementCalendarCategoryRulesOptions = asArray(ProcurementCalendarCategoryRules);
  public calendarGroupCampaignTypesOptions = asArray(CalendarGroupCampaignType);

  public isCampaignTypeAdded = false;

  public ngAfterViewInit(): void {
    const selectedCampaignTypes = this.formGroup.get('campaignTypes').value || [];
    this.isCampaignTypeAdded = selectedCampaignTypes.length > 0;
  }

  public addCampaignType() {
    this.isCampaignTypeAdded = true;
  }

  public get isCampaignTypesSelectVisible() {
    return this.formGroup.get('isForCampaign').value == true &&
      this.formGroup.get('campaignType').value == CalendarGroupCampaignType.RetailSalesPriceDiscount;
  }

}


@Component({
  selector: "manufacturer-info",
  templateUrl: './template/manufacturer-info.html'
})
export class ManufacturerInfo extends InfoBase {
  @Input()
  public countryOptions: ICountryTranslated[];
  @Input()
  public phoneCodes: IPhoneCodeItem[];
  public statuses = asArray(Status);
}


@Component({
  selector: "product-unit-code-type-format-info",
  templateUrl: './template/product-unit-code-type-format-info.html'
})
export class ProductUnitCodeTypeFormatInfo extends InfoBase {
  @Input()
  public unitCodeTypes: ISelectItem[];
}


@Component({
  selector: "logistics-partner-info",
  templateUrl: './template/logistics-partner-info.html'
})
export class LogisticsPartnerInfo extends InfoBase {
  @Input()
  public countryOptions: ICountryTranslated[];
}

@Component({
  selector: "product-tag-info",
  templateUrl: './template/product-tag-info.html'
})
export class ProductTagInfo extends InfoBase {
}

@Component({
  selector: "assortment-status-info",
  templateUrl: './template/assortment-status-info.html'
})
export class AssortmentStatusInfo extends InfoBase {
  public statusRules = asArray(StatusRule);
}

@Component({
  selector: "price-group-info",
  templateUrl: './template/price-group-info.html'
})
export class PriceGroupInfo extends InfoBase {
}

@Component({
  selector: "client-tag-info",
  templateUrl: './template/client-tag-info.html'
})
export class ClientTagInfo extends InfoBase {
}

@Component({
  selector: "campaign-type-info",
  templateUrl: './template/campaign-type-info.html'
})
export class CampaignTypeInfo extends InfoBase {
  public campaignTypeValueTypeOptions = asArray(CampaignTypeValueType);
  public campaignTypeVatTypeOptions = asArray(CampaignTypeVatType);
}