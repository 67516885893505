import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SettingsService } from 'src/app/_services';
import { IProductExtraParametersSettings } from 'src/app/models/settings/product-fields-settings';

@Injectable()
export class ExtraParametersSettingsCardResolve implements Resolve<IProductExtraParametersSettings> {

  constructor(private _settingsService: SettingsService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<IProductExtraParametersSettings> {
    return this._settingsService.getProductExtraParametersSettings();
  }
}