import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, ControlContainer } from '@angular/forms';
import { ContentLanguage, LxmDialog } from 'src/app/_helpers';
import { AuthenticationService, OfferService, ProductPricelistService, ProductService } from 'src/app/_services';
import { OfferType } from 'src/app/enum';
import { forkJoin, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ISupplierBasePriceScheme } from 'src/app/cards/product/product-pricelist/product-pricelist.model';
import { TranslateEnumPipe, TranslatedValuePipe } from 'src/app/pipes';
import { OfferProductsListBase } from '../../offer-products-list-base';
import moment from 'moment';
import { nullIfUndefined } from 'src/app/util/fn';
import { SupplierOfferProductDefaultItem } from '../../supplier-offer/supplier-offer-products.component';

@Component({
  selector: 'offer-product-replacement-dialog',
  host: { 'class': 'dialog' },
  templateUrl: './offer-product-replacement.dialog.html',
  styleUrls: ['./offer-product-replacement.dialog.scss']
})
export class OfferProductReplacementDialog extends OfferProductsListBase {

  public formGroup: FormGroup;
  public for = 'products';

  public isSaveLoading = false;

  public product: any;
  public offerId: string;
  public retailerId: string;

  public offerType: OfferType;

  public readonly = true;

  public get columns() {
    return [
      'product_image',
      'product_name',
      'available_from',
      'list_price',
      'discount',
      'price_retailer',
      'action_detail',
      'actions'
    ];
  };

  protected get _defaultItem(): any {
    return SupplierOfferProductDefaultItem;
  }
  
  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public dialogRef: MatDialogRef<OfferProductReplacementDialog>,
    private _pricesService: ProductPricelistService,
    private _offerService: OfferService,
    private _productsService: ProductService,
    public contentLanguage: ContentLanguage,
    _translatedValuePipe: TranslatedValuePipe,
    _translateEnumPipe: TranslateEnumPipe,
    @Optional() controlContainer: ControlContainer,
    _authService: AuthenticationService
  ) {
    super(controlContainer, contentLanguage);

    const { offerId, products, readonly, retailerId, offerType, date, currency } = this._data;

    this.retailerId = retailerId;
    this.date = date;
    this.currency = currency;
    this.offerType = offerType;
    this.readonly = readonly;

    this.offerId = offerId;

    this.formGroup = new FormGroup({
      replacementProductAc: new FormControl(),
      products: new FormControl(products?.map(x => x.value) ?? [])
    });

    this._supplierOfferProductsListComponentInit();
  }

  public get getProductAcExcludeIds() {
    return this.dataSource.data.map(x => x.value.productId);
  }

  public get hasReplacementProducts() {
    return this.dataSource.data?.length > 1;
  }

  public get replacementProducts() {
    if (this.hasReplacementProducts) {
      const products = this.dataSource.data.filter((x, i) => {
        return i !== 0;
      })
      return products;
    }
    return [];
  }

  public get originalProduct() {
    return this.dataSource.data[0]?.value;
  }

  private _getBasePrices(productIds: string[]): Observable<{ [productId: string]: ISupplierBasePriceScheme }> {
    return !this.retailerId || !this.date || !productIds.length || !this.currency?.id
      ? of(Object.assign({}, ...productIds.map(x => ({ [x]: null }))))
      : this._pricesService.getProductPrices(this.retailerId, this.date, productIds, this.currency?.id);
  }

  private _supplierOfferProductsListComponentInit() {
    this.formGroup.get('replacementProductAc').valueChanges.subscribe(x => {
      if (x) {

        var productId = x.id;

        const basePricesObservable = this.retailerId && this.offerType != OfferType.Logistics
          ? this._getBasePrices([productId])
          : of({});

        const productsWithDisplayObservable = this.retailerId && this.offerType != OfferType.Logistics
          ? this._offerService.productsWithDisplay([productId], this.retailerId)
          : of({});

        const productLogisticsDataObservable = this.retailerId && this.offerType == OfferType.Logistics
          ? this._offerService.logisticsData([productId], this.retailerId)
          : of({});

        const productValidityObservable = this.retailerId
          ? this._productsService.getProductsValidState([productId], [this.retailerId])
          : of({});

        // const offerProductRelationsObservable = this.offerType == OfferType.PriceChanges && this.date && (this.offerId || this.retailerId)
        //   ? this._offerService.getOfferProductRelations([productId], this.date, this.offerId, this.retailerId)
        //   : of({});

        forkJoin([basePricesObservable, productsWithDisplayObservable, productLogisticsDataObservable, productValidityObservable])
          .subscribe(results => {
            const basePrices = results[0];
            const productsWithDisplay = results[1];
            const logisticsData = results[2];
            const validState = results[3];
            // const offerProductRelations = results[4];

            const salePrice = basePrices[productId]?.mainAssortmentPrice || basePrices[productId]?.publicSalesPrice;
            const listPrice = basePrices[productId]?.publicSalesPrice;

            let item = {
              productId: productId,
              productHasDisplay: productsWithDisplay[productId],
              name: x.name,
              ean: x.ean,
              supplierCode: x.supplierCode,
              assortmentStatus: basePrices[productId]?.status,
              brand: { id: x.brand?.id, value: x.brand?.value },
              selfPrice: basePrices[productId]?.buyInPrice,
              calculatedSelfPriceInForeignCurrency: basePrices[productId]?.calculatedBuyInPriceInForeignCurrency,

              listPrice: listPrice,
              salePrice: salePrice,
              discount: this.calculateDiscount(salePrice, listPrice),
              vat: basePrices[productId]?.vat,
              mainAssortmentPrice: basePrices[productId]?.mainAssortmentPrice || basePrices[productId]?.publicSalesPrice,
              imageUrl: x.hasImage ? `${environment.apiUrl}api/products/${productId}/images/thumbnailMedium` : null,

              segment: logisticsData[productId]?.segment,
              storageConditions: logisticsData[productId]?.storageConditions,
              transportUnitDefaultEan: logisticsData[productId]?.transportUnitDefaultEan,
              storageMinTemp: logisticsData[productId]?.storageMinTemp,
              storageMaxTemp: logisticsData[productId]?.storageMaxTemp,
              palletType: logisticsData[productId]?.palletType,

              mainAssortmentCurrency: null,
              mainAssortmentListPrice: null,
              discountPriceChanges: null,
              priceChangesInfo: null,

              isValid: validState[productId]?.[this.retailerId || ""],

              availableFrom: null,
              replacementForProductId: this.originalProduct?.productId,
              isNew: true,
              isNormalized: false,
              isDeleted: false,
            };

            // if (this.offerType == OfferType.PriceChanges) {
            //   item.listPrice = basePrices[productId]?.publicSalesPrice;
            //   item.mainAssortmentPrice = basePrices[productId]?.mainAssortmentPrice;
            //   item.mainAssortmentListPrice = basePrices[productId]?.mainAssortmentListPrice;
            //   item.mainAssortmentCurrency = basePrices[productId]?.mainAssortmentCurrency;
            //   item.discountPriceChanges = basePrices[productId]?.defaultDiscountPercent;
            //   item.priceChangesInfo = {
            //     priceChangeDate: { editableDate: this.date },
            //     activeCampaignPriceSchemes: offerProductRelations?.activeCampaignPriceSchemes?.[productId],
            //     activeOffers: offerProductRelations?.activeOffers?.[productId]
            //   };
            // }

            
            // const data = this.dataSource.data;

            this.addItem(item);
            if (basePrices[productId]?.defaultDiscountPercent) {
              this.dataSource.data[this.dataSource.data.length - 1].get('discount').setValue(basePrices[productId]?.defaultDiscountPercent);
            }

          });
          this.formGroup.get('replacementProductAc').setValue(null);
      }
    });
  }

  public save() {
    this.closeDialog(this.replacementProducts);
  }

  protected _getNormalizedItem(item: any) {
    if (item.isNormalized) {
      return item;
    }

    return {
      id: item.id,
      productId: item.productId,
      preview: item.preview || {},
      campaignInfo: item.campaignInfo || {},
      name: this.contentLanguage.get(item.name),
      display: item.display || false,
      productHasDisplay: item.productHasDisplay || false,
      limit: item.limit || null,
      ean: item.ean,
      supplierCode: item.supplierCode,
      brand: item.brand?.value || null,
      availableFrom: item.availableFrom?.editableDate || null,
      campaignPriceValidFrom: item.campaignInfo?.campaignPriceValidFrom?.editableDate || this.date || moment(),
      selfPrice: item.selfPrice,
      calculatedSelfPriceInForeignCurrency: item.calculatedSelfPriceInForeignCurrency,
      listPrice: item.listPrice,
      salePrice: item.salePrice,
      salePriceCampaign: item.salePrice,
      discount: this.calculateDiscount(item.salePrice, item.listPrice),
      // discountCampaign: this.calculateDiscount(item.salePrice, this.getCampaignDiscountPrice(this.getMainAssortmentPrice(item), this.getListPrice(item))),
      discountCampaign: null,
      vat: item.vat,
      mainAssortmentPrice: item.mainAssortmentPrice,
      mainAssortmentListPrice: item.mainAssortmentListPrice,
      mainAssortmentCurrency: item.mainAssortmentCurrency,
      suggestedRetailPrice: item.suggestedRetailPrice,
      shops: item.shops || [],
      assortmentStatus: item.assortmentStatus,
      isDeleted: item.isDeleted || false,
      isNew: item.isNew || false,
      isNormalized: true,
      thumbnailUrl: item.thumbnailUrl,
      thumbnail: item.thumbnail,
      thumbnailContentType: item.thumbnailContentType,
      imageUrl: item.imageUrl,
      contentUnitId: item.contentUnitId,
      netContent: item.netContent,
      drainedWeightInGrams: item.drainedWeightInGrams,
      validSchemeSelfPrice: item.validSchemeSelfPrice,
      validSchemeCalculatedSelfPriceInForeignCurrency: item.validSchemeCalculatedSelfPriceInForeignCurrency,
      validSchemeListPrice: item.validSchemeListPrice,
      validSchemeMainAssortmentPrice: item.validSchemeMainAssortmentPrice,
      validSchemeMainAssortmentListPrice: item.validSchemeMainAssortmentListPrice,
      requestedPrice: item.requestedPrice,

      segment: nullIfUndefined(item.logisticsInfo?.segment),
      storageConditions: nullIfUndefined(item.logisticsInfo?.storageConditions),
      storageMinTemp: nullIfUndefined(item.logisticsInfo?.storageMinTemp),
      storageMaxTemp: nullIfUndefined(item.logisticsInfo?.storageMaxTemp),
      transportUnitDefaultEan: nullIfUndefined(item.logisticsInfo?.transportUnitDefaultEan),
      palletType: nullIfUndefined(item.logisticsInfo?.palletType),

      priceChangeDate: nullIfUndefined(item.priceChangesInfo?.priceChangeDate?.editableDate),
      priceChangeReason: nullIfUndefined(item.priceChangesInfo?.priceChangeReason),
      priceChangeNote: nullIfUndefined(item.priceChangesInfo?.priceChangeNote),
      editableListPrice: item.listPrice,
      salePricePriceChanges: item.salePrice,
      discountPriceChanges: item.discountPriceChanges || this.calculateDiscount(item.salePrice, item.listPrice),
      activeCampaignPriceSchemes: nullIfUndefined(item.priceChangesInfo?.activeCampaignPriceSchemes),
      activeOffers: nullIfUndefined(item.priceChangesInfo?.activeOffers),

      orderDetails: item.orderDetails,
      procurementDetails: item.procurementDetails || {},

      status: item.status,
      isValid: item.isValid,
      isSuspended: item.isSuspended ?? false,
      replacementForProductId: item.replacementForProductId ?? null
    };
  }

  public closeDialog(res?) {
    this.dialogRef.close(res);
  }

}
