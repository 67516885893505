import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class FileService {

  constructor(private _http: HttpClient) { }

  public upload(data: FormData) {
    return this._http
      .post<any>('api/files', data, {
        reportProgress: true,
        observe: 'events'
      });
  }

  public uploadImage(data: FormData) {
    return this._http
      .post<any>('api/files/image', data, {
        reportProgress: true,
        observe: 'events'
      });
  }

  public delete(id: string) {
    return this._http.delete<any>(`api/files/${id}`);
  }

  public download(id: string): any {
    document.location.href = environment.apiUrl + `api/files/${id}`;
  }

}
