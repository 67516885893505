import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { IOffersSupplierListItem } from 'src/app/models';
import { SupplierOfferStatus, UserAction, HttpMethod } from 'src/app/enum';
import { DataTableService, SignalRService, OfferService } from 'src/app/_services';
import { Subscription } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { HubConnection } from '@microsoft/signalr';
import { OffersListBase} from '../offers-list-base';
import { ActivatedRoute } from '@angular/router';
import { SellerOffersListState } from '../offers-list.state';

@Component({
  selector: "offers-supplier-card",
  templateUrl: './offers-supplier.card.html',
  host: { 'class': 'data-table-card' },
  styleUrls: ['./offers-supplier.card.scss', '../offers-list-base.scss']
})
export class OffersSupplierCard extends OffersListBase<IOffersSupplierListItem, any, SellerOffersListState> implements OnInit, OnDestroy {

  private _hubConnection: HubConnection;
  private _signalRSubscription: Subscription;

  public dataUrl = 'offers/forSupplier';
  method = HttpMethod.Post;
  initialData;
  public translationsPath = 'cards.offers';

  public OfferStatus = SupplierOfferStatus;
  public UserAction = UserAction;

  public table = {
    columns: [
      // 'offer_select',
      'retailer',
      'period',
      'name',
      'approved',
      'status'
    ]
  };

  constructor(
    private _offerService: OfferService,
    route: ActivatedRoute,
    dataTableService: DataTableService<IOffersSupplierListItem, any>,
    cd: ChangeDetectorRef,
    location: Location,
    public state: SellerOffersListState,
    private _signalR: SignalRService) {
    super(location, dataTableService, cd);
    this._state = state;
    
    this.initialData = route?.snapshot?.data?.initialData;

    this._signalRSubscription = this._signalR.commonHub
      .subscribe(x => {
        this._hubConnection = x;
        if (!x) {
          return;
        }
        x.on('offerHasChanged', data => {
          const row = this.dataSource.data.find(r => r.id === data.offerId);
          if (row) {
            row.isNew = data.read === false;
          }
        });
      });

  }

  protected getSortParam(sort: MatSort) {
    if (!sort || !sort.active || sort.direction === "") {
      return '';
    }

    let colId = sort.active;
    switch (colId) {
      case 'period':
        colId = 'from';
        break;
      case 'approved':
        colId = 'confirmedProductCount';
        break;
    }

    return colId + ' ' + sort.direction;
  }

  public getStatusProgress(status: SupplierOfferStatus) {
    return this._offerService.getSendingOfferStatusProgress(status);
  }

  ngOnDestroy(): void {

    if (this._hubConnection) {
      this._hubConnection.off('offerHasChanged');
    }

    if (this._signalRSubscription) {
      this._signalRSubscription.unsubscribe();
    }
  }
}
