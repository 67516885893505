<div class="flex flex-1 flex-col gap-5x">
  <lxm-input
    *ngIf="searchable"
    [formGroup]="controls"
    class="flex flex-1 border-none"
    for="searchTerm"
    autofocus
    [emitValueChanges]="true"
    (onValueChange)="onSearch($event)"
    [placeholder]="placeholder | translate"
  ></lxm-input>

  <div class="flex flex-col">
    <!-- <ng-container *ngTemplateOutlet="optionTemplate; context: { item: option }"></ng-container> -->
    <ng-container *ngIf="filteredOptions.length > 0">
      <div
        *ngFor="let option of filteredOptions"
        ov-menu-item
        (click)="handleOptionClick(option)"
        [class.selected]="isOptionSelected(option)"
      >
        <div class="flex items-center gap-5x">
          <lxm-checkbox [checked]="isOptionSelected(option)"></lxm-checkbox>

          <!-- *ngIf="multiple" -->

          <svg-icon
            *ngIf="labelIconField"
            class="mr-2 {{ labelIconClass }}"
            [key]="option[labelIconField]?.toLowerCase()"
          ></svg-icon>

          {{ getItemValue(option) | translate }}
        </div>
        <div>
          <span class="select-fullscreen-option__icon tick-active-icon"></span>
        </div>
      </div>
    </ng-container>
  </div>
</div>
