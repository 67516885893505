import { Component, Input, Optional, OnInit, Host } from "@angular/core";
import { DepositControlBuilder } from 'src/app/_helpers/control.builder';
import { ViewEncapsulation } from '@angular/core';
import { FormGroup, ControlContainer, FormArray } from '@angular/forms';
import { ReadonlyDirective } from 'src/app/directives';

@Component({
  selector: "deposit-manager",
  templateUrl: "./deposit-manager.component.html",
  styleUrls: ['./deposit-manager.scss']
})
export class DepositManagerComponent implements OnInit {

  @Input() public form: FormGroup;
  @Input() public for: string;
  @Input() public readonly = false;
  @Input() public depositOptions: any[];

  private _emptyDepositModel = {
    deposit: null,
    amount_pc: null
  };

  constructor(
    @Optional() @Host() private _readonlyQuery: ReadonlyDirective,
    @Optional() private _controlContainer: ControlContainer,
    private _: DepositControlBuilder) {
  }

  public ngOnInit(): void {

    if (this._readonlyQuery && this._readonlyQuery.readonly) {
      this.readonly = this._readonlyQuery.readonly;
    }

    if (this._controlContainer && this._controlContainer.control instanceof FormGroup) {
      this.form = <FormGroup>this._controlContainer.control;
    }
  }

  get formArray() {
    return this.form.get(this.for) as FormArray;
  }

  public addItem(model = this._emptyDepositModel) {
    this.formArray.push(this._.createDepositControl(model));
  }

  public removeItem(index: number) {
    this.formArray.removeAt(index);
  }

  public compareDepositOptions = (a, b) => {
    return (a.country?.name + a.translatedValue) > (b.country?.name + b.translatedValue) ? 1 : -1;
  }
}

