import { Injectable, Renderer2 } from "@angular/core";
import { ScriptService } from "./script.service";

@Injectable()
export class PipedriveService {

  private _pipedriveScriptLocal;
  private _pipedriveScriptExt;

  constructor(
    private _scriptService: ScriptService,
    private _renderer: Renderer2
  ) { }
 
    public appendLeadbooster(playbookUuid: string) {
      this._pipedriveScriptLocal = this._scriptService.loadJsScript(this._renderer, `window.pipedriveLeadboosterConfig = {base: 'leadbooster-chat.pipedrive.com',companyId: 104337,playbookUuid: '${playbookUuid}',version: 2};(function () {var w = window;if (w.LeadBooster) {console.warn('LeadBooster already exists');} else {w.LeadBooster = {q: [],on: function (n, h) {this.q.push({ t: 'o', n: n, h: h });},trigger: function (n) {this.q.push({ t: 't', n: n });},};}})();`);
      this._pipedriveScriptExt = this._scriptService.loadExternalJsScript(this._renderer, "https://leadbooster-chat.pipedrive.com/assets/loader.js", true);
    }

    public removeLeadbooster() {

      if (this._pipedriveScriptLocal) {
        this._pipedriveScriptLocal.remove();
      }

      if (this._pipedriveScriptExt) {
        this._pipedriveScriptExt.remove();
      }

      const leadboosterContainer = document.getElementById("LeadboosterContainer");
      if (leadboosterContainer) {
        leadboosterContainer.remove();
      }
      (window as any).LeadBooster = null;
    }

}