import { Component, OnInit, ViewChild } from '@angular/core';
import { TitleService } from 'src/app/_services/title.service';
import { OfferFilesCardComponent } from 'src/app/cards/offers/offer-files/offer-files.card';
import { IAttachment, IOfferCard, IOfferCardDetail } from 'src/app/models';
import { AppState } from 'src/app/state/app.state';
import { ActivatedRoute } from '@angular/router';
import { OfferKind, OfferType, SupplierOfferStatus } from 'src/app/enum';
import { Observable } from 'rxjs';
import { LxmDialog } from 'src/app/_helpers';

@Component({
  selector: 'offer-layout',
  templateUrl: './offer.layout.html',
  host: { 'class': 'content' },
  styleUrls: ['./offer.layout.scss']
})
export class OfferLayoutComponent implements OnInit {
  title = 'cards.offer.title';

  public offer: IOfferCardDetail;
  public offerId: string;
  public offerKind: OfferKind;
  public OfferKind = OfferKind;

  public retailerId: string;
  public offerType: string;

  public editing: boolean = false;

  @ViewChild('files') filesComponent: OfferFilesCardComponent;

  public get attachments(): IAttachment[] {
    return this.filesComponent.filesList.dataSource.data;
  }

  public get isEditable() {

    if (!this.offer) {
      return true;
    }

    if (this.offerKind === OfferKind.OfferRequest) {
      return this.offer.status === SupplierOfferStatus.Created;
    }
 
    if (this.offer.status === SupplierOfferStatus.Created ||
      this.offer.status === SupplierOfferStatus.Sent ||
      this.offer.status === SupplierOfferStatus.Negotiating) {
      return true;
    }

    return false;
  }

  constructor(
    route: ActivatedRoute,
    private _titleService: TitleService,
    private _dialog: LxmDialog,
    public appState: AppState) {

    const offerCard = route.snapshot.data.offerCard as IOfferCard;

    if (offerCard) {
      this.offer = offerCard.offerDetail;
      this.offerId = this.offer.id;
      this.offerKind = offerCard.offerKind;
      this.retailerId = this.offer?.retailer?.id;
      this.offerType = this.offer?.offerType?.id;
    } else {
      this.offerKind = route.snapshot.data.offerKind;
    }

  }

  public ngOnInit() {
    this._titleService.setTitle(this.title);
  }

  public onFormChange(form) {
    const { retailer, offerType } = form || {};
    this.retailerId = retailer?.id;
    this.offerType = offerType?.id;
  }

  public canDeactivate(): Observable<boolean> | boolean | Promise<boolean> {

    if (!this.editing) {
      return true;
    }

    return new Promise<boolean>((resolve, reject) => {
      this._confirmDiscardInternal(() => { resolve(true); });
    });
  }

  private _confirmDiscardInternal(onConfirm: () => void) {
    this._dialog.confirm({
      'title': 'cards.offers.offer_dialogs.discard_changes.title',
      'message': 'cards.offers.offer_dialogs.discard_changes.text',
    }, onConfirm);
  }
}
