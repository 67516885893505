import {Directive, HostListener} from "@angular/core";

@Directive({
    selector: "[stop-click]"
})
export class StopClickDirective {

  @HostListener("click") public onClick() {
    return false
  }

}
