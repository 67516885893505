import { FormGroup } from "@angular/forms";
import { numberUtil } from "src/app/util/number-util";
import { ContentLanguage } from "src/app/_helpers";

export function calculateProductSum(row) {
  const { baseUnitBuyInPrice } = row || {};
  const count = getProductCount(row);
  return numberUtil.toNumber(count) * baseUnitBuyInPrice;
}

export function getProductCount(row) {
  const { orderUnitId, availableUnits, amount } = row || {};

  if (!orderUnitId || !availableUnits.length) {
    return null;
  }
  const baseUnitsCount = availableUnits.find(x => x.id === orderUnitId)?.baseUnitsCount;

  return (baseUnitsCount * amount);
}

export function formatProductAcLabel(item: any, contentLanguageSvc: ContentLanguage) {
  const segments = [];
  if (item.ean) {
    segments.push(item.ean);
  }
  if (item.supplierCode) {
    segments.push(item.ean ? `(${item.supplierCode})` : item.supplierCode);
  }

  const name = item.name || item.productFullName;
  segments.push(contentLanguageSvc.get(name));

  return segments.join(' ');
}
