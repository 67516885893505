<lxm-button
      [disabled]="disabled"
      [readonly]="readonly"
      [label]="label" 
      [icon]="icon" 
      [type]="type" 
      [title]="title" 
      [buttonType]="buttonType"
      [padding]="padding"
      (click)="toggleOptionsList()">

      <div class="image-box-actions" [class.options-open]="showOptionsList">
            <div class="image-box-actions__options">
                  <ul>
                        <ng-content select="[options]"></ng-content>
                  </ul>
            </div>
      
      </div>


</lxm-button>

<lxm-overlay *ngIf="showOptionsList" name="image-box-overlay" (click)="toggleOptionsList(false)"></lxm-overlay>

