export enum Unit {
    Piece = <any>'40f201b0-7d42-4c27-8f2e-a6aebfb13e23',
    Kg = <any>'88d82609-4c55-42c9-85d7-a97984f66061',
    Liter = <any>'c27b0b78-4bb1-4e88-ab1b-db1de847c747',
    Meter = <any>'d17d2abf-a48a-4381-9d9a-d0a757a91540',
    SquareMeter = <any>'7761f8ee-b023-4ad5-9f3c-7c639175016b',
    CubicMeter = <any>'4de3e519-d7cc-43b6-ad36-60073d36afc5',
    Gram = <any>'Gram',
}

export const UnitShort = { ...Unit };

(Unit as any).__typeName = 'Unit';
(UnitShort as any).__typeName = 'UnitShort';
