<ng-container [formGroup]="formGroup"  [ngSwitch]="quill">

  <ng-container *ngSwitchCase="true" [ngSwitch]="readonly">
    <quill-editor *ngSwitchCase="false" [formControlName]="for" [placeholder]="placeholder" [modules]="quillModules">
    </quill-editor>
    <quill-view *ngSwitchDefault class="lxm-input textarea-readonly w-full" [content]="formValue"></quill-view>
  </ng-container>

  <ng-container *ngSwitchDefault [ngSwitch]="readonly">
  
    <div *ngSwitchCase="true" class="lxm-input textarea-readonly w-full">
        {{formValue}}
    </div>

    <ng-container *ngSwitchCase="false" [ngSwitch]="isAutosize">
      <div class="lxm-textarea-wrapper" [class.ng-invalid]="isValid === false" *ngSwitchCase="true">
        <textarea
          autosize 
          class="lxm-input lxm-textarea"
          placeholder="{{placeholder | translate}}"
          [id]="id"
          [formControlName]="for"
          [minRows]="minRows"
          [maxRows]="maxRows"
          [maxlength]="maxlength"
          (blur)="blur.emit($event)">
        </textarea>
        <ng-content select="[textarea-actions]">
        </ng-content>
      </div>
      <div class="lxm-textarea-wrapper" [class.ng-invalid]="isValid === false" *ngSwitchCase="false">
        <textarea
          class="lxm-input lxm-textarea"
          placeholder="{{placeholder | translate}}"
          [id]="id"
          [formControlName]="for"
          [minRows]="minRows"
          [maxRows]="maxRows"
          [maxlength]="maxlength"
          (blur)="blur.emit($event)">
        </textarea>
        <ng-content select="[textarea-actions]">
        </ng-content>
      </div>
    </ng-container>

  </ng-container>

</ng-container>




