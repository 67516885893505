<div class="radio-wrapper" [style.justify-content]="justify" [ngSwitch]="readonly" [class.readonly]="readonly">
  <ng-container *ngSwitchDefault>
    <div (click)="select(option)"
      *ngFor="let option of options; let i = index; trackBy: trackByFn;"
      class="lxm-radio__option option-{{i}}"
      [class.selected]="trackByFn(i, selected) === trackByFn(i, option)">
      <ng-container 
        [ngTemplateOutlet]="template"
        [ngTemplateOutletContext]="{ $implicit: option, option: option, i: i, isSelected: trackByFn(i, selected) === trackByFn(i, option) }">
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="true">
    <ng-container 
      [ngTemplateOutlet]="template"
      [ngTemplateOutletContext]="{ $implicit: selected, option: selected, i: 0, isSelected: trackByFn(i, selected) === trackByFn(i, option) }">
    </ng-container>
  </ng-container>
</div>

<ng-template #defaultItemTemplate let-option="option" let-i="i">
    <button class="toggle-button greyed-button mr10" 
      type="{{buttonType}}"
      [ngClass]="option[discriminator]"
      tabIndex="-1"
      [class.toggled]="trackByFn(i, selected) === trackByFn(i, option)">
        <type-indicator [type]="option" ></type-indicator>
        {{ getOptionLabel(option) | translate }}
    </button>
</ng-template>
