import { CommonModule } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";

@Component({
  selector: "print",
  templateUrl: "./prettyprint.component.html",
  styleUrls: ["./prettyprint.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class PrettyPrintComponent {
  @Input() public value: any;
  @Input() public toggled = false;

  @HostBinding("class.toggled") public get isToggled() {
    return this.toggled;
  }

  @HostListener("click") public toggleOpen() {
    this.toggled = !this.isToggled;
  }

  // public closeTimeout;
  // @HostListener('mouseleave') public toggleClose() {
  //   clearTimeout(this.closeTimeout);
  //   this.closeTimeout = setTimeout(() => {
  //     this.toggled = false;
  //   }, 100);
  // }
}
