<div class="tab-content">
    <card-section>
        <span header-title>
            {{'cards.settings.product_management_settings.segments_tree.title' | translate}}
        </span>
    </card-section>
</div>

<lxm-tree class="product-segments-tree" [initialData]="initialData" dataUrl="atcTree" [displayColumns]="displayColumns">
    <div lxmTreeColumnDef="code">
        <div *lxmTreeHeaderDef>
            {{'cards.settings.product_management_settings.segments_tree.table.code' | translate}}
        </div>
        <div *lxmTreeCellDef="let segment">{{segment?.code | formValue}}</div>
    </div>

    <div lxmTreeColumnDef="name">
        <div *lxmTreeHeaderDef>
            {{'cards.settings.product_management_settings.segments_tree.table.name' | translate}}
        </div>
        <div *lxmTreeCellDef="let segment">{{segment?.translatedName | translatedValue}}</div>
    </div>

    <div lxmTreeColumnDef="targetGroup">
        <div *lxmTreeHeaderDef>
            {{'cards.settings.product_management_settings.segments_tree.table.targetGroup' | translate}}</div>
        <div *lxmTreeCellDef="let segment">{{segment.targetGroup | translateEnum : 'AtcSegmentTargetGroup'}}</div>
    </div>

    <div lxmTreeColumnDef="canAssignToProduct">
        <div *lxmTreeHeaderDef>
            {{'cards.settings.product_management_settings.segments_tree.table.canAssignToProduct' | translate}}</div>
        <div *lxmTreeCellDef="let segment">{{segment.canAssignToProduct | translateEnum : 'Bool'}}</div>
    </div>

    <div lxmTreeColumnDef="status">
        <div *lxmTreeHeaderDef>
            {{'cards.settings.product_management_settings.segments_tree.table.status' | translate}}
        </div>
        <div *lxmTreeCellDef="let segment">{{segment.status | translateEnum : 'Status'}}</div>
    </div>
</lxm-tree>
