export enum ProductAllergenField {
  Allergen = 1,
  AllergenContentType = 2,
  SortOrder = 3
}

(ProductAllergenField as any).__typeName = 'ProductAllergenField';

(ProductAllergenField as any).__altTranslationKeys = {
  [ProductAllergenField.Allergen]: 'cards.products.product_certificate_info_and_nutrition_info.table.name',
  [ProductAllergenField.AllergenContentType]: 'cards.products.product_certificate_info_and_nutrition_info.table.contains'
};
