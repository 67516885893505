<div class="tab-content">
  <card-section>
    <span header-title>
      {{title | translate}}
    </span>
    <div header-actions *requiredRights="[UserAction.ManageClvs]">
      <div class="flex">
        <lxm-button type="beige"
                    class="ml15"
                    label="cards.settings.clv.common.action.add_classifier"
                    icon="plus-dark"
                    (click)="openClassificatorValueDialog({classificatorId: classificatorId})">
        </lxm-button>
        <!-- <lxm-button type="beige"
                    class="ml15"
                    label="cards.settings.clv.common.action.export_data"
                    icon="export">
        </lxm-button> -->
      </div>
    </div>
  </card-section>
</div>

<div class="clv-table">

  <div class="loading-container" *ngIf="dataSource.loading$ | async">
    <div class="loading">
      {{'global.table.loading' | translate}}
    </div>
  </div>

  <mat-table [dataSource]="dataSource" matSort [@.disabled]="true">

    <!-- General fields -->

    <ng-container matColumnDef="code">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header data-table-filter-text>
          {{ 'cards.settings.clv.common.table.code' | translate }}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let clv">{{clv.code | formValue}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="value">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header data-table-filter-text>
          {{ 'cards.settings.clv.common.table.value' | translate }}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let clv">{{clv.value | formValue}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header data-table-filter-text>
          {{ 'cards.settings.clv.common.table.description' | translate }}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let clv">{{clv.description | formValue}}</mat-cell>
    </ng-container>

    <!-- Product Brand Fields -->

    <ng-container matColumnDef="isPrivateLabel">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header data-table-filter-dropdown [valueType]="FilterValueType.Literal" [options]="trueFalse">
          {{translationPrefix + '.table.is_private_label' | translate}}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let clv">{{clv.isPrivateLabel | translateEnum : Bool}}</mat-cell>
    </ng-container>

    <!-- Code Format Fields -->

    <ng-container matColumnDef="typeId">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header 
             data-table-filter-dropdown 
             [options]="formData.unitCodeTypes" 
             [isTranslated]="true" 
             labelField="translatedValue" 
             value="id">
          {{translationPrefix + '.table.type' | translate}}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let clv">
        {{getCodeTypeName(clv)?.translatedValue[translate.currentLang] | formValue}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="codeFormat">
      <mat-header-cell *matHeaderCellDef>
        <div>
          {{translationPrefix + '.table.format' | translate}}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let clv">{{getCodeFormat(clv) | formValue}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="nextNumber">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header>
          {{translationPrefix + '.table.next_number' | translate}}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let clv">{{getNextNumber(clv) | formValue}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="hasChecksum">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header data-table-filter-dropdown [valueType]="FilterValueType.Literal" [options]="trueFalse">
          {{translationPrefix + '.table.has_checksum' | translate}}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let clv">{{clv.hasChecksum | translateEnum : Bool}}</mat-cell>
    </ng-container>

    <!-- Manufacturer fields -->

    <ng-container matColumnDef="manufacturerAddress">
      <mat-header-cell *matHeaderCellDef>
        <div>
          {{translationPrefix + '.table.address' | translate}}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let clv">{{ clv.address | address | formValue }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="manufacturerGln">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header data-table-filter-text>
          {{translationPrefix + '.table.gln' | translate}}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let clv">{{clv.gln | formValue}}</mat-cell>
    </ng-container>

    <!-- Logistics Partner fields -->
    <ng-container matColumnDef="logisticsPartnerAddress">
      <mat-header-cell *matHeaderCellDef>
        <div>
          {{translationPrefix + '.table.address' | translate}}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let clv">{{ clv.address | address | formValue }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="logisticsPartnerIsDefault">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header data-table-filter-dropdown [valueType]="FilterValueType.Literal" [options]="trueFalse">
          {{translationPrefix + '.table.is_default' | translate}}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let clv">{{clv.isDefault | translateEnum: Bool}}</mat-cell>
    </ng-container>
    
    <!-- Logistics Partner fields -->
    <ng-container matColumnDef="gs1Segments">
      <mat-header-cell *matHeaderCellDef>
        <div>
          {{translationPrefix + '.table.gs1_segments' | translate}}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let clv">
        <div class="comma-delimited-list">
          <div *ngFor="let segment of clv.gs1Segments | slice:0:3">
            {{ segment.code }} - {{ segment.value }}
          </div>
          <span *ngIf="clv?.gs1Segments?.length > 3" class="additional-count">(+{{clv.gs1Segments.length - 3}})</span>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Campaign Type fields -->

    <ng-container matColumnDef="valueType">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header data-table-filter-dropdown [options]="campaignTypeValueTypes">
          {{translationPrefix + '.table.value_type' | translate}}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let clv">{{clv.valueType | translateEnum : CampaignTypeValueType}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="numericValue">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header data-table-filter-text [valueType]="TextFilterValueType.Number">
          {{translationPrefix + '.table.numeric_value' | translate}}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let clv">{{clv.numericValue | formValue}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="vatType">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header data-table-filter-dropdown [options]="campaignTypeVatTypes">
          {{translationPrefix + '.table.vat_type' | translate}}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let clv">{{clv.vatType | translateEnum : CampaignTypeVatType}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="displayRetailPrice">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header data-table-filter-dropdown [valueType]="FilterValueType.Literal" [options]="trueFalse">
          {{translationPrefix + '.table.display_retail_price' | translate}}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let clv">{{clv.displayRetailPrice | translateEnum : Bool}}</mat-cell>
    </ng-container>


    <!-- General fields -->

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header data-table-filter-dropdown [options]="statuses">
          {{ 'cards.settings.clv.common.table.status' | translate }}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let clv">{{clv.status | translateEnum : Status}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="color">
      <mat-header-cell *matHeaderCellDef>
      </mat-header-cell>
      <mat-cell *matCellDef="let clv">
        <color-box [color]="clv.color" class="size-s"></color-box>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns" class="clv-table-head"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns" class="clv-table-row" (click)="onRowClick(row)"></mat-row>
  </mat-table>

  <data-table-footer 
    (paginator)="hookPaginator($event)">
  </data-table-footer>

</div>

