export enum PurchaseOrderStatus {
    Created = 1,
    Pending = 2,
    Negotiating = 4,
    Confirmed = 5,
    Rejected = 6,
    Cancelled = 7,
    Expired = 8,
    WaitingSupplierConfirm = 9,
    Completed = 10
}

(PurchaseOrderStatus as any).__typeName = 'PurchaseOrderStatus';