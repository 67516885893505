import { Component, Injector } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ProductCardBase } from '../product-card-base';
import { IProductCardMusicInfo, IClvDto } from '../product-info/product-info.model';
import { Observable } from 'rxjs';
import { asArray, IEnumValue, asObject, UserAction, ProductCardSection, ProductField , ValidType} from 'src/app/enum';
import { formUtil, keep } from 'src/app/util/form-util';
import { ProductValidity } from 'src/app/_services';
import { LOCAL_STORAGE_KEY } from 'src/app/config';

@Component({
  selector: 'product-music-card',
  templateUrl: './product-music.card.html',
  styleUrls: ['./product-music.card.scss']
})
export class ProductMusicCard extends ProductCardBase<IProductCardMusicInfo> {

  public UserAction = UserAction;
  public get title() { return 'cards.products.product_music_info.title'; }

  public cardSection = ProductCardSection.MusicInfo;

  public musicFormatOptions: IClvDto[];
  public musicGenreOptions: IClvDto[];

  public isExpandedKey = LOCAL_STORAGE_KEY.PRODUCT_MUSIC_CARD_OPEN;

  constructor(injector: Injector, public productValidity: ProductValidity) {
    super(injector, 'musicInfo', 'productMusicInfoChanged');
    this.musicFormatOptions = this._productCard.formData.musicFormats;
    this.musicGenreOptions = this._productCard.formData.musicGenres;
  }

  public get cardValidType(): ValidType {
    return this.productValidity.getCardStatusValidType(this.cardSection);
  }

  public get shouldExpandSections() {
    return this.productValidity.shouldValidate ? (
      (this.cardValidType === ValidType.Invalid || this.cardValidType === ValidType.Recommended) ? true : false
      ) : false;
  }


  protected _getNormalizedFormData(data: IProductCardMusicInfo) {
    return formUtil.transformData(data, {
      musicFormatId: () => data.musicFormat?.id,
      genres: val => val?.map(x => x.id),
      discAmount:keep,
      isOnParentAgreement:keep,
      year: keep,
      releaseYear: keep,
      publisher: keep,
      tracks:keep
    });
  }
  protected _createFormGroup(data: IProductCardMusicInfo): FormGroup {
    return formUtil.createFormGroup(this._getNormalizedFormData(data));
  }

  protected _createSaveRequest() {
    const data = this.form.value;
    return {
      musicFormatId:data.musicFormatId,
      genres:data.genres,
      discAmount:data.discAmount,
      isOnParentAgreement:data.isOnParentAgreement || false,
      year:data.year,
      releaseYear:data.releaseYear,
      publisher:data.publisher,
      tracks: data.tracks?.filter((x: any) => !x.isDeleted)
        .map((x: any) => ({
          id: x.id,
          trackNo: x.trackNo,
          title: x.title,
          authors: x.authors,
          duration:x.duration
        })),
    };
  }

  protected _saveInternal(req: any): Observable<any> {
    return this._productService.saveMusicInfo(this.productId, req);
  }

  protected get successMessage(): string {
    return 'cards.products.product_music_info.save_successful';
  }

}
