<lxm-dialog [dialogRef]="dialogRef" [formGroup]="form">
    <span header-title>
      {{ 'cards.offer.campaign_info.title' | translate }}
    </span>

    <div body style="padding-bottom: 40px;">

        <display-product class="dialog-display-product-sticky" [data]="product"> </display-product>

        <scope translationsPath="cards.offer.campaign_info">
          <form-field dialog label="netting">
            <slide-toggle toggle for="netting" [readonly]="isRetailer || readonly"></slide-toggle>
            <validation-errors for="netting"></validation-errors>
          </form-field>

          <form-field dialog label="addressed">
            <slide-toggle toggle for="addressed" [readonly]="isRetailer || readonly"></slide-toggle>
            <validation-errors for="addressed"></validation-errors>
          </form-field>

          <form-field dialog label="with_ad">
            <slide-toggle toggle for="withAd" [readonly]="readonly"></slide-toggle>
            <validation-errors for="withAd"></validation-errors>
          </form-field>

          <form-field dialog label="marketing_fee" for="marketingFee" type="input-text" [readonly]="readonly">
            <validation-errors for="campaignInfo.marketingFee" aliasTo="marketingFee"></validation-errors>
          </form-field>

          <form-field dialog label="invoice_recipient">
            <lxm-select select
                        labelField="value"
                        [searchable]="true"
                        for="invoiceRecipient"
                        url="api/clvac/manufacturers/"
                        [readonly]="isRetailer || readonly">
            </lxm-select>
            <validation-errors for="campaignInfo.invoiceRecipient" aliasTo="invoiceRecipient"></validation-errors>
          </form-field>

          <div class="separator"></div>

          <form-field dialog label="store_count" type="input-text" for="storeCount" class="w60p" [readonly]="readonly">
            <validation-errors for="campaignInfo.storeCount" aliasTo="storeCount"></validation-errors>
          </form-field>

          <form-field dialog label="display_size" type="input-text" for="displaySize" class="w60p" [readonly]="readonly">
            <validation-errors for="campaignInfo.displaySize" aliasTo="displaySize"></validation-errors>
          </form-field>

          <form-field dialog label="returnable_items">
            <lxm-select select [options]="returnableItemsOptions" for="returnableItems" value="id" [readonly]="isRetailer || readonly"></lxm-select>
            <validation-errors for="campaignInfo.returnableItems" aliasTo="returnableItems"></validation-errors>
          </form-field>

          <form-field dialog label="limit" type="input-text" for="limit" class="w60p" [readonly]="readonly">
            <validation-errors for="limit"></validation-errors>
          </form-field>

          <form-field dialog label="comments" type="textarea" class="w60p" for="comments" [readonly]="isRetailer || readonly">
            <validation-errors for="campaignInfo.comments" aliasTo="comments"></validation-errors>
          </form-field>

          <form-field dialog label="campaign_nr" type="input-text" for="campaignNr" class="w60p" [readonly]="readonly">
            <validation-errors for="campaignInfo.campaignNr" aliasTo="campaignNr"></validation-errors>
          </form-field>
          <!-- 

          <form-field label="campaign_nr">
            <lxm-select select
                        [options]="[{ name: '1' }]"
                        [searchable]="false"
                        for="campaignNr">
            </lxm-select>
            <validation-errors for="campaignNr"></validation-errors>
          </form-field>

          <form-field label="special_display">
            <slide-toggle toggle for="isSpecialDisplay"></slide-toggle>
            <validation-errors for="isSpecialDisplay"></validation-errors>
          </form-field>

          <form-field label="special_dispaly_place" *ngIf="form.value.isSpecialDisplay">
              <lxm-select select
                          [multiple]="true"
                          [options]="[{ name: 'Coop Maksimarket Lasnamäe' }, { name: 'Laagri Maksimarket' }]"
                          [searchable]="true"
                          for="specialDisplyPlaces">
              </lxm-select>
              <validation-errors for="specialDisplyPlaces"></validation-errors>
          </form-field>
           -->
        </scope>
    </div>

    <div footer>
      <lxm-button type="primary" label="action.save" [loading]="isSaveLoading" (click)="save()" buttonType="submit" *ngIf="!readonly"></lxm-button>
      <lxm-button type="secondary" [class.ml15]="!readonly" label="action.cancel" (click)="closeDialog()"></lxm-button>
    </div>
</lxm-dialog>
