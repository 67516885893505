import { Directive, Input, TemplateRef, ViewContainerRef, OnInit } from '@angular/core';
import { AuthenticationService } from '../_services';
import { UserAction } from '../enum';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[requiredRights]'
})
export class RequiredRightsDirective implements OnInit {

  private _requiredRights: UserAction[];

  @Input() set requiredRights(actions: UserAction[]) {
    this._requiredRights = actions;
  }

  @Input() public any = true;

  private _subscription: Subscription;

  constructor(
    private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef,
    private _authService: AuthenticationService) {
  }

  ngOnInit() {
    this._subscription = this._authService.jwt.subscribe(
      (jwt) => {

        this._viewContainer.clear();

        if (jwt) {
          if (this.any ? 
            this._authService.hasRight(this._requiredRights) : 
            this._authService.hasRights(this._requiredRights)
            ) {
            this._viewContainer.createEmbeddedView(this._templateRef);
          }
        }

      }
    );
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

}
