import { Injectable, OnInit } from "@angular/core";

@Injectable({ providedIn: "root" })
export class MarketProductsListState {

  public isSearchLoading = false;

  public isFullScreen = false;

  public zoom = 1;

}
