import { Component, OnInit, Host, ViewChild, ElementRef, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { debounceTime, map } from 'rxjs/operators';
import { IRetailerOption, IOfferCard, IOfferCardFormData, IOfferCardDetail, IUser, IOfferRecipient } from 'src/app/models';
import { LxmMessage, DateHandler, LxmDialog } from 'src/app/_helpers';
import { OfferService, AuthenticationService, SignalRService, TitleService } from 'src/app/_services';
import { OfferLayoutComponent } from 'src/app/views/offers/offer/offer.layout';
import { SupplierOfferStatus, UserAction, OfferKind, LxmAppModule, OfferType } from 'src/app/enum';
import { AppState } from 'src/app/state/app.state';
import { IClvDto } from '../product/product-info/product-info.model';
import { mergeMap } from 'rxjs/operators';

import { RouteNavigator } from 'src/app/_helpers/route.listener';
import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import { OfferExportDialogComponent } from './dialogs/export/export-offer.dialog';
import { forkJoin, merge, Subscription } from 'rxjs';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { MessageComponent } from 'src/app/components/_layout/messages/message/message.component';
import { appSettings } from 'src/app/app.settings';
import { BindSupplierDialogComponent } from './dialogs/bind-supplier/bind-supplier.dialog';
import { SuppliersService } from 'src/app/_services/suppliers.service';
import { nullIfEmptyString, stringIsNullOrEmpty } from 'src/app/util/fn';
import { PipedriveService } from 'src/app/_services/pipedrive.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslatedValuePipe } from 'src/app/pipes';
import { PurchaseOrderDataService } from 'src/app/_services/orders.service';
import { ORDERABLE_OFFER_TYPES } from 'src/app/config';
import { CalendarGroupCampaignType } from 'src/app/enum/calendar-group-campaign-type';

@Component({
  selector: 'receiving-offer-card',
  templateUrl: './receiving-offer.card.html',
  styleUrls: ['./offer.card.scss'],
  providers: [PipedriveService, TranslatedValuePipe]
})
export class ReceivingOfferCardComponent implements OnInit, OnDestroy {

  private _signalRSubscription: Subscription;
  private _msgRef: MatSnackBarRef<MessageComponent>;

  @ViewChild('download') private _download: ElementRef;

  @Input() public isEditable: boolean;

  public translationsPath = 'cards.offer.base_info';
  public OfferStatus = SupplierOfferStatus;
  public UserAction = UserAction;
  public OfferType = OfferType;

  public offerId: string;

  public productInstructions: string[];

  public form: FormGroup;
  public edit = false;

  private _offerCard: IOfferCard;
  private _formData: IOfferCardFormData;
  private _snapshot: any;

  public retailers: IRetailerOption[];
  public offerTypes: IClvDto[];
  public period: string;
  public currency: IClvDto;

  public offer: IOfferCardDetail;

  public sendingOffer = false;
  public saveLoading = false;
  public updatingPrices = false;
  public acceptingOffer = false;

  public canOrder = false;

  public recipientsOptions: IOfferRecipient[] = [];

  private _hubConnection: HubConnection;

  public ownerOptions: IUser[] = [];

  public get hasAutomaticConfirmation() {
    const retailer = this.retailers?.find(x => x.id == this.appState?.contentTenantId);
    return retailer?.hasAutomaticOfferConfirmation || false;
  }

  constructor(
    @Host() private _page: OfferLayoutComponent,
    private _router: Router,
    private _route: ActivatedRoute,
    private _routeNavigator: RouteNavigator,
    private _dateHandler: DateHandler,
    private _offerService: OfferService,
    private _dialog: LxmDialog,
    private _message: LxmMessage,
    public appState: AppState,
    authService: AuthenticationService,
    private _signalRService: SignalRService,
    private _suppliersService: SuppliersService,
    private _pipedriveService: PipedriveService,
    private _translateService: TranslateService,
    private _translatedValuePipe: TranslatedValuePipe,
    private _createPurchaseOrderDataService: PurchaseOrderDataService,
    private _titleService: TitleService
  ) {
    this._offerCard = _route.snapshot.data.offerCard as IOfferCard;

    this._formData = this._offerCard?.formData || _route.snapshot.data.formData;
    this.retailers = this._formData.retailers;
    this.offerTypes = this._formData.offerTypes;
    this.ownerOptions = this._formData.ownerOptions;
    this.offer = this._offerCard?.offerDetail;
    this.currency = this.offer.currency;
    this.canOrder = this.offer?.canOrder;

    if (this.offer) {
      this.offerId = this.offer.id;
      this._signalRSubscription = this._signalRService.commonHub
        .subscribe(connection => {
          this._hubConnection = connection;
          if (this._signalRService.isHubConnected(connection)) {
            if (this._offerCard?.offerKind === OfferKind.SupplierOffer) {
              connection.send('viewSupplierOffer', this.offer.id);
            } else if (this._offerCard?.offerKind === OfferKind.ReceivingOffer) {
              connection.send('viewReceivingOffer', this.offer.id);
            }

            connection.on('statusChanged', (data: any) => {
              this.offer.status = data.status;
            });

            connection.on('offerHasChanged', data => {
              if (this.offerId === data.offerId && data.read === false) {

                this._msgRef = this._message.ok({
                  message: 'cards.offer.base_info.offer_changed',
                  indefinite: true,
                  buttonText: 'action.reload_page',
                  buttonIcon: 'generate',
                  buttonAction: () => {
                    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
                    this._router.onSameUrlNavigation = 'reload';
                    this._router.navigate(['/offers', this.offerId]);
                    this._msgRef.dismiss();
                  }
                });

              }
            });
          }
        });
    }

    this.form = new FormGroup({
      offerOwner: new FormControl(this._offerCard?.owner),
      products: new FormControl(this._offerCard?.products || []),
      canOrder: new FormControl(this.canOrder)
    });

    if (this.procurement) {
      this._setProcurementControls(this.procurement, this._offerCard?.products);
    }

    if (!this.isKnownSupplier) {
      this.openBindSupplierDialog();
    }

  }

  public get isKnownSupplier() {
    return this._offerCard.isKnownSupplier;
  }

  public get hasBuyerCrm() {
    return this.appState.hasModule(LxmAppModule.BuyerCrm);
  }

  public get campaignType() {
    return this.offer?.calendarCampaign?.campaignType ?? CalendarGroupCampaignType.BuyInPriceDiscount;
  }

  public openBindSupplierDialog() {

    if (!this.hasBuyerCrm) {
      return;
    }

    const id = this.offer.supplier?.id;
    const name = this.offer.supplier?.name;

    this._dialog.open(BindSupplierDialogComponent, {
      ...appSettings.DIALOG_SIZES.M,
      data: {
        lxmSupplierId: id,
        lxmSupplierName: name
      },
      resolve: () => {
        const resolvers: any = {
          match: this._suppliersService.match(id)
        };

        return forkJoin(resolvers);
      }
    }, res => {

      if (res === null || res === undefined) {
        return
      }

      this._offerCard.isKnownSupplier = true;

    });
  }

  private _procurementProductsMapper: Subscription;
  public procurementProductsFormGroup: FormGroup = new FormGroup({});

  private _setProcurementControls(procurement, products?) {
    if (this._procurementProductsMapper) {
      this._procurementProductsMapper.unsubscribe();
    }
    const fg = new FormGroup({});
    for (let i = 0; i < procurement.items.length; i++) {
      const procurementItemId = procurement.items[i].id;
      const controlProducts = products?.length ? products.filter(x => x.procurementItemId === procurementItemId) : [];
      fg.addControl(procurementItemId, new FormControl(controlProducts));
    }

    this._procurementProductsMapper = fg.valueChanges.subscribe(val => {
      const mappedProducts = [].concat.apply([], Object.keys(val).map(x => {
        let products = val[x];
        for (let product of products) {
          product.procurementItemId = x;
        }
        return products;
      }));

      this.form.get('products').setValue(mappedProducts, { emitEvent: false });
    })

    this.procurementProductsFormGroup = fg;
  }

  ngOnInit() {
    if (!this.offerId) {
      this.edit = true;
    }
    this._snapshot = this.form.value;

    this._pipedriveService.appendLeadbooster("942475e9-c555-4ddd-af33-30f2687732c7");

    this._setOfferTitle();
  }

  private _setOfferTitle() {
    const { supplier } = this.offer;
    if (this.offerTitle) {
      const supplierShortName = supplier?.shortName;
      const title = supplierShortName ? supplierShortName + ' - ' + this.offerTitle : this.offerTitle;
      this._titleService.setTitle(title);
    }
  }

  public backToList() {
    this._routeNavigator.toPreviousRoute({ fallback: '/offers' });
  }

  public onOwnerChange($event) {
    this._offerService.setOwner(this.offerId, $event.id)
      .subscribe(res => {
        this._message.ok({ message: 'cards.offer.base_info.owner_set_successfully' });
      }, err => {
        this._message.error({ message: 'cards.offer.base_info.setting_owner_failed' });
      });
  }

  public exportXls() {
    this._dialog.open(OfferExportDialogComponent, {
      resolve: () =>
        this._offerService.exportXls(this.offerId)
          .pipe(
            mergeMap(async res => {
              const blob = await this._offerService.downloadXls(res.id).toPromise();
              return {
                fileName: res.fileName,
                blob: blob
              }
            })
          )
          .pipe(
            map(res => {
              const url = window.URL.createObjectURL(res.blob);

              const link = this._download.nativeElement;
              link.href = url;
              link.download = res.fileName;
              link.click();

              window.URL.revokeObjectURL(url);
            })
          )
    });
  }

  public exportEdiPricelist() {
    this._dialog.open(OfferExportDialogComponent, {
      resolve: () =>
        this._offerService.exportEdiPricelist(this.offerId)
          .pipe(
            mergeMap(async res => {
              const blob = await this._offerService.downloadXml(res.id).toPromise();
              return {
                fileName: res.fileName,
                blob: blob
              }
            })
          )
          .pipe(
            map(res => {
              const url = window.URL.createObjectURL(res.blob);

              const link = this._download.nativeElement;
              link.href = url;
              link.download = res.fileName;
              link.click();

              window.URL.revokeObjectURL(url);
            })
          )
    });
  }

  public get isOfferTypeOrderable() {
    return ORDERABLE_OFFER_TYPES.includes(this.offerTypeId);
  }

  public get canCreatePurchaseOrder() {
    if (!this.isOfferTypeOrderable || this.offer.status !== this.OfferStatus.Confirmed) {
      return false;
    }
    return this.canOrder && this.appState.hasRight([UserAction.ManagePurchaseOrders]);
  }

  public get canDownloadEdiPricelist() {
    return this.offerTypeId == OfferType.Procurement
      && this.offer.status == this.OfferStatus.Confirmed
      && this.appState.hasModule(LxmAppModule.EdiPricat);
  }

  public get offerType() {
    return this.offer.offerType;
  }

  public get offerTypeId(): any {
    return this.offerType?.id;
  }

  public get procurement() {
    return this.offer?.procurement;
  }

  public get isProcurement() {
    return this.offerTypeId === OfferType.Procurement;
  }

  public get procurementDescription(): string {
    if (this.isProcurement) {
      return this._translatedValuePipe.transform(this.procurement?.description);
    }
    return '-';
  }

  public get offerTitle() {

    const offerName = this.offer?.name;

    if (offerName) return offerName;

    if (!this.offerId) {
      return this.translationsPath + '.title_offer';
    } else {
      return this.translationsPath + '.title_edit_offer';
    }
  }

  public get showOfferSupplyPeriodField() {
    return this.isProcurement && (this.procurement?.supplyFrom || this.procurement?.supplyTo);
  }

  public get isOwner() {
    return this.form.value.offerOwner?.id == this.appState.user.id;
  }

  public get canEdit() {
    return this.isEditable
      && ((this.appState.hasRight([UserAction.ManageReceivedOffers]) && this.isOwner) || this.appState.hasRight([UserAction.EditOthersReceivedOffers]));
  }

  public toggleEdit() {
    if (this.edit) {
      this._revertToInitialData();
    }
    this.edit = !this.edit;
  }

  public getOfferStatusProgress(status: SupplierOfferStatus) {
    return this._offerService.getSendingOfferStatusProgress(status);
  }

  public save() {
    this.saveLoading = true;
    const f = this.form.value;

    const data: any = {
      products: f.products
        .map(x => ({
          id: x.id,
          requestedPrice: x.requestedPrice,
          retailPrice: x.retailPrice,
          retailPriceWithoutVat: x.retailPriceWithoutVat,
          status: x.status,
          priceChangeDate: x.priceChangeDate,
          retailerDetails: {
            fullName: x.retailerDetails?.fullName
              ? Object.assign({}, ...x.retailerDetails.fullName.filter(x => !stringIsNullOrEmpty(x.value)).map(n => ({ [n.language]: n.value })))
              : {},
            shortName: x.retailerDetails?.shortName
              ? Object.assign({}, ...x.retailerDetails.shortName.filter(x => !stringIsNullOrEmpty(x.value)).map(n => ({ [n.language]: n.value })))
              : {},
            brandId: x.retailerDetails?.brand?.id,
            subBrandId: x.retailerDetails?.subBrand?.id,
            model: x.retailerDetails?.model,
            countryOfOriginId: x.retailerDetails?.countryOfOrigin?.id,
            markingPriceRequired: x.retailerDetails?.markingPriceRequired,
            description: x.retailerDetails?.description
              ? Object.assign({}, ...x.retailerDetails?.description.filter(x => !stringIsNullOrEmpty(x.value)).map(n => ({ [n.language]: n.value })))
              : {},
            segmentId: x.retailerDetails?.segment?.id,
            parameter1: nullIfEmptyString(x.retailerDetails?.parameter1),
            parameter2: nullIfEmptyString(x.retailerDetails?.parameter2),
            parameter3: nullIfEmptyString(x.retailerDetails?.parameter3),
            parameter4: nullIfEmptyString(x.retailerDetails?.parameter4),
            parameter5: nullIfEmptyString(x.retailerDetails?.parameter5),
            parameter6: nullIfEmptyString(x.retailerDetails?.parameter6),
            parameter7: nullIfEmptyString(x.retailerDetails?.parameter7),
            parameter8: nullIfEmptyString(x.retailerDetails?.parameter8),
            parameter9: nullIfEmptyString(x.retailerDetails?.parameter9),
            parameter10: nullIfEmptyString(x.retailerDetails?.parameter10),
            expiryInDays: x.retailerDetails?.expiryInDays,
            minExpiryOnDelivery: x.retailerDetails?.minExpiryOnDelivery,
            expiryForTargetInDays: x.retailerDetails?.expiryForTargetInDays,
            tags: x.retailerDetails?.tags?.map(x => x.id),
            attributes: x.retailerDetails?.attributes?.map(x => x.id),
            codes: x.retailerDetails?.codes
              ?.map(x => ({
                id: x.id,
                isPrimary: x.isPrimary,
                typeId: x.type?.id,
                formatId: x.format?.id,
                value: x.value
              })),
            orderUnitId: x.retailerDetails?.orderUnit?.id,
            salesUnitId: x.retailerDetails?.salesUnit?.id,
            contentUnitId: x.retailerDetails?.contentUnit?.id,
            netContent: x.retailerDetails?.netContent,
            averageWeightInGrams: x.retailerDetails?.averageWeightInGrams,
            drainedWeightInGrams: x.retailerDetails?.drainedWeightInGrams
          },
          campaignInfo: x.campaignInfo
            ? {
              limit: x.campaignInfo.limit,
              netting: x.campaignInfo.netting || false,
              addressed: x.campaignInfo.addressed || false,
              withAd: x.campaignInfo.withAd || false,
              marketingFee: x.campaignInfo.marketingFee,
              invoiceRecipientId: x.campaignInfo.invoiceRecipient?.id,
              storeCount: x.campaignInfo.storeCount,
              displaySize: x.campaignInfo.displaySize,
              returnableItems: x.campaignInfo.returnableItems,
              comments: x.campaignInfo.comments,
              campaignNr: x.campaignInfo.campaignNr,
              campaignPriceValidFrom: x.campaignPriceValidFrom
            }
            : null
        }))
    };

    this._offerService.saveReceivingOffer(this.offerId, data)
      .result(this.form, id => {
        this._message.ok({
          message: 'common.message.offer_saved_successfully'
        });

        this._saveInitialData();
        this.edit = false;
        this.saveLoading = false;

        if (!this.offerId) {
          this._router.navigate(['/offers', id], { replaceUrl: true });
        }
      }, error => {
        this.saveLoading = false;
      });

  }

  public send() {

    if (!this.isKnownSupplier) {
      this.openBindSupplierDialog();
      return;
    }

    this._dialog.confirm(
      {
        image: 'send-proposals-modal-image',
        template: `
        <p class="dialog-title mt5 mb0">
          ${this._translateService.instant('cards.offers.offer_dialogs.receiving_offer_send.text')}
        </p>`
      },
      (ref) => {

        this.sendingOffer = true;

        this._offerService.sendOfferProposals(this.offerId)
          .result(null, res => {

            this.sendingOffer = false;

            this._message.ok({
              message: 'cards.offer.base_info.send_successful'
            });

            ref.close();
          }, (error) => {
            this._message.error({
              message: 'cards.offer.base_info.send_failed'
            });
            this.sendingOffer = false;
          });
      }
    );
  }

  public downloadImages() {
    this._dialog.open(OfferExportDialogComponent, {
      resolve: () =>
        this._offerService.exportProductImages(this.offerId)
          .pipe(
            mergeMap(async res => {
              const blob = await this._offerService.downloadProductImages(res.id).toPromise();
              return {
                fileName: res.fileName,
                blob: blob
              }
            })
          )
          .pipe(
            map(res => {
              const url = window.URL.createObjectURL(res.blob);

              const link = this._download.nativeElement;
              link.href = url;
              link.download = res.fileName;
              link.click();

              window.URL.revokeObjectURL(url);
            })
          )
    });
  }

  private _revertToInitialData() {
    this.form.setValue(this._snapshot);
  }

  private _saveInitialData() {
    this._snapshot = this.form.value;
  }

  public accept() {


    if (!this.isKnownSupplier) {
      this.openBindSupplierDialog();
      return;
    }

    let confirmOptions = {
      image: 'confirm-offer-retailer-image',
      yes: 'cards.offers.offer_dialogs.receiving_offer_accept.action.confirm',
      no: 'cards.offers.offer_dialogs.receiving_offer_accept.action.cancel',
      template: `
      <p class="dialog-title mt5">${this._translateService.instant('cards.offers.offer_dialogs.receiving_offer_accept.text')}</p> 
      <p>
        <span class="bold">
          ${this._translateService.instant('global.attention_uppercase')}
        </span>
        <span>
          ${this._translateService.instant('cards.offers.offer_dialogs.receiving_offer_accept.attention_text')}
        </span>
      </p>
      `
    };

    this._dialog.confirm(
      confirmOptions,
      (ref) => {

        this.acceptingOffer = true;

        this._offerService.acceptReceivingOffer(this.offerId)
          .result(null, res => {

            this.acceptingOffer = false;

            this._message.ok({
              message: 'cards.offer.base_info.accept_successful'
            });

            ref.close();
          }, (error) => {
            this._message.error({
              message: 'cards.offer.base_info.accepting_failed'
            });
            this.acceptingOffer = false;
          });
      }
    );
  }

  public reject() {
    this._dialog.confirm({
      image: 'reject-offer-modal-image',
      yes: 'cards.offers.offer_dialogs.reject_offer.action.confirm',
      no: 'cards.offers.offer_dialogs.reject_offer.action.cancel',
      template: `
      <p class="dialog-title mt5">${this._translateService.instant('cards.offers.offer_dialogs.reject_offer.text')}</p> 
      <p>
        <span class="bold">
          ${this._translateService.instant('global.attention_uppercase')}
        </span>
        <span>
          ${this._translateService.instant('cards.offers.offer_dialogs.reject_offer.attention_text')}
        </span>
      </p>
      `

    }, () => {
      this._offerService.rejectOffer(this.offerId)
        .result(this.form, () => {
          this._message.ok({
            message: 'cards.offers.offer_dialogs.reject_offer.offer_rejected'
          });
        });
    });
  }

  public createPurchaseOrder() {
    this._createPurchaseOrderDataService.offerId = this.offerId;
    this._router.navigate(['/orders/purchase/new']);
  }

  public ngOnDestroy() {

    this._pipedriveService.removeLeadbooster();

    if (this._hubConnection) {
      if (this.offerId && this._signalRService.isHubConnected(this._hubConnection)) {
        this._hubConnection.send('leaveOffer', this.offerId);
        this._hubConnection.off('statusChanged');
        this._hubConnection.off('offerHasChanged');
      }
    }

    if (this._signalRSubscription) {
      this._signalRSubscription.unsubscribe();
    }

    if (this._msgRef) {
      this._msgRef.dismiss();
    }
  }
}
