import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { FieldRequiredType } from "src/app/enum";

@Component({
  selector: "section-expanding",
  templateUrl: "./section-expanding.component.html",
  styleUrls: ['./section-expanding.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SectionExpandingComponent {

  @Input() public expanded = true;
  @Input() public hideWhenExpanded = false;
  @Input() public headerFullWidth = false;
  @Input() public required: FieldRequiredType = FieldRequiredType.None;
  @Input() public speed = 250;

  public toggleExpand() {
    this.expanded = !this.expanded;
  }

}
