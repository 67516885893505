import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: "search",
  templateUrl: "./search.component.html",
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {

  @ViewChild('searchInput') searchInput: ElementRef;

  @Input() public formGroup: FormGroup;
  @Input() public placeholder: string;

  @Input() public isOpen = false;
  @Input() public isLoading = false;

  @Input() public showPanel = true;
  @Input() public showBookmarks = false;
  @Input() public showSaveButton = false; // TODO

  @Input() public resetParams = null;

  @Output() public onSubmit = new EventEmitter();
  @Output() public onToggle = new EventEmitter();

  public onSearch() {
    this.onSubmit.emit();
  }

  public togglePanel(toggle = !this.isOpen) {
    this.isOpen = toggle;
    this.onToggle.emit(toggle);
  }

  public resetFields() {
    this.formGroup.reset(this.resetParams || {});
  }

  public get isInputFocused() {
    // console.log(this.searchInput);
    return document.activeElement === this.searchInput?.nativeElement;
  }

}
