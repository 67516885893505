import { Component, OnInit, Input, Optional, Host, SimpleChanges, ChangeDetectorRef, EventEmitter, Output, HostBinding, HostListener } from '@angular/core';
import { TareControlBuilder } from 'src/app/_helpers/control.builder';
import { ViewEncapsulation } from '@angular/core';
import { FormGroup, ControlContainer, FormArray, AbstractControl, FormControl } from '@angular/forms';
import { ReadonlyDirective } from 'src/app/directives';
import { numberUtil } from 'src/app/util/number-util';
import { AppState } from 'src/app/state/app.state';
import { IClvDto, IPriceGroup } from 'src/app/cards/product/product-info/product-info.model';
import { PriceTableBase } from '../price-table.base';

@Component({
  selector: "price-group-table",
  templateUrl: './price-group-table.component.html',
  styleUrls: ['./price-group-table.component.scss', '../shared.scss']
})
export class PriceGroupTableComponent extends PriceTableBase {

  @Input() public priceGroupOptions = [];
  public availablePriceGroupOptions: IPriceGroup[];

  constructor(
    appState: AppState,
     _cd: ChangeDetectorRef,
    @Optional() _controlContainer: ControlContainer
  ) { 
    super(appState, _cd, _controlContainer)
  }

  public get availablePriceGroups() {
    const existingPriceGroupIds = this.formArray?.value?.map(x => x.priceGroup?.id) ?? [];
    return this.priceGroupOptions?.filter(x => !existingPriceGroupIds.includes(x.id));
  }

  public get allRetailPrices() {
    const retailPrices = [];
    this.formArray.value.forEach(row => {
      row.retailPrices.forEach(retailPrice => {
        retailPrices.push(retailPrice);
      });
    });

    return retailPrices || [];
  }

  public updateRows() {
    const currencyIds = this.currencyIds;

    this.formArray.controls.forEach((row: any) => {

      const allRowRetailPrices = row.value.retailPrices;

      allRowRetailPrices.forEach((retailPrice, index) => {
        // Currency removed
        const hasCurrency = currencyIds.includes(retailPrice.currency?.id);
        if (!hasCurrency) {
          row.controls.retailPrices.removeAt(index);
        }
      });

      // ADD NEW CURRENCIES
      this.currencies.forEach(currency => {
        const hasCurrency = allRowRetailPrices.find(
          x => x.currency?.id === currency?.id
        );
        if (!hasCurrency) {
          row.controls.retailPrices.insert(row.controls.retailPrices.length, this.createRetailPriceFormGroup({currency: currency}))
        }
      });

    })
  }

  public createRow(data: any) {
    const { priceGroup, retailPrices } = data || {};
    const formGroup = new FormGroup({
      priceGroup: new FormControl(priceGroup),
      retailPrices: new FormArray(retailPrices?.length ? retailPrices?.map(x => this.createRetailPriceFormGroup(x)) : [this.createRetailPriceFormGroup({currency: this.defaultCurrency})]) 
    });

    this.calcMargin(formGroup);
    return formGroup;
  }

  public getSetRefValueFn(rows) {
    return rows.map(x => {
      const { priceGroup, retailPrices} = x;
      return {
        priceGroupId: priceGroup?.id,
        retailPrices: retailPrices
      }
    })
  }

  public addPriceGroup(x = { currency: this.defaultCurrency }) {
    const firstAvailablePriceGroup = this.availablePriceGroups?.[0];

    const data = {
      priceGroup: firstAvailablePriceGroup,
      retailPrices: [this.createRetailPriceFormGroup(x)]
    };

    const newPriceGroup = this.createRow(data);

    this.formArray.insert(this.formArray.length, newPriceGroup);
    this.availablePriceGroupOptions = this.availablePriceGroups;
    this.updateRows();
  }

  public onPriceGroupOptionChange(val, groupIndex: number) {
    if (val === undefined) {
      this.formArray.removeAt(groupIndex);
    }
    this.availablePriceGroupOptions = this.availablePriceGroups;
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    this.availablePriceGroupOptions = this.availablePriceGroups;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.availablePriceGroupOptions = this.availablePriceGroups;
  }

  // protected bindCalculations(): void {
  //   this.calcMargin();
  //   this.availablePriceGroupOptions = this.availablePriceGroups;
  // }

}

