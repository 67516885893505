export enum ProductUnitPalletType {
    Eur_1_1 = <any>"e460b375-67fa-49a3-b15d-206af66ce5cd",
    Eur_1_2 = <any>"519497f8-70a2-4bc4-9244-417423bfb2bb",
    Eur_1_3 = <any>"8978db75-fa43-48c3-a440-1048943d31a8",
    Eur_1_4 = <any>"f81e5786-8f9a-4568-bcce-281e4d86e7c0",
    Eur_1_8 = <any>"137d4f31-7ee7-47ca-a099-69c3cc41f305",
    Epal7_1_2 = <any>"fed5a2ee-13c3-44f0-936e-94679257bf35",
    Fin_1_1 = <any>"c05d253e-4e57-4ccc-9e7d-871660546d48",
    Eur2_1_1 = <any>"a324b803-3a5d-4786-a58a-64aeb610ea26",
    Eur2_1_2 = <any>"def7c77f-dc27-46ee-bb6a-7c850bedab03",
    Eur3_1_1 = <any>"267a0e13-9d35-4700-9ae0-67a22c4028b7",
    Iso3_1_1 = <any>"e44e999f-4f6b-4f63-9769-7316e6437696",
    Iso4_1_1 = <any>"e9762907-edae-4aa8-9b2e-d0ddfd9a9b20",
    Iso5_1_1 = <any>"33b1c9c6-82f6-4da8-9aae-768a31e0e307",
    Iso6_1_1 = <any>"7c125f28-b0a6-4ff0-b9c6-6fd69396a7e8",
    Iso3_1_2 = <any>"1ac30966-e9f9-4e6b-86fc-0e102b79ddb9",
    Iso3_1_4 = <any>"4b2de60b-e4a1-41b8-8ed5-8853c6cf9f13",
    AuPallet = <any>"25e979a3-58bc-4158-ba01-9ad2e76896cc",
    Custom = <any>"c36891ae-f60b-4942-a511-42145767c918",
    Unspecified = <any>"e42273c4-5211-4fb0-8505-b824c4060257",
    Block = <any>"edc7036a-24ed-4e28-898b-8d4be713f49e",
    Dolly = <any>"22f5c7f8-e826-46f2-b0a3-6a57bf1a5f12",
    HorizontalDrum = <any>"c74f609e-e6bc-404a-8baf-0ec02ce2b1a9",
    Ibc = <any>"f157b659-49c2-4257-88bf-a1a40d592307",
    Skid = <any>"2d7c9375-a507-4e2f-822c-3b243b7f5991",
    Stringer = <any>"8a4123f9-91ef-4b42-b26a-0e9e7847839e",
    VerticalDrum = <any>"92198888-3fef-4b03-a8e1-b235aef20fd0",
    SlipSheet = <any>"81a82bde-2a8d-411c-b757-3c0bbfe8afa7"
}

(ProductUnitPalletType as any).__typeName = "ProductUnitPalletType";