import { Component, Input } from "@angular/core";
import { AppState } from "src/app/state/app.state";

@Component({
  selector: "user",
  templateUrl: "./user.component.html",
  styleUrls: ['./user.component.scss']
})
export class UserComponent {

  @Input() public name: string;
  @Input() public date: Date;

  constructor(private _state: AppState) {}

  ngOnInit() {
    if (this.name === 'self') {
      this.name = this._state.user?.name || this._state.user?.email;
    }
  }

}
