<div class="image-box-image {{imageFit}}" (click)="open(index)" [title]="caption" [class.fallback]="_isFallbackImage">
      <img draggable="false" [src]="src" *ngIf="showImage">
      <ng-content></ng-content>
</div>
<div *ngIf="!readonly" class="image-box-actions" [class.options-open]="showOptionsList" (click)="toggleOptionsList()">
      <span class="edit-icon"></span>
      <div class="image-box-actions__options">
            <ul>
                  <li (click)="handleDelete()">
                        <span class="trash-icon mr10"></span>
                        <span>{{'components.image_box.delete_image' | translate}}</span>
                  </li>
                  <li (click)="downloadImage(src)">
                        <span class="download-icon mr10"></span>
                        <span>{{'components.image_box.download_image' | translate}}</span>
                  </li>
                  <ng-content select="[options]"></ng-content>
            </ul>
      </div>
</div>

<lxm-overlay *ngIf="showOptionsList" name="image-box-overlay" (click)="toggleOptionsList(false)"></lxm-overlay>