import { Component, Input, AfterContentInit, HostBinding } from "@angular/core";
import { NavigatorState } from '../../../../state/navigator.state';

@Component({
  selector: "view-spinner",
  templateUrl: "./view-spinner.component.html",
  styleUrls: ['./view-spinner.scss']
})
export class ViewSpinnerComponent implements AfterContentInit {

  constructor(public navigator: NavigatorState) { }

  @Input() public listen = false;
  public loading = true;

  @HostBinding('class.navigator-collapsed') public get navigatorCollapsed() {
    return this.navigator.collapsed;
  }

  @HostBinding('class.hidden') public get isLoading() {
    return !(this.loading || this.listen);
  }

  public setLoadEnd() {
    this.loading = false;
  }

  public ngAfterContentInit() {
    setTimeout(_ => this.setLoadEnd(), 200);
  }

}
