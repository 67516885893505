import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'file-drop-box',
  templateUrl: './file-drop-box.component.html',
  styleUrls: ['./file-drop-box.component.scss']
})
export class FileDropBoxComponent {

  @Input() public files: any[] = [];
  @Input() public uploadInProgress = false;
  @Input() public uploadingFile = false;
  @Input() public multiple = false;
  @Input() public indeterminate = false;
  @Input() public accept: string;
  @Input() public addFileText: string;
  @Input() public showAddFileCondition: boolean;

  @Output() public onChange = new EventEmitter<FileList>();
  @Output() public onDelete = new EventEmitter<number>();

  public imageUrl: string;

  public get progressMode() {
    return this.indeterminate ? 'indeterminate' : 'determinate';
  }

  public emitFiles(fileList: FileList) {
    this.onChange.emit(fileList);
  }

  public deleteFile(index: number) {
    this.onDelete.emit(index);
  }

}
