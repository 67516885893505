import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LxmAppModule, UserAction } from '../enum';
import moment from 'moment-timezone';
import { environment } from 'src/environments/environment';
import { IUserSettings } from '../models';
import { Guid } from '../util/Guid';
import { HttpBadRequestResponse } from '../_helpers';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  private readonly _jwt: BehaviorSubject<IToken>;
  private readonly _tokenString: BehaviorSubject<string>;
  private readonly _currentTenantId: BehaviorSubject<string>;
  private readonly _tenantTimeZone: BehaviorSubject<string>;

  private _lastError: HttpBadRequestResponse;

  public shouldLogOutSubscription: Subscription;

  public get lastError() {
    const tmp = this._lastError;
    this._lastError = undefined;
    return tmp;
  }

  constructor(private _http: HttpClient, private _router: Router) {
    this._tokenString = new BehaviorSubject<string>(null);
    this._currentTenantId = new BehaviorSubject<string>(null);
    this._tenantTimeZone = new BehaviorSubject<string>(null);
    this._jwt = new BehaviorSubject<IToken>(null);

    this._tokenString.next(localStorage.getItem('jwt'));
    this._tokenString.subscribe(token => {
      localStorage.setItem('jwt', token);

      const jwt = token ? this._parseJwt(token) : null;
      this._jwt.next(jwt);

      const tenantTimeZone = jwt ? jwt.TenantTimeZoneId : undefined;

      const currentClientId = jwt ? jwt.CurrentTenantId : null;
      this._currentTenantId.next(currentClientId);
      moment.tz.setDefault(tenantTimeZone);
      this._tenantTimeZone.next(tenantTimeZone);
    });

    let t: any = 0;

    this._jwt.subscribe(jwt => {

      clearTimeout(t);

      if (!jwt) {
        return;
      }

      // one minute before expiration
      const ms = (jwt.exp - 60) * 1000;
      const sessionEndNotificationTimeInMilliseconds = ms - new Date().getTime();

      t = setTimeout(
        function () { 
          // publish notification
        },
        sessionEndNotificationTimeInMilliseconds
      );
    });
  }

  public hasRight(actions: UserAction[]): boolean {
    if (!this.jwt?.value?.Actions) {
      return false;
    }

    if (!Array.isArray(this.jwt.value.Actions)) {
      return false;
    }

    return actions.some(x => this.jwt.value.Actions.indexOf(x) >= 0);
  }

  public hasRights(actions: UserAction[]): boolean {
    if (!this.jwt?.value?.Actions) {
      return false;
    }

    if (!Array.isArray(this.jwt.value.Actions)) {
      return false;
    }

    return actions.every(x => this.jwt.value.Actions.indexOf(x) >= 0);
  }

  public hasModule(module: LxmAppModule): boolean {
    if (!this.jwt?.value?.Modules) {
      return false;
    }

    return this.jwt.value.Modules.indexOf(module) >= 0;
  }

  public isUserOwner(userId: string) {
    if (!userId) return false;
    const jwt = this.jwt.value;
    const nameid = jwt?.nameid;
    if (!nameid) return false;
    return nameid === userId;
  }

  public get tokenValue(): string {
    return this._tokenString.value;
  }

  public get currentTenantId(): BehaviorSubject<string> {
    return this._currentTenantId;
  }

  public get tenantTimeZone(): BehaviorSubject<string> {
    return this._tenantTimeZone;
  }

  public get jwt(): BehaviorSubject<IToken> {
    return this._jwt;
  }

  public loginWithPassword(username: string, password: string) {
    const svc = this;
    const sessionId = Guid.newGuid().toString();
    return this._http.post<string>(`api/auth/password/${sessionId}`, { username, password })
      .pipe(
        switchMap(async res => {
          svc._tokenString.next(res);
          await this._setAuthCookie(sessionId);
          return res;
        })
      );
  }

  public initMobileIdAuth(phoneNumber: string) {
    return this._http.post<any>('api/mobileId/initAuth', { phoneNumber });
  }

  public loginWithMobileId(sessionId: any) {
    const svc = this;
    return this._http.get<any>(`api/mobileId/authStatus/${sessionId}`)
      .pipe(
        switchMap(async res => {
          svc._tokenString.next(res);
          await this._setAuthCookie(sessionId);
          return res;
        })
      );
  }

  private async _setAuthCookie(sessionId: string) {
    await this._http.get<any>(`api/auth/finalize/${sessionId}`).toPromise();
    // return this._updateAuthCookie(`api/auth/finalize/${sessionId}`);
  }

  private async _clearAuthCookie() {

    await this._http.get<any>('api/auth/logout').toPromise();
    // return this._updateAuthCookie('api/auth/logout');
  }

  private _updateAuthCookie(url: string) {
    return new Promise<void>(resolve => {
      const img = document.createElement('img');

      img.onload = function () {
        img.remove();
        resolve();
      };

      img.onerror = function () {
        img.remove();
        resolve();
      };

      img.src = environment.apiUrl + url;
      document.body.append(img);
    });
  }

  public initIdCardAuth() {
    return this._http.post<any>('api/idCard/initAuth', {});
  }

  public loginWithIdCard(sessionId: string) {
    const svc = this;
    return this._http.get<string>(`api/idcard/authStatus/${sessionId}`)
      .pipe(
        switchMap(async res => {
          svc._tokenString.next(res);
          await this._setAuthCookie(sessionId);
          return res;
        })
      );
  }

  public initAzureAdAuth() {
    return this._http.get<any>('api/azureAd/initAuth');
  }

  public loginWithAzureAd(sessionId: string) {
    const svc = this;
    return this._http.get<string>(`api/azureAd/authStatus/${sessionId}`)
      .pipe(
        switchMap(async res => {
          svc._tokenString.next(res);
          await this._setAuthCookie(sessionId);
          return res;
        })
      );
  }

  public async logout() {
    // remove user from local storage to log user out
    // this._cookieService.delete('jwt');
    await this._clearAuthCookie();
    this._tokenString.next('');
  }

  public logoutOnPageUnload() {
    window.addEventListener('beforeunload', _ => {
      this.logout();
    })
  }

  public getUserSettings() {
    return this._http.get<IUserSettings>(`api/user/settings`);
  }

  public switchTenant(tenantId: string) {
    const svc = this;
    const sessionId = Guid.newGuid().toString();
    this._http.post<any>(`api/auth/switchTenant/${tenantId}/${sessionId}`, {})
      .pipe(switchMap(async res => {
        await this._setAuthCookie(sessionId);
        return res;
      }))
      .subscribe(res => {
        svc._tokenString.next(res);
        window.location.href = '/';
      });
  }

  public authToken(token: string, returnUrl: string) {
    const svc = this;
    const sessionId = Guid.newGuid().toString();
    this._http.post<any>(`api/auth/token/${sessionId}`, {token: token})
      .pipe(switchMap(async res => {
        if (res !== null) {
          await this._setAuthCookie(sessionId);
        }
        return res;
      }))
      .subscribe(res => {
        if (res !== null) {
          svc._tokenString.next(res);
        }
        window.location.href = returnUrl;
      }, (x: HttpBadRequestResponse) => {
        this._lastError = x;
        this._router.navigate(['/login']);
      });
  }

  public authInvitationToken(token: string, returnUrl: string) {
    const svc = this;
    const sessionId = Guid.newGuid().toString();
    this._http.post<any>(`api/auth/invitation/${sessionId}`, {token: token})
      .pipe(switchMap(async res => {
        if (res !== null) {
          await this._setAuthCookie(sessionId);
        }
        return res;
      }))
      .subscribe(res => {
        if (res !== null) {
          svc._tokenString.next(res);
        }
        window.location.href = returnUrl;
      }, (x: HttpBadRequestResponse) => {
        this._lastError = x;
        this._router.navigate(['/login']);
      });
  }

  public verifyTenantInvitationToken(token: string, returnUrl: string) {
    const svc = this;
    const sessionId = Guid.newGuid().toString();
    this._http.post<any>(`api/auth/tenantInvitation/${sessionId}`, {token: token})
      .pipe(switchMap(async res => {
        if (res !== null) {
          await this._setAuthCookie(sessionId);
        }
        return res;
      }))
      .subscribe(res => {
        if (res !== null) {
          svc._tokenString.next(res);
        }
        window.location.href = returnUrl;
      }, (x: HttpBadRequestResponse) => {
        this._lastError = x;
        this._router.navigate(['/login']);
      });
  }

  private _parseJwt(tokenString: string): IToken {

    // source: https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library/38552302#38552302

    // const base64Url = token.split('.')[1];
    // const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    // const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    //   return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    // }).join(''));

    var token = JSON.parse(tokenString, function (key: string, value: any) {
      // if (key === 'TenantType') {
      //   return parseInt(value);
      // }
      // if (key === 'Actions_v2') {
      //   if (!this["Actions"]) {
      //     this["Actions"] = [];
      //   }

      //   this["Actions"].push(parseInt(value));
      //   return;
      // }
      // if (key === 'Modules') {
      //   if (!this["Modules"]) {
      //     this["Modules"] = [];
      //   }

      //   this["Modules"].push(parseInt(value));
      //   return;
      // }
      if (key === 'TenantFeatures') {
        return parseInt(value);
      }
      return value;
    });

    if (token) {
      token["Actions"] = token["Actions_v2"];
      delete token["Actions_v2"];
    }

    // console.log(token);

    return token;
  }

  public isLoggedIn() {
    return this.tokenValue !== undefined && this.tokenValue !== null && this.tokenValue !== '';
  }
}

interface IToken {
  nameid: string;
  unique_name: string;
  given_name: string;
  email: string;
  CurrentTenantId: string;
  // TenantType: TenantType;
  // TenantFeatures: TenantFeature;

  TenantTimeZoneId: string;
  Actions: UserAction[];
  Modules: LxmAppModule[];
  Visitor: any;
  nbf: number;
  exp: number;
  iat: number;
}
