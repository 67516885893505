import { Component, Input, OnInit} from "@angular/core";
import { ICalendarEntry } from "src/app/models";
import { DateHandler } from "src/app/_helpers";

@Component({
  selector: "dashboard-calendar-card",
  templateUrl: "./calendar.card.html",
  styleUrls: ['./calendar.card.scss']
})
export class DashboardCalendarCard {

  @Input() public data: ICalendarEntry[];

  public get hasEntries() {
    return this.data?.length > 0;
  }

  constructor(public date: DateHandler) {}

}

@Component({
  selector: "dashboard-calendar-offer",
  templateUrl: "./template/calendar-offer.html",
  host: { 'class': 'card-calendar-offer' },
  styleUrls: ['./template/calendar-offer.scss']
})
export class DashboardCalendarOffer {

  @Input() public data: ICalendarEntry;

  public get offerType() {
    return this.data?.offerType;
  }

}