export enum ClvType {
    Award = '6B0CC20B-8111-471B-B7C0-2F12F070E77F',
    ProductBrand = 'F778C835-9197-4DCA-B36F-AFBC44E94E86',
    Importer = '0C2BB088-B133-4AE1-AC52-DD205F4406C9',
    Manufacturer = '1653BBCD-9771-4253-9AAF-74D5E445CAC3',
    ProductUnitCodeType = 'CAA1C973-7DF9-4797-AB0C-9FF6D954F107',
    Winery = '1524BEFA-D84E-480E-9F64-C4EF0666B350',
    WineRegionOfOrigin = '7CA00AA5-26AE-49BB-B391-23EE4B1017BE',
    WineGrape = '17C0F89C-C519-41DD-BA6A-DE02FB9CFD69',
    WineCharacteristics = '2C609E2D-B4D6-4140-A945-8A84B5845D33',
    WineIntensity = '3A3659D4-D45E-4CEA-A864-BF5403F8CACF',
    WineTaste = 'F39C68A0-8FE5-4147-B5D3-2ED76D6609F4',
    WineFoodSuggestion = '5FA4B22E-6A39-4CC5-BFE4-983AB0181048',
    StorageConditions = '13685EDA-AC58-4573-97DB-FB154D18CBAC',
    DairyType = '4963C7E0-447C-4D71-B0AB-92D3DE824F15',
    Allergen = '40452C5D-1A3D-4C4F-A75A-81207A69E407',
    EthicalMarking = '12BDAD42-0171-4742-B96F-37B7270125CA',
    ProductUnitCodeTypeFormat = '41790B95-A937-4C27-99A6-4EF99A122EBE',
    ProductAttributeValue = '4A2D7283-1534-4969-829D-4E1152096613',
    ProductUnitType = '7FB58F32-FEF5-495E-951D-3D47C12FD096',
    ProductUnitPackageType = '2FA52B10-523C-4985-8EF9-83D0D5D28F62',
    ProductUnitPackageMaterialType = 'EB0B31D1-C547-420E-B4B6-83D516BBCBC3',
    ProductUnitReturnablePackageDepositType = '9B05B8E5-7B4B-4984-A553-423039FF1665',
    ProductUnitTareType = '4882A2D4-9541-444F-8BCA-2152BC5DC36A',
    ProductUnitPalletType = 'A1E19F0E-EED0-451F-94AB-7EFA13B491D7',
    Vat = '633CA231-4B6C-41E1-AFE1-E0BD277A6666',
    LogisticsPartner = '71C0B20D-E84A-44EC-8D37-FE2A3C24A59D',
    CalendarGroup = '1BA71B66-393B-4FAA-9C84-0FE99EE07E59',
    ChainBrand = 'F844A937-82A3-4943-9380-C9F492B728F6',
    AssortmentStatus = '502F378E-6B44-429C-811A-AE21DECDA4CF',
    ProductTag = '81CF67C5-37E5-4D39-A902-2C5CA8165A21',
    PriceGroup = '27912EF1-9752-4C41-BA68-1A7F26FEEFD7',
    ClientTag = '44BF8D7C-8F99-4F89-8506-36BBE93490D1',
    CampaignType = '35D6161E-982E-4462-86AC-A3F8D4F77FCA',
    PriceList = '16AEE6AB-5BD0-4599-92ED-B8FCC576DA34'
}

(ClvType as any).__typeName = 'ClvType';
