import { Component, ViewChild, Inject, ElementRef } from '@angular/core';
import { DialogComponent } from 'src/app/components';
import { FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileService, ProductService } from 'src/app/_services';
import { HttpEventType } from '@angular/common/http';
import { asArray, Status } from 'src/app/enum';
import { LxmMessage } from 'src/app/_helpers';
import { IProductImage } from '../product-info/product-info.model';

@Component({
  selector: "product-image-dialog",
  host: { 'class': 'dialog' },
  templateUrl: './product-image.dialog.html'
})
export class ProductImageDialogComponent {

  public saveLoading = false;

  @ViewChild('dialog', { static: true, read: DialogComponent })
  private _dialog: DialogComponent;

  public form = new FormGroup({
    status: new FormControl(),
    isMaster: new FormControl(),
    // for validation errors
    files: new FormControl(),
    fileId: new FormControl(),
    isForRetailChain: new FormControl(),
  });

  public statuses = asArray(Status);

  protected _timeZone: string;

  public productImageId: string;
  public files: any = [];
  public uploadInProgress = false;
  public uploadingFile = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public dialogRef: MatDialogRef<ProductImageDialogComponent>,
    private _fileService: FileService,
    private _productService: ProductService,
    private _lxmMessage: LxmMessage
  ) {
    if (this._data.image) {
      this.productImageId = this._data.image.id;
      this.files.push({
        id: this._data.image.fileId,
        name: this._data.image.fileName,
      });

      this.form.get('status').setValue(this._data.image.status);
      this.form.get('isMaster').setValue(this._data.image.isMaster);
      this.form.get('isForRetailChain').setValue(this._data.image.isForRetailChain);
    } else {
      this.form.get('status').setValue(Status.Active);
    }
  }

  public uploadFile(fileList: FileList) {
    for (let index = 0; index < fileList.length; index++) {
      const element = fileList[index];

      const file = {
        id: '',
        name: element.name,
        progress: 0,
        error: undefined
      };
      this.files.push(file);

      const formData = new FormData();
      formData.append('file', element);
      this._fileService.uploadImage(formData)
        .subscribe(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round(100 * event.loaded / event.total);
              this.uploadInProgress = true;
              break;
            case HttpEventType.Response:
              file.id = event.body;
              this.uploadInProgress = false;
              break;
          }
        }, error => {
          this.uploadInProgress = false;
          file.progress = -1;
          const field = error?.validationResults?.stream;
          if (field) {
            file.error = field[0].value;
          }
          // this._lxmMessage.error({
          //   message: 'cards.products.product_images.error.image_upload_failed'
          // });
        });
    }
  }

  public removeImage(index: number) {
    event.stopImmediatePropagation();
    this.files?.splice(index, 1);
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public save() {
    this.uploadingFile = true;
    if (this.productImageId) {
      const files = this.files;
      const data = {
        status: this.form.get('status').value,
        isMaster: this.form.get('isMaster').value,
        fileId: files.map((x: any) => x.id)[0],
        isForRetailChain: this.form.get('isForRetailChain').value,
      };

      this._productService.updateImage(this._data.productId, this.productImageId, data)
        .result(this.form, (res: IProductImage[]) => {
          this._lxmMessage.ok({
            message: 'cards.products.product_images.image_updated'
          });

          this.uploadingFile = false;
          this.dialogRef.close(res.map(x => { 
            if (this.productImageId == x.id) {
              x.thumbnailMediumUrl += `?${new Date().getTime()}`;
            }
            return x;
          }));
        }, err => {
          this.uploadingFile = false;
        });
    } else {
      const files = this.files.filter((x: any) => x.progress === 100);
      const data = {
        status: this.form.get('status').value,
        files: files.map((x: any) => x.id)
      };

      this._productService.addImages(this._data.productId, data)
        .result(this.form, (res: IProductImage[]) => {
          this._lxmMessage.ok({
            message: 'cards.products.product_images.image_added'
          });

          Array.prototype.push.apply(this._data.imagesCard.images, res);

          this.uploadingFile = false;
          this.dialogRef.close();
        }, err => {
          this.uploadingFile = false;
        });
    }
  }
}
