import { Component, Inject, Optional } from "@angular/core";
import { FormGroup, FormControl, FormArray } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { asArray, Status, IEnumValue, UserAction } from "src/app/enum";
import { RoleService } from "src/app/_services/role.service";
import { LxmDialog } from 'src/app/_helpers';
import { AuthenticationService } from 'src/app/_services';
import { IRolesFormData } from 'src/app/resolve/lxm-settings/users.resolve';

@Component({
  selector: "role-dialog",
  host: { 'class': 'dialog' },
  templateUrl: "./role.dialog.html",
  styleUrls: ["./role.dialog.scss"]
})
export class RoleDialog {

  public userActions: IEnumValue[];
  public statuses: IEnumValue[] = asArray(Status);

  public UserAction = UserAction;

  public form: FormGroup;

  public userActionsList: FormArray;

  public saveLoading = false;
  public deleteLoading = false;

  constructor(
    private _dialog: LxmDialog,
    private _authService: AuthenticationService,
    private _roleService: RoleService,
    public dialogRef: MatDialogRef<RoleDialog>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    const actions = (data.formData as IRolesFormData).userActions;

    this.userActionsList = new FormArray(
      Object.keys(actions)
        .map(module => new FormGroup({
          module: new FormControl(module),
          actions: new FormArray(
            actions[module]
              .map(x => new FormGroup({
                userAction: new FormControl(x.userAction),
                prerequisites: new FormControl(x.prerequisites),
                selected: new FormControl(data.role ? data.role.userActions.indexOf(x.userAction) >= 0 : false),
                hidden: new FormControl(x.hidden)
                })
              )
          )
        }))
    );

    this.form = new FormGroup({
      id: new FormControl(),
      name: new FormControl(),
      status: new FormControl(Status.Active),
      userActions: new FormControl(),
      userActionsList: this.userActionsList
    });

    this.form.patchValue(data.role);
  }

  public isPreReq(userAction: UserAction) {

    for (var m of this.userActionsList.value) {

      var actions = m.actions.filter(x => x.prerequisites && x.prerequisites.indexOf(userAction) >= 0);
      for (let i of actions) {
        var any = i.selected || this.isPreReq(i.userAction);
        if (any) {
          return true;
        }
      }
    }

    return false;
  }

  public toggleSelection(row) {
    row.get('selected').setValue(!row.value.selected);
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public save(): void {
    let f = this.form.value;

    let selectedActions = [];
    for (var m of this.userActionsList.value){
      for (var a of m.actions) {
        if (a.selected) {
          selectedActions.push(a.userAction)
        }
      }
    }

    let req = {
      name: f.name,
      status: f.status,
      userActions: selectedActions
    };

    this.saveLoading = true;
    this._roleService
      .save(f.id, req)
      .result(this.form, response => {
        this.saveLoading = false;
        this.dialogRef.close(true);
      }, e => {
        this.saveLoading = false;
      });
  }

  public delete(): void {
    this._dialog.confirm({
      title: "cards.lexi_market_settings.lxm_settings_user_role_edit.delete.title",
      message: "cards.lexi_market_settings.lxm_settings_user_role_edit.delete.message",
      height: "200px"
    }, () => {
      let f = this.form.value;
      this.deleteLoading = true;
      this._roleService
        .delete(f.id)
        .subscribe(response => {
          this.deleteLoading = false;
          this.dialogRef.close(true);
        }, err => {
          this.deleteLoading = false;
        });
    })
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}