import { Component, Input, ViewEncapsulation } from '@angular/core';
import { LxmTreeComponent } from '../../../lxm-tree/lxm-tree.component';

@Component({
    selector: "gpc-tree",
    templateUrl: './gpc-tree.component.html',
    styleUrls: [ './gpc-tree.scss' ],
    encapsulation: ViewEncapsulation.None
})
export class GpcTreeComponent {

    @Input() public initialData: any;

    public displayColumns: string[] = ['code', 'name', 'description', 'canAssignToProduct', 'status'];
}
