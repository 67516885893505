<div class="info-container">
  <span class="info-icon"></span>
  <span class="info-text-container">
    <span class="info-text ml6" *ngIf="prelude" [innerHTML]="prelude | translate"></span>
    <span class="info-item" *ngFor="let item of info; let i = index">
      <span class="info-numbers" *ngIf="numbered">
        <span class="info-number">
          {{i + 1 + "."}}
        </span>
      </span>
      <span class="info-text" [innerHTML]="item | translate : parameters"></span>
    </span>
  </span>
</div>
