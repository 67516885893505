<card-expanding [valid]="cardValidType" [expanded]="isExpanded" (onExpand)="saveLocalExpandedValue($event)">

  <span header-title>{{'cards.products.product_packaging.title' | translate}}</span>

  <div header-actions *requiredRights="[UserAction.ManageProducts]">
    <ng-container *ngIf="isOwnProduct">
      <lxm-button type="beige" icon="plus-dark" label="cards.products.product_packaging.action.add_package" (click)="openPackagingDialog()"></lxm-button>
    </ng-container>
  </div>

  <div body>

    <div *ngIf="!dataSource?.data?.length">
      <no-content image="no-product-packaging-data" text="global.no_product_packaging_data" [inline]="true"></no-content>
    </div>

    <div class="row overflow-x" *ngIf="dataSource?.data?.length">

      <div class="col-12 no-padding">

        <mat-table [dataSource]="dataSource" [trackBy]="trackById" matSort [@.disabled]="true">

          <ng-container matColumnDef="packaging_unit_type">
            <mat-header-cell *matHeaderCellDef>
              <span>
                {{'cards.products.product_packaging.table.unit_type' | translate}}
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span>
                  {{row.type | systemClvName | formValue }}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="packaging_retailer">
            <mat-header-cell *matHeaderCellDef>
              <span>
                {{'cards.products.product_packaging.table.retailer' | translate}}
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span>
                {{row.retailChain.name | formValue}}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="packaging_unit_code">
            <mat-header-cell *matHeaderCellDef>
              <span>
                {{'cards.products.product_packaging.table.unit_code' | translate}}
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              {{row.defaultEan | formValue}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="packaging_unit_contained">
            <mat-header-cell *matHeaderCellDef>
              <span>
                {{'cards.products.product_packaging.table.contained_unit' | translate}}
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span>
                {{(row.subUnitEan || (row.subUnitType | systemClvName)) | formValue}}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="packaging_number_units">
            <mat-header-cell *matHeaderCellDef>
              <span>
                {{'cards.products.product_packaging.table.number_units' | translate}}
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span>
                {{row.subUnitCount | formValue}}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="packaging_number_basic">
            <mat-header-cell *matHeaderCellDef>
              <span>
                {{'cards.products.product_packaging.table.number_basic' | translate}}
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span>
                {{row.baseUnitCount | formValue}}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="packaging_unit_height">
            <mat-header-cell *matHeaderCellDef>
              <span>
                {{'cards.products.product_packaging.table.unit_height' | translate}}
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span [class]="getRowHeightColorClass(row)">
                {{row.height_mm | formValue}}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="packaging_unit_width">
            <mat-header-cell *matHeaderCellDef>
              <span>
                {{'cards.products.product_packaging.table.unit_width' | translate}}
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span [class]="getRowWidthColorClass(row)">
                {{row.width_mm | formValue}}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="packaging_unit_length">
            <mat-header-cell *matHeaderCellDef>
              <span>
                {{'cards.products.product_packaging.table.unit_length' | translate}}
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span [class]="getRowLengthColorClass(row)">
                {{row.length_mm | formValue}}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="packaging_unit_gross_weight">
            <mat-header-cell *matHeaderCellDef>
              <span>
                {{'cards.products.product_packaging.table.unit_gross_weight' | translate}}
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span [class]="getRowGrossWeightColorClass(row)">
                {{(row.grossWeight_g / 1000) | numericValue:2}}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="packaging_unit_status">
            <mat-header-cell *matHeaderCellDef>
              <span>
                {{'table.status' | translate}}
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <div class="flex flex-row flex-nowrap align-center">
                <status-circle class="mr5" [status]="row.status" enum="Status"></status-circle>
                {{row.status | translateEnum: 'Status'}}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="packaging_deposit">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let row" class="overflow-cell">
              <data-hover icon="deposit" [disabled]="!row.packageDeposits || row.packageDeposits.length === 0">
                <span class="deposit-name">
                  {{getPackageDepositsValue(row)}}
                </span>
              </data-hover>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="packaging_materials">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let row"  class="overflow-cell">
              <data-hover icon="material" [disabled]="!row.packageMaterials || !row.packageMaterials[0]?.materialType">
                <span class="material-name">
                  {{getPackageMaterialsValue(row)}}
                </span>
              </data-hover>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="packaging_remove">
            <mat-header-cell *matHeaderCellDef [hidden]="!canEdit"></mat-header-cell>
            <mat-cell *matCellDef="let row" (click)="deleteUnit(row)" [hidden]="!canEdit">
              <div class="flex f-1 align-center justify-center" style="min-width: 34px; min-height: 34px;">
                <span class="product-unit-trash-icon" [class.disabled--03]="row.type.id === ProductUnitType.BaseUnit"></span>
              </div>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="table"></mat-header-row>
          <mat-row *matRowDef="let row; columns: table;" class="table-row-hover" [class.invalid]="isProductUnitInvalid(row.id)" (click)="openPackagingDialog(row)"></mat-row>
        </mat-table>

      </div>

    </div>

  </div>

</card-expanding>
