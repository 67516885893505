import { Component, OnInit, Input, Optional, Host, HostBinding } from '@angular/core';
import { FormGroup, ControlContainer, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { ReadonlyDirective } from 'src/app/directives';
import { FieldRequiredType } from 'src/app/enum';
import { LanguageTextControlBuilder } from 'src/app/_helpers';
import { AppState } from 'src/app/state/app.state';

@Component({
  selector: 'language-text',
  templateUrl: './language-text.component.html',
  styleUrls: ['./language-text.scss']
})
export class LanguageTextComponent implements OnInit {

  private _arr: FormArray;

  @Input() public formGroup: FormGroup;
  @Input() public for: string;
  @Input() public newline = false;
  @HostBinding('class.readonly')
  @Input() public readonly = false;
  @Input() public inputWidth = '100%';
  @Input() public inputClass: string;
  @Input() public maxlength: string;
  @Input() public required: ILanguageTextRequired[];
  @Input() public quill = false;
  @Input() public fromUiLanguages = false;

  public fgInternal = new FormGroup({});

  public get formArray() {
    return this._arr;
  }

  constructor(
    private _languageTextBuilder: LanguageTextControlBuilder,
    private _appState: AppState,
    @Optional() private _controlContainer: ControlContainer,
    @Optional() @Host() private _readonlyQuery: ReadonlyDirective

  ) { }

  public ngOnInit(): void {
    if (this._controlContainer && this._controlContainer.control instanceof FormGroup) {
      this.formGroup = <FormGroup>this._controlContainer.control;
    }
    
    if (this._readonlyQuery && this._readonlyQuery.readonly) {
      this.readonly = this._readonlyQuery.readonly;
    }

    const ref = this.formGroup.get(this.for);
    this._arr = this._languageTextBuilder.createLanguageTextControls(ref.value, this.fromUiLanguages ? this._appState.uiLanguages.map(x => x.value) : this._appState.contentLanguages);
    this.fgInternal.addControl(this.for, this._arr);

    this._bindControls(ref, this._arr);
  }

  private _bindControls(ref: AbstractControl, arr: FormArray) {

    let innerChange = false;

    ref.valueChanges.subscribe(x => {
      if (innerChange) {
        innerChange = false;
        return;
      }

      const normalizedArray = this._languageTextBuilder.getNormalizedItems(x);
      arr.setValue(normalizedArray, { emitEvent: false});
    });

    arr.valueChanges.subscribe(x => {
      innerChange = true;
      ref.setValue(x);
    });
  }

  public getRequiredType(language: string, value: any): FieldRequiredType {
    if (value || !this.required || this.required.length < 1 || !language) return FieldRequiredType.None;
    return this.required[language] || FieldRequiredType.None;
  }

  public getInputId(index: number) {
    if (index > 0) {
      return `${this.for}${index}`
    }
    return this.for;
  }

}

interface ILanguageTextRequired {
  language: string;
}