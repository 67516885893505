import { Component, Input, Optional, Host, OnInit, OnDestroy, HostBinding, Output, EventEmitter } from '@angular/core';
import { FormGroup, ControlContainer } from '@angular/forms';
import { ViewEncapsulation } from '@angular/core';
import { ReadonlyDirective } from 'src/app/directives';
import { Subscription } from 'rxjs';

@Component({
  selector: "combined-input",
  templateUrl: './combined-input.component.html',
  styleUrls: ['./combined-input.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CombinedInputComponent implements OnInit, OnDestroy {

  @Input() public formGroup: FormGroup;
  @Input() public id: string;
  @Input() public for: string;

  @HostBinding('class.readonly')
  @Input() public readonly = false;
  @Input() public disabled = false;
  @Input() public width = 'auto';
  @Input() public inputType = 'text';
  @Input() public placeholder: string;

  @Input() public suffix: string;
  @Input() public icon: string;

  @Input() public isValid: boolean = true;

  @Output() public blur = new EventEmitter();

  private _subscription: Subscription;

  constructor(
    @Optional() @Host() private _readonlyQuery: ReadonlyDirective,
    @Optional() @Host() private _controlContainer: ControlContainer
  ) {
  }

  public ngOnInit(): void {

    if (this._readonlyQuery && this._readonlyQuery.readonly) {
      this.readonly = this._readonlyQuery.readonly;
    }

    if (this._controlContainer && this._controlContainer.control instanceof FormGroup) {
      this.formGroup = <FormGroup>this._controlContainer.control;

      if (this.for) {
        this._subscription = this.formGroup.get(this.for).statusChanges.subscribe(x => this.isValid = x === 'VALID');
      }
    }
  }

  ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }
}
