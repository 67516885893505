<ng-container [formGroup]="formGroup" [readonly]="!canEdit">

    <form-field dialog label="cards.settings.clv.common.table.code" for="code" type="input-text">
      <validation-errors for="code"></validation-errors>
    </form-field>

    <form-field dialog label="cards.settings.clv.common.table.value" for="value" type="input-text" [required]="canEdit">
      <validation-errors for="value"></validation-errors>
    </form-field>

    <form-field dialog label="cards.settings.clv.common.table.description" for="description" type="input-text">
      <validation-errors for="description"></validation-errors>
    </form-field>

    <form-field dialog label="cards.settings.clv.common.table.status">
      <lxm-select select for="status" class="select w40p"
        [formGroup]="formGroup" 
        [options]="statuses"
        value="id">
      </lxm-select>
      <validation-errors for="status"></validation-errors>
    </form-field>

</ng-container>