<div class="tab-content">
    <card-section>
        <span header-title>
            {{'cards.settings.assortment_group_tags.title' | translate}}
        </span>
        <div header-actions>
            <div class="flex">
                <lxm-button *ngIf="canEdit" type="beige" class="ml15" icon="plus-dark"
                    label="cards.settings.assortment_group_tags.action.add" (click)="openDialog()">
                </lxm-button>
            </div>
        </div>
    </card-section>
</div>

<lxm-tree class="assortment-group-tags" dataUrl="assortmentGroupTags/list" [displayColumns]="displayColumns">
    <div lxmTreeColumnDef="code">
        <div *lxmTreeHeaderDef>
            {{'cards.settings.assortment_group_tags.label.code' | translate}}
        </div>
        <div *lxmTreeCellDef="let tag">{{tag.code | formValue}}</div>
    </div>

    <div lxmTreeColumnDef="sortOrder">
        <div *lxmTreeHeaderDef>
            {{'cards.settings.assortment_group_tags.label.sort_order' | translate}}
        </div>
        <div *lxmTreeCellDef="let tag">{{tag.sortOrder | formValue}}</div>
    </div>

    <div lxmTreeColumnDef="name">
        <div *lxmTreeHeaderDef>
            {{'cards.settings.assortment_group_tags.label.name' | translate}}
        </div>
        <div *lxmTreeCellDef="let tag">{{tag.name | formValue}}</div>
    </div>

    <div lxmTreeColumnDef="description">
        <div *lxmTreeHeaderDef>
            {{'cards.settings.assortment_group_tags.label.description' | translate}}
        </div>
        <div *lxmTreeCellDef="let tag">{{tag.description | formValue}}</div>
    </div>

    <div lxmTreeColumnDef="isChild">
        <div *lxmTreeHeaderDef>
            {{'cards.settings.assortment_group_tags.label.is_child' | translate}}
        </div>
        <div *lxmTreeCellDef="let tag">{{tag.isChild | translateEnum : Bool}}</div>
    </div>

    <div lxmTreeColumnDef="status">
        <div *lxmTreeHeaderDef>
            {{'cards.settings.assortment_group_tags.label.status' | translate}}
        </div>
        <div *lxmTreeCellDef="let tag">{{tag.status | translateEnum : Status}}</div>
    </div>

    <div lxmTreeColumnDef="color">
        <div *lxmTreeHeaderDef></div>
        <div *lxmTreeCellDef="let tag">
            <ng-container *ngIf="tag.isChild">
                <color-box [color]="tag.color" class="size-s"></color-box>
            </ng-container>
        </div>
    </div>

    <div lxmTreeColumnDef="actions">
        <div *lxmTreeHeaderDef></div>
        <div *lxmTreeCellDef="let tag">
            <div class="flex">
                <div style="width: 41px;">
                    <lxm-button *ngIf="canEdit && !tag.isChild" type="beige" icon="plus-dark"
                        title="cards.settings.assortment_group_tags.action.add_child"
                        (click)="$event.stopPropagation(); openDialog(null, tag)">
                    </lxm-button>
                </div>
                <div class="ml15" style="width: 41px;">
                    <lxm-button *ngIf="canEdit" type="beige" icon="edit"
                        title="cards.settings.assortment_group_tags.action.action.edit"
                        (click)="$event.stopPropagation(); openDialog(tag)">
                    </lxm-button>
                </div>
            </div>
        </div>
    </div>
</lxm-tree>