<scope>
  <card-section>
    <span header-title style="font-size: 24px; font-weight: bold;">
      {{'cards.lexi_market_settings.lxm_settings_user_role.title' | translate }}
    </span>
    <span header-actions>
      <lxm-button type="beige"
                  icon="plus-dark"
                  label="cards.lexi_market_settings.lxm_settings_user_role.action.add_role"
                  (click)="openRoleDialog()">
      </lxm-button>
    </span>
    <div body>
      <roles-table></roles-table>
    </div>
  </card-section>

</scope>
