<div class="notification-wrapper ml5">
  <div class="notification-header">
    <span>{{getNotificationHeader(data)}}</span>
  </div>
  <div class="notification-subject" 
    [innerHTML]="getNotificationSubject(data)">
  </div>
  <div class="notification-preview"
    [innerHTML]="getNotificationPreview(data)">
  </div>
</div>
<div class="notification-time">
  <time-diff date="{{data.createdAt}}"></time-diff>
</div>