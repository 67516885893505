import { Component, OnInit, Input, Optional, Host, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { FormGroup, ControlContainer, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { merge } from 'rxjs';
import { IClvDto } from 'src/app/cards/product/product-info/product-info.model';
import { ReadonlyDirective } from 'src/app/directives';
import { AppState } from 'src/app/state/app.state';
import { numberUtil } from 'src/app/util/number-util';


@Component({
  selector: 'product-procurement-list',
  templateUrl: './product-procurement-list.component.html',
  styleUrls: ['./product-procurement-list.component.scss']
})
export class ProductProcurementListComponent {

  private _arr: FormArray;

  @Input() public formGroup: FormGroup;
  @Input() public for: string;
  @Input() public readonly = false;

  public fgInternal = new FormGroup({});

  @Output() blur: EventEmitter<any> = new EventEmitter();

  public get formArray() {
    return this._arr;
  }

  constructor(
    appState: AppState,
    @Optional() private _controlContainer: ControlContainer,
    @Optional() @Host() private _readonlyQuery: ReadonlyDirective

  ) { }

  public ngOnInit(): void {
    if (this._controlContainer && this._controlContainer.control instanceof FormGroup) {
      this.formGroup = <FormGroup>this._controlContainer.control;
    }

    if (this._readonlyQuery && this._readonlyQuery.readonly) {
      this.readonly = this._readonlyQuery.readonly;
    }

    const ref = this.formGroup.get(this.for);
    const value = ref?.value || [];

    if (value?.length) {
      this._arr = new FormArray(
        value.map(x => this._createRow(x))
      );
    } else {
      this._arr = new FormArray(
        [this._createRow(null)]
      )
    }

    this.fgInternal.addControl(this.for, this._arr);
    this._bindControls(ref, this._arr);
  }

  private _createRow(x) {
    const { name, amount, comment, isNew, id, isDeleted } = x || {};

    const formGroup = new FormGroup({
      name: new FormControl(name),
      amount: new FormControl(amount),
      comment: new FormControl(comment),
      isNew: new FormControl(isNew || x === null),
      deleted: new FormControl(isDeleted || false),
      id: new FormControl(id)
    });

    return formGroup;
  }

  public addRow() {
    let row = this._createRow(null);
    this._arr.insert(this._arr.length, row);
  }

  public removeRow(index) {
    let row = this._arr.at(index);
    if (row.value.isNew) {
      this._arr.removeAt(index);
    }
    else {
      row.get('deleted').setValue(!row.value.deleted);
    }
  }

  private _bindControls(ref: AbstractControl, arr: FormArray) {

    let innerChange = false;

    ref.valueChanges.subscribe(value => {
      if (innerChange) {
        innerChange = false;
        return;
      }

      arr.clear({ emitEvent: false });

      for (const row of value) {
        arr.insert(arr.length, this._createRow(row), { emitEvent: false });
      }

    });

    arr.valueChanges.subscribe(rows => {
      innerChange = true;

      if (rows) {
        ref.setValue(rows
          .filter(x => !x.deleted));
      }
      else {
        ref.setValue(rows);
      }
    });
  }

}
