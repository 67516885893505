import { Component, OnInit } from '@angular/core';
import { TitleService } from 'src/app/_services/title.service';
import { LxmAppModule } from 'src/app/enum';
import { AppState } from 'src/app/state/app.state';

@Component({
  selector: 'settings-layout',
  templateUrl: './settings.layout.html',
  host: { 'class': 'content' },
  styleUrls: ['./settings.layout.scss']
})
export class SettingsLayout implements OnInit {
  title = 'cards.settings.title';

  public LxmAppModule = LxmAppModule;

  constructor(
    public appState: AppState,
    private _titleService: TitleService) { }

  public ngOnInit() {
    this._titleService.setTitle(this.title);
  }

  // public hasFeature(feature: TenantFeature) {
  //   return this._authenticationService.hasFeature(feature);
  // }
}
