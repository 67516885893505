<div class="retailer-info-hover-container" stop-propagation [popper]="tooltipcontent" popperTrigger="hover"
  [popperDelay]="delay" [popperPlacement]="position" [popperHideOnMouseLeave]="false"
  [popperApplyClass]="campaignScheme ? 'product-chain-info-popover' : null" popperAppendTo="app-root"
  style="position: relative">

  <div class="flex flex-row">
    <span class="product-campaign-sm-icon" *ngIf="campaignScheme"> </span>
  </div>

</div>

<popper-content #tooltipcontent (click)="preventPropagation()">

  <ng-container *ngIf="campaignScheme">
    <ng-container *ngTemplateOutlet="campaign">
    </ng-container>
  </ng-container>

</popper-content>

<!-- CAMPAIGN SECTION -->
<ng-template #campaign>
  <div class="popover-section">
    <div class="popover-section-header">
      <span class="product-campaign-icon"></span>
      <span class="popopver-section-header-text ml10">
        {{ campaignScheme.validFrom | formDate }} -
        {{ endDate(campaignScheme.validTo) | formDate }}
      </span>
    </div>
    <div class="popover-section-content">
      <div class="popover-section-row flex justify-between">
        <span class="mr20">
          {{ "cards.products.product_price_info_popover.campaign_list_price" | translate }}
        </span>
        <span>
          {{ campaignScheme.listPrice | priceValue:pricePrecision:campaignCurrency?.value }}
        </span>
      </div>

      <div class="popover-section-row flex justify-between">
        <span class="mr20">
          {{ "cards.products.product_price_info_popover.campaign_discount" | translate }}
        </span>
        <span class="campaign-discount-percentage">
          {{ campaignDiscount | percentValue }}
        </span>
      </div>

      <div class="popover-section-row flex justify-between">
        <span class="mr20">
          {{ "cards.products.product_price_info_popover.campaign_buy_in_price" | translate }}
        </span>
        <span>
          {{ campaignScheme.buyInPrice | priceValue:pricePrecision:campaignCurrency?.value }}
        </span>
      </div>

      <div class="popover-section-row flex justify-between">
        <span class="mr20">
          {{ "cards.products.product_price_info_popover.campaign_buy_in_unit_price" | translate }}
        </span>
        <span>
          {{ campaignScheme.buyInUnitPrice | priceValue:pricePrecision:campaignCurrency?.value }}
        </span>
      </div>

    </div>
  </div>
</ng-template>