<div class="data-table-footer-wrapper">
    <div class="data-table-footer-wrapper__left">
      <zoom-controls *ngIf="zoom" (valueChange)="zoomChange.emit($event)"></zoom-controls>
      <lxm-button *ngIf="isFullScreen !== undefined" [class.ml10]="zoom" type="transparent" (click)="toggleFullScreen()"
        [icon]="isFullScreen ? 'minimize' : 'maximize'">
      </lxm-button>
    </div>
    <div class="data-table-footer-wrapper__center">
      <ng-content select="[center]"></ng-content>
    </div>
    <div class="data-table-footer-wrapper__right">
      <ng-content select="[right]"></ng-content>
      <div class="loader-wrapper">
        <loading-spinner type="transparent" *ngIf="isLoading"></loading-spinner>
      </div>
      <mat-paginator
        class="f1"
        [length]="length"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        [class.no-display]="!showPagination"
        showFirstLastButtons>
      </mat-paginator>
    </div>
</div>