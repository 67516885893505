import { Component, Input, OnInit, AfterViewInit, AfterContentInit, AfterContentChecked, AfterViewChecked, OnDestroy, HostBinding, HostListener } from '@angular/core';
import { NavigatorState } from '../../../../state/navigator.state';
import { ProductValidity } from 'src/app/_services';
import { ProductCardSection, ValidType } from 'src/app/enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'reactive-links',
  templateUrl: './reactive-links.component.html',
  styleUrls: ['./reactive-links.scss']
})
export class ReactiveLinksComponent implements OnInit, OnDestroy {

  @Input() public options: IReactiveLinkOptions;
  @Input() public scroll = true;

  @HostBinding('hidden') @Input() public hidden: boolean;

  private _view: HTMLElement;
  public innerTargets: IReactiveLinkTarget[];
  private _innerSelectors: HTMLElement[] = [];

  public activeTargetIndex: number = 0;

  public scrollY: number = 0;

  public get headerText() {
    return this._state.headerText;
  }

  private _refreshSubscription: Subscription;

  constructor(
    private _state: NavigatorState, 
    private _productValidity: ProductValidity
  ) {
    this.scrollInterpreter = this.scrollInterpreter.bind(this);
    this._refreshSubscription = this._state.refreshReactiveLinks.subscribe(() => this.refresh())
  }

  public getCardStatusValidType(section: ProductCardSection) {
    return this._productValidity.getCardStatusValidType(section);
  }

  ngOnInit() {
    this.initListener();
  }

  public get shouldValidate() {
    return this._productValidity.shouldValidate;
  }

  ngOnDestroy() {
    this.removeListener();
    if (this._refreshSubscription) {
      this._refreshSubscription.unsubscribe();
    }
  }

  public scrollInterpreter(event): void {
    this.scrollY = event.target.scrollTop;
    this.checkActiveTargets();
  }

  public getAllTargets(options = this.options): void {
    this._view = this.getTarget(options.view);
    let targets = [];
    let selectors = [];
    options.targets.forEach(target => {
      let el = this.getTarget(target.selector);
      if (el) {
        targets.push(target);
        selectors.push(el);
      }
    })

    this.innerTargets = targets;
    this._innerSelectors = selectors;

    this.scrollY = this.getTargetScrollTop(this._view);
    this.checkActiveTargets();
  }

  public setListener(): void { this._view.addEventListener('scroll', this.scrollInterpreter) }
  public removeListener(): void { this._view.removeEventListener('scroll', this.scrollInterpreter) }

  public initListener() {
    setTimeout(_ => {
      this.getAllTargets();
      this.removeListener();
      this.setListener();
    }); 
  }

  public refresh() {
    setTimeout(_ => {
      this.removeListener();
      this.getAllTargets();
      this.removeListener();
      this.setListener();
    }); 
  }

  public checkActiveTargets(): void {
    for (let i = 0; i < this._innerSelectors.length; i++) {
      if (this.isInView(i)) {
        this.activeTargetIndex = i;
        break;
      }
    }
  }

  public isInView(index: number) {
    let selector: HTMLElement = this._innerSelectors[index];
    if (selector) {
      let rect = selector.getBoundingClientRect();
      return rect.top <= this.scrollY / 3.33 && (rect.top >= 0 || rect.bottom >= 350);
    }
    return false
  }

  public getTarget(selector: any) {
    try { return document.querySelector(selector) } catch (error) {
      console.error(error);
    };
  }

  public getTargetScrollTop(target: HTMLElement) {
    return target.scrollTop;
  }

  public getTargetTopByIndex(index: number) {
    return this._innerSelectors[index]?.offsetTop;
  }

  public scrollToTargetByIndex(index: number) {
    const targetY = this.getTargetTopByIndex(index);

    this._view.scrollTo(0, targetY - 20); // - margin
    this.activeTargetIndex = index;
  }

  // note - does not work on firefox
  @HostListener('mousewheel', ['$event']) private onMousewheel(event) {
    if (!this.scroll) return; 

    let currentTargetIndex = this.activeTargetIndex;

    if (this.checkScrollDirectionIsUp(event)) {

      if (currentTargetIndex > 0) {
        this.scrollToTargetByIndex(currentTargetIndex - 1)
      }

    } else {

      if (currentTargetIndex < this._innerSelectors.length - 1) {
        this.scrollToTargetByIndex(currentTargetIndex + 1)
      }

    }

    return false

  }

  public checkScrollDirectionIsUp(event) {
    if (event.wheelDelta) {
      return event.wheelDelta > 0;
    }
    return event.deltaY < 0;
  }

}

export interface IReactiveLinkOptions {
  view: string;
  targets: IReactiveLinkTarget[];
}

interface IReactiveLinkTarget {
  translateKey: string;
  selector: string;
  section?: ProductCardSection;
}
