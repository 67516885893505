export enum SupplierProductAssortmentStatus {
    Active = 1,
    EndOfLife = 2,
    Inactive = 3,
    NoScheme = 4,
    ActiveViaOfferOnly = 5,
    ActiveAndInOffer = 6,
    SchemeStartsTomorrow = 8
}

(SupplierProductAssortmentStatus as any).__typeName = 'SupplierProductAssortmentStatus';
