import { Component, Inject, Optional, ViewChild } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { delay } from "rxjs/operators";
import { DialogComponent } from 'src/app/components/_layout/dialog/dialog.component';
import { asArray, Status } from "src/app/enum";
import { LxmMessage } from "src/app/_helpers";
import { ILxmTreeNode } from "src/app/_services/lxm-tree.service";
import { SegmentService } from "src/app/_services/segment.service";

@Component({
  selector: "segment-dialog",
  host: { 'class': 'dialog' },
  templateUrl: "./segment.dialog.html",
  styleUrls: ["./segment.dialog.scss"]
})
export class SegmentDialog {

  public formGroup: FormGroup;
  public statuses: any[] = asArray(Status);
  public isParentActive: boolean;
  public hasChildren: boolean;
  
  @ViewChild('dialog', { static: true, read: DialogComponent })
  private _dialog: DialogComponent;

  public actionLoading = {} as any;

  constructor(
    private _segmentService: SegmentService,
    public dialogRef: MatDialogRef<SegmentDialog>,
    public _msg: LxmMessage,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ISegmentDialogData) {

    this.isParentActive = data.parentSegment && data.parentSegment.status !== undefined 
      ? data.parentSegment.status === Status.Active 
      : true;

    
    this.formGroup = new FormGroup({
      id: new FormControl(),
      code: new FormControl(),
      name: new FormControl(),
      description: new FormControl(),
      status: new FormControl(),
      canAssignToProduct: new FormControl(false),
      gpcBricks: new FormControl(),
    });
  }

  public get isNew() {
    return this.formGroup.get('id').value ? false : true;
  }
  
  public ngAfterViewInit(): void {

    this._dialog.dataLoaded$
      .pipe(
        delay(0)
      )
      .subscribe(state => {
        if (!state) {
          return;
        }

        const segment = this.data.segment;
        this.hasChildren = segment?.isParent;

        let status: Status;
        if (segment) {
          status = segment.status;
        } else if (this.data.parentSegment){
          status = this.isParentActive ? Status.Active : Status.Disabled;
        }
        else {
          status = Status.Active;
        }

        this.formGroup.patchValue({
          id: segment?.id,
          code: segment?.code,
          name: segment?.name,
          description: segment?.description,
          status: status,
          canAssignToProduct: this.hasChildren ? false : (segment?.canAssignToProduct || false),
          gpcBricks: segment?.gs1Segments,
        });
      });
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public save(): void {
    const f = this.formGroup.value;

    const req = {
      code: f.code,
      name: f.name,
      description: f.description,
      status: f.status,
      canAssignToProduct: f.canAssignToProduct,
      parentId: this.data.parentSegment ? this.data.parentSegment.id : null,
      gs1SegmentIds: f.gpcBricks?.map(x => x.id)
    };

    this._segmentService
      .save(f.id, req)
      .result(this.formGroup, response => {
        this.dialogRef.close(response);
        this._msg.ok({
          message: 'segments.segment_saved_successfully'
        })
      }, err => {
        this._msg.error({
          message: err.validationSummary
        });
      },
      "save",
      this.actionLoading);
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}

export class ISegmentDialogData {
  segment: ISegment;
  parentSegment: ISegment;
}

export class ISegment implements ILxmTreeNode {
  id: string;
  name: string;
  code: string;
  description: string;
  canAssignToProduct: boolean;
  status: Status;
  parentId: string;
  isParent: boolean;
  isParentActive: boolean;
  level: number;
  gs1Segments: any[]
}