import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ListBase } from '../list-base';
import { LxmDialog } from 'src/app/_helpers';
import { IClvDto } from 'src/app/cards/product/product-info/product-info.model';

@Component({
  selector: 'links-list',
  templateUrl: './links-list.component.html',
  styleUrls: ['./links-list.component.scss']
})
export class LinksListComponent extends ListBase implements OnInit {

  @Input() public linkTypeOptions: IClvDto[];

  private _defaultColumns: string[] = ['type_column', 'link_column', 'comment_column', 'actions'];

  public get columns(): string[] {
    return this._defaultColumns;
  }

  @Input() public dialogRef: any;
  @Input() public displayHeadersWhenEmpty = false;
  @Input() public softDelete = false;
  @Input() public readonly = false;

  @Output() public open = new EventEmitter();
  @Output() public remove = new EventEmitter();

  constructor(
    private _dialog: LxmDialog
  ) {
    super();
  }

  public openAddLinkDialog(row: any = null) {
    if (this.open) {
      this.open.emit(row);
    } else if (this.dialogRef) {
      this._dialog.open(this.dialogRef, {
        data: {
          row: row,
          dataTableRef: this.dataSource
        }
      });
    }
  }

  public removeLink(row: any) {
    if (this.softDelete) {
      if (row.isNew) {
        const i = this.dataSource.data.indexOf(row);
        this.dataSource.data.splice(i, 1);
      } else {
        row.isDeleted = true;
      }
    } else {
      this.remove.emit(row);
    }
  }

  public getTypeValue(type:IClvDto)
  {
    return this.linkTypeOptions.find(item=>item.id == type.id).translatedValue;
  }
}
