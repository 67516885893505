<ng-container [formGroup]="formGroup">

  <ng-select #select
             [class.autocomplete]="url"
             [class.has-value]="hasValue"
             [class.ng-invalid]="hasError"
             [formControlName]="for"
             appendTo="body"
             [items]="options"
             [bindValue]="value"
             [bindLabel]="labelField"
             [searchable]="true"
             [maxSelectedItems]="maxSelectedItems"
             [closeOnSelect]="true"
             [hideSelected]="hideSelected"
             [class.disabled]="disabled"
             (search)="find($event)"
             (open)="onOpen($event)"
             (close)="onClose($event)"
             (change)="onChange($event)">


    <ng-template ng-label-tmp let-item="item">
      {{item[labelField]}}
    </ng-template>

    <ng-template ng-option-tmp let-item="item">
      {{item[labelField]}}
    </ng-template>

  </ng-select>

  <span class="lxm-select-default">-</span>
  <span *ngIf="overlay" class="select-overlay" (click)="select.close()"></span>

</ng-container>
