import { Component, OnInit, Input, Optional, Host } from '@angular/core';
import { TareControlBuilder } from 'src/app/_helpers/control.builder';
import { ViewEncapsulation } from '@angular/core';
import { FormGroup, ControlContainer, FormArray } from '@angular/forms';
import { ReadonlyDirective } from 'src/app/directives';

@Component({
  selector: "tare-manager",
  templateUrl: './tare-manager.component.html',
  styleUrls: ['./tare-manager.scss']
})
export class TareManagerComponent implements OnInit {

  @Input()
  public formGroup: FormGroup;

  @Input()
  public for: string;

  @Input()
  public readonly = false;

  @Input()
  public tareOptions: any[];

  emptyTareModel = {
    tare: null,
    amount_pc: null
  };

  constructor(
    @Optional() @Host() private _readonlyQuery: ReadonlyDirective,
    @Optional() private _controlContainer: ControlContainer, private _: TareControlBuilder) {
    this.getTareOptions();
  }

  public ngOnInit(): void {

    if (this._readonlyQuery && this._readonlyQuery.readonly) {
      this.readonly = this._readonlyQuery.readonly;
    }

    if (this._controlContainer && this._controlContainer.control instanceof FormGroup) {
      this.formGroup = <FormGroup>this._controlContainer.control;
    }

  }

  get formArray() {
    return this.formGroup.get(this.for) as FormArray;
  }

  public getTareOptions() {
    return this.tareOptions = [
      { id: 1, name: 'Taara 1' },
      { id: 2, name: 'Taara 2' }
    ];
  }

  public addItem(model = this.emptyTareModel) {
    this.formArray.push(this._.createTareControl(model));
  }

  public removeItem(index: number) {
    this.formArray.removeAt(index);
  }

  public trackByFn(index: any, item: any) {
    return index;
  }
}

