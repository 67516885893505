<lxm-dialog [dialogRef]="dialogRef" [formGroup]="form" #dialog>
  <span header-title
    >{{(editable ? (schemeId ?
    'cards.products.product_pricelist_retail_edit.title_edit' :
    'cards.products.product_pricelist_retail_edit.title_new' ) :
    'cards.products.product_pricelist_retail_edit.title') | translate }}
  </span>
  <div body style="padding-bottom: 40px">
    <scope
      translationsPath="cards.products.product_pricelist_retailer_supplier.dialog"
    >
      <form-field
        dialog
        label="supplier"
        description="supplier"
        for="supplier"
        [required]="canSetSupplier"
      >
        <lxm-select
          select
          for="supplier"
          [readonly]="!canSetSupplier"
          [searchable]="true"
          [labelFn]="supplierAcLabel"
          [sortByLabel]="true"
          [options]="suppliersOptions"
        >
        </lxm-select>
        <validation-errors
          for="retailerSupplierId"
          aliasTo="supplier"
        ></validation-errors>
      </form-field>

      <form-field
        dialog
        label="logistics_partner"
        for="logisticsPartner"
        description="logistics_partner"
      >
        <lxm-select
          select
          for="logisticsPartner"
          labelField="value"
          [searchable]="true"
          [options]="logisticsPartnersOptions"
        >
          <lxm-button
            *requiredRights="[UserAction.ManageClvs]"
            header
            label="action.add_new"
            icon="plus-dark"
            (click)="openClassificatorValueDialog(ClvType.LogisticsPartner, form.get('logisticsPartner'), 'logisticsPartnersOptions')"
          >
          </lxm-button>
        </lxm-select>
        <validation-errors
          for="logisticsPartnerId"
          aliasTo="logisticsPartner"
        ></validation-errors>
      </form-field>

      <form-field
        dialog
        label="currency"
        for="buyInPriceCurrency"
        description="currency"
        [required]="acceptedCurrencies.length > 1"
      >
        <lxm-select
          select
          for="buyInPriceCurrency"
          [readonly]="acceptedCurrencies.length < 2"
          [options]="acceptedCurrencies"
          labelField="value"
          compareField="id"
          (change)="handleCalculations('currency')"
        ></lxm-select>
        <validation-errors
          for="buyInPriceCurrencyId"
          aliasTo="buyInPriceCurrency"
        ></validation-errors>
      </form-field>

      <form-field
        dialog
        label="scheme_type"
        for="schemeType"
        description="scheme_type"
        [required]="true"
      >
        <lxm-select
          select
          [options]="schemeTypeOptions"
          value="id"
          for="schemeType"
          [searchable]="true"
          [clearable]="false"
          [sortByLabel]="false"
        ></lxm-select>
        <validation-errors for="schemeType"></validation-errors>
      </form-field>

      <form-field
        *ngIf="isSchemeTypeCampaign && campaignTypeOptions?.length"
        dialog
        label="campaign_type"
        for="campaignType"
        description="campaign_type"
      >
        <lxm-select
          select
          [options]="campaignTypeOptions"
          labelField="value"
          for="campaignType"
          [searchable]="true"
          [clearable]="false"
          [sortByLabel]="false"
        ></lxm-select>
        <validation-errors for="schemeType"></validation-errors>
      </form-field>

      <form-field
        dialog
        label="procurement"
        for="procurement"
        description="procurement"
        *ngIf="this.form.get('schemeType').value === ProductPriceSchemeType.Procurement"
      >
        <lxm-select
          select
          for="procurement"
          [options]="procurements"
          compareField="id"
          [isTranslated]="true"
          [readonly]="scheme?.offerId !== null"
        ></lxm-select>
        <validation-errors
          for="procurementId"
          aliasTo="procurement"
        ></validation-errors>
      </form-field>

      <form-field
        dialog
        label="valid"
        for="validFrom"
        description="valid"
        [required]="true"
      >
        <div combined class="flex" style="align-items: center">
          <lxm-datepicker
            class="dialog-input-fixed-width-1"
            for="validFrom"
            [min]="nextDay"
          ></lxm-datepicker>
          <ng-container
            *ngIf="form.get('schemeType').value === ProductPriceSchemeType.Campaign || form.get('schemeType').value === ProductPriceSchemeType.Procurement"
          >
            <div class="ml10 mr10">-</div>
            <lxm-datepicker
              class="dialog-input-fixed-width-1"
              for="validTo"
              [min]="nextDay"
            ></lxm-datepicker>
          </ng-container>
        </div>
        <validation-errors for="validFrom"></validation-errors>
        <validation-errors for="validTo"></validation-errors>
      </form-field>

      <!-- NEW UI -->

      <div class="row mt10 flex-nowrap">
        <div class="col-4 no-padding">
          <form-field
            label="list_price"
            description="list_price"
            for="listPrice"
            labelPosition="top"
            [required]="true"
          >
            <lxm-input
              class="w-full"
              input
              for="listPrice"
              type="price"
              [pricePrecision]="digits.listPrice"
              [currency]="form.value.buyInPriceCurrency"
              (blur)="handleCalculations('listPrice')"
            >
              <validation-errors
                for="listPrice"
                [formGroup]="form"
              ></validation-errors>
            </lxm-input>
            <!-- <span below>
              {{form.get('listPrice').value}}
            </span> -->
          </form-field>
        </div>

        <div class="col-4 no-padding">
          <form-field
            label="discount"
            description="discount"
            for="discount"
            labelPosition="top"
          >
            <lxm-input
              class="f-1"
              input
              for="discount"
              type="percent"
              [digits]="digits.discount"
              (blur)="handleCalculations('discount')"
            >
            </lxm-input>
            <!-- <span below>
                {{form.get('discount').value}}
              </span> -->
          </form-field>
        </div>

        <div class="col-4 no-padding">
          <form-field
            label="buy_in_price"
            description="buy_in_price"
            for="buyInPrice"
            labelPosition="top"
            [required]="true"
          >
            <lxm-input
              class="w-full"
              input
              for="buyInPrice"
              type="price"
              [pricePrecision]="digits.buyInPrice"
              [currency]="form.value.buyInPriceCurrency"
              (blur)="handleCalculations('buyInPrice')"
            >
              <validation-errors
                for="buyInPrice"
                [formGroup]="form"
              ></validation-errors>
            </lxm-input>
            <!-- <span below>
              {{form.get('buyInPrice').value}}
            </span> -->
          </form-field>
        </div>
      </div>

      <div class="row flex-nowrap">
        <div class="col-4 no-padding">
          <form-field
            label="vat"
            description="vat"
            for="vat"
            labelPosition="top"
            [required]="true"
          >
            <lxm-select
              select
              for="vat"
              labelField="value"
              [searchable]="true"
              [sortByLabel]="true"
              [clearable]="false"
              [options]="vatOptions"
              (change)="handleCalculations('vat')"
            >
              <validation-errors for="vatId" aliasTo="vat"></validation-errors>
            </lxm-select>
          </form-field>
        </div>

        <div class="col-4 no-padding">
          <form-field
            label="{{ (markupMethod == 1 ? 'coverage' : 'margin') }}"
            description="margin"
            for="margin"
            labelPosition="top"
          >
            <lxm-input
              input
              for="margin"
              type="percent"
              [digits]="digits.margin"
              class="f-1"
              (blur)="handleCalculations('margin')"
            >
              <validation-errors for="margin"></validation-errors>
            </lxm-input>
            <!-- <span below>
                {{form.get('margin').value}}
              </span> -->
          </form-field>
        </div>

        <div class="col-4 no-padding">
          <form-field
            label="retail_price"
            description="retail_price"
            for="retailPrices"
            labelPosition="top"
          >
            <currency-price
              input
              class="f-1"
              for="retailPrices"
              [currencyOptions]="salesCurrencies"
              [defaultCurrency]="form.value.buyInPriceCurrency"
              [pricesWithoutVat]="true"
              [vat]="selectedVat"
              (blur)="handleCalculations('retailPrices')"
            >
            </currency-price>
          </form-field>
        </div>
      </div>

      <!-- PRICE GROUPS -->
      <ng-container *ngIf="priceGroups?.length">
        <div class="separator"></div>

        <ng-container
          *ngFor="let group of priceGroupsFormArrayRef?.controls; let i = index"
        >
          <div class="row flex-nowrap" [formGroup]="group">
            <div class="col-4 no-padding">
              <form-field
                label="price_group"
                description="price_group"
                for="priceGroup"
                labelPosition="top"
              >
                <lxm-select
                  select
                  for="priceGroup"
                  labelField="value"
                  [searchable]="true"
                  (change)="onPriceGroupOptionChange($event, i)"
                  [options]="availablePriceGroupOptions"
                >
                </lxm-select>
              </form-field>
            </div>

            <div class="col-4 no-padding">
              <form-field
                label="{{ (markupMethod == 1 ? 'coverage' : 'margin') }}"
                description="margin"
                for="margin"
                labelPosition="top"
              >
                <lxm-input
                  input
                  for="margin"
                  type="percent"
                  [digits]="digits.margin"
                  class="f-1"
                  (blur)="handleCalculations('margin', group)"
                >
                  <validation-errors for="margin"></validation-errors>
                </lxm-input>
              </form-field>
            </div>

            <div class="col-4 no-padding">
              <form-field
                label="retail_price"
                description="retail_price"
                for="retailPrices"
                labelPosition="top"
              >
                <currency-price
                  input
                  class="f-1"
                  for="retailPrices"
                  [currencyOptions]="salesCurrencies"
                  [defaultCurrency]="form.value.buyInPriceCurrency"
                  [pricesWithoutVat]="true"
                  [vat]="selectedVat"
                  (blur)="handleCalculations('retailPrices', group)"
                >
                </currency-price>
              </form-field>
            </div>
          </div>
        </ng-container>

        <div class="row" *ngIf="availablePriceGroupOptions?.length">
          <div class="col-4 no-padding"></div>
          <div class="col-4 no-padding"></div>
          <div class="col-4 no-padding">
            <lxm-button
              label="action.add_price_group"
              icon="plus-dark"
              (click)="addPriceGroup()"
            ></lxm-button>
          </div>
        </div>
      </ng-container>

      <!-- PROCUREMENT -->

      <div
        *ngIf="this.form.get('schemeType').value === ProductPriceSchemeType.Procurement"
      >
        <div class="separator"></div>

        <form-field
          dialog
          label="procurement_fullfillment_time"
          description="procurement_fullfillment_time"
          for="procurementFullfillmentTime"
          type="input-text"
          [readonly]="scheme?.offerId !== null"
        >
          <validation-errors
            for="procurementFullfillmentTime"
          ></validation-errors>
        </form-field>

        <form-field
          dialog
          label="procurement_supply_conditions"
          description="procurement_supply_conditions"
          for="procurementSupplyConditions"
          type="textarea"
          [readonly]="scheme?.offerId !== null"
        >
          <validation-errors
            for="procurementSupplyConditions"
          ></validation-errors>
        </form-field>

        <form-field
          dialog
          label="procurement_notes"
          description="procurement_notes"
          for="procurementNotes"
          type="textarea"
          [readonly]="scheme?.offerId !== null"
        >
          <validation-errors for="procurementNotes"></validation-errors>
        </form-field>
      </div>
    </scope>
  </div>

  <div footer class="justify-between">
    <span class="flex flex-row">
      <lxm-button
        type="orange"
        label="action.save"
        [loading]="saveLoading"
        (click)="save()"
        buttonType="submit"
      >
      </lxm-button>
      <lxm-button
        type="grey"
        label="action.cancel"
        class="ml10"
        (click)="closeDialog()"
      ></lxm-button>
    </span>

    <span class="flex flex-row">
      <lxm-button
        *ngIf="data.scheme"
        type="transparent"
        label="action.delete"
        icon="trash"
        class="ml30"
        (click)="deleteScheme()"
      ></lxm-button>
    </span>
  </div>
</lxm-dialog>
