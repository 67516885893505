import { Pipe, PipeTransform, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { TranslationChangeEvent, LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ClvService } from '../_services';
import { IClvDto } from '../cards/product/product-info/product-info.model';

@Pipe({
    name: 'systemClvName',
    pure: false
})
export class SystemClvNamePipe implements PipeTransform, OnDestroy {

    private _value: string;
    private _lastClv: IClvDto;
    private _lastLabelField: string;

    private _onTranslationChange: Subscription;
    private _onLangChange: Subscription;
    private _onDefaultLangChange: Subscription;

    constructor(
        private _translateService: TranslateService,
        private _clvService: ClvService, 
        private _ref: ChangeDetectorRef) { }

    public updateValue(clv: IClvDto, labelField: string): void {
        this._value = this._clvService.translate(clv, labelField);
        this._ref.markForCheck();
    }

    public transform(clv: IClvDto, labelField?: string) {
        // if we ask another time for the same key, return the last value
        if (clv === this._lastClv && labelField === this._lastLabelField) {
            return this._value;
        }

        // store the key, in case it changes
        this._lastClv = clv;
        this._lastLabelField = labelField;

        // set the value
        this.updateValue(clv, labelField);

        // if there is a subscription to onLangChange, clean it
        this._dispose();

        // subscribe to onTranslationChange event, in case the translations change
        if (!this._onTranslationChange) {
            this._onTranslationChange = this._translateService.onTranslationChange.subscribe((event: TranslationChangeEvent) => {
                if (event.lang === this._translateService.currentLang) {
                    this.updateValue(clv, labelField);
                }
            });
        }

        // subscribe to onLangChange event, in case the language changes
        if (!this._onLangChange) {
            this._onLangChange = this._translateService.onLangChange.subscribe((event: LangChangeEvent) => {
                this.updateValue(clv, labelField);
            });
        }

        // subscribe to onDefaultLangChange event, in case the default language changes
        if (!this._onDefaultLangChange) {
            this._onDefaultLangChange = this._translateService.onDefaultLangChange.subscribe(() => {
                this.updateValue(clv, labelField);
            });
        }

        return this._value;
    }

    public ngOnDestroy(): void {
        this._dispose();
    }

    /**
     * Clean any existing subscription to change events
     */
    private _dispose(): void {
        if (typeof this._onTranslationChange !== 'undefined') {
            this._onTranslationChange.unsubscribe();
            this._onTranslationChange = undefined;
        }
        if (typeof this._onLangChange !== 'undefined') {
            this._onLangChange.unsubscribe();
            this._onLangChange = undefined;
        }
        if (typeof this._onDefaultLangChange !== 'undefined') {
            this._onDefaultLangChange.unsubscribe();
            this._onDefaultLangChange = undefined;
        }
    }
}