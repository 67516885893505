import { Component, Output, ViewChild, ChangeDetectorRef, AfterViewInit, AfterViewChecked } from '@angular/core';
import { TabOptionsComponent } from 'src/app/components';
import { TabOption } from 'src/app/components/_layout/tabs/tab-options/tab-option.model';
import { LxmClvTableComponent } from 'src/app/components/_partials/settings/clv-table/lxm/lxm-clv-table.component';
import { LOCAL_STORAGE_KEY } from 'src/app/config';
import { ActivatedRoute } from '@angular/router';

export enum TabType {
  Clv = 1,
  GpcTree = 2,
  AtcTree = 3
}

export interface TabData {
  type: TabType;
  data: any;
}

@Component({
  selector: "lxm-field-settings-card",
  templateUrl: './lxm-field-settings.card.html',
  styleUrls: ['./lxm-field-settings.card.scss'],
})
export class LxmFieldSettingsCard implements AfterViewInit, AfterViewChecked {

  public TabType = TabType;

  public options: TabOption[] = LXM_TAB_OPTIONS;
  @Output()
  public tabType: TabType;

  @ViewChild(TabOptionsComponent, { static: false })
  private _tabOptions: TabOptionsComponent;

  @ViewChild(LxmClvTableComponent, { static: false })
  private _lmxClvTable: LxmClvTableComponent;

  public initialData: any = null;

  public tabLocalStorageKey = LOCAL_STORAGE_KEY.TAB_FIELD_SETTINGS;

  constructor(private _cd: ChangeDetectorRef, public route: ActivatedRoute) {
    this.initialData = this.route.snapshot?.data?.fieldData;
  }

  public ngAfterViewInit(): void {
    this.updateView();
    this.initialData = null;
  }

  public ngAfterViewChecked(): void {
    this.updateView();
  }

  private updateView(): void {
    if (!this._tabOptions) {
      return;
    }

    const option = this._tabOptions.selectedOption;
    if (!option || !option.data) {
      return;
    }

    switch (option.data.type) {
      case TabType.Clv: {
        if (!this._lmxClvTable || this._lmxClvTable.classificatorId === option.data.data.classificatorId) {
          break;
        }

        this._lmxClvTable.title = option.translationKey;
        this._lmxClvTable.setData(option.data.data);
        break;
      }
      case TabType.GpcTree: {
        break;
      }
    }

    this._cd.detectChanges();
  }

  public onTabSelect(option: TabData) {
    if (!option) {
      return;
    }

    this.tabType = option.type;
    this.updateView();
  }

}


//TODO: Not every tab is classificator. Setup all classificator value types.
const LXM_TAB_OPTIONS: TabOption[] = [
  new TabOption('cards.settings.lxm_settings.action.gpc_category',
    <TabData>{
      type: TabType.GpcTree,
    }),
  new TabOption('cards.settings.lxm_settings.action.atc_category',
    <TabData>{
      type: TabType.AtcTree,
    }),
  // new TabOption("cards.settings.lxm_settings.action.images",
  //   <IClvTabData>{
  //     classificatorId: "",
  //     controller: "systemClvs",
  //     displayedColumns: ["code", "value", "description", "status"],
  //     translationPrefix: "cards.settings.product_management_settings.awards"
  //   }),
  new TabOption('cards.settings.lxm_settings.action.materials',
    <TabData>{
      type: TabType.Clv,
      data: {
        classificatorId: 'EB0B31D1-C547-420E-B4B6-83D516BBCBC3',
        controller: 'systemClvs',
        displayedColumns: ['code', 'value', 'description', 'status'],
        translationPrefix: 'cards.settings.clv.common',
      }
    }),
  new TabOption('cards.settings.lxm_settings.action.deposits',
    <TabData>{
      type: TabType.Clv,
      data: {
        classificatorId: '9B05B8E5-7B4B-4984-A553-423039FF1665',
        controller: 'systemClvs',
        displayedColumns: ['code', 'value', 'description', 'status'],
        translationPrefix: 'cards.settings.clv.common'
      }
    }),
  new TabOption('cards.settings.lxm_settings.action.tare',
    <TabData>{
      type: TabType.Clv,
      data: {
        classificatorId: '4882A2D4-9541-444F-8BCA-2152BC5DC36A',
        controller: 'systemClvs',
        displayedColumns: ['code', 'value', 'description', 'status'],
        translationPrefix: 'cards.settings.clv.common'
      }
    }),
  new TabOption('cards.settings.lxm_settings.action.countries',
    <TabData>{
      type: TabType.Clv,
      data: {
        classificatorId: '',
        controller: 'systemClvs/countries',
        displayedColumns: ['country_iso', 'country_iso3', 'country_name'],
        translationPrefix: 'cards.settings.lxm_settings.countries'
      }
    }),
  new TabOption('cards.settings.lxm_settings.action.wine_character',
    <TabData>{
      type: TabType.Clv,
      data: {
        classificatorId: '2C609E2D-B4D6-4140-A945-8A84B5845D33',
        controller: 'systemClvs',
        displayedColumns: ['code', 'value', 'description', 'status'],
        translationPrefix: 'cards.settings.clv.common'
      }
    }),
  new TabOption('cards.settings.lxm_settings.action.food_suggestion',
    <TabData>{
      type: TabType.Clv,
      data: {
        classificatorId: '5FA4B22E-6A39-4CC5-BFE4-983AB0181048',
        controller: 'systemClvs',
        displayedColumns: ['code', 'value', 'description', 'status'],
        translationPrefix: 'cards.settings.clv.common'
      }
    }),
  new TabOption('cards.settings.lxm_settings.action.grape_varieties',
    <TabData>{
      type: TabType.Clv,
      data: {
        classificatorId: '17C0F89C-C519-41DD-BA6A-DE02FB9CFD69',
        controller: 'systemClvs',
        displayedColumns: ['code', 'value', 'description', 'status'],
        translationPrefix: 'cards.settings.clv.common'
      }
    }),
  new TabOption('cards.settings.lxm_settings.action.medication_dosage_form',
      <TabData>{
        type: TabType.Clv,
        data: {
          classificatorId: '283AA25C-7B95-4362-A9FA-450FE900CB81',
          controller: 'systemClvs',
          displayedColumns: ['code', 'value', 'description', 'status'],
          translationPrefix: 'cards.settings.clv.common'
        }
      })
];
