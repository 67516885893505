import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  AuthenticationService,
  OfferService,
  ProductPricelistService,
} from "src/app/_services";
import { UserAction } from "src/app/enum";
import { DialogHelper, LxmDialog } from "src/app/_helpers";
import { AppState } from "src/app/state/app.state";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { ProductPricelistBaseSchemeDialog } from "src/app/cards/product/product-pricelist/template/base-scheme/base-scheme.dialog";
import { ProductPricelistSupplierSchemeDialog } from "src/app/cards/product/product-pricelist/dialog/supplier-scheme/product-supplier-scheme.dialog";

@Component({
  selector: "receiving-offer-product-supplier-prices-dialog",
  host: { class: "dialog" },
  templateUrl: "./receiving-offer-product-supplier-prices.dialog.html",
  styleUrls: ["./receiving-offer-product-supplier-prices.dialog.scss"],
})
export class ReceivingOfferProductSupplierPricesDialog extends ProductPricelistSupplierSchemeDialog {
  constructor(
    protected _appState: AppState,
    protected _productPricelistService: ProductPricelistService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<ProductPricelistBaseSchemeDialog>,
    private _lxmDialog: LxmDialog,
    private _authService: AuthenticationService,
    _dialogHelper: DialogHelper,
    _translateService: TranslateService,
    private _offerService: OfferService,
  ) {
    super(
      _appState,
      _productPricelistService,
      data,
      _dialogRef,
      _lxmDialog,
      _authService,
      _dialogHelper,
      _translateService,
    );
    this.data.editable =
      this.data.editable &&
      _authService.hasRight([UserAction.ManageReceivedOffers]);
    this.data.vatId = this.data.product.vat?.id;
    this.data.listPrice = this.data.product.listPrice;
    this.data.buyInPrice = this.data.product.salePrice;
    this.data.retailPrice = this.data.product.retailPrice;
    this.data.retailPriceWithoutVat = this.data.product.retailPriceWithoutVat;
  }

  public get productId() {
    return this.data?.product?.productId;
  }

  public get offerProductId() {
    return this.data?.product?.id;
  }

  public get offerId() {
    return this.data?.offerId;
  }

  public get offerCurrencyId() {
    return this.data?.currency?.id;
  }

  public get hasSchemeId() {
    return this.schemeId ? true : false;
  }

  public deleteScheme(): void {
    const assortmentId = "";
    this.deleteSchemeInternal(
      (id) =>
        this._offerService.deleteOfferProductRetailerSupplierPriceScheme(
          this.offerId,
          this.offerProductId,
          assortmentId,
          id,
        ),
      null,
      () => this.closeDialogWithRes(null),
    );
  }

  public save(): void {
    this.saveLoading = true;

    const f = this.form.value;
    const defaultRetailPrices =
      this.data.currency &&
      (this.data.retailPrice || this.data.retailPriceWithoutVat)
        ? [
            {
              currency: this.data.currency,
              price: this.data.retailPrice,
              priceWithoutVat: this.data.retailPriceWithoutVat,
            },
          ]
        : [];

    const req = {
      retailerSupplierId: f.supplier?.id,
      logisticsPartnerId: f.logisticsPartner?.id,
      isCampaign: f.isCampaign || false,
      priceSchemeValidFrom: f.validFrom,
      validTo: f.isCampaign ? f.validTo : null,
      vatId: f.vat?.id || this.data.product.vat?.id,
      listPrice: f.listPrice || this.data.product.listPrice,
      retailPrices: (f.retailPrices || defaultRetailPrices).map((x) => ({
        currencyId: x.currency?.id,
        price: x.price,
        priceWithoutVat: x.priceWithoutVat,
      })),
      buyInPrice: f.buyInPrice || this.data.product.salePrice,
      buyInPriceCurrencyId: this.offerCurrencyId,
      priceGroups: (f.priceGroups || []).map((x) => ({
        priceGroupId: x.priceGroup?.id,
        retailPrices: (x.retailPrices || []).map((p) => ({
          currencyId: p.currency?.id,
          price: p.price,
          priceWithoutVat: p.priceWithoutVat,
        })),
      })),
    };

    if (this.hasSchemeId) {
      this._offerService
        .updateOfferProductRetailerSupplierPriceScheme(
          this.offerId,
          this.offerProductId,
          this.schemeId,
          req,
        )
        .result(
          this.form,
          (res) => {
            this.saveLoading = false;
            this.closeDialogWithRes(res);
          },
          (err) => {
            this.saveLoading = false;
          },
        );
    } else {
      this._offerService
        .createOfferProductRetailerSupplierPriceScheme(
          this.offerId,
          this.offerProductId,
          req,
        )
        .result(
          this.form,
          (res) => {
            this.saveLoading = false;
            this.closeDialogWithRes(res);
          },
          (err) => {
            this.saveLoading = false;
          },
        );
    }
  }

  private closeDialogWithRes(res) {
    const data = res
      ? {
          id: res.priceScheme.id,
          listPrice: res.priceScheme.listPrice,
          buyInPrice: res.priceScheme.buyInPrice,
          retailPrices: res.priceScheme.retailPrices,
          vatId: res.priceScheme.vat?.id,
          logisticsPartnerId: res.priceScheme.logisticsPartner?.id,
        }
      : {};

    this.dialogRef.close(data);
  }
}
