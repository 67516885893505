export enum OfferType {
  MainAssortment = <any>"3ec4d72a-0371-4e81-965a-b9f2740cc95e",
  Campaign = <any>"0da62ac4-604c-45ed-93ab-164ac18aea1c",
  Logistics = <any>"60299e4e-9c0a-4552-ad8e-bfc94ec8cc18",
  PriceChanges = <any>"29cf082f-dc65-447d-bda2-7cb1c5f017d0",
  Procurement = <any>"af54f118-f4ed-4dc1-a34a-3a4037b90f1c"
}

export enum OfferTypeBasic {
  MainAssortment = <any>OfferType.MainAssortment,
  Campaign = <any>OfferType.Campaign
}

export enum CalendarOfferType {
  MainAssortment = <any>OfferType.MainAssortment,
  Campaign = <any>OfferType.Campaign,
  Procurement = <any>OfferType.Procurement
}

const typeName = 'OfferType';

(OfferTypeBasic as any).__typeName = 'OfferTypeBasic';
(OfferType as any).__typeName = 'OfferType';
(CalendarOfferType as any).__typeName = 'CalendarOfferType';

const _ = 'enums.OfferType.';
const translationKeys = {
  [OfferType.MainAssortment]: _ + 'MainAssortment',
  [OfferType.Campaign]: _ + 'Campaign',
  [OfferType.Logistics]: _ + 'Logistics',
  [OfferType.PriceChanges]: _ + 'PriceChanges',
  [OfferType.Procurement]: _ + 'Procurement'
};

(OfferTypeBasic as any).__altTranslationKeys = translationKeys;
(OfferType as any).__altTranslationKeys = translationKeys;
(CalendarOfferType as any).__altTranslationKeys = translationKeys;