export enum ProductRetailerChangesState {
    UpToDate = 0,
    EanChanged = 1,
    NameChanged = 2,
    PackagingChanged = 3,
    ProductCardChanged = 4,
    MultipleChanges = 5,
}

(ProductRetailerChangesState as any).__typeName = 'ProductRetailerChangesState';
