import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class AssortmentSchemesService {

  constructor(private _http: HttpClient) {}

  public create(req: any) {
    return this._http.post<any>(`api/assortmentSchemes`, req);
  }

  public save(id: string, req: any) {
    return this._http.post<any>(`api/assortmentSchemes/${id}`, req);
  }

  public getMap(id?: string) {
    if (id) {
      return this._http.get<any>(`api/assortmentSchemes/${id}/map`);
    }

    return this._http.get<any>(`api/assortmentSchemes/current/map`);
  }

  public saveMap(id: string, data: any) {
    return this._http.post<any>(`api/assortmentSchemes/${id}/map`, data);
  }

  public saveTagsMap(id: string, data: any) {
    return this._http.post<any>(`api/assortmentSchemes/${id}/mapTags`, data);
  }

  public getTags() {
    return this._http.get(`api/assortmentSchemes/tags`);
  }
}
