
<div class="tab-content">
  <span class="card-title">
    {{title | translate}}
  </span>
</div>

<div class="clv-table">

  <div class="loading-container" *ngIf="dataSource.loading$ | async">
    <div class="loading">
      {{'global.table.loading' | translate}}
    </div>
  </div>

  <mat-table [dataSource]="dataSource" matSort [@.disabled]="true">

    <!-- General fields -->

    <ng-container matColumnDef="code">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header data-table-filter-text>
          {{translationPrefix + '.table.code' | translate}}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let clv">{{clv.code}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="value">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header data-table-filter-text>
          {{translationPrefix + '.table.value' | translate}}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let clv">
        <ng-container *ngIf="clv.translatedValue">
          {{clv.translatedValue | translatedValue}}
        </ng-container>
        <ng-container *ngIf="!clv.translatedValue">
          {{clv.value}}
        </ng-container>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header data-table-filter-text>
          {{translationPrefix + '.table.description' | translate}}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let clv">{{clv.description}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header data-table-filter-dropdown [options]="statuses">
          {{translationPrefix + '.table.status' | translate}}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let clv">{{ clv.status | translateEnum : 'Status'}}</mat-cell>
    </ng-container>

    <!-- country fields -->


    <ng-container matColumnDef="country_iso">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header data-table-filter-text>
          {{translationPrefix + '.table.lxm_classifier_country_id_A2' | translate}}
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let clv">{{clv.iso}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="country_iso3">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header data-table-filter-text>
          {{translationPrefix + '.table.lxm_classifier_country_id_A3' | translate}}
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let clv">{{clv.iso3}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="country_name">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header data-table-filter-text>
          {{translationPrefix + '.table.lxm_classifier_country_name' | translate}}
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let clv">{{clv.name}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns" class="clv-table-head"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns" class="clv-table-row" (click)="_onRowClick.emit(row)"></mat-row>
  </mat-table>

  <data-table-footer 
    (paginator)="hookPaginator($event)">
  </data-table-footer>

</div>

