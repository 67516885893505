import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class ImportsService {

  constructor(private _http: HttpClient) { }

  public list(data?: any) {
    return this._http.get<any>(`api/productsChanges`);
  }

  public getLxmProductsImportData(fileId: string, uniqueCode?: number) {
    return this._http.get<any>(`api/import/LxmProducts/${fileId}/${uniqueCode}`);
  }

  public getLxmPricesImportData(fileId: string, uniqueCode?: number) {
    return this._http.get<any>(`api/import/LxmPrices/${fileId}/${uniqueCode}`);
  }

  public getLxmFruitsVegetablesOfferProductsImportData(fileId: string, uniqueCode?: number) {
    return this._http.get<any>(`api/import/LxmFruitsVegetablesOfferProducts/${fileId}/${uniqueCode}`);
  }

  public getLxmOfferProductsImportData(fileId: string, uniqueCode?: number) {
    return this._http.get<any>(`api/import/LxmOfferProducts/${fileId}/${uniqueCode}`);
  }

  public getCustomImportData(fileId: string, uniqueCode?: number) {
    return this._http.get<any>(`api/import/Custom/${fileId}/${uniqueCode}`);
  }

  public importProductCard(data) {
    return this._http.post<any>(`api/import/LxmProducts`, data);
  }

  public importPricelist(data) {
    return this._http.post<any>(`api/import/LxmPrices`, data);
  }

  public importOfferProducts(data) {
    return this._http.post<any>(`api/import/LxmOfferProducts`, data);
  }

  public importFruitsVegetablesOfferProducts(data) {
    return this._http.post<any>(`api/import/LxmFruitsVegetablesOfferProducts`, data);
  }

  public importCustom(data) {
    return this._http.post<any>(`api/import/Custom`, data);
  }
}
