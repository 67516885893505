import { LOCAL_STORAGE_KEY } from 'src/app/config/local-storage-keys';

const getValue = (name: string) => {
    if (name && typeof window !== 'undefined' && window.localStorage) {
      return JSON.parse(window.localStorage.getItem(name)) || null;
    };
    return null;
}

const PAGE_SIZE_OPTIONS_DEFAULT = [20, 40, 60, 80, 100];

export const PAGE_SIZE_OPTIONS = {
    DEFAULT: PAGE_SIZE_OPTIONS_DEFAULT,
    PRODUCTS_LIST: PAGE_SIZE_OPTIONS_DEFAULT,
    MARKET_PRODUCTS_LIST: PAGE_SIZE_OPTIONS_DEFAULT,
    NOTIFICATIONS_LIST: PAGE_SIZE_OPTIONS_DEFAULT,
    OFFERS_LIST : PAGE_SIZE_OPTIONS_DEFAULT,
    ALTERATIONS_LIST: PAGE_SIZE_OPTIONS_DEFAULT,
    CLIENTS_LIST: PAGE_SIZE_OPTIONS_DEFAULT,
    SUPPLIERS_LIST: PAGE_SIZE_OPTIONS_DEFAULT,
    LOCATIONS_LIST: PAGE_SIZE_OPTIONS_DEFAULT,
    LOCATION_OPENING_HOURS: PAGE_SIZE_OPTIONS_DEFAULT,
    ASSORTMENT_GROUPS_LIST: PAGE_SIZE_OPTIONS_DEFAULT,
    ASSORTMENT_MANAGEMENT_LIST: PAGE_SIZE_OPTIONS_DEFAULT,
    PMD_PRODUCTS_LIST: PAGE_SIZE_OPTIONS_DEFAULT,
    PMD_DECISIONS_LIST: PAGE_SIZE_OPTIONS_DEFAULT,
    PMD_DECISION_PRODUCTS_LIST: PAGE_SIZE_OPTIONS_DEFAULT,
    PMD_DECISION_SELECT_PRODUCTS_LIST: [20, 40, 60],
    ROLES_TABLE: PAGE_SIZE_OPTIONS_DEFAULT,
    USERS_TABLE: PAGE_SIZE_OPTIONS_DEFAULT,
    ORDER_CATALOGUE: PAGE_SIZE_OPTIONS_DEFAULT,
    ORDERS_LIST: PAGE_SIZE_OPTIONS_DEFAULT,
    SALES_ORDERS_LIST: PAGE_SIZE_OPTIONS_DEFAULT,
    PAGE_SIZE_PMD_DECISION_SELECT_PROCUREMENT_PRODUCTS_LIST: [10000]
}

export const PAGE_SIZE = {
    DEFAULT: PAGE_SIZE_OPTIONS_DEFAULT[0],
    [LOCAL_STORAGE_KEY.PAGE_SIZE_PRODUCTS_LIST]: getValue(LOCAL_STORAGE_KEY.PAGE_SIZE_PRODUCTS_LIST) || PAGE_SIZE_OPTIONS.PRODUCTS_LIST[0],
    [LOCAL_STORAGE_KEY.PAGE_SIZE_MARKET_PRODUCTS_LIST]: getValue(LOCAL_STORAGE_KEY.PAGE_SIZE_MARKET_PRODUCTS_LIST) || PAGE_SIZE_OPTIONS.MARKET_PRODUCTS_LIST[0],
    [LOCAL_STORAGE_KEY.PAGE_SIZE_OFFERS_LIST]: getValue(LOCAL_STORAGE_KEY.PAGE_SIZE_OFFERS_LIST) || PAGE_SIZE_OPTIONS.OFFERS_LIST[0],
    [LOCAL_STORAGE_KEY.PAGE_SIZE_NOTIFICATIONS_LIST]: getValue(LOCAL_STORAGE_KEY.PAGE_SIZE_NOTIFICATIONS_LIST) || PAGE_SIZE_OPTIONS.NOTIFICATIONS_LIST[0],
    [LOCAL_STORAGE_KEY.PAGE_SIZE_ALTERATIONS_LIST]: getValue(LOCAL_STORAGE_KEY.PAGE_SIZE_ALTERATIONS_LIST) || PAGE_SIZE_OPTIONS.ALTERATIONS_LIST[0],
    [LOCAL_STORAGE_KEY.PAGE_SIZE_CLIENTS_LIST]: getValue(LOCAL_STORAGE_KEY.PAGE_SIZE_CLIENTS_LIST) || PAGE_SIZE_OPTIONS.CLIENTS_LIST[0],
    [LOCAL_STORAGE_KEY.PAGE_SIZE_SUPPLIERS_LIST]: getValue(LOCAL_STORAGE_KEY.PAGE_SIZE_SUPPLIERS_LIST) || PAGE_SIZE_OPTIONS.SUPPLIERS_LIST[0],
    [LOCAL_STORAGE_KEY.PAGE_SIZE_LOCATIONS_LIST]: getValue(LOCAL_STORAGE_KEY.PAGE_SIZE_LOCATIONS_LIST) || PAGE_SIZE_OPTIONS.LOCATIONS_LIST[0],
    [LOCAL_STORAGE_KEY.PAGE_SIZE_LOCATION_OPENING_HOURS]: getValue(LOCAL_STORAGE_KEY.PAGE_SIZE_LOCATION_OPENING_HOURS) || PAGE_SIZE_OPTIONS.LOCATION_OPENING_HOURS[0],
    [LOCAL_STORAGE_KEY.PAGE_SIZE_ASSORTMENT_GROUPS_LIST]: getValue(LOCAL_STORAGE_KEY.PAGE_SIZE_ASSORTMENT_GROUPS_LIST) || PAGE_SIZE_OPTIONS.ASSORTMENT_GROUPS_LIST[0],
    [LOCAL_STORAGE_KEY.PAGE_SIZE_ASSORTMENT_STATUS_LIST]: getValue(LOCAL_STORAGE_KEY.PAGE_SIZE_ASSORTMENT_STATUS_LIST) || PAGE_SIZE_OPTIONS.ASSORTMENT_MANAGEMENT_LIST[0],
    [LOCAL_STORAGE_KEY.PAGE_SIZE_PMD_PRODUCTS_LIST]: getValue(LOCAL_STORAGE_KEY.PAGE_SIZE_PMD_PRODUCTS_LIST) || PAGE_SIZE_OPTIONS.PMD_PRODUCTS_LIST[0],
    [LOCAL_STORAGE_KEY.PAGE_SIZE_PMD_DECISIONS_LIST]: getValue(LOCAL_STORAGE_KEY.PAGE_SIZE_PMD_DECISIONS_LIST) || PAGE_SIZE_OPTIONS.PMD_DECISIONS_LIST[0],
    [LOCAL_STORAGE_KEY.PAGE_SIZE_PMD_DECISION_PRODUCTS_LIST]: getValue(LOCAL_STORAGE_KEY.PAGE_SIZE_PMD_DECISION_PRODUCTS_LIST) || PAGE_SIZE_OPTIONS.PMD_DECISION_PRODUCTS_LIST[0],
    [LOCAL_STORAGE_KEY.PAGE_SIZE_PMD_DECISION_SELECT_PRODUCTS_LIST]: getValue(LOCAL_STORAGE_KEY.PAGE_SIZE_PMD_DECISION_SELECT_PRODUCTS_LIST) || PAGE_SIZE_OPTIONS.PMD_DECISION_SELECT_PRODUCTS_LIST[0],
    [LOCAL_STORAGE_KEY.PAGE_SIZE_PMD_DECISION_SELECT_PROCUREMENT_PRODUCTS_LIST]: PAGE_SIZE_OPTIONS.PAGE_SIZE_PMD_DECISION_SELECT_PROCUREMENT_PRODUCTS_LIST[0],
    [LOCAL_STORAGE_KEY.PAGE_SIZE_ROLES_TABLE]: getValue(LOCAL_STORAGE_KEY.PAGE_SIZE_ROLES_TABLE) || PAGE_SIZE_OPTIONS.ROLES_TABLE[0],
    [LOCAL_STORAGE_KEY.PAGE_SIZE_USERS_TABLE]: getValue(LOCAL_STORAGE_KEY.PAGE_SIZE_USERS_TABLE) || PAGE_SIZE_OPTIONS.USERS_TABLE[0],
    [LOCAL_STORAGE_KEY.PAGE_SIZE_ORDER_CATALOGUE]: getValue(LOCAL_STORAGE_KEY.PAGE_SIZE_ORDER_CATALOGUE) || 60,
    [LOCAL_STORAGE_KEY.PAGE_SIZE_ORDERS_LIST]: getValue(LOCAL_STORAGE_KEY.PAGE_SIZE_ORDERS_LIST) || PAGE_SIZE_OPTIONS.ORDERS_LIST[0],
    [LOCAL_STORAGE_KEY.PAGE_SIZE_SALES_ORDERS_LIST]: getValue(LOCAL_STORAGE_KEY.PAGE_SIZE_SALES_ORDERS_LIST) || PAGE_SIZE_OPTIONS.SALES_ORDERS_LIST[0],
}