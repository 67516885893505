<lxm-dialog [dialogRef]="dialogRef" [formGroup]="form">
    <span header-title>
        {{(edit ? 
            (ruleId ? 'cards.clients.expiry_rules.dialog.title_edit' : 'cards.clients.expiry_rules.dialog.title_add') 
            : 'cards.clients.expiry_rules.dialog.title_passive')  
            | translate}}
    </span>

    <div body style="padding-bottom: 40px;">

      <form-field dialog [readonly]="!edit" label="cards.clients.expiry_rules.dialog.label.order_nr" for="orderNr" type="input-number">
        <validation-errors for="orderNr"></validation-errors>
      </form-field>

      <form-field dialog label="cards.clients.expiry_rules.dialog.label.description" [readonly]="!edit" for="description" type="textarea">
          <validation-errors for="description"></validation-errors>
      </form-field>

      <form-field dialog label="cards.clients.expiry_rules.dialog.label.expiry_in_days">
          <div any class="flex expiry-condition">
            <div class="expiry-condition__col-operator">
              <lxm-select for="operator"
                  [readonly]="!edit"
                  [clearable]="false"
                  [options]="operatorOptions"
                  value="id">
              </lxm-select>
            </div>
            <div class="expiry-condition__col-value1">
              <lxm-input for="conditionValue1" [readonly]="!edit"></lxm-input>
            </div>
            <ng-container *ngIf="form.value.operator == ClientExpiryRuleOperator.Between || form.value.operator == ClientExpiryRuleOperator.NotBetween">
              <span class="ml10 mr10">-</span>
              <div class="expiry-condition__col-value2">
                <lxm-input for="conditionValue2" [readonly]="!edit"></lxm-input>
              </div>
            </ng-container>
          </div>
          <validation-errors for="operator"></validation-errors>
      </form-field>

      <form-field dialog label="cards.clients.expiry_rules.dialog.label.type">
        <lxm-select select class="dialog-input-fixed-width-1"
            [readonly]="!edit"
            [options]="typeOptions"
            [clearable]="false"
            for="type" 
            value="id">
        </lxm-select>
        <validation-errors for="status"></validation-errors>
      </form-field>

      <form-field dialog label="cards.clients.expiry_rules.dialog.label.percent" *ngIf="form.value.type == ClientExpiryRuleType.Percent"
          for="percent">
          <combined-input any for="percent" [readonly]="!edit" inputType="text" class="dialog-input-fixed-width-1">
              <span suffix>%</span>
              <validation-errors for="percent"></validation-errors>
          </combined-input>
      </form-field>

      <form-field dialog label="cards.clients.expiry_rules.dialog.label.value" *ngIf="form.value.type == ClientExpiryRuleType.Value"
        for="value">
        <lxm-input text for="value" [readonly]="!edit" class="w-full" class="dialog-input-fixed-width-1"></lxm-input>
        <validation-errors for="value"></validation-errors>
      </form-field>

      <form-field dialog label="cards.clients.expiry_rules.dialog.label.status">
          <lxm-select select
            class="dialog-input-fixed-width-1"
            [readonly]="!edit"
            [options]="statusOptions"
            [clearable]="false"
            for="status" 
            value="id">
          </lxm-select>
          <validation-errors for="status"></validation-errors>
      </form-field>

    </div>

    <div footer class="justify-between">

      <div class="flex flex-row">
        <lxm-button 
            *ngIf="edit"
            type="primary"
            label="action.save"
            (click)="save()"
            [loading]="saveLoading"
            buttonType="submit">
        </lxm-button>
        <lxm-button type="secondary"
                    class="ml10"
                    label="action.close"
                    (click)="closeDialog()">
        </lxm-button>
      </div>

      <div>
        <lxm-button *ngIf="ruleId && edit"
          type="transparent" 
          label="action.delete" 
          [loading]="deleteLoading"
          icon="trash" 
          class="ml30" 
          (click)="deleteRule()">
        </lxm-button>
      </div>

    </div>

</lxm-dialog>
