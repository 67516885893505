import { Component, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DataTableService } from "src/app/_services/data-table.service";
import { DataTableComponent } from "src/app/components/data-table/data-table.component";
import { ISelectItem } from "src/app/components/form/select/select-item";
import { IUserAssistantDto } from 'src/app/models';
import { UserStatus, LxmAppModule } from 'src/app/enum';
import { LOCAL_STORAGE_KEY } from "src/app/config";
import { AppState } from 'src/app/state/app.state';
import { UserService } from "src/app/_services";
import { LxmMessage } from "src/app/_helpers";

@Component({
    selector: "users-table",
    styleUrls: [ "./users-table.component.scss" ],
    templateUrl: "./users-table.component.html"
})
export class UsersTableComponent extends DataTableComponent<object, object> {

    public LOCAL_STORAGE_KEY = LOCAL_STORAGE_KEY.PAGE_SIZE_USERS_TABLE;

    displayedColumns = ["fullName", "email", "jobTitle", "phoneNumber", "roles", "status"]
    dataUrl = "user/griddata";
    initialData: IUserRow[];

    public roles: ISelectItem[];
    public locations: ISelectItem[];
    public UserStatus = UserStatus;

    public showLocations: boolean = false;

    public userLocationsRenderCount = 4;

    constructor(
        private _msg: LxmMessage,
        public appState: AppState,
        private _userService: UserService,
        private _route: ActivatedRoute,
        dataService: DataTableService<object, object>,
        cd: ChangeDetectorRef) {

        super(dataService, cd);
        
        const data = this._route.snapshot.data;
        this.initialData = data.initialData;
        this.roles = data.formData.roles;
        this.locations = data.formData.locations;
        this.showLocations = this.appState.hasModule(LxmAppModule.CreateSalesOrders)
            || this.appState.hasModule(LxmAppModule.CreatePurchaseOrders);
        if (this.showLocations){
            this.displayedColumns = ["fullName", "email", "jobTitle", "phoneNumber", "roles", "locations", "status"];
        }
    }

    public toggleAllUserLocations(user) {
        if (user.showAllLocations) {
            user.showAllLocations = false;
        } else {
            user.showAllLocations = true;
        }
    }

    public userLocations(user) {
        const { locations, showAllLocations } = user || {};
        if (locations.length) {
            let userLocations = locations.map(x => x.locationName);
            if (!showAllLocations) {
                userLocations = userLocations.slice(0, this.userLocationsRenderCount);
            }
            return userLocations.join(', ');
        }
        return '-';
    }

    public async copyInviteLinkToClipboard(user) {

        var res: any = await this._userService.getInviteLink(user.id).toPromise();
        
        const el = document.createElement('textarea');
        el.value = res.link;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        this._msg.ok({
            message: 'cards.lexi_market_settings.lxm_settings_user_invite.message.link_copied'
        });
    }
}

export interface IUserRow {
    id: string,
    firstName: string,
    lastName: string,
    fullName: string,
    phoneNumber: string,
    phoneCode: IPhoneCode,
    jobTitle: string,
    role: ISelectItem,
    location: ISelectItem,
    assistants: IUserAssistantDto[];
    lastInvitationSentAt: Date;
}

export interface IPhoneCode {
    id: string,
    value: string
}