<card-expanding>
  <span header-title>{{'cards.settings.product_management_settings._.title' | translate}}</span>

  <div body>
    <tab-options [localStorageKey]="tabLocalStorageKey" [options]="options" (change)="onTabSelect(elTabOptions?.selectedOption)" #elTabOptions></tab-options>
    <product-clv-table [initialData]="initialData" *ngIf="tabType === TabType.Clv"></product-clv-table>
    <product-segment-tree [initialData]="initialData" *ngIf="tabType === TabType.SegmentTree"></product-segment-tree>
    <product-content-languages [initialData]="initialData" *ngIf="tabType === TabType.ContentLanguages"></product-content-languages>
  </div>

</card-expanding>
