<lxm-select select
    (change)="onValueChanged($event?.id)"
    (clear)="onValueChanged(null)"
    class="select w-full"
    labelField="name"
    [searchable]="true"
    [options]="options"
    [formGroup]="form"
    [isTranslated]="isTranslated"
    [labelField]="labelField"
    [value]="value"
    placeholder="components.select.placeholder"
    for="filter">
</lxm-select>