import { Component, EventEmitter, Input, Optional, Output, ViewEncapsulation } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';

@Component({
  selector: 'owner-select',
  templateUrl: './owner-select.component.html',
  styleUrls: ['./owner-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OwnerSelectComponent {

  @Input() public formGroup: FormGroup;
  @Input() public for: string;

  @Output() public change = new EventEmitter();
  @Input() public options = [];
  @Input() public readonly = false;
  @Input() public placeholder: string = 'global.undefined';
  @Input() public underLabel: string = 'cards.offer.base_info.label.owner';

  constructor(
    @Optional() private _controlContainer: ControlContainer
  ) {
    if (this._controlContainer && this._controlContainer.control instanceof FormGroup) {
      this.formGroup = <FormGroup>this._controlContainer.control;
    };
  }

  public onChange(e) {
    this.change.emit(e);
  }

}
