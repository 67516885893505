<form class="new-message" (submit)="sendMessage()" [formGroup]="form" *ngIf="!readonly">
  <div style="flex:1">
    <lxm-textarea input for="message" [placeholder]="'components.chat.label.insert_message' | translate" [minRows]="1" (submit)="sendMessage()"></lxm-textarea>
  </div>
  <lxm-button type="grey" buttonType="submit" icon="send" title="action.add_comment" label="action.send" [loading]="sendingMessage" class="btn-send ml15"></lxm-button>
</form>

<div *ngIf="messages?.length === 0">
  <no-content image="no-comments" text="global.no_comments_added" [inline]="true"></no-content>
</div>

<div class="messages">
  <div class="chat-message" *ngFor="let message of messages" [class.current-user-message]="message.user.id === currentUser.id">
    <div class="message-container">
      <div class="message-header">
        <div class="message-user" *ngIf="message.user && message.user.id !== currentUser.id">
          <span class="profile-dark-icon"></span>
          <span class="ml10">
            {{message.user.name || message.user.email}}
          </span>
        </div>
        <span class="message-time ml10">
          {{message?.createdAt | amTimeAgo}}
        </span>
      </div>
      <display-product 
        class="mb10" 
        *ngIf="hasProductData(message)" 
        [data]="message.product" 
        [isTranslated]="true">
      </display-product>
      <div class="message-content">
        <span>{{ messageText(message) }}</span>
      </div>
    </div>
  </div>
</div>