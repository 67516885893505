import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IOfferCard, IOfferCardFormData, IOffersListFormData } from '../models';
import { CreateOfferDataService, OffersSearchDataService, ProductsRetailerSearchDataService } from '../_services';
import { AppState } from '../state/app.state';
import { ResolveBase } from './resolve-base';
import { PAGE_SIZE } from 'src/app/config/pagination';
import { BuyerOffersListState, OffersListViewType, SellerOffersListState } from '../cards/offers/offers-list.state';
import { LOCAL_STORAGE_KEY } from '../config';
import { LocalStorage } from '../_helpers';

@Injectable()
export class OfferCardResolve extends ResolveBase {
  req(route: ActivatedRouteSnapshot) {
    return this.http.get<IOfferCard>(`api/offers/${route.paramMap.get('offerId')}`);
  }
}

@Injectable()
export class CloneOfferCardResolve implements Resolve<any> {

  constructor(private _http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this._http.get<IOfferCard>(`api/offers/${route.paramMap.get('offerId')}/clone`);
  }
}

@Injectable()
export class OfferCardFromCalendarEntryResolve implements Resolve<any> {

  constructor(private _http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this._http.get<IOfferCard>(`api/offers/createFromCalendarEntry/${route.paramMap.get('calendarEntryId')}`);
  }
}

@Injectable()
export class OfferCardFormDataResolve implements Resolve<any> {
  constructor(private _http: HttpClient, private _createOfferDataService: CreateOfferDataService) { }
  resolve(route: ActivatedRouteSnapshot) {

    var productIds = this._createOfferDataService.productIds;
    this._createOfferDataService.productIds = [];
    
    return this._http.post<IOfferCardFormData>(`api/offers/formData`, {
      productIds: productIds
    });
  }
}

@Injectable()
export class OffersListFormDataResolve implements Resolve<any> {
  constructor(private _http: HttpClient) { }
  resolve(route: ActivatedRouteSnapshot): Observable<IOffersListFormData> {

    var searchKey = route.paramMap.get("searchKey");
    var url = 'api/offers/listFormData';
    if (searchKey) {
      url += `/${searchKey}`;
    }

    return this._http.get(url) as Observable<IOffersListFormData>;
  }
}

@Injectable()
export class RequestedOffersListFormDataResolve implements Resolve<any> {
  constructor(private _http: HttpClient) { }
  resolve(route: ActivatedRouteSnapshot): Observable<IOffersListFormData> {

    var searchKey = route.paramMap.get("searchKey");
    var url = 'api/offers/requests/listFormData';
    if (searchKey) {
      url += `/${searchKey}`;
    }

    return this._http.get(url) as Observable<IOffersListFormData>;
  }
}

@Injectable()
export class ReceivingOffersListDataResolve implements Resolve<any> {

  constructor(private _http: HttpClient, private _listState: BuyerOffersListState, private _state: OffersSearchDataService) { }

  resolve(route: ActivatedRouteSnapshot) {
    let searchData: any = this._state.serializeBuyerSearchData();

    const params = {
      $count: 'true',  
      $top: `${PAGE_SIZE[LOCAL_STORAGE_KEY.PAGE_SIZE_OFFERS_LIST]}`
    }

    const { offerKind } = route.data || {};
    this._listState.listViewType = LocalStorage.getValue(this._listState.listViewTypeKey + offerKind) || OffersListViewType.All;
    this._listState.listFilter = this._listState.getListFilter(this._listState.listViewType);

    const filter = this._listState.listFilter;

    if (filter) {
      Object.assign(params, {
        $filter: filter
      })
    };

    return this._http.post(`api/offers/forRetailer`, searchData, { params: params });
  }
}


@Injectable()
export class SendingOffersListDataResolve implements Resolve<any> {

  constructor(private _http: HttpClient, private _listState: SellerOffersListState, private _state: OffersSearchDataService) { }

  resolve(route: ActivatedRouteSnapshot) {

    const req = this._state.serializeSellerSearchData();

    const params = {
      $count: 'true',  
      $top: `${PAGE_SIZE[LOCAL_STORAGE_KEY.PAGE_SIZE_OFFERS_LIST]}`
    }

    const { offerKind } = route.data || {};
    this._listState.listViewType = LocalStorage.getValue(this._listState.listViewTypeKey + offerKind) || OffersListViewType.All;
    this._listState.listFilter = this._listState.getListFilter(this._listState.listViewType);

    const filter = this._listState.listFilter;

    if (filter) {
      Object.assign(params, {
        $filter: filter
      })
    };

    return this._http.post(`api/offers/forSupplier`, req, { params: params });
  }
}

@Injectable()
export class RequestedOffersListDataResolve implements Resolve<any> {

  constructor(private _http: HttpClient, private _listState: BuyerOffersListState, private _state: OffersSearchDataService ) { }

  resolve(route: ActivatedRouteSnapshot) {


    const req = this._state.serializeBuyerSearchData();

    const params = {
      $count: 'true',  
      $top: `${PAGE_SIZE[LOCAL_STORAGE_KEY.PAGE_SIZE_OFFERS_LIST]}`
    }

    const { offerKind } = route.data || {};
    this._listState.listViewType = LocalStorage.getValue(this._listState.listViewTypeKey + offerKind) || OffersListViewType.All;
    this._listState.listFilter = this._listState.getListFilter(this._listState.listViewType);

    const filter = this._listState.listFilter;

    if (filter) {
      Object.assign(params, {
        $filter: filter
      })
    };

    return this._http.post(`api/offers/requestedOffers`, req, { params: params });
  }
}

// @Injectable()
// export class OffersListCachedFilterDataResolve implements Resolve<any> {
//   constructor(private _http: HttpClient) { }
//   resolve(route: ActivatedRouteSnapshot): Observable<IOffersListFilterDataDto> {
//     return this._http.get(`api/offers/cachedfilterData/${route.paramMap.get("searchKey")}`) as Observable<any>;
//   }
// }