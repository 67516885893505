export enum NotificationType {
    RetailerAddedOfferComment = 1,
    RetailerCounterOffer = 2,
    CalendarEntryIsComingDue = 3,
    ChangesInActiveProducts = 4,
    SupplierAddedOfferComment = 5,
    RetailerRequestedOffer = 6,
    RetailerRejectedOffer = 7,
    ReleaseNotes = 8,
    RetailerMadeProposalIntoOffer = 9,
    RetailerConfirmedOffer = 10,
    RetailerExpiredOffer = 11,
    Procurement = 12,
    SalesOrderReceived = 13,
    SalesOrderComment = 14,
    SalesOrderRejected = 15,
    SalesOrderForwarded = 16,
    SalesOrderConfirmed = 17,
    PurchaseOrderReceived = 18,
    PurchaseOrderComment = 19,
    PurchaseOrderRejected = 20,
    PurchaseOrderSentForConfirmation = 21,
    PurchaseOrderForwarded = 22,
    PurchaseOrderConfirmed = 23,
    PurchaseOrderCancelled = 24,
    SalesOrderCancelled = 25,
    SalesOrderSentForConfirmation = 26,
    SalesOrderComplete = 27,
    CoopEstAssortmentChanges = 28
}

(NotificationType as any).__typeName = "NotificationType";