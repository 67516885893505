<div class="tab-content">
    <card-section>
        <span header-title>
            {{'cards.settings.product_management_settings.content_languages.title' | translate}}
        </span>
        <div header-actions *requiredRights="[UserAction.ManageClvs]">
            <div class="flex">
              <lxm-button type="beige"
                          class="ml15"
                          icon="plus-dark"
                          label="cards.settings.product_management_settings.content_languages.action.add_language"
                          (click)="openDialog()">
              </lxm-button>
            </div>
        </div>
    </card-section>
</div>

<div class="clv-table">

  <div class="loading-container" *ngIf="dataSource.loading$ | async">
    <div class="loading">
      {{'global.table.loading' | translate}}
    </div>
  </div>

  <mat-table [dataSource]="dataSource" matSort [@.disabled]="true">

    <ng-container matColumnDef="twoLetterIso">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header data-table-filter-text>
          {{'cards.settings.product_management_settings.content_languages.label.twoLetterIso' | translate}}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let lang">
        {{lang.twoLetterIso | formValue}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header data-table-filter-text>
          {{'cards.settings.product_management_settings.content_languages.label.title' | translate}}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let lang">
        {{lang.title | formValue}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="isRequired">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header data-table-filter-dropdown [valueType]="FilterValueType.Literal" [options]="trueFalse">
          {{'cards.settings.product_management_settings.content_languages.label.is_required' | translate}}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let lang">
        {{lang.isRequired | translateEnum : 'Bool'}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header data-table-filter-dropdown [options]="statuses">
          {{'cards.settings.product_management_settings.content_languages.label.status' | translate}}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let lang">
        {{lang.status | translateEnum : 'Status'}}
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns" class="clv-table-head"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns" class="clv-table-row" (click)="openDialog(row)"></mat-row>
  </mat-table>

  <data-table-footer 
    (paginator)="hookPaginator($event)">
  </data-table-footer>

</div>
