import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IUserProfileCard } from '../models';

@Injectable()
export class UserProfileCardResolve implements Resolve<any> {

  constructor(private _http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot): Observable<IUserProfileCard> {
    return this._http.get(`api/user/profileInfo`) as Observable<IUserProfileCard>;
  }
}


@Injectable()
export class ActivateAccountCardResolve implements Resolve<any> {

  constructor(private _http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot): Observable<IUserProfileCard> {
    return this._http.get(`api/user/activate`) as Observable<IUserProfileCard>;
  }
}