import { Component, Input, HostBinding, ViewEncapsulation, Output, EventEmitter } from "@angular/core";
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: "dropdown-button",
  templateUrl: "./dropdown-button.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./dropdown-button.component.scss']
})
export class DropdownButtonComponent {

  @Input() public disabled = false;
  @Input() public readonly = false;
  @Input() public loading = false;

  @Input() public type: string = 'transparent';
  @Input() public icon: string = 'additional-menu';
  @Input() public iconPosition: string = 'left';
  @Input() public label: string;
  @Input() public title: string;
  @Input() public padding: string = '8px 12px';
  @Input() public active = false;
  @Input() public buttonType = 'button';

  public showOptionsList = false;

  public toggleOptionsList(toSet = !this.showOptionsList) {
    this.showOptionsList = toSet;
    // console.log(this.showOptionsList);
  }

}
