<div class="section-expanding-container" [class.expanded]="expanded">

  <section class="section-expanding-header" (click)="toggleExpand()" [class.w-full]="headerFullWidth">
    <span class="section-expanding-toggle required-{{required}}">
      <ng-content select="[header-title]"></ng-content>
      <toggle-arrow class="ml12" [toggled]="expanded"></toggle-arrow>
    </span>
    <span class="section-header-actions-wrapper" (click)="$event.stopPropagation();">
      <ng-content select="[header-actions]" *ngIf="!hideWhenExpanded || expanded"></ng-content>
    </span>
  </section>

  <expander class="section-expanding-content" [trigger]="expanded" [speed]="speed">
    <ng-content select="[body]"></ng-content>
  </expander>

</div>
