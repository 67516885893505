import { Input, OnInit, Directive, Injector, OnDestroy, Output, EventEmitter, HostBinding, Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ProductService, ProductValidity, SignalRService } from 'src/app/_services';
import { ActivatedRoute } from '@angular/router';
import { ICountryTranslated, IProductCard, IProductCardFormData, IProductFieldSettingsDto } from './product-info/product-info.model';
import { Observable, Subscription } from 'rxjs';
import { DialogHelper, LocalStorage, LxmMessage } from 'src/app/_helpers';
import 'src/app/ext/observable-result';
import { IUser } from 'src/app/models';
import { HubConnection } from '@microsoft/signalr';
import { ClvType, ProductField } from 'src/app/enum';
import { LOCAL_STORAGE_KEY } from 'src/app/config';
import { formUtil } from 'src/app/util/form-util';

@Directive()
export abstract class ProductCardBase<T> implements OnInit, OnDestroy {

  private __hubConnection: HubConnection;
  private __signalRSubscription: Subscription;

  protected _snapshot: T;
  protected _productCard: IProductCard;
  protected _formData: IProductCardFormData;
  protected _productService: ProductService;
  protected _productValidity: ProductValidity;
  private _message: LxmMessage;
  private _dialogHelper: DialogHelper;
  private _edit = false;

  public ClvType = ClvType;

  public productField = ProductField;
  public isOwnProduct: boolean;

  public isExpanded = true;

  @Input() public isExpandedKey: string;
  @Input() public productId: string;

  @Output() public editing: EventEmitter<any> = new EventEmitter();

  public get countryOptions(): ICountryTranslated[] { return this._formData?.countries || [] };
  public get productFieldSettings(): IProductFieldSettingsDto[] { return this._productCard?.productFieldSettings || [] };

  public abstract get title();
  protected get defaultData() { return {} }
  protected get successMessage(): string { return null };
  protected _getNormalizedFormData?(data: T): any { return data };
  protected _createFormGroup?(data: T): FormGroup { return formUtil.createFormGroup(this._getNormalizedFormData(data)); };
  protected  _createSaveRequest?(): any { return null };
  protected _saveInternal?(req: any): Observable<any> { return null };

  @HostBinding('class.active')
  public get edit() {
    return this._edit;
  }

  public set edit(edit: boolean) {
    this._edit = edit;
    if (this.productId) {
      this.editing.emit({ editing: edit, section: this});
    }
  }

  public saveLoading = false;
  public expanded = true;

  public locked = false;
  public lockedBy: IUser;
  public lockedAt: Date;

  public form: FormGroup;

  constructor(injector: Injector, private _cardSection: string, private _changeMethod: string) {

    this._productService = injector.get(ProductService);
    this._productValidity = injector.get(ProductValidity);
    this._message = injector.get(LxmMessage);
    this._dialogHelper = injector.get(DialogHelper)
    const route = injector.get(ActivatedRoute);
    const signalRService = injector.get(SignalRService);

    if (route.snapshot.paramMap.get('productId')) { 
      this._productCard = route.snapshot.data.productCard as IProductCard;
      this.isOwnProduct = this._productCard.isOwnProduct;
      this._formData = this._productCard.formData;

      if (this._productCard.locks[_cardSection]) {
        this.locked = true;
        this.lockedAt = this._productCard.locks[_cardSection].lockedAt;
        this.lockedBy = this._productCard.locks[_cardSection].lockedBy;
      }

    this.form = this._createFormGroup(
      this._productCard && this._productCard[this._cardSection] ? this._productCard[this._cardSection] as T : this.defaultData as T
    );

    this.__signalRSubscription = signalRService.commonHub.subscribe(x => {
      this.__hubConnection = x;
      if (x === null) {
        return;
      }

      x.on(_changeMethod, (data: T) => this._onSectionChange(data));
      x.on('productSectionLocked', (data: any) => this._onSectionLocked(data));
      x.on('productSectionUnlocked', (data: any) => this._onSectionUnlocked(data));
    });

   }

  };

  private _onSectionChange(data: T): void {
    const normalizedData = this._getNormalizedFormData(data);
    this.form.patchValue(normalizedData);
    this._saveInitialData();
  }

  private _onSectionLocked(data: any): void {
    if (data.section === this._cardSection) {
      this.locked = true;
      this.lockedBy = data.lockedBy;
      this.lockedAt = data.lockedAt;
    }
  }

  private _onSectionUnlocked(data: any): void {
    if (data.section === this._cardSection) {
      this.locked = false;
      this.lockedBy = null;
      this.lockedAt = null;
    }
  }

  public openClassificatorValueDialog(clvType: ClvType, formRef: FormControl, optionsRefName: string, valueAsArray = false, formData: any = this._formData): void {
    this._dialogHelper.openFormNewClassificatorValueDialog(clvType, formRef, optionsRefName, valueAsArray, formData, this);
  }

  public ngOnInit() {
    if (this.isExpandedKey) {
      this.isExpanded = LocalStorage.getValue(this.isExpandedKey) ?? true;
    }
    this._saveInitialData();
  }

  public toggleExpand() {
    this.expanded = !this.expanded;
  }

  public saveLocalExpandedValue(expanded: boolean) {
    if (this.isExpandedKey) {
      LocalStorage.set(this.isExpandedKey, expanded);
    }
  }

  public async toggleEdit() {
    if (!this.expanded) {
      this.toggleExpand();
    }

    if (this.edit) {
      await this._productService.unlockSection(this.productId, this._cardSection).toPromise();
      this._revertToInitialData();
    } else {
      await this._productService.lockSection(this.productId, this._cardSection).toPromise();
    }
    this.edit = !this.edit;
  }

  protected _revertToInitialData() {
    this.form?.reset(this._snapshot);
  }

  protected _saveInitialData() {
    this._snapshot = this.form?.value;
  }

  protected _afterSaved(req?: any, res?: any) {}

  public save() {
    this.saveLoading = true;
    const req = this._createSaveRequest();
    this._saveInternal(req)
      .result(this.form, res => {
        if (this.successMessage) {
          this._message.ok({
            message: this.successMessage
          });
        }
        this._saveInitialData();
        this._revertToInitialData();
        if (this._productValidity.shouldValidate) {
          const retailerIds = this._productValidity.validatedRetailers.map(x => x.id);
          if (retailerIds.length > 0) {
            this._productService.validateRetailChainFieldRules(this.productId, retailerIds)
            .result(null, res => this._productValidity.set(res));
          }
        }

        this._afterSaved(req, res);

        this.edit = false;
        this.saveLoading = false;
      }, error => {
        this.saveLoading = false;
      });
  }
  
  public ngOnDestroy() {

    if (this.__hubConnection) {
      this.__hubConnection.off(this._changeMethod);
      this.__hubConnection.off('productSectionLocked');
      this.__hubConnection.off('productSectionUnlocked');
    }

    if (this.__signalRSubscription) {
      this.__signalRSubscription.unsubscribe();
    }
  }
}

