import { Component, OnInit, ChangeDetectorRef, OnDestroy, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { OfferKind, OfferRequestStatus, ReceivingOfferStatus, SupplierOfferStatus } from '../../../enum';
import { OfferService } from '../../../_services';

@Component({
  selector: 'selected-offers',
  templateUrl: './selected-offers.component.html',
  styleUrls: ['./selected-offers.component.scss']
})
export class SelectedOffersComponent {

  public OfferKind = OfferKind;

  @Input() public translationsPath = 'cards.offers';
  @Input() public kind = OfferKind;

  @Input() public selection: any[] = [];
  @Output() selectionChange = new EventEmitter<any[]>();

  @Input() public currentPageSelected: any = [];
  @Output() currentPageSelectedChange = new EventEmitter<any[]>();

  @Input() public isSelected: Function = (row) => {
    return this.selection.find(x => x.id === row.id) ? true : false;
  };

  @Input() public toggleSelection: Function = (row) => {
    const index = this._getSelectionOfferIndex(row);
    if (index < 0) {
      this.selection.push(row);
      this.currentPageSelected.push(row.id);
    } else {
      this.selection.splice(index, 1);
      const i = this._getCurrentPageOfferIndex(row);
      if (i > -1) {
        this.currentPageSelected.splice(i, 1);
      }
    }
  }

  public emptySelected() {
    this.selection = [];
    this.currentPageSelected = [];

    this.selectionChange.emit([]);
    this.currentPageSelectedChange.emit([]);
  }

  constructor(private _offerService: OfferService) {}

  private _getSelectionOfferIndex(row): number {
    return this.selection.findIndex(x => x.id === row.id);
  }

  private _getCurrentPageOfferIndex(row): number {
    return this.currentPageSelected.findIndex(x => x === row.id);
  }

  public getReceivingOfferStatusProgress(status: ReceivingOfferStatus) {
    return this._offerService.getReceivingOfferStatusProgress(status);
  }

  public getOfferRequestStatusProgress(status: OfferRequestStatus) {
    return this._offerService.getOfferRequestStatusProgress(status);
  }

  public getSendingOfferStatusProgress(status: SupplierOfferStatus) {
    return this._offerService.getSendingOfferStatusProgress(status);
  }
 
}
