import { Component } from '@angular/core';
import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import { Subscription } from 'rxjs';
import { AuthenticationService, SignalRService } from './_services';
import { TitleService } from './_services/title.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Lexi Market';


  private _hubConnection: HubConnection;
  private _signalRSubscription: Subscription;
  private _lastTenantId: string;

  public constructor(
    private _titleService: TitleService,
    private _signalRService: SignalRService,
    private _authService: AuthenticationService
  ) {
    this._titleService.setTitle(this.title);
  }

  public ngOnInit() {

    this._authService.jwt.subscribe(x => {

      if (!x) {
        this._leaveTenant(this._lastTenantId);
        return;
      }

      this._joinTenant(x.CurrentTenantId);
    });
  }

  public ngOnDestroy() {
    this._leaveTenant(this._lastTenantId);
  }

  private _joinTenant(tenantId: string) {
      this._lastTenantId = tenantId;
      this._signalRSubscription = this._signalRService.commonHub.subscribe(connection => {
      this._hubConnection = connection;
      if (this._signalRService.isHubConnected(connection)) {
        connection.send("joinTenant");
      }
    });
  }

  private _leaveTenant(tenantId: string) {
    if (!tenantId) {
      return;
    }

    if (this._signalRService.isHubConnected(this._hubConnection)) {
      this._hubConnection.send('leaveTenant', tenantId);
    }

    if (this._signalRSubscription) {
      this._signalRSubscription.unsubscribe();
    }
  }

}
