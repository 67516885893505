import { Component } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { UserService } from 'src/app/_services/user.service';
import { IUserProfileCard, IUserAssistantDto } from 'src/app/models';
import { IPhoneCode } from 'src/app/components/_partials/lxm-settings/users-table/users-table.component';
import { ActivatedRoute } from '@angular/router';
import { LxmMessage } from 'src/app/_helpers';
import { finalize } from 'rxjs/operators';
import { Console } from 'console';
import { LxmAppModule } from 'src/app/enum';
import { AppState } from 'src/app/state/app.state';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { TitleService } from 'src/app/_services';

@Component({
  selector: "user-settings-card",
  templateUrl: './user-settings.card.html',
  styleUrls: ['./user-settings.card.scss']
})
export class UserSettingsCard {

  public saveProfileLoading = false;
  public updatePasswordLoading = false;

  public phoneCodes: IPhoneCode[];
  public assistantsOptions: IUserAssistantDto[] = [];
  public profileData: FormGroup;

  public changePasswordData = new FormGroup({
    password: new FormControl(),
    newPassword: new FormControl(),
    newPasswordConfirm: new FormControl()
  });

  public emailNotificationsData: FormGroup;
  public smtpSettingsData: FormGroup;

  public canEditSendFromUserEmail = false;
  public passwordChanged = false;
  public updateSmtpSettingsLoading = false;
  public testSmtpSettingsLoading = false;
  public showLocations: boolean = false;
  private pwdMask: string;

  public tabLabels = [
    'cards.user_settings.profile.title',
    'cards.user_settings.profile_change_password.title',
    'cards.user_settings.profile_email_notifications.title',
    'cards.lexi_market_settings.mail_settings.title'
  ]

  constructor(
    public appState: AppState,
    route: ActivatedRoute,
    private _message: LxmMessage,
    private _userService: UserService,
    private _titleService: TitleService
  ) {
    const card = route.snapshot.data.userProfileCard as IUserProfileCard;

    this.phoneCodes = card.formData.phoneCodes;
    this.assistantsOptions = this._formatAssistantsOptions(card.formData.assistants);

    this.profileData = new FormGroup({
      firstName: new FormControl(card.userInfo.firstName),
      lastName: new FormControl(card.userInfo.lastName),
      userName: new FormControl(card.userInfo.userName),
      idCode: new FormControl(card.userInfo.idCode),
      email: new FormControl(card.userInfo.email),
      phoneCode: new FormControl(card.userInfo.phoneCode?.id),
      phoneNumberShort: new FormControl(card.userInfo.phoneNumberShort),
      roles: new FormControl(card.userInfo.roles),
      jobTitle: new FormControl(card.userInfo.jobTitle),
      portfolioDescription: new FormControl(card.userInfo.portfolioDescription),
      assistants: new FormControl(this._formatAssistantsOptions(card.userInfo.assistants)),
      locations: new FormControl(card.userInfo.locations),
    });

    this.emailNotificationsData = new FormGroup({
      isEnabled: new FormControl(!card.mailNotificationSettings.isDisabled),
      shouldReceiveActivitiesSummary: new FormControl(card.mailNotificationSettings.shouldReceiveActivitiesSummary),
      shouldReceiveOffersSummary: new FormControl(card.mailNotificationSettings.shouldReceiveOffersSummary),
      shouldReceiveCalendarEntriesSummary: new FormControl(card.mailNotificationSettings.shouldReceiveCalendarEntriesSummary),
      shouldReceiveProductPortfolioChanges: new FormControl(card.mailNotificationSettings.shouldReceiveProductPortfolioChanges),
      shouldReceiveQuietDayMessage: new FormControl(card.mailNotificationSettings.shouldReceiveQuietDayMessage),
      shouldReceiveUserInboxNotifications: new FormControl(card.mailNotificationSettings.shouldReceiveUserInboxNotifications),
    });

    if (card.smtpSettings && !card.smtpSettings?.withCredentials) {
      this.canEditSendFromUserEmail = false;
      card.smtpSettings.sendFromUserEmail = true;
    }
    
    this.pwdMask = card.smtpSettings?.isPasswordSet ? "*" : null;
    this.smtpSettingsData = new FormGroup({
      useTenantSmtpSettings: new FormControl(card.smtpSettings?.useTenantSmtpSettings),
      useCustomSmtp: new FormControl(card.smtpSettings?.useCustomSmtp),
      host: new FormControl(card.smtpSettings?.host),
      port: new FormControl(card.smtpSettings?.port),
      enableSsl: new FormControl(card.smtpSettings?.enableSsl),
      sendFromUserEmail: new FormControl(card.smtpSettings?.sendFromUserEmail),
      withCredentials: new FormControl(card.smtpSettings?.withCredentials),
      user: new FormControl(card.smtpSettings?.user),
      password: new FormControl(this.pwdMask),
    });

    this.showLocations = this.appState.hasModule(LxmAppModule.CreateSalesOrders)
      || this.appState.hasModule(LxmAppModule.CreatePurchaseOrders);
    
    this.smtpSettingsData.get('withCredentials').valueChanges.subscribe(x => {
      if (x) {
        this.canEditSendFromUserEmail = true;
      }
      else {
        this.smtpSettingsData.get('sendFromUserEmail').setValue(true);
        this.canEditSendFromUserEmail = false;
      }
    })

    this.smtpSettingsData.get('password').valueChanges.subscribe(x => {
      if (x && (this.pwdMask == null || x != this.pwdMask)) {
        this.passwordChanged = true;
      } else {
        this.passwordChanged = false;
      }
    })
  }

  ngOnInit() {
    this.onTabChange(0);
  }

  public onTabChange(tabIndex: number) {
    const title = this.tabLabels[tabIndex];
    if (title) {
      this._titleService.setTitle(title);
    }
  }

  public get userRoles() {
    const roles = this.profileData.get('roles').value;
    if (roles?.length > 0) {
      return roles.join(', ');
    }
    return '-';
  }

  public get userLocations() {
    const locations = this.profileData.get('locations').value;
    if (locations?.length > 0) {
      return locations.join(', ');
    }
    return '-';
  }

  public get sendDailyEmails() {
    return this.emailNotificationsData.get('isEnabled').value;
  }

  public get userSmtpSettingsIsEnabled() {
    return !this.smtpSettingsData.get('useTenantSmtpSettings').value;
  }

  public get customSmtp() {
    return this.smtpSettingsData.get('useCustomSmtp').value;
  }

  public get useCredentials() {
    return this.smtpSettingsData.get('withCredentials').value;
  }

  public saveProfile() {
    this.saveProfileLoading = true;
    const f = this.profileData.value;
    const data: any = {
      firstName: f.firstName,
      lastName: f.lastName,
      userName: f.userName,
      idCode: f.idCode,
      email: f.email,
      phoneCode: f.phoneCode,
      phoneNumberShort: f.phoneNumberShort,
      jobTitle: f.jobTitle,
      assistantIds: f.assistants.map(x => x.id),
      portfolioDescription: f.portfolioDescription 
        ? Object.assign({}, ...f.portfolioDescription.map(o => ({ [o.language]: o.value }))) 
        : {}
    };
    this._userService.saveProfileInfo(data)
      .result(this.profileData, (res) => {
        this._message.ok({
          message: 'cards.user_settings.profile.profile_saved'
        });
        this.saveProfileLoading = false;
      }, (err) => {
        this._message.error({
          message: 'cards.user_settings.profile.profile_save_failed'
        });
        this.saveProfileLoading = false;
      });
  }

  public updatePassword() {
    this.updatePasswordLoading = true;
    this._userService.updatePassword(this.changePasswordData.value)
      .pipe(
        finalize(() => {
          this.updatePasswordLoading = false;
        })
      )
      .result(this.changePasswordData, res => {
        this._message.ok({
          message: 'cards.user_settings.profile.password_changed'
        });
      }, err => {
        this._message.error({
          message: 'cards.user_settings.profile.changing_password_failed'
        });
      });
  }

  public updateMailNotificationSettingsLoading = false;
  public updateMailNotificationSettings() {
    this.updateMailNotificationSettingsLoading = true;
    const f = this.emailNotificationsData.value;

    const req = {
      isDisabled: !f.isEnabled,
      shouldReceiveActivitiesSummary: f.isEnabled ? f.shouldReceiveActivitiesSummary : false,
      shouldReceiveOffersSummary: f.isEnabled ? f.shouldReceiveOffersSummary : false,
      shouldReceiveCalendarEntriesSummary: f.isEnabled ? f.shouldReceiveCalendarEntriesSummary: false,
      shouldReceiveProductPortfolioChanges: f.isEnabled ? f.shouldReceiveProductPortfolioChanges: false,
      shouldReceiveQuietDayMessage: f.isEnabled ? f.shouldReceiveQuietDayMessage : false,
      shouldReceiveUserInboxNotifications: f.shouldReceiveUserInboxNotifications
    }
    
    this._userService.updateUserMailNotificationSettings(req).result(null, res => {
      // console.log(res);
      this.updateMailNotificationSettingsLoading = false;
      this._message.ok({
        message: 'cards.user_settings.profile_email_notifications.settings_save_success'
      });

    }, e => {
      this.updateMailNotificationSettingsLoading = false;
      console.error(e);
      this._message.error({
        message: 'cards.user_settings.profile_email_notifications.settings_save_failed'
      });
    })
  }

  public updateSmtpSettings() {
    this.updateSmtpSettingsLoading = true;
    const smtpReq = this.getSmtpSettings();
    this._userService.updateUserSmtpSettings(smtpReq).result(null, res => {
      this.updateSmtpSettingsLoading = false;
      this._message.ok({
        message: 'cards.lexi_market_settings.mail_settings.save_successful'
      });
    }, e => {
      this.updateSmtpSettingsLoading = false;
      console.error(e);
      this._message.error({
        message: 'cards.lexi_market_settings.mail_settings.save_failed'
      });
    })
  }
  
  public test() {
    this.testSmtpSettingsLoading = true
    const smtpTestReq = this.getSmtpSettings();

    this._userService.testSmtpSettings(smtpTestReq)
      .pipe(
        finalize(() => {
          this.testSmtpSettingsLoading = false;
        }
      ))
      .result(this.smtpSettingsData, () => {
        this._message.ok({
          message: 'cards.lexi_market_settings.mail_settings.test_mail_sent_successful'
        });
      }, () => {
        this._message.error({
          message: 'cards.lexi_market_settings.mail_settings.sending_test_mail_failed'
        });
      });
  }

  private getSmtpSettings(){
    const f = this.smtpSettingsData.value;

    const smtpReq = {
      useTenantSmtpSettings: f.useTenantSmtpSettings,
      useCustomSmtp: !f.useTenantSmtpSettings ? f.useCustomSmtp : false,
      host: f.host,
      port: f.port,
      enableSsl: f.enableSsl,
      sendFromUserEmail: f.sendFromUserEmail,
      withCredentials: f.withCredentials,
      user: f.user,
      password: this.passwordChanged ? f.password : null,
      isPasswordSet: false
    }
    return smtpReq;
  }

  private _formatAssistantsOptions(options: any[]) {
    if (!options) {
      return [];
    }
    return options.map(x => Object.assign({}, x, { label: x.fullName ? `${x.fullName} (${x.email})` : x.email }));
  }
}
