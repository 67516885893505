import { Component, Output, EventEmitter, Input, OnInit, Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ProductsSupplierSearchDataService } from 'src/app/_services';
import { asArray, SupplierProductAssortmentStatus, IEnumValue, ProductField, LxmAppModule, ProductPriceSchemeType, asObject } from 'src/app/enum';
import { AppState } from 'src/app/state/app.state';
import { IProductsSearchFormData, IProductListFilterDataDto, ICountryTranslated, ISegmentTreeOption, IClvDto, IRetailChain } from 'src/app/cards/product/product-info/product-info.model';
import { ActivatedRoute } from '@angular/router';
import { LocalStorage } from 'src/app/_helpers';
import { Subscription } from 'rxjs';
import { ProductsListState } from 'src/app/cards/products/products-list.state';

@Component({
  selector: 'product-search-supplier-card',
  templateUrl: './product-search-supplier.html',
  host: { 'class': 'product-search-card' },
  styleUrls: ['./product-search-supplier.scss']
})
export class SupplierProductSearchCard implements OnInit {

  public LxmAppModule = LxmAppModule;

  public formData: IProductsSearchFormData;

  @Output()
  public search = new EventEmitter();

  @Input()
  public isOpen = false;

  @Input()
  private localStorageKey: string;

  public statuses: IEnumValue[];

  public countryOptions: ICountryTranslated[];
  public nutritionalClaimsOptions: IEnumValue[];
  public segmentOptions: ISegmentTreeOption[];
  public tagsOptions: IClvDto[];
  public productPriceSchemeType = ProductPriceSchemeType;
  public schemeTypeOptions = [
    asObject(ProductPriceSchemeType, ProductPriceSchemeType.Main),
    asObject(ProductPriceSchemeType, ProductPriceSchemeType.Campaign),
    asObject(ProductPriceSchemeType, ProductPriceSchemeType.Procurement)
  ];
  public clientsOptions: IRetailChain[];
  public reailersOptions: IRetailChain[];

  public searchOperatorOptions = [
    { name: 'enums.AndOr.And', id: 1 }, 
    { name: 'enums.AndOr.Or', id: 2 }
  ];

  public searchProductValidationOptions = [
    { name: 'enums.ProductValidation.NoPictures', id: 1 }, 
    { name: 'enums.ProductValidation.ProductDataInvalidValues', id: 2 }
  ];

  constructor(
    private _state: ProductsListState,
    public appState: AppState,
    private _productsSearchDataService: ProductsSupplierSearchDataService,
    route: ActivatedRoute) {
    this.formData = (route.snapshot.data.formData || route.snapshot.data.productCard.formData) as IProductsSearchFormData;

    this.countryOptions = this.formData.countries;
    this.tagsOptions = this.formData.productTags;
    this.clientsOptions = this.formData.clients;
    this.reailersOptions = this.formData.clients;

    this.statuses = asArray(SupplierProductAssortmentStatus);
    this.nutritionalClaimsOptions = asArray(ProductField).filter(x => this.formData.nutritionalClaims?.includes(x.id));
    this.segmentOptions = this.formData.segmentTreeOptions;
  }

  public get searchData(): FormGroup {
    return this._productsSearchDataService.searchData;
  }

  public get productPriceSchemeFilterSelected(): boolean {
    return this._productsSearchDataService.productPriceSchemeFilterSelected;
  }

  public get showRetailersForProductValidation(): boolean {
    return this._productsSearchDataService.showRetailersForProductValidation;
  }

  public get isLoading() {
    return this._state.isSearchLoading;
  }

  public toggleOpen(isOpen: boolean) {
    this.isOpen = isOpen;
    LocalStorage.set(this.localStorageKey, isOpen);
  }

  public segmentTreeLabel(i: ISegmentTreeOption) {
    return `${'--'.repeat(i.level)} ${i.code} ${i.name}`.trim();
  }

  public disableOption(i: ISegmentTreeOption) {
    return !i.canAssignToProduct;
  }

  public ngOnInit(): void {
    this.submit();
  }

  public submit() {
    const val = this._productsSearchDataService.serializeSearchData();
    this.search.emit(val);
  }

}
