import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class MailSettingsCardResolve implements Resolve<any> {

  constructor(private _http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this._http.get(`api/settings/smtpSettings`) as Observable<any>;
  }
}
