import {
  Input,
  SimpleChanges,
  Directive,
  ChangeDetectorRef,
  EventEmitter,
  Optional,
  Output} from "@angular/core";
import {
  AbstractControl,
  ControlContainer,
  FormArray,
  FormControl,
  FormGroup
} from "@angular/forms";
import {
  calculateDiscount,
  calculateMarkup,
  calculateRetailPriceWithoutVatByBuyInPriceAndMargin
} from "src/app/_helpers/calculate";
import { numberUtil } from "src/app/util/number-util";
import { IClvDto } from "src/app/cards/product/product-info/product-info.model";
import { AppState } from "src/app/state/app.state";
import { ICurrency } from "src/app/models";
import { MarkupMethod } from "src/app/enum/markup-mehtod";

@Directive()
export abstract class PriceTableBase {
  public MarkupMethod = MarkupMethod;

  private _arr: FormArray;

  @Input() public formGroup: FormGroup;
  @Input() public for: string;
  @Input() public readonly = false;
  @Input() public currencies: ICurrency[];
  // @Input() public currencyOptions: IClvDto[];
  @Input() public defaultCurrency: IClvDto;
  @Input() public pricesWithoutVat = true;
  @Input() public digits: number = 2;
  @Input() public markupMethod = MarkupMethod.Coverage;
  @Input() public isBuyInPriceChangeable = false;

  @Output() blur: EventEmitter<any> = new EventEmitter();

  public fgInternal = new FormGroup({});

  constructor(
    public appState: AppState,
    public _cd: ChangeDetectorRef,
    @Optional() private _controlContainer: ControlContainer
  ) {
    if (
      this._controlContainer &&
      this._controlContainer.control instanceof FormGroup
    ) {
      this.formGroup = <FormGroup>this._controlContainer.control;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["currencies"] && !changes["currencies"].firstChange) {
      this.updateRows();
    }
    this._init();
  }

  ngAfterViewInit() {
    this.bindCalculations();
  }

  ngAfterContentChecked(): void {
    this._cd.detectChanges();
  }

  public get formArray() {
    return this._arr;
  }

  public get selectedVat() {
    const selectedVat = this.formGroup.get("vat").value;
    const vat = numberUtil.toNumber(selectedVat?.numericValue) || 0;
    return vat;
  }

  public get buyInPrice() {
    return numberUtil.toNumber(this.formGroup.get("buyInPrice").value);
  }

  public get buyInPriceCurrency() {
    return this.formGroup.get("buyInPriceCurrency").value;
  }

  public get currencyIds() {
    return this.currencies.map(x => x.id);
  }

  protected updateRows() {}

  protected createRow(x) {
    return this.createRetailPriceFormGroup(x);
  }

  public addRow(data = null) {
    let row = this.createRow(data);
    this._arr.insert(this._arr.length, row);
  }

  public removeRow(index) {
    let row = this._arr.at(index);
    if (row.value.isNew) {
      this._arr.removeAt(index);
    } else {
      row.get("deleted").setValue(!row.value.isDeleted);
    }
  }

  protected getSetRefValueFn(rows) {
    return rows?.filter(r => !r.isDeleted);
  }

  private _init() {
    if (!this.defaultCurrency) {
      this.defaultCurrency = this.appState.defaultCurrency;
    }

    const ref = this.formGroup.get(this.for);
    const value = ref.value;
    this._arr = new FormArray(value.map(x => this.createRow(x)));

    this.fgInternal.addControl(this.for, this._arr);
    this._bindControls(ref, this._arr);
  }

  public ngOnInit(): void {
    // this._init();
  }

  public createRetailPriceFormGroup(x) {
    const { currency, price, priceWithoutVat, isNew } = x || {};

    const formGroup = new FormGroup({
      margin: new FormControl(this._calcMarginFromRetailPrice(price)),
      currency: new FormControl(currency),
      price: new FormControl(price),
      priceWithoutVat: new FormControl(priceWithoutVat),
      isNew: new FormControl(isNew || x === null),
      isDeleted: new FormControl(false)
    });

    setTimeout(_ => {
      this.formGroup.get("vat").valueChanges.subscribe(x => {
        this.calcRetailPrices(formGroup);
      });
  
      formGroup.get("price").valueChanges.subscribe(x => {
        if (this.selectedVat !== undefined) {
          formGroup
            .get("priceWithoutVat")
            .patchValue(numberUtil.excludeVat(x, this.selectedVat), {
              emitEvent: false
            });
          this.calcMargin(formGroup);
        }
      });
      formGroup.get("priceWithoutVat").valueChanges.subscribe(x => {
        if (this.selectedVat !== undefined) {
          formGroup
            .get("price")
            .patchValue(numberUtil.applyVat(x, this.selectedVat), {
              emitEvent: false
            });
          this.calcMargin(formGroup);
        }
      });
  
      formGroup.get("margin").valueChanges.subscribe(x => {
        this.calcRetailPrices(formGroup);
      });
    });

    return formGroup;
  }

  private _bindControls(ref: AbstractControl, arr: FormArray) {
    let innerChange = false;

    ref.valueChanges.subscribe(value => {
      if (innerChange) {
        innerChange = false;
        return;
      }

      arr.clear({ emitEvent: false });

      for (var row of value) {
        arr.insert(arr.length, this.createRow(row), { emitEvent: false });
      }
    });

    arr.valueChanges.subscribe(rows => {
      innerChange = true;
      if (rows) {
        ref.setValue(this.getSetRefValueFn(rows));
      } else {
        ref.setValue(rows);
      }
    });
  }

  public calcRetailPrices(form: FormGroup = this.formGroup) {
    const vat = this.selectedVat;
    const buyInPrice = this.buyInPrice;
    const margin = numberUtil.toNumber(form.get("margin").value);
    const hasBuyInPriceAndMargin = !isNaN(buyInPrice) && !isNaN(margin);
    const retailPriceWithoutVat = hasBuyInPriceAndMargin
      ? calculateRetailPriceWithoutVatByBuyInPriceAndMargin(
          buyInPrice,
          margin,
          this.markupMethod
        )
      : null;

    const retailPrice = hasBuyInPriceAndMargin
      ? numberUtil.applyVat(retailPriceWithoutVat, vat)
      : null;

    if (!isNaN(retailPrice) && !isNaN(retailPriceWithoutVat)) {
      const priceValue = numberUtil.toNumber(retailPrice);
      const priceWithoutVatValue = numberUtil.toNumber(retailPriceWithoutVat);
      const patch = {
        price: priceValue,
        priceWithoutVat: priceWithoutVatValue
        // margin: this._calcMarginFromRetailPrice(priceValue)
      };

      form.patchValue(patch, { emitEvent: false });
    }
  }

  private _calcMarginFromRetailPrice(retailPrice) {
    const vat = this.selectedVat;
    const buyInPrice = this.buyInPrice;

    if (
      !retailPrice ||
      isNaN(retailPrice) ||
      !buyInPrice ||
      isNaN(buyInPrice)
    ) {
      return null;
    } else {
      const newMargin =
        calculateMarkup(retailPrice, buyInPrice, vat, this.markupMethod) * 100;
      return newMargin;
    }
  }

  private _calcMargin(form: FormGroup = this.formGroup) {
    const formValue = form.value;
    const retailPrice = formValue?.price;
    return this._calcMarginFromRetailPrice(retailPrice);
  }

  public calcMargin(form: FormGroup = this.formGroup) {
    const data = {
      margin: this._calcMargin(form)
    };
    form.patchValue(data, { emitEvent: false });
  }

  public calcBuyInPrice() {
    const listPrice = numberUtil.toNumber(
      this.formGroup.get("listPrice").value
    );
    const discountInputValue = this.formGroup.get("discount").value;
    const discount = numberUtil.toNumber(discountInputValue);

    if (!numberUtil.isNumber(listPrice) || !numberUtil.isNumber(discount)) {
      return;
    }

    const newBuyInPrice = listPrice * (1 - discount / 100);

    this.formGroup.patchValue(
      {
        buyInPrice: newBuyInPrice
      },
      {
        emitEvent: false
      }
    );

    this.calcMargin();
  }

  public calcDiscount() {
    const buyInPrice = numberUtil.toNumber(
      this.formGroup.get("buyInPrice").value
    );
    const listPrice = numberUtil.toNumber(
      this.formGroup.get("listPrice").value
    );

    if (
      !numberUtil.isNumber(buyInPrice) ||
      !numberUtil.isNumber(listPrice) ||
      !listPrice
    ) {
      return;
    }

    const newDiscount =
      numberUtil.toNumber(calculateDiscount(listPrice, buyInPrice)) * 100;

    this.formGroup.patchValue(
      {
        discount: numberUtil.round(newDiscount, 2)
      },
      {
        emitEvent: false
      }
    );

    this.calcMargin();
  }

  protected bindCalculations(): void {
    this.calcMargin();
  }
}
