import { Component, ChangeDetectorRef } from "@angular/core";
import { DataTableComponent } from "src/app/components/data-table/data-table.component";
import { UserAction } from "src/app/enum/user-action";
import { TranslateService } from '@ngx-translate/core';
import { DataTableService } from 'src/app/_services';
import { Status } from 'src/app/enum';
import { ActivatedRoute } from '@angular/router';
import { LOCAL_STORAGE_KEY } from "src/app/config";

@Component({
    selector: "roles-table",
    styleUrls: [ "./roles-table.component.scss" ],
    templateUrl: "./roles-table.component.html"
})
export class RolesTableComponent extends DataTableComponent<object, object> {

    public LOCAL_STORAGE_KEY = LOCAL_STORAGE_KEY.PAGE_SIZE_ROLES_TABLE;

    displayedColumns = ["name", "role_user_actions", "status"]
    dataUrl = "roles/griddata";
    initialData: IUserRoleRow[];

    constructor(
      private _route: ActivatedRoute,
      private _translateService: TranslateService,
      dataTableService: DataTableService<object, object>,
      cd: ChangeDetectorRef
    ) {
      super(dataTableService, cd);
      const data = this._route.snapshot.data;
      this.initialData = data.initialData;
    }

    public formatUserActions(userActions: string[]): string {
        return userActions
            .map(x => this._translateService.instant("enums.UserAction." + UserAction[x]))
            .join(", ");
    }
}

export interface IUserRoleRow {
  claimValue: string;
  id: string;
  name: string;
  status: Status
}