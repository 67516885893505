import { Component, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { OfferService, SignalRService, AuthenticationService } from 'src/app/_services';
import { IChatMessage, IOfferCard } from 'src/app/models';
import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import { OfferKind, UserAction } from 'src/app/enum';
import { Subscription } from 'rxjs';

@Component({
  selector: "offer-comments-card",
  templateUrl: './offer-comments.card.html',
  styleUrls: ['./offer-comments.scss']
})
export class OfferCommentsCardComponent implements OnDestroy {

  @Input() public isEditable: boolean;

  private _offerId: string;
  private _hubConnection: HubConnection;
  private _signalRSubscription: Subscription;

  public messages: IChatMessage[];
  public chatId: string;

  public canEdit: boolean;

  constructor(
    private _route: ActivatedRoute,
    private _offerService: OfferService,
    signalRService: SignalRService,
    authService: AuthenticationService,
    private _cd: ChangeDetectorRef) {

    const offerCardData: IOfferCard = this._route.snapshot.data.offerCard;

    this.messages = offerCardData.messages;
    this.chatId = offerCardData.offerDetail.commentsChatId;

    this._route.params.subscribe(params => {
      this._offerId = params['offerId'] || null;

      this._signalRSubscription = signalRService.commonHub.subscribe(connection => {
        this._hubConnection = connection;

        if (connection?.state === HubConnectionState.Connected) {
          connection.send('joinChat', this.chatId);
          connection.on('chatMessage', (data: IChatMessage) => {
            this.messages.unshift(data);
            this._cd.detectChanges();
          });
        }
      });
    });

    this.canEdit = (offerCardData.offerKind == OfferKind.SupplierOffer && authService.hasRight([UserAction.ManageSupplierOffers]))
      || (offerCardData.offerKind == OfferKind.ReceivingOffer && authService.hasRight([UserAction.ManageReceivedOffers, UserAction.ConfirmReceivedOffers]));
  }

  public send = (comment: string) => {
    return this._offerService.comment(this._offerId, comment);
  }

  public ngOnDestroy() {
    
    if (this._hubConnection?.state === HubConnectionState.Connected && this.chatId) {
      this._hubConnection.send('leaveChat', this.chatId);
      this._hubConnection.off('chatMessage');
    }

    if (this._signalRSubscription) {
      this._signalRSubscription.unsubscribe();
    }
  }
}
