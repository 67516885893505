<div
class="selected-offers"
[tooltip]="selectedOffersTemplate"
tooltipApplyClass="stripped"
*ngIf="selection.length > 0"
>
<span class="selected-count">
  {{(selection.length)}}
</span>
</div>

<ng-template #selectedOffersTemplate>
<div class="selected-offers-wrapper">
  <div class="flex flex-row justify-between align-center mb10">
    <span class="flex flex-row align-center">
      <h2>
        {{(translationsPath + '.table.selected_offers') | translate}}
      </h2>
      <span class="ml5">
        ({{selection.length}})
      </span>
    </span>
    <lxm-button
      type="transparent"
      icon="trash"
      padding="8px 0"
      (click)="emptySelected()"
    >
    </lxm-button>
  </div>
  <table class="selected-offers-table">
    <tr class="header-row">
      <th class="select-column"></th>
      <th class="name-column">
        {{(translationsPath + '.table.offer_name') | translate}}
      </th>
      <th class="supplier-column">
        {{(translationsPath + '.table.offer_supplier') | translate}}
      </th>
      <th class="status-column">
        {{(translationsPath + '.table.offer_status') | translate}}
      </th>
    </tr>
    <tr class="offer-row" *ngFor="let selection of selection">
      <td class="select-column">
        <mat-checkbox
          class="selected-checkbox"
          (click)="$event.stopPropagation()"
          (change)="$event ? toggleSelection(selection) : null"
          [checked]="isSelected(selection)"
        >
        </mat-checkbox>
      </td>
      <td class="name-column">
        <a
          class="link flex flex-row"
          href="/offers/{{selection.id}}"
          target="_blank"
        >
          <type-indicator [type]="selection.type"></type-indicator>
          {{selection.name}}
        </a>
      </td>
      <td class="supplier-column">
        {{selection.supplier}}
      </td>
      <td class="status-column">
        <status-indicator [status]="getReceivingOfferStatusProgress(selection.status)" *ngIf="kind == OfferKind.ReceivingOffer"
          [description]="selection.status | translateEnum : 'ReceivingOfferStatus'"></status-indicator>

        <status-indicator [status]="getOfferRequestStatusProgress(selection.status)" *ngIf="kind == OfferKind.OfferRequest"
          [description]="selection.status | translateEnum : 'OfferRequestStatus'"></status-indicator>

        <status-indicator [status]="getSendingOfferStatusProgress(selection.status)" *ngIf="kind == OfferKind.SupplierOffer"
          [description]="selection.status | translateEnum : 'SupplierOfferStatus'"></status-indicator>
      </td>
    </tr>
  </table>
</div>
</ng-template>