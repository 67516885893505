import { Component, Input } from '@angular/core';

@Component({
  selector: 'data-hover',
  templateUrl: './data-hover.component.html',
  styleUrls: ['./data-hover.component.scss']
})
export class DataHoverComponent {

  @Input() public icon: string = 'view';
  @Input() public disabled = false;
  @Input() public background: string;
  @Input() public popperApplyClass: string;
  @Input() public trigger = 'hover';

  public get isClickable() {
    return this.trigger === 'click';
  }

}
