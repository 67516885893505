import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthenticationService } from '../_services';

@Directive({
  selector: '[nonVisitor]'
})
export class NonVisitorDirective {

  constructor(
    private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef,
    private _authService: AuthenticationService) {
  }

  ngOnInit() {
    this._authService.jwt.subscribe(
      (jwt) => {

        this._viewContainer.clear();

        if (jwt && (!jwt.Visitor)) {
          this._viewContainer.createEmbeddedView(this._templateRef);
        }
      }
    );
  }

}


@Directive({
  selector: '[visitor]'
})
export class VisitorDirective {

  constructor(
    private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef,
    private _authService: AuthenticationService) {
  }

  ngOnInit() {
    this._authService.jwt.subscribe(
      (jwt) => {

        this._viewContainer.clear();

        if (jwt && (jwt.Visitor)) {
          this._viewContainer.createEmbeddedView(this._templateRef);
        }
      }
    );
  }

}
