<scope>
  <card-section>
    <!-- <span header-title style="font-size: 24px; font-weight: bold">
      {{'cards.lexi_market_settings.lxm_settings_subscription.title' | translate
      }}
    </span> -->
    <!-- <span header-actions>
      <lxm-button
        type="beige"
        class="ml15"
        icon="save"
        label="action.save"
        [loading]="actionLoading.save"
        (click)="savePackage()"
        *ngIf="!activeTo"
      ></lxm-button>
      <lxm-button
        type="beige"
        class="ml15"
        icon="save"
        label="cards.lexi_market_settings.lxm_settings_subscription.action.reactivate_account"
        [loading]="actionLoading.save"
        (click)="savePackage()"
        *ngIf="activeTo"
      ></lxm-button>
    </span> -->

    <!-- <form-field label="current_plan">
        <div any>
          Lexi.Market {{ currentPlan?.type }}
          <span *ngIf="currentAnnual">
            ({{
            'cards.lexi_market_settings.lxm_settings_subscription.label.annual_subscription'
            | translate }})
          </span>
        </div>
      </form-field> -->

    <!-- <form-field label="subscription_selection">
        <div any class="w-full">
          <div style="margin-bottom: 30px">
            <slide-toggle
              for="annual"
              yes="cards.lexi_market_settings.lxm_settings_subscription.label.annual_subscription"
              no="cards.lexi_market_settings.lxm_settings_subscription.label.annual_subscription"
            ></slide-toggle>
            <small>
              *
              {{'cards.lexi_market_settings.lxm_settings_subscription.label.annual_subscription_disclaimer'
              | translate}}
            </small>
          </div>

  
        </div>
      </form-field> -->

    <scope
      body
      translationsPath="cards.lexi_market_settings.lxm_settings_subscription"
      [formGroup]="form"
    >
      <h3 class="mb-6">Sinu pakett</h3>

      <div class="flex flex-1 flex-wrap gap-5">
        <div class="flex w-[30rem] flex-col gap-4 border border-grey-10 p-8x">
          <div class="flex w-full flex-row flex-wrap gap-10x">
            <div class="flex flex-col gap-5x">
              <span class="field-label">Aktiivne pakett</span>
              <span class="field-value-lg"
                >{{ currentPlan?.type ?? 'Tasuta' }}
                <!-- 
  <span *ngIf="currentAnnual">
            ({{
            'cards.lexi_market_settings.lxm_settings_subscription.label.annual_subscription'
            | translate }})
          </span> -->
              </span>
            </div>

            <div class="flex flex-col gap-5x">
              <span class="field-label">Paketti hind</span>
              <span class="field-value-lg"
                >{{currentPlan?.price?.monthly ?? 0}} €</span
              >
            </div>
          </div>

          <div class="flex flex-1 flex-col gap-5x">
            <div class="field-label">Paketi kasutatavus</div>
            <lxm-progress-bar class="w-full" [progress]="productsActivePercent">
              <span bottomLeft class="progress-bar-text">
                {{(productsActive || 0) + ' ' + ('global.active_products_suffix'
                | translate)}}
                <ng-container *ngIf="productCapacity">
                  / {{productCapacity}}
                </ng-container>
              </span>
            </lxm-progress-bar>

            <ng-container *ngIf="activeTo">
              {{
              'cards.lexi_market_settings.lxm_settings_subscription.label.account_active_to'
              | translate }} {{ activeTo | formDate }}
            </ng-container>
            <div class="flex flex-1 items-center justify-end">
              <lxm-button
                type="grey"
                (click)="onTermsLinkClick()"
                label="cards.lexi_market_settings.lxm_settings_subscription.label.read_terms"
              >
              </lxm-button>
              <!-- <a class="link" [href]="termsLink" target="_blank"
                >{{'cards.lexi_market_settings.lxm_settings_subscription.label.read_terms'
                | translate}}</a
              > -->

              <!-- <ng-container *ngIf="!activeTo">
                <lxm-button
                  type="grey"
                  label=".action.cancel_subscription"
                  (click)="cancelSubscription()"
                ></lxm-button>
              </ng-container> -->
            </div>
          </div>
        </div>

        <div
          class="flex w-[30rem] flex-col justify-between gap-4 border border-grey-10 p-8x"
          *ngIf="currentPlan"
        >
          <div
            class="flex w-full flex-col flex-wrap gap-10x"
            [formGroup]="paymentDetailsFg"
          >
            <div class="flex flex-col gap-5x">
              <span class="field-label">Tellija e-posti aadress</span>

              <ng-container [ngSwitch]="editPaymentDetails">
                <lxm-input
                  for="subscriberEmail"
                  [readonly]="!editPaymentDetails"
                  *ngSwitchCase="true"
                ></lxm-input>
                <a
                  class="field-value w-max"
                  href="mailto:{{paymentDetailsFg.get('subscriberEmail').value}}"
                  *ngSwitchCase="false"
                >
                  {{paymentDetailsFg.get('subscriberEmail').value || '-'}}
                </a>
              </ng-container>
            </div>

            <div class="flex flex-col gap-5x">
              <span class="field-label">Arve e-posti aadress</span>
              <ng-container [ngSwitch]="editPaymentDetails">
                <lxm-input
                  for="billingEmail"
                  [readonly]="!editPaymentDetails"
                  *ngSwitchCase="true"
                ></lxm-input>
                <a
                  class="field-value w-max"
                  href="mailto:{{paymentDetailsFg.get('billingEmail').value}}"
                  *ngSwitchCase="false"
                >
                  {{paymentDetailsFg.get('billingEmail').value || '-'}}
                </a>
              </ng-container>
            </div>
          </div>

          <div
            class="flex w-full justify-end gap-5x"
            [ngSwitch]="editPaymentDetails"
          >
            <ng-container *ngSwitchCase="true">
              <lxm-button
                type="grey"
                label="action.back"
                (click)="toggleEditPaymentDetails()"
              >
              </lxm-button>

              <lxm-button
                type="beige"
                label="action.save"
                icon="save"
                (click)="savePaymentDetails()"
              >
              </lxm-button>
            </ng-container>
            <ng-container *ngSwitchCase="false">
              <lxm-button
                type="grey"
                (click)="toggleEditPaymentDetails()"
                label="Muuda arveldusinfot"
              >
              </lxm-button>
            </ng-container>
          </div>
        </div>
      </div>

      <h3 class="mb-6 mt-6">Vaheta paketti</h3>
      <ng-container [ngSwitch]="packageView">
        <ng-container *ngSwitchCase="SubscriptionPackageView.Details">
          <form
            class="flex w-[34rem] flex-col gap-10x"
            [formGroup]="paymentDetailsFg"
          >
            <p class="text-sm text-grey-100">
              Tincidunt ut ac facilisis eu sem tellus vivamus. A tincidunt metus
              ut turpis diam tortor semper.
            </p>

            <div class="flex flex-col">
              <form-field label="subscriber_email" [required]="true" class="!mr-0">
                <lxm-input
                  for="subscriberEmail"
                  type="email"
                  class="w-full"
                ></lxm-input>
                <validation-errors for="subscriberEmail"></validation-errors>
              </form-field>

              <form-field label="billing_email" [required]="true" class="!m-0">
                <lxm-input
                  for="billingEmail"
                  type="email"
                  class="w-full"
                ></lxm-input>
                <validation-errors for="billingEmail"></validation-errors>
              </form-field>
            </div>

            <div class="flex w-[30rem] grow-0 border border-grey-10 p-8x">
              <div class="flex flex-col gap-5x">
                <span class="field-label">Valitud pakett</span>
                <span class="field-value">{{ pendingPackage?.type }} </span>
              </div>

              <div class="flex flex-col gap-5x">
                <span class="field-label">Paketti hind</span>
                <span class="field-value"
                  >{{pendingPackage?.price?.monthly ?? 0}} €

                  <span class="font-display text-xs font-medium text-grey-70">
                    &nbsp;/&nbsp;kuu
                  </span>
                </span>
              </div>
            </div>

            <div class="flex w-full">
              <lxm-checkbox for="terms" [required]="true">
                <div class="flex">Olen tutvunud ja nõustun&nbsp;</div>
              </lxm-checkbox>
              <a
                class="generic-hover text-sm text-grey-100 underline"
                (click)="onTermsLinkClick($event)"
                >Lepingutingimustega</a
              >
            </div>
            <validation-errors for="terms"></validation-errors>

            <div class="flex w-full">
              <lxm-button
                type="primary"
                label="Taotlen paketi vahetamist"
                (click)="finalizePaymentDetails()"
              ></lxm-button>
              <lxm-button
                type="grey"
                label="action.back"
                class="ml15"
                (click)="packageView = SubscriptionPackageView.Matrix"
              ></lxm-button>
            </div>
          </form>
        </ng-container>

        <ng-container *ngSwitchCase="SubscriptionPackageView.Matrix">
          <table class="packages-table">
            <thead>
              <tr>
                <th class="!border-none"></th>
                <th
                  [attr.colspan]="packages.length"
                  class="border-l border-grey-10 text-center"
                >
                  <div class="flex w-full items-center justify-center gap-5x">
                    <svg-icon
                      key="paidPlanOrangeEuro"
                      fontSize="16px"
                    ></svg-icon>
                    <span> Tasulised paketid </span>
                  </div>
                </th>
              </tr>
              <tr>
                <th class="border-r border-grey-10"></th>
                <th *ngFor="let package of packages" class="col-package">
                  <div class="flex w-full flex-col gap-2x">
                    <span> {{package.type}} </span>
                    <div
                      class="flex w-full items-center justify-center text-center"
                    >
                      <span> {{package.price.monthly | priceValue:0}} </span>
                      <span
                        class="font-display text-xs font-medium text-grey-70"
                      >
                        &nbsp;/&nbsp;kuu
                      </span>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let feature of packageFeatures | keyvalue">
                <td class="font-text text-sm font-normal text-grey-100">
                  {{feature.value.label}}
                </td>
                <td
                  *ngFor="let package of packages"
                  class="col-package border-l border-grey-10 text-center"
                  [ngSwitch]="feature.key === SubscriptionPackageItem.ActiveProductCount"
                >
                  <ng-container *ngSwitchCase="true">
                    {{package.productCount}}
                  </ng-container>

                  <ng-container
                    *ngSwitchDefault
                    [ngSwitch]="package.features.includes(feature.key)"
                  >
                    <svg-icon
                      key="planCheckmark"
                      fontSize="14px"
                      *ngSwitchCase="true"
                    ></svg-icon>
                    <svg-icon
                      key="monochromeX"
                      fontSize="14px"
                      class="text-grey-30"
                      *ngSwitchCase="false"
                    ></svg-icon>
                  </ng-container>
                </td>
              </tr>
              <tr>
                <td class="border-r border-grey-10"></td>
                <td
                  class="col-package"
                  *ngFor="let package of packages"
                  [ngSwitch]="isPackageActive(package.id)"
                >
                  <div
                    class="package-active gap-4x"
                    *ngSwitchCase="true"
                    (click)="currentPlan = null"
                  >
                    <svg-icon key="planCheckmark" fontSize="14px"></svg-icon>
                    <span> Valitud </span>
                  </div>

                  <div
                    class="flex items-center justify-center"
                    *ngSwitchCase="false"
                  >
                    <lxm-button
                      type="grey"
                      label="Vali pakett"
                      (click)="choosePackageAndSetPaymentDetails(package.id)"
                    ></lxm-button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-container>
      </ng-container>

      <!-- <form-field label="subscription_usage">
   
      </form-field> -->

      <!-- <form-field>
        <div any>
          <a class="link" [href]="termsLink" target="_blank"
            >{{'cards.lexi_market_settings.lxm_settings_subscription.label.read_terms'
            | translate}}</a
          >
        </div>
      </form-field> -->
    </scope>
  </card-section>
</scope>
