import { Injectable } from "@angular/core";
import { OverlayViewComponent } from "../components/_layout/overlay-view/overlay-view.component";
import { DomComponentService } from "./dom-component.service";

@Injectable({ providedIn: "root" })
export class OverlayViewService {
  constructor(private _domComponentService: DomComponentService) {}

  public overlays = [];
  public animationClosingIndex = null;

  public add(content: IOverlayItem) {
    const {
      id,
      headerRef,
      contentRef,
      headerBorder,
      animation,
      headerLabel,
      headerIcon,
      footerRef
    } = content;

    this.overlays = [
      ...this.overlays,
      {
        id: id,
        headerRef: headerRef,
        contentRef: contentRef,
        componentRef: this._domComponentService.add(OverlayViewComponent, id),
        headerBorder: headerBorder,
        animation: animation,
        headerLabel: headerLabel,
        headerIcon: headerIcon,
        footerRef: footerRef,
      },
    ];
  }

  public get overlayCount() {
    return this.overlays.length;
  }

  public close(id) {
    const ref = this.overlays.find((x) => x.id === id);
    if (ref) {
      this._domComponentService.remove(ref.componentRef);
      this.overlays = this.overlays.filter((x) => x.id !== id);
    }
  }

  public clear() {
    this.overlays.forEach((x) =>
      this._domComponentService.remove(x.componentRef),
    );
    this.overlays = [];
  }
}

export interface IOverlayItem {
  headerRef?: any;
  contentRef: any;
  componentRef?: any;
  id: any;
  headerBorder?: boolean;
  animation?: string; //left-to-right | bottom-to-top etc
  headerLabel?: string;
  headerIcon?: string;
  footerRef?: any;
}
