import { Component, HostBinding, Input } from "@angular/core";
import { Color, COLOR_MAP } from "src/app/enum/color";

@Component({
  selector: "color-box",
  styleUrls: ['./color-box.component.scss'],
  template: ``
})
export class ColorBoxComponent {

  @Input() public color: Color;

  @HostBinding('style.background') public get background() {
    return COLOR_MAP[this.color] || '#ffffff';
  };

}
