<lxm-dialog [dialogRef]="dialogRef" [formGroup]="form">
  <span header-title>
    {{ 'cards.offer.procurement_info.title' | translate }}
  </span>

  <div body>

    <display-product class="dialog-display-product-sticky" [data]="product"> </display-product>

    <form-field
      dialog
      label="cards.offer.procurement_info.label.fullfillment_time"
      type="input-text"
      inputClass="w60p"
      for="fullfillmentTime"
      [readonly]="readonly"
    >
      <validation-errors
        for="fullfillmentTime"
      ></validation-errors>
    </form-field>

    <form-field
      dialog
      label="cards.offer.procurement_info.label.conditions"
      type="textarea"
      inputClass="w60p"
      for="conditions"
      [readonly]="readonly"
    >
      <validation-errors
        for="conditions"
      ></validation-errors>
    </form-field>

    <form-field
      dialog
      label="cards.offer.procurement_info.label.note"
      type="textarea"
      inputClass="w60p"
      for="note"
      [readonly]="readonly"
    >
      <validation-errors
        for="note"
      ></validation-errors>
    </form-field>

  </div>

  <div footer>
    <lxm-button
      type="primary"
      label="action.save"
      (click)="save()"
      buttonType="submit"
      *ngIf="!readonly"
    ></lxm-button>
    <lxm-button
      type="secondary"
      [class.ml15]="!readonly"
      label="action.cancel"
      (click)="closeDialog()"
    ></lxm-button>
  </div>
</lxm-dialog>
