import { Component, Inject, Optional, ViewChild } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { delay } from "rxjs/operators";
import { DialogComponent } from 'src/app/components/_layout/dialog/dialog.component';
import { asArray, Status, StatusRule } from "src/app/enum";
import { LxmMessage } from "src/app/_helpers";
import { AssortmentGroupTagsService } from "src/app/_services/assortment-group-tags.service";
import { ILxmTreeNode } from "src/app/_services/lxm-tree.service";

@Component({
  selector: "assortment-group-tag-dialog",
  host: { 'class': 'dialog' },
  templateUrl: "./assortment-group-tag.dialog.html",
  styleUrls: ["./assortment-group-tag.dialog.scss"]
})
export class AssortmentGroupTagDialog {

  public formGroup: FormGroup;
  public statuses: any[] = asArray(Status);
  public statusRules: any[] = asArray(StatusRule);
  public isParentActive: boolean;
  public isParent: boolean;

  @ViewChild('dialog', { static: true, read: DialogComponent })
  private _dialog: DialogComponent;

  public actionLoading = {} as any;

  constructor(
    public dialogRef: MatDialogRef<AssortmentGroupTagDialog>,
    public _msg: LxmMessage,
    private _assortmentGroupTagsService: AssortmentGroupTagsService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: IAssortmentGroupTagDialogData) {

    this.isParent = data?.parentTag == null;

    this.isParentActive = data?.parentTag?.status !== undefined
      ? data.parentTag.status === Status.Active
      : true;

    this.formGroup = new FormGroup({
      id: new FormControl(),
      code: new FormControl(),
      name: new FormControl(),
      description: new FormControl(),
      status: new FormControl(),
      color: new FormControl(),
      sortOrder: new FormControl()
    });
  }

  public get isNew() {
    return this.formGroup.get('id').value ? false : true;
  }

  public ngAfterViewInit(): void {

    this._dialog.dataLoaded$
      .pipe(
        delay(0)
      )
      .subscribe(state => {
        if (!state) {
          return;
        }

        const tag = this.data.tag;

        let status: Status;
        if (tag) {
          status = tag.status;
        } else if (this.data.parentTag) {
          status = this.isParentActive ? Status.Active : Status.Disabled;
        }
        else {
          status = Status.Active;
        }

        this.formGroup.patchValue({
          id: tag?.id,
          code: tag?.code,
          name: tag?.name,
          description: tag?.description,
          status: status,
          color: tag?.color,
          sortOrder: tag?.sortOrder
        });
      });
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public save(): void {
    const f = this.formGroup.value;

    const req = {
      code: f.code,
      name: f.name,
      description: f.description,
      status: f.status,
      color: f.color,
      sortOrder: f.sortOrder,
      parentId: this.data?.parentTag?.id || null
    };

    const save = f.id
      ? this._assortmentGroupTagsService.save(f.id, req)
      : this._assortmentGroupTagsService.create(req);

    save.result(this.formGroup, response => {
      this.dialogRef.close(response);
      this._msg.ok({
        message: 'global.ok'
      })
    }, err => {
      this._msg.error({
        message: err.validationSummary
      });
    },
    "save",
    this.actionLoading);
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}

export class IAssortmentGroupTagDialogData {
  tag: IAssortmentGroupTag;
  parentTag: IAssortmentGroupTag;
}

export class IAssortmentGroupTag implements ILxmTreeNode {
  id: string;
  code: string;
  name: string;
  description: string;
  status: Status;
  color?: number;
  sortOrder: number;
  sortOrderDefined: boolean;
  parentId: string;
  isParent: boolean;
  isParentActive: boolean;
  level: number;
}