import { Component, Optional, ChangeDetectorRef } from "@angular/core";
import { ControlContainer } from "@angular/forms";
import { calculateMargin, calculateUnitPrice } from "src/app/_helpers/calculate";
import { AppState } from "src/app/state/app.state";
import { PriceTableBase } from "../price-table.base";

@Component({
  selector: "currency-price-table",
  templateUrl: "./currency-price-table.component.html",
  styleUrls: ["./currency-price-table.component.scss", "../shared.scss"]
})
export class CurrencyPriceTableComponent extends PriceTableBase {
  constructor(
    appState: AppState,
    _cd: ChangeDetectorRef,
    @Optional() _controlContainer: ControlContainer
  ) {
    super(appState, _cd, _controlContainer);
  }

  public get allRetailPrices() {
    return this.formArray.value;
  }

  public get buyInPriceChanged() {
    return this.formGroup.get("buyInPriceChanged").value;
  }

  public updateRows() {
    const allRetailPrices = this.allRetailPrices;

    allRetailPrices.forEach((retailPrice, index) => {
      // Currency removed
      const hasCurrency = this.currencyIds.includes(retailPrice.currency?.id);
      if (!hasCurrency) {
        this.removeRow(index);
      }
    });

    // ADD NEW CURRENCIES
    this.currencies.forEach(currency => {
      const hasCurrency = allRetailPrices.find(
        x => x.currency?.id === currency?.id
      );
      if (!hasCurrency) {
        this.addRow({ currency: currency });
      }
    });
  }

  public removeRow(index) {
    this.formArray.removeAt(index);
  }

  public addRow(data = null) {
    let row = this.createRow(data);
    this.formArray.insert(this.formArray.length, row, { emitEvent: false });
  }

  public calculateMargin(salesPrice, listPrice) {
    return calculateMargin(salesPrice, listPrice);
  }

  public calculateUnitPrice(price: number, formGroup: any = this.formGroup) {
    const { contentUnitId, drainedWeightInGrams, netContent } = formGroup.value || {};
    return calculateUnitPrice(price, contentUnitId, drainedWeightInGrams, netContent);
  }

  public editBuyInPrice() {
    this.formGroup.get("buyInPriceChanged").setValue(true);
  }

}
