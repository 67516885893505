import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { OverlayViewService } from "src/app/_services/overlay-view.service";

@Component({
  selector: "ov-header",
  templateUrl: "./ov-header.component.html",
  styleUrls: ["./ov-header.component.scss"],
})
export class OverlayViewHeaderComponent {
  @Input() public icon: string;
  @Input() public label: string;

  @Input() public close = () => {
    console.log("close");
  };

  constructor(public OVService: OverlayViewService) {}
}
