import { Component, Input } from '@angular/core';

@Component({
  selector: 'info-array',
  templateUrl: './info-array.component.html',
  styleUrls: ['./info-array.scss']
})
export class InfoArrayComponent {

  @Input() public info: string[];
  @Input() public prelude: string;
  @Input() public parameters: any;
  @Input() public numbered = true;

}
