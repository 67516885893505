export enum ProductBookRoleField {
  Role = 1,
  Personal = 2,
  Desription = 3,
  SortOrder = 4
}

(ProductBookRoleField as any).__typeName = 'ProductBookRoleField';

(ProductBookRoleField as any).__altTranslationKeys = {
  [ProductBookRoleField.Role]: 'cards.products.product_book_info.role_section.role',
  [ProductBookRoleField.Personal]: 'cards.products.product_book_info.role_section.personal',
  [ProductBookRoleField.Desription]: 'cards.products.product_book_info.role_section.description',
  [ProductBookRoleField.SortOrder]: 'cards.products.product_book_info.role_section.sortOrder'
};
