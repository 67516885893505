import { Component, Injector } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProductCardBase } from '../product-card-base';
import { IProductCardElectronicsInfo, IClvDto } from '../product-info/product-info.model';
import { asArray, StateOfFish, UserAction, ProductCardSection } from 'src/app/enum';
import { Observable } from 'rxjs';
import { formUtil, keep } from 'src/app/util/form-util';
import { ProductValidity } from 'src/app/_services';
import { LOCAL_STORAGE_KEY } from 'src/app/config';

@Component({
  selector: 'product-electronics-card',
  templateUrl: './product-electronics.card.html',
  styleUrls: ['./product-electronics.card.scss']
})
export class ProductElectronicsCardComponent extends ProductCardBase<IProductCardElectronicsInfo> {

  public UserAction = UserAction;

  public get title () { return 'cards.products.product_electronics.title'; }

  public cardSection = ProductCardSection.ElectronicsInfo;

  public stateOptions = asArray(StateOfFish);

  public batteryTypeOptions: IClvDto[];

  public isExpandedKey = LOCAL_STORAGE_KEY.PRODUCT_ELECTRONICS_CARD_OPEN;

  constructor(injector: Injector, public productValidity: ProductValidity) {
    super(injector, 'electronicsInfo', 'productElectronicsInfoChanged');

    this.batteryTypeOptions = this._productCard.formData.batteryTypes;
  }

  protected get successMessage(): string {
    return 'cards.products.product_electronics.save_successful';
  }

  protected _getNormalizedFormData(data: IProductCardElectronicsInfo) {
    return formUtil.transformData(data, {
      isElectronicsDevice: keep,
      hasIntegratedBattery: keep,
      integratedBatteryWeight: keep,
      batteryTypeId: () => data.batteryType?.id,
    });
  }

  protected _createFormGroup(data: IProductCardElectronicsInfo): FormGroup {
    return formUtil.createFormGroup(this._getNormalizedFormData(data));
  }

  protected _createSaveRequest() {
    const f = this.form.value;
    return {
      isElectronicsDevice: f.isElectronicsDevice || false,
      hasIntegratedBattery: f.hasIntegratedBattery || false,
      integratedBatteryWeight: f.integratedBatteryWeight,
      batteryTypeId: f.batteryTypeId
    };
  }

  protected _saveInternal(req: any): Observable<any> {
    return this._productService.saveElectronicsInfo(this.productId, req);
  }
}
