import { Component, Injector } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IClvDto } from '../product-info/product-info.model';
import { ProductCardBase } from '../product-card-base';
import { Observable } from 'rxjs';
import { formUtil } from 'src/app/util/form-util';
import { UserAction, ProductCardSection } from 'src/app/enum';
import { ProductValidity } from 'src/app/_services';
import { LOCAL_STORAGE_KEY } from 'src/app/config';

@Component({
  selector: 'product-attributes-card',
  templateUrl: './product-attributes.card.html',
  styleUrls: ['./product-attributes.card.scss']
})
export class ProductAttributesCard extends ProductCardBase<IClvDto[]> {

  public UserAction = UserAction;

  public get title() { return 'cards.products.product_attributes.title'; }

  public cardSection = ProductCardSection.Attributes;

  public attributeOptions: IClvDto[] = [];

  public isExpandedKey = LOCAL_STORAGE_KEY.PRODUCT_ATTRIBUTES_CARD_OPEN;

  constructor(injector: Injector, public productValidity: ProductValidity) {
    super(injector, 'attributes', 'productAttributesChanged');
    this.attributeOptions = this._productCard.formData.productAttributes;
  }

  protected get successMessage(): string {
    return 'cards.products.product_attributes.save_successful';
  }

  protected get defaultData() {
    return [];
  }

  protected _getNormalizedFormData(data: IClvDto[]) {
    return {
      attributes: data?.map(x => x.id)
    };
  }

  protected _saveInternal(req: any): Observable<any> {
    return this._productService.saveAttributes(this.productId, req);
  }

  protected _createSaveRequest() {
    const form = this.form.value;
    return {
      attributes: form.attributes,
    };
  }
  
}
