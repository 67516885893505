export enum ClientChangeType {
  StatusChange = 1,
  Created = 2,
  Saved = 3,
  CreatedContact = 4,
  UpdatedContact = 5,
  DeletedContact = 6,
  OfferSettingsSaved = 7,
  OwnerChange = 8
}

(ClientChangeType as any).__typeName = 'ClientChangeType';
