import { Component, OnInit, AfterViewChecked, Input, Optional, ContentChild, TemplateRef, ViewChild, ChangeDetectorRef, Output, EventEmitter, HostBinding } from '@angular/core';
import { FormGroup, ControlContainer, AbstractControl } from '@angular/forms';
import { LocaleService } from 'src/app/_services';

@Component({
  selector: "lxm-radio",
  templateUrl: './lxm-radio.component.html',
  styleUrls: ['./lxm-radio.component.scss']
})
export class LxmRadioComponent implements OnInit, AfterViewChecked {

  @Input() public formGroup: FormGroup;
  @Input() public for: string;
  @HostBinding('class.readonly')
  @Input() public readonly = false;
  @Input() public options: any[];
  @Input() public labelField = 'name';
  @Input() public isTranslated = false;
  @Input() public discriminator: string;
  @Input() public toggleSelf = false;
  @Input() public buttonType = 'button';
  @Input() public justify: 'flex-start';

  private _forValue: any;
  @Output() public forValueChange = new EventEmitter<any>();

  @Input() public get forValue() { return this._forValue; }
  public set forValue(val) {
    this._forValue = val;
    this.selected = val;
    this.forValueChange.emit(this._forValue);
  }

  public selected: any;

  @ContentChild(TemplateRef) itemTemplate: TemplateRef<any>;
  @ViewChild('defaultItemTemplate') private _defaultItemTemplate: TemplateRef<any>;

  private _ref: AbstractControl;

  @Input() public trackByFn = (index: number, item: any) => {
    return item?.id;
  }

  public get template() {
    return this.itemTemplate || this._defaultItemTemplate;
  }

  constructor(@Optional() private _controlContainer: ControlContainer, private _cdRef: ChangeDetectorRef, private _localeService: LocaleService) { }

  public ngOnInit(): void {

    if (this.for) {

      if (this._controlContainer && this._controlContainer.control instanceof FormGroup) {
        this.formGroup = <FormGroup>this._controlContainer.control;
      }

      this._ref = this.formGroup.get(this.for);
      this.selected = this._ref.value;
      this._bindRef(this._ref);
    }
  }

  private _bindRef(ref: AbstractControl) {
    ref.valueChanges.subscribe(x => {
      this.selected = x;
    });
  }

  public getOptionLabel(item: any) {
    if (!this.isTranslated) {
      return item[this.labelField];
    }

    const lang = this._localeService.currentLanguage.value;
    return item[this.labelField][lang];
  }

  public select(option: any) {

    if (this.for) {

      if (this.toggleSelf && this._ref.value === option) {
        this._ref.setValue(null);
      } else {
        this._ref.setValue(option);
      }

    } else {
      this.forValue = option;
    }
    
  }

  public ngAfterViewChecked() {
    this._cdRef.detectChanges();
  }
}
