import { Injectable, Inject } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import moment from 'moment';

@Injectable({ providedIn: 'root' })
export class DateHandler {

  // DATES
  public addDays(date: moment.MomentInput, days: number) {
    return moment(date).add(days, 'days');
  }

  public addMonths(date: moment.MomentInput, months: number) {
    return moment(date).add(months, 'months');
  }

  public addYears(date: moment.MomentInput, years: number) {
    return moment(date).add(years, 'years');
  }

  public subtractDays(date: moment.MomentInput, days) {
    return moment(date).add(days * -1, 'days');
  }

  public previousDay(date: moment.MomentInput) {
    return this.addDays(date, -1);
  }

  public nextDay(date: moment.MomentInput) {
    return this.addDays(date, 1);
  }

  public isSameOrAfter(date: moment.MomentInput, diff = moment()) {
    return moment(date).isSameOrAfter(diff);
  }

  public weekFromNow(date: moment.MomentInput) {
    return this.addDays(date, 7);
  }

  public monthFromNow(date: moment.MomentInput) {
    return this.addDays(date, 30);
  }

  public yearFromNow(date: moment.MomentInput) {
    return this.addDays(date, 365);
  }

  public rangePeriodChecker(begin: moment.Moment, end: moment.Moment) {
    if (!end) return 'termless';
    const period = moment(end).diff(moment(begin), 'days');
    switch (period) {
      case 7:
        return 'week';
      case 30:
        return 'month';
      case 365:
        return 'year';
      default:
        return 'none';
    }
  }

  public getPeriod(period: string, date?: moment.MomentInput) {

    date = date || moment();

    switch (period) {
      case 'week':
        return {
          begin: date,
          end: this.weekFromNow(date)
        };
      case 'month':
        return {
          begin: date,
          end: this.monthFromNow(date)
        };
      case 'year':
        return {
          begin: date,
          end: this.yearFromNow(date)
        };
      case 'termless':
        return {
          begin: date,
          end: null
        };
    }
  }

  public toQueryDate(date) {
    return moment(date).format('MM-DD-YYYY');
  }

}
