import { Component, Input } from "@angular/core";
import { appSettings } from 'src/app/app.settings';

@Component({
  selector: "phone-number",
  templateUrl: "./phone-number.component.html",
  styleUrls: ['./phone-number.component.scss']
})
export class PhoneNumberComponent {

  @Input() public code: string | number;
  @Input() public number: string | number;
  @Input() public spacing: number = 4;

  public get hasNumber() {
    return this.number ? true : false;
  }

}
