<ng-container [formGroup]="formGroup">
  <div class="owner-icon-container">
    <span class="owner-icon"></span>
  </div>
  <lxm-select
    [readonly]="readonly"
    [options]="options"
    placeholder="{{placeholder | translate}}"
    [underLabel]="underLabel"
    (change)="onChange($event)"
    [clearable]="false"
    [sortByLabel]="true"
    [for]="for"
  >
  </lxm-select>
</ng-container>