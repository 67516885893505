<search [formGroup]="searchData" [isOpen]="isOpen" [isLoading]="isLoading" (onToggle)="toggleOpen($event)" placeholder="cards.products.products_retailer_search.placeholder" (onSubmit)="submit()">
  <ng-container panel-left>
    <scope translationsPath="cards.products.products_retailer_search">
      <form-field panel label="brand" for="brands">
        <lxm-select select
                    labelField="value"
                    [searchable]="true"
                    for="brands"
                    [multiple]="true"
                    additionalField="tenantName"
                    url="api/clvac/brands/">
        </lxm-select>
      </form-field>
      <form-field panel label="sub_brand" for="subBrands">
        <lxm-select select
                    labelField="value"
                    [searchable]="true"
                    for="subBrands"
                    [multiple]="true"
                    additionalField="tenantName"
                    url="api/clvac/brands/">
        </lxm-select>
      </form-field>
      <form-field panel label="supplier" for="suppliers">
        <lxm-select select
                    labelField="name"
                    [searchable]="true"
                    for="suppliers"
                    [multiple]="true"
                    url="api/suppliers/ac/">
        </lxm-select>
      </form-field>
      <form-field panel label="origin" for="countries">
        <lxm-select select for="countries"
          value="id"
          [options]="countryOptions"
          [isTranslated]="true"
          labelField="translatedName"
          [sortByLabel]="true"
          [searchable]="true"
          [multiple]="true">
        </lxm-select>
      </form-field>
      <form-field panel label="product_attributes" for="productAttributes">
        <lxm-select select
                    labelField="translatedValue"
                    [options]="formData.productAttributes"
                    [searchable]="true"
                    for="productAttributes"
                    [multiple]="true"
                    [isTranslated]="true"
                    compareField="id">
        </lxm-select>
      </form-field>
      <form-field panel label="nutritional_claims" for="nutritionalClaims">
        <lxm-select select for="nutritionalClaims"
                    value="id"
                    labelField="name"
                    [options]="nutritionalClaimsOptions"
                    [sortByLabel]="true"
                    [multiple]="true"
                    [searchable]="true">
        </lxm-select>
      </form-field>

    </scope>
  </ng-container>
  <ng-container panel-right>
    <scope translationsPath="cards.products.products_retailer_search">
      <form-field panel label="gs1_tree" for="gs1Segments">
        <lxm-select select
                    labelField="name"
                    [searchable]="true"
                    [multiple]="true"
                    for="gs1Segments"
                    url="api/clvac/gs1-segments/">
        </lxm-select>
      </form-field>

      <form-field panel label="category" for="categories">
        <lxm-select select for="categories"
          [options]="segmentOptions"
          [searchable]="true"
          [multiple]="true"
          [labelFn]="segmentTreeLabel">
          </lxm-select>
      </form-field>

      <form-field panel label="assortment_status" for="assortmentStatuses" *ngIf="hasAssortment">
        <lxm-select select
                    labelField="value"
                    [multiple]="true"
                    compareField="id"
                    [options]="assortmentStatusOptions"
                    for="assortmentStatuses">
        </lxm-select>
        <div select *ngIf="searchData.value.assortmentStatuses?.length == 1 && searchData.value.assortmentStatuses[0].code != -1" class="assortment-movement-container">
          <lxm-select for="assortmentMovement"
                      value="id"
                      [clearable]="true"
                      [options]="assortmentMovementOptions">
          </lxm-select>
        </div>
      </form-field>
      <form-field panel label="period" for="assortmentMovementFrom" *ngIf="searchData.value.assortmentMovement">
        <div any class="flex align-center">
          <lxm-datepicker for="assortmentMovementFrom"></lxm-datepicker> <div class="ml10 mr10">-</div> <lxm-datepicker for="assortmentMovementTo"></lxm-datepicker>
        </div>
      </form-field>
      <form-field panel label="assortment_group" for="assortmentGroups" *ngIf="hasAssortment">
        <lxm-select select
                    labelField="name"
                    [multiple]="true"
                    compareField="id"
                    [options]="assortmentGroupOptions"
                    for="assortmentGroups">
        </lxm-select>
      </form-field>
      <form-field panel label="location" for="locations" *ngIf="hasAssortment">
        <lxm-select select
                    labelField="name"
                    [multiple]="true"
                    compareField="id"
                    [options]="locationOptions"
                    for="locations">
        </lxm-select>
      </form-field>
      <form-field panel for="isInCampaign" label="is_in_campaign">
        <lxm-select select
                    value="id"
                    labelField="name"
                    [options]="isInCampaignOptions"
                    for="isInCampaign">
        </lxm-select>
      </form-field>

      <form-field panel label="assortment_date" for="date">
        <lxm-datepicker any class="f-1" for="date"></lxm-datepicker>
      </form-field>
    </scope>
  </ng-container>
</search>
