import { Pipe, PipeTransform } from "@angular/core";
import { CurrencyPipe } from "@angular/common";
import { numberUtil } from 'src/app/util/number-util';

@Pipe({
  name: "priceValue",
  pure: false
})
export class PriceValuePipe implements PipeTransform {

  constructor(private currencyPipe: CurrencyPipe) {};

  transform(value: any, precision: number = 3, currency = "EUR", emptyValue = '-') {

    if (typeof (value) === "string") {
      value = value?.replace(',', '.');
    }

    if (!numberUtil.isNumber(value)) {
      value = numberUtil.toNumber(value);
    }

    return (value || value === 0)
      ? this.currencyPipe.transform(value, currency ?? 'EUR', 'symbol', `1.${precision}-${precision}`, 'et-EE') 
      : emptyValue;
  }
}
