import {
  Component,
  Input,
  Output,
  HostBinding,
  Optional,
  EventEmitter,
  OnInit,
  Host,
} from "@angular/core";
import { ScopeComponent } from "src/app/components/_layout/card/card.component";

@Component({
  selector: "lxm-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent implements OnInit {
  private _translationsPath: string;

  // TYPE INPUTS:
  // BIG BUTTONS - PRIMARY (orange) : SECONDARY (grey)
  // REGULAR BUTTONS - BEIGE : GREEN : GREY : GREYED : BLACK : TRANSPARENT : DATE-PERIOD

  @HostBinding("class") @Input() public type: string = "transparent";
  @HostBinding("style.width") @Input() public width: string;
  @HostBinding("style.min-width") @Input() public minWidth: string;
  @HostBinding("style.height") @Input() public height: string;
  @HostBinding("class.disabled") @Input() public disabled = false;
  @HostBinding("class.readonly") @Input() public readonly = false;
  @HostBinding("class.loading") @Input() public loading = false;

  @Input() public tabIndex = "-1";
  @Input() public icon: string;
  @Input() public iconPosition: string = "left";
  @Input() public label: string;
  @Input() public title: string;
  @Input() public padding: string = "8px 12px";
  @Input() public active = false;
  @Input() public buttonType = "button";
  @Input() public justify = "center";

  @Output() public click: EventEmitter<MouseEvent> = new EventEmitter();
  public onButtonClick(event: MouseEvent) {
    // event.stopImmediatePropagation();
    // this.click.emit(event);
  }

  public get labelKey() {
    if (this.label && this.label.startsWith(".")) {
      return this._translationsPath + this.label;
    }

    return this.label;
  }

  constructor(@Optional() @Host() private _scope: ScopeComponent) {}

  public ngOnInit(): void {
    if (this._scope) {
      this._translationsPath = this._scope.translationsPath;
    }
    if (this.label && !this.title) {
      this.title = this.labelKey;
    }
  }
}
