export enum DiffValueFormatterType {
  None = 0,
  Enum = 1,
  Clv = 2,
  Country = 3,
  Bool = 4,
  LocalSegment = 5,
  RetailChain = 6,
  User = 7,
  MultiLanguageText = 8,
  SystemClv = 9,
  Gs1Segment = 10,
  Complex = 11,
  Collection = 12,
  Numeric = 13,
  Date = 14,
  ProductUnit = 15,
  ComplexField = 16,
  Product = 17,
  Procurement = 18,
  AtcSegment = 19,
}

(DiffValueFormatterType as any).__typeName = 'DiffValueFormatterType';
