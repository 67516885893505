
<search [formGroup]="searchData" [isOpen]="isOpen" [isLoading]="isLoading" (onToggle)="toggleOpen($event)" placeholder="cards.offers.offers_retailer_search.placeholder" (onSubmit)="submit()">
  <scope panel-left translationsPath="cards.offers.offers_retailer_search">
    <form-field panel label="offer_date">
      <div any class="flex flex-row date-range">
        <lxm-datepicker for="dateFrom" class="f-1"></lxm-datepicker>
        <div class="date-range__separator">-</div>
        <lxm-datepicker for="dateTo" class="f-1"></lxm-datepicker>
      </div>
    </form-field>
    <form-field panel label="offer_type">
      <lxm-select select labelField="value" for="offerType" [options]="offerTypeOptions" compareField="id">
      </lxm-select>
    </form-field>
    <form-field panel label="offer_status">
      <lxm-select select labelField="name" [searchable]="true" for="offerStatus" [multiple]="true" [options]="statusOptions" compareField="id">
      </lxm-select>
    </form-field>
  </scope>
  <scope panel-right translationsPath="cards.offers.offers_retailer_search">
    <form-field panel label="supplier">
      <lxm-select select labelField="name" [searchable]="true" for="suppliers" [multiple]="true"
        url="api/clvac/suppliers/" compareField="id">
      </lxm-select>
    </form-field>
    <form-field panel label="purchase_manager">
      <lxm-select select labelField="displayName" [searchable]="true" for="recipients" [multiple]="true" [options]="recipientsOptions" [sortByLabel]="true" compareField="id">
      </lxm-select>
    </form-field>
    <form-field panel label="show_inactive_managers">
      <slide-toggle toggle for="showInactiveRecipients"></slide-toggle>
    </form-field>
  </scope>
</search>