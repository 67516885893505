import { ChangeDetectionStrategy, Component, HostBinding, Input, TemplateRef, ViewChild, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "[tooltip]",
  templateUrl: "./tooltip.component.html",
  styleUrls: ['./tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TooltipComponent {

  @ViewChild('tooltipContent') tooltipContent;

  @Input() tooltip: TemplateRef<any>;

  @Input() public tooltipPosition: string = 'auto';
  @Input() public tooltipTrigger: string = 'hover';
  @Input() public tooltipApplyClass: string = 'tt-popper';
  @Input() public tooltipAppendTo: string = 'app-root';
  @Input() public tooltipDelay: number;
  @Input() public tooltipBoundaries: string;
  @Input() public tooltipTimeout: number;

  @HostBinding('class.active')
  public get isOpen(): number {
    if (!this.tooltipContent) return 0;
    return this.tooltipContent.opacity;
  }

  ngOnInit() {
    if (this.tooltipTrigger === 'hover' && this.tooltipTimeout === undefined) {
      this.tooltipTimeout = 100;
    }
  }
  
}
