<div class="flex flex-row f-1" [formGroup]="formGroup" [class.readonly]="readonly" [ngSwitch]="hasValues">

  <ng-container any *ngSwitchCase="true">
    <lxm-select class="f-1 mr5" for="productPaymentSupplier"
    [searchable]="true"
    [options]="suppliersOptions"
    value="id"
    [readonly]="readonly"
    [disabled]="disabled"
    labelField="name"
    [sortByLabel]="true">
    <validation-errors [for]="productPaymentSupplier" aliasTo="productPaymentSupplier" [errorsFormGroup]="form"></validation-errors>
  </lxm-select>

    <lxm-input class="flex f-0" for="payment" type="price" [currency]="currency" [readonly]="readonly" style="min-width: 90px;" [disabled]="disabled">
      <validation-errors [for]="'payment'" aliasTo="payment" [errorsFormGroup]="form"></validation-errors>
    </lxm-input>

  </ng-container>

  <ng-container any *ngSwitchDefault>
      <lxm-button 
        class="wmc" 
        padding="8px 0" 
        label="cards.products.product_packaging_edit_basic_unit.action.add_product_payment"
        icon="plus-dark" 
        (click)="addItem()" 
        *ngIf="!readonly">
      </lxm-button>
      <span class="input-padding" *ngIf="readonly" > -
      </span>
  </ng-container>

</div>
