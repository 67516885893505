import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AssortmentGroupTagsService {

  constructor(private _http: HttpClient) { }

  public create(req: any) {
    return this._http.post<any>(`api/assortmentGroupTags`, req);
  }

  public save(id: string, req: any) {
    return this._http.post<any>(`api/assortmentGroupTags/${id}`, req);
  }

  public getDetails(id: string) {
    if (!id) {
      return of(null);
    }

    return this._http.get(`api/assortmentGroupTags/${id}`);
  }

  public getTags() {
    return this._http.get(`api/assortmentGroupTags/list`);
  }
}