export enum ProductCardSection {
  BaseInfo = 1,
  ExtraParameters = 2,
  Attributes = 4,
  AlcoholInfo = 8,
  SeafoodInfo = 16,
  ElectronicsInfo = 32,
  MarkingAndNutritionInfo = 64,
  NutritionClaims = 128,
  NutritionFacts = 256,
  VitaminsAndMinerals = 512,
  AdditionalInfoAndAttachments = 1024,
  ExpiryInfo = 2048,
  ProductUnits = 4096,
  ProductImages = 8192,
  PriceSchemes = 16384,
  AssortmentSchemes = 32768,
  MeatInfo = 65536,
  MovieInfo = 131072,
  MusicInfo = 262144,
  BookInfo = 524288,
  GameInfo = 1048576,
  MedicationInfo = 4194304,
  ClientPricelists = 1073741824,
  History = 2147483648
}

(ProductCardSection as any).__typeName = "ProductCardSection";
