import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IProductLink,IClvDto } from 'src/app/cards/product/product-info/product-info.model';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';

@Component({
  selector: 'add-link',
  host: { 'class': 'dialog' },
  templateUrl: './add-link.dialog.html'
})

export class AddLinkDialog  {

  public linkTypeOptions: IClvDto[];

  public form = new FormGroup({
    typeId: new FormControl(this._data.link?.type.id),
    link: new FormControl(this._data.link?.link),
    comment: new FormControl(this._data.link?.comment)
  });

  public saveInProgress = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: IAddLinkDialogData,
    public dialogRef: MatDialogRef<AddLinkDialog>,
  ) {
      this.linkTypeOptions = _data.linkTypeOptions;
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public save() {

    const data = {
      id: this._data.link?.id,
      typeId:this.form.get('typeId').value,
      link: this.form.get('link').value,
      comment: this.form.get('comment').value,
    };
    this.saveInProgress = true;

    if (this._data.link?.id) {
      this._data.save(data)
        .result(this.form, res => {
          const item = this._data.dataTableRef.data.find(x => x.id === this._data.link.id);
          Object.assign(item, res);
          this.saveInProgress = false;
          this.dialogRef.close();
        }, err=>{
          this.saveInProgress=false;
        })

    } else {
        this._data.add(data)
        .result(this.form, res => {
          this._data.dataTableRef.data.push(res);
          this._data.dataTableRef._updateChangeSubscription();
          this.saveInProgress = false;
          this.dialogRef.close();
        }, err=>{
          this.saveInProgress=false;
        });
    }

  }

}

export interface IAddLinkDialogData {
  save: (data: any) => Observable<IProductLink>;
  add: (data: any) => Observable<IProductLink>;
  link: IProductLink;
  linkTypeOptions: IClvDto[];
  dataTableRef: MatTableDataSource<any>;
}
