import { Component, OnInit, Host, ViewChild, ElementRef, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { IOfferCard, IOfferCardFormData, IOfferCardDetail, IOfferRecipient } from 'src/app/models';
import { LxmMessage, DateHandler, LxmDialog } from 'src/app/_helpers';
import { OfferService, AuthenticationService, SignalRService } from 'src/app/_services';
import { OfferLayoutComponent } from 'src/app/views/offers/offer/offer.layout';
import { OfferType, UserAction, OfferRequestStatus, OfferProductStatus } from 'src/app/enum';
import { AppState } from 'src/app/state/app.state';
import { IClvDto } from '../product/product-info/product-info.model';

import { RouteNavigator } from 'src/app/_helpers/route.listener';
import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import { Subscription } from 'rxjs';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { MessageComponent } from 'src/app/components/_layout/messages/message/message.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'request-offer-card',
  templateUrl: './request-offer.card.html',
  styleUrls: ['./offer.card.scss']
})
export class RequestOfferCardComponent implements OnInit, OnDestroy {

  private _signalRSubscription: Subscription;
  private _msgRef: MatSnackBarRef<MessageComponent>;

  @ViewChild('download') private _download: ElementRef;

  @Input() public isEditable: boolean;

  public translationsPath = 'cards.offer.base_info';
  public OfferStatus = OfferRequestStatus;
  public UserAction = UserAction;
  public OfferType = OfferType;

  public offerId: string;

  public productInstructions: string[];

  public form: FormGroup;
  public edit = false;

  private _offerCard: IOfferCard;
  private _formData: IOfferCardFormData;
  private _snapshot: any;

  public retailerCampaigns: IClvDto[] = [];
  public currencyOptions: IClvDto[] = [];

  public offerTypeOptions = [];
  
  public period: string;

  public offer: IOfferCardDetail;

  public sendingOfferRequest = false;
  public saveLoading = false;
  public updatingPrices = false;
  public acceptingOffer = false;
  public canEdit: boolean;

  public recipientsOptions: IOfferRecipient[] = [];

  public get canEditCurrency(): boolean {
    if (this.currencyOptions.length < 2) {
      return false;
    }

    return (this.offer?.status ?? OfferRequestStatus.Created) == OfferRequestStatus.Created || this.offer?.status === 0;
  }

  private _hubConnection: HubConnection;

  constructor(
    @Host() private _page: OfferLayoutComponent,
    private _router: Router,
    private _route: ActivatedRoute,
    private _routeNavigator: RouteNavigator,
    private _dateHandler: DateHandler,
    private _offerService: OfferService,
    private _dialog: LxmDialog,
    private _message: LxmMessage,
    public appState: AppState,
    authService: AuthenticationService,
    private _signalRService: SignalRService,
    private _translateService: TranslateService
  ) {
    this._offerCard = _route.snapshot.data.offerCard as IOfferCard;

    this._formData = this._offerCard?.formData || _route.snapshot.data.formData;
    
    this.retailerCampaigns = (this._formData as any).campaigns || [];

    this.offer = this._offerCard?.offerDetail;

    this.offerTypeOptions = this._formData.offerTypes.filter(x =>  x.id === OfferType.MainAssortment.toString() || x.id == OfferType.Campaign.toString())

    if (this.offer) {
      this.offerId = this.offer.id;
      this._signalRSubscription = this._signalRService.commonHub
        .subscribe(connection => {
          this._hubConnection = connection;
          if (connection?.state === HubConnectionState.Connected) {
            
            connection.send('viewOfferRequest', this.offer.id);

            connection.on('statusChanged', (data: any) => {
              this.offer.status = data.status;
            });

            connection.on('offerHasChanged', data => {
              if (this.offerId === data.offerId && data.read === false) {

                this._msgRef = this._message.ok({
                  message: 'cards.offer.base_info.offer_changed',
                  indefinite: true,
                  buttonText: 'action.reload_page',
                  buttonIcon: 'generate',
                  buttonAction: () => {
                    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
                    this._router.onSameUrlNavigation = 'reload';
                    this._router.navigate(['/offers', this.offerId]);
                    this._msgRef.dismiss();
                  }
                });

              }
            });
          }
        });
    }

    this.form = new FormGroup({
      name: new FormControl(this.offer?.name),
      offerType: new FormControl(
        this.offer?.offerType
        || this.offerTypeOptions.find(x => x.id === OfferType.MainAssortment.toString())
      ),
      dateFrom: new FormControl(this.offer?.from?.editableDate),
      dateTo: new FormControl(this.offer?.to?.editableDate),
      description: new FormControl(this.offer?.description),
      currency: new FormControl(this.offer?.currency),
      products: new FormControl(this._offerCard?.products
        || this._formData.products?.map(x => Object.assign({}, x, { id: null, isNew: true }))
        || []
      )
    });

    this.canEdit = authService.hasRight([UserAction.ManageReceivedOffers]);

    const selectedRetailer = this._formData.retailers.find(x => x.id == this.appState.contentTenantId);
    this.currencyOptions = this._formData.currencies.filter(x => selectedRetailer.acceptedCurrencies?.indexOf(x.value) >= 0);    

    const currentValue = this.form.get("currency").value?.id;
    this.form.get("currency").setValue(
      this.currencyOptions.find(x => x.id === currentValue)
      || this.currencyOptions.find(x => x.value == selectedRetailer.defaultCurrency)
    );

  }

  ngOnInit() {
    if (!this.offerId) {
      this.edit = true;
    }
    this._snapshot = this.form.value;
  }

  public setDatePeriod(period: string) {
    const dateFromControl = this.form.get('dateFrom');
    const dateToControl = this.form.get('dateTo');

    const x = this._dateHandler.getPeriod(period, dateFromControl.value);
    dateFromControl.setValue(x.begin);
    dateToControl.setValue(x.end);
    this.period = period;
  }

  public backToList() {
    this._routeNavigator.toPreviousRoute({ fallback: '/offers' });
  }

  public toggleEdit() {
    if (this.edit) {
      this._revertToInitialData();
    }
    this.edit = !this.edit;
  }

  public getOfferStatusProgress(status: OfferRequestStatus) {
    return this._offerService.getOfferRequestStatusProgress(status);
  }

  public save() {
    this.saveLoading = true;
    const f = this.form.value;
    const data: any = {
      offerTypeId: f.offerType?.id,
      validFrom: f.dateFrom,
      validTo: f.dateTo,
      name: f.name,
      description: f.description,
      currencyId: f.currency?.id,
      products: f.products
        .filter(x => !x.isDeleted)
        .map(x => ({
          id: x.id,
          productId: x.productId,
          requestedPrice: x.requestedPrice,
          status: x.status || OfferProductStatus.Pending
        }))
    };

    const o = this.offerId
      ? this._offerService.saveOfferRequest(this.offerId, data)
      : this._offerService.createOfferRequest(data);

    o.result(this.form, id => {
        this._message.ok({
          message: 'common.message.offer_saved_successfully'
        });

        this._saveInitialData();
        this.edit = false;
        this.saveLoading = false;

        if (!this.offerId) {
          this._router.navigate(['/offers', id], { replaceUrl: true });
        }
      }, error => {
        this.saveLoading = false;
      });
  }

  public send() {
    this._dialog.confirm(
      {
        image: 'request-offer-modal-image',
        template: `
        <p class="dialog-title mt5 mb0">${this._translateService.instant('cards.offers.offer_dialogs.request_offer_send.title')}</p>
        <p>
          ${this._translateService.instant('cards.offers.offer_dialogs.request_offer_send.text')}
        </p>
        `
      },
      (ref) => {

        this.sendingOfferRequest = true;

        this._offerService.sendOfferRequest(this.offerId)
          .pipe(finalize(() => {
            this.sendingOfferRequest = false;
          }))
          .result(null, res => {
            this._message.ok({
              message: 'cards.offer.base_info.send_successful'
            });

            ref.close();
          }, (error) => {
            this._message.error({
              messages: ['cards.offer.base_info.send_failed', error.validationSummary]
            });
          });
      }
    );
  }

  private _revertToInitialData() {
    this.form.setValue(this._snapshot);
  }

  private _saveInitialData() {
    this._snapshot = this.form.value;
  }

  public ngOnDestroy() {
    if (this._hubConnection) {
      if (this.offerId) {
        this._hubConnection.send('leaveOffer', this.offerId);
        this._hubConnection.off('statusChanged');
        this._hubConnection.off('offerHasChanged');
      }
    }

    if (this._signalRSubscription) {
      this._signalRSubscription.unsubscribe();
    }

    if (this._msgRef) {
      this._msgRef.dismiss();
    }
  }
}
