import { Injectable, OnInit } from "@angular/core";
import { LOCAL_STORAGE_KEY } from "src/app/config";
import { OfferKind, OfferRequestStatus, ReceivingOfferStatus, SupplierOfferStatus } from "src/app/enum";
import { AppState } from "src/app/state/app.state";
import { LocalStorage } from "src/app/_helpers";

export interface IOffersListState {

  isSearchLoading: boolean;
  listFilter: any;
  listViewType: any;
  isFullScreen: boolean;
  zoom: number;

  getListFilter(viewType: OffersListViewType): any;
  setListViewType(toSet: OffersListViewType, kind: OfferKind): void;
}

@Injectable({ providedIn: "root" })
export class OffersListStateBase {
  public isSearchLoading = false;

  public isFullScreen = false;
  public zoom = 1;
}

@Injectable({ providedIn: "root" })
export class SellerOffersListState extends OffersListStateBase implements IOffersListState {

  public get listViewTypeKey() {
    return LOCAL_STORAGE_KEY.SUPPLIER_OFFERS_LIST_VIEW_TYPE
  }
  public listViewType = LocalStorage.getValue(this.listViewTypeKey) || OffersListViewType.All;
  public listFilter = this.getListFilter(this.listViewType);

  public FILTER_ALL = undefined;

  public get FILTER_ACTIVE() {
    return `status eq '${SupplierOfferStatus.Sent}' or status eq '${SupplierOfferStatus.Negotiating}'`
  }

  public get FILTER_ARCHIVE() {
    return `status eq '${SupplierOfferStatus.Cancelled}' or status eq '${SupplierOfferStatus.Rejected}' or status eq '${SupplierOfferStatus.Expired}' or status eq '${SupplierOfferStatus.Confirmed}'`;
  }

  public get FILTER_SAVED() {
    return `status eq '${SupplierOfferStatus.Created}'`;
  }

  public get FILTER_SENT_REQUESTS() {
    return `status eq '${OfferRequestStatus.Sent}'`;
  }

  public get FILTER_REQUEST_DRAFTS() {
    return `status eq '${OfferRequestStatus.Created}'`;
  }

  public getListFilter(viewType: OffersListViewType) {
    switch (viewType) {
      case OffersListViewType.All:
        return this.FILTER_ALL;
      case OffersListViewType.Saved:
        return this.FILTER_SAVED;
      case OffersListViewType.Archive:
        return this.FILTER_ARCHIVE;
      case OffersListViewType.SentRequests:
        return this.FILTER_SENT_REQUESTS;
      case OffersListViewType.RequestDrafts:
        return this.FILTER_REQUEST_DRAFTS;
      case OffersListViewType.Active:
        return this.FILTER_ACTIVE;
      default:
        return undefined;
    }
  }

  public setListViewType(toSet: OffersListViewType) {
    LocalStorage.set(this.listViewTypeKey, toSet);
    this.listViewType = toSet;
  }
}

@Injectable({ providedIn: "root" })
export class BuyerOffersListState extends OffersListStateBase {

  public get listViewTypeKey() {
    return LOCAL_STORAGE_KEY.RETAILER_OFFERS_LIST_VIEW_TYPE;
  }

  public listViewType =
    LocalStorage.getValue(this.listViewTypeKey) || OffersListViewType.All;
  public listFilter = this.getListFilter(this.listViewType);

  public FILTER_ALL = undefined;

  public get FILTER_ACTIVE() {
    return `status eq '${ReceivingOfferStatus.Pending}' or status eq '${ReceivingOfferStatus.Negotiating}'`;
  }

  public get FILTER_ARCHIVE() {
    return `status eq '${ReceivingOfferStatus.Cancelled}' or status eq '${ReceivingOfferStatus.Rejected}' or status eq '${ReceivingOfferStatus.Expired}' or status eq '${ReceivingOfferStatus.Confirmed}'`;
  }

  public get FILTER_SAVED() {
    return `status eq '${SupplierOfferStatus.Created}'`;
  }

  public get FILTER_SENT_REQUESTS() {
    return `status eq '${OfferRequestStatus.Sent}'`;
  }

  public get FILTER_REQUEST_DRAFTS() {
    return `status eq '${OfferRequestStatus.Created}'`;
  }

  public getListFilter(viewType: OffersListViewType) {
    switch (viewType) {
      case OffersListViewType.All:
        return this.FILTER_ALL;
      case OffersListViewType.Saved:
        return this.FILTER_SAVED;
      case OffersListViewType.Archive:
        return this.FILTER_ARCHIVE;
      case OffersListViewType.SentRequests:
        return this.FILTER_SENT_REQUESTS;
      case OffersListViewType.RequestDrafts:
        return this.FILTER_REQUEST_DRAFTS;
      case OffersListViewType.Active:
        return this.FILTER_ACTIVE;
      default:
        return undefined;
    }
  }

  public setListViewType(toSet: OffersListViewType, kind: OfferKind) {
    LocalStorage.set(this.listViewTypeKey, toSet);
    LocalStorage.set(this.listViewTypeKey + kind, toSet);
    this.listViewType = toSet;
  }
}

export enum OffersListViewType {
  All = 1,
  Saved = 2,
  Archive = 3,
  Active = 4,
  SentRequests = 5,
  RequestDrafts = 6
}
