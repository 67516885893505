import { Component, ViewChild, ChangeDetectorRef, Input, ChangeDetectionStrategy, OnInit, HostListener, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Status, asArray, UserAction } from 'src/app/enum';
import { AssortmentGroupsService, AssortmentSchemesService, DataTableService } from 'src/app/_services';
import { DataTableComponent } from '../../../data-table/data-table.component';
import { appSettings } from 'src/app/app.settings';
import { LxmDialog } from 'src/app/_helpers';
import { forkJoin } from 'rxjs';
import { AssortmentSchemeDialogComponent } from './dialog/assortment-scheme.dialog';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import moment from 'moment';
import { PopperContent } from 'ngx-popper';
import { AssortmentGroupTagsService } from 'src/app/_services/assortment-group-tags.service';
import { AssortmentGroupTagsDialogComponent } from './dialog/assortment-group-tags.dialog';

@Component({
  selector: 'assortment-schemes',
  templateUrl: './assortment-schemes.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: [ './assortment-schemes.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class AssortmentSchemesComponent implements OnInit {

  @ViewChild('schemeslist') public schemeslist: PopperContent;

  @Input() public initialData: any;
  @Input() public canEdit: boolean;

  public currentScheme: any;
  public currentSchemeId: string;
  public assortmentGroups: any[];
  public assortmentGroupsDictionary: {};
  public segmentTree: any[];
  public schemes: any[];
  public map: any;
  public tagsMap: any;

  public schemesCategorized;
  public schemeCategoriesExpanded = [];

  public UserAction = UserAction;

  public form: FormGroup;

  public saveLoading = false;
  public hasSchemes = false;
  public showSchemeSelection = false;
  public edit = false;

  private _mapHistory = [];

  public assortmentGroupsSnapshot = [];
  public assortmentGroupFilterOptions = [];
  public segmentTreeSnapshot = [];

  constructor(
    private _dialog: LxmDialog,
    private _cd: ChangeDetectorRef,
    private _assortmentSchemesService: AssortmentSchemesService
  ) {
    this.form = new FormGroup({
      matrix: new FormArray([]),
      groupFilter: new FormControl(),
      segmentFilter: new FormControl()
    });
    
  }

  public getAssormentGroupsTitle(assortmentGroups, i) {
    if (assortmentGroups[i.key]?.title) {
      return null;
    }

    let title = '';

    Object.values(assortmentGroups).forEach((x: any) => {
      title += x.name + ' ';
    });
    
    assortmentGroups[i.key].title = title;
  }

  public setData(data = this.initialData) {
    if (!data) return;

    this._cd.detach();
    const { assortmentSchemeId, assortmentGroups, segmentTree, schemes, map, tags } = data;

    this.currentScheme = schemes.find(x => x.id === assortmentSchemeId) || schemes[0];
    this.currentSchemeId = assortmentSchemeId;
    this.assortmentGroups = assortmentGroups;
    this.segmentTree = segmentTree;
    this.schemes = schemes;
    this.hasSchemes = schemes?.length > 0;;

    this.map = map;
    this._mapHistory = [];

    this.tagsMap = tags;

    this.categorizeSchemesByYear();
    this._setDataSnapshots();
    this._applyFilters();

    this._cd.reattach();
    this._cd.detectChanges();
  }

  ngOnInit(): void {
    if (this.initialData) {
      this.setData(this.initialData);
      this.initialData = null;
    } else {
      this._assortmentSchemesService.getMap().result(null, res => {
        this.setData(res);
      });
    }
  }

  private _setDataSnapshots() {
    this.assortmentGroupsSnapshot = this.assortmentGroups;
    this.segmentTreeSnapshot = this.segmentTree;
    this.assortmentGroupFilterOptions = Object.values(this.assortmentGroups);
  }

  private _applyFilters() {
    this.filterAssortmentGroups();
    this.filterSegments();
  }

  public filterAssortmentGroups() {
    const groupFilter = this.form.get('groupFilter').value;

    if (groupFilter?.length > 0) {
      this.assortmentGroups = groupFilter;
    } else {
      this.assortmentGroups = this.assortmentGroupsSnapshot;
    }
  }

  public filterSegments() {
    const segmentFilter = this.form.get('segmentFilter').value;

    if (segmentFilter?.length > 0) {
      this.segmentTree = segmentFilter;
    } else {
      this.segmentTree = this.segmentTreeSnapshot;
    }

  }

  public selectScheme(scheme) {
    const { id } = scheme || {};
    if (!id || id === this.currentSchemeId) {
      return;
    }
    this.schemeslist.hide();
    this.updateMap(id);
  }

  public updateMap(id) {
    this._cd.detach();
    this._assortmentSchemesService.getMap(id).result(null, res => {
      this.setData(res);
      this.toggleSchemeSelection(false);
    })
    this._cd.reattach();
    this._cd.detectChanges();
  }

  public isAssortmentSchemeSelected(scheme) {
    if (!scheme || !this.currentSchemeId) return false;
    return scheme.id === this.currentSchemeId;
  }

  public assortmentGroupsSort(a, b){
    return a.name > b.name;
  }

  public segmentFilterLabenFn(item): string {
    if (item.code) {
      return item.code + ' ' + item.name
    }
    return item.name;
  } 

  public openSegmentIds = new Set();

  public setOpen(nodeId: any) {
    if (this.isOpen(nodeId)) {
      this.openSegmentIds.delete(nodeId)
    } else {
      this.openSegmentIds.add(nodeId);
    }
  }

  public isOpen(nodeId) {
    return this.openSegmentIds.has(nodeId);
  }

  public toggleEdit(toSet = !this.edit) {
    this.edit = toSet;
  }

  public isChecked(segment: any, assortmentGroup: any){
    return this.map && this.map[segment.id]
      ? this.map[segment.id][assortmentGroup.id]
      : false;
  }

  public trackById(index, item) {
    return item.id;
  }

  public setCssClasses(segment: any, assortmentGroup: any): string[] {
    const classes = [];

    if (this.map[segment.id] && this.map[segment.id][assortmentGroup.id] == SelectionType.Primary) {
      classes.push("primary");
    }

    if (!this.edit) {
      classes.push("readonly")
    }

    return classes;
  }

  @HostListener('document:keydown.control.z')
  public undo() {
    if (!this.edit) return;
    const prevMap = this._mapHistory.pop();
    if (prevMap) {
      this.map = prevMap;
    }
  }

  // TAGS
   
  public selected = [];

  public selectSegment(event, segment, assortmentGroup, isMarked = this.isMarked(segment, assortmentGroup), selectionType = SelectionType.Primary) {
    if (!this.editTags) {
      return;
    }

    event?.preventDefault();

    const segmentId = segment.id;
    const assortmentGroupId = assortmentGroup.id;

    const item = {
      segmentId: segmentId,
      assortmentGroupId: assortmentGroupId,
      assortmentGroup: assortmentGroup,
      segment: segment
    }

    console.log(item);

    let isInheritedViaSegment = segment.parentId && this.selected.some(x => x.segmentId == segment.parentId && x.assortmentGroupId === assortmentGroupId);
    if (isInheritedViaSegment && isMarked) {
      return false;
    }

    if (isMarked) {
      const refIndex = this.selected.findIndex(x => x.segmentId === segmentId && x.assortmentGroupId === assortmentGroupId);
      this.selected.splice(refIndex, 1);
    } else {
      this.selected.push(item);
    }

    if (segment.children.length) {
      for(let child of segment.children) {
        this.selectSegment(null, child, assortmentGroup, isMarked, SelectionType.Inherited);
      }
    }

  }

  public isMarked(segment, assortmentGroup) {
    const segmentId = segment.id;
    const assortmentGroupId = assortmentGroup.id;
    return this.selected.some(x => x.segmentId === segmentId && x.assortmentGroupId === assortmentGroupId);
  }

  public getTags(segment, assortmentGroup) {
    const segmentId = segment.id;
    const assortmentGroupId = assortmentGroup.id;
    return this.tagsMap?.[segmentId]?.[assortmentGroupId];
  }

  public editTags = false;
  public toggleEditTags() {
    this.editTags = !this.editTags;
    if (!this.editTags) {
      this.selected = [];
    }
  }

  public openTagsDialog() {
    this._dialog.open(AssortmentGroupTagsDialogComponent, {
      ...appSettings.DIALOG_SIZES.M,
      restoreFocus: false,
      data: {
        assortmentSchemeId: this.currentSchemeId,
        selected: this.selected,
        tagsMap: this.tagsMap
      },
      resolve: () => {
        const resolvers: any = {
          tags: this._assortmentSchemesService.getTags()
        };
        return forkJoin(resolvers);
      }
    }, res => {
      if (res) {
        this.selected = [];
        this.editTags = false;
        this.updateMap(res);
      }
    });
  }

  @HostListener('window:keydown.escape', ['$event']) onKeyDown(e) {
    if (this.editTags) {
      this.toggleEditTags();
    }
  }

  // END TAGS

  public onCheckboxChange(event: any, segment: any, assortmentGroup: any) {
    if (!this.edit) {
      event.preventDefault();
      return;
    }

    if (!this._setMapValue(segment, assortmentGroup, event.target.checked, SelectionType.Primary)) {
      event.preventDefault();
    }

    this._cd.detectChanges();
  }

  private _setMapValue(segment: any, assortmentGroup: any, checked: boolean, selectionType: SelectionType, force: boolean = false) {
    if (!segment?.id || !assortmentGroup?.id) return;

    if (!this.map[segment.id]) {
      this.map[segment.id] = {};
    }

    if (!force && this.map[segment.id][assortmentGroup.id] == SelectionType.Inherited) {
      return false;
    }

    if (selectionType === SelectionType.Primary) {
      this._mapHistory.push(this._cloneMap(this.map));
    }
    else if (selectionType == SelectionType.Inherited && !checked) {
      
      let isInheritedViaSegment = segment.parentId && this.map[segment.parentId] && this.map[segment.parentId][assortmentGroup.id] > 0;
      if (isInheritedViaSegment) {
        return false;
      }

      if (assortmentGroup?.roots) {
        for (let rootId of assortmentGroup.roots) {
          let isInheritedViaAssortmentGroup = this.map[segment.id][rootId] == SelectionType.Primary;
          if (isInheritedViaAssortmentGroup) {
            return false;
          }
        }
      }
    }

    this.map[segment.id][assortmentGroup.id] = checked ? selectionType : 0;

    if (assortmentGroup.children.length) {
      for(let childId of assortmentGroup.children) {
        this._setMapValue(segment, this.assortmentGroups[childId], checked, SelectionType.Inherited, true);
      }
    }

    if (segment.children.length) {
      for(let child of segment.children) {
        this._setMapValue(child, assortmentGroup, checked, SelectionType.Inherited, true);
      }
    }

    return true;
  }

  private _cloneMap(map: any) {

    let clone = {};

    for (var i of Object.keys(map)) {
      clone[i] = Object.assign({}, map[i]);
    }

    return clone;
  }

  public openDialog(item?: any) {

    this._dialog.open(AssortmentSchemeDialogComponent, {
        ...appSettings.DIALOG_SIZES.AUTO,
        restoreFocus: false,
        data: {
          item: item,
        },
        // resolve: () => {
        //   const resolvers: any = {
        //     formData: this._assortmentGroupsService.getAssortmentGroupFormData(item?.id)
        //   };

        //   return forkJoin(resolvers);
        // }
      }, res => {
        if (res) {
          this.updateMap(res)
        }
      });
  }

  public save() {
    this.saveLoading = true;
   
    const mapItems = [];

    for (let segmentId in this.map) {
      for (let assortmentGroupId in this.map[segmentId]) {
        if (this.map[segmentId][assortmentGroupId] == SelectionType.Primary) {
          mapItems.push({segmentId, assortmentGroupId})
        }
      }
    }

    this._assortmentSchemesService.saveMap(this.currentSchemeId, { map: mapItems })
      .result(null, x => {
        this._mapHistory = [];
        this.saveLoading = false;
        this.toggleEdit(false);
        this._cd.detectChanges();
      });


  }

  public toggleSchemeSelection(toSet = !this.showSchemeSelection) {
    this.showSchemeSelection = toSet;
  }

  public categorizeSchemesByYear() {
    let categories = {};
    this.schemes.forEach(x => {
      const yearFrom = moment(x.validFrom).startOf('year').format('YYYY');
      
      if (!this.isSchemeCategoryExpanded(yearFrom)) {
        this.schemeCategoriesExpanded.push(yearFrom);
      }

      if (categories.hasOwnProperty(yearFrom) && Array.isArray(categories[yearFrom])) {
        categories[yearFrom].push(x);
        return;
      }
      
      categories[yearFrom] = [x];
    })

    this.schemesCategorized = categories;
  }

  public isSchemeCategoryExpanded(schemeYear: string) {
    return this.schemeCategoriesExpanded.includes(schemeYear);
  }

  public toggleSchemeCategoryExpand(schemeYear: string) {
    if (!schemeYear) return;
    const expanded = this.schemeCategoriesExpanded.includes(schemeYear);

    if (expanded) {
      const schemeIndex = this.schemeCategoriesExpanded.findIndex(x => x === schemeYear);
      this.schemeCategoriesExpanded.splice(schemeIndex, 1);
    } else {
      this.schemeCategoriesExpanded.push(schemeYear)
    }
  }

}

enum SelectionType {
  Primary = 1,
  Inherited = 2
}
