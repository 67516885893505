import { Component, ViewChild, ChangeDetectorRef, Output, AfterViewChecked } from "@angular/core";
import { TabOption } from "src/app/components/_layout/tabs/tab-options/tab-option.model";
import { TabOptionsComponent } from 'src/app/components/_layout/tabs/tab-options/tab-options.component';
import { LOCAL_STORAGE_KEY } from 'src/app/config';
import { ActivatedRoute } from "@angular/router";
import { AppState } from 'src/app/state/app.state';
import { AuthenticationService } from "src/app/_services";
import { AssortmentClvTableComponent } from 'src/app/components/_partials/settings/clv-table/assortment-management/assortment-clv-table.component';
import { ClvType, LxmAppModule, UserAction } from "src/app/enum";

@Component({
  selector: "assortment-management-card",
  templateUrl: "./assortment-management.card.html",
  styleUrls: ['./assortment-management.card.scss'],
})
export class AssortmentManagementCardComponent implements AfterViewChecked {

  public TabType = TabType;

  public options: TabOption[];

  public canEdit: boolean;

  @Output()
  public tabType: TabType;

  @ViewChild(TabOptionsComponent, { static: false })
  private _tabOptions: TabOptionsComponent;

  @ViewChild(AssortmentClvTableComponent, { static: false })
  private _clvTable: AssortmentClvTableComponent;

  public initialData;

  public tabLocalStorageKey = LOCAL_STORAGE_KEY.TAB_ASSORTMENT_MANAGEMENT_SETTINGS;

  constructor(
    private _appState: AppState,
    private _cd: ChangeDetectorRef,
    private _route: ActivatedRoute,
    private _authService: AuthenticationService) {
    this.initialData = this._route?.snapshot?.data?.assortmentManagementData;

    this.options = [...TAB_OPTIONS];

    if (_appState.hasModule(LxmAppModule.BuyerAssortment)) {

      this.options.push(
        new TabOption("cards.settings.assortment_management.action.assortment_schemes",
          {
            type: TabType.AssortmentSchemes,
          })
      );

      this.options.push(
        new TabOption("cards.settings.assortment_management.action.assortment_group_tags",
          {
            type: TabType.AssortmentGroupTags,
          })
      );

      this.options.push(
        new TabOption("cards.settings.assortment_management.action.assortment_groups",
          {
            type: TabType.AssortmentGroups,
          })
      );

      this.options.push(
        new TabOption('cards.settings.assortment_management.action.assortment_statuses',
          <TabData>{
            type: TabType.Clv,
            data: {
              classificatorId: ClvType.AssortmentStatus,
              controller: "assortmentStatuses",
              displayedColumns: ['code', 'value', 'description', 'status', 'color']
            }
          })
      );
    }
  }

  ngOnInit() {
    this.canEdit = this._authService.hasRights([UserAction.ManageBuyerAssortment]);
  }

  public ngAfterViewInit(): void {
    this.updateView();
    this.initialData = null;
  }

  public ngAfterViewChecked(): void {
    this.updateView();
  }

  private updateView(): void {
    if (!this._tabOptions) {
      return;
    }

    const option = this._tabOptions.selectedOption;
    if (!option || !option.data) {
      return;
    }

    switch (option.data.type) {
      case TabType.Clv: {
        if (!this._clvTable || this._clvTable.classificatorId === option.data.data.classificatorId) {
          break;
        }

        this._clvTable.title = option.translationKey;
        this._clvTable.setData(option.data.data);
        break;
      }
    }

    this._cd.detectChanges();
  }

  public onTabSelect(option: TabOption) {
    if (!option) {
      return;
    }

    this.tabType = option.data.type;
    this.updateView();
  }
}

export enum TabType {
  Clv = 1,
  AssortmentGroups = 4,
  AssortmentSchemes = 5,
  AssortmentGroupTags = 6
}

export interface TabData {
  type: TabType;
  data: any;
}

const TAB_OPTIONS: TabOption[] = [

];
