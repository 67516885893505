import { Component, OnInit } from "@angular/core";

@Component({
  selector: "dialog-spinner",
  templateUrl: "./dialog-spinner.component.html",
  styleUrls: ['./dialog-spinner.scss']
})
export class DialogSpinnerComponent {

  constructor() { }
}
