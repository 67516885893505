declare global {
    interface Date {
        stripTimezone(): Date;
    }
}

Date.prototype.stripTimezone = function(): Date {
    return new Date(this.getTime() - this.getTimezoneOffset() * 60000);
}

export {};