import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { Observable, of } from 'rxjs';
import { HttpNotFoundResponse } from '../_helpers';
import { catchError } from 'rxjs/operators';

@Injectable()
export abstract class ResolveBase implements Resolve<any> {

  constructor(protected http: HttpClient, protected router: Router, protected location: Location) { }

  abstract req(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>;

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.req(route, state)
      .pipe(
        catchError((error, x) => {
          if (error instanceof HttpNotFoundResponse) {
            this.router.navigateByUrl('404', { skipLocationChange: true })
              .then(() => {
                this.location.go(state.url);
              });

            return of(null);
          }
        })
      );
  }
}
