<ng-container [formGroup]="form">

  <scope translationsPath="cards.lexi_market_settings.mail_settings">
    <card-section>
      <span header-title style="font-size: 24px; font-weight: bold;">
        {{'cards.lexi_market_settings.mail_settings.title' | translate }}
      </span>
      <span header-actions>
        <div class="flex">
          <lxm-button type="beige" class="ml15" icon="send" label=".btn_test" [loading]="testLoading" (click)="test()" *ngIf="useCustomSmtp"></lxm-button>
          <lxm-button type="beige" class="ml15" icon="save" label="action.save" [loading]="saveLoading" (click)="save()"></lxm-button>
        </div>
      </span>
      <div body class="row">
        <div class="col-8 no-padding">

          <form-field label="use_custom_smtp">
            <slide-toggle toggle for="useCustomSmtp"></slide-toggle>
            <validation-errors for="useCustomSmtp"></validation-errors>
          </form-field>

          <ng-container *ngIf="form.value.useCustomSmtp">

            <form-field for="host" label="host" type="input-text">
              <validation-errors for="host"></validation-errors>
            </form-field>

            <form-field for="port" label="port" type="input-text">
              <validation-errors for="port"></validation-errors>
            </form-field>

            <form-field label="use_start_tls">
              <slide-toggle toggle for="useStartTls"></slide-toggle>
              <validation-errors for="useStartTls"></validation-errors>
            </form-field>

            <form-field label="enable_ssl">
              <slide-toggle toggle for="enableSsl"></slide-toggle>
              <validation-errors for="enableSsl"></validation-errors>
            </form-field>

            <form-field label="with_credentials">
              <slide-toggle toggle for="withCredentials"></slide-toggle>
              <validation-errors for="withCredentials"></validation-errors>
            </form-field>

            <ng-container *ngIf="form.value.withCredentials">

              <form-field for="user" label="user" type="input-text">
                <validation-errors for="user"></validation-errors>
              </form-field>

              <form-field for="password" label="password" type="input-password">
                <validation-errors for="password"></validation-errors>
              </form-field>

            </ng-container>

            <form-field label="send_from_user_email" >
              <slide-toggle toggle for="sendFromUserEmail" [readonly]="!canEditSendFromUserEmail"></slide-toggle>
              <validation-errors for="sendFromUserEmail"></validation-errors>
            </form-field>

            <form-field label="ip_addresses">
              <div any>
                <div>90.190.52.51</div>
              </div>
            </form-field>

          </ng-container>

        </div>

      </div>
    </card-section>
  </scope>

</ng-container>
