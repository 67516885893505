<card-expanding>

  <span header-title>{{ 'cards.offer.files.title' | translate}}</span>

  <div header-actions *ngIf="canEdit">
    <ng-container *ngIf="isEditable">
      <div class="flex align-center">
        <lxm-button *ngIf="hasAssessmentForm" type="green" icon="excel" [label]="assessmentFormButtonLabel" (click)="getAssessmentForm()" [loading]="isAssessmentFormLoading">
        </lxm-button>
        <lxm-button class="ml15" type="beige"
                    icon="plus-dark"
                    label="cards.offer.files.action.offer_add_files"
                    (click)="openAddFileDialog()">
        </lxm-button>
      </div>
    </ng-container>
  </div>

  <div body>

    <div class="row" class="mb10" *ngIf="hasAssessmentForm && canEdit && isEditable">
      <info-array [info]="assessmentFormInstructions" [prelude]="assessmentFormInstructionsPrelude"
        class="row mb20">
      </info-array>
    </div>

    <div class="row overflow-x">
      <files-list #filesList
                  [readonly]="!canEdit || !isEditable"
                  [data]="attachments"
                  [displayHeadersWhenEmpty]="false"
                  (open)="openAddFileDialog($event)"
                  (download)="downloadAttachment($event)"
                  (remove)="removeAttachment($event)">
      </files-list>
    </div>

  </div>

</card-expanding>


<a class="no-display" #download></a>