export enum OfferProductField {
  Product = 1,
  Display = 2,
  Limit = 3,
  Status = 4,
  AvailableFrom = 5,
  SelfPrice = 6,
  ListPrice = 7,
  SalePrice = 8,
  SuggestedRetailPrice = 9,
  Vat = 10,
  WithAd = 11,
  MarketingFee = 12,
  InvoiceRecipient = 13,
  CampaignInfo = 14,
  MainAssortmentPrice = 15,
  RequestedPrice = 16,
  RetailPrice = 17,

  AttachmentName = 18,
  AttachmentDescription = 19,

  Netting = 20,
  Addressed = 21,
  StoreCount = 22,
  DisplaySize = 23,
  ReturnableItems = 24,
  Comments = 25,
  CampaignNr = 26,
  CampaignPriceValidFrom = 27,
  MainAssortmentListPrice = 28,

  PriceValidFrom = 29
}

(OfferProductField as any).__typeName = 'OfferProductField';

const _ = 'cards.offer.products.table.';

(OfferProductField as any).__altTranslationKeys = {
  [OfferProductField.Product]: _ + 'product',
  [OfferProductField.Display]: _ + 'display',
  [OfferProductField.Limit]: _ + 'limit',
  [OfferProductField.Status]: _ + 'status',
  [OfferProductField.AvailableFrom]: _ + 'offer_product_available_from',
  [OfferProductField.SelfPrice]: _ + 'internal_price',
  [OfferProductField.ListPrice]: _ + 'internal_pricelist',
  [OfferProductField.SalePrice]: _ + 'offer_price_retailer',
  [OfferProductField.SuggestedRetailPrice]: _ + 'offer_recommended_retail_price',
  [OfferProductField.Vat]: _ + 'var',

  [OfferProductField.Netting]: 'cards.offer.campaign_info.label.netting',
  [OfferProductField.Addressed]: 'cards.offer.campaign_info.label.addressed',
  [OfferProductField.WithAd]: 'cards.offer.campaign_info.label.with_ad',
  [OfferProductField.MarketingFee]: 'cards.offer.campaign_info.label.marketing_fee',
  [OfferProductField.InvoiceRecipient]: 'cards.offer.campaign_info.label.invoice_recipient',
  [OfferProductField.StoreCount]: 'cards.offer.campaign_info.label.store_count',
  [OfferProductField.DisplaySize]: 'cards.offer.campaign_info.label.display_size',
  [OfferProductField.ReturnableItems]: 'cards.offer.campaign_info.label.returnable_items',
  [OfferProductField.Comments]: 'cards.offer.campaign_info.label.comments',
  [OfferProductField.CampaignNr]: 'cards.offer.campaign_info.label.campaign_nr',

  [OfferProductField.CampaignInfo]: 'cards.offer.campaign_info.title',
  [OfferProductField.MainAssortmentPrice]: _ + 'main_assortment_price',
  [OfferProductField.RequestedPrice]: _ + 'offer_product_requested_price',
  [OfferProductField.RetailPrice]: _ + 'offer_product_retail_price',
  [OfferProductField.AttachmentName]: 'cards.products.product_file_management.label.file_name',
  [OfferProductField.AttachmentDescription]: 'cards.products.product_file_management.label.file_description',
};
