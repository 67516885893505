import { Component, Inject, Input, ViewChild, AfterViewInit, Directive, ChangeDetectionStrategy, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subscription, Observable, BehaviorSubject, merge } from 'rxjs';
import { CodeControlBuilder, TareControlBuilder, PackageMaterialControlBuilder, DepositControlBuilder } from 'src/app/_helpers/control.builder';
import { ProductUnitService, IProductUnitFormData, ClvService, AuthenticationService, ProductValidity, ProductService } from 'src/app/_services';
import { LxmDialog } from 'src/app/_helpers/dialogs';
import { DialogComponent } from 'src/app/components/_layout/dialog/dialog.component';
import { ProductUnitType, translate, Status, getKey, asArray, UserAction, ProductUnitPalletType, ProductCardSection, LxmAppModule, UnitShort, Unit, ValidType, ProductUnitField, FieldRequiredType } from 'src/app/enum';
import { IProductCode, ISubUnit, IClvDto, IProductCardFormData, IProductCardMarkingAndNutritionInfo } from '../product-info/product-info.model';
import { IPackagingData, IProductUnitGridItem, IMaterialData } from './product-packaging-info.model';
import { numberUtil } from 'src/app/util/number-util';
import { AppState } from 'src/app/state/app.state';
import { appSettings } from 'src/app/app.settings';
import { IErrorValue, LxmMessage } from 'src/app/_helpers';
import { ProductCardBase } from '../product-card-base';
import { LOCAL_STORAGE_KEY } from 'src/app/config';
import { ProductUnitCodeType } from 'src/app/enum/product-unit-code-type';

@Component({
  selector: "product-packaging-card",
  templateUrl: './product-packaging.card.html',
  styleUrls: ['./product-packaging.card.scss']
})
export class ProductPackagingCard extends ProductCardBase<IPackagingData> {

  public get title(): any {
    return 'cards.products.product_packaging.title';
  }

  public UserAction = UserAction;
  public ProductUnitType = ProductUnitType;

  public isOwnProduct: boolean;

  public dataSource: MatTableDataSource<IPackagingData>;

  public cardSection = ProductCardSection.ProductUnits;

  public tableSupplier = ['packaging_unit_type', 'packaging_retailer', 'packaging_unit_code', 'packaging_unit_contained', 'packaging_number_units', 'packaging_number_basic', 'packaging_unit_height', 'packaging_unit_width', 'packaging_unit_length', 'packaging_unit_gross_weight', 'packaging_unit_status', 'packaging_deposit', 'packaging_materials'];
  public tableRetailer = ['packaging_unit_type', 'packaging_unit_code', 'packaging_unit_contained', 'packaging_number_units', 'packaging_number_basic', 'packaging_unit_height', 'packaging_unit_width', 'packaging_unit_length', 'packaging_unit_gross_weight', 'packaging_unit_status', 'packaging_deposit', 'packaging_materials'];

  @Input()
  public productId: string;

  public canEdit: boolean;

  private _loadSubscription: Subscription;

  public isExpandedKey = LOCAL_STORAGE_KEY.PRODUCT_PACKAGING_CARD_OPEN;

  constructor(
    injector: Injector,
    private _appState: AppState,
    private _dialog: LxmDialog,
    private _productUnitService: ProductUnitService,
    private _clvService: ClvService,
    private _route: ActivatedRoute,
    authService: AuthenticationService,
    public productValidity: ProductValidity,
    private _msg: LxmMessage,
    private _translateService: TranslateService
  ) {
    super(injector, 'packagingInfo', 'packagingInfoChanged');
    this.isOwnProduct = this._route.snapshot.data.productCard.isOwnProduct;
    this.dataSource = new MatTableDataSource<IPackagingData>(this._route.snapshot.data.productCard.units);

    this.canEdit = authService.hasRight([UserAction.ManageProducts])  && this.isOwnProduct;
  }

  public get cardValidType(): ValidType {
    return this.productValidity.getCardStatusValidType(this.cardSection);
  }

  public isProductUnitInvalid(id) {
    return this.productValidity.isProductUnitInvalid(id);
  }

  public get table() {

    let table: string[] = [];

    if (this._appState.hasModule(LxmAppModule.SellerPimExtensions)) {
      table = table.concat(this.tableSupplier);
    }
    else {
      table = table.concat(this.tableRetailer);
    }

    if (this.isOwnProduct) {
      table.push('packaging_remove');
    }

    return table;
  }

  public openPackagingDialog(row = null) {

    this._dialog.open(ProductPackagingDialog, {
      ...appSettings.DIALOG_SIZES.L,
      data: {
        productId: this.productId,
        productCard: this
      },
      resolve: () => {
        const resolvers: any = {
          formData: this._productUnitService.getFormData(this.productId, row ? row.id : null)
        };

        if (row) {
          resolvers.unit = this._productUnitService.get(row.id);
        }

        return forkJoin(resolvers);
      }
    });
  }

  public getPackageDepositsValue(row: IProductUnitGridItem): string {
    return row.packageDeposits
    .map(x => `${x.depositType.country.name} - ${this._clvService.translate(x.depositType)}: ${x.amount_pc} ${this._translateService.instant('enums.UnitShort.Piece')}`)
    .join(', ');
  }

  public getPackageMaterialsValue(row: IProductUnitGridItem): string {
    return row.packageMaterials
    .map(x => `${this._clvService.translate(x.materialType)}: ${x.amount_g} ${this._translateService.instant('enums.UnitShort.Gram')}`)
    .join(', ');
  }

  public loadUnits(): void {
    if (this._loadSubscription) {
      this._loadSubscription.unsubscribe();
    }

    this._loadSubscription = this._productUnitService
      .getGridData(this.productId)
      .subscribe(result => {
        this.dataSource.data = result;
      }, err => {
        console.error(err);
        this._msg.error({
          message: 'cards.products.product_packaging.table.load_failed'
        })
      });
  }

  public deleteUnit(row: IProductUnitGridItem): void {
    event.stopImmediatePropagation();
    if (row.type.id === ProductUnitType.BaseUnit.toString()) {
      return;
    }

    this._dialog.confirm({
      image: 'delete-modal-image',
      template: `
      <p class="dialog-title mt5">${this._translateService.instant('cards.products.product_packaging.delete.title')}</p>
      <p>
        ${this._translateService.instant('cards.products.product_packaging.delete.message')}
      </p>
      `
    }, () => {
      this._productUnitService.delete(row.id)
        .subscribe(res => {
          this.loadUnits();
        }, err => {
          console.error(err);
          this._msg.error({
            message: 'cards.products.product_packaging.delete.delete_failed'
          })
        });
    })
  }

  public stopPropagation() {
    event.stopPropagation();
  }

  public trackById(index: number, item: IProductUnitGridItem) {
    return item.id;
  }


  public getRowHeightColorClass(row) {

    switch(row.type?.id) {
      case ProductUnitType.Pallet:
        const heightmm = row.height_mm;

        if (!heightmm) {
          return null;
        }
    
        if (heightmm < 600) {
          return 'color--azure';
        }
    
        if (heightmm >= 1400 && heightmm <= 2200) {
          return 'color--tangerine';
        }
    
        if (heightmm > 2200) {
          return 'color--watermelon';
        }
        
        return null;

      default:
        return null;
    }

  }

  public getRowWidthColorClass(row) {

    switch(row.type?.id) {

      case ProductUnitType.Pallet:
        const widthmm = row.width_mm;

        if (!widthmm) {
          return null;
        }
    
        if (widthmm > 1200) {
          return 'color--watermelon';
        }
    
        return null;

      default:
        return null;
    }


  }

  public getRowLengthColorClass(row) {

    switch(row.type?.id) {
      case ProductUnitType.Pallet:
        const lengthmm = row.length_mm;

        if (!lengthmm) {
          return null;
        }
    
        if (lengthmm > 1200) {
          return 'color--watermelon';
        }
    
        return null;

      default:
        return null;
    }

  }

  public getRowGrossWeightColorClass(row) {

    switch(row.type?.id) {
      case ProductUnitType.Pallet:
        const gross = row.grossWeight_g / 1000;

        if (!gross) {
          return null;
        }
    
        if (gross > 960001 && gross < 1199999) {
          return 'color--tangerine';
        }

        if (gross > 1200000) {
          return 'color--watermelon';
        }
    
        return null;

      default:
        return null;
    }


  }



}

@Component({
  selector: "product-packaging-dialog",
  host: { 'class': 'dialog' },
  templateUrl: './product-packaging.dialog.html'
})
export class ProductPackagingDialog implements AfterViewInit {

  public UserAction = UserAction;
  public ProductUnitType = ProductUnitType;

  @ViewChild('dialog', { static: true, read: DialogComponent })
  private _dialog: DialogComponent;

  public childDialog: ProductPackagingDialogBase;

  public unit: IPackagingData;
  public type: any;

  public baseUnitType: IClvDto;
  public allUnitTypes: IClvDto[];
  public selectableUnitTypes: IClvDto[];

  public canEdit: boolean;
  public saveLoading = false;

  public form = new FormGroup({
    unitType: new FormControl(),
    isActive: new FormControl()
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: IProductUnitDialogData,
    public dialogRef: MatDialogRef<ProductPackagingDialog>,
    authService: AuthenticationService
  ) {
    this.canEdit = authService.hasRight([UserAction.ManageProducts]) && this._data.productCard.isOwnProduct;
  }

  public ngAfterViewInit(): void {

    this._dialog.dataLoaded$
      .subscribe(state => {
        if (!state) {
          return;
        }

        this.allUnitTypes = this._data.formData.unitTypes;
        this.baseUnitType = this.allUnitTypes.find(x => x.id === ProductUnitType.BaseUnit.toString());
        this.selectableUnitTypes = this.allUnitTypes.filter(x => x.id !== this.baseUnitType.id);
        this.unit = this._data.unit;
        this.type = this.unit && this.allUnitTypes.find(x => x.id === this.unit.type?.id);

        this.form.patchValue({
          unitType: this.type?.id,
          isActive: !this.unit || this.unit.status === Status.Active
        });
      });
  }

  public saveUnit() {
    if (!this.childDialog) {
      return;
    }

    this.childDialog.saveUnit();
  }

  public selectUnitType(e) {
    this.type = e;
  }

  public selectPackageType(e) {
    
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public closeDialog() {
    this.dialogRef.close();
  }
}

@Directive()
class ProductPackagingDialogBase {

  public ProductUnitField = ProductUnitField;
  public FieldRequiredType = FieldRequiredType;

  public UserAction = UserAction;
  public ProductUnitType = ProductUnitType;

  private _productId: string;
  private _productCard: ProductPackagingCard;

  private _layerNumberSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  protected _unit: IPackagingData;

  public formData: IProductUnitFormData;
  public form: FormGroup;
  public canEdit: boolean;
  public userHasChangedHeight: boolean = false;

  public checkPackageCapacity: boolean = true;
  public packageCapacityCheckFailed: boolean = false;
  public packageCapacityCheckError: IErrorValue;

  public subUnits: ISubUnit[];
  public palletTypes: any[];

  public layerNumber$ = this._layerNumberSubject.asObservable();

  public hasSupplierPim: boolean;
  public isOwnProduct: boolean;

  public codeTypes = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: IProductUnitDialogData,
    public main: ProductPackagingDialog,
    protected _depositBuilder: DepositControlBuilder,
    protected _packageMaterialBuilder: PackageMaterialControlBuilder,
    protected _tareBuilder: TareControlBuilder,
    protected _codeBuilder: CodeControlBuilder,
    protected _clvService: ClvService,
    private _appState: AppState,
    private _productService: ProductService,
    private _translateService: TranslateService,
    private _productUnitService: ProductUnitService,
    private _dialog: LxmDialog,
    public productValidity: ProductValidity,
    authService: AuthenticationService) {

    this.main.childDialog = this;
    this.formData = _data.formData;
    this._unit = this.normalizeData(_data.unit);
    this._productId = _data.productId;
    this._productCard = _data.productCard;

    this.subUnits = this.formData.subUnits.map(x => {
      const retailChain = x.retailChainId
        ? this.formData.retailChains.find(y => y.id === x.retailChainId)
        : null;

      const nameParts = [
        this._clvService.translate(x.type),
        x.ean,
        retailChain ? retailChain.name : null
      ];

      const clone = { ...x };
      clone.name = nameParts.filter(y => y).join(' ');
      return clone;
    });

    this.palletTypes = this.formData.palletTypes
      .map(x => {
        const clone = { ...x };
        clone.value = translate(ProductUnitPalletType, x.id, this._translateService);
        return clone;
      })
      .sort((x, y) => x.value.localeCompare(y.value));

    this.form = this.createForm();
    this.form.patchValue(this._unit);
    this.bindCalculations();

    this.isOwnProduct = this._data.productCard.isOwnProduct;
    this.hasSupplierPim = _appState.hasModule(LxmAppModule.SellerPimExtensions);

    this.canEdit = authService.hasRight([UserAction.ManageProducts]) && this._data.productCard.isOwnProduct;

    this.setCodeTypes();
  }

  public isFieldInvalid(field: ProductUnitField, formValue: any) {
    return this.productValidity.isProductUnitFieldInvalid(this._unit?.id, field, formValue);
  }

  public get subUnitHasNetAndGrossWeight() {
    const { netWeight_g, grossWeight_g, subUnit } = this.form.value || {};
    if (!subUnit) {
      return true;
    }
    if (netWeight_g === null || netWeight_g === undefined) {
      return false;
    }
    if (grossWeight_g === null || grossWeight_g === undefined) {
      return false;
    }
    return true;
  }

  public setCodeTypes() {
    this.codeTypes = this.formData?.codeTypes?.filter(x => x.id !== ProductUnitCodeType.InternalCode);
  }

  protected normalizeData(unit: IPackagingData): any {
    const res = {...unit} || <any>{};
    res.palletTypeId = res.palletType?.id;
    res.packageTypeId = res.packageType?.id;
    res.typeId = res.type?.id;
    res.pickingUnitId = res.pickingUnit?.id;
    return res;
  }

  protected createForm(): FormGroup {

    return new FormGroup({
      id: new FormControl(),
      isActive: new FormControl(),
      retailChain: this.getRetailChainControl(),
      isOrderUnit: new FormControl(this._unit?.id ? this._unit.isStackable : this.main.type?.id !== ProductUnitType.BaseUnit),
      subUnit: this.getSubUnitControl(),
      subUnitCount: new FormControl(),
      baseUnitCount: new FormControl(),
      baseUnitsCountInLayer: new FormControl(),
      palletTypeId: new FormControl(),
      height_mm: new FormControl(),
      width_mm: new FormControl(),
      length_mm: new FormControl(),
      compressedHeight_mm: new FormControl(),
      compressedWidth_mm: new FormControl(),
      compressedLength_mm: new FormControl(),
      netWeight_g: new FormControl(),
      grossWeight_g: new FormControl(),
      packageTypeId: new FormControl(),
      isCompressable: new FormControl(),
      hasPegHole: new FormControl(),
      isStackable: new FormControl(),
      maxStack: new FormControl(),
      productFaceCount: new FormControl(),
      isReturnablePackage: new FormControl(),
      isShelfReadyPackage: new FormControl(),
      isReusablePackage: new FormControl(),
      packageName: new FormControl(),
      codes: this.getCodeControls(),
      tares: this.getTareControls(),
      packageDeposits: this.getDepositControls(),
      packageMaterials: this.getMaterialControls(),
      layerCount: new FormControl(),
      pickingUnitId: new FormControl(),
      hasVariableWeight: new FormControl(),
      isInventoryUnit: new FormControl()
    });
  }

  public saveUnit(): void {
    if (this.packageCapacityCheckFailed) {
      this._dialog.confirm({
        title: 'product_units.dialogs.save_with_failed_capacity_check.title',
        message: 'product_units.dialogs.save_with_failed_capacity_check.text'
      }, () => {
        this.main.saveLoading = true;
        this.checkPackageCapacity = false;
        this.save();
      });
    } else {
    this.main.saveLoading = true;
      this.save();
    }
  }

  private save() {
    const val = this.form.value;

    const req = {
      typeId: this.main.type ? this.main.type.id : null,
      productId: this._productId,
      netWeight_g: val.netWeight_g,
      grossWeight_g: val.grossWeight_g,
      height_mm: val.height_mm,
      width_mm: val.width_mm,
      length_mm: val.length_mm,
      isCompressable: val.isCompressable || false,
      compressedHeight_mm: val.compressedHeight_mm,
      compressedWidth_mm: val.compressedWidth_mm,
      compressedLength_mm: val.compressedLength_mm,
      hasPegHole: val.hasPegHole || false,
      isStackable: val.isStackable || false,
      maxStack: val.isStackable ? val.maxStack : 1,
      isOrderUnit: val.isOrderUnit || false,
      isReturnablePackage: val.isReturnablePackage || false,
      isShelfReadyPackage: val.isShelfReadyPackage || false,
      productFaceCount: val.productFaceCount,
      baseUnitsCountInLayer: val.baseUnitsCountInLayer,
      layerCount: val.layerCount,
      subUnitCount: val.subUnitCount,
      baseUnitCount: val.baseUnitCount,
      isReusablePackage: val.isReusablePackage || false,
      packageName: val.packageName,
      status: this.main.form.value.isActive ? Status.Active : Status.Disabled,
      retailChainId: val.retailChain ? val.retailChain.id : null,
      subUnitId: val.subUnit ? val.subUnit.id : null,
      packageTypeId: val.packageTypeId,
      palletTypeId: val.palletTypeId,
      codes: val.codes.filter((x: IProductCode) => !x.isDeleted),
      packageDeposits: val.packageDeposits
        ? val.packageDeposits
            .map(x => ({
              id: x.id,
              depositTypeId: x.deposit,
              amount_pc: x.amount_pc
            }))
        : null,
      packageMaterials: val.packageMaterials
        ? val.packageMaterials
            .map(x => ({
              id: x.id,
              materialTypeId: x.material,
              amount_g: x.amount_g,
              isReusable: x.isReusable
            }))
        : null,
      tares: val.tares
        ? val.tares
            .map(x => ({
              id: x.id,
              tareTypeId: x.tare,
              amount_pc: x.amount_pc
            }))
        : null,
      checkPackageCapacity: this.checkPackageCapacity,
      pickingUnitId: val.pickingUnitId,
      hasVariableWeight: val.hasVariableWeight || false,
      isInventoryUnit: val.isInventoryUnit || false
    };

    this._productUnitService
      .save(val.id, req)
      .result(this.form, () => {
        this._productCard.loadUnits();

        if (this.productValidity.shouldValidate) {
          const retailerIds = this.productValidity.validatedRetailers.map(x => x.id);
          if (retailerIds.length > 0) {
            this._productService.validateRetailChainFieldRules(this._productId, retailerIds)
            .result(null, res => this.productValidity.set(res));
          }
        }

        this.main.closeDialog();
        this.main.saveLoading = false;
      }, err => {
        if (!this.packageCapacityCheckFailed) {
          let packageCapacityCheckFailed = err.validationResults.packageCapacityCheckFailed;
          if (packageCapacityCheckFailed !== undefined) {
            this.packageCapacityCheckFailed = true;
            this.packageCapacityCheckError = packageCapacityCheckFailed[0];
          }
        }

        this.main.saveLoading = false;
      });
  }

  private bindCalculations(): void {
    this.bindLayerNumber();
    this.bindNetWeight();
    this.bindGrossWeight();
    this.bindPalletHeight();
  }

  private bindNetWeight(): void {
    if (this.main.type?.id === ProductUnitType.BaseUnit) {
      return;
    }

    merge(
      this.form.get('subUnitCount').valueChanges,
      this.form.get('subUnit').valueChanges
    )
    .subscribe(() => {
      const subUnit = this.form.get('subUnit').value as ISubUnit;
      const subUnitCount = numberUtil.toNumber(this.form.get('subUnitCount').value);

      if (!subUnit?.grossWeight_g || !subUnitCount) {
        return;
      }

      const value = subUnit.grossWeight_g * subUnitCount;
      const roundedValue = numberUtil.toNumber(value.toFixed(1));

      this.form.patchValue({ netWeight_g: roundedValue });
    });
  }

  private bindGrossWeight(): void {
    merge(
      this.form.get('netWeight_g').valueChanges,
      this.form.get('packageMaterials').valueChanges
    )
    .subscribe(() => {
      const netWeight = numberUtil.toNumber(this.form.get('netWeight_g').value);
      const packageMaterials = this.form.get('packageMaterials').value as IMaterialData[];

      const value = netWeight + packageMaterials.filter(x => x.amount_g).reduce((sum, current) => sum + numberUtil.toNumber(<any>current.amount_g), 0);

      if (isNaN(value)) {
        return;
      }

      const roundedValue = numberUtil.toNumber(value.toFixed(1));
      this.form.patchValue({ grossWeight_g: roundedValue });
    });
  }

  private bindLayerNumber(): void {
    merge(
      this.form.get('baseUnitsCountInLayer').valueChanges,
      this.form.get('subUnitCount').valueChanges,
      this.form.get('subUnit').valueChanges
    )
      .subscribe(this.calculateLayerNumber);

    this.calculateLayerNumber();
  }

  private calculateLayerNumber = (): void => {
    const subUnit = this.form.get('subUnit').value;

    if (!subUnit) {
      this.form.get("layerCount").setValue(null);
      this._layerNumberSubject.next(null);
      return;
    }

    let value = ((subUnit.baseUnitCount || 1)
      * numberUtil.toNumber(this.form.get('subUnitCount').value))
      / numberUtil.toNumber(this.form.get('baseUnitsCountInLayer').value);
    value = Math.max(1, Math.ceil(value));

    if (isNaN(value) || !isFinite(value)) {
      this.form.get("layerCount").setValue(null);
      this._layerNumberSubject.next(null);
      return;
    }

    this.form.get("layerCount").setValue(value);
    this._layerNumberSubject.next(value);
  }

  private bindPalletHeight(): void {
    if (this.main.type?.id !== ProductUnitType.Pallet) {
      return;
    }

    merge(
      this.form.get('subUnit').valueChanges,
      this.form.get('layerCount').valueChanges
    )
    .subscribe(() => {
      const subUnit = this.form.get('subUnit').value as ISubUnit;
      const layerCount = numberUtil.toNumber(this.form.get('layerCount').value);
      const value = !subUnit?.height_mm || !layerCount ? null : subUnit.height_mm * layerCount;
      this.form.patchValue({ height_mm: value });
      this.userHasChangedHeight = false;
    });

    this.form.get('height_mm').valueChanges.subscribe(() => {
      this.userHasChangedHeight = true;
    });
  }

  protected getRetailChainControl(): FormControl {
    const data = this._unit.retailChainId
      ? this.formData.retailChains.find(x => x.id === this._unit.retailChainId)
      : null;

    return new FormControl(data);
  }

  protected getSubUnitControl(): FormControl {
    const data = this._unit.subUnitId
      ? this.subUnits.find(x => x.id === this._unit.subUnitId)
      : null;

    return new FormControl(data);
  }

  protected getCodeControls(): FormControl {
    return new FormControl(this._unit.codes || []);
  }

  protected getTareControls(): FormArray {
    return this._tareBuilder.createTareControls(this._unit.tares || []);
  }

  protected getDepositControls(): FormArray {
    return this._depositBuilder.createDepositControls(this._unit.packageDeposits || []);
  }

  protected getMaterialControls(): FormArray {
    return this._packageMaterialBuilder.createPackageMaterialControls(this._unit.packageMaterials || []);
  }
}

@Component({
  selector: "packaging-dialog-basic-unit",
  templateUrl: './template/basic-unit.template.html'
})
export class ProductPackagingDialogBasicUnit extends ProductPackagingDialogBase {

}

@Component({
  selector: "packaging-dialog-bulk-packaging",
  templateUrl: './template/bulk-packaging.template.html'
})
export class ProductPackagingDialogBulkPackaging extends ProductPackagingDialogBase {

}

@Component({
  selector: "packaging-dialog-pallet",
  templateUrl: './template/pallet.template.html'
})
export class ProductPackagingDialogPallet extends ProductPackagingDialogBase {

  public get palletHeightInputColorClass() {
    const heightmm = this.form.get('height_mm').value;

    if (!heightmm) {
      return null;
    }

    if (heightmm < 600) {
      return 'color--azure';
    }

    if (heightmm >= 1400 && heightmm <= 2200) {
      return 'color--tangerine';
    }

    if (heightmm > 2200) {
      return 'color--watermelon';
    }

    return null;
  }

  public get palletWidthInputColorClass() {
    const widthmm = this.form.get('width_mm').value;

    if (!widthmm) {
      return null;
    }

    if (widthmm > 1200) {
      return 'color--watermelon';
    }

    return null;
  }

  public get palletLengthInputColorClass() {
    const lengthmm = this.form.get('length_mm').value;

    if (!lengthmm) {
      return null;
    }

    if (lengthmm > 1200) {
      return 'color--watermelon';
    }

    return null;
  }

  public get palletGrossWeightInputColorClass() { 
    const gross = this.form.get('grossWeight_g').value;

    if (!gross) {
      return null;
    }

    if (gross > 960001 && gross < 1199999) {
      return 'color--tangerine';
    }

    if (gross > 1200000) {
      return 'color--watermelon';
    }

    return null;
  }

}

@Component({
  selector: "packaging-dialog-display",
  templateUrl: './template/display.template.html'
})
export class ProductPackagingDialogDisplay extends ProductPackagingDialogBase {

}

export interface IProductUnitDialogData {
  invalidFields: any;
  productId: string;
  productCard: ProductPackagingCard;
  unit: IPackagingData;
  formData: IProductUnitFormData;
}
