export enum OfferExportType {
  LxmCrmOfferEng = 1,
  LxmCrmOfferEst = 2,
  LxmCrmOfferSimpleEng = 3,
  LxmCrmOfferSimpleEst = 4,
  SupplierProductsExport = 5,
  OfferPdf = 6,
  OfferAndProductsPdf = 7
}

(OfferExportType as any).__typeName = 'OfferExportType';
