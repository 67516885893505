import { Component, OnInit, Input, ChangeDetectorRef, ElementRef, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Location } from '@angular/common';
import { ProductsListBase } from '../products-list-base';
import { HttpMethod, LxmAppModule, UserAction } from 'src/app/enum';

import { mergeMap, map } from 'rxjs/operators';
import { LxmDialog } from 'src/app/_helpers';
import { AuthenticationService, CreateOfferDataService, DataTableService, ProductService } from 'src/app/_services';
import { ProductsRetailerExportDialogComponent } from './export-products-retailer.dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsListState } from '../products-list.state';
import { AppState } from 'src/app/state/app.state';
import { MatSort } from '@angular/material/sort';
import { Observable } from 'rxjs';

@Component({
  selector: 'products-retailer-card',
  templateUrl: './products-retailer.card.html',
  host: { 'class': 'data-table-card' },
  styleUrls: ['./products-retailer.card.scss', '../products-list-base.scss']
})
export class ProductsRetailerCard extends ProductsListBase<IProductData, any> implements OnInit {

  @ViewChild('download') private _download: ElementRef;

  public table = {
    columns: []
  };

  dataUrl = 'products/forRetailer';
  method = HttpMethod.Post;
  initialData;

  public formData;

  public UserAction = UserAction;
  public LxmAppModule = LxmAppModule;

  public get selectedSuppliersCount() {

    return this.selection
      .map(x => x.supplier)
      .filter((value, index, array) => {
          return array.indexOf(value) === index;
      })
      .length;
  }

  constructor(
    public state: ProductsListState,
    private _appState: AppState,
    private _authService: AuthenticationService,
    private _productService: ProductService,
    private _router: Router,
    private _createOfferDataService: CreateOfferDataService,
    public appState: AppState,
    location: Location,
    public dialog: LxmDialog,
    _dataService: DataTableService<IProductData, any>,
    _cd: ChangeDetectorRef,
    route: ActivatedRoute
  ) {
    super(state, location, dialog, _dataService, _cd);
    this.initialData = route?.snapshot?.data?.initialData;

    this.formData = route?.snapshot?.data?.formData;
    let columns = this.formData?.columns;
    if (!columns) {
      columns = this._getDefaultColumns();
    }

    this.table.columns = columns;

    let pricePrecision = this.formData?.pricePrecision;
    if (pricePrecision) {
      this.pricePrecision = pricePrecision;
    }

    this.procurementHasPriority = this.formData?.procurementHasPriority;
  }

  protected getSortParam(sort: MatSort) {
    if (!sort || !sort.active || sort.direction === "") {
      return '';
    }

    let colId = sort.active;
    if (colId === 'product_name') {
      colId = 'fullName';
    }
    if (colId === 'custom_code') {
      colId = 'mainCode';
    }
    if (colId === 'category') {
      colId = 'segment';
    }
    if (colId === 'product_listed_price') {
      colId = 'retailerPriceScheme/listPrice';
    }
    if (colId === 'product_retail_price') {
      colId = 'retailerPriceScheme/retailPrice';
    }

    return colId + ' ' + sort.direction;
  }

  private _getDefaultColumns() {

    var cols = [
      'select',
      'image',
      'fullName',
      'ean',
      'brand',
      'category',
      'supplier'
    ];

    if (this._appState.hasModule(LxmAppModule.BuyerPrices)) {
      cols.push('product_scheme_info');
      cols.push('product_listed_price');
      cols.push('product_price_for_retailer');
    }

    if (this._appState.hasModule(LxmAppModule.BuyerAssortment)) {
      cols.push('assortment_groups');
    }

    return cols;
  }

  public get retailPriceField() {
    return this.table.columns.includes('product_retail_price_without_vat') ? 'retailPriceWithoutVat' : 'retailPrice'
  }

  public isAllSelected() {
    return this.currentPageSelected.length === this.dataSource.data.length;
  }

  public getPrioritySchemeField(row, field: string) {
    const { mainPriceScheme, procurementPriceScheme, campaignPriceScheme } = row || {};

    if (mainPriceScheme) {
      return mainPriceScheme?.[field];
    }
    if (this.procurementHasPriority && procurementPriceScheme) {
      return procurementPriceScheme?.[field];
    }
    if (campaignPriceScheme) {
      return campaignPriceScheme?.[field];
    }
    if (procurementPriceScheme) {
      return procurementPriceScheme?.[field];
    }
    return null;
  }

  public masterToggle() {
    if(this.isAllSelected()) {
      this.dataSource.data.forEach(row => {
        
          const index = this._getSelectionProductIndex(row);
          if (index > -1) {
            this.selection.splice(index, 1);
          }
        
      })
    } else {
      this.dataSource.data.forEach(row => {
        if (!this.isProductSelected(row)) {
          this.selection.push(row);
        }
      });
    }
    this._updateCurrentPageSelection();
  }

  public toggleSelection(row): void {
    const index = this._getSelectionProductIndex(row);
    if (index < 0) {
      this.selection.push(row);
      this.currentPageSelected.push(row.id);
    } else {
      this.selection.splice(index, 1);
      const i = this._getCurrentPageProductIndex(row);
      if (i > -1) {
        this.currentPageSelected.splice(i, 1);
      }
    }
  }

  public newProduct() {
    this._router.navigate(['/products/new']);
  }

  public createOffer() {
    this._createOfferDataService.productIds = this.selection.map(x => x.id);
    this._router.navigate(['/offers/new']);
  }

  public exportXlsForSupplier() {
    var ids = this.selection.map(x => x.id);
    let exportFunc = this._productService.exportSupplierProducts({ productIds: ids });
    this.downloadXls(exportFunc);
  }

  public exportXls() {
    var ids = this.selection.map(x => x.id);
    let exportFunc: Observable<any>;
    if (ids.length) {
      exportFunc = this._productService.exportXls({ productIds: ids, date: this.getData()?.date });
    }
    else {
      exportFunc = this._productService.exportRetailerProducts(this.getData());
    }

    this.downloadXls(exportFunc);
  }

  private downloadXls(exportFunc: Observable<any>) {

    this.dialog.open(ProductsRetailerExportDialogComponent, {
      resolve: () =>
        exportFunc
          .pipe(
            mergeMap(async res => {
              const blob = await this._productService.downloadXls(res.id).toPromise();
              return {
                fileName: res.fileName,
                blob: blob
              }
            })
          )
          .pipe(
            map(res => {
              const url = window.URL.createObjectURL(res.blob);

              const link = this._download.nativeElement;
              link.href = url;
              link.download = res.fileName;
              link.click();

              window.URL.revokeObjectURL(url);
            })
          )
    });
  }
}


export interface IProductData {
  id: string;
  fullName: string;
  status: number;
  image: string;
  weight: string;
  ean: number;
  brand: string;
  segment: string;
  supplier: string;
  company: string;
  publicSalesPrice: string;
  retailSalesPrice: string;
  hasCampaign: boolean;
  campaignInfo: any;
  productInfoFullness: number;
  internalCode: number;
}

export interface ISupplierInfo {
  company: string;
  as: string;
}

export interface IProductInfo {
  name: string;
  weight: string;
}
